import {Divider, Grid, Typography} from "@mui/material";

//@ts-ignore
const RowBlock = (props) => {
    const {title} = props
    return (
        <Grid container alignItems={'center'} >
            <Grid item xs={2}><Typography>{title}</Typography></Grid>
            <Divider orientation="vertical" flexItem style={{marginRight: '10px'}}/>
            <Grid item xs={9}>{props.children}</Grid>
        </Grid>
    )
}

export default RowBlock