import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import { PointScheduleListSearchParams, PointScheduleStatusCode } from "types/PointScheduleListItemType"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { PointReasonType, PointStatusCode } from "types/PointType"
import { PointScheduleListSearchType } from ".."

const searchPointSchduleMenuList: { value: keyof PointScheduleListSearchParams; title: string }[] = [
  { value: "status", title: "상태" },
  { value: "reasonType", title: "지급 사유" },
  { value: "reasonMessage", title: "지급 메세지" },
]

const statusMenuList: { value: PointScheduleStatusCode; title: string }[] = [
  {
    value: PointScheduleStatusCode.대기,
    title: "대기",
  },
  {
    value: PointScheduleStatusCode.취소,
    title: "취소",
  },
  {
    value: PointScheduleStatusCode.완료,
    title: "완료",
  },
  {
    value: PointScheduleStatusCode.실패,
    title: "실패",
  },
]

const reasonTypeMenuList: { value: PointReasonType; title: string }[] = [
  {
    value: "이벤트 적립",
    title: "이벤트 적립",
  },
  {
    value: "수동 적립",
    title: "수동 적립",
  },
  {
    value: "수동 차감",
    title: "수동 차감",
  },
]

type Props = {
  searchParams: PointScheduleListSearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: keyof PointScheduleListSearchParams) => void
  onSearch: (keyword: PointScheduleListSearchType["keyword"]) => void
}

const PointScheduleListSearchBar = ({
  searchParams,
  changeSearchKeyword,
  changeSearchFilterValue,
  onSearch,
}: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"status"}
          required
          onChange={(e) => changeSearchFilterValue(e.target.value as keyof PointScheduleListSearchParams)}
        >
          {searchPointSchduleMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        {searchParams.filter === "status" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as PointStatusCode
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              {statusMenuList.map((menu) => (
                <MenuItem value={menu.value} key={menu.title}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {searchParams.filter === "reasonType" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as PointReasonType
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              {reasonTypeMenuList.map((menu) => (
                <MenuItem value={menu.value} key={menu.title}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {searchParams.filter === "reasonMessage" && (
          <SearchInput
            style={{ height: 40 }}
            value={searchParams.keyword}
            onChange={(e) => changeSearchKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(searchParams.keyword)
              }
            }}
          />
        )}
      </SearchBar>
    </Wrapper>
  )
}

export default PointScheduleListSearchBar

const Wrapper = styled.div`
  width: 500px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
