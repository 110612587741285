import React, { useCallback } from "react"
import { DiagnosisType } from "../../../../types/DiagnosisType"
import { currencyFormat, numberWithCommas, toRange, truncateWithCommas } from "../../../../utils/NumberUtils"
import { FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { Spacer } from "../../../../components/Spacer"
type PreSettlementStatusTypes = {
  provisionalPreSettlement?:DiagnosisType['provisionalPreSettlement'];
  confirmedPreSettlement?:DiagnosisType['confirmedPreSettlement']
  setConfirmedPreSettlement:(state:DiagnosisType['confirmedPreSettlement'])=>void
}

export const PreSettlementStatus:React.FC<PreSettlementStatusTypes> = (props)=>{
  const getFinalPrice = ():string=>{
    if(!props.confirmedPreSettlement) return '';
    if(props.confirmedPreSettlement.calculationType === '정률'){
      //정률, 천원 이하 절삭되도록 수정
      const price = (props.confirmedPreSettlement?.standardPriceForPreSettlement??0) * (props.confirmedPreSettlement.rate / 100)
      return numberWithCommas((Math.floor ( (price)/1000) ) * 1000)
    }

    return numberWithCommas(props.confirmedPreSettlement.amount);

  }

  const getDefaultPreSettlementItem = useCallback<()=>DiagnosisType['confirmedPreSettlement']>(()=>{

    return {
      id:props.confirmedPreSettlement?.id,
      status:'작성완료',
      estimatedPriceAtRequest:{
        min:props.provisionalPreSettlement?.estimatedPriceAtRequest?.min ?? 0,
        max:props.provisionalPreSettlement?.estimatedPriceAtRequest?.max ??0,
        median:props.provisionalPreSettlement?.estimatedPriceAtRequest?.median ??0,
      },
      standardPriceForPreSettlement:props.provisionalPreSettlement?.standardPriceForPreSettlement ?? 0,
      preSettlementPrice:0,
      calculationType: '정률',
      rate: 0,
    }
  }, [
    props.confirmedPreSettlement?.id,
    props.provisionalPreSettlement?.estimatedPriceAtRequest?.max,
    props.provisionalPreSettlement?.estimatedPriceAtRequest?.median,
    props.provisionalPreSettlement?.estimatedPriceAtRequest?.min,
    props.provisionalPreSettlement?.standardPriceForPreSettlement
  ])


  const onChangeValue = useCallback((key:
                                       | 'standardPriceForPreSettlement'
                                       | 'preSettlementPrice'
                                       | 'calculationType'
                                       | 'rate'
                                       | 'amount',
                                     value:string|number)=>{
    const defaultItem = getDefaultPreSettlementItem();

    const nextItem:DiagnosisType['confirmedPreSettlement'] = Object.assign(
      defaultItem,
      props.confirmedPreSettlement, {
      [key]: value,
      status:'작성완료'
    })
    props.setConfirmedPreSettlement(nextItem)
  }, [getDefaultPreSettlementItem, props])

  if(!props.provisionalPreSettlement) return null;

  return (
    <>
      <h2>선정산</h2>

      <table className={"basic"}>
        <tbody>
        <tr>
          <td className={"header"}>예상 가격 범위</td>
          <td colSpan={2}>{props.confirmedPreSettlement?.estimatedPriceAtRequest ? `${toRange(
            (props.confirmedPreSettlement?.estimatedPriceAtRequest.min??0),
            (props.confirmedPreSettlement?.estimatedPriceAtRequest.max??0),
            4
          )} 만원` : '-'}</td>

          <td className={"header"}>최적 판매가</td>
          <td colSpan={2}>{
            props.confirmedPreSettlement?.estimatedPriceAtRequest ?
              `${truncateWithCommas((props.confirmedPreSettlement?.estimatedPriceAtRequest?.median??0),4 )}만원`
              : '-'}</td>
          <td className={"header"}>가견적 기준가</td>
          <td colSpan={2}>{numberWithCommas(props.provisionalPreSettlement.standardPriceForPreSettlement)}원</td>
          <td className={"header"}>가견적 금액</td>
          <td colSpan={2}>{numberWithCommas(props.provisionalPreSettlement.preSettlementPrice)}원</td>
        </tr>
        </tbody>
      </table>

      <Spacer space={18}/>
      <table className={'basic'}>
        <tbody>
        <tr>
          <td className={'header'} colSpan={6}>
            선정산 입력
          </td>
        </tr>

        <tr>
          <td colSpan={3}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', padding:'10px'}}>
              <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'space-between'}}>
                <Typography variant={'subtitle1'} fontWeight={'700'}>선정산 기준가</Typography>

                <div style={{height:'40px'}}></div>
              </div>
              <TextField
                fullWidth
                placeholder={"0"}
                size={"small"}
                value={numberWithCommas(props.confirmedPreSettlement?.standardPriceForPreSettlement ?? 0)}
                inputProps={{
                  style: {
                    textAlign: 'right'
                  }
                }}
                InputProps={{
                  endAdornment:<InputAdornment position="end">원</InputAdornment>
                }}
                onChange={(event) => {
                  if(event.target.value === '') {
                    onChangeValue('standardPriceForPreSettlement', 0)
                    return;
                  }

                  const input = event.target.value.replace(/\,/g, '');
                  // props.setPreCalculatedBasePrice(input === '' ? 0 : parseInt(input))
                  onChangeValue('standardPriceForPreSettlement', parseInt(input))
                }}
              />
            </div>
          </td>

          <td colSpan={3}>
            <div style={{display:'flex', flexDirection:'column', padding:'10px'}}>
              <div style={{display:'flex', flex:1, height:'46px', alignItems:'center', justifyContent:'space-between'}}>
                <Typography variant={'subtitle1'} fontWeight={'700'}>선정산 적용</Typography>
                <div>
                  <RadioGroup
                    row
                    value={props.confirmedPreSettlement?.calculationType === '정률' ? '1' : '2'}
                    onChange={(event)=> {
                      onChangeValue('calculationType', event.target.value === '1' ? '정률':'정가')
                    }}>
                    <FormControlLabel control={<Radio size={'small'}/>} label={'정률'} value={'1'}/>
                    <FormControlLabel control={<Radio size={'small'}/>} label={'정가'} value={'2'}/>
                  </RadioGroup>
                </div>
              </div>
              <TextField
                fullWidth
                placeholder={"0"}
                size={"small"}
                value={props.confirmedPreSettlement?.calculationType === '정률' ? (props.confirmedPreSettlement.rate).toString() : currencyFormat(props.confirmedPreSettlement?.amount ?? 0) ?? ''}
                inputProps={{
                  style: {
                    textAlign: 'right'
                  }
                }}
                InputProps={{
                  endAdornment:<InputAdornment position="end">{props.confirmedPreSettlement?.calculationType === '정률' ? '%' : '원' ?? '%'}</InputAdornment>
                }}
                onChange={(event) => {
                  const input = event.target.value === '' ? '0' : event.target.value.replace(/\,/g, '');
                  if(!props.confirmedPreSettlement ) {
                    onChangeValue('rate', input === '' ? 0 : parseInt(input))
                    return;
                  }

                  if(props.confirmedPreSettlement.calculationType === '정률') {
                    onChangeValue('rate', input === '' ? 0 : parseInt(input))
                  } else {
                    onChangeValue('amount', input === '' ? 0 : parseInt(input))
                  }
                }}
              />
            </div>

          </td>
        </tr>

        <tr>
          <td colSpan={1} className={'header'}>
            최종 적용 금액
          </td>

          <td colSpan={5}>
            <Typography variant={'subtitle1'} fontWeight={'700'}>
              {getFinalPrice()}원
            </Typography>
          </td>
        </tr>
        </tbody>
      </table>

    </>
  )
}
