import styled from "@emotion/styled"
import { deleteNoticeDetail, getNoticeDetail, updateNoticeDetail } from "apis/noticeAPI"
import Button from "components/Button"
import PopupPage from "components/Popup/PopupPage"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import { isEqual, isNil } from "lodash"
import { DateTime } from "luxon"
import { forwardRef, useImperativeHandle, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { NoticeDetailRequestBodyType } from "types/NoticeDetailRequestBodyType"
import { NoticeDetailResponseType } from "types/NoticeDetailResponseType"
import { NoticeDetailForm } from "./NoticeDetailForm"

const SEARCH_PARAMS_KEY = "noticeId"

const EMPTY_NOTICE_DETAIL_REQUEST_BODY: NoticeDetailRequestBodyType = {
  title: "",
  onDisplay: false,
  notifyDate: DateTime.local().toISODate(),
  startedAt: null,
  endedAt: null,
  body: "",
  htmlBody: "",
}

const mapNoticeDetailToNoticeDetailRequestBody = (
  input: NoticeDetailResponseType | null
): NoticeDetailRequestBodyType =>
  isNil(input)
    ? EMPTY_NOTICE_DETAIL_REQUEST_BODY
    : {
        title: input.title,
        onDisplay: input.onDisplay,
        notifyDate: input.notifyDate,
        startedAt: input.startedAt || null,
        endedAt: input.startedAt || null,
        body: input.body,
        htmlBody: input.htmlBody,
      }

export type NoticeDetailUpdatePopupRefAttributes = {
  open: (noticeId: number) => void
  close: () => void
}

export const NoticeDetailUpdatePopup = forwardRef<NoticeDetailUpdatePopupRefAttributes, { onClose: () => void }>(
  ({ onClose }, ref) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [initialNoticeDetailRequestBody, setInitialNoticeDetailRequestBody] = useState(
      EMPTY_NOTICE_DETAIL_REQUEST_BODY
    )
    const [noticeId, setNoticeId] = useState<number | null>(null)
    const [noticeDetailRequestBody, setNoticeDetailRequestBody] = useState(EMPTY_NOTICE_DETAIL_REQUEST_BODY)

    useMemo(() => {
      return searchParams.has(SEARCH_PARAMS_KEY) ? Number(searchParams.get(SEARCH_PARAMS_KEY)) : null
    }, [searchParams])

    const openPopup = (noticeId: number) => {
      setSearchParams({ [SEARCH_PARAMS_KEY]: noticeId.toString() }, { replace: true })
      setNoticeId(noticeId)
      fetchNoticeDetail(noticeId)
    }
    const closePopup = () => {
      setSearchParams({}, { replace: true })
      setNoticeId(null)
      setInitialNoticeDetailRequestBody(EMPTY_NOTICE_DETAIL_REQUEST_BODY)
      setNoticeDetailRequestBody(EMPTY_NOTICE_DETAIL_REQUEST_BODY)
      onClose()
    }

    const fetchNoticeDetail = async (noticeId: number) => {
      try {
        const noticeDetail = await getNoticeDetail(noticeId)
        const noticeDetailRequestBody = mapNoticeDetailToNoticeDetailRequestBody(noticeDetail)
        setInitialNoticeDetailRequestBody(noticeDetailRequestBody)
        setNoticeDetailRequestBody(noticeDetailRequestBody)
      } catch (error) {
        const errorMessage = (error as any).message || "공지사항을 찾을 수 없습니다."
        toast.error(errorMessage)
        closePopup()
      }
    }

    const handleOnClickDelete = async () => {
      if (isNil(noticeId)) return
      if (!window.confirm("공지사항을 삭제하시겠습니까?")) return

      try {
        await deleteNoticeDetail(noticeId)
        toast.success("삭제 완료")
        closePopup()
      } catch {
        toast.error("삭제 실패")
      }
    }

    const handleOnClickSubmit = async () => {
      if (isNil(noticeId)) return
      if (isNil(noticeDetailRequestBody)) return

      try {
        const noticeDetail = await updateNoticeDetail(noticeId, noticeDetailRequestBody)
        const nextNoticeDetailReqeustBody = mapNoticeDetailToNoticeDetailRequestBody(noticeDetail)
        setInitialNoticeDetailRequestBody(nextNoticeDetailReqeustBody)
        setNoticeDetailRequestBody(nextNoticeDetailReqeustBody)
        toast.success("수정 완료")
        closePopup()
      } catch {
        toast.error("수정 실패")
      }
    }

    useImperativeHandle(ref, () => ({ open: openPopup, close: closePopup }))

    return (
      <PopupPage isOpen={!isNil(noticeId)}>
        <PopupPageChildrenWrapper>
          <PopupPageTitle>공지사항 수정</PopupPageTitle>
          <PopupPageScrollContainer>
            <NoticeDetailForm
              initialState={initialNoticeDetailRequestBody}
              state={noticeDetailRequestBody}
              setState={setNoticeDetailRequestBody}
            />
          </PopupPageScrollContainer>
          <PopupPageFooter>
            <ButtonListWrapper>
              <ButtonListItemWrapper>
                <Button buttonStyle="red-fill" onClick={handleOnClickDelete}>
                  삭제
                </Button>
              </ButtonListItemWrapper>
              <ButtonListItemWrapper>
                <Button
                  buttonStyle="blue-fill"
                  disabled={isEqual(initialNoticeDetailRequestBody, noticeDetailRequestBody)}
                  onClick={handleOnClickSubmit}
                >
                  저장
                </Button>
              </ButtonListItemWrapper>
              <ButtonListItemWrapper>
                <Button buttonStyle="black-border" onClick={closePopup}>
                  닫기
                </Button>
              </ButtonListItemWrapper>
            </ButtonListWrapper>
          </PopupPageFooter>
        </PopupPageChildrenWrapper>
      </PopupPage>
    )
  }
)

const PopupPageChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const ButtonListWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
const ButtonListItemWrapper = styled.div`
  :not(:last-child) {
    margin-right: 10px;
  }
`
