import styled from "@emotion/styled"
import * as monaco from "monaco-editor"

import { useEffect, useRef } from "react"
import { isNil } from "utils/validationUtils"

type Props = {
  initialContent: string
  changeMarkdownState: (value: any) => void
}

export const MarkdownEditor = ({ initialContent, changeMarkdownState }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>()

  useEffect(() => {
    if (containerRef.current instanceof HTMLElement) {
      editorRef.current = monaco.editor.create(containerRef.current, {
        renderWhitespace: "all",
        value: initialContent,
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
      })
    }
    return () => {
      editorRef.current?.dispose()
    }
  }, [initialContent])

  useEffect(() => {
    const editor = editorRef.current
    const editorTextModel = editor?.getModel()

    if (isNil(editor) || isNil(editorTextModel)) {
      return
    }

    editorTextModel.onDidChangeContent(() => {
      changeMarkdownState(editorTextModel.getValue())
    })
  }, [changeMarkdownState])

  return (
    <>
      <MarkdownInstructLink>
        <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
          마크다운 문법 가이드
        </a>
      </MarkdownInstructLink>

      <MarkdownContainer ref={containerRef} />
    </>
  )
}

const MarkdownContainer = styled.div`
  background: #ffffff;

  border: 1px solid #ced2d6;
  border-radius: 4px;
  flex: 1;
  height: 746px;
  overflow: hidden;
`

const MarkdownInstructLink = styled.div`
  width: 100%;
  margin: 16px 0;
  font-size: 15px;
  text-align: end;
  margin: 16px 0;

  a {
    text-decoration: none;
    color: #3b464f;
  }
`
