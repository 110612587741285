import { FunctionComponent } from "react"

export const DetailModelImageInput: FunctionComponent<{
  thumbnailUrl: string | null
  onChangeThumbanilFile: (fileList: FileList) => void
}> = ({ thumbnailUrl, onChangeThumbanilFile }) => {
  return (
    <label style={{ display: "inline-flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
      <img
        style={{ width: 260, height: 260, objectFit: "contain" }}
        src={thumbnailUrl || require("./DetailModelImageInputPlaceholder.png")}
        alt="상세 모델 이미지"
      />
      <div style={{ height: 16 }} />
      이미지 업로드
      <input
        style={{ display: "none" }}
        accept="image/*"
        type="file"
        onChange={(event) => {
          const fileList = event.target.files
          if (fileList) {
            onChangeThumbanilFile(fileList)
          }
          event.target.value = ""
        }}
      />
    </label>
  )
}
