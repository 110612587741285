import { Grid, Paper, Tab } from "@mui/material"
import { useEffect, useReducer } from "react"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import ShowcaseTab from "./ShowcaseTab"
import ShowroomTab from "./ShowroomTab"
import { ShowroomType } from "types/ShowroomType"
import { ShowcaseType } from "types/ShowcaseType"
import { getShowrooms } from "../../apis/showroomAPI"
import { ProductType } from "types/ProductType"
import { getShowcases } from "../../apis/showcaseAPI"
import { ShowroomPageActionCode } from "./showroomPageActionCode"
import _ from "lodash"
import { replaceItem } from "../../utils/collectionUtils"
import AddShowroomPopup from "./ShowroomTab/AddShowroomPopup"
import EditShowroomPopup from "./ShowroomTab/EditShowroomPopup"
import { useLocation, useSearchParams } from "react-router-dom"

export interface ShowroomState {
  tab: string
  showrooms: ShowroomType[]
  showcases: ShowcaseType[]
  products: ProductType[]
  selectionShowroomIds: number[]
  selectionShowcaseIds: number[]
  refresh: boolean

  openAddShowroomPopup: boolean
  openEditShowroomPopup: boolean
  selectedShowroom?: ShowroomType | null

  openAddShowcasePopup: boolean
  openEditShowcasePopup: boolean
  editShowcase?: ShowcaseType | null
}
const initState: ShowroomState = {
  tab: "showroom",
  showrooms: [],
  selectionShowroomIds: [],
  selectionShowcaseIds: [],
  showcases: [],
  products: [],
  refresh: true,
  openAddShowroomPopup: false,
  openEditShowroomPopup: false,
  selectedShowroom: null,
  openAddShowcasePopup: false,
  openEditShowcasePopup: false,
  editShowcase: null,
}

export interface ShowroomAction {
  type: ShowroomPageActionCode
  payload?: any
}

function reducer(state: ShowroomState, action: ShowroomAction): ShowroomState {
  switch (action.type) {
    case ShowroomPageActionCode.CHANGE_TAB:
      return { ...state, tab: action.payload }
    case ShowroomPageActionCode.FETCH_SHOWROOMS:
      return { ...state, showrooms: action.payload }
    case ShowroomPageActionCode.FETCH_SHOWCASES:
      return { ...state, showcases: action.payload }
    case ShowroomPageActionCode.SELECTION_SHOWROOM_IDS:
      return { ...state, selectionShowroomIds: action.payload }
    case ShowroomPageActionCode.SELECTION_SHOWCASE_IDS:
      return { ...state, selectionShowcaseIds: action.payload }
    case ShowroomPageActionCode.UPDATE_SHOWROOM:
      return {
        ...state,
        showrooms: replaceItem(
          state.showrooms,
          (item: { id: number }) => item.id === action.payload.id,
          action.payload
        ),
      }
    case ShowroomPageActionCode.UPDATE_SHOWCASE:
      return {
        ...state,
        showcases: replaceItem(
          state.showcases,
          (item: { id: number }) => item.id === action.payload.id,
          action.payload
        ),
      }
    case ShowroomPageActionCode.UPDATE_PRODUCT:
      return {
        ...state,
        products: replaceItem(state.products, (item: { id: number }) => item.id === action.payload.id, action.payload),
      }

    case ShowroomPageActionCode.OPEN_ADD_SHOWROOM_POPUP:
      return { ...state, openAddShowroomPopup: true }
    case ShowroomPageActionCode.CLOSE_ADD_SHOWROOM_POPUP:
      return { ...state, openAddShowroomPopup: false }

    case ShowroomPageActionCode.OPEN_EDIT_SHOWROOM_POPUP:
      return { ...state, openEditShowroomPopup: true, selectedShowroom: action.payload }
    case ShowroomPageActionCode.CLOSE_EDIT_SHOWROOM_POPUP:
      return { ...state, openEditShowroomPopup: false }
  }
  return state
}
function createRequestParams(state: ShowroomState) {
  let params: { showroomId?: number[]; showcaseId?: number[] } = {}
  if (state.selectionShowroomIds && state.selectionShowroomIds.length > 0) {
    params["showroomId"] = state.selectionShowroomIds
  }

  if (state.selectionShowcaseIds && state.selectionShowcaseIds.length > 0) {
    params["showcaseId"] = state.selectionShowcaseIds
  }

  return params
}

const stateToQuery = ({ tab, selectionShowcaseIds, selectionShowroomIds }: ShowroomState) => {
  return {
    tab,
    selectionShowcaseIds: String(selectionShowcaseIds),
    selectionShowroomIds: String(selectionShowroomIds),
  }
}

const Showroom = () => {
  const [state, dispatch] = useReducer(reducer, initState)
  const [searchParams, setSearchParams] = useSearchParams()
  let { search } = useLocation()

  const fetchShowrooms = () => {
    getShowrooms().then((showrooms) => dispatch({ type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: showrooms }))
  }
  const fetchShowcases = (params?: { [key: string]: any }) => {
    getShowcases(params).then((showcases) =>
      dispatch({ type: ShowroomPageActionCode.FETCH_SHOWCASES, payload: showcases })
    )
  }

  useEffect(() => {
    fetchShowrooms()
    fetchShowcases()
    dispatch({
      type: ShowroomPageActionCode.CHANGE_TAB,
      payload: new URLSearchParams(search).get("tab") ? new URLSearchParams(search).get("tab") : "showroom",
    })
  }, [])

  useEffect(() => {
    setSearchParams(stateToQuery(state))
  }, [state.tab, state.selectionShowcaseIds, state.selectionShowroomIds, setSearchParams, state])

  useEffect(() => {
    fetchShowcases(createRequestParams(_.omit(state, "showroomId")))
  }, [state, state.selectionShowroomIds])

  const handleShowcaseIdsSelection = (showcaseIds: number[]) => {
    dispatch({ type: ShowroomPageActionCode.SELECTION_SHOWCASE_IDS, payload: showcaseIds })
  }

  const handleShowcaseChanged = () => {
    fetchShowcases(createRequestParams(_.omit(state, "showroomId")))
  }

  return (
    <Grid container spacing={2}>
      <AddShowroomPopup state={state} dispatch={dispatch} />
      <EditShowroomPopup state={state} dispatch={dispatch} />

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <TabContext value={state.tab}>
            <TabList
              onChange={(_e, value) => {
                dispatch({ type: ShowroomPageActionCode.CHANGE_TAB, payload: value })
                setSearchParams({ ...searchParams, tab: value })
              }}
            >
              <Tab value={"showroom"} label={`쇼룸 (${state.showrooms.length})`}></Tab>
              <Tab value={"showcase"} label={`쇼케이스 (${state.showcases.length})`}></Tab>
            </TabList>
            <TabPanel value={"showroom"}>
              {" "}
              <ShowroomTab state={state} dispatch={dispatch} />{" "}
            </TabPanel>
            <TabPanel value={"showcase"}>
              {" "}
              <ShowcaseTab
                showcases={state.showcases}
                selectionShowcaseIds={state.selectionShowcaseIds}
                handleSelection={handleShowcaseIdsSelection}
                handleShowcaseChanged={handleShowcaseChanged}
              />{" "}
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Showroom
