import { Box, Button, Paper, Stack } from "@mui/material"
import { useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { deleteProductSearchKeyword, getProductSearchKeywords, postProductSeacrhKeyord } from "apis/productAPI"
import { SearchKeywordType } from "types/SearchKeywordType"
import { useMount } from "react-use"
import { SearchKeywordReorderPopup } from "./SearchKeywordReorderPopup"
import { SearchKeywordDeleteConfirmPopup } from "./SearchKeywordDeleteConfirmPopup"
import { isNil } from "utils/validationUtils"
import { toast } from "react-toastify"
import { SearchKeywordCreatePopup } from "./SearchKeywordCreatePopup"

const SearchKeywordTab = () => {
  const [selectedKeyword, setSelectedKeyword] = useState<SearchKeywordType | undefined>(undefined)
  const [isOpenDeleteKeywordPopup, setIsOpenDeleteKeywordPopup] = useState<boolean>(false)
  const [isOpenCreateKeywordPopup, setIsOpenCreateKeywordPopup] = useState<boolean>(false)
  const [isOpenReorderPopup, setIsOpenReorderPopup] = useState<boolean>(false)
  const [keywordList, setKeywordList] = useState<SearchKeywordType[]>([])

  const tableColumns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "priority", headerName: "노출 순서", width: 100 },
    { field: "type", headerName: "타입", width: 100 },
    { field: "keyword", headerName: "키워드", flex: 1, sortable: false },
    { field: "count", headerName: "노출 상품 수", width: 150 },
    {
      type: "actions",
      field: "actions",
      headerName: "",
      width: 200,
      sortable: false,
      getActions: (value: any) => [
        <Button variant="outlined" size={"medium"} color="error" onClick={() => selecteKeywordToDelete(value.row)}>
          키워드 삭제
        </Button>,
      ],
    },
  ]

  const getSearchKeyword = () => {
    getProductSearchKeywords().then((result) => {
      setKeywordList(result)
    })
  }

  useMount(() => {
    getSearchKeyword()
  })

  const closeDeleteKeywordPopup = () => {
    setIsOpenDeleteKeywordPopup(false)
    setSelectedKeyword(undefined)
  }

  const closeReorderKeywordPopup = () => {
    setIsOpenReorderPopup(false)
    getSearchKeyword()
  }

  const addNewKeyword = (keyword: string) => {
    postProductSeacrhKeyord({ keyword }).then((response) => {
      toast.success("키워드가 추가되었습니다.")
      setKeywordList((prev) => prev.concat(response))
      setIsOpenCreateKeywordPopup(false)
    })
  }

  const selecteKeywordToDelete = (searchKeyword: SearchKeywordType) => {
    setSelectedKeyword(searchKeyword)
    setIsOpenDeleteKeywordPopup(true)
  }

  const deleteKeyWord = () => {
    if (isNil(selectedKeyword)) return

    deleteProductSearchKeyword(selectedKeyword.id).then(() => {
      toast.success("키워드가 삭제되었습니다.")
      setKeywordList((prev) => prev.filter((value) => value.id !== selectedKeyword.id))
      closeDeleteKeywordPopup()
    })
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Button variant={"outlined"} size={"medium"} onClick={() => setIsOpenCreateKeywordPopup(true)}>
              키워드 추가
            </Button>
            <Button variant={"contained"} size={"medium"} onClick={() => setIsOpenReorderPopup(true)}>
              순서 변경
            </Button>
          </Stack>

          <Box sx={{ height: 720, width: "100%", marginTop: "10px" }}>
            <DataGrid<SearchKeywordType>
              getRowId={(row) => row.id}
              columns={tableColumns}
              rows={keywordList}
              getRowHeight={() => 70}
            />
          </Box>
        </Stack>
      </Paper>
      {isOpenReorderPopup && <SearchKeywordReorderPopup closePopup={closeReorderKeywordPopup} />}
      {isOpenDeleteKeywordPopup && !!selectedKeyword && (
        <SearchKeywordDeleteConfirmPopup
          keyword={selectedKeyword.keyword}
          handleConfirm={deleteKeyWord}
          handleClose={closeDeleteKeywordPopup}
        />
      )}
      {isOpenCreateKeywordPopup && (
        <SearchKeywordCreatePopup
          handleConfirm={addNewKeyword}
          handleClose={() => setIsOpenCreateKeywordPopup(false)}
        />
      )}
    </>
  )
}

export default SearchKeywordTab
