import { httpDelete, httpGet, httpPut } from "../utils/httpAPI"
import { TranslationTableType } from "../types/TranslationTableType"
import { TranslationTableDetailType } from "../types/TranslationTableDetailType"
import { PageType } from "types/PageType"
import { TranslationKeyType } from "../types/TranslationKeyType"
import { TranslationTableColumnType } from "../types/TranslationTableColumnType"

export interface TableParams {
  schema: "viver"
}

export interface ColumnParams {
  tableName: string
}

export interface TableDetailParams {
  schema: "viver"
  tableName: string
  page: number
  size: number
}

export interface SetTableColumnTargetParams {
  schema: "viver"
  tableName: string
  columnNameList: string[]
}

export interface SetTableColumnAliasParams {
  tableName: string
  columnName: string
  tableNameAlias: string
  columnNameAlias: string
}

export interface KeyParams {
  table: string
  column: string | null
  key: string
  language: "ko" | "en" | "ja" | "zh" | null
  content: string
  translationContent: string
  page: number
  size: number
}

export interface SetManualTranslationParams {
  languageCode: "ko" | "en" | "ja" | "all"
  i18nValue: string
}

export const getTranslationTables: (params: TableParams) => Promise<TranslationTableType[]> = (params) =>
  httpGet("/translation/tables", params)

export const getTranslationColumns: (params: ColumnParams) => Promise<TranslationTableColumnType[]> = (params) =>
  httpGet("/translation/columns", params)

export const getTranslationManagedTables: (params: TableParams) => Promise<TranslationTableType[]> = (params) =>
  httpGet("/translation/managed-tables", params)

export const getTranslationTableDetails: (
  params: TableDetailParams
) => Promise<PageType<TranslationTableDetailType>> = (params) => httpGet("/translation/table/details", params)

export const setTranslationTableColumnSave: (params: SetTableColumnTargetParams) => Promise<void> = (params) =>
  httpPut("/translation/column/save", params)

export const setTranslationTableColumnDelete: (params: SetTableColumnTargetParams) => Promise<void> = (params) =>
  httpPut("/translation/column/delete", params)

export const setTranslationTableAlias: (params: SetTableColumnAliasParams) => Promise<void> = (params) =>
  httpPut("/translation/column/alias", params)

export const getTranslationKeys: (params: KeyParams) => Promise<PageType<TranslationKeyType>> = (params) =>
  httpGet("/translation/keys", params)

export const setManualTranslation: (id: number, params: SetManualTranslationParams) => Promise<void> = (id, params) =>
  httpPut(`/translation/manual-translation/${id}`, params)
