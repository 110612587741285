import PenaltyListTable from "./PenaltyListTable"
import { usePenaltyList } from "./usePenaltyList"
import styled from "@emotion/styled"
import Button from "components/Button"
import Counters from "pages/SellOrder/Counters"
import { useState } from "react"
import CheckBox from "components/Checkbox"
import PenaltyDetailPopup from "pages/Penalty/PenaltyDetailPopup"
import CancelShippingRequestPopup from "../CancelShippingRequestPopup"
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/lab"

export type RequestCancelShippingType = {
  id?: number
  orderId: string
  productId: number
  productTitle: string
  quantity?: number
  weight?: number
}

const searchOptionList = [
  { value: "sellerId", label: "판매자ID" },
  { value: "sellerName", label: "판매자명" },
]

const PenaltyListPage = () => {
  const [selectedShippingList, setSelectedShippingList] = useState<RequestCancelShippingType[]>([])
  const [selectedPenaltyOrderId, setSelectedPenaltyOrderId] = useState<string | undefined>()
  const [isOpenShippingRequestPopup, setIsOpenShippingRequestPopup] = useState(false)
  const {
    isFilteringShippingRequested,
    setIsFilteringShippingRequested,
    currentStatus,
    countList,
    penaltyList,
    paginationParams,
    searchDateFilter,
    setSearchDateFilter,
    searchKey,
    searchValue,
    setSearchKey,
    setSearchValue,
    setIsRefresh,
    changePage,
    togglePenaltyForShipping,
    toggleAllPenaltyForShipping,
    selectedPenaltyForShipping,
    setPaginationParams,
    refreshList,
  } = usePenaltyList()
  const numericFields = ["sellerId"]

  const selectPaneltyDetail = (penaltyId: string) => {
    setSelectedPenaltyOrderId(penaltyId)
  }

  const handleClickShippingButton = () => {
    const parsedShippingInfo: RequestCancelShippingType[] = selectedPenaltyForShipping.map((penalty) => ({
      ...penalty.sellOrder.cancelShipping,
      orderId: penalty.sellOrder.id,
      productId: penalty.sellOrder.product.id,
      productTitle: penalty.sellOrder.product.title,
    }))
    setSelectedShippingList(parsedShippingInfo)
    setIsOpenShippingRequestPopup(true)
  }

  return (
    <Grid container spacing={2}>
      <CancelShippingRequestPopup
        open={isOpenShippingRequestPopup}
        selectedShipping={selectedShippingList}
        handleClose={() => {
          setIsOpenShippingRequestPopup(false)
          refreshList()
        }}
      />

      {selectedPenaltyOrderId && (
        <PenaltyDetailPopup
          selectedPenaltyOrderId={selectedPenaltyOrderId}
          closePopup={() => setSelectedPenaltyOrderId(undefined)}
        />
      )}
      <Grid item xs={12}>
        <Counters params={countList} selectedLabel={currentStatus} />
      </Grid>
      <Grid item xs={12}>
        <ShippingRequestNeededWrapper>
          <ShippingRequestDisplayWrapper>
            <CheckBox
              id={"isFilteringShippingRequested"}
              checked={isFilteringShippingRequested}
              onChange={() => setIsFilteringShippingRequested(!isFilteringShippingRequested)}
            />
            <label htmlFor="isFilteringShippingRequested" style={{ marginLeft: 10 }}>
              반송 대기건만 보기
            </label>
          </ShippingRequestDisplayWrapper>
          {isFilteringShippingRequested && (
            <ButtonWrapper>
              <Button onClick={handleClickShippingButton}>반송 요청</Button>
            </ButtonWrapper>
          )}
        </ShippingRequestNeededWrapper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select
                      value={searchKey}
                      onChange={(event) => {
                        setSearchKey(event.target.value as any)
                        setSearchValue("")
                      }}
                    >
                      {searchOptionList.map((optionItem) => (
                        <MenuItem key={optionItem.value} value={optionItem.value}>
                          {optionItem.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={searchValue}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsRefresh((prevState) => !prevState)
                        setPaginationParams((prev) => ({ ...prev, number: 0 }))
                      }
                    }}
                    onChange={(params) => {
                      const value = numericFields.includes(searchKey || "")
                        ? params.target.value.replace(/[^0-9]/g, "")
                        : params.target.value

                      setSearchValue(value)
                    }}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} sx={{ lineHeight: "45px" }}>
                  <Label>패널티 발생일</Label>
                  <DesktopDatePicker
                    label="시작일"
                    inputFormat="yyyy-MM-dd"
                    value={searchDateFilter.startDate ?? null}
                    onChange={(params) => {
                      setSearchDateFilter((prevState) => ({ ...prevState, startDate: params }))
                      setPaginationParams((prev) => ({ ...prev, number: 0 }))
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                  <DesktopDatePicker
                    label="종료일"
                    inputFormat="yyyy-MM-dd"
                    value={searchDateFilter.endDate ?? null}
                    onChange={(params) => {
                      setSearchDateFilter((prevState) => ({ ...prevState, endDate: params }))
                      setPaginationParams((prev) => ({ ...prev, number: 0 }))
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sx={{ pt: 1 }}>
            <PenaltyListTable
              selectPaneltyDetail={selectPaneltyDetail}
              toggleAllPenaltyForShipping={toggleAllPenaltyForShipping}
              togglePenaltyForShipping={togglePenaltyForShipping}
              isCheckboxHidden={!isFilteringShippingRequested}
              penaltyList={penaltyList}
              changePage={changePage}
              paginationParams={paginationParams}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PenaltyListPage

const ButtonWrapper = styled.div`
  width: 200px;
`

const ShippingRequestNeededWrapper = styled.div`
  width: 100%;
  font-weight: 700;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

const ShippingRequestDisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Label = styled.div`
  font-size: 16px;
  color: #3b464f;
`
