import { commonResourceUpload } from "apis/commonResourceAPI"
import { createMdPick, getMdPickDetail, updateMdPickDetail } from "apis/mdPickAPI"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { MdPickCreateBodyType, MdPickType } from "types/MdPickType"

import { isNil } from "utils/validationUtils"
import { ThemeModeTypeCode } from "../../../../types/ShopHeroBannerType"
import { upload } from "../../../../apis/resourceAPI"

type MdPickStateType = Omit<MdPickType, "id">

const initCurationState: MdPickStateType = {
  title: "",
  products: [],
  onDisplay: true,
  priority: 0,
  curationType: "수동",
  imageUrl: null,
  minQuantity: undefined,
  maxQuantity: undefined,
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().plus({ year: 1 }).toISO(),
  createdAt: DateTime.now().toISO(),
  themeModeType: ThemeModeTypeCode.DARK,
  title1:  "",
  title2:  "",
  title3:  "",
  showDomesticUser: true,
  showForeignUser: false
}

export const useMdPickEditorData = (curationId: number | null) => {
  const [formState, setFormState] = useState<MdPickStateType>(initCurationState)

  const handleOnChangeState = (partialState: Partial<MdPickStateType>) => {
    setFormState((prevState) => ({ ...prevState, ...partialState }))
  }

  const handleChangeCurationType = (type: MdPickStateType["curationType"]) => {
    setFormState((prevState) => ({ ...prevState, curationType: type }))
  }

  const handleChangeThemeModeType = (type: MdPickStateType["themeModeType"]) => {
    setFormState((prevState) => ({ ...prevState, themeModeType: type }))
  }

  const handleChangeImage = (image:any) => {
    upload(image).then((result) => {
      handleOnChangeState({ imageUrl: result.url })
    })
  }

  const handleDeleteImage = () => {
    handleOnChangeState({ imageUrl: null})
  }

  const onAddImage = useCallback(async (fileList: FileList) => {
    const { url } = await commonResourceUpload(fileList)

    handleOnChangeState({ imageUrl: url })
  }, [])

  const callApiByEditorType = useCallback(
    async (formState: MdPickStateType) => {
      const formattedFormState: MdPickCreateBodyType = {
        ...formState,
        products: formState.products.map((product) => ({
          onDisplay: product.onDisplay,
          priority: product.priority,
          product: { id: product.product.id },
        })),
      }
      if (curationId) {
        await updateMdPickDetail(curationId, formattedFormState)
      } else {
        await createMdPick(formattedFormState)
      }
    },
    [curationId]
  )

  const fetchCurationDetail = useCallback((curationId: number) => {
    getMdPickDetail(curationId).then((curation) => {
      setFormState({
        title: curation.title,
        products: curation.products,
        onDisplay: curation.onDisplay,
        priority: curation.priority,
        startedAt: curation.startedAt,
        endedAt: curation.endedAt,
        createdAt: curation.createdAt,
        curationType: curation.curationType,
        imageUrl: curation.imageUrl,
        minQuantity: curation.minQuantity,
        maxQuantity: curation.maxQuantity,
        themeModeType: curation.themeModeType,
        title1: curation.title1,
        title2: curation.title2,
        title3: curation.title3,
        showDomesticUser: curation.showDomesticUser,
        showForeignUser: curation.showForeignUser
      })
    })
  }, [])

  const checkIfIsValidPayload = (formState: MdPickStateType) => {
    if (isNil(formState)) {
      return false
    }

    if (isEmpty(formState.title)) {
      toast.error("제목을 입력해주세요")
      return false
    }

    if (isEmpty(formState.imageUrl)) {
      toast.error("상단 이미지를 등록해주세요")
      return false
    }

    if (isNil(formState.minQuantity)) {
      toast.error("최소 노출 갯수를 설정해주세요.")
      return false
    }

    if (isNil(formState.maxQuantity)) {
      toast.error("최대 노출 갯수를 설정해주세요.")
      return false
    }

    return true
  }

  useEffect(() => {
    if (curationId) {
      fetchCurationDetail(curationId)
    } else {
      setFormState(initCurationState)
    }
  }, [curationId, fetchCurationDetail])

  return {
    handleOnChangeState,
    handleChangeCurationType,
    handleChangeThemeModeType,
    handleChangeImage,
    handleDeleteImage,
    callApiByEditorType,
    onAddImage,
    checkIfIsValidPayload,
    formState,
  }
}
