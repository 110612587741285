import { DataGrid, GridColumnHeaderParams, GridSortItem, GridToolbarContainer } from "@mui/x-data-grid"
import React, { useState } from "react"
import { DiagnosisState } from "./index"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { numberWithCommas, truncate } from "../../utils/NumberUtils"
import { Button } from "@mui/material"
import { DiagnosisResultCode, DiagnosisStatusCode, DiagnosisType } from "types/DiagnosisType"
import { toDateStr, toDateTimeStr, toTimeStr } from "../../utils/datetimeUtil"

function createColumns(handleEditButtonClick: Function, handleViewButtonClick: Function): any[] {

    const openDiagnosisPopup = (row: DiagnosisType) => {
        handleEditButtonClick(row)
        // @ts-ignore
        // switch (row?.productDiagnosis?.status) {
        //     case "진단서 작성 대기":
        //     case "진단서 작성중":
        //         handleEditButtonClick(row)
        //         break;
        //     case "진단서 작성 완료":
        //     case "진단서 확정":
        //         handleViewButtonClick(row)
        //         break;
        // }
    }
    const getColorByStatus = (status: DiagnosisStatusCode | SellOrderStatusCode) => {
        switch (status) {
            case SellOrderStatusCode.판매_포기:
            case SellOrderStatusCode.페널티_대기:
            case SellOrderStatusCode.판매_불합격:
            case DiagnosisStatusCode.진단서_작성_대기:
                return 'error'
            case SellOrderStatusCode.입고중:
            case DiagnosisStatusCode.진단서_작성중:
                return 'warning'
            case DiagnosisStatusCode.진단서_확정:
            case SellOrderStatusCode.입고_완료:
                return 'success'
            default:
                return 'info'
        }
    }

    // @ts-ignore
    return [
        {
            field: 'id',
            headerName: "판매주문번호",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 130,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{ lineHeight: 1 }}>판매주문 번호 <br /> (상품 번호)</p>),
            valueGetter: (params: any) => `${params.row.id}`,
            renderCell: (params: any) => (
                <div style={{ textAlign: "left" }}>
                    <p><span style={{ fontWeight: "bold" }}>{params.row.id}</span>
                        <br /><span style={{ color: '#aaa' }}>{params.row.product?.id}</span></p>
                </div>
            )
        },
        {
            field: 'productId',
            headerName: "상품번호",
            hide: true,
            valueGetter: (params: any) => `${params.row.product?.id}`,
        },
        {
            field: 'status', headerName: "진단상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: any) => {
                return params.row.productDiagnosis?.status
            },
            renderCell: (params: any) => (
                <div>
                    <Button size={"small"} variant={"outlined"} style={{ fontWeight: "bold" }}
                        onClick={() => { openDiagnosisPopup(params.row) }}
                        color={getColorByStatus(params.row.productDiagnosis?.status)}>{params.row.productDiagnosis?.status}</Button>
                </div>
            )
        },
        {
            field: 'sellOrderStatus', headerName: "진행상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: any) => params.row.sellOrderStatus,
            renderCell: (params: any) => (
                <div>
                    <Button size={"small"} variant={"outlined"} style={{ fontWeight: "bold" }}
                        color={getColorByStatus(params.row.sellOrderStatus)}>{params.row.sellOrderStatus}</Button>
                </div>
            )
        },
        {
            field: 'userNotified', headerName: "결과전달", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 70,
            valueGetter: (params: any) => {
                return !!params.row.productDiagnosis?.notifiedAt
            },
            renderCell: (params: any) => (
                !!params.row.productDiagnosis?.notifiedAt
                    ? <p style={{ color: "#2e7d32" }}>완료</p>
                    : <p style={{ fontWeight: "bold", color: "#d32f2f" }}>필요</p>
            )
        },
        // {field: 'saleType', headerName: "판매방식", editable: false, sortable: false,
        //     minWidth: 70, maxWidth: 70,
        //     valueGetter: (params: any) => `${params.row.product?.saleType}`,
        // },
        {
            field: 'result', headerName: "진단결과", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 90,
            valueGetter: (params: any) => {
                return `${params.row.productDiagnosis?.result || '-'}`
            },
            renderCell: (params: any) => (
                params.row.productDiagnosis?.result == DiagnosisResultCode.보류
                    ? <p style={{ fontWeight: "bold", color: "#d32f2f" }}>{params.row.productDiagnosis?.result}</p>
                    : <p>{params.row.productDiagnosis?.result}</p>
            )
        },
        {
            field: "globalSale",
            headerName: "해외판매",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${params.row.product?.globalSale ? "동의" : "미동의"}`,
            renderCell: (params: any) => (
              <div>
                  <Button size={"small"} variant={"text"} color={params.row.product?.globalSale ? "success" : "error"}>
                      {params.row.product?.globalSale ? "동의" : "미동의"}
                  </Button>
              </div>
            ),
        },
        {
            field: 'sellerId', headerName: "판매자 ID", editable: false, sortable: false,
            flex: 1,
            minWidth: 90,
            valueGetter: (params: any) => `${params.row.seller.id}`,
        },
        {
            field: 'title', headerName: "상품명", editable: false, sortable: false,
            flex: 1,
            minWidth: 220,
            valueGetter: (params: any) => `${params.row.product?.title}`,
            renderCell: (params: any) => (
                <div>
                    <p><span style={{ fontWeight: "bold" }}>{params.row.product?.title}</span>
                        <br /><span style={{ color: '#aaa' }}>{params.row.product?.titleSecondary}</span></p>
                </div>
            )
        },
        {
            field: 'fullRefNo', headerName: "Ref No.", editable: false, sortable: false,
            flex: 1,
            minWidth: 160,
            valueGetter: (params: any) => `${params.row.product?.detailModel?.fullRefNo}`,
        },
        {
            field: 'diagnoser', headerName: "담당자", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 100,
            valueGetter: (params: any) => {
                return `${params.row.productDiagnosis?.diagnosedBy?.name || '-'}`
            }
        },
        {
            field: 'estimatedPrice', headerName: "최적판매가", editable: false, sortable: false,
            align: 'right',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: any) => {
                const price = params.row.productDiagnosis?.viverPrices?.finalPrice
                return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
            }
        },
        /*
          {
              field: 'sellerName',
              headerName: "판매자 정보",
              editable: false,
              sortable: false,
              flex: 1,
              minWidth: 120,
              valueGetter: (params: any) => `${params.row.seller?.name}`,
              renderCell: (params: any) => (
                  <div>
                      <p>{params.row.seller?.name}<br/>
                          {params.row.seller?.phone}</p>
                  </div>
              )
          },
          {
              field: 'phone',
              headerName: "전화번호",
              hide: true,
              valueGetter: (params: any) => `${params.row.seller?.phone}`,
          },
          {
              field: 'buyerName',
              headerName: "구매자 정보",
              editable: false,
              sortable: false,
              flex: 1,
              minWidth: 120,
              valueGetter: (params: any) => `${params.row.buyOrder?.buyer?.name}`,
              renderCell: (params: any) => (
                  <div>
                      <p>{params.row.buyOrder?.buyer?.name}<br/>
                          {params.row.buyOrder?.buyer?.phone}</p>
                  </div>
              )
          },
          {
              field: 'phone',
              headerName: "전화번호",
              hide: true,
              valueGetter: (params: any) => `${params.row.buyOrder?.buyer?.phone}`,
          },*/
        {
            field: 'acceptedAt',
            headerName: "판매신청일",
            editable: false,
            sortable: true,
            flex: 1,
            minWidth: 80,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{ lineHeight: 1 }}>판매<br />신청일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => (
                <div style={{ textAlign: "center" }}>
                    <p>{toDateStr(params.row.acceptedAt, false)}<br />
                        {toTimeStr(params.row.acceptedAt, true)}</p>
                </div>
            )
        },
        {
            field: 'waitingDiagnosisAt',
            headerName: "입고확인일",
            editable: false,
            sortable: true,
            flex: 1,
            minWidth: 80,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{ lineHeight: 1 }}>입고<br />확인일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => (
                <div style={{ textAlign: "center" }}>
                    <p>{toDateStr(params.row.waitingDiagnosisAt, false)}<br />
                        {toTimeStr(params.row.waitingDiagnosisAt, true)}</p>
                </div>
            )
        },
        {
            field: 'notifiedAt',
            headerName: "안내완료일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{ lineHeight: 1 }}>안내<br />완료일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => (
                <div style={{ textAlign: "center" }}>
                    <p>{toDateStr(params.row.productDiagnosis?.notifiedAt, false)}<br />
                        {toTimeStr(params.row.productDiagnosis?.notifiedAt, true)}</p>
                </div>
            )
        },
    ]
}

type DiagnosisProps = {
    state: DiagnosisState,
    dispatch: any,
    fetch: Function,
    components: any[],
}
const initSort: GridSortItem[] = [{ field: 'waitingDiagnosisAt', sort: 'desc' }]

const DiagnosisTable: React.FC<DiagnosisProps> = ({ state, dispatch, fetch, components }) => {
    const [sort, setSort] = useState<GridSortItem[]>(initSort)

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
                {components}
            </GridToolbarContainer>
        );
    }

    const handleSelection = (ids: any[]) => { dispatch({ type: 'SELECTION_DIAGNOSIS_IDS', payload: ids as string[] }) }
    const handleEditButtonClick = (row: SellOrderType) => {
        dispatch({ type: 'SHOW_EDIT_DIAGNOSIS_POPUP', payload: row })
    }
    const handleViewButtonClick = (row: SellOrderType) => {
        dispatch({ type: 'SHOW_VIEW_DIAGNOSIS_POPUP', payload: row })
    }

    function getSortParam(changedModel?: GridSortItem[] | null) {
        const s = changedModel && changedModel.length > 0 ? changedModel : sort
        return `${s[0].field},${s[0].sort}`
    }

    return (
        <>
            <div style={{ height: 1200, width: '100%' }}>
                <DataGrid
                    components={{ Toolbar: CustomToolbar }}
                    getRowId={row => row.id!}
                    columns={createColumns(handleEditButtonClick, handleViewButtonClick)}

                    sortingMode='server'
                    sortModel={sort}
                    onSortModelChange={(change) => {
                        setSort(change)
                        fetch({ page: 0, size: state.sellOrders.pageable?.pageSize || 25, sort: getSortParam(change) })
                    }}

                    pagination
                    {...dispatch.detailModels?.content}
                    paginationMode="server"
                    onPageChange={(page) => {
                        fetch({ page, size: state.sellOrders.pageable?.pageSize || 25, sort: getSortParam() })
                    }}
                    onPageSizeChange={(size) => {
                        fetch({ page: 0, size, sort: getSortParam() })
                    }}

                    page={state.sellOrders.pageable?.pageNumber || 0}
                    pageSize={state.sellOrders.pageable?.pageSize || 25}
                    rowCount={state.sellOrders?.totalElements || 0}
                    rows={state.sellOrders?.content || []}

                    selectionModel={state.selectedIds}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    onSelectionModelChange={handleSelection}
                    loading={state.loading}
                />
            </div>
        </>)
}

// @ts-ignore
export default DiagnosisTable
