import {
  ProvisionalPreSettlementDetailType,
  ProvisionalPreSettlementSearchParams,
  ProvisionalPreSettlementType,
} from "../types/PreSettlementType"
import { PageType } from "../types/PageType"
import { CountType } from "../types/CountType"
import { httpGet, httpPut } from "../utils/httpAPI"

export const getProvisionalPreSettlement:(params:ProvisionalPreSettlementSearchParams)=>Promise<PageType<ProvisionalPreSettlementType>> = async (params)=>{
  return httpGet<PageType<ProvisionalPreSettlementType>>('/pre-settlements/provisional', params)
}

export const getProvisionalPreSettlementDetailInfo :(settlementId:number) => Promise<ProvisionalPreSettlementDetailType> = async (detailId)=>{
  return httpGet<ProvisionalPreSettlementDetailType>(`/pre-settlements/provisional/${detailId}`)
}

export const countProvisionalPreSettlements:()=>Promise<CountType[]> = async ()=>{
  return httpGet<CountType[]>('/pre-settlements/provisional/counts')
}

export const writeProvisionalPreSettlements = (detailId:number, params:{
  estimatedPriceAtRequest:{
    min:number;
    max:number;
    median:number;
  }
  standardPriceForPreSettlement:number;
  calculationType:'정가'|'정률'
  amount:number;
  rate:number;
  preSettlementPrice:number
})=>{
  return httpPut(`/pre-settlements/provisional/${detailId}`,{...params})
}

export const notifyProvisionalPreSettlementsResult = (detailId:number)=>{

  return httpPut(`/pre-settlements/provisional/guide-result?ids=${detailId}`)
}

export const rejectProvisionalPreSettlements = (detailId:number)=>{
  return httpPut(`/pre-settlements/provisional/${detailId}/reject`)
}
