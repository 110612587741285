import styled from "@emotion/styled"
import React, { useCallback, useState } from "react"
import { downloadOfficialModelList, getOfficialModelList, updateOfficialModelList } from "apis/officialModelAPI"
import { OfficialModelPriceSearchBar } from "./SearchBar"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { toast } from "react-toastify"
import { Button } from "@mui/material"
import OfficialModelTable from "./OfficialModelTable"
import { OfficialModelType } from "types/OfficialModelType"
import { OfficialModelResultType } from "types/OfficialModelResultType"
import { GridCellEditCommitParams } from "@mui/x-data-grid"

const FETCH_LIST_COUNT = 20
const initSearchParams = {
  brandSid: "ALL",
  searchFilter: "fullRefNo",
  searchWord: "",
  detailModelExists: "ALL",
  firstMetricsMonth: "ALL",
  lastMetricsMonth: "ALL",
  selectedIds: [],
}

const OfficialModelTab = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })

  const [downloading, setDownloading] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useState<any>(initSearchParams)

  const [officialModelList, setOfficialModelList] = useState<OfficialModelType[]>([])

  const [metricsMonthList, setMetricsMonthListt] = useState<String[]>([])

  const changeBrandSid = useCallback((brandSid: any) => {
    setSearchParams((prev: any) => ({ ...prev, brandSid: brandSid }))
  }, [])

  const changeSearchFilter = useCallback((searchFilter: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchFilter: searchFilter }))
  }, [])

  const changeSearchWord = useCallback((searchWord: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchWord: searchWord }))
  }, [])

  const changeDetailModelExists = useCallback((detailModelExists: any) => {
    setSearchParams((prev: any) => ({ ...prev, detailModelExists: detailModelExists }))
  }, [])


  const changeFirstMetricsMonth = useCallback((firstMetricsMonth: any) => {
    setSearchParams((prev: any) => ({ ...prev, firstMetricsMonth: firstMetricsMonth }))
  }, [])

  const changeLastMetricsMonth = useCallback((lastMetricsMonth: any) => {
    setSearchParams((prev: any) => ({ ...prev, lastMetricsMonth: lastMetricsMonth }))
  }, [])


  const parseSearchParams = (searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      "brandSid": searchParams.brandSid === "ALL" ? null : searchParams.brandSid,
      "detailModelExists": searchParams.detailModelExists === "ALL" ? null : searchParams.detailModelExists,
      "firstMetricsMonth": searchParams.firstMetricsMonth === "ALL" ? null : searchParams.firstMetricsMonth,
      "lastMetricsMonth": searchParams.lastMetricsMonth === "ALL" ? null : searchParams.lastMetricsMonth,
      [searchParams.searchFilter]: searchParams.searchWord,
    }
  }

  const fetchList = useCallback(
    (pageNumber?: number, searchParams?: any) => {
      getOfficialModelList({
        size: FETCH_LIST_COUNT,
        page: pageNumber,
        ...parseSearchParams(searchParams),
      })
        .then((result: OfficialModelResultType) => {
          let pageNum: number = result.list.number ?? 0
          let totalElements: number = result.list.totalElements ?? 0
          let totalPages: number = result.list.totalPages ?? 0
          let first = result.list.first
          let size: number = result.list.size ?? FETCH_LIST_COUNT
          setOfficialModelList(result.list.content)
          setMetricsMonthListt(result.metricsMonthList)
          setPaginationParams((prev) => ({
            ...prev,
            pageNum,
            totalElements,
            totalPages,
            first,
            size,
          }))
          setSearchParams((prev: any) => ({ ...prev, selectedIds: [] }))
        })
        .catch(() => {
          toast.error("모델별 공식 출시가 수집 내역을 불러오는데 실패했습니다.")
        })
    },
    [parseSearchParams],
  )

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchList(pageNumber, searchParams)
    },
    [searchParams, fetchList],
  )

  const onSearch = useCallback(() => {
    fetchList(0, searchParams)
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchList])

  const onSelectionModelChange = useCallback((ids: any[]) => {
    setSearchParams((prev: any) => ({ ...prev, selectedIds: ids }))
  }, [searchParams])

  const handleCellUpdate = (params: GridCellEditCommitParams) => {
    const index = officialModelList.findIndex((model) => model && model.id === params.id)
    const newList = [...officialModelList]
    newList[index] = {
      ...newList[index],
      [params.field]: params.value,
    }
    setOfficialModelList(newList)
  }

  const updateOfficialModel = async () => {
    updateOfficialModelList(officialModelList.filter((model: any) => searchParams.selectedIds.includes(model.id))).then(() => {
      setSearchParams((prev: any) => (initSearchParams))
      fetchList(0, searchParams)
    })
  }

  const onDownloadExcel = async () => {
    if (downloading) {
      toast.info("모델별 공식 출시가 수집 내역을 다운로드 중입니다.")
      return
    }

    setDownloading(true)
    try {
      const blob = await downloadOfficialModelList({ ...parseSearchParams(searchParams) })
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `official_model_list.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      toast.error("모델별 공식 출시가 수집 내역을 다운로드 하지 못했습니다.")
    }
    setDownloading(false)
  }

  useMount(() => {
    fetchList()
  })

  return (
    <PageBodyWrapper>
      <SearchBarWrapper>
        <OfficialModelPriceSearchBar
          changeBrandSid={changeBrandSid}
          changeSearchFilter={changeSearchFilter}
          changeSearchWord={changeSearchWord}
          changeDetailModelExists={changeDetailModelExists}
          changeFirstMetricsMonth={changeFirstMetricsMonth}
          changeLastMetricsMonth={changeLastMetricsMonth}
          onSearch={onSearch}
          searchParams={searchParams}
        />
      </SearchBarWrapper>
      <SearchBarWrapper>
        <Button
          color={downloading ? "warning" : "primary"}
          variant={downloading ? "contained" : "outlined"}
          style={{ marginRight: "15px" }}
          onClick={onDownloadExcel}>
          {downloading ? "다운로드 중" : "엑셀 다운로드"}
        </Button>
        <Button
          variant={"outlined"}
          style={{  marginRight: "15px" }}
          onClick={updateOfficialModel}>
          저장
        </Button>
      </SearchBarWrapper>
      <OfficialModelTable
        rows={officialModelList ?? []}
        metricsMonthList={metricsMonthList ?? []}
        onPageChange={onPageChange}
        onSelectionModelChange={onSelectionModelChange}
        handleCellUpdate={handleCellUpdate}
        page={paginationParams.number}
        size={paginationParams.size}
        totalCount={paginationParams.totalElements ?? 0}
        selectedIds={searchParams.selectedIds}
      />
    </PageBodyWrapper>
  )
}

const PageBodyWrapper = styled.div`
    width: 100%;
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
`

const SearchBarWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 28px;
`
export default OfficialModelTab