import { useState } from "react"
import styled from "@emotion/styled"
import { useSearchParams } from "react-router-dom";
import CouponCodeListPage from "../CouponCodeListPage"
import CouponCodeUsageHistoryPage from "../CouponCodeUsageHistoryListPage";
import CouponPointUsageHistoryPage from "../CouponPointUsageHistoryListPage";

type CouponCodePageTabType = "list" | "coupon-usage" | "point-usage"

const CouponCodeManagementPage = () => {
  const [queryParam, setQueryParam] = useSearchParams()
  const [activeTab, setActiveTab] = useState<CouponCodePageTabType>((queryParam.get("tab") as CouponCodePageTabType) || "list")

  const pageTabMenu: { value: CouponCodePageTabType; title: string }[] = [
    { value: "list", title: "쿠폰 코드" },
    { value: "coupon-usage", title: "쿠폰 코드 사용 이력" },
    { value: "point-usage", title: "쿠폰 포인트 사용 이력" },
  ]

  return (
    <PageWrapper>
      <PageTabWrapper>
        {pageTabMenu.map(({ value, title }) => {
          return (
            <PageTab active={activeTab === value} onClick={() => {
              setActiveTab(value)
              setQueryParam({tab: value})
            }}>
              {title}
            </PageTab>
          )
        })}
      </PageTabWrapper>
      {activeTab === "list" && <CouponCodeListPage />}
      {activeTab === "coupon-usage" && <CouponCodeUsageHistoryPage />}
      {activeTab === "point-usage" && <CouponPointUsageHistoryPage />}
    </PageWrapper>
  )
}

export default CouponCodeManagementPage

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const PageTabWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const PageTab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: ${({ active }) => (active ? "#1976d2" : "black")};

  font-weight: ${({ active }) => (active ? "600" : "500")};
  border-bottom: ${({ active }) => (active ? "2px solid #1976d2" : "none")};
  &:not(:last-child) {
    margin-right: 16px;
  }
`
