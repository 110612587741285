import ListTableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { stockTableHeaderItemList, SelectableProductStockItemType } from "./ProductStockTable"
import { Identifier } from "dnd-core"
import { useDrag, useDrop } from "react-dnd"
import { useRef } from "react"
import styled from "@emotion/styled"
import CheckBox from "components/Checkbox"

type Props = {
  listItem: { id: number | null; item: SelectableProductStockItemType }
  isEditable: boolean
  onChangeGroup: (prevId: number | null, newId: number | null, listItem: SelectableProductStockItemType) => void
  onChangeOrder: (id: number | null, prevIndex: number, newIndex: number) => void
  onCheckOpen: (groupId: number, productId: number, isOpen: boolean) => void
  onCheckClose: (groupId: number, productId: number, isClose: boolean) => void
  onSelectItem: (listItem: SelectableProductStockItemType) => void
}

export const ProductStockTableListItem = ({
  listItem,
  isEditable,
  onChangeGroup,
  onChangeOrder,
  onSelectItem,
  onCheckClose,
  onCheckOpen,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, connectDrag] = useDrag({
    type: "STOCK_ITEM",
    item: listItem,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
  })

  const [, connectDrop] = useDrop<
    { id: number | null; item: SelectableProductStockItemType },
    void,
    { handlerId: Identifier | null }
  >({
    accept: "STOCK_ITEM",
    drop: (item) => {
      if (item.id !== listItem.id) {
        onChangeGroup(item.id, listItem.id, item.item)
      } else {
        if (item.item.priority !== listItem.item.priority) {
          onChangeOrder(item.id, item.item.priority, listItem.item.priority)
        }
      }
    },
  })

  connectDrag(ref)
  connectDrop(ref)

  return (
    <Wrapper
      ref={isEditable ? ref : null}
      data-handler-id={handlerId}
      onClick={() => {
        if (!isEditable) return

        onSelectItem(listItem.item)
      }}
    >
      {stockTableHeaderItemList.map((headerItem, index) => {
        if (headerItem.key === "open") {
          return (
            <ListTableRowItem
              key={index}
              style={{ maxWidth: stockTableHeaderItemList[index].width, justifyContent: "center" }}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation()

                  if (!listItem.id) return

                  if (!isEditable) return

                  onCheckOpen(listItem.id, listItem.item.item.id, listItem.item.whenOpen || false)
                }}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <CheckBox readOnly checked={!!listItem.item.whenOpen} />
                <div
                  style={{
                    margin: "0 10px",
                  }}
                >
                  {listItem.item.whenOpenCheckedBy?.name || "-"}
                </div>
              </div>
            </ListTableRowItem>
          )
        }

        if (headerItem.key === "close") {
          return (
            <ListTableRowItem
              key={index}
              style={{ maxWidth: stockTableHeaderItemList[index].width, justifyContent: "center" }}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation()

                  if (!isEditable) return

                  if (!listItem.id) return

                  onCheckClose(listItem.id, listItem.item.item.id, listItem.item.whenClosed || false)
                }}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <CheckBox readOnly checked={!!listItem.item.whenClosed} />
                <div
                  style={{
                    margin: "0 10px",
                  }}
                >
                  {listItem.item.whenClosedCheckedBy?.name || "-"}
                </div>
              </div>
            </ListTableRowItem>
          )
        }

        return (
          <ListTableRowItem
            key={index}
            style={{ maxWidth: stockTableHeaderItemList[index].width, justifyContent: "center" }}
          >
            {headerItem.getValue?.(listItem.item) || "-"}
          </ListTableRowItem>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 10px 0;

  :not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  &:hover {
    background: rgba(224, 224, 224, 0.3);
  }

  background: ${({ isSelected }) => (isSelected ? "rgba(25, 118, 210, 0.08)" : "white")};
`
