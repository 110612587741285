import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSelectionModel,
  useGridApiContext,
} from "@mui/x-data-grid"
import React, {useEffect, useMemo, useState} from "react"
import { ResellItemType } from "types/ResellItemType"
import { Alert, AlertProps, Box, Button, CardContent, lighten, Snackbar, Switch, Typography } from "@mui/material"
import _ from "lodash"
import SearchDetailModelPopupCustom from "./SearchDetailModelPopupCustom"
import { DetailModelType } from "types/DetailModelType"
import { BrandType } from "types/BrandType"

const ResellItemTable: React.FC<{
  rows?: ResellItemType[]
  page: number
  pageSize: number
  loading: boolean
  totalCount?: number
  handleResellItemChange: Function
  handlePageSizeChange: Function
  handlePageChange: Function
  handleSelectRow: (ids: GridSelectionModel) => void
  brandList: BrandType[] | null
}> = ({
  rows,
  handleResellItemChange,
  handlePageSizeChange,
  handlePageChange,
  page,
  pageSize,
  totalCount,
  loading,
  handleSelectRow,
  brandList,
}) => {
  const [snackbar, setSnackbar] = useState<AlertProps | null>(null)
  const [openSearchDetailModelPopup, setOpenSearchDetailModelPopup] = useState<{ detailModel: DetailModelType, handleChange: Function } | null>(null)

  const columns = useMemo<GridColumns<ResellItemType>>(
    () => [
      {
        field: "confirmStatus",
        headerName: "확인",
        width: 90,
        renderCell: ConfirmStatusCell,
        renderEditCell: EditConfirmStatusCell,
        editable: true,
      },
      {
        field: "brandSid",
        headerName: "브랜드",
        type: "singleSelect",
        editable: true,
        width: 90,
        valueOptions: brandList ? brandList.map((brand) => brand.sid!) : [],
      },
      { field: "refNo", headerName: "레퍼런스 번호", editable: true, width: 140 },
      {
        field: "detailModel",
        headerName: "모델",
        renderCell: DetailModelCell,
        renderEditCell: renderEditDetailModelCell2({ onClickDetailModel: handleChangeDetailModel }),
        editable: true,
        width: 150,
      },
      { field: "year", headerName: "스템핑", editable: true, width: 60 },
      {
        field: "itemStatus",
        headerName: "상태",
        editable: true,
        width: 60,
        type: "singleSelect",
        valueOptions: ["NEW", "USED"],
      },
      { field: "officialGuarantee", headerName: "보증서", type: "boolean", editable: true, width: 60 },
      { field: "formattedLastPrice", headerName: "가격 (만원)", type: "number", editable: true, width: 100, valueGetter: (params: any) => params.row.formattedLastPrice},
      { field: "registerDate", headerName: "게시일", type: "date", editable: false, width: 100 },
    ],
    [brandList]
  )

  function getRowColor(color: string) {
    return lighten(color, 0.6)
  }

  function getHoverRowColor(color: string) {
    return lighten(color, 0.5)
  }

  const processRowUpdate: (
    newRow: ResellItemType,
    oldRow: ResellItemType
  ) => ResellItemType | Promise<ResellItemType> = (newRow, oldRow) => {
    if (!_.isEqual(newRow, oldRow)) {
      handleResellItemChange(newRow)
    }
    return newRow
  }

  const handleProcessRowUpdateError = React.useCallback((error: any) => {
    console.log(error)
  }, [])

  function handleCloseSnackbar() {
    setSnackbar(null)
  }

  function handleChangeDetailModel(row: DetailModelType, handleChange: Function) {
    setOpenSearchDetailModelPopup({detailModel: row, handleChange: handleChange})
  }

  return (
    <>
      <Box>
        <div style={{ height: 735, width: "100%" }}>
          <Box
            sx={{
              height: "100%",
              "& .resell-item-row-checked": {
                bgcolor: (theme) => getRowColor(theme.palette.success.main),
                "&:hover": {
                  bgcolor: (theme) => getHoverRowColor(theme.palette.success.main),
                },
              },
              "& .resell-item-row-unchecked": {
                bgcolor: (theme) => lighten(theme.palette.warning.main, 0.6),
                "&:hover": {
                  bgcolor: (theme) => getHoverRowColor(theme.palette.warning.main),
                },
              },
            }}
          >
            <DataGrid<ResellItemType>
              columns={columns}
              loading={loading}
              pagination
              paginationMode={"server"}
              sortingMode={"server"}
              onSelectionModelChange={handleSelectRow}
              onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
              onPageChange={(page) => handlePageChange(page)}
              rowHeight={40}
              rows={rows ? rows : []}
              page={page}
              pageSize={pageSize}
              rowCount={totalCount}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              experimentalFeatures={{ newEditingApi: true }}
              editMode="row"
              getRowClassName={(params) => {
                return params?.row?.confirmStatus === "미확인" ? "resell-item-row-unchecked" : "resell-item-row-checked"
              }}
            />
          </Box>
        </div>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <SearchDetailModelPopupCustom
        open={openSearchDetailModelPopup?.detailModel ?? false}
        handleClose={(detailModel: DetailModelType | null) => {
            openSearchDetailModelPopup?.handleChange(detailModel)
            setOpenSearchDetailModelPopup(null)
        }}
      />
    </>
  )
}
export default ResellItemTable

const ConfirmStatusCell = (props: GridRenderCellParams<ResellItemType["confirmStatus"]>) => {
  const { value } = props
  return <Switch checked={value === "확인"} readOnly={true} />
}

const EditConfirmStatusCell = (props: GridRenderEditCellParams<ResellItemType["confirmStatus"]>) => {
  const { id, value, field } = props
  const apiRef = useGridApiContext()
  return (
    <Switch
      checked={value === "확인"}
      onChange={(e) => {
        if (e.target.checked) {
          apiRef.current.setEditCellValue({ id, field, value: "확인" })
        } else {
          apiRef.current.setEditCellValue({ id, field, value: "미확인" })
        }
      }}
    />
  )
}

const DetailModelCell = (props: GridRenderCellParams<ResellItemType["detailModel"]>) => {
  const { value } = props
  return (
    <Typography sx={{ fontSize: "10px" }}>
      {value?.title}
      {value?.titleSecondary}
    </Typography>
  )
}

const renderEditDetailModelCell2 = (props: { onClickDetailModel: (detailModel: DetailModelType, handleChange: Function) => void }) =>
    (params: GridRenderEditCellParams<ResellItemType["detailModel"]>) => {
        const {id, value, field} = params;
        const apiRef = useGridApiContext();

        const handleChange = (newValue: DetailModelType | null) => {
            apiRef.current.setEditCellValue({id, field, value: newValue});
        };

        return (
            <Button variant={"contained"} size={"small"} onClick={() => props.onClickDetailModel(params.row, handleChange)}>
                찾기
            </Button>
        )
    }