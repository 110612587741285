import {useState} from "react"
import styled from "@emotion/styled"
import MagazineArticleListPage from "../MagazineArticleListPage";
import MagazineQuestionListPage from "../MagazineQuestionListPage";

type ContentPageTabType = "article" | "question"

const MagazineTabPage = () => {
    const [activeTab, setActiveTab] = useState<ContentPageTabType>("article")

    const pageTabMenu: { value: ContentPageTabType; title: string }[] = [
        { value: "article", title: "아티클" },
        { value: "question", title: "Q & A" },
    ]

    return (
        <PageWrapper>
            <PageTabWrapper>
                {pageTabMenu.map(({ value, title }) => {
                    return (
                        <PageTab active={activeTab === value} onClick={() => setActiveTab(value)}>
                            {title}
                        </PageTab>
                    )
                })}
            </PageTabWrapper>
            {activeTab === "article" && <MagazineArticleListPage />}
            {activeTab === "question" && <MagazineQuestionListPage />}
        </PageWrapper>
    )
}

export default MagazineTabPage

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const PageTabWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
`

const PageTab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: ${({ active }) => (active ? "#1976d2" : "black")};

  font-weight: ${({ active }) => (active ? "600" : "500")};
  border-bottom: ${({ active }) => (active ? "2px solid #1976d2" : "none")};
  &:not(:last-child) {
    margin-right: 16px;
  }
`
