import React, { useCallback, useState } from "react"
import { Button, Grid, Paper } from "@mui/material"
import { useMount } from "react-use"
import { deleteNewsLetters, getNewsLetters } from "../../apis/newsletterAPI"
import { NewsLetterTable } from "./NewsLetterTable"
import { PageType } from "../../types/PageType"
import { NewsLetterType } from "../../types/NewsLetterType"
import { NewsLetterManagePopup } from "./NewsLetterManagePopup"
import { toast } from "react-toastify"

export const NewsLetterManager:React.FC = ()=>{
  const [list, setList] = useState<PageType<NewsLetterType>>({
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  })
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  useMount(()=>{
    setLoading(true)
    getNewsLetters()
      .then((result)=>{
        setLoading(false)
        setList(result)
      })
      .catch(()=>{
        setLoading(false)
      })
  })
  const [popup, setPopup] = useState<{visible:boolean, item:NewsLetterType|null}>({
    visible:false,
    item:null
  })

  const onClickRowItem = useCallback<(item:NewsLetterType)=>void>((item)=>{

    setPopup({
      visible:true,
      item
    })
  }, [])

  const onClickDeleteItems = useCallback(()=>{

    if(selectedIds.length === 0) return;

    const confirmed = window.confirm(`정말로 ${selectedIds.join(',')}를 삭제하시겠습니까?`)
    if(!confirmed) return;

    deleteNewsLetters(selectedIds).then(()=>{
      toast.success('삭제에 성공했습니다.');

      getNewsLetters()
        .then((result)=>{
          setList(result)
        })
    })
  }, [selectedIds])

  return (
    <Grid container spacing={2}>

      <NewsLetterManagePopup
        visible={popup.visible}
        onRequestClose={()=> {
          setPopup({
            visible:false,
            item:null
          })
        }}
        refresh={()=> {
          getNewsLetters().then((result) => {
            setLoading(false)
            setList(result)
          })
        }}
        selectedItem={popup.item}
      />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <NewsLetterTable
            content={list}
            onClickRowItem={onClickRowItem}
            loading={loading}
            selectedIds={selectedIds}
            fetch={(params)=>{
              getNewsLetters(params).then((result)=>{
                setList(result)
              })
            }}
            onSelectionModelChange={(item)=>{
              setSelectedIds(item as string[])
            }}
            components={[
              <Button
                variant={"contained"}
                color={"primary"}
                size={"small"}
                style={{ margin: "0 2px" }}
                onClick={()=>{
                  setPopup({
                    item:null,
                    visible:true,
                  })
                }}
              >
                추가하기
              </Button>,
              <Button
                variant={"contained"}
                color={"error"}
                size={"small"}
                style={{ margin: "0 2px" }}
                disabled={selectedIds.length === 0}
                onClick={onClickDeleteItems}
              >
                삭제하기
              </Button>
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
