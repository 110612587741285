import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import SearchInput from "../Search/SearchBar/SearchInput"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { PageType } from "../../types/PageType"
import { BrandType } from "../../types/BrandType"
import { getBrandList } from "../../apis/brandAPI"
import SearchBar from "../Search/SearchBar"

export type SearchBrandStateType = {
  searchFilter: string
  searchKeyword: string
  selectedIds: string[]
  brandList: PageType<BrandType>
  refresh: boolean
  loading: boolean
  page: number
  pageSize: number
}

const initialState: SearchBrandStateType = {
  searchFilter: "sid",
  searchKeyword: "",
  selectedIds: [],
  brandList: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  refresh: false,
  loading: false,
  page: 0,
  pageSize: 25,
}

const searchMenuList: { value: string; title: string }[] = [
  { value: "sid", title: "브랜드ID" },
  { value: "name", title: "브랜드명" },
  { value: "koName", title: "한글 브랜드명" },
]

function createColumns(): GridColDef[] {
  return [
    {
      field: "sid",
      headerName: "ID",
      width: 150,
    },
    {
      field: "name",
      headerName: "브랜드명",
      flex: 1,
      width: 200,
    },
    {
      field: "koName",
      headerName: "한글 브랜드명",
      width: 200,
    },
    {
      field: "onDisplay",
      headerName: "노출 여부",
      width: 100,
      align: "center",
      valueGetter: (params) => {
        return params.value == true ? "예" : "아니오"
      },
    },
  ]
}

const BrandSelectPopup: React.FunctionComponent<{
  isMultiSelectable?: boolean
  open: boolean
  setBrands: (brand: BrandType[]) => void
  close: () => void
}> = ({ isMultiSelectable, open, setBrands, close }) => {
  const [state, setState] = useState<SearchBrandStateType>(initialState)

  const fetchBrandSimpleList = () => {
    getBrandList({
      page: state.page,
      size: state.pageSize,
      ...(state.searchKeyword ? { [state.searchFilter]: state.searchKeyword } : {}),
    }).then((results) => {
      setState((prevState) => ({
        ...prevState,
        brandList: results,
      }))
    })
  }

  useEffect(() => {
    if (!open) {
      setState(initialState)
      return
    }

    fetchBrandSimpleList()
  }, [open, state.refresh, state.page, state.pageSize])

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <TitleWrapper>
        <DialogTitle>브랜드 선택</DialogTitle>

        <BrandSearchBar>
          <SearchBar>
            <FormControl style={{ minWidth: "150px" }}>
              <Select
                size={"small"}
                defaultValue={state.searchFilter}
                required
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    searchFilter: e.target.value,
                    searchKeyword: "",
                  }))
                }}
                MenuProps={{
                  style: { zIndex: 20000 },
                }}
              >
                {searchMenuList.map((menu) => (
                  <MenuItem value={menu.value.toString()} key={menu.value}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <SearchInput
              value={state.searchKeyword}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  searchKeyword: e.target.value,
                }))
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setState((prevState) => ({
                    ...prevState,
                    refresh: !prevState.refresh,
                  }))
                }
              }}
            />
          </SearchBar>
        </BrandSearchBar>
      </TitleWrapper>
      <DialogContent style={{ width: "900px" }}>
        <Stack direction={"column"} spacing={1}>
          <Box height={"500px"}>
            <DataGrid
              checkboxSelection={isMultiSelectable}
              pagination
              paginationMode="server"
              page={state.page}
              pageSize={state.pageSize}
              rowCount={state.brandList.totalElements ?? 0}
              rows={state.brandList.content ?? []}
              loading={state.loading}
              onPageChange={(page) => {
                setState((prevState) => ({
                  ...prevState,
                  page: page,
                }))
              }}
              onPageSizeChange={(size) => {
                setState((prevState) => ({
                  ...prevState,
                  pageSize: size,
                }))
              }}
              style={{ width: "100%", height: "100%" }}
              columns={createColumns()}
              getRowHeight={() => 100}
              onSelectionModelChange={(ids: any) =>
                setState((prevState) => ({
                  ...prevState,
                  selectedIds: ids,
                }))
              }
              keepNonExistentRowsSelected
              // onSortModelChange={(model) => {
              //   changeSortOption(`${model[0].field},${model[0].sort}`)
              // }}
              getRowId={(row) => row.sid}
              selectionModel={state.selectedIds}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          size={"small"}
          color={"primary"}
          onClick={async () => {
            const selectedBrands = state.brandList.content.filter((brand) => state.selectedIds.includes(brand.sid!!))

            setBrands(selectedBrands)
            close()
          }}
        >
          확인
        </Button>
        <Button variant={"contained"} size={"small"} color={"error"} onClick={() => close()}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BrandSelectPopup

const TitleWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const BrandSearchBar = styled.div`
  padding-right: 24px;
`
