import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import Button from "components/Button"
import styled from "@emotion/styled"
import { PointScheduleStateKeyType, PointScheduleStateValueType } from "../useEditPointScheduleData"
import { useState } from "react"
import { findUserByCsv } from "apis/userAPI"

type Props = {
  userSource?: string
  changePointScheduleState: (key: PointScheduleStateKeyType, value: PointScheduleStateValueType) => void
}

export const CsvFileUploader = ({ userSource, changePointScheduleState }: Props) => {
  const [selectedCsvFile, setSelectedCsvFile] = useState<FileList | undefined>()

  const handleSubmitCsv = () => {
    if (!selectedCsvFile) {
      return
    }

    findUserByCsv(selectedCsvFile)
      .then((res) =>
        changePointScheduleState(
          "userIds",
          res.map(({ id }) => id)
        )
      )
      .catch((e) => toast.error("파일 업로드에 실패했습니다"))
  }

  if (!isEmpty(userSource)) {
    return (
      <OptionWrapper>
        <h3>제출된 파일 Url</h3>
        <InlineWrapper>
          <Url>{userSource}</Url>
          <div style={{ marginLeft: "10px" }}>
            <Button
              buttonSize="M"
              style={{ height: "40px", width: "50px" }}
              onClick={() => changePointScheduleState("userSource", "")}
            >
              변경
            </Button>
          </div>
        </InlineWrapper>
      </OptionWrapper>
    )
  }

  return (
    <OptionWrapper>
      <h3>CSV 파일 선택</h3>
      <InlineWrapper>
        <FileUploder
          type={"file"}
          accept=".csv"
          onChange={(e) => {
            if (e.target.files) {
              setSelectedCsvFile(e.target.files)
            }
          }}
        />
        <div style={{ marginLeft: "10px" }}>
          <Button buttonSize="M" style={{ height: "40px", width: "50px" }} onClick={handleSubmitCsv}>
            제출
          </Button>
        </div>
      </InlineWrapper>
    </OptionWrapper>
  )
}

export default CsvFileUploader

const FileUploder = styled.input`
  flex: 1;
  height: 40px;
  padding: 5px 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const Url = styled.p`
  margin: 0;
  font-size: 12px;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h3 {
    margin: 5px 0;
    font-size: 16px;
  }
`
