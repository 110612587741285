import { PaginationParamsType } from "types/PageType"
import { getPointList } from "apis/pointAPI"
import { useCallback, useState } from "react"
import PointListTable from "./PointListTable"
import { PointListItemType, PointListSearchParams } from "types/PointListItemType"
import styled from "@emotion/styled"
import { useMount } from "react-use"
import { PointReasonType, PointStatusCode } from "types/PointType"
import PointListTableSearchBar from "pages/Point/PointListPage/SearchBar"

export type PointListSearchType = {
  filter: keyof PointListSearchParams
  keyword: string | PointStatusCode | PointReasonType
}

const POINT_LIST_FETCH_SIZE = 20

const PointListPage = () => {
  const [pointList, setPointList] = useState<PointListItemType[]>([])
  const [searchParams, setSearchParams] = useState<PointListSearchType>({
    filter: "status",
    keyword: "",
  })

  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: POINT_LIST_FETCH_SIZE,
  })

  const parseSearchParams = useCallback((searchParams?: PointListSearchType) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchPointList = useCallback(
    (pageNumber?: number, searchParams?: PointListSearchType) => {
      getPointList({ size: POINT_LIST_FETCH_SIZE, page: pageNumber, ...parseSearchParams(searchParams) }).then(
        ({ content, totalElements = 0, totalPages = 0, first, size = POINT_LIST_FETCH_SIZE, number = 0 }) => {
          setPointList(content || [])
          setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
        }
      )
    },
    [parseSearchParams]
  )

  const changeSearchFilterValue = useCallback(
    (searchFilterValue: keyof PointListSearchParams) => {
      setSearchParams({ filter: searchFilterValue, keyword: "" })
      fetchPointList()
    },
    [fetchPointList]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(
    (keyword: PointListSearchType["keyword"]) => {
      fetchPointList(0, { ...searchParams, keyword })
      setPaginationParams((prev) => ({ ...prev, number: 0 }))
    },
    [fetchPointList, searchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber - 1 }))
      fetchPointList(pageNumber - 1, searchParams)
    },
    [searchParams, fetchPointList]
  )

  useMount(() => {
    fetchPointList()
  })

  return (
    <>
      <PageHeaderWrapper>
        <PointListTableSearchBar
          searchParams={searchParams}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <PointListTable pointList={pointList} paginationParams={paginationParams} changePage={changePageNumber} />
    </>
  )
}

export default PointListPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 16px;
  margin-bottom: 20px;
`
