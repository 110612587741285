import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { UserCollectionListFilterKeyType, UserCollectionSearchType } from "../index"
import { CollectionStatusCode, ExposureLevelCode } from "../../../../types/UserCollectionType"

const searchUserFilterMenuList: { value: UserCollectionListFilterKeyType; title: string }[] = [
  { value: "userId", title: "사용자ID" },
  { value: "status", title: "상태" },
  { value: "detailModelId", title: "상세모델ID" },
  { value: "exposureLevel", title: "노출 레벨" },
  { value: "repurchaseGuarantee", title: "매입보증 여부" },
]

type Props = {
  searchParams: UserCollectionSearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: UserCollectionListFilterKeyType) => void
  onSearch: (keyword: UserCollectionSearchType["keyword"]) => void
}

const UserCollectionListTableSearchBar = ({
  searchParams,
  changeSearchKeyword,
  changeSearchFilterValue,
  onSearch,
}: Props) => {
  const numericFields = ["userId", "detailModelId"]

  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"userId"}
          required
          onChange={(e) => {
            changeSearchKeyword("")
            changeSearchFilterValue(e.target.value as UserCollectionListFilterKeyType)
          }}
        >
          {searchUserFilterMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        {searchParams.filter === "status" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as CollectionStatusCode
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              <MenuItem value={CollectionStatusCode.보유중.valueOf()} key={CollectionStatusCode.보유중}>
                {CollectionStatusCode.보유중}
              </MenuItem>
              <MenuItem value={CollectionStatusCode.삭제.valueOf()} key={CollectionStatusCode.삭제}>
                {CollectionStatusCode.삭제}
              </MenuItem>
              <MenuItem value={CollectionStatusCode.진단중.valueOf()} key={CollectionStatusCode.진단중}>
                {CollectionStatusCode.진단중}
              </MenuItem>
              <MenuItem value={CollectionStatusCode.판매중.valueOf()} key={CollectionStatusCode.판매중}>
                {CollectionStatusCode.판매중}
              </MenuItem>
              <MenuItem value={CollectionStatusCode.판매완료.valueOf()} key={CollectionStatusCode.판매완료}>
                {CollectionStatusCode.판매완료}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {searchParams.filter === "exposureLevel" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as ExposureLevelCode
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              <MenuItem value={ExposureLevelCode.공개} key={ExposureLevelCode.공개}>
                {ExposureLevelCode.공개}
              </MenuItem>
              <MenuItem value={ExposureLevelCode.비공개} key={ExposureLevelCode.비공개}>
                {ExposureLevelCode.비공개}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {searchParams.filter === "repurchaseGuarantee" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as string
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              <MenuItem value={"0"} key={"0"}>
                해당없음
              </MenuItem>
              <MenuItem value={"1"} key={"1"}>
                매입보증 대상
              </MenuItem>
            </Select>
          </FormControl>
        )}

        {(searchParams.filter === "userId" || searchParams.filter === "detailModelId") && (
          <SearchInput
            style={{ height: 40 }}
            value={searchParams.keyword}
            onChange={(params) => {
              const value = numericFields.includes(searchParams.filter || "")
                ? params.target.value.replace(/[^0-9]/g, "")
                : params.target.value

              changeSearchKeyword(value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(searchParams.keyword)
              }
            }}
          />
        )}
      </SearchBar>
    </Wrapper>
  )
}

export default UserCollectionListTableSearchBar

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  max-width: 600px;
`
