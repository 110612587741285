import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { Box, Button, Stack } from "@mui/material"
import { useMemo, useState } from "react"
import styled from "@emotion/styled"
import { MdPickType } from "types/MdPickType"

type Props = {
  productList: MdPickType["products"]
  handleOnChangeState: (partialState: Partial<MdPickType>) => void
  openShowProductSelectPopup: () => void
}

const MdPickProductTable = ({ productList, handleOnChangeState, openShowProductSelectPopup }: Props) => {
  const [selectionProductIds, setSelectionCurationProductIds] = useState<number[]>([])

  const columns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        valueGetter: (params: any) => `${params.row.product.id}`,
      },
      {
        field: "product.title",
        headerName: "상품명",
        flex: 3,
        valueGetter: (params: any) => `${params.row.product.title}`,
      },
      {
        field: "product.status",
        headerName: "상태",
        width: 100,
        valueGetter: (params: any) => `${params.row.product.status}`,
      },
      {
        field: "product.price",
        headerName: "가격",
        width: 100,
        valueGetter: (params: any) => `${params.row.product.price}`,
      },
      {
        field: "product.globalSale",
        headerName: "해외판매",
        width: 80,
        valueGetter: (params: any) => `${params.row.product.globalSale ? "Y" : "N"}`,
      },
      {
        field: "product.thumbnail",
        headerName: "썸네일",
        width: 85,
        renderCell: (params: any) => (
          <img src={params.row.product.thumbnail} style={{ height: "100%" }} alt="product thumbnail" />
        ),
      },
      {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
      {
        field: "actions",
        type: "actions",
        headerName: "순서(위치이동)",
        width: 120,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<ArrowUpwardIcon />}
            label={"위로"}
            onClick={() => {
              if (!productList.length) return

              const index = productList.findIndex(({ product }) => product && product.id === params.row.product.id)
              if (index <= 0) return

              let nextProducts = [
                ...productList.slice(0, index - 1),
                productList[index],
                productList[index - 1],
                ...productList.slice(index + 1),
              ]

              nextProducts = nextProducts.map(data => ({
                ...data,
                priority: null
              })) ;

              handleOnChangeState({ products: nextProducts })
            }}
          />,
          <GridActionsCellItem
            icon={<ArrowDownwardIcon />}
            label={"아래로"}
            onClick={() => {
              if (!productList.length) return

              const index = productList.findIndex(({ product }) => product && product.id === params.row.product.id)
              if (index === productList.length-1) return

              let nextProducts = [
                ...productList.slice(0, index),
                productList[index + 1],
                productList[index],
                ...productList.slice(index + 2),
              ]

              nextProducts = nextProducts.map(data => ({
                ...data,
                priority: null
              })) ;

              handleOnChangeState({ products: nextProducts })
            }}
          />,
        ],
      },
    ]
  }, [handleOnChangeState, productList])

  return (
    <Box>
      <Stack height={"100%"}>
        <ButtonWrapper>
          <Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={openShowProductSelectPopup}
            style={{ marginRight: 10 }}
          >
            추가
          </Button>
          <Button
            variant={"contained"}
            color={"error"}
            size={"small"}
            disabled={selectionProductIds.length < 1}
            onClick={() => {
              if (!productList.length) return

              const nextProducts = productList.filter(
                ({ product }) => product && !selectionProductIds.includes(product.id)
              )
              handleOnChangeState({ products: nextProducts })
            }}
          >
            삭제
          </Button>
        </ButtonWrapper>
      </Stack>
      <DataGrid
        getRowId={(row: any) => row.product.id}
        style={{
          height: "500px",
        }}
        columns={columns}
        onSelectionModelChange={(ids) => {
          setSelectionCurationProductIds(ids as number[])
        }}
        checkboxSelection
        getRowHeight={() => 80}
        rows={productList ?? []}
        pagination
        onCellEditCommit={(params:any) => {
          const index = productList.findIndex((data) => data && data.product.id === params.id)
          if (index < 0) return

          const updatedProducts = [...productList];
          updatedProducts[index] = {
            ...updatedProducts[index],
            priority: params.value
          };
          handleOnChangeState({ products: updatedProducts })
        }}
      />
    </Box>
  )
}

export default MdPickProductTable

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
