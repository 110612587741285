import {Button, Grid} from "@mui/material"
import React, {useEffect, useReducer} from "react";
import {PageType} from "types/PageType";
import {getQuestions} from "../../../apis/magazineAPI";
import {Question} from "../../../types/MagazineType";
import QuestionTable from "./QuestionTable";
import EditQuestionPopup from "./EditQuestionPopup";

export interface QuestionState {
    selectedIds: string[],
    searchParams: SearchParams,
    questions: PageType<Question>,
    isShowEditPopup: boolean,
    editQuestion?: Question,
    refresh: boolean,
    loading: boolean
}

const initState: QuestionState = {
    selectedIds: [],
    searchParams: {page: 0, size: 50},
    questions: {content:[], pageable:{pageSize: 25, pageNumber: 0}, totalPages:0, totalElements:0, numberOfElements:0},
    isShowEditPopup: false,
    editQuestion: undefined,
    refresh: false,
    loading: false
}

interface QuestionAction {
    type: string,
    payload?: any
}

interface SearchParams {
    page?: number,
    size?: number,
}

function reducer(state: QuestionState, action: QuestionAction): QuestionState {
    switch (action.type) {
        case 'SELECTION_QUESTION_IDS': return {...state, selectedIds: action.payload}
        case 'FETCH_QUESTION': return {...state, questions: action.payload}
        case 'SHOW_VIEW_QUESTION_POPUP': return {...state,  isShowEditPopup: true, editQuestion: action.payload}
        case 'HIDE_VIEW_QUESTION_POPUP': return {...state,  isShowEditPopup: false}
        case 'REFRESH': return {...state, refresh: !state.refresh}
        case 'START_LOADING': return {...state, loading: true}
        case 'END_LOADING': return {...state, loading: false}
        case 'SET_SEARCH_PARAMS': return {...state, searchParams: action.payload}
    }

    return state;
}

const MagazineQuestionListPage = () => {
    const [state, dispatch] = useReducer(reducer, initState)

    const fetchQuestions = (searchParams: any = {}) => {
        dispatch({
            type: 'SET_SEARCH_PARAMS',
            payload: {page: searchParams?.page || initState.searchParams.page, size: searchParams?.size || initState.searchParams.size}
        })
        getQuestions(searchParams).then(res => dispatch({type: 'FETCH_QUESTION', payload: res}))
    }

    const handleNewContentClick = (event: any) => {
        dispatch({type: 'SHOW_VIEW_QUESTION_POPUP', payload: undefined})
    }

    useEffect(() => {
        fetchQuestions()
    }, [state.refresh])

    return (
        <Grid container spacing={2}>
            <EditQuestionPopup open={state.isShowEditPopup} selectedQuestion={state.editQuestion} handleClose={
                () => {
                    dispatch({type: 'HIDE_VIEW_QUESTION_POPUP'})
                    dispatch({type: 'REFRESH'})
                }
            } />
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <QuestionTable
                        state = {state}
                        dispatch = {dispatch}
                        fetch= { fetchQuestions }
                        components={[
                            <Button variant={"contained"} color={"error"} size={"small"} style={{margin: '0 2px'}} onClick={handleNewContentClick}>Q & A 등록</Button>,
                        ]}
                    />
                </Grid>
            </Grid>
        </Grid>)
}

export default MagazineQuestionListPage