import _ from "lodash"
import { isNotNil } from "./validationUtils"

const parseResponseBody = async <T = any>(response: Response): Promise<T | string> => {
  let responseBody = await response.text()
  try {
    return JSON.parse(responseBody || "{}")
  } catch (jsonParseError) {
    console.warn(`parseResponseBody: 응답이 JSON 형식이 아닙니다. 일반 string으로 반환되었습니다.`, {
      url: response.url,
      responseBody,
    })
    return responseBody
  }
}
export const baseUrl = process.env.REACT_APP_API_HOSTNAME
  ? `https://${process.env.REACT_APP_API_HOSTNAME}`
  : "http://localhost:8090"

export const serviceUrl = process.env.REACT_APP_SERVICE_HOSTNAME
    ? `https://${process.env.REACT_APP_SERVICE_HOSTNAME}`
    : "https://localhost"
export const basePath = "/api"
export const httpGet = async <T = any>(
  pathname: string,
  params?: { [key: string]: any },
  option?: {
    emptyStringAcceptable?: boolean
  }
) => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)

  url.search = new URLSearchParams(
    _.pickBy(params, (param) => {
      if (option?.emptyStringAcceptable) {
        return isNotNil(param)
      }

      return !!param
    })
  ).toString()
  try {
    const response = await fetch(url.href, { method: "GET", credentials: "include" })
    const responseBody = await parseResponseBody(response)
    if (400 <= response.status && response.status < 600) {
      throw responseBody
    }
    return responseBody as T
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }
}

export const httpGetToBlob = async <T = any>(pathname: string, params?: { [key: string]: any }) => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)
  url.search = new URLSearchParams(_.pickBy(params, (param) => !!param)).toString()
  try {
    const response = await fetch(url.href, { method: "GET", credentials: "include" })
    if (400 <= response.status && response.status < 600) {
      throw parseResponseBody(response)
    }

    return response.blob()
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }
}

export const httpPostToBlob = async <T = any>(pathname: string, body?: any) => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)
  try {
    const response = await fetch(url.href, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })

    if (400 <= response.status && response.status < 600) {
      const responseBody = await parseResponseBody(response)
      throw responseBody
    }

    return response.blob()
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }
}


export const httpPost = async <T = any>(pathname: string, body?: any): Promise<T> => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)
  try {
    const response = await fetch(url.href, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
    const responseBody = await parseResponseBody(response)
    if (400 <= response.status && response.status < 600) {
      throw responseBody
    }
    return responseBody as T
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }
}

export const httpPostMultipartFile = async <T = any>(
  pathname: string,
  file: FileList | File[],
  options?: [{ key: string; value: any }]
): Promise<T> => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)
  const body = new FormData()

  body.append("file", file[0], file[0].name)

  options?.forEach((option) => body.append(option.key, option.value))

  try{
    const response = await fetch(url.href, {
      method: "POST",
      body: body,
      credentials: "include",
    })
    const responseBody = await parseResponseBody(response)
    if (400 <= response.status && response.status < 600) {
      throw responseBody
    }
    return responseBody as T
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }

}

export const httpPut = async <T = any>(pathname: string, body?: any): Promise<T> => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)
  try {
    const response = await fetch(url.href, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
    const responseBody = await parseResponseBody(response)
    if (400 <= response.status && response.status < 600) {
      throw responseBody
    }
    return responseBody as T
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }
}

export const httpDelete = async <T = any>(pathname: string, params?: { [key: string]: any }): Promise<T> => {
  const url = new URL(`${basePath}${pathname}`, baseUrl)
  url.search = new URLSearchParams(params).toString()
  try {
    const response = await fetch(url.href, { method: "DELETE", credentials: "include" })
    const responseBody = await parseResponseBody(response)
    if (400 <= response.status && response.status < 600) {
      throw responseBody
    }
    return responseBody as T
  } catch (networkError) {
    console.error(networkError)
    throw networkError
  }
}
