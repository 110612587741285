import _ from "lodash"

export const isNil = (value: any): value is null | undefined => _.isNil(value)

export const isNotNil = <T>(value: T | null | undefined): value is T => !_.isNil(value)

export const isNotEmpty = <T>(value: T | null | undefined): value is T => !_.isEmpty(value)

export const validateUrl = (url: string) => {
  const regExp =
    /^(http(s))?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

  return regExp.test(url)
}

export const validateEngDate = (date: string) => {
  const reGoodDate = /^(0[1-9]|[12][0-9]|3[01])[/]((0[1-9]|1[012])[/](19|20)[0-9]{2})*$/
  return reGoodDate.test(date)
}
