import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { AttachmentType, DiagnosisType, defaultImageLabels } from "types/DiagnosisType"
import AttachmentImageList from "pages/Diagnoses/AttachmentImageList"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

type Props = {
  disabled: boolean
  images: AttachmentType[]
  changeDiagnosisState: (key: keyof DiagnosisType, newState: any) => void
}

const DiagnosisImages = ({ disabled, images, changeDiagnosisState }: Props) => {
  return (
    <>
      <h2>진단 상세 사진 첨부</h2>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>첨부 이미지 (최대 30장)</AccordionSummary>
        <AccordionDetails>
          <AttachmentImageList
            init={images}
            defaultImageLabels={defaultImageLabels}
            save={(images: AttachmentType[]) => {
              changeDiagnosisState("images", images)
            }}
            max={30}
            disabled={disabled}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default DiagnosisImages
