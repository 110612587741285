import {
  createDetailModel,
  getDetailModel,
  updateDetailModel,
  updateDetailModelStatus,
  uploadThumbnail,
} from "apis/detailModelAPI"
import { getModelList } from "apis/modelAPI"
import { isEmpty } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { DetailModelRequestBodyType } from "types/DetailModelRequestBodyType"
import { DetailModelType } from "types/DetailModelType"
import { ErrorType } from "types/ErrorType"

export type SpecLabelType =
  | "다이얼 색상"
  | "다이얼 특성"
  | "케이스 소재"
  | "브레이슬릿 소재"
  | "브레이슬릿 종류"
  | "글라스"
  | "케이스 직경"
  | "케이스 크기"
  | "케이스 두께"
  | "무게"
  | "방수"
  | "베젤 소재"
  | "베젤 종류"
  | "무브먼트"
  | "칼리버"
  | "진동수"
  | "파워리저브"
  | "MANUFACTURED IN"
  | "크라운 소재"
  | "클라스프"
  | "다이얼"
  | "핸즈"
const initialSpecsForm: {
  [Label in SpecLabelType]: { label: Label; value: string; allowDisplay: boolean; allowSearch: boolean }
} = {
  "다이얼 색상": { label: "다이얼 색상", value: "", allowDisplay: false, allowSearch: false },
  "다이얼 특성": { label: "다이얼 특성", value: "", allowDisplay: false, allowSearch: false },
  "케이스 소재": { label: "케이스 소재", value: "", allowDisplay: false, allowSearch: false },
  "브레이슬릿 소재": { label: "브레이슬릿 소재", value: "", allowDisplay: false, allowSearch: false },
  "브레이슬릿 종류": { label: "브레이슬릿 종류", value: "", allowDisplay: false, allowSearch: false },
  글라스: { label: "글라스", value: "", allowDisplay: false, allowSearch: false },
  "케이스 직경": { label: "케이스 직경", value: "", allowDisplay: false, allowSearch: false },
  "케이스 크기": { label: "케이스 크기", value: "", allowDisplay: false, allowSearch: false },
  "케이스 두께": { label: "케이스 두께", value: "", allowDisplay: false, allowSearch: false },
  무게: { label: "무게", value: "", allowDisplay: false, allowSearch: false },
  방수: { label: "방수", value: "", allowDisplay: false, allowSearch: false },
  "베젤 소재": { label: "베젤 소재", value: "", allowDisplay: false, allowSearch: false },
  "베젤 종류": { label: "베젤 종류", value: "", allowDisplay: false, allowSearch: false },
  무브먼트: { label: "무브먼트", value: "", allowDisplay: false, allowSearch: false },
  칼리버: { label: "칼리버", value: "", allowDisplay: false, allowSearch: false },
  진동수: { label: "진동수", value: "", allowDisplay: false, allowSearch: false },
  파워리저브: { label: "파워리저브", value: "", allowDisplay: false, allowSearch: false },
  "MANUFACTURED IN": { label: "MANUFACTURED IN", value: "", allowDisplay: false, allowSearch: false },
  "크라운 소재": { label: "크라운 소재", value: "", allowDisplay: false, allowSearch: false },
  클라스프: { label: "클라스프", value: "", allowDisplay: false, allowSearch: false },
  다이얼: { label: "다이얼", value: "", allowDisplay: false, allowSearch: false },
  핸즈: { label: "핸즈", value: "", allowDisplay: false, allowSearch: false },
}

export const useDetailModelCreatePopupStore = (
  params: {
    brandSid: string
    modelId: number
    detailModelId: number | null
  },
  onRefreshList: () => void
) => {
  const [modelOptions, setModelOptions] = useState<{ modelId: number; modelName: string }[]>([])
  const [registrationStatus, setRegistrationStatus] = useState<null | DetailModelType["registrationStatus"]>(null)
  const [detailModelAllowDisplay, setDetailModelAllowDisplay] = useState(false)
  const [detailModelInfoForm, setDetailModelInfoForm] = useState({
    modelId: params.modelId,
    name: "",
    nameKorean: "",
    refNo: "",
    fullRefNo: "",
  })
  const [detailModelImageForm, setDetailModelImageForm] = useState<{
    thumbnailUrl: string | null
  }>({
    thumbnailUrl: null,
  })
  const [detailModelTitleForm, setDetailModelTitleForm] = useState({
    titleSecondary: "",
  })
  const [detailModelCommentForm, setDetailModelCommentForm] = useState({
    display: true,
    commentTitle: "",
    commentDetail: "",
  })
  const [detailModelSearchTagsForm, setDetailModelSearchTagsForm] = useState({ searchTags: "" })

  const [detailModelSpecsForm, setDetailModelSpecsForm] = useState(initialSpecsForm)

  const changeThumbnailFile = useCallback(async (fileList: FileList) => {
    if (isEmpty(fileList)) return
    const { url } = await uploadThumbnail(fileList)
    setDetailModelImageForm((state) => ({ ...state, thumbnailUrl: url }))
  }, [])

  const nextDetailModelForm: DetailModelRequestBodyType = useMemo(() => {
    return {
      model: { id: detailModelInfoForm.modelId },
      name: detailModelInfoForm.name,
      koName: detailModelInfoForm.nameKorean,
      refNo: detailModelInfoForm.refNo,
      fullRefNo: detailModelInfoForm.fullRefNo,

      thumbnail: detailModelImageForm.thumbnailUrl,

      title: `${detailModelInfoForm.name} ${detailModelInfoForm.refNo}`,
      titleSecondary: detailModelTitleForm.titleSecondary,

      advice: {
        adviser: { id: 1 },
        summary: detailModelCommentForm.display ? detailModelCommentForm.commentTitle : "",
        full: detailModelCommentForm.display ? detailModelCommentForm.commentDetail : "",
      },

      searchTags: detailModelSearchTagsForm.searchTags.split(",").map((searchTag) => searchTag.trim()),

      specTags: Object.values(detailModelSpecsForm).map(({ label, value, allowDisplay, allowSearch }) => ({
        label,
        value,
        onDisplay: allowDisplay,
        onSearch: allowSearch,
      })),

      onDisplay: detailModelAllowDisplay,
    }
  }, [
    detailModelAllowDisplay,
    detailModelCommentForm.commentDetail,
    detailModelCommentForm.commentTitle,
    detailModelCommentForm.display,
    detailModelImageForm.thumbnailUrl,
    detailModelInfoForm.fullRefNo,
    detailModelInfoForm.modelId,
    detailModelInfoForm.name,
    detailModelInfoForm.nameKorean,
    detailModelInfoForm.refNo,
    detailModelSearchTagsForm.searchTags,
    detailModelSpecsForm,
    detailModelTitleForm.titleSecondary,
  ])

  const setDetailModelToFormData = useCallback((detailModel: DetailModelType) => {
    setRegistrationStatus(detailModel.registrationStatus || null)
    setDetailModelAllowDisplay(detailModel.onDisplay || false)
    setDetailModelInfoForm((state) => ({
      ...state,
      name: detailModel.name || "",
      nameKorean: detailModel.koName || "",
      refNo: detailModel.refNo || "",
      fullRefNo: detailModel.fullRefNo || "",
    }))
    setDetailModelImageForm((state) => ({ ...state, thumbnailUrl: detailModel.thumbnail || null }))
    setDetailModelTitleForm((state) => ({ ...state, titleSecondary: detailModel.titleSecondary || "" }))
    setDetailModelCommentForm((state) => ({
      ...state,
      commentTitle: detailModel.advice?.summary || "",
      commentDetail: detailModel.advice?.full || "",
    }))

    setDetailModelSearchTagsForm((state) => ({ ...state, searchTags: detailModel.searchTags || "" }))

    const nextSpecsForm = (detailModel.specTags || []).reduce<{
      [Label in SpecLabelType]: { label: Label; value: string; allowDisplay: boolean; allowSearch: boolean }
    }>((specs, spec) => {
      if (
        // prettier-ignore
        ["다이얼 색상", "다이얼 특성", "케이스 소재", "브레이슬릿 소재", "브레이슬릿 종류", "글라스", "케이스 직경", "케이스 크기", "케이스 두께", "무게", "방수", "베젤 소재", "베젤 종류", "무브먼트", "칼리버", "진동수", "파워리저브", "MANUFACTURED IN", "크라운 소재", "클라스프", "다이얼", "핸즈"]
          .includes(spec.label)
      ) {
        return {
          ...specs,
          [spec.label]: {
            label: spec.label,
            value: spec.value,
            allowDisplay: spec.onDisplay,
            allowSearch: spec.onSearch,
          },
        }
      }
      return specs
    }, initialSpecsForm)
    setDetailModelSpecsForm((state) => ({ ...state, ...nextSpecsForm }))
  }, [])

  const saveDetailModelForm = useCallback(async () => {
    if (!params.detailModelId) return

    try {
      await updateDetailModel(params.detailModelId, nextDetailModelForm)
      onRefreshList()
      toast.success("저장되었습니다.")
    } catch (e) {
      toast.error((e as ErrorType).message)
    }
  }, [nextDetailModelForm, params.detailModelId, onRefreshList])

  const fetchRegeditDetailModel = useCallback(async () => {
    if (!detailModelInfoForm.modelId) throw alert("모델을 선택해주세요")
    if (isEmpty(detailModelInfoForm.name)) throw alert("상세모델명(영문)을 입력해주세요")
    if (isEmpty(detailModelInfoForm.nameKorean)) throw alert("상세모델명(한글)을 입력해주세요")
    if (isEmpty(detailModelInfoForm.refNo)) throw alert("Ref No. 를 입력해주세요")
    if (isEmpty(detailModelInfoForm.fullRefNo)) throw alert("Full Ref. 를 입력해주세요")

    try {
      await createDetailModel(nextDetailModelForm)
      onRefreshList()
      toast.success("모델 등록이 완료되었습니다.")
    } catch {
      toast.error("모델 등록에 실패했습니다.")
    }
  }, [
    nextDetailModelForm,
    detailModelInfoForm.modelId,
    detailModelInfoForm.name,
    detailModelInfoForm.nameKorean,
    detailModelInfoForm.refNo,
    detailModelInfoForm.fullRefNo,
    onRefreshList,
  ])

  const changeDetailModelFormStatus = useCallback(async () => {
    if (!params.detailModelId) return

    try {
      await saveDetailModelForm()

      const detailModel = await updateDetailModelStatus(params.detailModelId)
      onRefreshList()
      setDetailModelToFormData(detailModel)
    } catch {
      toast.error("상태 변경에 실패했습니다.")
    }
  }, [saveDetailModelForm, params.detailModelId, setDetailModelToFormData, onRefreshList])

  useEffect(() => {
    getModelList({ brand: params.brandSid, page: 0, size: 100, name: "" }).then(({ content }) =>
      setModelOptions(content.map(({ id, name }) => ({ modelId: id, modelName: name })))
    )
  }, [params.brandSid])

  useEffect(() => {
    if (!params.detailModelId) return
    getDetailModel(params.detailModelId).then((detailModel) => {
      setDetailModelToFormData(detailModel)
    })
  }, [params.detailModelId, setDetailModelToFormData])

  return useMemo(
    () => ({
      registrationStatus,
      detailModelAllowDisplay,
      detailModelInfoForm,
      detailModelImageForm,
      detailModelTitleForm,
      detailModelCommentForm,
      detailModelSearchTagsForm,
      detailModelSpecsForm,
      modelOptions,
      fetchRegeditDetailModel,
      changeThumbnailFile,
      setDetailModelAllowDisplay,
      setDetailModelInfoForm,
      setDetailModelTitleForm,
      setDetailModelCommentForm,
      setDetailModelSearchTagsForm,
      setDetailModelSpecsForm,
      saveDetailModelForm,
      changeDetailModelFormStatus,
    }),
    [
      saveDetailModelForm,
      changeThumbnailFile,
      detailModelAllowDisplay,
      detailModelCommentForm,
      detailModelImageForm,
      detailModelInfoForm,
      detailModelSearchTagsForm,
      detailModelSpecsForm,
      detailModelTitleForm,
      fetchRegeditDetailModel,
      changeDetailModelFormStatus,
      modelOptions,
      registrationStatus,
    ]
  )
}
