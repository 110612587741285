import { getProduct } from "apis/productAPI"
import { postUpgradeServiceOrder } from "apis/upgradeServiceOrderAPI"
import { isEmpty } from "lodash"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { ProductType } from "types/ProductType"
import { UpgradeServiceItemType } from "types/UpgradeServiceOrderType"
import { UserType } from "types/UserType"
import { isNil } from "utils/validationUtils"

export const useUpgradeServiceRegister = () => {
  const [selectedProduct, setSelectedProduct] = useState<ProductType>()
  const [selectedUser, setSelectedUser] = useState<UserType>()
  const [selectedUpgradeItemIndexList, setSelectedUpgradeItemIndexList] = useState<number[]>([])
  const [upgradeItemList, setUpgradeItemList] = useState<UpgradeServiceItemType[]>([])

  const selectProduct = (product: ProductType) => {
    if (isNil(product)) return
    fetchProductDetail(product.id)
  }

  const fetchProductDetail = async (productId: number) => {
    getProduct(productId)
      .then((response) => setSelectedProduct(response))
      .catch(() => {
        setSelectedProduct(undefined)
        toast.error("상품 정보를 불러올 수 없습니다.")
      })
  }

  const deleteUpgradeItemListState = () => {
    setUpgradeItemList((prev) => prev.filter((item, index) => selectedUpgradeItemIndexList.includes(index)))
  }

  const addUpgradeItemListstate = (item: UpgradeServiceItemType) => {
    setUpgradeItemList((prev) => prev.concat(item))
  }

  const toggleUpgradeItem = (index: number) => {
    if (selectedUpgradeItemIndexList.includes(index)) {
      setSelectedUpgradeItemIndexList((prev) => prev.filter((prevIndex) => prevIndex !== index))
    } else {
      setSelectedUpgradeItemIndexList((prev) => prev.concat(index))
    }
  }

  const registerUpgradeServiceOrder = async () => {
    if (!selectedProduct) {
      toast.error("상품 번호를 입력해주세요")
      return
    }

    if (isEmpty(upgradeItemList)) {
      toast.error("수리항목을 추가해주세요.")
      return
    }

    if (!selectedUser) {
      toast.error("회원 ID를 추가해주세요")
      return
    }

    try {
      postUpgradeServiceOrder({
        orderType: "구매",
        userId: selectedUser.id,
        items: upgradeItemList,
        productId: selectedProduct.id.toString(),
      })
      toast.success("수리주문을 생성했습니다.")
      return true
    } catch (error) {
      toast.error("수리주문 생성에 실패했습니다.")
      return
    }
  }

  const toggleAllUpgradeItem = () => {
    setSelectedUpgradeItemIndexList(
      isEmpty(selectedUpgradeItemIndexList) ? upgradeItemList.map((item, index) => index) : []
    )
  }

  const isAllChecked = useMemo(() => {
    if (isEmpty(upgradeItemList) || isEmpty(selectedUpgradeItemIndexList)) return false

    return upgradeItemList.length === selectedUpgradeItemIndexList.length
  }, [upgradeItemList, selectedUpgradeItemIndexList])

  const onEditUpgradeServiceItem = (updatedItem: UpgradeServiceItemType, index: number) => {
    setUpgradeItemList((prev) =>
      prev.map((item, itemIndex) => {
        if (itemIndex === index) {
          return updatedItem
        }
        return item
      })
    )
  }

  return {
    selectedUpgradeItemIndexList,
    upgradeItemList,
    selectedProduct,
    isAllChecked,
    selectedUser,
    selectProduct,
    addUpgradeItemListstate,
    toggleUpgradeItem,
    deleteUpgradeItemListState,
    registerUpgradeServiceOrder,
    toggleAllUpgradeItem,
    setSelectedUser,
    onEditUpgradeServiceItem,
  }
}
