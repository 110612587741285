import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const ListTableFooter = ({ children }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper>{children}</Wrapper>
}

export default ListTableFooter

const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid rgba(224, 224, 224, 1);

  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
`
