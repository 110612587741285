import {BuyOrderType} from "types/BuyOrderType";
import React from "react";
import {Button} from "@mui/material";
import {SaleTypeCode} from "types/ProductType";
import {approveRefund, rejectRefund, requestRefund} from "../../../../apis/buyOrderAPI";

type Props = {
  buyOrder: BuyOrderType
  handleClose: Function
  setBuyOrder: (buyOrder: BuyOrderType) => void
}

const BottomButtons = ({ buyOrder, setBuyOrder, handleClose }: Props) => {

  const handleRequestRefund = () => {
    if (window.confirm("고객이 반품을 희망한 경우에만 사용하여야 합니다.\n[반품 요청] 처리하시겠습니까?")) {
      requestRefund(buyOrder.id!).then((res) => {
        alert(`${buyOrder?.id} 반품 요청 성공`)
        setBuyOrder(res)
      })
    }
  }

  const handleApproveRefund = () => {
    if (
        window.confirm(
            "고객이 반품 신청한 상품이 진단을 통해 반품 가능한 상태가 확인된 경우에만 사용하여야 합니다.\n[반품 승인] 처리하시겠습니까?"
        )
    ) {
      approveRefund(buyOrder.id!).then((res) => {
        alert(`${buyOrder?.id} 반품 승인 성공`)
        setBuyOrder(res)
      })
    }
  }

  const handleRejectRefund = () => {
    if (
        window.confirm(
            "고객이 반품 신청한 상품이 진단을 통해 반품 불가능한 상태가 확인된 경우에만 사용하여야 합니다.\n[반품 거절] 처리하시겠습니까?"
        )
    ) {
      rejectRefund(buyOrder.id!).then((res) => {
        alert(`${buyOrder?.id} 반품 거절 성공`)
        setBuyOrder(res)
      })
    }
  }

  return (
    <>
      {buyOrder.product?.saleType != SaleTypeCode.진단전판매
        && <>
          <Button
              size={"medium"}
              variant={"contained"}
              onClick={() => handleRequestRefund()}
              disabled={buyOrder?.buyOrderStatus != "구매 완료"}
          >
            반품 요청
          </Button>
          <Button
              size={"medium"}
              variant={"contained"}
              onClick={() => handleApproveRefund()}
              disabled={buyOrder?.buyOrderStatus != "반품 요청 완료"}
          >
            반품 승인
          </Button>
          <Button
              size={"medium"}
              variant={"contained"}
              onClick={() => handleRejectRefund()}
              disabled={buyOrder?.buyOrderStatus != "반품 요청 완료"}
          >
            반품 거절
          </Button>
        </>
      }

      <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
        닫기
      </Button>
    </>
  )
}

export default BottomButtons
