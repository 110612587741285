import { deleteDetailModel, getDetailModelList, hideDetailModel, showDetailModel } from "apis/detailModelAPI"
import { getModel } from "apis/modelAPI"
import { useCallback, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useMount } from "react-use"

import { DetailModelListItemType } from "types/DetailModelListItemType"
import { ModelListItemType } from "types/ModelListItemType"
import { isNotEmpty } from "utils/validationUtils"
import { toast } from "react-toastify"
import { ErrorType } from "types/ErrorType"
import { BrandType } from "types/BrandType"
import { getBrands } from "apis/brandAPI"

export const detailModelSearchOptionList: { key: string; title: string }[] = [
  { key: "title", title: "상세모델명" },
  { key: "fullRefNo", title: "ref No." },
  { key: "id", title: "ID" },
]

export const useDetailModelManagerStore = () => {
  const params = useParams<{ modelId: string }>()
  const [searchParams, setSearchParams] = useSearchParams()

  const detailModelListParams = useMemo(() => {
    const modelId = Number(params.modelId) || 0
    const page = Number(searchParams.get("page")) || 1
    const brandSid = searchParams.get("brandSid") || ""
    const title = searchParams.get("title") || undefined
    const id = searchParams.get("id") || undefined
    const fullRefNo = searchParams.get("fullRefNo") || undefined
    const registrationStatus = searchParams.get("registrationStatus") || ""
    return { modelId, page, brandSid, title, registrationStatus, id, fullRefNo }
  }, [params.modelId, searchParams])

  const [brandList, setBrandList] = useState<BrandType[]>([])
  const [model, setModel] = useState<ModelListItemType | null>(null)
  const [detailModelList, setDetailModelList] = useState<{
    content: DetailModelListItemType[]
    totalPages: number
  }>({ content: [], totalPages: 0 })

  const fetchBrandList = useCallback(async () => {
    const brandList = await getBrands()
    setBrandList(brandList)
  }, [])

  const fetchDetailModelList = useCallback(
    async (params: {
      modelId: number
      page: number
      title?: string
      registrationStatus: string
      id?: string
      fullRefNo?: string
    }) => {
      const [model, detailModelList] = await Promise.all([
        getModel(params.modelId),
        getDetailModelList({
          modelId: params.modelId,
          page: params.page - 1,
          title: params.title,
          fullRefNo: params.fullRefNo,
          registrationStatus: params.registrationStatus,
          id: params.id,
          size: 15,
          sort: "createdAt,desc",
        }),
      ])
      setModel(model)
      setDetailModelList({
        content: detailModelList.content,
        totalPages: detailModelList.totalPages ?? 1,
      })
    },
    []
  )

  const removeDetailModel = useCallback(async (id: number) => {
    if (window.confirm(`상세모델(${id})을 삭제하시겠습니까?`)) {
      try {
        await deleteDetailModel(id)
        toast.success(`상세모델(${id})를 삭제하였습니다.`)
      } catch (error) {
        toast.error((error as ErrorType).message)
      }
      fetchDetailModelList(detailModelListParams)
    }
  }, [detailModelListParams, fetchDetailModelList])

  const changePage = useCallback(
    (page: number) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(page))
      setSearchParams(nextSearchParams)
    },
    [searchParams, setSearchParams]
  )

  const changeTitle = useCallback(
    (title: string) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(1))
      if (isNotEmpty(title)) {
        nextSearchParams.set("title", title)
      } else {
        nextSearchParams.delete("title")
      }
      setSearchParams(nextSearchParams)
    },
    [searchParams, setSearchParams]
  )

  const changeRegistrationStatus = useCallback(
    (registrationStatus: string) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(1))
      if (isNotEmpty(registrationStatus)) {
        nextSearchParams.set("registrationStatus", registrationStatus)
      } else {
        nextSearchParams.delete("registrationStatus")
      }
      setSearchParams(nextSearchParams)
    },
    [searchParams, setSearchParams]
  )

  const changeDetailModelDisplay = useCallback(
    async (detailModelId: number, allowDisplay: boolean) => {
      if (allowDisplay) {
        await showDetailModel(detailModelId)
      } else {
        await hideDetailModel(detailModelId)
      }
      fetchDetailModelList(detailModelListParams)
    },
    [detailModelListParams, fetchDetailModelList]
  )

  const changeSearchKeyValue = useCallback(
    (key: string, value?: string) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(1))

      const unUnsedSearchParams = detailModelSearchOptionList.filter((option) => option.key !== key)
      unUnsedSearchParams.forEach((params) => {
        nextSearchParams.delete(params.key)
      })

      nextSearchParams.set(key, value || "")
      setSearchParams(nextSearchParams)
    },
    [setSearchParams, searchParams]
  )

  useMount(() => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.set("page", String(1))
    nextSearchParams.set("registrationStatus", "등록대기,이미지제작,검수대기,검수완료")

    setSearchParams(nextSearchParams, { replace: true })
  })

  return useMemo(
    () => ({
      brandList,
      model,
      detailModelListParams,
      detailModelList,
      fetchBrandList,
      fetchDetailModelList,
      removeDetailModel,
      changePage,
      changeTitle,
      changeRegistrationStatus,
      changeDetailModelDisplay,
      changeSearchKeyValue,
    }),
    [
      brandList,
      model,
      detailModelListParams,
      detailModelList,
      fetchBrandList,
      fetchDetailModelList,
      removeDetailModel,
      changePage,
      changeTitle,
      changeRegistrationStatus,
      changeDetailModelDisplay,
      changeSearchKeyValue,
    ]
  )
}
