import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

type Props = {
  isSelected?: boolean
}

const ListTableRow = ({
  isSelected = false,
  children,
  ...props
}: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) => {
  return (
    <Wrapper {...props} isSelected={isSelected}>
      {children}
    </Wrapper>
  )
}

export default ListTableRow

const Wrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 10px 0;

  :not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  &:hover {
    background: rgba(224, 224, 224, 0.3);
  }

  background: ${({ isSelected }) => (isSelected ? "rgba(25, 118, 210, 0.08)" : "white")};
`
