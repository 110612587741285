import {ProductType} from "./ProductType"
import {SellOrderType} from "./SellOrderType"
import { ShippingMethod } from "./ShippingMethod"

export const enum OrderShippingTypeCode {
  구매_발송 = "구매 발송",
  구매_반품_픽업 = "구매 반품 픽업",
  구매_반품_반송 = "구매 반품 반송",

  판매_픽업 = "판매 픽업",
  판매_반송 = "판매 반송"
}

export const enum ShippingStatusCode {
  배송_준비 = "배송 준비",
  상품_발송 = "상품 발송",
  배송중 = "배송중",
  배송_완료 = "배송 완료",
}

export declare interface JourneyType {
  when?: string
  how?: string
  where?: string
}

export declare interface ShippingType {
  id?: number
  shippingType?: ShippingTypeCode
  name?: string
  phone?: string
  zipCode?: string
  address?: string
  addressDetail?: string
  shippingStatus?: ShippingStatusCode
  trackingNo?: string
  waitingAt?: string
  startedAt?: string
  inTransitAt?: string
  completedAt?: string
  journey?: JourneyType[]
  orderId?: SellOrderType["id"]
  productId?: ProductType["id"]
  productTitle?: ProductType["title"]
  item?: string
  quantity?: number
  weight?: number
  price?: number
  note?: string
  shippingMethod?: ShippingMethod
  isGlobalShipping?: boolean
  trackingUrl?: string
}

export const enum ShippingTypeCode {
  발송 = "발송",
  반송 = "반송",
}

export declare interface UpdateTrackingNoRequest {
  trackingNo: string
}

export declare interface ShippingRequestItemType {
  id?: number
}
export declare interface ShippingRequestType {
  items: ShippingRequestItemType[]
  manually?: boolean
}
export declare interface ShippingRequestResultType {
  succeed?: ShippingRequestResultItemType[]
  failure?: ShippingRequestResultItemType[]
}
export declare interface ShippingRequestResultItemType {
  id?: number
  code?: string
  trackingNo?: string
  message?: string
}
