import { toDateTimeStr } from "utils/datetimeUtil"
import { SellOrderType } from "types/SellOrderType"
import { UserDetailType, UserMemoTargetTypeCode, UserType } from "../../../../types/UserType"
import QuickMemoButton from "../../../../components/QuickMemo/QuickMemoButton"
import { FunctionComponent, useEffect, useState } from "react"
import UserIdButton from "../../../../components/UserIdButton"
import { getUserDetail } from "../../../../apis/userAPI"
import { toast } from "react-toastify"
import { FormControlLabel, Switch } from "@mui/material"
import { DiagnosisType } from "../../../../types/DiagnosisType"
import { updateProduct } from "apis/productAPI"
import { isNil, isNotNil } from "utils/validationUtils"

type Props = {
  sellOrder?: SellOrderType
  diagnosis?: DiagnosisType
}

const SellOrderInformation: FunctionComponent<Props> = ({ sellOrder, diagnosis }) => {
  const [sellOrderState, setSellOrderState] = useState<SellOrderType | null>(null)
  const [seller, setSeller] = useState<UserType | UserDetailType | null>(null)
  const [unmask, setUnmask] = useState<boolean>(false)

  const handleClickButtonTitleEdit = async () => {
    if (isNil(sellOrderState)) {
      toast.error("판매주문정보를 불러오는 중입니다.")
      return
    }
    if (isNil(sellOrderState.product)) {
      toast.error("판매주문에 연결된 상품이 없습니다.")
      return
    }

    const nextProductTitle = window.prompt("상품명 변경", sellOrderState.product.title)
    if (isNotNil(nextProductTitle)) {
      try {
        await updateProduct(sellOrderState.product.id, { title: nextProductTitle })
        toast.success("상품명을 변경했습니다.")
        setSellOrderState((sellOrderState) =>
          sellOrderState
            ? {
                ...sellOrderState,
                product: sellOrderState.product
                  ? { ...sellOrderState.product, title: nextProductTitle }
                  : sellOrderState.product,
              }
            : null
        )
      } catch (error: any) {
        toast.error(error.message ?? error)
      }
    }
  }

  const handleClickButtonTitleSecondaryEdit = async () => {
    if (isNil(sellOrderState)) {
      toast.error("판매주문정보를 불러오는 중입니다.")
      return
    }
    if (isNil(sellOrderState.product)) {
      toast.error("판매주문에 연결된 상품이 없습니다.")
      return
    }

    const nextProductTitleSecondary = window.prompt("상품정보 변경", sellOrderState.product.titleSecondary)
    if (isNotNil(nextProductTitleSecondary)) {
      try {
        await updateProduct(sellOrderState.product.id, { titleSecondary: nextProductTitleSecondary })
        toast.success("상품정보를 변경했습니다.")
        setSellOrderState((sellOrderState) =>
          sellOrderState
            ? {
                ...sellOrderState,
                product: sellOrderState.product
                  ? { ...sellOrderState.product, titleSecondary: nextProductTitleSecondary }
                  : sellOrderState.product,
              }
            : null
        )
      } catch (error: any) {
        toast.error(error.message ?? error)
      }
    }
  }

  useEffect(() => {
    if (isNotNil(sellOrder)) {
      setSellOrderState(sellOrder)
    }

    if (isNotNil(sellOrder) && isNotNil(sellOrder.seller)) {
      getUserDetail(String(sellOrder.seller.id), unmask)
        .then((seller) => setSeller(seller))
        .catch((err) => {
          toast.error("회원 정보 조회에 실패했습니다.")
          console.log("Error", err)
        })
    }
  }, [sellOrder, unmask])

  return (
    <table className={"basic"}>
      <tbody>
        <tr>
          <td className={"no-border"} colSpan={6} style={{ textAlign: "end" }}>
            <FormControlLabel
              style={{ color: "#ed3030" }}
              control={<Switch color="warning" value={unmask} />}
              label="개인정보 마스킹 해제"
              checked={unmask}
              onChange={(event, checked) => {
                if (checked) {
                  checked = window.confirm(
                    "업무상 고객의 개인정보를 조회합니다." +
                      "\n\n* 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 경우 " +
                      "\n5년 이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다."
                  )
                }
                setUnmask(checked)
              }}
            />
          </td>
        </tr>
        <tr>
          <td className={"ta-center"} rowSpan={8}>
            <img
              style={{ display: "block", margin: "auto", width: "200px", height: "300px", objectFit: "cover" }}
              src={sellOrderState?.product?.thumbnail}
              alt=""
            />
          </td>
          <td className={"header"}>진단서 번호</td>
          <td className={"header"}>판매주문번호</td>
          <td className={"header"}>판매신청 일시</td>
          <td className={"header"}>입고확인 일시</td>
          <td className={"header"}>안내완료 일시</td>
        </tr>
        <tr>
          <td>{sellOrderState?.productDiagnosis?.id}</td>
          <td>{sellOrderState?.id}</td>
          <td>{sellOrderState?.acceptedAt && toDateTimeStr(sellOrderState?.acceptedAt)}</td>
          <td>{sellOrderState?.waitingDiagnosisAt && toDateTimeStr(sellOrderState?.waitingDiagnosisAt)}</td>
          <td>
            {sellOrderState?.productDiagnosis?.notifiedAt &&
              toDateTimeStr(sellOrderState?.productDiagnosis?.notifiedAt)}
          </td>
        </tr>
        <tr>
          <td className={"header"}>판매자 ID</td>
          <td className={"header"}>판매자명</td>
          <td className={"header"}>연락처</td>
          <td className={"header"} colSpan={2}>
            바이버 계정
          </td>
          {/*<td className={"header"}>판매방식</td>*/}
        </tr>
        <tr>
          <td>
            <UserIdButton id={seller?.id} />
            <QuickMemoButton target={UserMemoTargetTypeCode.USER} targetId={seller?.id} small />
          </td>
          <td>{seller?.name}</td>
          <td>{seller?.phone}</td>
          <td colSpan={2}>{seller?.email}</td>
          {/*<td>{sellOrderState?.sellOrderType}</td>*/}
        </tr>
        <tr>
          <td className={"header"} colSpan={3}>
            상품명
          </td>
          <td className={"header"} colSpan={1}>
            Ref No.
          </td>
          <td className={"header"} colSpan={1}>
            진단감정
          </td>
        </tr>
        <tr>
          <td colSpan={1}>{sellOrderState?.product?.brandSid}</td>
          <td colSpan={1}>
            {sellOrderState?.product?.title}
            <button style={{ marginLeft: 8 }} onClick={handleClickButtonTitleEdit}>
              수정
            </button>
          </td>
          <td colSpan={1}>
            {sellOrderState?.product?.titleSecondary}
            <button style={{ marginLeft: 8 }} onClick={handleClickButtonTitleSecondaryEdit}>
              수정
            </button>
          </td>
          <td colSpan={1}>{sellOrderState?.product?.detailModel?.fullRefNo}</td>
          <td colSpan={1}>
            {sellOrderState?.product?.saleType === "진단전판매"
              ? diagnosis?.advancedDiagnosis
                ? "정밀 감정진단"
                : "일반 감정진단"
              : ""}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default SellOrderInformation
