import { FunctionComponent } from "react"

export const DetailModelSearchTagsInput: FunctionComponent<{
  searchTags: string
  onChangeSearchTag: (searchTags: string) => void
}> = ({ searchTags, onChangeSearchTag }) => {
  return (
    <div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
        <div style={{ width: 120 }}>검색 키워드</div>
        <div style={{ width: 8 }} />
        <div style={{ color: "#8F98A1" }}>자연어 입력 쉼표(,)로 구분</div>
      </div>
      <div style={{ height: 12 }} />
      <DetailModelSearchKeywordContentTextField
        label="검색어"
        inputValue={searchTags}
        inputPlaceholder="ex) 헐크, 섭마"
        onChangeInputValue={(searchTags) => onChangeSearchTag(searchTags)}
      />
    </div>
  )
}

const DetailModelSearchKeywordContentTextField: FunctionComponent<{
  label: string
  inputValue: string
  inputPlaceholder?: string
  onChangeInputValue: (inputValue: string) => void
}> = ({ label, inputValue, inputPlaceholder, onChangeInputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "start" }}>
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <textarea
        style={{
          flex: 1,
          height: 160,
          padding: 16,
          border: "1px solid #CED2D6",
          borderRadius: 4,
          outline: "none",
          lineHeight: "24px",
          fontSize: 16,
          fontFamily: "Pretendard",
          resize: "vertical",
        }}
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={(event) => onChangeInputValue(event.target.value)}
      />
    </div>
  )
}
