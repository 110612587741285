import styled from "@emotion/styled"
import { FC } from "react"
const CloseIconSrc = require("images/16_cross.png")

type Props = {
  url: string
  id?: number
  deleteImage: () => void
}

export const EditableImage: FC<Props> = ({ url, deleteImage }) => {
  return (
    <Wrapper>
      <Image src={url} alt="registration image" />
      <CloseButton onClick={deleteImage}>
        <img src={CloseIconSrc} style={{ width: "16px", height: "16px" }} alt="close icon" />
      </CloseButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  border: 1px solid #ced2d6;
  overflow: hidden;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
`

const CloseButton = styled.button`
  position: absolute;
  bottom: 4px;
  right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  outline: none;
  border: none;
  border-radius: 4px;
  background: #fff;
`
