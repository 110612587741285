import {Box, Button, Stack} from "@mui/material";
import ServiceBannerTableFilter from "./ServiceBannerTableFilter";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {DateTime} from "luxon";
import ShopServiceBannerAPI from "../../../apis/shopServiceBannerAPI";
import EditIcon from "@mui/icons-material/Edit";
import {PageType} from "../../../types/PageType";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import _ from "lodash"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ShopServiceBannerType } from "../../../types/ShopServiceBannerType"

function renderPreviewCell(params: any) {
    return (<img src={params?.row?.bannerImageUrl} style={{width: '100%'}} />)
}
function renderStatusCell(params: any) {
    if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
    const startedAt = DateTime.fromISO(params?.row?.startedAt)
    const endedAt = DateTime.fromISO(params?.row?.endedAt)

    return (
      <Stack direction={'column'} alignItems={"self-end"}>
          <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
          <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      </Stack>
    )
}


interface ServiceBannerTableFilterState {
    postingStatus: string | null,
    page: number | null,
    size: number | null
}

const initServiceBannerTableFilter: ServiceBannerTableFilterState = {
    postingStatus: null,
    page: 0,
    size: 50
}

//@ts-ignore
const ServiceBannerTable = ({handleSelection, handleUpdateServiceBanner, refreshCounter}) => {
    const [tableFilter, setTableFilter] = useState<ServiceBannerTableFilterState>(initServiceBannerTableFilter)
    const [serviceBanners, setServiceBanners] = useState<PageType<ShopServiceBannerType>>({content: []})
    const [rows, setRows] = useState<ShopServiceBannerType[]>([])

    const getColumns = () => {
        return [
            {field: 'id', headerName: "ID", width: 70},
            { field: "title", headerName: "제목", flex: 2 },
            {field: 'createdAt', headerName: "등록 일시", width: 180, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
            {field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
            {field: 'postingStatus', headerName: "진행 상태", width: 100, renderCell: renderStatusCell},
            {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
            {field: 'showDomesticUser',  width: 80,
              renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                  <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
                </div>
              ),
              renderHeader: () => (
                <div style={{  textAlign: "center", lineHeight: "1.2" }}>
                  내국인 회원<br />노출
                </div>
              ),
            },
            { field: "showForeignUser", width: 80,
              renderCell: (params: any) => (
                <div
                  style={{ textAlign: "center"  }}
                >
                  <span>{params.row.showForeignUser ? "Y" : "N"}</span>
                </div>
              ),
              renderHeader: () => (
                <div style={{ textAlign: "center", lineHeight: "1.2" }}>
                  외국인 회원<br />노출
                </div>
              ),
            },
            {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
            {field: 'actions', headerName: "순서(위치이동)", type: 'actions',  width: 110, getActions: (params: any) => ([
                    <GridActionsCellItem
                      icon={<ArrowUpwardIcon/>}
                      label={"위로"}
                      onClick={() => {
                          const index = _.findIndex(rows, {id: params.row.id})
                          if (index > 0) {
                            let updatedRows = [
                              ...(_.slice(rows, 0, index-1)),
                              rows[index],
                              rows[index-1],
                              ...(_.slice(rows, index+1, rows.length)),
                            ]

                            updatedRows = updatedRows.map(data => ({
                              ...data,
                              priority: null
                            })) ;

                            setRows(updatedRows);
                          }
                      }}
                    />,
                    <GridActionsCellItem
                      icon={<ArrowDownwardIcon/>}
                      label={"아래로"}
                      onClick={() => {
                          const index = _.findIndex(rows, {id: params.row.id})
                          if (index < rows.length-1) {
                            let updatedRows = [
                              ...(_.slice(rows, 0, index)),
                              rows[index+1],
                              rows[index],
                              ...(_.slice(rows, index+2, rows.length)),
                            ]

                            updatedRows = updatedRows.map(data => ({
                              ...data,
                              priority: null
                            })) ;

                            setRows(updatedRows);
                          }
                      }}
                    />,
                    <GridActionsCellItem
                      icon = {<EditIcon />}
                      label = {"수정"}
                      onClick={() => {
                          handleUpdateServiceBanner(params.row)
                      }}
                      // showInMenu
                    />
                ])},
        ]
    }

    const fetch = (tableFilter: any = {}) => {
      ShopServiceBannerAPI.getPage(tableFilter).then((result) => {
            setServiceBanners(result)
            setRows(result?.content)
        })
    }

    useEffect(() => {
        fetch(tableFilter)
    }, [tableFilter, refreshCounter])

    const handleSubmitReordering = () => {
      ShopServiceBannerAPI.reordering(rows).then((result) => {
          fetch(tableFilter)
        }
      )
    }

    return (
        <Stack spacing={2}>
            <ServiceBannerTableFilter
                filter ={tableFilter}
                handleFilterChange={(changedFilter: ServiceBannerTableFilterState) => { setTableFilter(changedFilter) }}
                handleSubmitReordering = {handleSubmitReordering}
            />
            <Box sx={{height: 720, width: '100%'}}>
                <DataGrid
                    columns={getColumns()}
                    getRowHeight={() => 70}
                    onSelectionModelChange={handleSelection}
                    checkboxSelection

                    paginationMode="server"
                    onPageChange={(page) => {
                        setTableFilter({...tableFilter, page: page})
                    }}
                    onPageSizeChange={(size) => {
                        setTableFilter({...tableFilter, size: size})
                    }}

                    page={serviceBanners.pageable?.pageNumber}
                    pageSize={serviceBanners.pageable?.pageSize}
                    rowCount={serviceBanners?.totalElements}
                    rows={rows}
                    onCellEditCommit={(params) => {
                      const updatedRows = rows.map(row => {
                        if (row.id === params.id) {
                          return { ...row, priority: params.value };
                        }
                        return row;
                      });
                      setRows(updatedRows);
                    }}
                />
            </Box>
        </Stack>
    )
}

export default ServiceBannerTable