import styled from "@emotion/styled"
import Button from "components/Button"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { useState } from "react"
import { toDateTimeStr } from "utils/datetimeUtil"
import { numberWithCommas } from "utils/NumberUtils"
import PenaltyRefundModal from "../PenaltyRefundModal"

type Props = {
  paymentAmount: number
  refundAmount: number | null // 환불 금액
  refundReason: string | null // 환불 사유
  refundedAt: string | null // 환불 일시
  orderId: string
  refetch: () => void
}

const PenaltyRefundInformation = ({
  refetch,
  orderId,
  refundedAt,
  refundReason,
  refundAmount,
  paymentAmount,
}: Props) => {
  const [isOpenRefundPenaltyModal, setIsOpenRefundPenaltyModal] = useState(false)
  return (
    <>
      <PenaltyRefundModal
        refetch={refetch}
        isOpen={isOpenRefundPenaltyModal}
        closeModal={() => setIsOpenRefundPenaltyModal(false)}
        orderId={orderId}
      />
      {!refundedAt && (
        <Header>
          <ButtonWrapper>
            <Button onClick={() => setIsOpenRefundPenaltyModal(true)}>패널티 조정</Button>
          </ButtonWrapper>
        </Header>
      )}
      {refundedAt ? (
        <TableWrapper>
          <InlineWrapper>
            <DetailTableColumCell title={"조정일시"}>{toDateTimeStr(refundedAt)}</DetailTableColumCell>
            <DetailTableColumCell title={"청구 금액"}>{numberWithCommas(paymentAmount)}원</DetailTableColumCell>
            <DetailTableColumCell title={"조정 금액 (환불 금액)"}>{numberWithCommas(refundAmount) || 0}원</DetailTableColumCell>
            <DetailTableColumCell title={"최종 결제 금액"}>{numberWithCommas((paymentAmount ||0 ) - (refundAmount || 0) )}원</DetailTableColumCell>
            <DetailTableColumCell title={"조정 사유"}>{refundReason || "-"}</DetailTableColumCell>
          </InlineWrapper>
        </TableWrapper>
      ) : (
        <EmptyRefundInformation>
          <p>패널티 조정 내역이 없습니다.</p>
        </EmptyRefundInformation>
      )}
    </>
  )
}

export default PenaltyRefundInformation

const TableWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  div.table-column-cell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`

const ButtonWrapper = styled.div`
  width: 150px;
  margin-bottom: 20px;
`

const Header = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
`
const EmptyRefundInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
