import { Checkbox, FormControlLabel, Radio, RadioGroup, TextareaAutosize, TextField, Typography } from "@mui/material"
import { SellerProvidedProductInfo } from "types/ProductType"
import { DateTime } from "luxon"
import {
  Accessories,
  AccessoriesConditionCode,
  ComponentsType,
  DiagnosisType,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  ProductConditionCode,
} from "types/DiagnosisType"
import { SerialNumberFinder } from "components/SerialNumber/SerialNumberFinder"
import { BrandType } from "../../../../types/BrandType"
import { isNil, isNotNil } from "utils/validationUtils"
import React, { useEffect, useState } from "react"

type Props = {
  brand: BrandType
  sellerProvidedProductInfo?: SellerProvidedProductInfo
  components?: ComponentsType
  serialNo: string
  disabled?: boolean
  isGuaranteedProduct?: boolean
  formattedDate: string
  changeFormattedDate: React.Dispatch<React.SetStateAction<string>>
  checkAccessoriesAll: (accessories: Accessories) => boolean
  checkAccessoriesNone: (accessories: Accessories) => boolean
  changeDiagnosisStateAndRequestResellPrice: (key: keyof DiagnosisType, newState: any) => void
  changeDiagnosisState: (key: keyof DiagnosisType, newState: any) => void
}

const ProductCondition = ({
  brand,
  serialNo,
  sellerProvidedProductInfo,
  components,
  disabled = false,
  isGuaranteedProduct = false,
  formattedDate,
  changeFormattedDate,
  checkAccessoriesAll,
  checkAccessoriesNone,
  changeDiagnosisState,
  changeDiagnosisStateAndRequestResellPrice,
}: Props) => {
  if (isNil(components)) return <></>

  return (
    <>
      <h2>상품상태</h2>

      {!isGuaranteedProduct && (
        <>
          <h3>고객입력</h3>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>제품상태</td>
                <td className={"header"}>보증서</td>
                <td className={"header"}>정품박스</td>
                <td className={"header"}>부속품</td>
                <td className={"header"}>스탬핑 연도</td>
              </tr>
              <tr>
                <td>{sellerProvidedProductInfo?.productCondition}</td>
                <td>{sellerProvidedProductInfo?.officialGuarantee}</td>
                <td>{sellerProvidedProductInfo?.originalBoxCondition}</td>
                <td>{sellerProvidedProductInfo?.accessoriesCondition}</td>
                <td>{sellerProvidedProductInfo?.stampingYear}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <h3>바이버체크</h3>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"header"} colSpan={1} width={"15%"}>
              시리얼 넘버
            </td>
            <td className={"header"} width={"15%"}>
              제품상태
            </td>
            <td className={"header"} width={"15%"}>
              보증서
            </td>
            <td className={"header"} width={"15%"}>
              정품박스
            </td>
            <td className={"header"} width={"20%"}>
              부속품
            </td>
            <td className={"header"}>스탬핑 일자</td>
          </tr>
          <tr>
            <td colSpan={1}>
              <div style={{ margin: "0 10px" }}>
                <SerialNumberFinder
                  value={serialNo || ""}
                  onChangeValue={(value) => changeDiagnosisState("serialNo", value)}
                  onSelect={(serial) => changeDiagnosisState("serialNo", serial?.serialNo)}
                />
              </div>
            </td>
            <td colSpan={1}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) => {
                  changeDiagnosisStateAndRequestResellPrice("components", {
                    ...components,
                    staffConfirmed: { ...components.staffConfirmed, productCondition: value },
                  })
                }}
              >
                {[ProductConditionCode.미착용, ProductConditionCode.중고].map((value) => (
                  <FormControlLabel
                    value={value}
                    label={value}
                    disabled={disabled}
                    checked={components.staffConfirmed?.productCondition === value}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </td>
            <td colSpan={1}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) =>
                  changeDiagnosisState("components", {
                    ...components,
                    staffConfirmed: { ...components.staffConfirmed, officialGuarantee: value },
                  })
                }
              >
                {[OfficialGuaranteeCode.있음, OfficialGuaranteeCode.없음].map((value) => (
                  <FormControlLabel
                    value={value}
                    label={value}
                    disabled={disabled}
                    checked={components.staffConfirmed?.officialGuarantee === value}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </td>
            <td colSpan={1}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) =>
                  changeDiagnosisState("components", {
                    ...components,
                    staffConfirmed: { ...components.staffConfirmed, originalBoxCondition: value },
                  })
                }
              >
                {[OriginalBoxConditionCode.있음, OriginalBoxConditionCode.없음].map((value) => (
                  <FormControlLabel
                    value={value}
                    label={value}
                    disabled={disabled}
                    checked={components.staffConfirmed?.originalBoxCondition === value}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </td>

            <td colSpan={1}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) => {
                  const changedComponents = JSON.parse(JSON.stringify(components))
                  changedComponents.staffConfirmed.accessoriesCondition = value

                  changeDiagnosisStateAndRequestResellPrice("components", changedComponents)
                }}
              >
                {[AccessoriesConditionCode.있음, AccessoriesConditionCode.없음, AccessoriesConditionCode.일부파손].map(
                  (value) => (
                    <FormControlLabel
                      value={value}
                      label={value}
                      disabled={disabled}
                      checked={components.staffConfirmed?.accessoriesCondition === value}
                      control={<Radio />}
                    />
                  )
                )}
              </RadioGroup>
            </td>

            <td colSpan={1}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <TextField
                  variant="standard"
                  style={{ width: "50%" }}
                  inputProps={{ maxLength: 10 }}
                  type={"text"}
                  placeholder={"DD/MM/YYYY"}
                  disabled={components.staffConfirmed?.officialGuarantee === "없음"}
                  value={formattedDate}
                  onChange={(event) => {
                    changeDiagnosisStateAndRequestResellPrice("components", {
                      ...components,
                      staffConfirmed: { ...components.staffConfirmed, stampingDate: event.target.value },
                    })

                    if (changeFormattedDate) {
                      changeFormattedDate(event.target.value)
                    }
                  }}
                />
                <FormControlLabel
                  value={"연도만 표기"}
                  label={"연도만 표기"}
                  disabled={components.staffConfirmed?.officialGuarantee == "없음"}
                  checked={components.staffConfirmed?.onDisplayStampingYear ?? false}
                  control={<Checkbox />}
                  onChange={(_, value) => {
                    changeDiagnosisState("components", {
                      ...components,
                      staffConfirmed: { ...components.staffConfirmed, onDisplayStampingYear: value },
                    })
                  }}
                />
              </div>
              <RadioGroup row sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography style={{ marginLeft: 10 }}>정식 A/S기간&nbsp;</Typography>
                <div style={{ marginRight: 10 }}>
                  <FormControlLabel
                    label="내"
                    checked={
                      formattedDate
                        ? DateTime.fromFormat(formattedDate, "dd/MM/yyyy").diffNow("years").years >= -5
                        : false
                    }
                    control={<Radio color={"default"} />}
                  />
                  <FormControlLabel
                    label="외"
                    checked={
                      formattedDate
                        ? DateTime.fromFormat(formattedDate, "dd/MM/yyyy").diffNow("years").years < -5
                        : false
                    }
                    control={<Radio color={"default"} />}
                  />
                </div>
              </RadioGroup>
            </td>
          </tr>
          <tr>
            <td className={"header"} colSpan={4}>
              상세 부속품 체크리스트
              <br />
              <span style={{ fontWeight: 400, fontSize: "10pt", color: "#777" }}>(있는 부속품만 체크해주세요)</span>
            </td>
            <td className={"header"} colSpan={2}>
              특이사항
            </td>
          </tr>
          <tr>
            {isNotNil(brand) ? (
              <td colSpan={4}>
                {/*<div style={{ width: "400px", margin: "10px auto" }}>*/}
                {brand.accessories?.map(({ value, key }) => (
                  <FormControlLabel
                    label={value}
                    checked={!!components.staffConfirmed.accessories[key]}
                    control={<Checkbox />}
                    disabled={disabled}
                    onChange={(event, value) => {
                      const changedComponents: ComponentsType = components
                      changedComponents.staffConfirmed.accessories[key] = value

                      if (checkAccessoriesAll(components.staffConfirmed.accessories))
                        components.staffConfirmed.accessoriesCondition = AccessoriesConditionCode.없음
                      else if (checkAccessoriesNone(components.staffConfirmed.accessories))
                        components.staffConfirmed!.accessoriesCondition = AccessoriesConditionCode.없음
                      else components.staffConfirmed!.accessoriesCondition = AccessoriesConditionCode.일부파손

                      changeDiagnosisStateAndRequestResellPrice("components", changedComponents)
                    }}
                  />
                ))}
                {/*</div>*/}
              </td>
            ) : (
              <td colSpan={3} />
            )}
            <td colSpan={2}>
              <TextField
                variant="standard"
                type={"text"}
                fullWidth
                multiline
                disabled={disabled}
                value={components.staffConfirmed.note}
                onChange={(event) =>
                  changeDiagnosisState("components", {
                    ...components,
                    staffConfirmed: { ...components.staffConfirmed, note: event.target.value },
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>

      <h3>진단 후 패키지 상태 일치 여부 (고객이 입력한 상태와 바이버 진단 상태)</h3>

      <table className={"basic"}>
        <tbody>
          <tr>
            <td colSpan={2} className={"header"}>
              바이버 진단결과
            </td>
            <td colSpan={3} className={"header"}>
              불일치 시 상세 설명
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) =>
                  changeDiagnosisState("components", { ...components, matched: event.target.value })
                }
              >
                {[
                  { label: "일치", value: true },
                  { label: "불일치", value: false },
                ].map(({ label, value }) => (
                  <FormControlLabel
                    value={value}
                    label={label}
                    checked={`${components.matched}` === `${value}`}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </td>
            <td colSpan={3} className={"ta-left"}>
              <TextField
                variant="standard"
                fullWidth={true}
                type={"text"}
                multiline={true}
                value={components.mismatchedReason}
                onChange={(event) => {
                  changeDiagnosisState("components", { ...components, mismatchedReason: event.target.value })
                }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className={"header"}>
              진단자 메모
            </td>
          </tr>
          <tr>
            <td colSpan={5} className={"ta-left"}>
              <TextareaAutosize
                style={{ width: "100%" }}
                minRows={3}
                value={components.note}
                onChange={(event) => changeDiagnosisState("components", { ...components, note: event.target.value })}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default ProductCondition
