import CheckBox from "components/Checkbox"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const penaltyTableHeaderItemList = [
  {
    title: "CHECKBOX",
    width: "5%",
  },
  {
    title: "판매주문번호 \n(상품번호)",
    width: "10%",
  },
  {
    title: "패널티 상태",
    width: "5%",
  },
  {
    title: "반송 상태",
    width: "8%",
  },
  {
    title: "상품명 (Ref.No)",
    width: "20%",
  },
  {
    title: "판매자 ID",
    width: "8%",
  },
  {
    title: "판매자 정보",
    width: "8%",
  },
  {
    title: "패널티 금액(원)",
    width: "10%",
  },
  {
    title: "결제 금액(원)",
    width: "10%",
  },
  {
    title: "판매 신청일",
    width: "8%",
  },
  {
    title: "패널티 발생일",
    width: "8%",
  },
  {
    title: "패널티 완납일",
    width: "8%",
  },
]

type Props = {
  isCheckboxHidden: boolean
  isAllChecked: boolean
  toggleAllPenaltyForShipping: () => void
}

const PenaltyListTableHeader = ({ isCheckboxHidden, toggleAllPenaltyForShipping, isAllChecked }: Props) => {
  return (
    <ListTableHeader>
      {penaltyTableHeaderItemList.map((headerItem, index) => {
        if (headerItem.title === "CHECKBOX") {
          if (isCheckboxHidden) {
            return <div key={headerItem.title} />
          }

          return (
            <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
              <CheckBox checked={isAllChecked} onChange={toggleAllPenaltyForShipping} />
            </ListTableHeaderItem>
          )
        }

        return (
          <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
            {headerItem.title}
          </ListTableHeaderItem>
        )
      })}
    </ListTableHeader>
  )
}

export default PenaltyListTableHeader
