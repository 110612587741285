import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { ShippingType } from "types/ShippingType"

export const getShippings = (sellOrderList: SellOrderType[], selectedIdList: string[]): ShippingType[] => {
  return sellOrderList
    .filter(
      (sellOrder) =>
        selectedIdList.includes(sellOrder.id!) && (sellOrder.sellOrderStatus === SellOrderStatusCode.픽업_준비 || sellOrder.sellOrderStatus === SellOrderStatusCode.입고중)
    )
    .map((sellOrder) => {
      return {
        ...sellOrder.shipping!,
        orderId: sellOrder?.id,
        productId: sellOrder?.product?.id,
        productTitle: sellOrder?.product?.title,
        quantity: 1,
        weight: 1,
        price: sellOrder?.product?.initialPrice,
      }
    })
}

export const getSelectedOrders = (sellOrderList: SellOrderType[], selectedIdList: string[]): SellOrderType[] => {
  return sellOrderList.filter((sellOrder) => selectedIdList.includes(sellOrder?.id || ""))
}
