import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { headerItemList } from "pages/User/UserListPage/UserListTable/TableHeader"
import { UserType, UserTypeCode, UserTypeCodeList } from "types/UserType"
import { DateTime } from "luxon"
import { MenuItem } from "@mui/material"
import { isEqual } from "lodash"

type Props = {
  user: UserType
} & HTMLAttributes<HTMLDivElement>

const UserListTableRow = ({ user, ...props }: PropsWithChildren<Props>) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: headerItemList[0].width }}>{user.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>{user.authType}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>{user.email}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[3].width }}>{converEmtpyValueToDash(user.name)}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[4].width }}>{converEmtpyValueToDash(user.nickname)}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[5].width }}>{converEmtpyValueToDash(user.phone)}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[6].width }}>{converEmtpyValueToDash(user.status)}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[7].width }}>{user.foreigner ? "Y" : "N"}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[8].width }}>
        {UserTypeCodeList.filter((item) => isEqual(item.value, user.userType)).map((item) => item.title)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[9].width }}>
        {user.signupAt ? `${DateTime.fromISO(user.signupAt).toISODate()}` : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[10].width }}>{user.globalSale ? "Y" : "N"}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[11].width }}>{user.hasMemo ? "O" : "-"}</TableRowItem>
    </TableRow>
  )
}

export default UserListTableRow
