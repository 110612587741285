import {FunctionComponent, useEffect, useRef} from "react";
import * as monaco from "monaco-editor";
import {isNil} from "lodash";
import {ContentBlock} from "../../../types/MagazineType";

const ArticleJsonEditor: FunctionComponent<{
    initialContentBlock: ContentBlock[]
    onChangeJson: (json: string) => void
    onChangeCursorSelection: (str?: string) => void
}> = ({ initialContentBlock, onChangeJson, onChangeCursorSelection }) => {

    const containerRef = useRef<HTMLDivElement>(null)
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>()

    useEffect(() => {
        const editor = editorRef.current
        if (containerRef.current instanceof HTMLElement) {
            editorRef.current = monaco.editor.create(containerRef.current, {
                value: JSON.stringify(initialContentBlock, null, 2),
                renderWhitespace: "all",
                scrollBeyondLastLine: false,
                lineDecorationsWidth: 0,
                wordWrap: "bounded",
                autoIndent: "none",
                minimap: {enabled: false},
            })
        }
        return () => {
            editor?.dispose()
        }
    }, [])

    useEffect(() => {
        editorRef.current?.setValue(JSON.stringify(initialContentBlock, null, 2))
    }, [initialContentBlock])

    useEffect(() => {
        const editor = editorRef.current
        const editorTextModel = editor?.getModel()

        editor?.onDidChangeCursorSelection((e) => {
            if (!isNil(editor)) {
                const selected = editor.getModel()?.getValueInRange(e.selection)
                onChangeCursorSelection(selected)
            }
        })
        editorTextModel?.onDidChangeContent((e) => {
            if (!isNil(editor)) {
                onChangeJson(editor.getValue())
            }
        })
    }, [onChangeJson, onChangeCursorSelection])

    return (
      <div ref={containerRef} style={{border: "1px solid #ccc", height: 550, flex: 1}} />
    )
}

export default ArticleJsonEditor