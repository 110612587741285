import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import { PointListSearchParams } from "types/PointListItemType"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { PointListSearchType } from ".."
import { PointReasonType, PointStatusCode } from "types/PointType"

const searchPointMenuList: { value: keyof PointListSearchParams; title: string }[] = [
  { value: "status", title: "상태" },
  { value: "reasonType", title: "지급 사유" },
  { value: "reasonMessage", title: "지급 메세지" },
]

const statusMenuList: { value: PointStatusCode; title: string }[] = [
  {
    value: PointStatusCode.활성,
    title: "활성",
  },
  {
    value: PointStatusCode.만료,
    title: "만료",
  },
  {
    value: PointStatusCode.취소,
    title: "취소",
  },
]

const reasonTypeMenuList: { value: PointReasonType; title: string }[] = [
  {
    value: "이벤트 적립",
    title: "이벤트 적립",
  },
  {
    value: "수동 적립",
    title: "수동 적립",
  },
  {
    value: "수동 차감",
    title: "수동 차감",
  },
]

type Props = {
  searchParams: PointListSearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: keyof PointListSearchParams) => void
  onSearch: (keyword: PointListSearchType["keyword"]) => void
}

const PointListTableSearchBar = ({ searchParams, changeSearchKeyword, changeSearchFilterValue, onSearch }: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"status"}
          required
          onChange={(e) => changeSearchFilterValue(e.target.value as keyof PointListSearchParams)}
        >
          {searchPointMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        {searchParams.filter === "status" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as PointStatusCode
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              {statusMenuList.map((menu) => (
                <MenuItem value={menu.value} key={menu.title}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {searchParams.filter === "reasonType" && (
          <FormControl style={{ minWidth: "350px" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as PointReasonType
                changeSearchKeyword(keyword)
                onSearch(keyword)
              }}
            >
              {reasonTypeMenuList.map((menu) => (
                <MenuItem value={menu.value} key={menu.title}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {searchParams.filter === "reasonMessage" && (
          <SearchInput
            style={{ height: 40 }}
            value={searchParams.keyword}
            onChange={(e) => changeSearchKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(searchParams.keyword)
              }
            }}
          />
        )}
      </SearchBar>
    </Wrapper>
  )
}

export default PointListTableSearchBar

const Wrapper = styled.div`
  width: 500px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
