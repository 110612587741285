import { DataGrid, GridColumnHeaderParams } from "@mui/x-data-grid"
import React from "react"
import { numberWithCommas } from "../../../utils/NumberUtils"

//@ts-ignore
const OfficialModelTable = ({ rows, metricsMonthList, onPageChange, handleCellUpdate, onSelectionModelChange, selectedIds, page, size, totalCount }) => {
  const columns = [
    {
      field: "brandSid",
      headerName: "브랜드",
      editable: false,
      sortable: false,
      type: "string",
      width: 110,
      valueGetter: (params: any) => `${params.row.brandSid}`,
    },
    {
      field: "modelName",
      headerName: "모델명",
      editable: false,
      sortable: false,
      type: "string",
      width: 260,
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.modelName}</div>
      },

    },
    {
      field: "fullRefNo",
      headerName: "Full Ref No.",
      editable: false,
      sortable: false,
      type: "string",
      width: 150,
      renderCell: (params: any) => {
        return <div style={{ whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.fullRefNo}</div>
      },
    },
    {
      field: "modelDetailUrl",
      headerName: "최종 모델 URL",
      editable: true,
      sortable: false,
      type: "string",
      flex: 10,
      renderCell: (params: any) => {
        return <div style={{ whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}><a target="_blank"
                                                                                                                                              href={params.row.modelDetailUrl}>{params.row.modelDetailUrl}</a>
        </div>
      },
    },
    {
      field: "detailModelRequestCount",
      editable: false,
      sortable: false,
      type: "string",
      width: 80,
      headerAlign: "center" as const,
      align: "center" as const,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: 1, textAlign: "center", width: "100%" }}>
          모델등록<br />요청건수
        </div>
      ),
      valueGetter: (params: any) => `${params.row.detailModelRequestCount}건`,
    },
    {
      field: "detailModelExists",
      editable: false,
      sortable: false,
      headerAlign: "center" as const,
      width: 80,
      align: "center" as const,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: 1, textAlign: "center", width: "100%" }}>
          모델<br />생성 YN
        </div>
      ),
      valueGetter: (params: any) => `${params.row.detailModelId ? "Y" : "N"}`,
    },
    {
      field: `firstMetricsMonth`,
      editable: true,
      sortable: false,
      headerAlign: "center" as const,
      width: 100,
      align: "center" as const,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: 1, textAlign: "center", width: "100%" }}>
          예상<br />출시월
        </div>
      ),
      valueGetter: (params: any) => `${params.row.firstMetricsMonth ?? "-"}`,
    },
    {
      field: `lastMetricsMonth`,
      editable: true,
      sortable: false,
      headerAlign: "center" as const,
      width: 100,
      align: "center" as const,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: 1, textAlign: "center", width: "100%" }}>
          예상<br />단종월
        </div>
      ),
      valueGetter: (params: any) => `${params.row.lastMetricsMonth ?? "-"}`,
    },
    ...metricsMonthList.map((metricsMonth: string) => ({
      field: `metricsMonth_${metricsMonth}`,
      editable: false,
      sortable: false,
      headerName: metricsMonth,
      width: 150,
      align: "center" as const,
      valueGetter: (params: any) => params.row.officialPriceList[metricsMonth] ? `${numberWithCommas(params.row.officialPriceList[metricsMonth])}원` : "-",
    })),
  ]

  return (
    <div style={{ height: 1200, width: "100%", overflow: "scroll" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
        pagination
        page={page}
        pageSize={size}
        rowCount={totalCount}
        onPageChange={onPageChange}
        paginationMode={"server"}
        selectionModel={selectedIds}
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        disableSelectionOnClick
        disableColumnMenu={true}
        getRowHeight={(params) => undefined}
        onCellEditCommit={handleCellUpdate}
      />

    </div>
  )
}

export default OfficialModelTable