import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import { toDateTimeStr } from "utils/datetimeUtil"

export const headerItemList = [
  {
    title: "메모",
    width: "70%",
    getValue: (value: string) => value,
  },
  {
    title: "작성자",
    width: "10%",
    getValue: (value: string) => value,
  },
  {
    title: "작성일",
    width: "15%",
    getValue: (value: string) => toDateTimeStr(value),
  },
]

const SerialNumberMemoListTableHeader = () => {
  return (
    <ListTableHeader>
      {headerItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default SerialNumberMemoListTableHeader
