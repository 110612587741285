import { Box, Button, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"

import EditIcon from "@mui/icons-material/Edit"
import { deleteMyPageBannerList, getMyPageBannerList } from "apis/myPageBannerAPI"
import { BannerExposureAreaCodeList, MyPageBannerType } from "types/MyPageBannerType"
import { Spacer } from "components/Spacer"
import { isEmpty } from "lodash"
import { MyPageBannerEditor } from "./MyPageBannerEditPopup"
import { DateTime } from "luxon"
import MyPageBannerFilter from "./MyPageBannerFilter"
import { PageType } from "../../../../types/PageType"

type MyPageBannerTableFilterState = {
  page: number
  size: number
  postingStatus: string | null
  exposureArea: string | "all"
}

function renderStatusCell(params: any) {
  if (params?.row?.postingStatus === "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

export const MyPageBannerList = () => {
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false)
  const [editingBannerId, setEditingBannerId] = useState<number | undefined>(undefined)
  const [list, setList] = useState<MyPageBannerType[]>([])
  const [selectedIdList, setSelectedIdList] = useState<number[]>([])
  const [tableFilter, setTableFilter] = useState<MyPageBannerTableFilterState>({
    page: 0,
    size: 50,
    postingStatus: null,
    exposureArea: "all"
  })
  const [MyPageBanners, setMyPageBanners] = useState<PageType<MyPageBannerType>>({content: []})


  const fetch = (tableFilter: any = {}) => {
    getMyPageBannerList(tableFilter).then((result) => {
      setMyPageBanners(result)
      setList(result.content)
    })
  }

  useEffect(() => {
    fetch(tableFilter)
  }, [tableFilter])

  const closeEditor = () => {
    setIsOpenEditPopup(false)
    setEditingBannerId(undefined)
  }


  const handleDeleteMyPageBanner = () => {
    if (window.confirm(`${selectedIdList.length}개의 배너를 삭제하시겠습니까?`)) {
       deleteMyPageBannerList(selectedIdList).then(() => {
        fetch(tableFilter)
      })
    }
  }

  const getColumns = () => {
    return [
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={"수정"}
            onClick={() => {
              setEditingBannerId(params.row.id)
              setIsOpenEditPopup(true)
            }}
            showInMenu
          />,
        ],
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "bannerLinkUrl", headerName: "링크 URL", flex: 1 },
      {
        field: "bannerImageUrl",
        headerName: "미리보기",
        width: 180,
        renderCell: (params: any) => (
          <img
            src={params.row.bannerImageUrl}
            style={{
              width: "150px",
            }}
            alt="미리보기"
          />
        ),
      },
      {
        field: "exposureAreas",
        headerName: "노출 영역",
        width: 150,
        renderCell: (params: any) => {
          return (
            <div>
              {params.row.exposureAreas.split(',').map((area: string, index: number) => {
                const trimmedArea = area.trim();
                const matchedArea = BannerExposureAreaCodeList.find(item => item.value === trimmedArea);
                return (
                  <div key={index}>{matchedArea ? matchedArea.title : trimmedArea}</div>
                );
              })}
            </div>
          )
        },
      },
      {field: 'showDomesticUser',  width: 80,
        renderCell: (params: any) => (
          <div style={{textAlign: "center"}}>
            <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
          </div>
        ),
        renderHeader: () => (
          <div style={{  textAlign: "center", lineHeight: "1.2" }}>
            내국인 회원<br />노출
          </div>
        ),
      },
      { field: "showForeignUser", width: 80,
        renderCell: (params: any) => (
          <div
            style={{ textAlign: "center"  }}
          >
            <span>{params.row.showForeignUser ? "Y" : "N"}</span>
          </div>
        ),
        renderHeader: () => (
          <div style={{ textAlign: "center", lineHeight: "1.2" }}>
            외국인 회원<br />노출
          </div>
        ),
      },
      {
        field: "title1",
        headerName: "텍스트",
        width: 180,
        renderCell: (params: any) => {
          return (
            <div>
              <div>{params.row.title1}</div>
              <div>{params.row.title2}</div>
            </div>
          )
        },
      },
      { field: "priority", headerName: "정렬순서", width: 70 },
      {
        field: "startedAt",
        headerName: "노출 기간",
        width: 180,
        renderCell: (params: any) => {
          const startedAt = DateTime.fromISO(params?.row?.startedAt)
          const endedAt = DateTime.fromISO(params?.row?.endedAt)

          return (
            <Stack direction={'column'} alignItems={"self-end"}>
              <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
              <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
            </Stack>
          )
        },
      },
      { field: "postingStatus", headerName: "진행 상태", width: 100, renderCell: renderStatusCell }
    ]
  }

  return (
    <>
      {isOpenEditPopup && (
        <MyPageBannerEditor
          editingBannerId={editingBannerId}
          close={closeEditor}
          refreshList={() => {
            fetch(tableFilter)
          }}
        />
      )}
      <Stack spacing={2}>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setIsOpenEditPopup(true)
              setEditingBannerId(undefined)
            }}
          >
            추가
          </Button>
          <Spacer space={10} horizontal />
          <Button
            variant="contained"
            color="error"
            disabled={isEmpty(selectedIdList)}
            onClick={handleDeleteMyPageBanner}
          >
            삭제
          </Button>
        </div>
        <MyPageBannerFilter
          filter={tableFilter}
          handleFilterChange={(changedFilter: MyPageBannerTableFilterState) => {
           setTableFilter(changedFilter)
          }}
        />
        <Box sx={{ height: 720, width: "100%" }}>
          <DataGrid<MyPageBannerType>
            columns={getColumns()}
            rows={list}
            getRowHeight={() => 110}
            onSelectionModelChange={(ids: any) => setSelectedIdList(ids as number[])}
            checkboxSelection
            paginationMode="server"
            onPageChange={(page) => {
              setTableFilter({...tableFilter, page: page})
            }}
            onPageSizeChange={(size) => {
              setTableFilter({...tableFilter, size: size})
            }}
            page={MyPageBanners.pageable?.pageNumber}
            pageSize={MyPageBanners.pageable?.pageSize}
            rowCount={MyPageBanners?.totalElements}
          />
        </Box>
      </Stack>
    </>
  )
}
