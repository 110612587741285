import { DiagnosisIssueListState } from "./index"
import { Button, Grid } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import { BuyOrderStatusCode } from "../../../types/BuyOrderType"
import { ShippingStatusCode } from "../../../types/ShippingType"
import { toDateTimeStr } from "../../../utils/datetimeUtil"
import { DiagnosisIssueListType, DiagnosisStatusCode } from "../../../types/DiagnosisType"
import { SellOrderStatusCode } from "../../../types/SellOrderType"
import { getSellOrder } from "../../../apis/sellOrderAPI"

type IssueListProps = {
  state: DiagnosisIssueListState
  dispatch: any
}

function createColumns(dispatch: any): any[] {
  const openDiagnosisPopup = async (row: DiagnosisIssueListType) => {
    dispatch({ type: "START_LOADING" })

    await getSellOrder(row.sellOrderId)
      .then((results) => {
        return dispatch({ type: "FETCH_SELL_ORDER", payload: results })
      })
      .finally(() => dispatch({ type: "END_LOADING" }))

    dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP", payload: row })
  }

  const openBuyOrderPopup = (row: DiagnosisIssueListType) => {
    dispatch({ type: "TOGGLE_BUY_ORDER_POPUP", payload: row })
  }

  const openProductPopup = (row: DiagnosisIssueListType) => {
    dispatch({ type: "TOGGLE_PRODUCT_POPUP", payload: row })
  }

  const openDiagnosisPrintPopup = (row: DiagnosisIssueListType) => {
    dispatch({ type: "TOGGLE_DIAGNOSIS_PRINT_POPUP", payload: row })
  }

  const getColorByStatus = (status: BuyOrderStatusCode | ShippingStatusCode) => {
    switch (status) {
      case BuyOrderStatusCode.결제_대기:
      case BuyOrderStatusCode.상품_준비:
      case ShippingStatusCode.배송_준비:
        return "info"
      case BuyOrderStatusCode.진단중:
      case BuyOrderStatusCode.배송중:
      case ShippingStatusCode.상품_발송:
        return "error"
      case BuyOrderStatusCode.반품_요청완료:
      case BuyOrderStatusCode.구매_취소:
        return "warning"
      case BuyOrderStatusCode.구매_완료:
      case ShippingStatusCode.배송_완료:
        return "success"
    }
  }

  const getDiagnosisColorByStatus = (status: DiagnosisStatusCode | SellOrderStatusCode) => {
    switch (status) {
      case SellOrderStatusCode.판매_포기:
      case SellOrderStatusCode.페널티_대기:
      case SellOrderStatusCode.판매_불합격:
      case DiagnosisStatusCode.진단서_작성_대기:
        return "error"
      case SellOrderStatusCode.입고중:
      case DiagnosisStatusCode.진단서_작성중:
        return "warning"
      case DiagnosisStatusCode.진단서_확정:
      case SellOrderStatusCode.입고_완료:
        return "success"
      default:
        return "info"
    }
  }

  return [
    {
      field: "productId",
      headerName: "상품번호",
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <div style={{ textAlign: "left", textDecoration: "underline" }} onClick={() => openProductPopup(params.row)}>
          <span style={{ fontWeight: "bold" }}>{params.row.productId}</span>
        </div>
      ),
    },
    {
      field: "buyOrderId",
      headerName: "구매주문번호",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 130,
      maxWidth: 160,
      renderCell: (params: any) => (
        <div style={{ textAlign: "left" }}>
          <p>
            <span>{params.row.buyOrderId}</span>
          </p>
        </div>
      ),
    },
    {
      field: "saleType",
      headerName: "판매유형",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      maxWidth: 140,
    },
    {
      field: "buyOrderStatus",
      headerName: "진행상태",
      headerAlign: "center",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.buyOrderStatus}`
      },
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openBuyOrderPopup(params.row)
            }}
            color={getColorByStatus(params.row.buyOrderStatus)}
          >
            {params.row.buyOrderStatus}
          </Button>
        </div>
      ),
    },
    {
      field: "diagnosisStatus",
      headerName: "진단서 확정",
      headerAlign: "center",
      align: "center",
      minWidth: 110,
      maxWidth: 140,
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openDiagnosisPopup(params.row)
            }}
            color={getDiagnosisColorByStatus(params.row.diagnosisStatus)}
          >
            {params.row.diagnosisStatus}
          </Button>
        </div>
      ),
    },
    {
      field: "issue",
      headerName: "진단서 발급",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      maxWidth: 140,
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => openDiagnosisPrintPopup(params.row)}
            color={"error"}
          >
            발급
          </Button>
        </div>
      ),
    },
    {
      field: "diagnosisType",
      headerName: "진단유형",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      maxWidth: 140,
      valueGetter: (params: any) => `${params.row.diagnosisType === true ? "정밀" : "일반"}`,
    },
    {
      field: "productName",
      headerName: "상품명",
      headerAlign: "center",
      align: "center",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "buyerId",
      headerName: "구매자 ID",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      maxWidth: 160,
    },
    {
      field: "orderedAt",
      headerName: "구매일",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 200,
      maxWidth: 200,
      valueGetter: (params: any) => toDateTimeStr(params.value),
    },
  ]
}

const IssueListTable: React.FC<IssueListProps> = ({ state, dispatch }) => {
  return (
    <Grid container spacing={1} sx={{ pt: 1 }}>
      <Grid item xs={12}>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.buyOrderId!}
            columns={createColumns(dispatch)}
            pagination
            paginationMode="server"
            onPageChange={(page) => {
              dispatch({ type: "CHANGE_PAGE_NUMBER", payload: page })
            }}
            onPageSizeChange={(size) => {
              dispatch({ type: "CHANGE_PAGE_SIZE", payload: size })
            }}
            page={state.pageNumber || 0}
            pageSize={state.pageSize || 25}
            rowCount={state.diagnoses?.totalElements || 0}
            rows={state.diagnoses?.content || []}
            selectionModel={state.selectedId}
            // checkboxSelection
            disableSelectionOnClick={true}
            loading={state.loading}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default IssueListTable
