import styled from "@emotion/styled"
import { Pagination } from "@mui/material"
import { FunctionComponent } from "react"

import ListTable from "components/Table/List"
import ListTableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { ModelRequestListItemType } from "types/ModelRequestType"
import { toDateTimeStr } from "utils/datetimeUtil"

const modelRegistrationListTableHeaderItemList: { title: string; width?: string }[] = [
  { title: "요청일", width: "120px" },
  { title: "진행단계", width: "96px" },
  { title: "브랜드", width: "132px" },
  { title: "모델명", width: "80px" },
  { title: "상세모델명" },
  { title: "ref No." },
  { title: "요청자", width: "100px" },
]

export const ModelRegistrationRequestListTable: FunctionComponent<{
  list: {
    content: ModelRequestListItemType[]
    currentPage: number
    totalPages: number
  }
  onChangePage: (page: number) => void
  onClickRow: (id: number) => void
}> = ({ list, onChangePage, onClickRow }) => {
  return (
    <ListTable>
      <ListTableHeader>
        {modelRegistrationListTableHeaderItemList.map(({ title, width }, index) => (
          <ListTableHeaderItem key={index} style={{ maxWidth: width }}>
            {title}
          </ListTableHeaderItem>
        ))}
      </ListTableHeader>
      <ListTableBody>
        {list.content.length < 1 ? (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        ) : (
          list.content.map((listItem) => (
            <TableRow
              key={listItem.id.toString()}
              style={{ height: 48, cursor: "pointer" }}
              onClick={() => onClickRow(listItem.id)}
            >
              <TableRowItem style={{ maxWidth: modelRegistrationListTableHeaderItemList[0].width }}>
                {toDateTimeStr(listItem.requestedAt)}
              </TableRowItem>
              <TableRowItem
                style={{
                  maxWidth: modelRegistrationListTableHeaderItemList[1].width,
                }}
              >
                <StatusBadge status={listItem.status}>{listItem.status}</StatusBadge>
              </TableRowItem>
              <TableRowItem style={{ maxWidth: modelRegistrationListTableHeaderItemList[2].width }}>
                {listItem.brand.name}
              </TableRowItem>
              <TableRowItem style={{ maxWidth: modelRegistrationListTableHeaderItemList[3].width }}>
                {listItem.model.name}
              </TableRowItem>
              <TableRowItem
                style={{
                  maxWidth: modelRegistrationListTableHeaderItemList[4].width,
                }}
              >
                {listItem.title}
              </TableRowItem>
              <TableRowItem
                style={{
                  maxWidth: modelRegistrationListTableHeaderItemList[5].width,
                }}
              >
                {listItem.fullRefNo}
              </TableRowItem>
              <TableRowItem
                style={{
                  maxWidth: modelRegistrationListTableHeaderItemList[6].width,
                }}
              >
                {listItem.requestedFrom?.name}
              </TableRowItem>
            </TableRow>
          ))
        )}
      </ListTableBody>
      <ListTableFooter>
        <Pagination
          count={list.totalPages}
          page={list.currentPage}
          onChange={(_, page) => {
            onChangePage(page)
          }}
        />
      </ListTableFooter>
    </ListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StatusBadge = styled.div<{ status: ModelRequestListItemType["status"] }>`
  width: 60px;
  height: 27px;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;

  ${({ status }) => {
    switch (status) {
      case "등록거절":
        return `
          color: #EB2020;
          border-color: #EB2020;
        `
      case "등록완료":
        return `
        color: #298A20;
        border-color: #298A20;
      `
      case "등록요청":
        return `
        color: #ED6C02;
        border-color: #ED6C02;
      `
      case "등록중":
        return `
        color: #ED6C02;
        border-color: #ED6C02;
      `
      case "요청취소":
        return `
        color: #B7BEC4;
        border-color: #B7BEC4;
      `
    }
  }}
`
