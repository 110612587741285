import {FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import * as React from "react"
import {useCallback, useState} from "react";
import {PageType} from "../../../types/PageType";
import {CountType} from "../../../types/CountType";
import {DateTime} from "luxon";
import {
    BuyOrderReviewListSearchParams,
    searchMenuList
} from "../OrderReviewTableFilter";
import {DesktopDatePicker} from "@mui/lab";
import {BuyOrderReviewType} from "../../../types/BuyOrderReviewType";
import {BuyOrderType} from "../../../types/BuyOrderType";

export interface BuyOrderReviewState {
    selectedIds: string[]
    buyOrderReviews: PageType<BuyOrderReviewType>
    buyOrderReviewCounts: CountType[]
    editBuyOrderReview?: BuyOrderType
    startDateFilter: DateTime | null
    endDateFilter: DateTime | null
    searchFilterKey: string | null
    searchFilterValue: string | null
    refresh: boolean
    loading: boolean
}
const initState: (fullRefNo?: string | null) => BuyOrderReviewState = (fullRefNo) => {
    let state: BuyOrderReviewState = {
        selectedIds: [],
        buyOrderReviews: {
            content: [],
            pageable: {
                pageSize: 25,
                pageNumber: 0,
            },
            totalPages: 0,
            totalElements: 0,
            numberOfElements: 0,
        },
        buyOrderReviewCounts: [],
        editBuyOrderReview: undefined,
        startDateFilter: null,
        endDateFilter: null,
        searchFilterKey: "fullRefNo",
        searchFilterValue: null,
        refresh: false,
        loading: false,
    }

    if (fullRefNo) {
        state.searchFilterKey = "fullRefNo"
        state.searchFilterValue = fullRefNo
    }

    return state
}

type Props = {
    changeSearchKeyword: (keyword: string) => void
    changeSearchFilterValue: (filterValue: string) => void
    onSearch: () => void
}

export type BuyOrderReviewSearchType = { filter: keyof BuyOrderReviewListSearchParams; keyword: string | undefined}

// @ts-ignore
const BuyOrderReviewTableFilter = ({filter, handleFilterChange }) => {
    const [searchFilter, setSearchFilter] = useState<BuyOrderReviewSearchType>({
        filter: "fullRefNo",
        keyword: "",
    })
    const changeSearchKeyword = useCallback((keyword: string) => {
        setSearchFilter((prev) => ({ ...prev, keyword }))
    }, [])

    const changeSearchFilterValue = useCallback((searchFilterValue: keyof BuyOrderReviewListSearchParams) => {
        setSearchFilter((prev) => ({ ...prev, filter: searchFilterValue }))
    }, [])


    return (
        <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <FormControl sx={{ width: "160px" }} size={"small"}>
                        <InputLabel id="search_key">검색항목</InputLabel>
                        <Select
                            defaultValue={"fullRefNo"}
                            required
                            onChange={(e) => changeSearchFilterValue(e.target.value as keyof BuyOrderReviewListSearchParams)}
                        >
                            {searchMenuList.map((menu) => (
                                <MenuItem value={menu.value.toString()} key={menu.title}>
                                    {menu.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ width: "220px" }}
                        size={"small"}
                        onChange={(e) => {
                            handleFilterChange({...filter, [searchFilter.filter]: null})
                            changeSearchKeyword(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            handleFilterChange({...filter, [searchFilter.filter]: searchFilter.keyword})
                        }}
                    />
                </Stack>
            <Stack spacing={1} direction={"row"} sx={{ lineHeight: "45px" }}>
                <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>작성일</InputLabel>
                <DesktopDatePicker
                    label="시작일"
                    inputFormat="yyyy-MM-dd"
                    value={filter.createdAtGoe ?? null}
                    onChange={(e) => {
                        handleFilterChange({...filter, createdAtGoe: e.toISODate(), page: 0})
                    }}
                    renderInput={(e) => <TextField {...e} sx={{ width: "160px" }} size={"small"} />}
                />
                <DesktopDatePicker
                    label="종료일"
                    inputFormat="yyyy-MM-dd"
                    value={filter.createdAtLoe ?? null}
                    onChange={(e) => {
                        handleFilterChange({...filter, createdAtLoe: e.toISODate(), page: 0})
                    }}
                    renderInput={(e) => <TextField {...e} sx={{ width: "160px" }} size={"small"} />}
                />
            </Stack>
            </Stack>
        </Grid>
    )
}

export default BuyOrderReviewTableFilter