import { getProduct } from "apis/productAPI"
import {
  deleteUpgradeServiceOrder,
  expireUpgradeService,
  finishUpgradeService,
  getUpgradeServiceOrderDetail,
  notifyUpgradeServiceToUser,
  putUpgradeServiceOrder,
  startUpgradeService,
} from "apis/upgradeServiceOrderAPI"
import { isEmpty, isNil } from "lodash"
import { useCallback, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { ProductType } from "types/ProductType"
import { UpgradeServiceItemType, UpgradeServiceOrderDetailType } from "types/UpgradeServiceOrderType"

export const useUpgradeServiceDetail = (orderId: string) => {
  const [selectedUpgradeItemIndexList, setSelectedUpgradeItemIndexList] = useState<number[]>([])
  const [upgradeServiceDetail, setUpgradeServiceDetail] = useState<UpgradeServiceOrderDetailType>()
  const [upgradeItemList, setUpgradeItemList] = useState<UpgradeServiceItemType[]>([])

  const fetchUpgradeServiceDetail = useCallback(() => {
    getUpgradeServiceOrderDetail(orderId)
      .then((response) => {
        setUpgradeServiceDetail(response)
        setUpgradeItemList(response.items)
      })
      .catch(() => toast.error("수리 내역을 불러오는데 실패했습니다."))
  }, [orderId])

  const updateUpgradeServiceItemList = async () => {
    if (!upgradeServiceDetail) return

    await putUpgradeServiceOrder(orderId, {
      items: upgradeItemList,
      productId: upgradeServiceDetail.product.id,
      userId: upgradeServiceDetail.user.id,
    })
    toast.success("저장이 완료되었습니다.")
    fetchUpgradeServiceDetail()
  }

  const notifyUpgradePaymentToUser = async () => {
    if (!window.confirm("고객에게 결제를 요청하시겠습니까?")) return

    try {
      await notifyUpgradeServiceToUser(orderId)
      toast.success("고객 결제요청을 완료했습니다.")
      fetchUpgradeServiceDetail()
    } catch {
      toast.error("결제요청에 실패했습니다.")
    }
  }

  const requestStartUpgradeService = async () => {
    if (!window.confirm("수리를 시작하시겠습니까?")) return

    try {
      await startUpgradeService(orderId)
      toast.success("수리상태를 변경했습니다.")
      fetchUpgradeServiceDetail()
    } catch {
      toast.error("수리상태 변경에 실패했습니다.")
    }
  }

  const requestFinishUpgradeService = async () => {
    if (!window.confirm("수리를 완료하시겠습니까?")) return

    try {
      await finishUpgradeService(orderId)
      toast.success("수리상태를 변경했습니다.")
      fetchUpgradeServiceDetail()
    } catch {
      toast.error("수리상태 변경에 실패했습니다.")
    }
  }

  const requestExpireUpgradeService = async () => {
    if (!window.confirm("수리주문을 만료하시겠습니까?")) return

    try {
      await expireUpgradeService(orderId)
      toast.success("수리상태를 변경했습니다.")
      fetchUpgradeServiceDetail()
    } catch {
      toast.error("수리상태 변경에 실패했습니다.")
    }
  }

  const requestDeleteUpgradeService = async () => {
    if (!window.confirm("수리주문을 삭제하시겠습니까?")) return

    try {
      await deleteUpgradeServiceOrder(orderId)
      toast.success("수리주문을 삭제했습니다.")
    } catch {
      toast.error("수리주문 삭제에 실패했습니다.")
    }
  }

  const deleteUpgradeItemListState = () => {
    setUpgradeItemList((prev) => prev.filter((item, index) => !selectedUpgradeItemIndexList.includes(index)))
    setSelectedUpgradeItemIndexList([])
  }

  const addUpgradeItemListstate = (item: UpgradeServiceItemType) => {
    setUpgradeItemList((prev) => prev.concat(item))
  }

  const toggleUpgradeItem = (index: number) => {
    if (selectedUpgradeItemIndexList.includes(index)) {
      setSelectedUpgradeItemIndexList((prev) => prev.filter((prevIndex) => prevIndex !== index))
    } else {
      setSelectedUpgradeItemIndexList((prev) => prev.concat(index))
    }
  }

  const toggleAllUpgradeItem = () => {
    setSelectedUpgradeItemIndexList(
      isEmpty(selectedUpgradeItemIndexList) ? upgradeItemList.map((item, index) => index) : []
    )
  }

  const isAllChecked = useMemo(() => {
    if (isEmpty(upgradeItemList) || isEmpty(selectedUpgradeItemIndexList)) return false

    return upgradeItemList.length === selectedUpgradeItemIndexList.length
  }, [upgradeItemList, selectedUpgradeItemIndexList])

  const onEditUpgradeServiceItem = (updatedItem: UpgradeServiceItemType, index: number) => {
    setUpgradeItemList((prev) =>
      prev.map((item, itemIndex) => {
        if (itemIndex === index) {
          return updatedItem
        }

        return item
      })
    )
  }

  const onSelectProduct = useCallback(async (newProduct: ProductType) => {
    if (isNil(newProduct)) return
    try {
      const productDetail = await getProduct(newProduct.id)

      setUpgradeServiceDetail((prev) => {
        if (!prev) return
        return {
          ...prev,
          product: {
            id: productDetail.id,
            serialNo: productDetail.serialNo || "",
            detailModel: {
              id: productDetail.detailModel.id || 0,
              refNo: productDetail.detailModel.refNo || "",
              fullRefNo: productDetail.detailModel.fullRefNo || "",
              title: productDetail.detailModel.title || "",
              titleSecondary: productDetail.detailModel.titleSecondary || "",
              name: productDetail.detailModel.name || "",
              thumbnail: productDetail.detailModel.thumbnail || "",
              brand: {
                sid: productDetail.detailModel.brand?.sid || "",
                name: productDetail.detailModel.brand?.name || "",
              },
            },
          },
        }
      })
    } catch {
      toast.error("상품 선택에 실패했습니다.")
    }
  }, [])

  useMount(() => fetchUpgradeServiceDetail())

  return {
    isAllChecked,
    upgradeServiceDetail,
    upgradeItemList,
    selectedUpgradeItemIndexList,
    updateUpgradeServiceItemList,
    deleteUpgradeItemListState,
    addUpgradeItemListstate,
    toggleUpgradeItem,
    notifyUpgradePaymentToUser,
    requestStartUpgradeService,
    requestFinishUpgradeService,
    requestExpireUpgradeService,
    requestDeleteUpgradeService,
    toggleAllUpgradeItem,
    onEditUpgradeServiceItem,
    setUpgradeServiceDetail,
    onSelectProduct,
  }
}
