import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Check, Search} from "@mui/icons-material";
import {getDetailModels} from "../../../apis/detailModelAPI";
import {DetailModelType} from "types/DetailModelType";
import ResellItemDetailView from "./ResellItemDetailView";

const initDetailModels:DetailModelType[] = []

type ProductSearchParams = {
    title?: string,
    fullRefNo?: string,
    brand?: string,
    page?: number,
    size?: number
}

const initParams: ProductSearchParams = {
    page: 0,
    size: 1000
}

type SearchProductModelProps = {
    dialogTitle?: string,
    open: any,
    handleClose: Function,
}

const SearchDetailModelPopup: React.FC<SearchProductModelProps> = ({dialogTitle, open, handleClose}) => {
    const [detailModels, setDetailModels] = useState(initDetailModels)
    const [params, setParams] = useState<ProductSearchParams>(initParams)

    const fetchDetailModels = (params?: any|null) => getDetailModels(params).then((page) => setDetailModels(page.content))

    useEffect(() => {
        setParams({...initParams, fullRefNo: open?.fullRefNo ?? open?.refNo, brand: open?.brandSid})
    }, [open])

    useEffect(() => {
        if (open) {
            fetchDetailModels(params)
        }
    }, [params])

    const handleChangeParams = (param: ProductSearchParams) => {
        setParams({...params, ...param})
    }

    const handleSearchButton = () => {
        fetchDetailModels(params)
    }

    return (
        <Dialog open={open} maxWidth={"xl"} fullWidth>
            <DialogTitle>{dialogTitle ? dialogTitle : '상세 모델 검색'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} marginTop={1}>
                                <Stack direction={'row'} spacing={1}>
                                    <TextField label={'상세 모델명'} fullWidth size={'small'} onChange={(p) => handleChangeParams({title: p.target.value})}/>
                                    <TextField label={'레퍼런스 넘버'} value={params.fullRefNo} fullWidth size={'small'} onChange={(p) => handleChangeParams({fullRefNo: p.target.value})}/>
                                    <IconButton>
                                        <Search onClick={handleSearchButton} />
                                    </IconButton>
                                </Stack>

                            </Grid>
                            <Grid item xs={12}>
                                <Typography width={"100%"} textAlign={'right'} variant={'caption'} align={'right'}>
                                    검색 결과 {detailModels.length} 개
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ImageList sx={{width: '100%', height: '500px'}} cols={3} rowHeight={150} variant="quilted">
                                    {detailModels?.map((detailModel) => {
                                        return <ImageListItem key={detailModel.id} style={{}}>
                                            <img src={detailModel.thumbnail as string} height={150}/>
                                            <ImageListItemBar
                                                title={detailModel.title}
                                                subtitle={
                                                    <span>
                                                        <Typography sx={{fontSize: '10px'}}>{detailModel?.titleSecondary || "-"}</Typography>
                                                        <Typography sx={{fontSize: '10px'}}>{detailModel?.fullRefNo || "-"}</Typography>
                                                    </span>
                                                }
                                                actionIcon={
                                                    <IconButton onClick={() => handleClose(detailModel)}>
                                                        <Check/>
                                                    </IconButton>
                                                }
                                                actionPosition={'left'}
                                            />
                                        </ImageListItem>
                                    })}
                                </ImageList>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <ResellItemDetailView
                            resellItem={open}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => handleClose()}>닫기</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SearchDetailModelPopup