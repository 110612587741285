import {Stack} from "@mui/material";
import React, {useState} from "react";
import BuyOrderReviewAPI from "../../../apis/buyOrderReviewAPI";
import BuyOrderReviewTable from "./BuyOrderReviewTable";
import BuyOrderReviewToolbar from "./BuyOrderReviewToolbar";
import {BuyOrderReviewType} from "../../../types/BuyOrderReviewType";

const BuyOrderReviewTab = () => {
    const [updateBuyOrderReview, setUpdateBuyOrderReview] = useState<BuyOrderReviewType | null>(null)
    const [selectionIds, setSelectionIds] = useState<string[]>([])
    const [refreshCounter, setRefreshCounter] = useState(0)

    const refresh = () => setRefreshCounter(refreshCounter + 1)

    const updateRecommend = (reviewIds: String[], recommend: boolean) => {
        var params = {ids: reviewIds, recommend: recommend}
        BuyOrderReviewAPI.updateRecommend(params).then((result) => {
            refresh()
        })
    }
    const handleHideButtonClick = () => {updateRecommend(selectionIds, false)}
    const handleShowButtonClick = () => {updateRecommend(selectionIds, true)}

    return (
        <Stack direction={"column"} spacing={1}>
            <BuyOrderReviewToolbar
                handleShowButtonClick={handleShowButtonClick}
                handleHideButtonClick={handleHideButtonClick}
            />
            <BuyOrderReviewTable
                handleSelection={(ids: string[]) => { setSelectionIds(ids) }}
                handleUpdateBuyOrderReview={(buyOrderReview: BuyOrderReviewType) => setUpdateBuyOrderReview(buyOrderReview)}
                refreshCounter={refreshCounter}
            />
        </Stack>
    )

}

export default BuyOrderReviewTab