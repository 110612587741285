import styled from "@emotion/styled"
import UploadIcon from "@mui/icons-material/Upload"
import { Button } from "@mui/material"
import { useRef } from "react"

type UploadButtonProps = {
  handleImage: (files: FileList) => void
  width?: string
  height?: string
  variant?: "text" | "outlined" | "contained" | undefined
  text?: string
}

export const UploadButton: React.FC<UploadButtonProps> = ({ handleImage, width, height, variant, text }) => {
  const refId = useRef(Math.random())

  return (
    <label htmlFor={`contained-button-file-${refId.current}`}>
      <UploadInput
        accept="image/*"
        id={`contained-button-file-${refId.current}`}
        multiple
        type="file"
        onChange={(params) => {
          if (params?.target?.files != null) handleImage(params?.target?.files)
        }}
      />
      <Button variant={variant} component="span" fullWidth sx={{ width: width, height: height }} color={"error"}>
        {text ? text : <UploadIcon />}
      </Button>
    </label>
  )
}

const UploadInput = styled.input`
    display: none;
`
