import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import React from "react";
import {QuestionState} from "./index";
import {toDateTimeStr} from "../../../utils/datetimeUtil";
import {Button, Grid} from "@mui/material";
import {Question} from "../../../types/MagazineType";

function createColumns(handleEditButtonClick: Function): any[] {

    return [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            minWidth: 70, maxWidth: 70,
        },
        {field: 'onDisplay', headerName: "노출", editable: false, sortable: false,
            align: 'center',
            minWidth: 60, maxWidth: 60,
            renderCell: (params: any) => (
                params.row.onDisplay
                    ? <Button size={"small"} variant={"text"} color={"success"}>노출</Button>
                    : <Button size={"small"} variant={"text"} color={"error"}>숨김</Button>
            )
        },
        {field: 'publishDate', headerName: "발행일", editable: false, sortable: false,
            align: 'center',
            minWidth: 120, maxWidth: 120,
        },
        {field: 'question', headerName: "질문", editable: false, sortable: false,
            minWidth: 300,
            renderCell: (params: any) => (
                <div style={{whiteSpace: "pre-line", wordBreak:"break-word", fontWeight:700}}>{params.row.question}</div>
            )
        },
        {field: 'startedAt', headerName: "시작일시", editable: false, sortable: false,
            minWidth: 180,
            renderCell: (params: any) => (
                toDateTimeStr(params.row.startedAt)
            )
        },
        {field: 'endedAt', headerName: "종료일시", editable: false, sortable: false,
            minWidth: 180,
            renderCell: (params: any) => (
                toDateTimeStr(params.row.endedAt)
            )
        },
    ]
}

type ContentProps = {
    state: QuestionState,
    dispatch: any,
    fetch: Function,
    components: any[],
}

const QuestionTable: React.FC<ContentProps> = ({state, dispatch, fetch, components}) => {

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{justifyContent: "flex-end"}}>
                {components}
            </GridToolbarContainer>
        );
    }

    const handleEditButtonClick = (row: Question) => {
        dispatch({type: 'SHOW_VIEW_QUESTION_POPUP', payload: row})
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <div style={{height: 800, width: '100%'}}>
                <DataGrid
                    components={{Toolbar: CustomToolbar}}
                    getRowId={row => row.id!}
                    columns={createColumns(handleEditButtonClick)}

                    pagination
                    {...dispatch.detailModels?.content}
                    paginationMode="server"
                    onPageChange={(page) => {
                        fetch({page, size: state.questions.pageable?.pageSize})
                    }}
                    onPageSizeChange={(size) => {
                        fetch({page: 0, size})
                    }}

                    page={state.questions.pageable?.pageNumber}
                    pageSize={state.questions.pageable?.pageSize}
                    rowCount={state.questions?.totalElements}
                    rows={state.questions?.content}

                    rowHeight={80}
                    onRowClick={handleEditButtonClick}
                    disableSelectionOnClick={true}
                />
            </div>
            </Grid>
        </Grid>)
}

// @ts-ignore
export default QuestionTable