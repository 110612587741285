import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"
import { PageType } from "types/PageType"
import { TermDetailPostBody, TermListParams, TermType } from "types/TermType"

export const getTermList: (params: TermListParams) => Promise<PageType<TermType>> = (params) =>
  httpGet(`/terms`, params)

export const updateTermDetail: (id: number, body: TermDetailPostBody) => Promise<TermType> = (id, body) =>
  httpPut(`/terms/${id}`, body)

export const deleteTerm: (id: number) => Promise<void> = (id) => httpDelete(`/terms/${id}`)

export const postTermDetail: (body: TermDetailPostBody) => Promise<TermType> = (body) => httpPost(`/terms`, body)
