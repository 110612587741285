import BannerTable from "./BannerTable";
import BannerToolbar from "./BannerToolbar";
import {Stack} from "@mui/material";
import BannerCreatePopup from "./BannerCreatePopup";
import {useState} from "react";
import BannerReorderPopup from "./BannerReorderPopup";
import BannerUpdatePopup from "./BannerUpdatePopup";
import {ShopHeroBannerType} from "types/ShopHeroBannerType";
import DeleteConfirmPopup from "../../../../components/Popup/DeleteConfirmPopup";
import ShopBannerAPI from "../../../../apis/shopBannerAPI";


const BannerTab = () => {
    const [showCreateBanner, setShowCreateBanner] = useState(false)
    const [updateBanner, setUpdateBanner] = useState<ShopHeroBannerType | null>(null)
    const [showReorderBanner, setShowReorderBanner] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectionIds, setSelectionIds] = useState<number[]>([])
    const [refreshCounter, setRefreshCounter] = useState(0)

    const refresh = () => setRefreshCounter(refreshCounter + 1)

    return (
        <Stack direction={"column"} spacing={1}>
            <BannerToolbar handleCreateBanner={() => setShowCreateBanner(true)}
                           handleReorderBanner={() => setShowReorderBanner(true)}
                           handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
                           disabledDeleteButton={ selectionIds.length == 0}
            />
            <BannerTable
                handleSelection={(ids: number[]) => { setSelectionIds(ids) }}
                handleUpdateBanner={(banner: ShopHeroBannerType) => setUpdateBanner(banner)}
                refreshCounter={refreshCounter}
            />
            <BannerCreatePopup open={showCreateBanner} handleClose={() => {
                refresh()
                setShowCreateBanner(false)
            }}/>
            <BannerUpdatePopup banner={updateBanner} handleClose={() => {
                refresh()
                setUpdateBanner(null)
            }}/>
            <BannerReorderPopup open={showReorderBanner} handleClose={() => {
                refresh()
                setShowReorderBanner(false)
            }}/>
            <DeleteConfirmPopup open={showDeletePopup} handleClose={() => {
                refresh()
                setShowDeletePopup(false)
            }} handleConfirm={() => {
                Promise.all(selectionIds.map((id) => ShopBannerAPI.delete(id)))
                    .then((results) => {
                        refresh()
                        setShowDeletePopup(false)
                    })
            }}/>
        </Stack>
    )
}

export default BannerTab