import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  ConditionsType,
  DiagnosisGradeCode,
  DiagnosisResultCode,
  DiagnosisType,
  PartConditionType,
} from "types/DiagnosisType"
import React from "react"

type Props = {
  appearanceCondition?: ConditionsType
  internalCondition?: ConditionsType
  changeDiagnosisState: (key: keyof DiagnosisType, newState: any) => void
}

const DetailedDiagnosis = ({ appearanceCondition, internalCondition, changeDiagnosisState }: Props) => {
  const getNewConditionState = (
    prevCondition: ConditionsType,
    label: string,
    newPart: PartConditionType
  ): ConditionsType => {
    return {
      ...prevCondition,
      parts: prevCondition.parts.map((part) => {
        if (part.label === label) {
          return newPart
        }

        return part
      }),
    }
  }

  return (
    <>
      <h2>상세진단</h2>
      <div>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>외부 컨디션</AccordionSummary>
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <RadioGroup
              row
              sx={{ justifyContent: "center" }}
              onChange={(event, value) => {
                if (value) {
                  appearanceCondition?.parts?.forEach((it) => {
                    it.grade = DiagnosisGradeCode.양호
                    changeDiagnosisState(
                      "appearanceCondition",
                      getNewConditionState(appearanceCondition, it.label, {
                        ...it,
                        grade: it.grade,
                      })
                    )
                  })
                }
              }}
            >
              <FormControlLabel
                value="전체 양호"
                label="전체 양호"
                checked={appearanceCondition?.parts?.every((it, idx) => {
                  return it.grade === DiagnosisGradeCode.양호
                })}
                control={<Radio />}
              />
            </RadioGroup>
          </div>
          <AccordionDetails>
            <table className={"basic"}>
              <tbody>
                <tr>
                  <td className={"header"}>상세항목</td>
                  <td colSpan={3} className={"header"}>
                    항목별 상태
                  </td>
                  <td className={"header"}>상세설명</td>
                  <td className={"header"}>사진필요</td>
                </tr>
                {appearanceCondition?.parts?.map((partItem: PartConditionType, idx: number) => (
                  <tr>
                    <td>{partItem.label}</td>
                    <td colSpan={3}>
                      <RadioGroup
                        row
                        sx={{ justifyContent: "center" }}
                        onChange={(event) => {
                          const changedGrade = event.target.value as DiagnosisGradeCode
                          const getResultCode = (gradeCode: DiagnosisGradeCode): DiagnosisResultCode => {
                            switch (gradeCode) {
                              case DiagnosisGradeCode.업그레이드_필요:
                                return DiagnosisResultCode.조건부_합격
                              case DiagnosisGradeCode.판매_불가:
                                return DiagnosisResultCode.불합격
                              default:
                                return DiagnosisResultCode.합격
                            }
                          }

                          changeDiagnosisState(
                            "appearanceCondition",
                            getNewConditionState(appearanceCondition, partItem.label, {
                              ...partItem,
                              grade: changedGrade,
                            })
                          )
                          changeDiagnosisState("result", getResultCode(changedGrade))
                        }}
                      >
                        {[
                          { value: DiagnosisGradeCode.양호, label: "양호" },
                          { value: DiagnosisGradeCode.보통, label: "보통" },
                          { value: DiagnosisGradeCode.업그레이드_필요, label: "업그레이드 필요" },
                          { value: DiagnosisGradeCode.판매_불가, label: "판매 불가" },
                        ].map(({ value, label }) => (
                          <FormControlLabel
                            value={value}
                            label={label}
                            checked={appearanceCondition.parts[idx].grade === value}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    </td>
                    <td>
                      <TextField
                        variant="standard"
                        fullWidth
                        type={"text"}
                        multiline
                        value={appearanceCondition?.parts[idx]!.note}
                        onChange={(event) =>
                          changeDiagnosisState(
                            "appearanceCondition",
                            getNewConditionState(appearanceCondition, partItem.label, {
                              ...appearanceCondition.parts[idx],
                              note: event.target.value,
                            })
                          )
                        }
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={appearanceCondition?.parts[idx]!.imageRequired}
                        onChange={(event, value) =>
                          changeDiagnosisState(
                            "appearanceCondition",
                            getNewConditionState(appearanceCondition, partItem.label, {
                              ...appearanceCondition.parts[idx],
                              imageRequired: value,
                            })
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className={"header"}>
                    진단자 메모
                  </td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      minRows={3}
                      value={appearanceCondition?.note}
                      onChange={(event) =>
                        changeDiagnosisState("appearanceCondition", {
                          ...appearanceCondition,
                          note: event.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>내부 컨디션</AccordionSummary>
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <RadioGroup
              row
              sx={{ justifyContent: "center" }}
              onChange={(event, value) => {
                if (value) {
                  internalCondition?.parts?.forEach((it) => {
                    it.grade = DiagnosisGradeCode.양호
                    changeDiagnosisState(
                      "internalCondition",
                      getNewConditionState(internalCondition, it.label, {
                        ...it,
                        grade: it.grade,
                      })
                    )
                  })
                }
              }}
            >
              <FormControlLabel
                value="전체 양호"
                label="전체 양호"
                checked={internalCondition?.parts?.every((it, idx) => {
                  return it.grade === DiagnosisGradeCode.양호
                })}
                control={<Radio />}
              />
            </RadioGroup>
          </div>
          <AccordionDetails>
            <table className={"basic"}>
              <tbody>
                <tr>
                  <td className={"header"}>상세항목</td>
                  <td colSpan={3} className={"header"}>
                    항목별 상태
                  </td>
                  <td className={"header"}>측정값</td>
                  <td className={"header"}>상세설명</td>
                </tr>
                {internalCondition?.parts?.map((partItem, idx) => (
                  <tr>
                    <td>{partItem.label}</td>
                    <td colSpan={3}>
                      <RadioGroup
                        row
                        sx={{ justifyContent: "center" }}
                        onChange={(event, value) => {
                          changeDiagnosisState(
                            "internalCondition",
                            getNewConditionState(internalCondition, partItem.label, {
                              ...internalCondition.parts[idx],
                              grade: value as DiagnosisGradeCode,
                            })
                          )
                        }}
                      >
                        {[
                          { value: DiagnosisGradeCode.양호, label: "양호" },
                          { value: DiagnosisGradeCode.보통, label: "보통" },
                          { value: DiagnosisGradeCode.업그레이드_필요, label: "업그레이드 필요" },
                          { value: DiagnosisGradeCode.판매_불가, label: "판매 불가" },
                        ].map(({ value, label }) => (
                          <FormControlLabel
                            value={value}
                            label={label}
                            checked={internalCondition.parts[idx].grade === value}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    </td>
                    <td>
                      <TextField
                        variant="standard"
                        type={"text"}
                        value={partItem.measured}
                        onChange={(event) =>
                          changeDiagnosisState(
                            "internalCondition",
                            getNewConditionState(internalCondition, partItem.label, {
                              ...internalCondition.parts[idx],
                              measured: event.target.value,
                            })
                          )
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        variant="standard"
                        fullWidth
                        type={"text"}
                        multiline
                        value={partItem.note}
                        onChange={(event) =>
                          changeDiagnosisState(
                            "internalCondition",
                            getNewConditionState(internalCondition, partItem.label, {
                              ...internalCondition.parts[idx],
                              note: event.target.value,
                            })
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className={"header"}>
                    진단자 메모
                  </td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      minRows={3}
                      value={internalCondition?.note}
                      onChange={(event) => {
                        changeDiagnosisState("internalCondition", { ...internalCondition, note: event.target.value })
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}

export default DetailedDiagnosis
