import React, { FunctionComponent, useEffect, useState } from "react"
import { StyledAbsoluteBox, StyledBox, StyledStix } from "./styled"
import { SelectOptions } from "./SelectOptions"

export const DiagnosisPrintSign:FunctionComponent<{
  userId:string,
  signUrl:string | null,
}> = ({
  userId,
  signUrl
}) => {
  const [displaySignSelect, setDisplaySignSelect] = useState(false)
  const [selectSign, setSelectSign] = useState<string | null>(userId)

  useEffect(() => {
    setSelectSign(userId)
  }, [userId])
  return (
    <StyledBox
      onClick={()=>{
        setDisplaySignSelect(!displaySignSelect)
      }}
    >
      <StyledStix $align="right" $fontSize={8} $lineHeight={10} $color="#000" className="is-design">VIVER Labs.</StyledStix>
      <StyledBox
        $height={26}
        $alignItems="flex-end"
        $marginLeft={7}
        $paddingBottom={5}
        $justifyContent="center"
        $width={112}

      >

        {/* 서명 이미지 삽입 */}
        {
          selectSign === userId && signUrl !== null ? (
            <StyledAbsoluteBox $width={"100%"} style={{background:"transparent", justifyContent:"center"}}>
              <img src={signUrl} alt="" style={{
                width:"70%",
                display:"block"
              }} />
            </StyledAbsoluteBox>
          ) : null
        }

        {/* 서명 이미지 선택 */}
        {
          displaySignSelect && signUrl !== null ? (
            <StyledAbsoluteBox
              $width={"100%"}
              className="not-printing"
            >
              <SelectOptions
                items={[userId, "직접 서명"]}
                selectedIndex={
                  [userId, "직접 서명"].findIndex(item => item === selectSign)
                }
                handleChange={(event) => {
                  setSelectSign(event.target.value)
                  setDisplaySignSelect(false)
                }}
              />
            </StyledAbsoluteBox>
          ) : null
        }
        {/* 서명 뒤 문구 */}
        <StyledStix
          $color="#E2E2E1"
          $fontSize={8}
          $lineHeight={8}
          className="is-design"
        >Watch Inspector’s Signature</StyledStix>
        <StyledAbsoluteBox
          $width="100%"
          style={{
            borderBottom:"1px solid #231815",
            height: 0,
            top: "auto",
            bottom: 0,
            transform: "none"
          }}
          className="is-design"
        ></StyledAbsoluteBox>
      </StyledBox>
    </StyledBox>
  )
}