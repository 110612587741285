import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI"
import {PageType} from "types/PageType"
import {UserNotificationScheduleType} from "../types/UserNotificationScheduleType";

export const getSchedules: (searchParams: any) => Promise<PageType<UserNotificationScheduleType>> = (searchParams) =>
    httpGet("/user-notifications/schedules", searchParams)
export const getSchedule: (id: number) => Promise<UserNotificationScheduleType> = (id) =>
    httpGet(`/user-notifications/schedules/${id}`)

export const createSchedule: (dto: Omit<UserNotificationScheduleType, "id">) => Promise<UserNotificationScheduleType> = (dto) =>
    httpPost("/user-notifications/schedules", dto)
export const updateSchedule: (id: number, dto: UserNotificationScheduleType) => Promise<UserNotificationScheduleType> = (id, dto) =>
    httpPut(`/user-notifications/schedules/${id}`, dto)
export const cancelSchedule: (id: number) => Promise<UserNotificationScheduleType> = (id) =>
    httpDelete(`/user-notifications/schedules/${id}`)
