import React, { useEffect, useRef, useState } from "react"
import { OrderType, SellOrderType } from "types/SellOrderType"
import ProductQR from "components/ProductQR"
import { toDateTimeStr } from "utils/datetimeUtil"
import { ProductType } from "types/ProductType"
import { getSellOrder } from "../../../apis/sellOrderAPI"
import { getProduct } from "../../../apis/productAPI"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useReactToPrint } from "react-to-print"

type QRTableToPrintProps = {
  productId?: number
}

const QRTableToPrint: React.FC<QRTableToPrintProps> = ({ productId }) => {
  const [product, setProduct] = useState<ProductType>()
  const [sellOrder, setSellOrder] = useState<SellOrderType>()
  const [isLabelPrint, setIsLabelPrint] = useState(false)
  const ecuQrRef = useRef(null)
  const polyBagQRRef = useRef(null)

  useEffect(() => {
    if (productId) {
      productId &&
        getProduct(productId).then((res) => {
          setProduct(res)
          res?.sellOrder && getSellOrder(res.sellOrder.id!).then(setSellOrder)
        })
    }
  }, [productId])

  const ecuQrPrint = useReactToPrint({
    content: () => ecuQrRef.current,
    copyStyles: true,
    onAfterPrint: () =>{
      setIsLabelPrint(false)
    }
  })

  const polyBagQrPrint = useReactToPrint({
    content: () => polyBagQRRef.current,
    copyStyles: true,
    onAfterPrint: () =>{
      setIsLabelPrint(false)
    }
  })

  return (
    <StyledAllPrint>
      <StyledECUQRTable ref={ecuQrRef}  data-label-print={isLabelPrint}>
        <StyledTable className={"basic label"}>
          <colgroup>
            <col width="13%" />
            <col width="*" />
          </colgroup>
          <tbody>
          <tr>
            <td className={"header"} colSpan={6}>{`#${product?.id} _ ${product?.saleType} _ ECU 부착용`}</td>
            <td colSpan={2} rowSpan={4} align={"center"}>
              <ProductQR size={80} id={product?.id} />
            </td>
          </tr>
          <tr>
            <td className={"header"}>상품명</td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }} colSpan={5}>
              {product?.title}
              <br />
              {product?.titleSecondary}
              <b>({product?.exposedProductInfo?.productCondition})</b>{" "}
              <span
                style={{
                  color: sellOrder?.buyOrder?.advancedDiagnosis ? "red" : "inherit",
                  textDecoration: sellOrder?.buyOrder?.advancedDiagnosis ? "underline" : "none",
                  fontWeight: sellOrder?.buyOrder?.advancedDiagnosis ? "bold" : "inherit",
                }}
              >
                  {sellOrder?.buyOrder && sellOrder?.sellOrderType === OrderType.진단전 && (sellOrder.buyOrder.advancedDiagnosis ? "정밀" : "일반")}
                </span>
            </td>
          </tr>
          <tr>
            <td className={"header"}>상품번호</td>
            <td colSpan={2}>{product?.id}</td>
            <td className={"header"}>판매주문ID</td>
            <td colSpan={2}>{sellOrder?.id}</td>
          </tr>
          <tr>
            <td className={"header"}>입고확인</td>
            <td colSpan={2}>{toDateTimeStr(sellOrder?.waitingDiagnosisAt)}</td>
            <td className={"header"}>판매자</td>
            <td colSpan={2}>
              {sellOrder?.seller?.name} {sellOrder?.seller?.phone}
            </td>
          </tr>
          </tbody>
        </StyledTable>
        <Button className="not-print" variant={"contained"} color={"error"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={()=>{
          setIsLabelPrint(true)
          window.requestAnimationFrame(()=>{
            ecuQrPrint()
          })
        }}>ECU QR 출력</Button>
      </StyledECUQRTable>
      <StyledPolyBagQrPrint ref={polyBagQRRef} data-label-print={isLabelPrint}>
        <StyledTable className={"basic label"}>
          <colgroup>
            <col width="13%" />
            <col width="*" />
          </colgroup>
          <tbody>
          <tr>
            <td className={"header"} colSpan={6}>{`#${product?.id} _ ${product?.saleType} _ 폴리백 부착용`}</td>
            <td colSpan={2} rowSpan={4} align={"center"}>
              <ProductQR size={80} id={product?.id} />
            </td>
          </tr>
          <tr>
            <td className={"header"}>상품명</td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }} colSpan={5}>
              {product?.title}
              <br />
              {product?.titleSecondary} <b>({product?.exposedProductInfo?.productCondition})</b>{" "}
              <span
                style={{
                  color: sellOrder?.buyOrder?.advancedDiagnosis ? "red" : "inherit",
                  textDecoration: sellOrder?.buyOrder?.advancedDiagnosis ? "underline" : "none",
                  fontWeight: sellOrder?.buyOrder?.advancedDiagnosis ? "bold" : "inherit",
                }}
              >
                  {sellOrder?.buyOrder && (sellOrder.buyOrder.advancedDiagnosis ? "정밀" : "일반")}
                </span>
            </td>
          </tr>
          <tr>
            <td className={"header"}>상품번호</td>
            <td colSpan={2}>{product?.id}</td>
            <td className={"header"}>판매주문ID</td>
            <td colSpan={2}>{sellOrder?.id}</td>
          </tr>
          <tr>
            <td className={"header"}>입고확인</td>
            <td colSpan={2}>{toDateTimeStr(sellOrder?.waitingDiagnosisAt)}</td>
            <td className={"header"}>판매자</td>
            <td colSpan={2}>
              {sellOrder?.seller?.name} {sellOrder?.seller?.phone}
            </td>
          </tr>
          <tr>
            <td className={"header"} rowSpan={4}>
              부속품
            </td>
            <td className={"ta-left"} colSpan={2}>
              <span style={{ margin: "0px 5px" }}>☐</span>외부박스
            </td>
            <td className={"ta-left"} colSpan={2}>
              <span style={{ margin: "0px 5px" }}>☐</span>내부박스
            </td>
            <td className={"ta-left"} colSpan={3}>
              <span style={{ margin: "0px 5px" }}>☐</span>COSC인증책자
            </td>
          </tr>
          <tr>
            <td className={"ta-left"} colSpan={2}>
              <span style={{ margin: "0px 5px" }}>☐</span>메달
            </td>
            <td className={"ta-left"} colSpan={2}>
              <span style={{ margin: "0px 5px" }}>☐</span>보증서케이스
            </td>
            <td className={"ta-left"} colSpan={3}>
              <span style={{ margin: "0px 5px" }}>☐</span>사용설명서 (<span style={{ margin: "0px 5px" }}>국</span>/
              <span style={{ margin: "0px 5px" }}>외</span>)
            </td>
          </tr>
          <tr>
            <td className={"ta-left"} colSpan={2}>
              <span style={{ margin: "0px 5px" }}>☐</span>쇼핑백
            </td>
            <td className={"ta-left"} colSpan={2}>
              <span style={{ margin: "0px 5px" }}>☐</span>영수증
            </td>
            <td className={"ta-left"} colSpan={3}>
              <span style={{ margin: "0px 5px" }}>☐</span>여분링크
            </td>
          </tr>
          <tr>
            <td className={"ta-left"} colSpan={7} style={{ paddingLeft: "5px" }}>
              기타
            </td>
          </tr>
          </tbody>
        </StyledTable>
        <Button className="not-print" variant={"contained"} color={"error"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={()=>{
          setIsLabelPrint(true)
          window.requestAnimationFrame(() => {
            polyBagQrPrint()
          })
        }}>폴리백 QR 출력</Button>
      </StyledPolyBagQrPrint>
    </StyledAllPrint>
  )
}

export default QRTableToPrint

const StyledAllPrint = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  @media print {
    padding-top: 10mm;
    .not-print{
      display: none;
    }
    table{
      position: relative;
    }
  }
`

const StyledTable = styled.table`
  font-size: 14px;
  width: 100%;
	tr > td{
		border-width: 1px;
	}
`
const StyledECUQRTable = styled.div`
	@media print {
		&[data-label-print="true"]{
			padding: 2mm 4mm 0;
			height: 28mm;
			overflow: hidden;
			width: 100%;
			box-sizing: border-box;
			.not-print{
				display: none;
			}
			table {
				width: 100% !important;
				height: auto !important;
				aspect-ratio: 10 / 2.6;
				position: relative;
				left: auto !important;
				top: auto !important;
				font-size: 14px !important;
        tr{
          height: 20px !important;
        }
				td{
					border-color:#000;
					border-width:1px;
				}
				td.header{
					font-weight: 400;
				}
			}
		}
	}
`
const StyledPolyBagQrPrint = styled.div`
	@media print {
		&[data-label-print="true"]{
			padding: 4mm 6mm 0;
			height: 56mm;
			overflow: hidden;
			width: 100%;
			box-sizing: border-box;
			table {
				width: 100% !important;
        height: auto !important;
				aspect-ratio: 10 / 5.4;
				position: relative;
				left: auto !important;
				top: auto !important;
				font-size: 14px !important;
        tr{
          height: 20px !important;
        }
				td{
					border-color:#000;
					border-width:1px;
				}
				td.header{
					font-weight: 400;
				}
			}
			.not-print{
				display: none;
			}
		}
	}
`