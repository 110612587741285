import * as XLSX from "xlsx";
import {DateTime} from "luxon";
import {ShippingType} from "types/ShippingType";

export const downloadExcel = (shippings: ShippingType[]) => {
    const data = shippings.map(it =>(
        {
            "배송형태(배송 OR 반품)": it.shippingType == "발송" ? "배송" : "반품",
            "고객명": it.name,
            "우편번호": it.zipCode,
            "지역": it.address?.split(" ")?.[0],
            "주소": it.address,
            "상세주소": it.addressDetail,
            "고객 핸드폰 번호": it.phone,

            "ITEM": `${it.item || ""}`,
            "WEIGHT": `${it.weight || ""}`,
            "VALUE": `${it.price || ""}`,
            "MEMO": "",
            "고객사 ORDER NUMBER": `${it.id}`,
        }
    ))
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, `배송_템플릿_발렉스_${DateTime.now().toFormat("yyyyMMdd_HHmmss")}.xlsx`)
}

export const downloadIlyangExcel = (shippings:ShippingType[])=>{
  const data = shippings.map(it =>(
    {
      "수취고객명": it.name,
      "수취인": it.name,
      "수취인전화": it.phone,
      "수취인휴대폰": it.phone,
      "수취인우편번호": it.zipCode,
      "수취인주소": `${it.address} ${it.addressDetail}`,
      "총중량": `${it.weight || "1"}`,
      "상품명1": `${it.item || ""}`,
      "수량1":1,
      "물품가격1":`${it.price || ""}`,
      "MEMO": "대면배송",
      "총수량(출력매수)":1,
      "고객주문번호": `${it.id}`,
    }
  ))
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  XLSX.writeFile(workbook, `배송_템플릿_일양_${DateTime.now().toFormat("yyyyMMdd_HHmmss")}.xlsx`)
}
