import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { SetStateAction, Dispatch } from "react"
import { DiagnosisGradeCode, DiagnosisResultCode, DiagnosisType } from "types/DiagnosisType"

type Props = {
  grade?: DiagnosisGradeCode
  failedReason?: string
  setDiagnosis: Dispatch<SetStateAction<DiagnosisType>>
}

const OverAllStatus = ({ grade, failedReason, setDiagnosis }: Props) => {
  return (
    <>
      <h2>종합상태</h2>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"header"}>종합상태</td>
            <td className={"header"}>판매 불가 사유</td>
          </tr>
          <tr>
            <td>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) => {
                  switch (event.target.value) {
                    case DiagnosisGradeCode.양호:
                      setDiagnosis((prev) => ({
                        ...prev,
                        grade: DiagnosisGradeCode.양호,
                        result: DiagnosisResultCode.합격,
                        penaltyReasonType: undefined

                      }))
                      break
                    case DiagnosisGradeCode.보통:
                      setDiagnosis((prev) => ({
                        ...prev,
                        grade: DiagnosisGradeCode.보통,
                        result: DiagnosisResultCode.합격,
                        penaltyReasonType: undefined
                      }))
                      break
                    case DiagnosisGradeCode.업그레이드_필요:
                      setDiagnosis((prev) => ({
                        ...prev,
                        grade: DiagnosisGradeCode.업그레이드_필요,
                        result: DiagnosisResultCode.조건부_합격,
                      }))
                      break
                    case DiagnosisGradeCode.판매_불가:
                      setDiagnosis((prev) => ({
                        ...prev,
                        grade: DiagnosisGradeCode.판매_불가,
                        result: DiagnosisResultCode.불합격,
                      }))
                      break
                  }
                }}
              >
                {[
                  { value: DiagnosisGradeCode.양호, label: "양호" },
                  { value: DiagnosisGradeCode.보통, label: "보통" },
                  { value: DiagnosisGradeCode.업그레이드_필요, label: "업그레이드 필요" },
                  { value: DiagnosisGradeCode.판매_불가, label: "판매 불가" },
                ].map(({ value, label }) => (
                  <FormControlLabel value={value} label={label} checked={grade === value} control={<Radio />} />
                ))}
              </RadioGroup>
            </td>
            <td>
              <TextField
                variant="standard"
                fullWidth={true}
                type={"text"}
                multiline={true}
                value={failedReason}
                onChange={(event) => {
                  setDiagnosis((prev) => ({ ...prev, failedReason: event.target.value }))
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default OverAllStatus
