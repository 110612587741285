import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const ListTableBody = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

export default ListTableBody

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.825rem;

  max-height: 785px;
  overflow-y: scroll;
`
