import styled from "@emotion/styled"
import { TextField, Switch } from "@mui/material"
import { isNil } from "lodash"
import { Dispatch, FunctionComponent, SetStateAction } from "react"
import { parseMarkdownToHtml } from "utils/markdownUtils"
import { NoticeDetailRequestBodyType } from "types/NoticeDetailRequestBodyType"
import { NoticeDetailFormDateRange } from "./NoticeDetailFormDateRange"
import { NoticeDetailFormMarkdown } from "./NoticeDetailFormMarkdown"
import { NoticeDetailFormNotifyDate } from "./NoticeDetailFormNotifyDate"

export const NoticeDetailForm: FunctionComponent<{
  initialState?: NoticeDetailRequestBodyType
  state: NoticeDetailRequestBodyType
  setState: Dispatch<SetStateAction<NoticeDetailRequestBodyType>>
}> = ({ initialState, state, setState }) => {
  return (
    <>
      <FormField>
        <FormFieldTitle>제목</FormFieldTitle>
        <TextField
          size={"small"}
          fullWidth
          value={state.title}
          onChange={(event) => {
            setState((state) => ({ ...state, title: event.target.value }))
          }}
        />
      </FormField>
      <FormField>
        <FormFieldTitle>게시상태</FormFieldTitle>
        <div>
          <span>게시종료</span>
          <Switch
            checked={state.onDisplay}
            onChange={(event) => {
              setState((state) => ({ ...state, onDisplay: event.target.checked }))
            }}
          />
          <span>게시중</span>
        </div>
      </FormField>
      <FormField>
        <FormFieldTitle>게시날짜</FormFieldTitle>
        <NoticeDetailFormNotifyDate
          notifyDate={state.notifyDate}
          onChangeNotifyDate={(notifyDate) => {
            setState((state) => ({ ...state, notifyDate }))
          }}
        />
      </FormField>
      <FormField>
        <FormFieldTitle>게시기간</FormFieldTitle>
        <NoticeDetailFormDateRange
          startedAt={state.startedAt}
          endedAt={state.endedAt}
          onChangeStartedAt={(startedAt) => {
            setState((state) => ({ ...state, startedAt }))
          }}
          onChangeEndedAt={(endedAt) => {
            setState((state) => ({ ...state, endedAt }))
          }}
        />
      </FormField>
      <FormField style={{ flex: 1, minHeight: 300 }}>
        <FormFieldTitle>내용</FormFieldTitle>
        <NoticeDetailFormMarkdown
          mode={isNil(initialState) ? "default" : "diff"}
          initialMarkdown={isNil(initialState) ? "" : initialState.body}
          onChangeMarkdown={(markdownText) => {
            setState((state) => ({ ...state, body: markdownText, htmlBody: parseMarkdownToHtml(markdownText) }))
          }}
        />
        <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
          마크다운 문법 가이드
        </a>
      </FormField>
    </>
  )
}

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`
const FormFieldTitle = styled.h3`
  width: 150px;
  margin: 5px 0;
  font-size: 16px;
`
