import styled from "@emotion/styled"
import Button from "components/Button"
import { useCallback, useEffect, useState } from "react"

import { FaqCategoryType, FaqType } from "types/FaqType"
import { isNotNil } from "utils/validationUtils"
import { FaqCategorySelect } from "./FaqCategorySelect"
import { useFaqEditor } from "./useFaqEditor"

const DeleteIconSrc = require("images/24_delete.png")

type Props = {
  selectedFaq: FaqType | undefined
  selectedCategory: FaqCategoryType
  refreshList: (modifiedFaq?: FaqType) => void
}

const TITLE_TEXT_AREA_ID = "title-textarea"
export const FaqEditor = ({ selectedFaq, selectedCategory, refreshList }: Props) => {
  const [titleHeight, setTitleHeight] = useState<number>(36)
  const { faqState, changeValue, isSavable, saveFaqDetail, deleteFaqDetail } = useFaqEditor({
    selectedCategory,
    selectedFaq,
  })

  const handleSaveFaq = async () => {
    const modifedFaq = await saveFaqDetail()
    refreshList(modifedFaq)
  }

  const handleDeleteFaq = async () => {
    await deleteFaqDetail()
    refreshList()
  }

  const resizeTitleHeightOnLoad = useCallback(() => {
    const scrollHeight = document.getElementById(TITLE_TEXT_AREA_ID)?.scrollHeight || 36
    setTitleHeight(scrollHeight)
  }, [])

  const resetTitleHeight = useCallback(() => {
    setTitleHeight(36)
  }, [])

  useEffect(() => {
    resetTitleHeight()
    setTimeout(resizeTitleHeightOnLoad, 0)
  }, [selectedFaq?.title, resizeTitleHeightOnLoad, resetTitleHeight])

  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{isNotNil(selectedFaq) ? "질문 상세" : "신규 생성"}</Title>
        <InlineWrapper>
          <Label>질문</Label>
          <TitleInput
            id={TITLE_TEXT_AREA_ID}
            titleHeight={titleHeight}
            value={faqState.title}
            onChange={(e) => {
              setTitleHeight(e.target.scrollHeight)
              changeValue("title", e.target.value)
            }}
          />
        </InlineWrapper>
        <InlineWrapper>
          <Label>카테고리</Label>
          <FaqCategorySelect
            selectCategory={(category) => changeValue("type", category)}
            selectedCategory={faqState.type}
          />
        </InlineWrapper>
        <InlineWrapper>
          <Label>답변</Label>
          <TextArea value={faqState.body} onChange={(e) => changeValue("body", e.target.value)} />
        </InlineWrapper>
      </ContentWrapper>
      <BottomWrapper>
        <DeleteButtonWrapper onClick={handleDeleteFaq}>
          <img src={DeleteIconSrc} alt="delete icon" />
          삭제
        </DeleteButtonWrapper>
        <SaveButtonWrapper>
          <Button
            disabled={!isSavable}
            onClick={handleSaveFaq}
            buttonSize="M"
            style={{
              height: "40px",
            }}
          >
            저장
          </Button>
        </SaveButtonWrapper>
      </BottomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 815px;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  border: 0.5px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 48px;
  flex: 1;
`

const BottomWrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 24px;
`

const SaveButtonWrapper = styled.div`
  width: 120px;
`

const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 72px;

  color: #666f78;
  font-size: 15px;
  padding: 8px;

  img {
    height: 24px;
    width: 24px;
    margin-right: 4px;
  }
`

const Title = styled.div`
  padding: 40px 0 32px 0;
  font-weight: 700;
  color: #1c2229;
  font-size: 24px;
`

const InlineWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 32px;
`

const Label = styled.div`
  width: 88px;
  padding: 10px 0 8px 0;
  color: #3b464f;
  font-size: 16px;
  flex-shrink: 0;
`

const TitleInput = styled.textarea<{ titleHeight: number }>`
  width: 400px;
  height: ${({ titleHeight }) => titleHeight}px;

  outline: none;
  border: none;
  padding: 8px 4px;
  color: #1c2229;
  font-size: 16px;
  border-bottom: 1px solid #000;

  resize: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`

const TextArea = styled.textarea`
  background: #ffffff;
  border: 1px solid #ced2d6;
  border-radius: 4px;
  width: 100%;
  min-height: 272px;
  outline: none;
  resize: vertical;
  padding: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`
