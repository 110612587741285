import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const eventApplyListTableHeaderItemList = [
  {
    title: "회원ID",
    width: "10%",
  },
  {
    title: "Email",
    width: "15%",
  },
  {
    title: "이름",
    width: "10%",
  },
  {
    title: "연락처",
    width: "12%",
  },
  {
    title: "이벤트 응모 추가 데이터",
    width: "38%",
  },
  {
    title: "이벤트 응모시간",
    width: "15%",
  },
]

export const EventApplyListTableHeader = () => {
  return (
    <ListTableHeader>
      {eventApplyListTableHeaderItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}
