import { Colors } from "@viver-inc/viver-ui"
import { useReactToPrint } from "react-to-print"
import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback, Fragment, useContext,
} from "react"
import Button from "components/Button"
import PopupPage from "components/Popup/PopupPage"
import styled from "@emotion/styled"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import {
  APPRAISAL_RESULTS,
  COMMON_LABELS,
  EXTERIOR_GROUP,
  StateResultType,
  AppraisalResultType,
  VisibleAppraisalResultType, VISIBLE_APPRAISAL_RESULTS,
} from "./common"
import { StyledBox, StyledSection, StyledStix, StyledTxt, StyledWrap } from "./styled"
import { translateAppraisalResultKo } from "./translate"
import { DiagnosisPrintSign } from "./DiagnosisPrintSign"
import { DiagnosisPrintReport, AppraisalCertificateReportType } from "./DiagnosisPrintReport"
import { DiagnosisPrintReportDetail } from "./DiagnosisPrintReportDetail"
import { DiagnosisPrintExterior, AppraisalCertificateExteriorType } from "./DiagnosisPrintExterior"
import { DiagnosisPrintPerformanceCheck, AppraisalCertificatePerformanceType } from "./DiagnosisPrintPerformanceCheck"
import { DiagnosisPrintInterior, AppraisalCertificateInteriorType } from "./DiagnosisPrintInterior"
import {
  getDiagnosisIssuance,
  getDiagnosisIssuanceAuto,
  getDiagnosisIssuanceManual,
  resultDiagnosisIssuance,
} from "apis/diagnosisAPI"
import { DiagnosisIssuanceType } from "types/DiagnosisIssuanceType"
import { AuthContext } from "../../../AuthContext"
import { toast } from "react-toastify"
import { PrintMarginsKey, useDiagnosisPrintMargin } from "./DiagnosisPrintMargin"


const EMPTY_INTERIOR = [
  {
    label: "칼리버",
    value: "",
    result: "",
  },
  {
    label: "로터",
    value: "",
    result: "",
  },
  {
    label: "밸런스휠",
    value: "",
    result: "",
  },
  {
    label: "헤러스프링",
    value: "",
    result: "",
  },
  {
    label: "팔렛포크",
    value: "",
    result: "",
  },
  {
    label: "이스케이프먼트",
    value: "",
    result: "",
  },
  {
    label: "메인플레이트",
    value: "",
    result: "",
  },
  {
    label: "배럴",
    value: "",
    result: "",
  },
]

export type UseDataType = {
  date: string
  verifyNo: string
  signUrl: string | null
  report: AppraisalCertificateReportType[]
  interior: AppraisalCertificateInteriorType[] | []
  performance: AppraisalCertificatePerformanceType[]
  exterior: AppraisalCertificateExteriorType[]
  appraisalResult: VisibleAppraisalResultType
}


const transformLabel = (key:string) => {
  const translate = COMMON_LABELS.find(item => item.key === key)
  return translate || { ko: key, en: key, key: key }
}

type getDataType =
  {
    productId: number;
    userId?: never;
    detailId?: never;
    advancedDiagnosis?: never;
    diagnosisId?: never;
  } | {
    productId?: never;
    userId: number;
    detailId: number;
    advancedDiagnosis: boolean;
    diagnosisId?: never;
  } | {
    productId?: never;
    userId?: never;
    detailId?: never;
    advancedDiagnosis?: never;
    diagnosisId: number;
}

type DiagnosisPrintPopupType = {
  isOpen: boolean;
  advancedDiagnosis?:boolean
  closePopup: () => void;
} & getDataType

const matchPrintMarginsLabel = (key:PrintMarginsKey) => {
  switch (key){
    case "pageTop":
      return "상단 여백"
    case "pageLeft":
      return "좌측 여백"
    case "dateTop":
      return "DATE 상단 여백"
    case "exteriorTop":
      return "Exterior 상단 여백"
    case "resultLeft":
      return "감정결과(영문) 좌측 여백"
    case "resultKoLeft":
      return "감정결과(국문) 좌측 여백"
    case "resultFontSize":
      return "감정결과(국문/영문) 글씨 크기"
    case "performanceTop":
      return "Performance 상단 여백"
    case "interiorTop":
      return "Interior 상단 여백"
    case "descriptionFontSize":
      return "일반감정진단서 interior 안내 글씨 크기"
  }
}

const createAppraisalReport = (result: DiagnosisIssuanceType) => {
  const report = result.resultDetailModelTags.map(item => {
    const translate = transformLabel(item.label);
    return { label: translate.en, labelKo: translate.ko, value: item.value, key: translate.key };
  });
  const reportSerialNo = transformLabel("serialNo");
  report.push({ label: reportSerialNo.en, labelKo: reportSerialNo.ko, value: result.serialNo, key: reportSerialNo.key });
  return report as AppraisalCertificateReportType[];
};

const createInteriorData = (result: DiagnosisIssuanceType) => {
  const interiorData = result.resultInterior !== null ? result.resultInterior : EMPTY_INTERIOR;
  return interiorData.map(item => {
    const translate = transformLabel(item.label);
    return { label: translate.en, labelKo: translate.ko, value: item.value, result: item.result, key: translate.key };
  }) as AppraisalCertificateInteriorType[];
};

const createPerformanceData = (result: DiagnosisIssuanceType) => {
  const writeWaterResistance = result.resultPerformanceCheck.find(item => item.label === "방수직접입력");
  return result.resultPerformanceCheck.map(item => {
    const translate = transformLabel(item.label);
    return {
      label: translate.en,
      labelKo: translate.ko,
      key: translate.key,
      value: item.value,
      userWriteValue: item.label === "방수" && item.value === "직접입력" ? writeWaterResistance?.value : "",
      range: item.range
    };
  }) as AppraisalCertificatePerformanceType[];
};

const createExteriorData = (result: DiagnosisIssuanceType) => {
  const exterior: AppraisalCertificateExteriorType[] = [];
  EXTERIOR_GROUP.forEach((groups) => {
    const groupData:AppraisalCertificateExteriorType["group"] = []
    groups.group.forEach(label => {
      const item = result.resultExteriors.find(d => d.label === label);
      if( item ) {
        const translate = transformLabel(item.label)
        groupData.push({
          label: translate.en,
          labelKo: translate.ko,
          key: translate.key,
          value: item.value,
        })
      }
    })
    const resultItem = result.resultExteriors.find(d => d.label === groups.result);
    if( resultItem ){
      exterior.push({
        group: groupData,
        result: resultItem.value as AppraisalResultType,
        resultKey: resultItem.label
      })
    }
  })
  return exterior;
};

const transformResponseData = (result:DiagnosisIssuanceType):UseDataType => {
  const report = createAppraisalReport(result);
  const interior = createInteriorData(result);
  const performance = createPerformanceData(result);
  const exterior = createExteriorData(result);

  return {
    date: result.verifyDate,
    verifyNo: result.verifyNo,
    signUrl: result.signUrl,
    report,
    interior,
    performance,
    appraisalResult: result.result as VisibleAppraisalResultType,
    exterior
  }
}

export const DiagnosisPrintPopup:FunctionComponent<DiagnosisPrintPopupType> = ({
  isOpen,
  closePopup,
  productId,
  userId,
  detailId,
  diagnosisId,
  advancedDiagnosis
}) => {
  const printComponentRef = useRef<HTMLDivElement|null>(null)

  const [appraisalResultData, setAppraisalResultData] = useState<UseDataType["appraisalResult"]>("Genuine")
  const [reportData, setReportData] = useState<UseDataType["report"]>([])
  const [interiorData, setInteriorData] = useState<UseDataType["interior"]>([])
  const [performanceData, setPerformanceData] = useState<UseDataType["performance"]>([])
  const [exteriorData, setExteriorData] = useState<UseDataType["exterior"]>([])
  const [originData, setOriginData] = useState<DiagnosisIssuanceType | null>(null)
  const [isPrint, setIsPrint] = useState<boolean>(true)
  const { user } = useContext(AuthContext)
  const [editable, setEditable] = useState<boolean>(originData?.verifyNo === "")
  const [showStyleEdit, setShowStyleEdit] = useState(false)
  const { getMargin, setMargin, margins, resetMargin } = useDiagnosisPrintMargin();



  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  })

  const handleReportValue = (label:string, value: string) => {
    setReportData(prev => (
      [...prev].map(item => (
        item.label === label ? {...item, value} : item
      ))
    ))

  };

  const handleAppraisalResultValue = (value: string) => {
    if (APPRAISAL_RESULTS.includes(value as AppraisalResultType)) {
      setAppraisalResultData(value as VisibleAppraisalResultType)
    }
  };

  const handleInterior = (label:string, value:string, result:string) => {
    setInteriorData(prev => (
      [...prev].map(item => (
        item.label === label ? { ...item, value: value as StateResultType, result: result as AppraisalResultType } : item
      ))
    ))
  }

  const handlePerformance = (label:string, key:string, value:string) => {
    setPerformanceData(prev => (
      [...prev].map(item => {

        if( item.label === label ){
          return {...item, [key]:value}
        } else {
          if( item.label === "방수직접입력" ) {
            if(key === "userWriteValue"){
              return { ...item, value }
            }
            if( label === "Water resistance" && value === "직접입력" ){
              return { ...item, value:""}
            }
          }
          return item
        }
      })
    ))
  }

  const handleExteriorValue = (label:string, value:string) => {
    setExteriorData(prev => (
      [...prev].map(item => {
        const group = item.group.map(g => (
          g.label === label ? {...g, value} : g
        ))
        return {
          group,
          result: item.result,
          resultKey: item.resultKey
        }
      })
    ))
  }

  const handleExteriorResult = (index:number, result:string) => {
    if( APPRAISAL_RESULTS.includes(result as AppraisalResultType) ){
      setExteriorData(prev => (
        [...prev].map((item, prevIndex) => (
          prevIndex === index ? {...item, result: result as AppraisalResultType } : item
        ))
      ))
    }
  }


  // 저장
  const saveData = useCallback(()=>{
    if( originData === null ) return

    if( originData.verifyNo !== "" ) {
      toast.error("이미 발급 완료된 진단서 입니다.");
      return
    }

    if(!window.confirm('발급을 완료하면 진단서 발급 관리에\n기록되고 프린트 할 수 있습니다.')){
      return
    }

    const resultDetailModelTags = [...reportData]
      .map(item => ({label: item.key, value: item.value}))
      .filter(item => item.label !== "serialNo")
    const serialNo = [...reportData].find(item => item.key === "serialNo")
    const resultExteriors:{label:string, value:string}[] = []
    exteriorData.forEach(item => {
      item.group.forEach(g => {
        resultExteriors.push({
          label: g.key,
          value: g.value
        })
      })
      resultExteriors.push({
        label: item.resultKey,
        value: item.result
      })
    })

    const resultInterior = originData.resultInterior !== null ? [...interiorData].map((item) => ({
      label: item.key,
      value: item.value,
      result: item.result
    })) : null

    const resultPerformanceCheck = [...performanceData].map((item) =>{
      if( item.key === "방수직접입력" ) {
        return {
          label: item.key,
          value: item.value,
        }
      } else {
        return {
          label: item.key,
          value: item.value,
          range: item.range
        }
      }
    })

    const editData = {
      ...originData,
      serialNo:serialNo?.value,
      result: appraisalResultData,
      resultDetailModelTags,
      resultExteriors,
      resultInterior,
      resultPerformanceCheck
    } as DiagnosisIssuanceType
    resultDiagnosisIssuance(editData).then((result)=>{
      setOriginData(prev => {
        if( prev !== null ){
          return {
            ...prev,
            verifyNo:result.verifyNo
          }
        } else {
          return {
            ...result
          }
        }
      })
      if(result.verifyNo !== ""){
        // 진단서 저장시 프린트 버튼 활성화. ( verifyNo 가 있어야 출력 가능 )
        setIsPrint(true)
        setEditable(false)
      }
    }).catch(error => {
      toast.error(error.message);
    })
  },[originData, reportData, exteriorData, interiorData, performanceData, appraisalResultData])


  const handleData = useCallback((result:DiagnosisIssuanceType) => {
    const setData = transformResponseData(result)
    setAppraisalResultData(setData.appraisalResult)
    setReportData([...setData.report])
    setInteriorData([...setData.interior])
    setPerformanceData([...setData.performance])
    setExteriorData([...setData.exterior])

    setOriginData({...result})
    setIsPrint(setData.verifyNo !== "")
    setEditable(setData.verifyNo === "")
  }, [])

  const fetchData = useCallback((fetchFunction: () => Promise<any>) => {
    fetchFunction().then(handleData).catch((error) => {
      toast.error(error.message);
      closePopup();
    });
  }, [handleData, closePopup]);

  useEffect(() => {
    if( isOpen ){
      if (productId) {
        fetchData(()=> getDiagnosisIssuance(productId))
      } else if (userId && detailId && advancedDiagnosis !== undefined) {
        fetchData(()=> getDiagnosisIssuanceManual(userId, detailId, advancedDiagnosis))
      } else if (diagnosisId) {
        fetchData(()=> getDiagnosisIssuanceAuto(diagnosisId))
      }
    }
  }, [isOpen, productId, userId, detailId, diagnosisId, advancedDiagnosis, fetchData])


  return (
    <PopupPage isOpen={isOpen} closePage={closePopup}>
      <ContentWrapper>
        <PopupPageTitle>
          <StyledBox $flexDirection={"column"}>
            <strong onClick={()=>{setShowStyleEdit(prev => !prev)}} style={{cursor:"pointer"}}>진단서 발급</strong>
            {
              showStyleEdit ? (
                <StyledBox style={{flexWrap:"wrap"}} $flexDirection={"column"} $justifyContent={"center"} $gap={5}>
                  {
                    Object.entries(margins).map( ([key]) => (
                      <StyledTxt $fontSize={8} $lineHeight={12} key={key}>
                        {matchPrintMarginsLabel(key as PrintMarginsKey)} :
                        <input value={getMargin(key as PrintMarginsKey)} type="number" min={0}
                               onChange={(event) => setMargin(key as PrintMarginsKey, parseInt(event.target.value, 10))} />
                      </StyledTxt>
                    ))
                  }
                  <Button onClick={resetMargin}>설정 초기화</Button>
                </StyledBox>
              ) :null
            }
          </StyledBox>
        </PopupPageTitle>
        <PopupPageScrollContainer>
          <StyledWrap ref={printComponentRef} pageLeft={getMargin("pageLeft")} pageTop={getMargin("pageTop")}>
            <StyledBox
              $width={46}
              $marginTop={20}
              $marginRight={20}
              style={{position: "absolute", right:0, top:0, }}
              className="is-design">
              <img src={`${process.env.PUBLIC_URL}/viver.png`} alt="viver" style={{display:"block", width:"100%"}} />
            </StyledBox>
            {/* Report */}
            <StyledSection>
              {/* 섹션 타이틀 */}
              <StyledBox
                $height={37}
                $paddingBottom={2}
                $paddingLeft={2}
                $flexDirection={"column"}
                $justifyContent={"flex-end"}
                style={{borderBottom: `2px solid ${Colors.GRAY_100}` }}
                className="is-design"
              >
                <StyledStix
                  $fontSize={13}
                  $weight={700}
                  $isItalic={true}
                  $lineHeight={16}
                >
                  VIVER Watch<br/>Authentication Report
                </StyledStix>
              </StyledBox>

              {/* Date, Verify No. */}
              {
                originData !== null ?
                  (
                    <StyledBox  $flexDirection="column" $paddingTop={getMargin("dateTop")} $paddingLeft={5} $gap={5}>
                      <StyledBox $gap={14} $alignItems={"center"}>
                        <StyledTxt
                          $fontSize={7}
                          $lineHeight={10}
                          $color="#727171"
                          $weight={700}
                          className="is-design"
                        >
                          DATE
                        </StyledTxt>
                        <StyledTxt $fontSize={8} $lineHeight={10} $color="#000" style={{flexShrink:0 }}>
                          {originData.verifyDate}
                        </StyledTxt>
                      </StyledBox>
                      <StyledBox $gap={14} $alignItems={"center"}>
                        <StyledTxt
                          as="strong"
                          $fontSize={7}
                          $lineHeight={10}
                          $color="#727171"
                          $weight={700}
                          className="is-design"
                        >
                          VIVER Verify No.
                        </StyledTxt>
                        <StyledTxt $fontSize={8} $lineHeight={10} $color="#000" style={{flexShrink:0 }}>
                          {originData.verifyNo}
                        </StyledTxt>
                      </StyledBox>
                    </StyledBox>
                  ) : null
              }


              {/* report list */}
              <DiagnosisPrintReport
                editable={editable}
                report={reportData}
                handleChange={(label, value) =>{
                  handleReportValue(label, value)
                }}
              />

              {/* report detail */}
              <DiagnosisPrintReportDetail
                editable={editable}
                appraisalResult={appraisalResultData}
                selectItems={[...VISIBLE_APPRAISAL_RESULTS]}
                resultLeft={getMargin("resultLeft")}
                resultKoLeft={getMargin("resultKoLeft")}
                resultFontSize={getMargin("resultFontSize")}
                handleChange={(value) => {
                  handleAppraisalResultValue(value)
                }}
              />


              {/* 서명 */}
              {
                originData !== null ? (
                  <StyledBox $paddingLeft={5} $paddingTop={28} $flexDirection={"column"} $alignItems={"flex-end"}>
                    <DiagnosisPrintSign
                      userId={user?.name ? user?.name : ""}
                      signUrl={originData.signUrl}
                    />

                    {/* 안내문구 */}
                    <StyledBox $paddingTop={20} className="is-design">
                      <StyledTxt
                        $fontSize={5}
                        $weight={400}
                        $lineHeight={8}
                        $color={"#231815"}
                        style={{letterSpacing:0.05}}
                      >
                        본 감정결과는 감정 항목에 한정된 것으로, 감정 항목 이외의 부분에 대해서는 보증하지 않습니다. 또한 감정 시점의 시계 상태에<br />
                        대한 보증이므로 밀봉이 해제된 이후의 위변조에 행위에 대해서는 바이버가 책임지지 않습니다.
                      </StyledTxt>
                    </StyledBox>

                    {/* 스티커 위치 */}
                    <StyledBox
                      $width={34}
                      $height={34}
                      $marginBottom={5}
                      className="is-design"
                      style={{
                        backgroundColor:"#F2F2F2",
                        borderRadius:"50%",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                      }}
                    ></StyledBox>
                  </StyledBox>
                ) : null
              }

            </StyledSection>

            {/* results */}
            <StyledSection>
              {/* 섹션 타이틀 */}
              <StyledBox
                $height={37}
                $paddingBottom={2}
                $paddingLeft={2}
                $flexDirection={"column"}
                $justifyContent={"flex-end"}
                style={{borderBottom: `2px solid ${Colors.GRAY_100}` }}
                className="is-design"
              >
                <StyledBox $gap={4} $alignItems={"flex-end"}>
                  <StyledStix $fontSize={13} $weight={700} $isItalic={true} $lineHeight={16}>Result</StyledStix>
                  <StyledTxt $color="#000" $fontSize={5} $lineHeight={12}>감정진단결과</StyledTxt>
                </StyledBox>
              </StyledBox>

              {/* Exterior */}
              <StyledBox
                $paddingLeft={4}
                $paddingTop={getMargin("exteriorTop")}
                $paddingBottom={6}
                className="is-design"
              >
                <StyledStix
                  $color="#595757"
                  $fontSize={7}
                  $lineHeight={15}
                  $isItalic={true}
                >Exterior</StyledStix>
              </StyledBox>
              <DiagnosisPrintExterior
                editable={editable}
                exteriors={exteriorData}
                handelChange={(label, value) =>{
                  handleExteriorValue(label, value)
                }}
                handleResultChange={(index, value) =>{
                  handleExteriorResult(index, value)
                }}
              />


              {/* Performance Check */}
              <StyledBox
                $paddingLeft={4}
                $paddingTop={getMargin("performanceTop")}
                $paddingBottom={10}
                className="is-design"
              >
                <StyledStix
                  $color="#595757"
                  $fontSize={7}
                  $lineHeight={15}
                  $isItalic={true}
                >Performance Check</StyledStix>
              </StyledBox>
              <DiagnosisPrintPerformanceCheck
                items={performanceData}
                editable={editable}
                handleChange={(label, key, value) => {
                  handlePerformance(label, key, value)
                }}
              />

              {/* Interior */}
              <StyledBox
                $paddingLeft={4}
                $paddingTop={getMargin("interiorTop")}
                $paddingBottom={5}
              >
                <StyledStix
                  $color="#595757"
                  $fontSize={7}
                  $lineHeight={15}
                  $isItalic={true}
                  className="is-design"
                >Interior</StyledStix>
              </StyledBox>
              <DiagnosisPrintInterior
                editable={editable}
                isDetail={originData?.resultInterior !== null}
                items={interiorData}
                descriptionFontSize={getMargin("descriptionFontSize")}
                handleStateChange={(label, value, result) => {
                  handleInterior(label, value, result)
                }}
                handleValueChange={(label, value, result) => {
                  handleInterior(label, value, result)
                }}
              />

              {/* 하단 설명 */}
              <Description  items={[...VISIBLE_APPRAISAL_RESULTS]}/>
            </StyledSection>
          </StyledWrap>
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <StyledBox $justifyContent={"space-between"}>
            <StyledBox  $width={"20%"} >
              <Button onClick={closePopup}>닫기</Button>
            </StyledBox>
            <StyledBox  $gap={10} $width={"50%"} >
              <Button onClick={saveData} disabled={!editable}>진단서 발급</Button>
              <Button buttonStyle="blue-border" onClick={handlePrint} disabled={!isPrint}>프린트</Button>
            </StyledBox>
          </StyledBox>
        </PopupPageFooter>
      </ContentWrapper>
    </PopupPage>
  )
}

const Description:FunctionComponent<{
  items: string[]
}> = ({items}) => {
  return (
    <StyledBox $justifyContent={"flex-end"} $alignItems={"center"} $gap={4} className="is-design">
      <StyledTxt
        $color="#6D6D6D"
        $fontSize={5}
        $weight={700}
        $lineHeight={8}
      >
        감정기준용어
      </StyledTxt>

      {
        items.map((item, index) => (
          <Fragment key={index}>
            <div style={{width:1, height:3, backgroundColor:"#6D6D6D"}}></div>
            {/*<StyledBox $width={1} $height={2} style={{backgroundColor:"#6D6D6D"}}></StyledBox>*/}
            <StyledBox $gap={2} $alignItems={"center"}>
              <StyledStix
                $isItalic={true}
                $color={"#231815"}
                $fontSize={6}
                $lineHeight={8}
                $weight={700}
              >
                {item}
              </StyledStix>
              <StyledTxt
                $color={"#231815"}
                $fontSize={5}
                $lineHeight={8}
                $weight={500}
              >
                {translateAppraisalResultKo(item)}
              </StyledTxt>
            </StyledBox>
          </Fragment>
        ))
      }
    </StyledBox>
  )
}

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	padding: 16px;
`