import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel, FormGroup,
    Grid, Switch,
    TextField
} from "@mui/material";
import {ShowroomState} from "../index";
import React, {useEffect, useState} from "react";
import {ShowroomType} from "types/ShowroomType";
import {ShowroomPageActionCode} from "../showroomPageActionCode";
import {createShowroom, getShowrooms} from "../../../apis/showroomAPI";

type AddShowroomPopupProps = {
    state: ShowroomState,
    dispatch: any
}

const initShowroom = {
    onDisplay: false,
}


const AddShowroomPopup: React.FC<AddShowroomPopupProps> = ({state, dispatch}) => {
    // @ts-ignore
    const [showroom, setShowroom] = useState<ShowroomType>(initShowroom)

    useEffect(() => {
        // @ts-ignore
        setShowroom(initShowroom)
    }, [state.openAddShowroomPopup])

    return <Dialog open={state.openAddShowroomPopup} maxWidth={"xs"} fullWidth>
        <DialogTitle>쇼룸 추가</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DialogContentText>
                        추가할 쇼룸 정보를 입력해주세요.
                    </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id={"name"}
                        label={"쇼룸 이름"}
                        placeholder={"ex) 압구정 로데오역점"}
                        InputLabelProps={{shrink: true,}}
                        value={showroom?.name}
                        // @ts-ignore
                        onChange={(params) => setShowroom({...showroom, name: params.target.value as string})}
                        autoFocus
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id={"address"}
                        label={"쇼룸 주소"}
                        placeholder={"ex) 서울시 강남구 선릉로 157길 24, 1층"}
                        InputLabelProps={{shrink: true,}}
                        value={showroom?.address}
                        // @ts-ignore
                        onChange={(params) => setShowroom({...showroom, address: params.target.value as string})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id={"phone"}
                        label={"쇼룸 전화번호"}
                        placeholder={"ex) 02-518-0351"}
                        InputLabelProps={{shrink: true,}}
                        value={showroom?.phone}
                        // @ts-ignore
                        onChange={(params) => setShowroom({...showroom, phone: params.target.value as string})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id={"operatingTime"}
                        label={"운영 시간"}
                        placeholder={"ex) 월요일 ~ 일요일 : 11시~20시 (연중 무휴)"}
                        InputLabelProps={{shrink: true,}}
                        value={showroom?.operatingTime}
                        onChange={(params) => setShowroom({...showroom, operatingTime: params.target.value as string})}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={showroom?.onDisplay}
                                onChange={(_event, checked) => setShowroom({...showroom, onDisplay: checked})}
                            />}
                            label="노출"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <DialogActions>
                <Button color={"error"} onClick={() => {
                    dispatch({type: ShowroomPageActionCode.CLOSE_ADD_SHOWROOM_POPUP})
                }}>취소</Button>
                <Button color={"primary"} onClick={() => {
                    createShowroom(showroom).then(() => {
                        getShowrooms().then((results) => {
                            dispatch({type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: results})
                            dispatch({type: ShowroomPageActionCode.CLOSE_ADD_SHOWROOM_POPUP})
                        })
                    })
                }}>추가</Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

export default AddShowroomPopup