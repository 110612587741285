import {AdminUserType} from "./AdminUserType";

export const enum MessageStatusCode {
  발송대기 = "발송대기",
  발송완료 = "발송완료",
  발송실패 = "발송실패",
  발송취소 = "발송취소",
}

export const enum UserGroupTypeCode {
  개발팀 = "개발팀",
  전체회원 = "전체회원",
  특정회원 = "특정회원",
}

export const enum NotificationTypeCode {
  매거진_신규 = "매거진_신규",
  마케팅_기타 = "마케팅_기타",
  공지_이벤트 = "공지_이벤트",
  공지_기타 = "공지_기타",
}

export declare interface UserNotificationScheduleType {
  id?: number
  status?: MessageStatusCode
  notificationType?: NotificationTypeCode,
  userGroupType?: UserGroupTypeCode
  userIds?: number[]
  inAppMessage?: InAppMessageContentType
  pushMessage?: PushMessageContentType
  scheduledAt?: string
  canceledBy?: AdminUserType
  createdAt?: string
  createdBy?: AdminUserType
  updatedAt?: string
  updatedBy?: AdminUserType
}
export declare interface InAppMessageContentType {
  notificationGroup? : string,
  title? : string,
  titleSecondary? : string,
  linkUrl? : string,
  usage? : boolean,
}

export declare interface PushMessageContentType {
  notificationGroup? : string,
  title?: string,
  body?: string,
  linkUrl?: string,
  ad?: boolean,
  usage?: boolean,
}
