import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useState } from "react"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import _ from "lodash"
import { SearchKeywordType } from "types/SearchKeywordType"
import { useMount } from "react-use"
import { getProductSearchKeywords, reOrderProductSearchKeywordPriority } from "apis/productAPI"
import { toast } from "react-toastify"

type Props = {
  closePopup: () => void
}

export const SearchKeywordReorderPopup = ({ closePopup }: Props) => {
  const [searchKeywordList, setSearchKeywordList] = useState<SearchKeywordType[]>([])

  const handleSubmit = () => {
    const parsedRow = searchKeywordList.map((row) => ({ id: row.id }))
    reOrderProductSearchKeywordPriority(parsedRow).then((response) => {
      toast.success("키워드 순서가 변경되었습니다.")
      closePopup()
    })
  }

  const getSearchKeyword = () => {
    getProductSearchKeywords().then((result) => {
      setSearchKeywordList(result.sort((keywordA, keywordB) => keywordA.priority - keywordB.priority))
    })
  }

  useMount(() => {
    getSearchKeyword()
  })

  const getColumns = () => {
    return [
      { field: "priority", headerName: "노출순서" },
      { field: "keyword", headerName: "키워드", flex: 1 },
      { field: "count", headerName: "노출 상품 수", width: 100 },

      {
        field: "actions",
        type: "actions",
        headerName: "순서 변경",
        width: 100,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<ArrowUpwardIcon />}
            label={"위로"}
            onClick={() => {
              const index = _.findIndex(searchKeywordList, { id: params.row.id })
              if (index > 0) {
                setSearchKeywordList([
                  ..._.slice(searchKeywordList, 0, index - 1),
                  searchKeywordList[index],
                  searchKeywordList[index - 1],
                  ..._.slice(searchKeywordList, index + 1, searchKeywordList.length),
                ])
              }
            }}
          />,
          <GridActionsCellItem
            icon={<ArrowDownwardIcon />}
            label={"아래로"}
            onClick={() => {
              const index = _.findIndex(searchKeywordList, { id: params.row.id })
              if (index < searchKeywordList.length) {
                setSearchKeywordList([
                  ..._.slice(searchKeywordList, 0, index),
                  searchKeywordList[index + 1],
                  searchKeywordList[index],
                  ..._.slice(searchKeywordList, index + 2, searchKeywordList.length),
                ])
              }
            }}
          />,
        ],
      },
    ]
  }

  return (
    <Dialog open maxWidth={"md"} fullWidth>
      <DialogTitle>키워드 순서 변경</DialogTitle>
      <DialogContent>
        <Box height={700}>
          <DataGrid columns={getColumns()} rows={searchKeywordList} getRowHeight={() => 70} hideFooter />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleSubmit}>
          수정
        </Button>
        <Button variant={"contained"} color={"error"} onClick={closePopup}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}
