import styled from "@emotion/styled"
import { useRef } from "react"
import { useDrag, useDrop, XYCoord } from "react-dnd"
import { FaqType } from "types/FaqType"
import { Identifier } from "dnd-core"

const IconSrc = require("images/24_align_justify.png")

type Props = {
  faqItem: FaqType
  isSelected: boolean
  selectFag: (faqItem: FaqType) => void
  onChangeOrder: (prevPriority: number, newPriority: number) => void
}

export const DraggableFaqListItem = ({ faqItem, isSelected, selectFag, onChangeOrder }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drag] = useDrag(() => ({
    type: "FAQ_ITEM",
    item: { priority: faqItem.priority },
  }))

  const [{ handlerId }, drop] = useDrop<{ priority: number }, void, { handlerId: Identifier | null }>({
    accept: "FAQ_ITEM",
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: { priority: number }, monitor) => {
      if (!ref.current) {
        return
      }

      const dragIndex = item.priority
      const hoverIndex = faqItem.priority

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onChangeOrder(dragIndex, hoverIndex)
      item.priority = hoverIndex
    },
  })

  drag(drop(ref))

  return (
    <FaqListItem ref={ref} isSelected={isSelected} data-handler-id={handlerId} onClick={() => selectFag(faqItem)}>
      <Icon src={IconSrc} />
      <CategoryLabel>{faqItem.type}</CategoryLabel>
      <FaqTitle>{faqItem.title}</FaqTitle>
    </FaqListItem>
  )
}

const FaqListItem = styled.div<{ isSelected: boolean }>`
  display: inline-flex;
  align-items: center;
  background: ${({ isSelected }) => (isSelected ? "#F0F2F5" : "#fff")};

  :not(:last-child) {
    border-bottom: 1px solid #f0f2f5;
  }
`

const CategoryLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  flex-shrink: 0;

  background: #ffffff;
  border: 1px solid #ced2d6;
  border-radius: 21px;
  color: #666f78;
  font-size: 14px;
`

const FaqTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #1c2229;
  margin: 16px;

  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-all;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 10px;
`
