import { BuyOrderType } from "./BuyOrderType"
import { SellOrderType } from "./SellOrderType"
import {
  Accessories,
  AccessoriesConditionCode,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  ProductConditionCode,
} from "./DiagnosisType"
import { ResourceType } from "./ResourceType"
import { SelfCheckType } from "./ProductType"

export const enum CollectionStatusCode {
  보유중 = "보유중",
  진단중 = "진단중",
  판매중 = "판매중",
  판매완료 = "판매완료",
  삭제 = "삭제",
}
export const enum ExposureLevelCode {
  공개 = "공개",
  비공개 = "비공개",
}

export declare interface UserCollectionType {
  id?: number
  userId?: number
  detailModelId?: number
  status?: CollectionStatusCode
  exposureLevel?: ExposureLevelCode
  sellOrder?: SellOrderType
  buyOrder?: BuyOrderType
  repurchaseGuaranteeUntil?: string
  accessoriesCondition?: AccessoriesConditionCode
  stampingYear?: number
  officialGuarantee?: OfficialGuaranteeCode
  originalBoxCondition?: OriginalBoxConditionCode
  productCondition?: ProductConditionCode
  purchasePrice?: number
  thumbnail?: string
  productId?: number
  gallery?: ResourceType[]
  accessories?: Accessories
  selfCheck?: SelfCheckType
  createdAt?: string
  updatedAt?: string
}

export type UserCollectionSearchParams = {
  userId?: number[]
  status?: CollectionStatusCode[]
  detailModelId?: number[]
  exposureLevel?: ExposureLevelCode[]
  repurchaseGuarantee?: boolean
  size?: number
  page?: number
}
