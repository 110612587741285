import React from "react";
import QRCode from "react-qr-code";


type ProductQRProps = {
    id?: string|number,
    size: number
}

const ProductQR: React.FC<ProductQRProps> = ({id, size = 120}) => {
    return (
        <QRCode size={size} value={`https://office.viver.co.kr/qr-code?product=${id}`}/>
    )
}

export default ProductQR