import { getModelRequestList } from "apis/modelRequestAPI"

import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { ModelRequestListItemType } from "types/ModelRequestType"
import { PaginationParamsType } from "types/PageType"
import { isNotEmpty } from "utils/validationUtils"

const FETCH_LIST_COUNT = 20

export const useModelRequestListData = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<ModelRequestListItemType["status"][]>([
    "등록거절",
    "등록완료",
    "등록요청",
    "등록중",
    "요청취소",
  ])

  const [modelRequestList, setModelRequestList] = useState<ModelRequestListItemType[]>([])

  const [searchParams, setSearchParams] = useState<any>({
    filter: "fullRefNo",
    keyword: "",
  })

  const parseSearchParams = useCallback((searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: isNotEmpty(searchParams.keyword) ? searchParams.keyword : undefined,
    }
  }, [])

  const fetchModelRequestList = useCallback(
    ({
      pageNumber = 0,
      searchParams,
      statusList,
    }: {
      pageNumber?: number
      searchParams?: any
      statusList?: ModelRequestListItemType["status"][]
    }) => {
      getModelRequestList({
        size: FETCH_LIST_COUNT,
        page: pageNumber,
        ...parseSearchParams(searchParams),
        status: statusList?.join(","),
        sort: "createdAt,desc",
      })
        .then(({ content, totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
          setModelRequestList(content)
          setPaginationParams((prev) => ({
            ...prev,
            number,
            totalElements,
            totalPages,
            first,
            size,
          }))
        })
        .catch(() => {
          toast.error("모델등록 요청리스트를 불러오는데 실패했습니다.")
        })
    },
    [parseSearchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      fetchModelRequestList({ pageNumber: pageNumber - 1, searchParams, statusList: selectedStatusFilter })
    },
    [searchParams, fetchModelRequestList, selectedStatusFilter]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev: any) => ({ ...prev, keyword }))
  }, [])

  const changeSearchFilterValue = useCallback((searchFilterValue: any) => {
    setSearchParams((prev: any) => ({ ...prev, filter: searchFilterValue }))
  }, [])

  const changeStatusFilter = useCallback(
    (status: ModelRequestListItemType["status"]) => {
      const changedStatusFilterList = selectedStatusFilter.includes(status)
        ? selectedStatusFilter.filter((prevStatus) => prevStatus !== status)
        : selectedStatusFilter.concat(status)
      setSelectedStatusFilter(changedStatusFilterList)
      fetchModelRequestList({ searchParams, statusList: changedStatusFilterList })
    },

    [selectedStatusFilter, searchParams, fetchModelRequestList]
  )

  const onSearch = useCallback(() => {
    fetchModelRequestList({ searchParams, statusList: selectedStatusFilter })
  }, [fetchModelRequestList, searchParams, selectedStatusFilter])

  const onRefresh = useCallback(() => {
    fetchModelRequestList({})
  }, [fetchModelRequestList])

  useMount(() => {
    onRefresh()
  })

  return {
    modelRequestList,
    paginationParams,
    changePageNumber,
    onSearch,
    onRefresh,
    changeSearchKeyword,
    changeSearchFilterValue,
    searchParams,
    selectedStatusFilter,
    changeStatusFilter,
  }
}
