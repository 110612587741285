import { DateTimePicker } from "@mui/lab"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import ImageUploadButton from "components/ImageUploadButton"
import RowBlock from "components/RowBlock"
import { DateTime } from "luxon"
import React, { useCallback } from "react"
import { toast } from "react-toastify"

import { isNil } from "utils/validationUtils"
import { useMyPageBannerEditPopup } from "./useMyPageBannerEditPopup"
import { BannerExposureAreaCodeList } from "types/MyPageBannerType"
import { ThemeModeTypeCodeList } from "../../../../types/ShopHeroBannerType"
import CheckBox from "../../../../components/Checkbox"
import styled from "@emotion/styled"

type Props = {
  close: () => void
  editingBannerId?: number
  refreshList: () => void
}

export const MyPageBannerEditor = ({ close, editingBannerId, refreshList }: Props) => {
  const { form, setForm, createMyPageBanner, editMyPageBanner, uploadImage, validateForm } =
    useMyPageBannerEditPopup(editingBannerId)

  const handleClickSave = useCallback(async () => {
    if (!validateForm()) return

    try {
      if (isNil(editingBannerId)) {
        await createMyPageBanner()
        close()
      } else {
        await editMyPageBanner()
      }

      refreshList()
      toast.success("저장되었습니다.")
    } catch {
      toast.error("저장에 실패했습니다.")
    }
  }, [createMyPageBanner, editMyPageBanner, editingBannerId, close, validateForm, refreshList])

  const getExposureAreas = (area : string, checked : boolean )  => {
    let exposureAreas = ""
    if ( checked) {
      exposureAreas = form.exposureAreas ? `${form.exposureAreas},${area}` : area;
    }
    else {
      exposureAreas = form.exposureAreas ? form.exposureAreas?.replace(area, "")  : "";
    }
    exposureAreas = exposureAreas.replace(/^,|,$/g, ''); // 앞뒤의 콤마 제거
    exposureAreas = exposureAreas.replace(/,,/g, ','); // 중간에 연속된 콤마 제거
    exposureAreas = exposureAreas.replace(/^,|,$|,,/g, '');// 만약 문자열이 비어있거나 중간에 여러 콤마가 있어서 콤마가 남아있는 경우 제거
    return exposureAreas
  }

  return (
    <Dialog maxWidth={"xl"} open>
      <DialogTitle>배너 추가</DialogTitle>
      <DialogContent>
        <Stack direction={"row"}>
          <Stack spacing={3}>
            <RowBlock title={"배너 이미지"}>
              <ImageUploadButton
                height={"200px"}
                width={"400px"}
                handleImage={uploadImage}
                imageUrl={form.bannerImageUrl}
                handleDeleteImage={() => {
                  setForm((prev) => ({ ...prev, bannerImageUrl: "" }))
                }}
              />
            </RowBlock>
            <RowBlock title={"타입"}>
              <Stack direction={'row'}  justifyContent={'flex-start'}>
                <RadioGroup row sx={{ justifyContent: "center" }}
                            onChange={(event, value) => {
                              // @ts-ignore
                              setForm((prev) => ({...prev, themeModeType: value}))
                            }}
                >
                  {ThemeModeTypeCodeList.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      label={item.title}
                      checked={form.themeModeType === item.value}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </RowBlock>
            <RowBlock title={"텍스트"}   >
              <Stack direction={"column"} spacing={1}>
                <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={form.title1} onChange={(e) => setForm((prev) => ({...prev, title1: e.target.value}))} placeholder={'첫째줄 소문구'}/>
                <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={form.title2} onChange={(e) => setForm((prev) => ({...prev, title2: e.target.value}))} placeholder={'둘째줄 대문구'}/>
              </Stack>
            </RowBlock>
            <RowBlock title={"링크 URL"}>
              <TextField
                size={"small"}
                value={form.bannerLinkUrl}
                fullWidth
                onChange={(e) => setForm((prev) => ({ ...prev, bannerLinkUrl: e.target.value }))}
              />
            </RowBlock>
            <RowBlock title={"노출 여부"}>
              <Stack direction={"row"}>
                <Switch
                  checked={form.onDisplay ?? false}
                  onChange={(e) => setForm((prev) => ({ ...prev, onDisplay: e.target.checked }))}
                />
              </Stack>
            </RowBlock>
            <RowBlock title={"노출 영역"}>
              <Stack direction={"column"} >
                  {BannerExposureAreaCodeList.map((item) => (
                    <FormControlLabel label={item.title} control={
                      <Checkbox checked={form.exposureAreas?.includes(item.value)}
                                onChange={(e) => {setForm((prev) => ({ ...prev, exposureAreas:getExposureAreas(item.value, e.target.checked)}))}}/>
                    }/>
                    ))}
              </Stack>
            </RowBlock>
            <RowBlock title={"노출"}   >
              <Stack direction={'row'} alignItems={'center'}   >
                <CheckBox checked={form?.showDomesticUser}   id="showDomesticUser"  onChange={() => setForm((prev) => ({...prev, showDomesticUser: !prev.showDomesticUser})) } />
                <CheckBoxLabel htmlFor="showDomesticUser" >
                  내국인 회원 (국내거주 외국인 포함)
                </CheckBoxLabel>
                <CheckBox checked={form?.showForeignUser}   id="showForeignUser"  onChange={() => setForm((prev) => ({...prev, showForeignUser: !prev.showForeignUser})) } />
                <CheckBoxLabel htmlFor="showForeignUser" >
                  외국인 회원
                </CheckBoxLabel>
              </Stack>
            </RowBlock>
            <RowBlock title={"정렬순서"}>
              <Stack direction={"row"}>
                <TextField
                  size={"small"}
                  type="number"
                  value={form.priority}
                  fullWidth
                  onChange={(e) => {
                    const value = Number(e.target.value)
                    if (isNaN(value)) return
                    if (value < 0) return

                    setForm((prev) => ({ ...prev, priority: value }))
                  }}
                />
              </Stack>
            </RowBlock>
            <RowBlock title={"노출 기간"}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <DateTimePicker
                  onChange={(e) => {
                    const changedDate = e?.toISO() ?? DateTime.now().toISO()

                    setForm((prev) => ({
                      ...prev,
                      startedAt: changedDate,
                    }))
                  }}
                  value={DateTime.fromISO(form.startedAt ?? DateTime.now().toISO())}
                  renderInput={(props) => <TextField {...props} />}
                  label={"게시 시작"}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                />
                <Typography>~</Typography>
                <DateTimePicker
                  onChange={(e) => {
                    const changedDate = e?.toISO() ?? DateTime.now().toISO()

                    setForm((prev) => ({
                      ...prev,
                      endedAt: changedDate,
                    }))
                  }}
                  value={DateTime.fromISO(form.endedAt ?? DateTime.now().toISO())}
                  renderInput={(props) => <TextField {...props} />}
                  label={"게시 종료"}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                />
              </Stack>
            </RowBlock>
          </Stack>

          {/*<Box position={"relative"} width={"360px"} height={"360px"}>*/}
          {/*  <Box*/}
          {/*    style={{*/}
          {/*      position: "absolute",*/}
          {/*      width: "360px",*/}
          {/*      height: "360px",*/}
          {/*      left: "0px",*/}
          {/*      top: "0px",*/}
          {/*      border: "solid",*/}
          {/*      background: "#33333333",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {form.bannerImageUrl ? (*/}
          {/*      <img*/}
          {/*        width={"100%"}*/}
          {/*        height={"100%"}*/}
          {/*        src={form.bannerImageUrl}*/}
          {/*        style={{ position: "absolute", objectFit: "contain" }}*/}
          {/*        alt="배너 미리보기"*/}
          {/*      />*/}
          {/*    ) : (*/}
          {/*      <></>*/}
          {/*    )}*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleClickSave}>
          저장
        </Button>
        <Button variant={"contained"} color={"error"} onClick={close}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`