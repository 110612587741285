import styled from "@emotion/styled"
import Modal from "components/Modal"

import Button from "components/Button"

import { useProductStockTargetSelectorData } from "./useProductStockTargetSelectorData"

type Props = {
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
  closeModal: () => void
  selectedProductCount: number
  listId: number | null
  productIdList: number[]
  clearSelectedProductList: () => void
}

export const ProductStockTargetSelectorPopup = ({
  closeModal,
  selectedProductCount,
  site,
  listId,
  productIdList,
  clearSelectedProductList,
}: Props) => {
  const {
    productStockList,
    newProductStockName,
    selectedStock,
    onSaveList,
    onChangeNewProductStockName,
    onAddNewList,
    onSelectStock,
  } = useProductStockTargetSelectorData(site, listId, productIdList)

  return (
    <Modal isOpen closeModal={closeModal}>
      <ContentWrapper>
        <TitleWrapper>
          <Title>리스트 관리</Title>
          <SubTitle>총 {selectedProductCount}개의 상품</SubTitle>
        </TitleWrapper>
        <ListWrapper>
          {productStockList.concat({ id: -1, name: "새로운 리스트" }).map((item) => {
            return (
              <SelectorListItem key={item.id} onClick={() => onSelectStock(item.id)}>
                <RadioWrapper>
                  <Radio type="radio" readOnly checked={selectedStock?.id === item.id} />
                </RadioWrapper>

                {item.id === -1 ? (
                  <InlineWrapper>
                    <Input
                      placeholder="리스트 추가"
                      value={newProductStockName}
                      onChange={(e) => onChangeNewProductStockName(e.target.value)}
                    />
                    <Icon src={require("../../../images/24_plus.png")} onClick={onAddNewList} />
                  </InlineWrapper>
                ) : (
                  <div style={{ marginLeft: "12px" }}>{item.name}</div>
                )}
              </SelectorListItem>
            )
          })}
        </ListWrapper>

        <InlineWrapper style={{ marginTop: "20px", justifyContent: "flex-end" }}>
          <ButtonWrapper>
            <Button
              onClick={async () => {
                await onSaveList()
                clearSelectedProductList()
                closeModal()
              }}
            >
              저장
            </Button>
          </ButtonWrapper>
        </InlineWrapper>
      </ContentWrapper>
    </Modal>
  )
}

const ContentWrapper = styled.div`
  width: 324px;
  padding: 24px 7px 9px 24px;
`

const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: baseline;
`

const Title = styled.div`
  color: #212121;
  font-size: 24px;
  font-weight: 800;
`

const SubTitle = styled.div`
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Input = styled.input`
  width: 204px;
  height: 32px;

  margin: 0 10px 0 6px;
  padding: 7px 10px;
  border-radius: 2px;
  border: 1px solid #d4d4d4;
  outline: none;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  width: 90px;
`

const Radio = styled.input`
  margin: 0;
  padding: 0;
`

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SelectorListItem = styled.div`
  width: 100%;
  height: 36px;
  display: inline-flex;
  align-items: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`
