import styled from "@emotion/styled"
import Button from "components/Button"
import { FaqCategoryBar } from "./FaqCategoryBar"
import { FaqEditor } from "./FaqEditor/FaqEditor"
import { FaqList } from "./FaqList/FaqList"
import { useFaqData } from "./useFaqData"

export const FaqListPage = () => {
  const {
    categoryList,
    selectedCategory,
    selectCategory,
    faqList,
    selectedFaq,
    selectFaq,
    refreshList,
    onChangeOrder,
  } = useFaqData()

  return (
    <PageWrapper>
      <InlineWrapper>
        <FaqCategoryBar
          categoryList={categoryList}
          selectCategory={selectCategory}
          selectedCategory={selectedCategory}
        />
        <CreateButtonWrapper>
          <Button disabled={!selectedFaq} onClick={() => selectFaq(undefined)}>
            신규 생성
          </Button>
        </CreateButtonWrapper>
      </InlineWrapper>
      <ContentWrapper>
        <FaqList
          category={selectedCategory}
          faqList={faqList}
          selectedFaq={selectedFaq}
          selectFaq={selectFaq}
          onChangeOrder={onChangeOrder}
        />
        <div style={{ width: "24px" }} />
        <FaqEditor selectedFaq={selectedFaq} selectedCategory={selectedCategory} refreshList={refreshList} />
      </ContentWrapper>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  display: inline-flex;
  margin-top: 24px;
  align-items: flex-start;
`

const InlineWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  max-width: 1196px;
  justify-content: space-between;
`

const CreateButtonWrapper = styled.div`
  width: 120px;
`
