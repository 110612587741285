import styled from "@emotion/styled"
import { createNoticeDetail } from "apis/noticeAPI"
import Button from "components/Button"
import PopupPage from "components/Popup/PopupPage"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import { forwardRef, useImperativeHandle, useState } from "react"
import { toast } from "react-toastify"
import { NoticeDetailRequestBodyType } from "types/NoticeDetailRequestBodyType"
import { NoticeDetailForm } from "./NoticeDetailForm"

const EMPTY_NOTICE_DETAIL_REQUEST_BODY: NoticeDetailRequestBodyType = {
  title: "",
  onDisplay: false,
  notifyDate: DateTime.local().toISODate(),
  startedAt: null,
  endedAt: null,
  body: "",
  htmlBody: "",
}

export type NoticeDetailCreatePopupRefAttributes = {
  open: () => void
  close: () => void
}

export const NoticeDetailCreatePopup = forwardRef<NoticeDetailCreatePopupRefAttributes, { onClose: () => void }>(
  ({ onClose }, ref) => {
    const [openCreatePopup, setOpenCreatePopup] = useState(false)
    const [noticeDetailRequestBody, setNoticeDetailRequestBody] = useState<NoticeDetailRequestBodyType>(
      EMPTY_NOTICE_DETAIL_REQUEST_BODY
    )

    const openPopup = () => {
      setOpenCreatePopup(true)
    }
    const closePopup = () => {
      setOpenCreatePopup(false)
      setNoticeDetailRequestBody(EMPTY_NOTICE_DETAIL_REQUEST_BODY)
      onClose()
    }

    const submit = async () => {
      if (isEmpty(noticeDetailRequestBody.title)) {
        toast.error("제목을 입력해주세요")
        return
      }
      if (isEmpty(noticeDetailRequestBody.body)) {
        toast.error("내용을 입력해주세요")
        return
      }

      try {
        await createNoticeDetail(noticeDetailRequestBody)
        toast.success("작성 완료")
        closePopup()
      } catch {
        toast.error("작성 실패")
      }
    }

    useImperativeHandle(ref, () => ({ open: openPopup, close: closePopup }))

    return (
      <PopupPage isOpen={openCreatePopup}>
        <PopupPageChildrenWrapper>
          <PopupPageTitle>공지사항 작성</PopupPageTitle>
          <PopupPageScrollContainer>
            <NoticeDetailForm state={noticeDetailRequestBody} setState={setNoticeDetailRequestBody} />
          </PopupPageScrollContainer>
          <PopupPageFooter>
            <ButtonListWrapper>
              <ButtonListItemWrapper>
                <Button buttonStyle="blue-fill" onClick={submit}>
                  저장
                </Button>
              </ButtonListItemWrapper>
              <ButtonListItemWrapper>
                <Button buttonStyle="black-border" onClick={closePopup}>
                  닫기
                </Button>
              </ButtonListItemWrapper>
            </ButtonListWrapper>
          </PopupPageFooter>
        </PopupPageChildrenWrapper>
      </PopupPage>
    )
  }
)

const PopupPageChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const ButtonListWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
const ButtonListItemWrapper = styled.div`
  :not(:last-child) {
    margin-right: 10px;
  }
`
