export enum PointStatusCode {
  활성 = "활성",
  취소 = "취소",
  만료 = "만료",
}

export enum PointActionTypeCode {
  적립 = "적립",
  차감 = "차감",
}

export interface PointType {
  id: number
  actionType: PointActionTypeCode
  status: PointStatusCode
  reasonType: PointReasonType
  reasonMessage: string
  points: number
  availablePoints: number
  availableDate: string
  createdAt: string
}

export type PostPointBodyType = {
  reasonType: PointReasonType
  reasonMessage: string
  points: number
  availableDate: string
}

export type PointListParams = {
  size: number
  page: number
}

export type PointReasonType =
  | "회원 가입 적립"
  | "구매 완료 적립"
  | "구매 리뷰 작성 적립"
  | "판매 리뷰 작성 적립"
  | "컬렉션 등록 적립"
  | "구매 차감"
  | "이벤트 적립"
  | "수동 적립"
  | "수동 차감"
  | "친구초대"
