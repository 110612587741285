import { httpDelete, httpGet, httpPost, httpPostMultipartFile, httpPut } from "../utils/httpAPI"
import { DetailModelBatchUploadResultType, DetailModelType } from "types/DetailModelType"
import { DetailModelListItemType } from "types/DetailModelListItemType"
import { PageType } from "types/PageType"
import { UploadResultType } from "types/UploadResultType"
import { DetailModelRequestBodyType } from "types/DetailModelRequestBodyType"
import { SellOrderBatchUploadResultType } from "../types/SellOrderType"

export const getDetailModel: (id: number) => Promise<DetailModelType> = (id) => httpGet(`/detail-models/${id}`)
export const getDetailModels: (params?: { [key: string]: any }) => Promise<PageType<DetailModelType>> = (params) =>
  httpGet("/detail-models", params)
export const updateDetailModel: (
  id: number,
  detailModelRequestBody: DetailModelRequestBodyType
) => Promise<DetailModelType> = (id, detailModelRequestBody) => httpPut(`/detail-models/${id}`, detailModelRequestBody)
export const deleteDetailModel: (id: number) => Promise<void> = (id) => httpDelete(`/detail-models/${id}`)
export const uploadThumbnail: (fileList: FileList) => Promise<UploadResultType> = (file) =>
  httpPostMultipartFile("/detail-models/thumbnail", file)
export const uploadResource: (fileList: FileList) => Promise<UploadResultType> = (file) =>
  httpPostMultipartFile("/detail-models/resources", file)
export const updateDetailModelStatus: (detailModelId: number) => Promise<DetailModelType> = (detailModelId) =>
  httpPost(`/detail-models/${detailModelId}/registration-status/next`)

export const createDetailModel = (detailModelRequestBody: DetailModelRequestBodyType): Promise<DetailModelType> =>
  httpPost("/detail-models", detailModelRequestBody)
export const getDetailModelList: (params?: {
  modelId: number
  page: number
  size: number
  sort: string
  registrationStatus: string
  title?: string
  id?: string
  fullRefNo?: string
}) => Promise<PageType<DetailModelListItemType>> = (params) =>
  httpGet("/detail-models", params, { emptyStringAcceptable: true })

export const showDetailModel = (detailModelId: number) => {
  return httpPost<DetailModelType>(`/detail-models/${detailModelId}/show`)
}
export const hideDetailModel = (detailModelId: number) => {
  return httpPost<DetailModelType>(`/detail-models/${detailModelId}/hide`)
}

export const uploadDetailModelBatchByExcel: (fileList: FileList) => Promise<DetailModelBatchUploadResultType> = (
  fileList
) => httpPostMultipartFile("/detail-models/bulk-insert", fileList)
