import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, TextField, Typography} from "@mui/material";
import RowBlock from "../../../components/RowBlock";
import {DateTimePicker} from "@mui/lab";
import ImageUploadButton from "../../../components/ImageUploadButton";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import {upload} from '../../../apis/resourceAPI'
import ShopBrandLogoAPI from "../../../apis/shopBrandLogoAPI";
import { isNil } from "../../../utils/validationUtils"
import { toast } from "react-toastify"
import { ShopBrandLogoType } from "../../../types/ShopBrandLogoType"
import CheckBox from "../../../components/Checkbox"
import styled from "@emotion/styled"

const initState: ShopBrandLogoType = {
    title: null,
    bannerImageUrl: null,
    bannerLinkUrl: null,
    isHighlight: false,
    onDisplay: true,
    priority: null,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO(),
    createdAt: null
}

// @ts-ignore
const BrandLogoUpdatePopup = ({BrandLogo, handleClose}) => {
    const [state, setState] = useState(initState)

    useEffect(() => {
        if (BrandLogo != null) {
            setState(BrandLogo)
        } else {
            setState(initState)
        }
    }, [BrandLogo])

    // @ts-ignore
    const handleBrandLogoImage = (image) => {
        upload(image).then((result) => {
            setState({
                ...state,
                bannerImageUrl: result.url,
            })
        })
    }

    const handleSubmit = () => {
        if (isNil(state.id)) {
            toast.error("수정할 정보가 없습니다. 다시 조회 후 수정 하세요.")
            return
        }
        if (isNil(state.title)) {
            toast.error("제목을 입력하세요.")
            return
        }
        if (isNil(state.bannerImageUrl)) {
            toast.error("이미지를 선택하세요.")
            return
        }
        if (isNil(state.bannerLinkUrl)) {
            toast.error("링크 URL를 입력하세요.")
            return
        }
        if (isNil(state.startedAt)) {
            toast.error("노출 시작일자를 입력하세요.")
            return
        }
        if (isNil(state.endedAt)) {
            toast.error("노출 종료일자를 입력하세요.")
            return
        }
        if (state.startedAt > state.endedAt) {
            toast.error("노출 기간을 확인하세요.")
            return
        }

        ShopBrandLogoAPI.update(state.id, state).then(() => {handleClose()})
    }

    return (
        <Dialog open={BrandLogo != null} maxWidth={"xl"}>
            <DialogTitle>브랜드 로고 수정</DialogTitle>
            <DialogContent>
                <Stack direction={'row'}>
                    <Stack spacing={3}>
                        <RowBlock title={"제목"}>
                            <TextField
                              size={"small"}
                              fullWidth
                              value={state?.title}
                              onChange={(e) => {
                                  if (e.target.value.length <= 8) {
                                      setState({ ...state, title: e.target.value })
                                  }
                              }}
                              placeholder={"화면에 노출되는 서비스 제목 (띄어쓰기 포함 8글자까지)"}
                            />
                        </RowBlock>
                        <RowBlock title={"이미지"}>
                            <Stack direction={'column'} alignItems={'flex-start'} spacing={2} alignContent={'space-between'} >
                                <ImageUploadButton  height={'330px'} width={'330px'}  handleImage={handleBrandLogoImage} imageUrl={state.bannerImageUrl}  handleDeleteImage={() => {setState({...state, bannerImageUrl: null})}}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"강조여부"}>
                            <Stack direction={'row'} alignItems={'center'}   >
                                <CheckBox checked={state?.isHighlight!!}   id="isHighlight"  onChange={() => setState((pre) => ({...state, isHighlight: !state.isHighlight})) } />
                                <CheckBoxLabel htmlFor="isMagazine" >
                                    강조 체크
                                </CheckBoxLabel>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"링크 URL"}><TextField size={'small'} fullWidth value={state.bannerLinkUrl} onChange={(e) => setState({...state, bannerLinkUrl: e.target.value})} placeholder={'https://'}/></RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={'row'} justifyContent={'flex-start'}>
                                <Switch checked={state.onDisplay ?? false} onChange={(e) => setState({...state, onDisplay: e.target.checked})}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'center'}>
                                <DateTimePicker onChange={(e) => {
                                    setState({
                                        ...state,
                                        startedAt: e?.toISO() ?? DateTime.now().toISO()
                                    })
                                }} value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 시작'} inputFormat={"yyyy-MM-dd HH:mm"} />
                                <Typography>~</Typography>
                                <DateTimePicker onChange={(e) => {
                                    setState({
                                        ...state,
                                        endedAt: e?.toISO() ?? DateTime.now().toISO()
                                    })
                                }} value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 종료'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                            </Stack>
                        </RowBlock>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`
export default BrandLogoUpdatePopup