import CardTitle from "../../components/CardTitle";
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import React from "react";
import {DateTime} from "luxon"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {CategoryType} from "types/CategoryType";
import {deleteCategory, hideCategory, showCategory} from "../../apis/categoryAPI";
import {Switch} from "@mui/material";
import {hideShowroom} from "../../apis/showroomAPI";

function createColumns(handleDeleteButtonClick: any, handleEditButtonClick: any, handleDisplaySwitchToggle: Function): any[] {
    return [
        {
            field: 'actions',
            type: 'actions',
            width: 30,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon = {<EditIcon />}
                    label = {"수정"}
                    onClick={() => handleEditButtonClick?.(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon = {<DeleteIcon />}
                    label = {"삭제"}
                    onClick={() => handleDeleteButtonClick?.(params.row)}
                    showInMenu
                />
            ]
        },
        {
            field: 'onDisplay',
            headerName: "노출상태",
            editable: false,
            width: 70,
            renderCell: (params: any) => {
                return <Switch
                    checked={params.row.onDisplay}
                    onChange={(_event, checked) => handleDisplaySwitchToggle(params.row, checked)}
                />
            }
        },
        {field: 'sid', headerName: "ID", editable: false, minWidth: 100, flex: 0.3},
        {field: 'name', headerName: "카테고리명", editable: false, minWidth: 100, flex: 1},
        {
            field: 'createdBy',
            headerName: "생성자",
            editable: false,
            valueGetter: (params: any) => `${params.row.createdBy.name}`,
            width: 100,
        },
        {
            field: 'createdAt',
            headerName: "생성일",
            editable: false,
            width: 100,
            valueFormatter: (params: any) => `${DateTime.fromISO(params.value).toISODate()}`
        },
        {
            field: 'updatedBy',
            headerName: "수정자",
            editable: false,
            valueGetter: (params: any) => `${params.row.updatedBy.name}`,
            width: 100
        },
        {
            field: 'updatedAt',
            headerName: "수정일",
            editable: false,
            width: 100,
            valueFormatter: (params: any) => `${DateTime.fromISO(params.value).toISODate()}`
        }
    ]
}

type CategoryProps = {
    rows: CategoryType[],
    dispatch: any
}

const CategoryTable: React.FC<CategoryProps> = ({rows, dispatch}) => {
    const handleDeleteButtonClick = (row: CategoryType) => {
        deleteCategory(row.sid as string).then(() => dispatch({type: 'REFRESH'}))
    }
    const handleEditButtonClick = (brand: CategoryType) => {
        dispatch({type: 'SHOW_EDIT_CATEGORY_POPUP', payload: brand})
    }

    const handleDisplaySwitchToggle = (category: CategoryType, checked: boolean) => {
        (checked ? showCategory(category.sid as string) : hideCategory(category.sid as string)).then(() => {
            dispatch({type: 'REFRESH'})
        })
    }

    return (
        <>
            <CardTitle>카레고리 목록</CardTitle>
            <div style={{height: 400, width: '100%'}}>
                <DataGrid
                    getRowId={row => row.sid!}
                    columns={createColumns(handleDeleteButtonClick, handleEditButtonClick, handleDisplaySwitchToggle)}
                    rows={rows}
                    pageSize={10}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    onSelectionModelChange={(rows) => dispatch({type: 'SELECT_CATEGORY_IDS', payload: rows as string[]})}
                />
            </div>
        </>)
}

// @ts-ignore
export default CategoryTable