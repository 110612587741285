import React from 'react';
import { Typography } from "@mui/material"
import { ProvisionalPreSettlementDetailType } from "../../../types/PreSettlementType"

export const TitleView:React.FC<{
  provisionalSettlementPerson:string;
  alertPerson:string;
  status:ProvisionalPreSettlementDetailType['status']
}> = (props)=>{

  return (
    <table className={"basic"}>
      <tbody>
      <tr>
        <td colSpan={4} className={"no-border ta-left"}>
          <Typography fontWeight={"bold"} fontSize={"30px"}>
            선정산 가견적 관리
          </Typography>
        </td>
        <td className={"header"}>견적서 담당자</td>
        <td className={"header"}>안내 담당자</td>
        <td className={"header"}>상태</td>
      </tr>
      <tr>
        <td colSpan={4} className={"no-border"}/>
        <td style={{height:'80px'}}>
          {props.provisionalSettlementPerson}
        </td>
        <td>{props.alertPerson}</td>
        <td className={"bold"}>{props.status}</td>
      </tr>
      </tbody>
    </table>
  )
}
