import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material"
import React, {useEffect, useState} from "react"
import "../../App.css"
import {getProductPriceHistories} from "../../apis/productAPI";
import {PageType} from "types/PageType";
import {ProductPriceHistoryType} from "types/ProductPriceHistoryType";
import {toRange, truncateWithCommas} from "../../utils/NumberUtils";
import {toast} from "react-toastify";
import {toDateTimeStr} from "../../utils/datetimeUtil";

type ProductPriceHistoryPopupProps = {
  open: boolean
  productId: number | undefined
  handleClose: Function
}

const ProductPriceHistoryPopup: React.FC<ProductPriceHistoryPopupProps> = ({ open, productId, handleClose }) => {

  const [priceHistories, setPriceHistories] = useState<PageType<ProductPriceHistoryType>>({content: []})

  useEffect(() => {
    if (open) {
      getProductPriceHistories({productId: productId})
          .then((res) => setPriceHistories(res))
          .catch((err) => {
              toast.error(`가격변경이력 조회 실패 (${err.message})`)
          })
    } else {
    }
  }, [open])

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth>
      <DialogTitle>
        상점노출가 변경이력
      </DialogTitle>
      <DialogContent>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>변경 일시</td>
              <td className={"header"}>변경일 시세</td>
              <td className={"header"}>최적 판매가</td>
              <td className={"header"}>변경 전 판매가</td>
              <td className={"header"}>변경 후 판매가</td>
            </tr>
            {priceHistories.content?.length ? priceHistories.content.map((it) =>
              <tr>
                <td>{toDateTimeStr(it.createdAt)}</td>
                <td>{toRange(it.estimatedResellPrice?.marketPrice?.min, it.estimatedResellPrice?.marketPrice?.max,4)} 만원</td>
                <td>{truncateWithCommas(it.estimatedResellPrice?.correctedEstimatedPrice?.median, 4) || '-'} 만원</td>
                <td>{truncateWithCommas(it.beforePrice, 4)} 만원</td>
                <td>{truncateWithCommas(it.afterPrice, 4)} 만원 ( {(it.afterPrice || 0) - (it.beforePrice || 0) > 0 ? '+' : ''}{truncateWithCommas((it.afterPrice || 0) - (it.beforePrice || 0), 4)} 만원 )</td>
              </tr>
            ) : <tr><td colSpan={5}>변경이력을 찾을 수 없습니다.</td></tr>}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductPriceHistoryPopup
