


import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import { FunctionComponent } from "react"

const DeleteConfirmPopup: FunctionComponent<{
    open: boolean
    handleConfirm: () => void
    handleClose: () => void
}> = ({ open, handleConfirm, handleClose }) => {
    return (
        <Dialog open={open}>
            <DialogTitle>삭제 확인</DialogTitle>
            <DialogContent>
                삭제 하시겠습니까?
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'error'} onClick={handleConfirm}>삭제</Button>
                <Button variant={'contained'} color={'info'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteConfirmPopup