import { marked } from "marked"

/**
 * 마크다운을 바이버 공지사항 HTML 형식으로 변환합니다.
 * @param markdownText 마크다운 형식의 string
 * @returns
 */
export const parseMarkdownToHtml = (markdownText: string) => {
  const htmlHeadContents = [
    `<meta charset="UTF-8">`,
    `<meta http-equiv="X-UA-Compatible" content="IE=edge">`,
    `<meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no" />`,
    `<link rel="stylesheet" media="screen" href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css" data-turbolinks-track="reload">`,
    `<style>html{-webkit-touch-callout:none;-webkit-user-select:none;-webkit-tap-highlight-color:transparent}body{position:relative;margin:0;overflow-x:hidden}</style>`,
    `<style>.vx-content{font-family:Pretendard;font-style:normal;font-feature-settings:'case' on;word-break:break-all}h1,h2,p{margin-inline-start:24px;margin-inline-end:24px;margin-block-start:16px;margin-block-end:16px;}h1{color:#1C2229;font-size: 1.125em}p.text{color:#3B464F;font-size: 0.938em}</style>`,
    `<style>table { border-collapse: collapse; } tr, td, th { border: solid 1px black; }</style>`,
  ]
  const htmlBodyContent = marked.parse(markdownText)
  return (
    `<!DOCTYPE html>` +
    `<html lang="ko">` +
    `<head title="\${content.title}">${htmlHeadContents.join("")}</head>` +
    `<body className="vx-content">${htmlBodyContent}</body>` +
    `</html>`
  )
}
