import React, { useCallback, useEffect, useReducer } from "react"
import { DiagnosisIssueHistorySearchParam, DiagnosisIssueHistoryType } from "../../../types/DiagnosisType"
import { PageType } from "../../../types/PageType"
import { SellOrderType } from "../../../types/SellOrderType"
import { getDiagnosisIssueHistory } from "../../../apis/diagnosisAPI"
import ViewBuyOrderPopup from "../../BuyOrder/ViewBuyOrderPopup"
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import IssueHistoryTable from "./IssueHistoryTable"
import { DateTime } from "luxon"
import { DesktopDatePicker } from "@mui/lab"
import { DiagnosisPrintPopup } from "../DiagnosisPrintPopup"
import { useSearchParams } from "react-router-dom"
import { SaleTypeCode } from "../../../types/ProductType"
import EditRePurchaseGuaranteedDiagnosisPopup from "../RePurchaseGuaranteed/EditRePurchaseGuaranteedDiagnosisPopup"
import EditBeforeDiagnosisDiagnosisPopup from "../BeforeDiagnosis/EditBeforeDiagnosisDiagnosisPopup"
import EditConsignmentDiagnosisPopup from "../Consignment/EditConsignmentDiagnosisPopup"
import BeforeDiagnosisProductPopup from "../../Product/BeforeDiagnosisProductPopup"
import EditPreProductPopup from "../../PreProduct/EditPreProductPopup"

export interface DiagnosisIssueHistoryState {
  diagnosesHistory: PageType<DiagnosisIssueHistoryType>
  selectedId: number[]
  selectedDiagnosisHistory?: DiagnosisIssueHistoryType
  selectedSellOrder?: SellOrderType
  isShowProductPopup: boolean
  isShowBuyOrderPopup: boolean
  isShowDiagnosisPrintPopup: boolean
  isShowDiagnosisPopup: boolean
  issuedAtStartDate?: DateTime
  issuedAtEndDate?: DateTime
  searchFilterKey?: keyof DiagnosisIssueHistorySearchParam
  searchFilterValue?: string
  refresh: boolean
  loading: boolean
  pageSize: number
  pageNumber: number
}

const initState: DiagnosisIssueHistoryState = {
  diagnosesHistory: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  selectedId: [],
  selectedDiagnosisHistory: undefined,
  selectedSellOrder: undefined,
  isShowProductPopup: false,
  isShowBuyOrderPopup: false,
  isShowDiagnosisPrintPopup: false,
  isShowDiagnosisPopup: false,
  issuedAtStartDate: undefined,
  issuedAtEndDate: undefined,
  searchFilterKey: "productId",
  searchFilterValue: undefined,
  refresh: false,
  loading: false,
  pageSize: 25,
  pageNumber: 0,
}

export interface DiagnosisIssueHistoryAction {
  type: string
  payload?: any
}

function reducer(state: DiagnosisIssueHistoryState, action: DiagnosisIssueHistoryAction): DiagnosisIssueHistoryState {
  switch (action.type) {
    case "FETCH_ISSUE_HISTORY":
      return { ...state, diagnosesHistory: action.payload }
    case "FETCH_SELL_ORDER":
      return { ...state, selectedSellOrder: action.payload }
    case "TOGGLE_PRODUCT_POPUP":
      return { ...state, isShowProductPopup: !state.isShowProductPopup, selectedDiagnosisHistory: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, issuedAtStartDate: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, issuedAtEndDate: action.payload }
    case "TOGGLE_BUY_ORDER_POPUP":
      return { ...state, isShowBuyOrderPopup: !state.isShowBuyOrderPopup, selectedDiagnosisHistory: action.payload }
    case "TOGGLE_DIAGNOSIS_POPUP":
      return { ...state, isShowDiagnosisPopup: !state.isShowDiagnosisPopup, selectedDiagnosisHistory: action.payload }
    case "TOGGLE_DIAGNOSIS_PRINT_POPUP":
      return {
        ...state,
        isShowDiagnosisPrintPopup: !state.isShowDiagnosisPrintPopup,
        selectedDiagnosisHistory: action.payload,
      }
    case "CHANGE_PAGE_NUMBER":
      return {
        ...state,
        pageNumber: action.payload,
        refresh: !state.refresh,
      }
    case "CHANGE_PAGE_SIZE":
      return { ...state, pageNumber: 0, pageSize: action.payload, refresh: !state.refresh }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }
  return state
}

const DiagnosesIssueHistory: React.FC = () => {
  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(reducer, initState)

  const renderEditPopupByOrderType = useCallback(() => {
    if (!state.selectedDiagnosisHistory) {
      return
    }

    if (state.selectedDiagnosisHistory.saleType === SaleTypeCode.매입보증판매) {
      return (
        <EditRePurchaseGuaranteedDiagnosisPopup
          open={state.isShowDiagnosisPopup}
          selectedSellOrder={state.selectedSellOrder!!}
          handleClose={() => {
            dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )
    }

    if (state.selectedDiagnosisHistory.saleType === SaleTypeCode.진단전판매) {
      return (
        <EditBeforeDiagnosisDiagnosisPopup
          open={state.isShowDiagnosisPopup}
          selectedSellOrder={state.selectedSellOrder}
          handleClose={() => {
            dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )
    }

    return (
      <EditConsignmentDiagnosisPopup
        open={state.isShowDiagnosisPopup}
        selectedSellOrder={state.selectedSellOrder}
        handleClose={() => {
          dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
    )
  }, [state.selectedSellOrder, state.isShowDiagnosisPopup])

  const fetchDiagnosisIssueHistory = (searchParams: DiagnosisIssueHistorySearchParam = {}) => {
    let newParams: DiagnosisIssueHistorySearchParam = {
      ...searchParams,
      diagnosisId: currentSearchParams.get("diagnosisId") ?? undefined,
      issuedAtStartDate: state.issuedAtStartDate?.toISODate(),
      issuedAtEndDate: state.issuedAtEndDate?.toISODate(),
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getDiagnosisIssueHistory(newParams)
      .then((results) => dispatch({ type: "FETCH_ISSUE_HISTORY", payload: results }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  useEffect(() => {
    fetchDiagnosisIssueHistory({
      page: state.pageNumber || 0,
      size: state.pageSize || 25,
    })
  }, [state.refresh])

  return (
    <Grid container spacing={2}>
      {renderEditPopupByOrderType()}
      {state.selectedDiagnosisHistory?.saleType == SaleTypeCode.진단전판매 ? (
        <BeforeDiagnosisProductPopup
          open={state.isShowProductPopup}
          selectedProductId={state.selectedDiagnosisHistory?.productId}
          handleClose={() => {
            dispatch({ type: "TOGGLE_PRODUCT_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      ) : (
        <EditPreProductPopup
          open={state.isShowProductPopup}
          selectedProductId={state.selectedDiagnosisHistory?.productId}
          handleClose={() => {
            dispatch({ type: "TOGGLE_PRODUCT_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )}
      <DiagnosisPrintPopup
        isOpen={state.isShowDiagnosisPrintPopup}
        closePopup={() => {
          dispatch({ type: "TOGGLE_DIAGNOSIS_PRINT_POPUP" })
        }}
        diagnosisId={state.selectedDiagnosisHistory?.id!!}
      />
      <ViewBuyOrderPopup
        open={state.isShowBuyOrderPopup}
        selectedBuyOrderId={state.selectedDiagnosisHistory?.buyOrderId}
        handleClose={() => {
          dispatch({ type: "TOGGLE_BUY_ORDER_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ lineHeight: "45px" }}
                >
                  <InputLabel>발급일시</InputLabel>
                  <DesktopDatePicker
                    label="시작일"
                    inputFormat="yyyy-MM-dd"
                    value={state.issuedAtStartDate ?? null}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_START_DATE", payload: params })
                      dispatch({ type: "REFRESH" })
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                  <DesktopDatePicker
                    label="종료일"
                    inputFormat="yyyy-MM-dd"
                    value={state.issuedAtEndDate ?? null}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_END_DATE", payload: params })
                      dispatch({ type: "REFRESH" })
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select
                      value={state.searchFilterKey || ""}
                      onChange={(params) => {
                        dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                      }}
                    >
                      <MenuItem value={"productId"}>상품번호</MenuItem>
                      <MenuItem value={"diagnosisId"}>진단서번호</MenuItem>
                      <MenuItem value={"diagnosisVerifyNo"}>Verify No.</MenuItem>
                      <MenuItem value={"buyOrderId"}>구매주문번호</MenuItem>
                      <MenuItem value={"buyerId"}>구매자ID</MenuItem>
                      <MenuItem value={"sellerId"}>판매자ID</MenuItem>
                      <MenuItem value={"issuedByNickName"}>담당자</MenuItem>
                      <MenuItem value={"serialNo"}>시리얼 No.</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={state.searchFilterValue || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch({ type: "REFRESH" })
                      }
                    }}
                    onChange={(e) => {
                      dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: e.target.value })
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction={"row"} justifyContent={"end"}>
                <Button
                  variant={"contained"}
                  color={"success"}
                  size={"medium"}
                  style={{ margin: "0 2px" }}
                  onClick={() =>
                    window.open(`${window.location.origin}/diagnoses/manual-authentication-issuance`, "_blank")
                  }
                >
                  진단서 수동 발급
                </Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <IssueHistoryTable state={state} dispatch={dispatch} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DiagnosesIssueHistory
