import { httpDelete, httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { PageType } from "types/PageType"
import { ShopCurationCreateBodyType, ShopCurationType } from "types/ShopCurationType"

export default {
  create: (curation: ShopCurationCreateBodyType) => httpPost<ShopCurationType>("/shop/contents/product-curations", curation),
  update: (curation: ShopCurationType): Promise<ShopCurationType> => httpPut(`/shop/contents/product-curations/${curation.id}`, curation),
  getDetail: (id: number): Promise<ShopCurationType> => httpGet(`/shop/contents/product-curations/${id}`),
  delete: (id: number): Promise<string> => httpDelete(`/shop/contents/product-curations/${id}`),
  getPage: (params?: { [key: string]: any }): Promise<PageType<ShopCurationType>> => httpGet("/shop/contents/product-curations", params),
  getList: (params?: { [key: string]: any }): Promise<ShopCurationType[]> => httpGet("/shop/contents/product-curations/list", params),
  reordering: (list: ShopCurationType[]): Promise<ShopCurationType[]> => httpPut(`/shop/contents/product-curations/reordering`, list),
}
