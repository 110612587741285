import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { DateTime } from "luxon"
import { ExchangeRateFeesListState } from "./index"
import { Button, Stack } from "@mui/material"
import { useSearchParams } from "react-router-dom"

function createColumns(): any[] {

  return [
    {
      field: "id",
      headerName: "No",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 60,
      maxWidth: 60,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          No
        </p>
      ),
      valueGetter: (params: any) => {
        return `${params.row.id}`
      },
    },
    {
      field: "metricsDate",
      headerName: "일자",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          일자
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            { (params.row.metricsDate)}
          </p>
        </div>
      ),
    },
    {
      field: "currency",
      headerName: "통화구분",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.currency}`
      },
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
          >
            {params.row.currency}
          </Button>
        </div>
      ),
    },
    {
      field: "price",
      headerName: "매매기준환율",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.price
        return price ? price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-";
      },
    },
    {
      field: "safeMarginFeeRate",
      headerName: "환율안전마진",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.safeMarginFeeRate
        return price ? `${(price)}` : "-"
      },
    },
    {
      field: "etcFeeRate",
      headerName: "기타수수료",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.etcFeeRate
        return price ? `${(price)}` : "-"
      },
    },
  ]
}

type ExchangeRateFeesProps = {
  state: ExchangeRateFeesListState
  dispatch: any
  fetch: Function
  components: any[]
}

const ExchangeRateFeesTable: React.FC<ExchangeRateFeesProps> = ({ state, dispatch, fetch, components }) => {

  console.log('state', state)
  const [currentSearchParams] = useSearchParams()

  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }


  return (
    <>
      <Stack spacing={1}>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid
           getRowId={(row) =>row.id }
            columns={createColumns()}
            pagination
            {...dispatch.detailModels?.content}
            paginationMode="server"
            onPageChange={(page) => {
              fetch({ page, size: state.result.pageable?.pageSize || 25 })
            }}
            onPageSizeChange={(size) => {
              fetch({ page: 0, size })
            }}
            page={state.result.pageable?.pageNumber || 0}
            pageSize={state.result.pageable?.pageSize || 25}
            rowCount={state.result?.totalElements || 0}
            rows={state.result?.content || []}
            loading={state.loading}
          />
        </div>
      </Stack>
    </>
  )
}

// @ts-ignore
export default ExchangeRateFeesTable
