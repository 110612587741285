import { TextField } from "@mui/material"
import Button from "components/Button"
import styled from "@emotion/styled"

import { DatePicker } from "@mui/lab"

import { DateTime } from "luxon"
import { toDateStr } from "utils/datetimeUtil"

type Props = {
  openListManager: () => void
  date: string | undefined
  isEditable: boolean
  onChangeDate: (date: string | undefined) => void
  onClickDownload: () => void
}

export const ProductStockManagerHeader = ({ openListManager, onChangeDate, date, isEditable, onClickDownload }: Props) => {
  return (
    <InlineWrapper style={{ width: "100%", justifyContent: "flex-end", marginBottom: "24px" }}>
      <InlineWrapper>
        <Button style={{marginRight: '8px'}} buttonSize="M" onClick={onClickDownload}>
          엑셀 다운로드
        </Button>

        <ButtonWrapper>
          <Button buttonSize="M" onClick={openListManager} disabled={!isEditable}>
            리스트 관리
          </Button>
        </ButtonWrapper>
        <InlineWrapper style={{ marginLeft: "16px" }}>
          <div style={{ marginRight: "8px" }}>기준일</div>
            <DatePicker<DateTime>
              value={date}
              inputFormat={"yyyy-MM-dd"}
              onChange={(newValue) => {
                if (!newValue) return

                onChangeDate(toDateStr(newValue.toISO()))
              }}
              InputProps={{
                style: {
                  width: "180px",
                },
              }}
              renderInput={(params) => <TextField size={"small"} {...params} />}
            />
        </InlineWrapper>
      </InlineWrapper>
    </InlineWrapper>
  )
}

const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  width: 88px;
`
