import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const eventTableHeaderItemList = [
  {
    title: "ID",
    width: "100px",
  },
  {
    title: "배너 이미지",
    width: "20%",
  },
  {
    title: "제목",
    width: "60%",
  },
  {
    title: "본문형식",
    width: "120px",
  },
  {
    title: "노출여부",
    width: "100px",
  },
  {
    title: "고객 알림 일시",
    width: "180px",
  },
  {
    title: "진행기간",
    width: "180px",
  },
  {
    title: "URL 복사",
    width: "100px",
  },
]

const EventListTableHeader = () => {
  return (
    <ListTableHeader>
      {eventTableHeaderItemList.map((headerItem) => {
        return (
          <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
            {headerItem.title}
          </ListTableHeaderItem>
        )
      })}
    </ListTableHeader>
  )
}

export default EventListTableHeader
