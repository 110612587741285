import styled from "@emotion/styled"
import { FC, InputHTMLAttributes } from "react"

const CheckBox: FC<InputHTMLAttributes<HTMLInputElement>> = ({ ...props }) => {
  return <Input type={"checkbox"} {...props} />
}

export default CheckBox

const Input = styled.input`
  padding: 0;
  margin: 0;
  width: 18px;
  height: 18px;
`
