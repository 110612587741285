import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import {ShopCurationType, ShopCurationProductType, ShopCurationDetailModelType} from "types/ShopCurationType"
import { Box, Button, Stack } from "@mui/material"
import { useMemo, useState } from "react"
import styled from "@emotion/styled"

type Props = {
  isManual: boolean
  detailModels: ShopCurationDetailModelType[]
  handleOnChangeState: (partialState: Partial<ShopCurationType>) => void
  openShowProductSelectPopup: () => void
}

const CurationDetailModelTable = ({ detailModels, handleOnChangeState, openShowProductSelectPopup, isManual }: Props) => {
  const [selectionCurationDetailModelIds, setSelectionCurationDetailModelIds] = useState<number[]>([])

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        valueGetter: (params: any) => `${params.row.detailModel.id}`,
      },
      {
        field: "detailModel.thumbnail",
        headerName: "썸네일",
        width: 100,
        renderCell: (params: any) => <img src={params.row.detailModel.thumbnail} style={{ height: "100%" }} />,
      },
      {
        field: "detailModel.fullRefNo",
        headerName: "레퍼런스",
        width: 160,
        valueGetter: (params: any) => `${params.row.detailModel.fullRefNo}`,
      },
      {field: "title", headerName: "상세 모델명", valueGetter: (params: any) => `${params.row.detailModel.title}`, flex: 1},
      {field: "titleSecondary", headerName: "상세 모델명", valueGetter: (params: any) => `${params.row.detailModel.titleSecondary}`,flex: 1},
      {field: "onSaleProductCount", headerName: "판매 상품수", valueGetter: (params: any) => `${params.row.detailModel.onSaleProductCount}`,type: 'number', width: 100},

    ]

    if (isManual) {
      return [
        ...defaultColumns,
        {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
        {
          field: "actions",
          type: "actions",
          headerName: "순서(위치이동)",
          width: 120,
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<ArrowUpwardIcon />}
              label={"위로"}
              onClick={() => {
                if (!detailModels.length) return

                const index = detailModels.findIndex(({ detailModel }) => detailModel && detailModel.id === params.row.detailModel.id)
                if (index <= 0) return

                let nextProducts = [
                  ...detailModels.slice(0, index - 1),
                  detailModels[index],
                  detailModels[index - 1],
                  ...detailModels.slice(index + 1),
                ]

                nextProducts = nextProducts.map(data => ({
                  ...data,
                  priority: null
                })) ;

                handleOnChangeState({ detailModels: nextProducts })
              }}
            />,
            <GridActionsCellItem
              icon={<ArrowDownwardIcon />}
              label={"아래로"}
              onClick={() => {
                if (!detailModels.length) return

                const index = detailModels.findIndex(({ detailModel }) => detailModel && detailModel.id === params.row.detailModel.id)
                if (index === detailModels.length-1) return

                let nextProducts = [
                  ...detailModels.slice(0, index),
                  detailModels[index + 1],
                  detailModels[index],
                  ...detailModels.slice(index + 2),
                ]

                nextProducts = nextProducts.map(data => ({
                  ...data,
                  priority: null
                })) ;

                handleOnChangeState({ detailModels: nextProducts })
              }}
            />,
          ],
        },
      ]
    }

    return defaultColumns
  }, [isManual, handleOnChangeState, detailModels])

  return (
    <Box>
      {isManual && (
        <Stack height={"100%"}>
          <ButtonWrapper>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={openShowProductSelectPopup}
              style={{ marginRight: 10 }}
            >
              추가
            </Button>
            <Button
              variant={"contained"}
              color={"error"}
              size={"small"}
              disabled={selectionCurationDetailModelIds.length < 1}
              onClick={() => {
                if (!detailModels.length) return

                const nextProducts = detailModels.filter(
                  ({ detailModel }) => detailModel && !selectionCurationDetailModelIds.includes(detailModel.id as number)
                )

                handleOnChangeState({ detailModels: nextProducts })
              }}
            >
              삭제
            </Button>
          </ButtonWrapper>
        </Stack>
      )}
      <DataGrid
        getRowId={(row: any) => row.detailModel.id}
        style={{
          height: "500px",
        }}
        columns={columns}
        onSelectionModelChange={(ids) => {
          setSelectionCurationDetailModelIds(ids as number[])
        }}
        checkboxSelection={isManual}
        getRowHeight={() => 80}
        rows={detailModels ?? []}
        hideFooter
        onCellEditCommit={(params) => {
          const index = detailModels.findIndex(({ detailModel }) => detailModel && detailModel.id === params.id)
          if (index < 0) return

          const updatedDetailModels = [...detailModels];
          updatedDetailModels[index] = {
            ...updatedDetailModels[index],
            priority: params.value
          };

          handleOnChangeState({ detailModels: updatedDetailModels })
        }}
      />
    </Box>
  )
}

export default CurationDetailModelTable

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
