import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, Radio, RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material"
import RowBlock from "../../../../components/RowBlock"
import { DateTimePicker } from "@mui/lab"
import ImageUploadButton from "../../../../components/ImageUploadButton"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { ShopBannerType } from "types/ShopBannerType"
import { upload, uploadForNoResize } from "../../../../apis/resourceAPI"
import ShopBannerAPI from "../../../../apis/shopBannerAPI"
import {
    BannerTypeCode,
    ThemeModeTypeCode, ThemeModeTypeCodeList,
} from "../../../../types/ShopHeroBannerType"
import { isNil } from "../../../../utils/validationUtils"
import { toast } from "react-toastify"
import CheckBox from "../../../../components/Checkbox"
import styled from "@emotion/styled"

const initState: ShopBannerType = {
    bannerImageUrl: null,
    bannerLinkUrl: null,
    onDisplay: true,
    priority: null,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO(),
    createdAt: null,
    description: null,
    title: null,
    themeModeType: ThemeModeTypeCode.DARK,
    title1: null,
    title2: null,
    showDomesticUser: true,
    showForeignUser: false
}

// @ts-ignore
const BannerCreatePopup = ({open, handleClose}) => {
    const [state, setState] = useState(initState)

    useEffect(() => {
        setState(initState)
    }, [open])

    // @ts-ignore
    const handleBannerImage = (image) => {
        upload(image).then((result) => {
            setState({
                ...state,
                bannerImageUrl: result.url,
            })
        })
    }


    const handleSubmit = () => {

        if (isNil(state.title)) {
            toast.error("제목을 입력하세요.")
            return
        }
        if (  isNil(state.bannerImageUrl)) {
            toast.error("배너 이미지를 선택하세요.")
            return
        }
        if (isNil(state.bannerLinkUrl)) {
            toast.error("링크 URL를 입력하세요.")
            return
        }
        if (isNil(state.startedAt)) {
            toast.error("노출 시작일자를 입력하세요.")
            return
        }
        if (isNil(state.endedAt)) {
            toast.error("노출 종료일자를 입력하세요.")
            return
        }
        if (state.startedAt > state.endedAt) {
            toast.error("노출 기간을 확인하세요.")
            return
        }
        ShopBannerAPI.create(state).then(() => {handleClose()})
    }

    return (
        <Dialog open={open} maxWidth={"xl"}>
            <DialogTitle>배너 추가</DialogTitle>
            <DialogContent>
                <Stack direction={'row'}>
                    <Stack spacing={3}>
                        <RowBlock title={"설명"}><TextField size={'small'}   fullWidth value={state.description} onChange={(e) => setState({...state, description: e.target.value})} placeholder={'배너의 설명을 입력 해주세요. (서비스에 노출되진 않습니다)'}/></RowBlock>
                        <RowBlock title={"제목"}><TextField size={'small'}   fullWidth value={state.title}
                                                          onChange={(e) => {
                                                              // if (e.target.value.length <= 8) {
                                                                  setState({ ...state, title: e.target.value })
                                                              // }
                                                          }}
                                                          placeholder={'배너 제목'}/>
                        </RowBlock>

                        <RowBlock title={"배너 이미지"}>
                            <Stack direction={'column'} alignItems={'flex-start'} spacing={2} alignContent={'space-between'} >
                                <ImageUploadButton  height={'330px'} width={'330px'}  handleImage={handleBannerImage} imageUrl={state.bannerImageUrl}  handleDeleteImage={() => {setState({...state, bannerImageUrl: null})}}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"타입"}>
                            <Stack direction={'row'}  justifyContent={'flex-start'}>
                                <RadioGroup row sx={{ justifyContent: "center" }}
                                            onChange={(event, value) => {
                                                // @ts-ignore
                                                setState({...state, themeModeType: value})
                                            }}
                                >
                                    {ThemeModeTypeCodeList.map((item) => (
                                      <FormControlLabel
                                        key={item.value}
                                        value={item.value}
                                        label={item.title}
                                        checked={state.themeModeType === item.value}
                                        control={<Radio />}
                                      />
                                    ))}
                                </RadioGroup>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"텍스트"}   >
                            <Stack direction={"column"} spacing={1}>
                                <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={state.title1} onChange={(e) => setState({...state, title1: e.target.value})} placeholder={'첫째줄 소문구'}/>
                                <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={state.title2} onChange={(e) => setState({...state, title2: e.target.value})} placeholder={'둘째줄 대문구'}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"링크 URL"}><TextField size={'small'} fullWidth onChange={(e) => setState({...state, bannerLinkUrl: e.target.value})} placeholder={'https://'}/></RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={'row'} justifyContent={'flex-start'}>
                                <Switch checked={state.onDisplay ?? false} onChange={(e) => setState({...state, onDisplay: e.target.checked})}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'center'}>
                                    <DateTimePicker onChange={(e) => {
                                        setState({
                                            ...state,
                                            startedAt: e?.toISO() ?? DateTime.now().toISO()
                                        })
                                    }} value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 시작'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                                    <Typography>~</Typography>
                                    <DateTimePicker onChange={(e) => {
                                        setState({
                                            ...state,
                                            endedAt: e?.toISO() ?? DateTime.now().toISO()
                                        })
                                    }} value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 종료'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                                </Stack>
                        </RowBlock>
                        <RowBlock title={"노출"}   >
                            <Stack direction={'row'} alignItems={'center'}   >
                                <CheckBox checked={state?.showDomesticUser}   id="showDomesticUser"  onChange={() => setState((pre) => ({...state, showDomesticUser: !state.showDomesticUser})) } />
                                <CheckBoxLabel htmlFor="showDomesticUser" >
                                    내국인 회원 (국내거주 외국인 포함)
                                </CheckBoxLabel>
                                <CheckBox checked={state?.showForeignUser}   id="showForeignUser"  onChange={() => setState((pre) => ({...state, showForeignUser: !state.showForeignUser})) } />
                                <CheckBoxLabel htmlFor="showForeignUser" >
                                    외국인 회원
                                </CheckBoxLabel>
                            </Stack>
                        </RowBlock>
                    </Stack>

                    {/*<Box position={'relative'} width={'360px'} height={'106px'}>*/}
                    {/*    <Box style={{*/}
                    {/*        position: 'absolute',*/}
                    {/*        width: '360px',*/}
                    {/*        height: '106px',*/}
                    {/*        left: '0px',*/}
                    {/*        top: '0px',*/}
                    {/*        border: 'solid',*/}
                    {/*        background: '#33333333'*/}
                    {/*    }}>*/}
                    {/*        { state.bannerImageUrl ? <img src={state.bannerImageUrl} style={{position: 'absolute', width: '100%', height: '100%'}} /> : <></>}*/}

                    {/*    </Box>*/}
                    {/*</Box>*/}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>추가</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`

export default BannerCreatePopup