import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import React, { useState } from "react"

import { Button } from "@mui/material"
import { toast } from "react-toastify"
import { downloadExcel, downloadIlyangExcel } from "utils/shippingToExcelUtil"
import { cancelSellOrderShipping, cancelSellOrderShippingDirectPickup } from "apis/sellOrderAPI"
import { RequestCancelShippingType } from "pages/Penalty/PenaltyListPage"
import { getShippings } from "../../../../apis/shippingAPI"

function createColumns(): any[] {
  return [
    {
      field: "orderId",
      editable: false,
      sortable: false,
      minWidth: 160,
      headerName: "주문번호",
      renderCell: (params: any) => (
        <div style={{ textAlign: "left" }}>
          <p>{params.row.orderId}</p>
        </div>
      ),
    },
    {
      field: "shippingType",
      headerName: "배송형태",
      editable: false,
      sortable: false,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      field: "name",
      headerName: "판매자명",
      editable: false,
      sortable: false,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      field: "zipCode",
      headerName: "우편번호",
      editable: false,
      sortable: false,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      field: "address",
      headerName: "주소",
      editable: false,
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      field: "addressDetail",
      headerName: "상세주소",
      editable: false,
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      field: "phone",
      headerName: "휴대폰번호",
      editable: false,
      sortable: false,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      field: "productTitle",
      headerName: "상품명",
      editable: false,
      sortable: false,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "weight",
      headerName: "무게",
      editable: true,
      sortable: false,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      field: "quantity",
      headerName: "수량",
      editable: true,
      sortable: false,
      minWidth: 60,
      maxWidth: 60,
    },
  ]
}

type SellOrderProps = {
  shippings: RequestCancelShippingType[]
  close: () => void
}

const ShippingRequestTable: React.FC<SellOrderProps> = ({ shippings, close }) => {
  const [selectedOrderIdList, setSelectedOrderIdList] = useState<string[]>(shippings.map((it) => it.orderId))

  const handleDownloadValexExcel = () => {
    const shippingIds = shippings.filter((it) => selectedOrderIdList.includes(it.orderId)).map((it) => it.id)
    getShippings({ id: shippingIds, unmasking: true }).then((result) => downloadExcel(result.content))
  }

  const handleDownloadIlyangExcel = () => {
    const shippingIds = shippings.filter((it) => selectedOrderIdList.includes(it.orderId)).map((it) => it.id)
    getShippings({ id: shippingIds, unmasking: true }).then((result) => downloadIlyangExcel(result.content))
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
        <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleDownloadIlyangExcel}>
          일양 다운로드
        </Button>
        <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleDownloadValexExcel}>
          발렉스 다운로드
        </Button>
        <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handleDirectPickUpClick}>
          본인 직접 수령
        </Button>
        <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handRequestClick}>
          배송 신청 완료
        </Button>
      </GridToolbarContainer>
    )
  }

  const handleSelection = (orderIdList: string[]) => {
    setSelectedOrderIdList(orderIdList)
  }

  const handleDirectPickUpClick = () => {
    if (!selectedOrderIdList.length) {
      toast.error("배송을 요청할 주문을 선택해주세요")
      return
    }

    if (window.confirm(`${selectedOrderIdList.length}건의 본인 직접 수령 요청합니다.`)) {
      Promise.all(
        selectedOrderIdList.map((orderId) => {
          return cancelSellOrderShippingDirectPickup(orderId).catch((error) => {
            toast.error(`${orderId} : ${error.message}`)
          })
        })
      ).then(() => {
        toast.success("본인 직접 수령 요청이 완료돠었습니다.")
        close()
      })
    }
  }

  const handRequestClick = () => {
    if (!selectedOrderIdList.length) {
      toast.error("배송을 요청할 주문을 선택해주세요")
      return
    }

    if (window.confirm(`${selectedOrderIdList.length}건의 반송을 요청합니다.`)) {
      Promise.all(
        selectedOrderIdList.map((orderId) => {
          return cancelSellOrderShipping(orderId).catch((error) => {
            toast.error(`${orderId} : ${error.message}`)
          })
        })
      ).then(() => {
        toast.success("반송 요청이 완료돠었습니다.")
        close()
      })
    }
  }

  return (
    <>
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid<RequestCancelShippingType>
          columns={createColumns()}
          components={{ Toolbar: CustomToolbar }}
          rows={shippings}
          getRowId={(row) => row.orderId}
          checkboxSelection
          selectionModel={selectedOrderIdList}
          disableSelectionOnClick={true}
          onSelectionModelChange={(rowIdList) => handleSelection(rowIdList as string[])}
        />
      </div>
    </>
  )
}

export default ShippingRequestTable
