import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PageType} from "types/PageType";
import {DateTime} from "luxon";
import {NoticeType} from "../../types/NoticeType";
import {getNotices} from "../../apis/noticeAPI";

type SearchNoticesPopupProps = {
    open: boolean,
    dialogTitle?: string,
    handleClose: Function
}

const SearchNoticesPopup: React.FC<SearchNoticesPopupProps> = ({open, dialogTitle, handleClose}) => {
    const [articles, setNotices] = useState<PageType<NoticeType>>({content:[]})
    const [results, setResults] = useState<NoticeType[]>([])
    const [page, setPage] = useState<number>(0)
    const [size, setSize] = useState<number>(500)

    const fetchNotices = (params?: any|null) => getNotices(params).then((page) => {
        setNotices(page)
        setResults(page.content)
    })

    useEffect(() => {
        if (open) {
            setPage(0)
            setSize(500)
            fetchNotices({page, size})
        }
    }, [open])

    return (
        <Dialog open={open} PaperProps={{sx: {width: '100%', height: '100%'}}}>
            <DialogTitle>{dialogTitle ? dialogTitle : '이벤트 선택'}</DialogTitle>
            <DialogContent style={{height: '100%'}}>
                <Grid container marginTop={1} spacing={1} direction={"row"} justifyContent={"end"}>
                    <Grid item xs={12}>
                        <Typography width={"100%"} textAlign={'right'} variant={'caption'} align={'right'}>
                        검색 결과 {results.length || 0} 개
                        </Typography>
                    </Grid>
                    <TextField size={"small"}
                        placeholder={"제목으로 검색"}
                        onChange={
                            Notice => setResults(articles.content?.filter(it => it.title?.includes(Notice.target.value)))
                    }/>
                    <Grid item xs={12}>
                        <List>
                            {results?.map((result) => {
                                return <ListItem key={result.id} style={{}} onClick={() => handleClose(result)}>
                                    <ListItemButton>
                                        <span style={{marginLeft: '20px'}}>
                                            <Typography sx={{fontSize: '16px', fontWeight: 700}}>{result?.title}</Typography>
                                            <Typography sx={{fontSize: '16px', fontWeight: 400}}>{result?.notifyDate ? DateTime.fromISO(result.notifyDate!).toFormat("yyyy.MM.dd") : ""}</Typography>
                                        </span>
                                    </ListItemButton>
                                </ListItem>
                            })}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => handleClose()}>닫기</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SearchNoticesPopup