import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"
import { PageType } from "types/PageType"
import { NoticeDetailRequestBodyType } from "types/NoticeDetailRequestBodyType"
import { NoticeDetailResponseType } from "types/NoticeDetailResponseType"
import { NoticeType } from "types/NoticeType"

export const getNotices = (params: { size: number; page: number }) => httpGet<PageType<NoticeType>>("/notices", params)
export const getNoticeDetail = (noticeId: number) => httpGet<NoticeDetailResponseType>(`/notices/${noticeId}`)
export const createNoticeDetail = (noticeDetail: NoticeDetailRequestBodyType) =>
  httpPost<NoticeDetailResponseType>("/notices", noticeDetail)
export const updateNoticeDetail = (noticeId: number, noticeDetail: NoticeDetailRequestBodyType) =>
  httpPut<NoticeDetailResponseType>(`/notices/${noticeId}`, noticeDetail)
export const deleteNoticeDetail = (noticeId: number) => httpDelete(`/notices/${noticeId}`)
