import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import React, {useCallback, useState} from "react";
import {DateTime} from "luxon";
import DateRangePicker from "../../../components/DateRangePicker";
import MultipleStatusFilter from "../../../components/MultipleStatusFilter";
import {ProductStatusCode, SaleTypeCode} from "../../../types/ProductType";
import PageableDataGrid from "../../../components/PageableDataGrid";
import {getBuyPriceOfferGroupByProductPage, getBuyPriceOfferPage} from "../../../apis/buyPriceOfferAPI";
import {numberWithCommas, truncate} from "../../../utils/NumberUtils";
import BuyPriceOfferDetailPopup from "../BuyPriceOfferDetailPopup";
import SearchInput from "../../../components/Search/SearchBar/SearchInput";

type SearchParams = {
    page: number,
    size: number,
    startDate: string,
    endDate: string,
    productStatuses?: string[] | null,
    productSaleTypes?: string[] | null,
    sort: string,
    productId?: string,
    buyerId?: string,
    buyerName?: string,
    buyerPhone?: string
}

const initSearchParams = {
    page: 0,
    size: 50,
    sort: 'offeredAt,desc',
    startDate: DateTime.now().minus({day: 7}).toISODate(),
    endDate: DateTime.now().toISODate(),
    productStatuses: null,
    productSaleTypes: null,
}

const searchMenuList: { value: string; title: string }[] = [
    { value: "productId", title: "상품번호" },
    { value: "buyerId", title: "구매자 ID" },
    { value: "buyerName", title: "구매자 이름" },
    { value: "buyerPhone", title: "구매자 연락처" },
]

export type BuyPriceOfferListSearchParams = {
    productId?: number,
    buyerId?: string,
    buyerName?: string,
    buyerPhone?: string
}

export type BuyPriceOfferSearchType = { filter: keyof BuyPriceOfferListSearchParams; keyword: string | undefined}

const BuyPriceOfferGroupByProductTab = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(initSearchParams)
    const [selectedProductId, setSelectedProductId] = useState<number|null>(null)

    const columns = [
        {field: 'id', headerName: "상품 ID", width: 70},
        {field: 'saleType', headerName: "판매 방식", width: 100},
        {field: 'status', headerName: "상품 상태", width: 100},
        {field: 'title', headerName: "상품명", flex: 1, renderCell: (params: any) => (
                <Button size={"small"} variant={"outlined"} style={{fontWeight: "bold"}} onClick={() => {setSelectedProductId(params.row['id'])}}>
                    {params.row['title']}
                </Button>
            )
        },
        {field: 'detailModel.refNo', headerName: "Ref No.", width: 150},

        {field: 'buyPriceOfferCount', headerName: "제안받은수", width: 80, type: 'number'},

        {field: 'price', headerName: "현재 가격", width: 110, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.price, 4))} 만원`, type: 'number'},
        {field: 'maxBuyPriceOfferedPrice', headerName: "최대제안가격", width: 110, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.maxBuyPriceOfferedPrice, 4))} 만원`, type: 'number'},
        {field: 'minBuyPriceOfferedPrice', headerName: "최소제안가격", width: 110, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.minBuyPriceOfferedPrice, 4))} 만원`, type: 'number'},

        {field: 'priceUpdatedAt', headerName: "가격변경일", width: 120, valueGetter: (params: any) => `${ params.row.priceUpdatedAt ? DateTime.fromISO(params.row.priceUpdatedAt).toFormat("yyyy-MM-dd") : '-'}`, type: 'date'},
        {field: 'firstOfferedAt', headerName: "첫제안일", width: 120, valueGetter: (params: any) => `${DateTime.fromISO(params.row.firstOfferedAt).toFormat("yyyy-MM-dd")}`, type: 'date'},
        {field: 'latestOfferedAt', headerName: "최근제안일", width: 120, valueGetter: (params: any) => `${DateTime.fromISO(params.row.latestOfferedAt).toFormat("yyyy-MM-dd")}`, type: 'date'},
    ]

    const [searchFilter, setSearchFilter] = useState<BuyPriceOfferSearchType>({
        filter: "productId",
        keyword: "",
    })
    const changeSearchKeyword = useCallback((keyword: string) => {
        setSearchFilter((prev) => ({ ...prev, keyword }))
    }, [])

    const changeSearchFilterValue = useCallback((searchFilterValue: keyof BuyPriceOfferListSearchParams) => {
        setSearchFilter((prev) => ({ ...prev, filter: searchFilterValue }))
    }, [])

    return (
       <Stack direction={"column"} spacing={1}>
           <DateRangePicker startDate={searchParams.startDate}
                            endDate={searchParams.endDate}
                            onChange={(change) => {setSearchParams({...searchParams, startDate: change.startDate, endDate: change.endDate})}}
           />
           <Grid container direction="row"
                 justifyContent="flex-start"
                 alignItems="center" spacing={2}>
               <Grid item container xs={6} justifyContent="flex-end" spacing={0}>
                   <FormControl sx={{ width: "160px" }} size={"small"}>
                       <InputLabel id="search_key">검색항목</InputLabel>
                       <Select
                           defaultValue={"productId"}
                           required
                           onChange={(e) => changeSearchFilterValue(e.target.value as keyof BuyPriceOfferListSearchParams)}
                       >
                           {searchMenuList.map((menu) => (
                               <MenuItem value={menu.value.toString()} key={menu.title}>
                                   {menu.title}
                               </MenuItem>
                           ))}
                       </Select>
                   </FormControl>
                   <SearchInput
                       style={{
                           flex: 1,
                       }}
                       onChange={(e) => changeSearchKeyword(e.target.value)}
                       onKeyDown={(e) => {
                           setSearchParams((prev) => ({ ...prev, [searchFilter.filter]: searchFilter.keyword}))
                       }}
                   />
               </Grid>
               <Grid item xs={3}>
                   <MultipleStatusFilter
                       name={"판매 방식"}
                       codes={[SaleTypeCode.직접판매, SaleTypeCode.위탁판매, SaleTypeCode.진단전판매].map(e => e.valueOf())}
                       onChange={ (values) => setSearchParams({...searchParams, productSaleTypes: values}) }
                   />
               </Grid>
               <Grid item xs={3}>
                   <MultipleStatusFilter
                       name={"상품 상태"}
                       codes={[ProductStatusCode.판매중, ProductStatusCode.결제_완료, ProductStatusCode.결제_대기, ProductStatusCode.판매_취소, ProductStatusCode.판매_대기, ProductStatusCode.판매_완료].map(e => e.valueOf())}
                       onChange={ (values) => setSearchParams({...searchParams, productStatuses: values}) }
                   />
               </Grid>
           </Grid>

           <Box sx={{height: 720, width: '100%'}}>
               <PageableDataGrid
                   columns={columns}
                   fetch={getBuyPriceOfferGroupByProductPage}
                   searchParams={searchParams}
               />
           </Box>
           {selectedProductId && <BuyPriceOfferDetailPopup productId={selectedProductId} handleClose={() => setSelectedProductId(null)}/>}
       </Stack>
    )
}

export default BuyPriceOfferGroupByProductTab