import {Box, Button, Divider, FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography} from "@mui/material"
import CardTitle from "../../../components/CardTitle"
import { SearchOutlined } from "@mui/icons-material"
import { DatePicker } from "@mui/lab"
import { DateTime } from "luxon"
import ResellItemTable from "./ResellItemTable"
import ResellItemDetailView from "./ResellItemDetailView"
import { useEffect, useRef, useState } from "react"
import { BrandType } from "types/BrandType"
import { getBrands } from "../../../apis/brandAPI"
import { getResellItemPage, getResellItemList, ResellItemSearchParams, updateResellItem } from "../../../apis/resellItemAPI"
import { ResellItemType } from "types/ResellItemType"
import { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel"
import { PageType } from "types/PageType"
import { downloadExcel } from '../../../utils/excelUtil'
import _ from 'lodash'
import { toast } from "react-toastify"

interface ResellItemState {
  resellItemList?: PageType<ResellItemType> | null
  resellItemListIsLoading: boolean
  resellItemSearchParams?: ResellItemSearchParams | null
  resellItemPageParams?: any | null
  selectionResellItem?: ResellItemType | null
  bradList?: BrandType[] | null,
  itemStatus?: string | null,
}

const initState = {
  resellItemList: null,
  resellItemListIsLoading: true,
  resellItemSearchParams: {
    scrapSources: ["WATCH_EXCHANGE"], // 시계 거래소 고정
    brandSid: null,
    refNoIsNull: null,
    yearIsNull: null,
    lastPriceIsNull: null,
    searchText: null,
    registerDateGoe: DateTime.local().minus({ days: 6 }).toISODate(),
    registerDateLoe: DateTime.local().toISODate(),
  },
  selectionResellItem: null,
  resellItemPageParams: {
    sort: "registerDate,desc",
    size: 25,
    page: 0,
  },
  brandList: [],
}

const ResellItemTab = () => {
  const refInputTextSearchText = useRef<HTMLInputElement | null>(null)
  const [brandList, setBrandList] = useState<BrandType[] | null>([])
  const [state, setState] = useState<ResellItemState>(initState)

  useEffect(() => {
    getBrands().then((brandList) => setBrandList(brandList))
  }, [])

  const handleExcelDownload = () => {
    getResellItemList(state.resellItemSearchParams ?? {})
        .then(list => downloadExcel(list.map(item => ({
          '작성자 ID': item.writerId ?? '-',
          '브랜드': item.brandSid ?? '-',
          '상태': item.itemStatus ?? '-',
          '레퍼런스 번호': item.refNo ?? '-',
          '풀레퍼런스 번호': item.detailModel?.fullRefNo ?? '-',
          '상세 모델명': item.detailModel?.title ?? '-',
          '상세 모델명2': item.detailModel?.titleSecondary ?? '-',
          '스템핑': item.year ?? '-',
          '가격': item.lastPrice ?? '-',
          '게시일': item.registerDate ?? '-'
        })) ?? [], '거래게시글'))
  }

  const fetchResellItemList = async () => {
    getResellItemPage({
      ...state.resellItemPageParams,
      ...state.resellItemSearchParams,
    }).then(result => {
      var data = {
        ...result,
          content: result.content?.map(item => ({
            ...item,
            formattedLastPrice: (item.lastPrice ?? 0) / 10000
          }))
      }
      updateState({...state, resellItemList: data, resellItemListIsLoading: false})
    })
  }

  function handleResellItemChange(resellItem: ResellItemType) {
    var priceFormattedResellItem = {...resellItem, lastPrice: (resellItem?.formattedLastPrice ?? 0) * 10000}

    updateResellItem(resellItem.id, priceFormattedResellItem).then((r) => {
      toast.success("수정 완료")
      if (r.priceWarningMessage) {
        toast.warning(r.priceWarningMessage)
      }


      updateState({
        ...state,
        resellItemList: {
          ...state.resellItemList,
          content:
            state.resellItemList?.content?.map((item) => {
              if (item.id == resellItem.id) return priceFormattedResellItem
              else return item
            }) ?? [],
        },
      })
    }).catch((e) => toast.error(e.message))
  }

  function handleResellItemPageSize(pageSize: number) {
    updateState({ ...state, resellItemPageParams: { ...state.resellItemPageParams, size: pageSize } })
  }

  function handleResellItemPage(page: number) {
    updateState({ ...state, resellItemPageParams: { ...state.resellItemPageParams, page: page } })
  }

  function handleSelectRow([id]: GridSelectionModel) {
    updateState({
      ...state,
      selectionResellItem: state.resellItemList?.content.find((resellItem) => resellItem.id === id),
    })
  }

  function updateState(newState: ResellItemState) {
    if (!_.isEqual(state, newState)) {
      setState(newState)
    }
  }

  useEffect(() => {
    fetchResellItemList()
  }, [state.resellItemSearchParams, state.resellItemPageParams])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardTitle>거래 게시물 관리</CardTitle>
      </Grid>

      {/*검색어 입력 필드*/}
      <Grid item xs={6}>

        <TextField
            inputRef={refInputTextSearchText}
            sx={{width: '400px'}}
            size="small"
            id="standard-bare"
            variant="outlined"
            placeholder={"검색어 입력"}
            onKeyUp={(e) => {
              if (e.code == "Enter") {
                setState({
                  ...state,
                  resellItemSearchParams: {
                    ...state.resellItemSearchParams,
                    searchText: refInputTextSearchText.current?.value,
                  },
                })
              }
            }}
            inputProps={{
              endAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
              ),
            }}
        />
      </Grid>

      {/*날짜 필터*/}
      <Grid item xs={6}>
        <Stack direction="row"
               justifyContent="flex-end"
               alignItems="center"
               spacing={1}>
            <Stack direction={"row"} spacing={1}>
              <DatePicker<DateTime>
                  label="시작 날짜"
                  value={
                    state.resellItemSearchParams?.registerDateGoe
                        ? new Date(state.resellItemSearchParams?.registerDateGoe)
                        : new Date()
                  }
                  onChange={(inputDate) => {
                    if (!inputDate) return

                    const inputIsoDate = inputDate.toISODate()
                    const currentIsoDate = state.resellItemSearchParams?.registerDateLoe
                    const loe = currentIsoDate && currentIsoDate < inputIsoDate ? inputIsoDate : currentIsoDate

                    setState({
                      ...state,
                      resellItemSearchParams: {
                        ...state.resellItemSearchParams,
                        registerDateGoe: inputIsoDate,
                        registerDateLoe: loe,
                      },
                    })
                  }}
                  renderInput={(params) => <TextField size={'small'} {...params} />}
              />
              <DatePicker<DateTime>
                  label="끝 날짜"
                  value={
                    state.resellItemSearchParams?.registerDateLoe
                        ? new Date(state.resellItemSearchParams?.registerDateLoe)
                        : new Date()
                  }
                  onChange={(inputDate) => {
                    if (!inputDate) return

                    const inputIsoDate = inputDate.toISODate()
                    const currentIsoDate = state.resellItemSearchParams?.registerDateGoe
                    const goe = currentIsoDate && currentIsoDate > inputIsoDate ? inputIsoDate : currentIsoDate

                    setState({
                      ...state,
                      resellItemSearchParams: {
                        ...state.resellItemSearchParams,
                        registerDateLoe: inputIsoDate,
                        registerDateGoe: goe,
                      },
                    })
                  }}
                  renderInput={(params) => <TextField size={'small'} {...params} />}
              />
            </Stack>
        </Stack>
      </Grid>

      {/*검색 필터*/}
      <Grid item xs={12}>
        <Box>
            <table className={"basic"} width={"100%"}>
              <tbody>
              <tr>
                <td className={"header"}>가격 여부</td>
                <td colSpan={2}><FormControl size="small" fullWidth>
                  <Select
                      defaultValue={"ALL"}
                      onChange={(e) => {
                        switch (e.target.value) {
                          case "ALL":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, lastPriceIsNull: null },
                            })
                            break
                          case "EXIST":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, lastPriceIsNull: false },
                            })
                            break
                          case "NOT_EXIST":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, lastPriceIsNull: true },
                            })
                            break
                        }
                      }}
                  >
                    <MenuItem value={"ALL"}>전체</MenuItem>
                    <MenuItem value={"EXIST"}>있음</MenuItem>
                    <MenuItem value={"NOT_EXIST"}>없음</MenuItem>
                  </Select>
                </FormControl></td>
                <td className={"header"}>가격 범위</td>
                <td colSpan={2}>
                  <Stack direction="row"
                         justifyContent="center"
                         alignItems="center"
                         spacing={0.5}>
                    <TextField fullWidth size={'small'} placeholder={'최소'} type={'number'} InputProps={{endAdornment: <InputAdornment position="end">만원</InputAdornment>,}} onChange={(e) => {
                      updateState({...state, resellItemSearchParams: { ...state.resellItemSearchParams, lastPriceGoe: e.target.value ? parseInt(e.target.value) * 10000 : null }})
                    }}/>
                    <Typography>~</Typography>
                    <TextField fullWidth size={'small'} placeholder={'최대'} type={'number'} InputProps={{endAdornment: <InputAdornment position="end">만원</InputAdornment>,}} onChange={(e) => {
                      updateState({...state, resellItemSearchParams: { ...state.resellItemSearchParams, lastPriceLoe: e.target.value ? parseInt(e.target.value) * 10000 : null }})
                    }}/>
                  </Stack>
                </td>
              </tr>
              <tr>
                <td className={"header"}>브랜드</td>
                <th colSpan={2}>
                  <FormControl size="small" fullWidth>
                    <Select
                        defaultValue={"ALL"}
                        onChange={(e) => {
                          if (e.target.value === "ALL") {
                            updateState({ ...state, resellItemSearchParams: { ...state.resellItemSearchParams, brandSid: null } })
                          } else {
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, brandSid: e.target.value },
                            })
                          }
                        }}
                    >
                      <MenuItem value={"ALL"}>전체</MenuItem>
                      {brandList ? brandList.map((brand) => <MenuItem value={brand.sid}>{brand.name}</MenuItem>) : null}
                    </Select>
                  </FormControl>
                </th>
                <td className={"header"}>상태</td>
                <th colSpan={2}>
                  <FormControl size="small" fullWidth>
                    <Select
                        defaultValue={"ALL"}
                        onChange={(e) => {
                          switch (e.target.value) {
                            case "ALL":
                              updateState({
                                ...state,
                                resellItemSearchParams: { ...state.resellItemSearchParams, itemStatus: null },
                              })
                              break
                            case "NEW":
                              updateState({
                                ...state,
                                resellItemSearchParams: { ...state.resellItemSearchParams, itemStatus: "NEW" },
                              })
                              break
                            case "USED":
                              updateState({
                                ...state,
                                resellItemSearchParams: { ...state.resellItemSearchParams, itemStatus: "USED" },
                              })
                              break
                          }
                        }}
                    >
                      <MenuItem value={"ALL"}>전체</MenuItem>
                      <MenuItem value={"NEW"}>미착용</MenuItem>
                      <MenuItem value={"USED"}>중고</MenuItem>
                    </Select>
                  </FormControl>
                </th>
              </tr>
              <tr>
                <td className={"header"}>데이터 확인</td>
                <td>
                  <FormControl size="small" fullWidth>
                    <Select
                        defaultValue={"ALL"}
                        onChange={(e) => {
                          if (e.target.value === "ALL") {
                            updateState({
                              ...state,
                              resellItemSearchParams: {...state.resellItemSearchParams, confirmStatus: null},
                            })
                          } else {
                            updateState({
                              ...state,
                              resellItemSearchParams: {...state.resellItemSearchParams, confirmStatus: e.target.value},
                            })
                          }
                        }}
                    >
                      <MenuItem value={"ALL"}>전체</MenuItem>
                      <MenuItem value={"확인"}>확인</MenuItem>
                      <MenuItem value={"미확인"}>미확인</MenuItem>
                    </Select>
                  </FormControl>
                </td>
                <td className={"header"}>레퍼런스 번호 여부</td>
                <td><FormControl size="small" fullWidth>
                  <Select
                      defaultValue={"ALL"}
                      onChange={(e) => {
                        switch (e.target.value) {
                          case "ALL":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, refNoIsNull: null },
                            })
                            break
                          case "EXIST":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, refNoIsNull: false },
                            })
                            break
                          case "NOT_EXIST":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, refNoIsNull: true },
                            })
                            break
                        }
                      }}
                  >
                    <MenuItem value={"ALL"}>전체</MenuItem>
                    <MenuItem value={"EXIST"}>있음</MenuItem>
                    <MenuItem value={"NOT_EXIST"}>없음</MenuItem>
                  </Select>
                </FormControl></td>
                <td className={"header"}>스탠핑 연도 여부</td>
                <td><FormControl size="small" fullWidth>
                  <Select
                      defaultValue={"ALL"}
                      onChange={(e) => {
                        switch (e.target.value) {
                          case "ALL":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, yearIsNull: null },
                            })
                            break
                          case "EXIST":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, yearIsNull: false },
                            })
                            break
                          case "NOT_EXIST":
                            updateState({
                              ...state,
                              resellItemSearchParams: { ...state.resellItemSearchParams, yearIsNull: true },
                            })
                            break
                        }
                      }}
                  >
                    <MenuItem value={"ALL"}>전체</MenuItem>
                    <MenuItem value={"EXIST"}>있음</MenuItem>
                    <MenuItem value={"NOT_EXIST"}>없음</MenuItem>
                  </Select>
                </FormControl></td>
              </tr>
              </tbody>
            </table>
        </Box>
      </Grid>


      {/*테이블*/}
      <Grid item xs={12}><Divider /></Grid>
      <Grid item container xs={12} justifyContent="flex-end" spacing={1} direction={"row"}>
        <Grid item xs={7}>
          <Stack>
            <Stack direction={"row"} justifyContent="flex-end" spacing={2}>
              <Button size={'small'} variant={'contained'} color={"primary"} onClick={handleExcelDownload}>엑셀 다운로드</Button>
            </Stack>
            <ResellItemTable
              rows={state.resellItemList?.content}
              handleSelectRow={handleSelectRow}
              handleResellItemChange={handleResellItemChange}
              handlePageSizeChange={handleResellItemPageSize}
              handlePageChange={handleResellItemPage}
              page={state.resellItemPageParams.page}
              pageSize={state.resellItemPageParams.size}
              totalCount={state.resellItemList?.totalElements}
              loading={state.resellItemListIsLoading}
              brandList={brandList}
            />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <ResellItemDetailView resellItem={state.selectionResellItem} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResellItemTab
