import React, {useState} from "react";
import UserDetailPopupPage from "../pages/User/UserDetailPopupPage";
import {Button} from "@mui/material";
import { DiagnosisPrintPopup } from "../pages/Diagnoses/DiagnosisPrintPopup"
import { SellOrderStatusCode } from "../types/SellOrderType"

type Props = {
  productId?: number | undefined,
  userId?: number | undefined,
  advancedDiagnosis?: boolean | undefined,
  detailModelId?: number | undefined
  disabled: boolean
}

const AuthenticationIssuanceButton: React.FC<Props> = ({productId, userId, advancedDiagnosis, detailModelId, disabled}) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {
        <DiagnosisPrintPopup
          isOpen={open}
          closePopup={() => setOpen(false)}
          {...(productId
            ? { productId: productId!! } // 진단체크리스에사 발급 인 경우
            : { // 수동발급인 경우
              userId: userId!!,
              detailId: detailModelId!!,
              advancedDiagnosis: advancedDiagnosis!!,
            })}
        />
      }

      <Button
        size={"medium"}
        disabled={disabled}
        variant={"contained"}
        color={"success"}
        onClick={() => setOpen(true)}
      >
        진단서 발급
      </Button>
    </>
  )
}

export default AuthenticationIssuanceButton