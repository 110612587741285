import {Button, Stack} from "@mui/material";


// @ts-ignore
const HomeReviewToolbar = ({handleCreateHomeReview, handleDeleteButton, disabledDeleteButton}) => {
    return(
        <Stack direction={"row"} justifyContent={'flex-end'} spacing={1}>
            <Button variant={'contained'} size={'small'} onClick={handleCreateHomeReview}>추가</Button>
            <Button variant={'contained'} size={'small'} onClick={handleDeleteButton} color={'error'} disabled={disabledDeleteButton}>삭제</Button>
        </Stack>
    )
}

export default HomeReviewToolbar