import { Stack, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useState } from "react"

import { ProductDetailTextBannerList } from "./ProductDetailTextBanner/ProductDetailTextBannerList"
import FrontPopTab from "./FrontPopTab"
import { MyPageBannerList } from "./MyPageBanner/MyPageBannerList"

type EtcTabType = "전면팝업" | "마이페이지" | "상품상세텍스트"

const EtcTab = () => {
  const [selectedEtcTab, setSelectedEtcTab] = useState<EtcTabType>("전면팝업")

  return (
    <Stack direction={"column"}>
      <TabContext value={selectedEtcTab}>
        <TabList onChange={(_e, value) => setSelectedEtcTab(value)}>
          <Tab value={"전면팝업"} label={"전면 팝업 배너"} />
          <Tab value={"띠배너관리"} label={"띠배너관리"} />
          <Tab value={"상품상세텍스트"} label={"상품 상세 텍스트 배너"} />
        </TabList>
        <TabPanel value={"전면팝업"}>
          <FrontPopTab />
        </TabPanel>
        <TabPanel value={"띠배너관리"}>
          <MyPageBannerList />
        </TabPanel>
        <TabPanel value={"상품상세텍스트"}>
          <ProductDetailTextBannerList />
        </TabPanel>
      </TabContext>
    </Stack>
  )
}

export default EtcTab
