import Modal from "components/Modal"
import styled from "@emotion/styled"
import Button from "components/Button"
import { ModelSearchInput } from "./ModelSearchInput"
import { useSerialNumberRegister } from "./useSerialNumberRegister"
import CheckBox from "components/Checkbox"

type Props = {
  closeModal: () => void
}

export const SerialNumberRegisterModal = ({ closeModal }: Props) => {
  const {
    selectedModel,
    serialNumber,
    onSelectModel,
    changeSerialNumber,
    isSubmittable,
    toggleBlackListFlag,
    isBlackList,
    onRegisterSerial,
  } = useSerialNumberRegister()

  const handleRegisterSerialNumber = () => {
    onRegisterSerial()
    closeModal()
  }

  return (
    <Modal isOpen closeModal={closeModal}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h1>시리얼 정보 등록</h1>
        </ModalContentHeader>
        <ModalContentBody>
          <InlineWrapper>
            <Label>시리얼 넘버 (필수)</Label>
            <Input
              placeholder="시리얼 넘버를 입력해주세요."
              value={serialNumber}
              onChange={(e) => changeSerialNumber(e.target.value)}
            />
          </InlineWrapper>
          <InlineWrapper>
            <Label>모델 선택</Label>
            <ColumnWrapper style={{ flex: 1 }}>
              <ModelSearchInput onSelectModel={onSelectModel} />
              {!!selectedModel && (
                <SelectedModelWrapper>
                  <ModelImage src={selectedModel.image} />
                  <ModelInfo>
                    <ModelInfoInlineWrapper>
                      <ModelInfoLabel>브랜드</ModelInfoLabel>
                      <ModelInfoValue>{selectedModel.brand}</ModelInfoValue>
                    </ModelInfoInlineWrapper>
                    <ModelInfoInlineWrapper>
                      <ModelInfoLabel>모델명</ModelInfoLabel>
                      <ColumnWrapper>
                        <ModelInfoValue>{selectedModel.title}</ModelInfoValue>
                        <ModelInfoValue style={{ color: "#969696" }}> {selectedModel.titleSecondary}</ModelInfoValue>
                      </ColumnWrapper>
                    </ModelInfoInlineWrapper>
                    <ModelInfoInlineWrapper>
                      <ModelInfoLabel>Ref No.</ModelInfoLabel>
                      <ModelInfoValue>{selectedModel.refNo}</ModelInfoValue>
                    </ModelInfoInlineWrapper>
                  </ModelInfo>
                </SelectedModelWrapper>
              )}
            </ColumnWrapper>
          </InlineWrapper>
        </ModalContentBody>
        <ModalContentFooter>
          <BlackListCheckWrapper>
            <CheckBox checked={isBlackList} id="black-list" onChange={toggleBlackListFlag} />
            <CheckBoxLabel htmlFor="black-list" onChange={toggleBlackListFlag}>
              블랙리스트
            </CheckBoxLabel>
          </BlackListCheckWrapper>
          <ButtonWrapper>
            <Button
              disabled={!isSubmittable}
              onClick={handleRegisterSerialNumber}
              buttonSize="M"
              style={{ height: "35px" }}
            >
              시리얼 등록
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

const ModalContentWrapper = styled.div`
  width: 885px;
  height: 605px;
  display: flex;
  flex-direction: column;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 50px;

  h1 {
    font-size: 24px;
    margin: 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  flex: 1;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px 12px;
`

const ButtonWrapper = styled.div`
  width: 105px;
`

const BlackListCheckWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`

const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 16px;
`

const Label = styled.div`
  width: 145px;
  height: 38px;
  display: flex;
  align-items: center;
  color: #3b464f;
`
const Input = styled.input`
  width: 290px;
  outline: none;
  border: none;
  padding: 10px 0;
  border-bottom: 1px solid #000000;
`

const SelectedModelWrapper = styled.div`
  display: inline-flex;
  margin-top: 28px;
`

const ModelImage = styled.img`
  width: 271px;
  height: 271px;
  border: 1px solid #ced2d6;
`

const ModelInfo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`

const ModelInfoInlineWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 12px;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ModelInfoLabel = styled.div`
  font-size: 16px;
  width: 72px;
  margin-right: 4px;
`

const ModelInfoValue = styled.div``
