import { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { DetailModelRegeditPopup } from "./DetailModelRegeditPopup/DetailModelRegeditPopup"
import { DetailModelManagerBrandChips } from "./DetailModelManagerBrandChips"
import { DetailModelManagerModelListTable } from "./DetailModelManagerModelListTable"
import { DetailModelManagerSearchBar } from "./DetailModelManagerSearchBar"
import { useDetailModelManagerStore } from "./useDetailModelManagerStore"
import { ModelManagerTab } from "pages/ModelManager/ModelManagerTab"

const DetailModelManager: FunctionComponent = () => {
  const navigate = useNavigate()
  const {
    brandList,
    detailModelListParams,
    detailModelList,
    fetchBrandList,
    fetchDetailModelList,
    removeDetailModel,
    changePage,
    changeRegistrationStatus,
    changeDetailModelDisplay,
    changeSearchKeyValue,
  } = useDetailModelManagerStore()

  const [openCreatePopup, setOpenCreatePopup] = useState(false)
  const [detailModelIdToUpdate, setDetailModelIdToUpdate] = useState<number | null>(null)

  useEffect(() => {
    fetchBrandList()
    fetchDetailModelList(detailModelListParams)
  }, [detailModelListParams, fetchBrandList, fetchDetailModelList])

  return (
    <>
      {openCreatePopup && (
        <DetailModelRegeditPopup
          brandSid={detailModelListParams.brandSid}
          modelId={detailModelListParams.modelId}
          detailModelId={null}
          onClickCancel={() => setOpenCreatePopup(false)}
          onClickConfirm={() => {
            fetchDetailModelList(detailModelListParams)
            setOpenCreatePopup(false)
          }}
          onRefreshList={() => fetchDetailModelList(detailModelListParams)}
        />
      )}
      {detailModelIdToUpdate && (
        <DetailModelRegeditPopup
          brandSid={detailModelListParams.brandSid}
          modelId={detailModelListParams.modelId}
          detailModelId={detailModelIdToUpdate}
          onClickCancel={() => setDetailModelIdToUpdate(null)}
          onClickConfirm={() => {
            fetchDetailModelList(detailModelListParams)
            setDetailModelIdToUpdate(null)
          }}
          onRefreshList={() => fetchDetailModelList(detailModelListParams)}
        />
      )}
      <ModelManagerTab />
      <div style={{ margin: "4px 24px" }}>
        <DetailModelManagerBrandChips
          brands={brandList}
          selectedBrandSid={detailModelListParams.brandSid}
          onChangeBrandSid={(brandSid) => {
            const urlSearchParams = new URLSearchParams()
            urlSearchParams.set("brandSid", brandSid)
            navigate({ pathname: "/model-manager/model-db", search: urlSearchParams.toString() })
          }}
        />
      </div>
      <div style={{ height: 16 }} />
      <DetailModelManagerSearchBar
        listParams={detailModelListParams}
        onChangeRegistrationStatus={changeRegistrationStatus}
        onChangeSearchKeyValue={changeSearchKeyValue}
        onClickOpenCreatePopup={() => setOpenCreatePopup(true)}
      />
      <div style={{ height: 20 }} />
      <DetailModelManagerModelListTable
        fetchDetailModelList={fetchDetailModelList}
        detailModelListParams={detailModelListParams}
        detailModelList={detailModelList}
        removeDetailModel={removeDetailModel}
        onChangePage={changePage}
        onChangeDetailModelDisplay={changeDetailModelDisplay}
        onClickUpdateDetailModel={(detailModel) => setDetailModelIdToUpdate(detailModel.id)}
      />
    </>
  )
}

export default DetailModelManager
