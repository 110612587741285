import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { penaltyTableHeaderItemList } from "pages/Penalty/PenaltyListPage/PenaltyListTable/TableHeader"
import { toDateTimeStr } from "utils/datetimeUtil"
import { PenaltyStatusCode, CancelShippingCode } from "types/PenaltyType"
import { ShippingStatusCode } from "types/ShippingType"
import CheckBox from "components/Checkbox"
import styled from "@emotion/styled"
import { numberWithCommas } from "utils/NumberUtils"
import { CheckablePenalityListItem } from "pages/Penalty/PenaltyListPage/usePenaltyList"
import { getPenaltyShippingStatus } from "pages/Penalty/utils"

type Props = {
  penalty: CheckablePenalityListItem
  isCheckboxHidden: boolean
  togglePenaltyForShipping: (selectedPenaltyList: CheckablePenalityListItem) => void
  onClickRow: (orderId: string) => void
} & HTMLAttributes<HTMLDivElement>

const PenaltyListTableRow = ({
  penalty,
  isCheckboxHidden,
  togglePenaltyForShipping,
  onClickRow,
}: PropsWithChildren<Props>) => {
  const handleOnClick = (e: any) => {
    e.preventDefault()
    onClickRow(penalty.orderId)
  }

  return (
    <TableRow onClick={handleOnClick}>
      {!isCheckboxHidden && (
        <TableRowItem
          style={{ maxWidth: penaltyTableHeaderItemList[0].width }}
          onClick={(e) => {
            e.stopPropagation()
            togglePenaltyForShipping(penalty)
          }}
        >
          <CheckBox readOnly checked={penalty.isChecked} />
        </TableRowItem>
      )}

      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[1].width }}>{penalty.orderId}</TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[2].width }}>
        <StatusText status={penalty.status}>{penalty.status}</StatusText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[3].width }}>
        <ShippingStatusText shippingStatus={getPenaltyShippingStatus(penalty)}>
          {getPenaltyShippingStatus(penalty)}
        </ShippingStatusText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[4].width }}>
        <ColumnWrapper>
          <b>{penalty.sellOrder.product.id}</b>
          <p style={{ margin: "3px 0 0 0" }}>{penalty.sellOrder.product.title}</p>
        </ColumnWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[5].width }}>
        {penalty.sellOrder.seller.id}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[6].width }}>
        {penalty.sellOrder.seller.name}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[7].width, justifyContent: "flex-end"}} >
        {numberWithCommas(penalty.paymentAmount) || "-"}
      </TableRowItem>
        <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[8].width, justifyContent: "flex-end"}} >
            {numberWithCommas(penalty.paymentAmount - (penalty.refundAmount ?? 0)) || "-"}
        </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[9].width }}>
        <DateTimeWrapper>{toDateTimeStr(penalty.sellOrder.orderedAt)}</DateTimeWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[10].width }}>
        <DateTimeWrapper>{toDateTimeStr(penalty.createdAt)}</DateTimeWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: penaltyTableHeaderItemList[11].width }}>
        <DateTimeWrapper>{penalty.completedAt ? toDateTimeStr(penalty.completedAt) : "-"}</DateTimeWrapper>
      </TableRowItem>
    </TableRow>
  )
}

export default PenaltyListTableRow

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DateTimeWrapper = styled.div`
  max-width: 80px;
`

export const StatusText = styled.div<{ status: PenaltyStatusCode }>`
  font-weight: 700;

  color: ${({ status }) => {
    switch (status) {
      case PenaltyStatusCode.완료:
        return "#1976d2"

      case PenaltyStatusCode.실패:
        return "#d32f2f"

      case PenaltyStatusCode.대기중:
        return "rgba(0,0,0,0.2)"
    }
  }};
`

export const ShippingStatusText = styled.div<{ shippingStatus?: CancelShippingCode | string }>`
  color: ${({ shippingStatus }) => {
    switch (shippingStatus) {
      case ShippingStatusCode.배송_준비:
        //파란색
        return "#1976d2"

      case ShippingStatusCode.배송중:
        //초록색
        return "#2e7d32"

      case ShippingStatusCode.상품_발송:
        //주황색
        return "#e7682e"

      case ShippingStatusCode.배송_완료:
        //회색
        return "rgba(0,0,0,0.2)"

      case "반송 대기":
        //빨간색
        return "#d32f2f"
    }
  }};
`
