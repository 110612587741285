import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, Stack, Switch, TextField, Typography,} from "@mui/material"
import RowBlock from "components/RowBlock"
import {DateTimePicker} from "@mui/lab"
import {DateTime} from "luxon"
import React, {useEffect, useState} from "react"
import {ShopCurationCreateBodyType, ShopCurationType} from "types/ShopCurationType"
import shopDetailModelCurationAPI from "apis/shopDetailModelCurationAPI"
import _ from "lodash"
import DetailModelSelectPopup from "components/Popup/DetailModelSelectPopup"
import CurationDetailModelTable from "./CurationDetailModelTable"
import {ProductConditionCode} from "types/DiagnosisType"
import {toast} from "react-toastify"
import CheckBox from "../../../../components/Checkbox"
import styled from "@emotion/styled"

export const exposureMenuList: { value: ShopCurationType["curationType"]; title: string }[] = [
  { value: "수동", title: "수동" },
  { value: "최신입고순", title: "최신 입고순" },
  { value: "최근가격변동", title: "30일 내 가격 변동" },
  { value: "최근가격상승", title: "30일 내 가격 상승" },
  { value: "최근가격하락", title: "30일 내 가격 하락" },
  { value: "가격높은순", title: "가격 높은순" },
  { value: "가격낮은순", title: "가격 낮은순" },
  { value: "주간거래많은순", title: "주간 거래 많은순" }
]

const initCurationState: ShopCurationCreateBodyType = {
  title: null,
  titleSecondary: null,
  products: [],
  minQuantity: null,
  maxQuantity: null,
  onDisplay: true,
  priority: null,
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().toISO(),
  excludeSoldOut: false,
  curationType: "수동",
  saleType: ["직접판매", "위탁판매", "진단전판매"],
  productCondition: [ProductConditionCode.미착용, ProductConditionCode.중고],
  showDomesticUser: true,
  showForeignUser: false
}

type CurationStateType = ShopCurationCreateBodyType | ShopCurationType

type Props = {
  curationId: number | null
  handleClose: () => void
  editorType: "EDIT" | "CREATE"
}

const CurationEditorPopup = ({ curationId, handleClose, editorType }: Props) => {
  const [state, setState] = useState<CurationStateType>(initCurationState)
  const [showProductSelectPopup, setShowProductSelectPopup] = useState(false)

  const handleOnChangeState = (partialState: Partial<ShopCurationType>) => {
    setState((state) => (state ? { ...state, ...partialState } : state))
  }

  const changeCurationType = (type: ShopCurationType["curationType"]) => {
    const curationValue =
      type === "수동"
        ? {
            saleType: initCurationState.saleType,
            productCondition: initCurationState.productCondition,
          }
        : {}

    setState((prevState) => ({ ...prevState, curationType: type, ...curationValue }))
  }

  const checkIfIsValidPayload = (state: CurationStateType) => {
    if (_.isNil(state)) {
      return false
    }

    if (_.isNil(state.title)) {
      toast.error("큐레이션 제목을 입력해주세요")
      return false
    }

    if (_.isNil(state.titleSecondary)) {
      toast.error("큐레이션 부제목을 입력해주세요")
      return false
    }

    if (_.isNil(state.minQuantity)) {
      toast.error("최소 노출개수를 입력해주세요")
      return false
    }

    if (_.isNil(state.maxQuantity)) {
      toast.error("최대 노출개수를 입력해주세요")
      return false
    }

    if (state?.curationType === "수동" && state.detailModels?.length!!<1) {
      toast.error("노출 상세 모델을 등록해주세요")
      return false
    }

    return true
  }

  const callApiByEditorType = async (state: CurationStateType) => {
    if (editorType === "EDIT") {
      await shopDetailModelCurationAPI.update(state as ShopCurationType)
    } else {
      await shopDetailModelCurationAPI.create(state as ShopCurationCreateBodyType)
    }
  }

  const handleOnClickSubmitButton = async () => {
    if (_.isNil(state)) return

    if (!checkIfIsValidPayload(state)) {
      return
    }

    await callApiByEditorType(state)
    handleClose()
  }

  const fetchCurationDetail = (curationId: number) => {
    shopDetailModelCurationAPI.getDetail(curationId).then((curation) => {
      setState(curation)
    })
  }

  useEffect(() => {
    if (!!curationId) {
      fetchCurationDetail(curationId)
    } else {
      setState(initCurationState)
    }
  }, [curationId])

  return (
    <Dialog open={true} maxWidth={"lg"} fullWidth>
      <DialogTitle>큐레이션 {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <RowBlock title={"설명"}>
            <TextField size={'small'}
                       fullWidth
                       value={state.description}
                       onChange={(e) => setState({...state, description: e.target.value})}
                       placeholder={'배너의 설명을 입력 해주세요. (서비스에 노출되진 않습니다)'}
            />
          </RowBlock>
          <RowBlock title={"제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.title}
              onChange={(e) => handleOnChangeState({ title: e.target.value })}
              placeholder={"큐레이션 제목"}
            />
          </RowBlock>
          <RowBlock title={"부제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.titleSecondary}
              onChange={(e) => handleOnChangeState({ titleSecondary: e.target.value })}
              placeholder={"큐레이션 부제목"}
            />
          </RowBlock>
          <RowBlock title={"노출 기준"}>
            <FormControl fullWidth>
              <Select
                size={"small"}
                defaultValue={"수동"}
                required
                value={state?.curationType || "수동"}
                onChange={(e) => changeCurationType(e.target.value as ShopCurationType["curationType"])}
              >
                {exposureMenuList.map((menu) => (
                  <MenuItem value={menu.value.toString()} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </RowBlock>
          <RowBlock title={"최소 노출 갯수"}>
            <TextField
              size={"small"}
              value={state?.minQuantity}
              fullWidth
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (inputValue) {
                  handleOnChangeState({ minQuantity: inputValue })
                }
              }}
              placeholder={"3"}
            />
          </RowBlock>
          <RowBlock title={"최대 노출 갯수"}>
            <TextField
              size={"small"}
              value={state?.maxQuantity}
              fullWidth
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (inputValue) {
                  handleOnChangeState({ maxQuantity: inputValue })
                }
              }}
              placeholder={"10"}
            />
          </RowBlock>
          {state?.curationType === "수동" && (
            <RowBlock title={"노출 상세 모델"}>
              <CurationDetailModelTable
                isManual={state?.curationType === "수동"}
                detailModels={state?.detailModels || []}
                handleOnChangeState={handleOnChangeState}
                openShowProductSelectPopup={() => setShowProductSelectPopup(true)}
              />
            </RowBlock>
          )}
          <RowBlock title={"노출 여부"}>
            <Stack direction={"row"}>
              <Switch
                checked={state ? Boolean(state.onDisplay) : false}
                onChange={(e) => handleOnChangeState({ onDisplay: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"품절 상세 모델 제외"}>
            <Stack direction={"row"}>
              <Switch
                  checked={state ? Boolean(state.excludeSoldOut) : false}
                  onChange={(e) => handleOnChangeState({ excludeSoldOut: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 기간"}>
              <Stack direction={"row"} spacing={2}>
                <DateTimePicker
                  onChange={(dateTime) => {
                    handleOnChangeState({
                      startedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                    })
                  }}
                  value={state?.startedAt ? DateTime.fromISO(state.startedAt) : null}
                  renderInput={(props) => <TextField {...props} />}
                  label={"게시 시작"}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                />
                <Typography>~</Typography>
                <DateTimePicker
                  onChange={(dateTime) => {
                    handleOnChangeState({
                      endedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                    })
                  }}
                  value={state?.endedAt ? DateTime.fromISO(state.endedAt) : null}
                  renderInput={(props) => <TextField {...props} />}
                  label={"게시 종료"}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                />
              </Stack>
          </RowBlock>
          <RowBlock title={"노출"}   >
            <Stack direction={'row'} alignItems={'center'}   >
              <CheckBox checked={state?.showDomesticUser}   id="showDomesticUser"  onChange={() => setState((pre) => ({...state, showDomesticUser: !state.showDomesticUser})) } />
              <CheckBoxLabel htmlFor="showDomesticUser" >
                내국인 회원 (국내거주 외국인 포함)
              </CheckBoxLabel>
              <CheckBox checked={state?.showForeignUser}   id="showForeignUser"  onChange={() => setState((pre) => ({...state, showForeignUser: !state.showForeignUser})) } />
              <CheckBoxLabel htmlFor="showForeignUser" >
                외국인 회원
              </CheckBoxLabel>
            </Stack>
          </RowBlock>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleOnClickSubmitButton}>
          {editorType === "EDIT" ? "수정" : "등록"}
        </Button>
        <Button variant={"contained"} color={"error"} onClick={handleClose}>
          취소
        </Button>
      </DialogActions>

      <DetailModelSelectPopup
        open={showProductSelectPopup}
        handleClose={(detailModels) => {
          if (_.isNil(state)) return
          const prevDetailModels = state.detailModels ?? []
          const addedDetailModels = detailModels.map((detailModel) => ({ onDisplay: true, priority: 100, detailModel: detailModel }))
          const nextDetailModels = [...addedDetailModels, ...prevDetailModels]
          handleOnChangeState({ detailModels: nextDetailModels })
          setShowProductSelectPopup(false)
        }}
      />
    </Dialog>
  )
}

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`

export default CurationEditorPopup
