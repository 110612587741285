import styled from "@emotion/styled"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { StatusText } from "pages/Penalty/PenaltyListPage/PenaltyListTable/TableBody/TableRow"
import { toDateTimeStr } from "utils/datetimeUtil"
import { numberWithCommas } from "utils/NumberUtils"
import { PenaltyDetail } from "types/PenaltyType"

type Props = {
  paymentAmount: number
  initialPrice: number
  completedAt?: string
  status: PenaltyDetail["status"]
  breakdown: PenaltyDetail["breakdown"]
}

const PenaltySettlementInformation = ({ breakdown, paymentAmount, initialPrice, status, completedAt }: Props) => {
  const upgradeFee = breakdown.fees.find((fee) => fee.feePolicyType === "판매 업그레이드 수수료")

  return (
    <TableWrapper>
      <InlineWrapper>
        <DetailTableColumCell title="완납 일시">{completedAt ? toDateTimeStr(completedAt) : "-"}</DetailTableColumCell>
        <DetailTableColumCell title="패널티 기준가">{numberWithCommas(initialPrice) || "-"}원</DetailTableColumCell>
        <DetailTableColumCell title="패널티 금액">
          {numberWithCommas((breakdown.paymentAmount || 0) - (upgradeFee?.amount || 0))} 원
        </DetailTableColumCell>
        <DetailTableColumCell title="업그레이드 비용">
          {!upgradeFee ? 0 : numberWithCommas(upgradeFee.amount)}원
        </DetailTableColumCell>
        <DetailTableColumCell title="청구 금액">
          {numberWithCommas(breakdown.paymentAmount || 0) || "0"}원
        </DetailTableColumCell>
        <DetailTableColumCell title="납부확인">
          <StatusText status={status}>{status}</StatusText>
        </DetailTableColumCell>
      </InlineWrapper>
    </TableWrapper>
  )
}

export default PenaltySettlementInformation

const TableWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  div.table-column-cell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`
