import { Box, Button, Grid, IconButton, Link, List, ListItem, Switch, TextField } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { upload } from "apis/resourceAPI"
import DeleteConfirmPopup from "components/Popup/DeleteConfirmPopup"
import { Identifier } from "dnd-core"
import { FunctionComponent, PropsWithChildren, useCallback, useRef, useState } from "react"
import { DndProvider, XYCoord, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DetailModelType } from "types/DetailModelType"
import { Article, ContentBlock, ContentBlockTypeCode, Question } from "types/MagazineType"
import { isNotEmpty } from "utils/validationUtils"
import { isEmpty } from "lodash"
import SearchArticlesPopup from "components/Popup/SearchMagazineArticlePopup"
import SearchQuestionsPopup from "components/Popup/SearchMagazineQuestionPopup"

export const ArticleContentBlockEditor: FunctionComponent<{
  contentBlocks: ContentBlock[]
  embedDetailModels: DetailModelType[]
  onChangeContentBlocks: (contentBlocks: ContentBlock[]) => void
}> = (props) => {
  return (
    <Box maxHeight={550} overflow="scroll">
      <DndProvider backend={HTML5Backend}>
        {isEmpty(props.contentBlocks) && (
          <Box height={550} textAlign="center">
            <Box>내용이 없습니다</Box>
          </Box>
        )}
        {props.contentBlocks.map((contentBlock, index) => (
          <ArticleContentBlock
            key={index}
            index={index}
            contentBlock={contentBlock}
            embedDetailModels={props.embedDetailModels}
            onChangeOrder={(dragIndex, dropIndex) => {
              const nextContentBlocks = [...props.contentBlocks]
              const dragContentBlock = nextContentBlocks[dragIndex]
              nextContentBlocks[dragIndex] = nextContentBlocks[dropIndex]
              nextContentBlocks[dropIndex] = dragContentBlock
              props.onChangeContentBlocks(nextContentBlocks)
            }}
            onChangeContentBlock={(nextContentBlock) => {
              const nextContentBlocks = props.contentBlocks.map((prevContentBlock, _index) =>
                index === _index ? nextContentBlock : prevContentBlock
              )
              props.onChangeContentBlocks(nextContentBlocks)
            }}
            onDeleteContentBlock={() => {
              const nextContentBlocks = props.contentBlocks.filter((_, _index) => index !== _index)
              props.onChangeContentBlocks(nextContentBlocks)
            }}
          />
        ))}
      </DndProvider>
    </Box>
  )
}

const ArticleContentBlock: FunctionComponent<{
  index: number
  contentBlock: ContentBlock
  embedDetailModels: DetailModelType[]
  onChangeOrder: (dragIndex: number, nextIndex: number) => void
  onChangeContentBlock: (contentBlock: ContentBlock) => void
  onDeleteContentBlock: () => void
}> = (props) => {
  return (
    <ArticleContentBlockWrapper index={props.index} onChangeOrder={props.onChangeOrder}>
      <ArticleContentBlockContent
        contentBlock={props.contentBlock}
        embedDetailModels={props.embedDetailModels}
        onChangeContentBlock={props.onChangeContentBlock}
        onDeleteContentBlock={props.onDeleteContentBlock}
      />
    </ArticleContentBlockWrapper>
  )
}

const ARTICLE_CONTENT_BLOCK_TITLES = {
  [ContentBlockTypeCode.H1]: "타이틀",
  [ContentBlockTypeCode.H2]: "서브타이틀",
  [ContentBlockTypeCode.TEXT]: "텍스트",
  [ContentBlockTypeCode.DASH]: "단락 구분선",
  [ContentBlockTypeCode.HR]: "얇은 구분선",
  [ContentBlockTypeCode.MARGIN]: "여백",
  [ContentBlockTypeCode.IMAGE]: "이미지",
  [ContentBlockTypeCode.VIDEO]: "동영상",
  [ContentBlockTypeCode.YOUTUBE]: "유튜브",
  [ContentBlockTypeCode.DETAIL_MODEL]: "상세모델",
  [ContentBlockTypeCode.REFERENCE]: "출처",
  [ContentBlockTypeCode.HTML]: "HTML",
  [ContentBlockTypeCode.GALLERY]: "GALLERY",
  [ContentBlockTypeCode.CONTENT]: "CONTENT",
  [ContentBlockTypeCode.PRODUCT]: "PRODUCT",
} as const
const ArticleContentBlockContent: FunctionComponent<{
  contentBlock: ContentBlock
  embedDetailModels: DetailModelType[]
  onChangeContentBlock: (contentBlock: ContentBlock) => void
  onDeleteContentBlock: () => void
}> = ({ contentBlock, embedDetailModels, onChangeContentBlock, onDeleteContentBlock }) => {
  const [openDeleteConfirmPopup, setOpenDeleteConfirmPopup] = useState(false)
  const [openSearchArticlesPopup, setOpenSearchArticlesPopup] = useState<boolean>(false)
  const [openSearchQuestionsPopup, setOpenSearchQuestionsPopup] = useState<boolean>(false)

  const renderTextField = useCallback(() => {
    switch (contentBlock.type) {
      case ContentBlockTypeCode.H1:
      case ContentBlockTypeCode.H2:
      case ContentBlockTypeCode.MARGIN: {
        return (
          <Grid item>
            <TextField
              size="small"
              fullWidth
              multiline={true}
              placeholder={ARTICLE_CONTENT_BLOCK_TITLES[contentBlock.type]}
              value={contentBlock.value}
              onChange={(e) => {
                onChangeContentBlock({ ...contentBlock, value: e.target.value })
              }}
            />
          </Grid>
        )
      }

      case ContentBlockTypeCode.TEXT: {
        const viverBaseUrl = "https://www.viver.co.kr"

        return (
          <Grid item container spacing={1}>
            <SearchArticlesPopup
              open={openSearchArticlesPopup}
              handleClose={(selectedArticle: Article) => {
                if (selectedArticle) {
                  onChangeContentBlock({
                    ...contentBlock,
                    value: `${contentBlock.value}\n<a href="${viverBaseUrl}/contents/${
                      selectedArticle.id
                    }">${selectedArticle.title?.replaceAll("\n", "")}</a>`,
                  })
                }
                setOpenSearchArticlesPopup(false)
              }}
            />
            <SearchQuestionsPopup
              open={openSearchQuestionsPopup}
              handleClose={(selectedQuestion: Question) => {
                if (selectedQuestion) {
                  onChangeContentBlock({
                    ...contentBlock,
                    value: `${contentBlock.value}\n<a href="${viverBaseUrl}/contents/qna/${
                      selectedQuestion.id
                    }">${selectedQuestion.question?.replaceAll("\n", "")}</a>`,
                  })
                }
                setOpenSearchQuestionsPopup(false)
              }}
            />
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                multiline={true}
                placeholder={ARTICLE_CONTENT_BLOCK_TITLES[contentBlock.type]}
                value={contentBlock.value}
                onChange={(e) => {
                  onChangeContentBlock({ ...contentBlock, value: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Button size={"small"} variant={"outlined"} onClick={() => setOpenSearchArticlesPopup(true)}>
                  매거진 링크 추가
                </Button>
              </Grid>
              <Grid item>
                <Button size={"small"} variant={"outlined"} onClick={() => setOpenSearchQuestionsPopup(true)}>
                  Q&A 링크 추가
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      case ContentBlockTypeCode.REFERENCE: {
        return (
          <Grid item>
            <TextField
              size="small"
              fullWidth
              multiline={true}
              placeholder={ARTICLE_CONTENT_BLOCK_TITLES[contentBlock.type]}
              value={contentBlock.value}
              onChange={(e) => {
                onChangeContentBlock({ ...contentBlock, value: e.target.value })
              }}
            />
            <List dense>
              {contentBlock.resources?.map((resource, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        onChangeContentBlock({
                          ...contentBlock,
                          resources: contentBlock.resources?.filter((_, _index) => _index !== index),
                        })
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="https://www.viver.co.kr"
                    value={resource}
                    onChange={(e) => {
                      onChangeContentBlock({
                        ...contentBlock,
                        resources: contentBlock.resources?.map((_, _index) => {
                          if (_index === index) {
                            return e.target.value
                          }
                          return _
                        }),
                      })
                    }}
                  >
                    {resource}
                  </TextField>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={() => {
                onChangeContentBlock({ ...contentBlock, resources: [...(contentBlock.resources || []), ""] })
              }}
            >
              URL 추가
            </Button>
          </Grid>
        )
      }

      case ContentBlockTypeCode.IMAGE:
      case ContentBlockTypeCode.VIDEO:
      case ContentBlockTypeCode.YOUTUBE: {
        return (
          <Grid item container spacing={1}>
            <Grid item xs={4} style={{ overflowX: "auto" }}>
              {contentBlock.resources?.map((src, index) => {
                if (contentBlock.type === ContentBlockTypeCode.IMAGE) {
                  return <img key={index} src={src} alt={`${contentBlock.caption}-${index}`} />
                }
                if (contentBlock.type === ContentBlockTypeCode.VIDEO) {
                  return <video key={index} src={src} />
                }
                if (contentBlock.type === ContentBlockTypeCode.YOUTUBE) {
                  return <iframe key={index} title={String(index)} src={`https://www.youtube.com/embed/${src}`} />
                }
                return null
              })}
            </Grid>
            <Grid item xs={8}>
              <Grid item container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    multiline={true}
                    placeholder="이미지 캡션"
                    value={contentBlock.caption}
                    onChange={(e) => {
                      onChangeContentBlock({ ...contentBlock, caption: e.target.value })
                    }}
                  />
                </Grid>
                {contentBlock.type === ContentBlockTypeCode.VIDEO && (
                  <Grid item container xs={12} spacing={1}>
                    <Grid item>
                      <label>
                        <input
                          style={{ display: "none" }}
                          accept="image/*"
                          type="file"
                          onChange={async (event) => {
                            if (event.target.files && isNotEmpty(event.target.files)) {
                              const result = await upload(event.target.files, [
                                { key: "path", value: "resources/content" },
                              ])
                              onChangeContentBlock({ ...contentBlock, poster: [result.url] })
                            }
                            event.target.value = ""
                          }}
                        />
                        <Button component="span" size={"small"} variant={"outlined"}>
                          비디오 포스터 추가/변경
                        </Button>
                      </label>
                    </Grid>
                    {contentBlock.poster ? (
                      contentBlock.poster instanceof Array ? (
                        contentBlock.poster.map((src, index) => (
                          <Grid item container xs={12} justifyContent="center">
                            <Grid>
                              <img
                                key={index}
                                style={{ height: 100, objectFit: "contain" }}
                                src={src}
                                alt={`video-poster-${index}`}
                              />
                            </Grid>
                            <Button onClick={() => onChangeContentBlock({ ...contentBlock, poster: undefined })}>
                              포스터
                              <br />
                              제거
                            </Button>
                          </Grid>
                        ))
                      ) : (
                        <Grid item container xs={12} justifyContent="center">
                          <Grid>
                            <img
                              style={{ height: 100, objectFit: "contain" }}
                              src={contentBlock.poster}
                              alt={`video-poster`}
                            />
                          </Grid>
                          <Button onClick={() => onChangeContentBlock({ ...contentBlock, poster: undefined })}>
                            포스터
                            <br />
                            제거
                          </Button>
                        </Grid>
                      )
                    ) : null}
                  </Grid>
                )}
                <Grid item spacing={1}>
                  좌우여백 사용
                  <Switch
                    checked={contentBlock.margin}
                    onChange={(_, check) => {
                      onChangeContentBlock({ ...contentBlock, margin: check })
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }

      case ContentBlockTypeCode.DETAIL_MODEL: {
        return (
          <Grid item container xs={12}>
            {contentBlock.detailModelIds!.map((detailModelId) => {
              const detailModel = embedDetailModels?.find((detailModel) => detailModel.id === detailModelId)
              return detailModel ? (
                <Grid item container xs={12}>
                  {detailModel.thumbnail ? (
                    <Grid item xs={3}>
                      <img src={detailModel.thumbnail} alt={`${detailModel.title}-${detailModel.titleSecondary}`} />
                    </Grid>
                  ) : null}
                  <Grid item xs={9} alignItems="center">
                    <p>{detailModel.title}</p>
                    <p>{detailModel.titleSecondary}</p>
                  </Grid>
                </Grid>
              ) : (
                `모델정보가 없습니다: detailModelId ${detailModelId}`
              )
            })}
          </Grid>
        )
      }

      case ContentBlockTypeCode.DASH:
      case ContentBlockTypeCode.HR: {
        // 표시할게 없읍니다
        return null
      }
    }

    return (
      <Grid item style={{ wordBreak: "break-all" }}>
        {JSON.stringify(contentBlock)}
      </Grid>
    )
  }, [contentBlock, embedDetailModels, onChangeContentBlock, openSearchArticlesPopup, openSearchQuestionsPopup])

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item fontWeight={700}>
          {ARTICLE_CONTENT_BLOCK_TITLES[contentBlock.type]}
        </Grid>
        <Grid>
          <Button size="small" onClick={() => setOpenDeleteConfirmPopup(true)}>
            삭제
          </Button>
        </Grid>
      </Grid>
      {renderTextField()}
      <DeleteConfirmPopup
        open={openDeleteConfirmPopup}
        handleClose={() => setOpenDeleteConfirmPopup(false)}
        handleConfirm={() => {
          onDeleteContentBlock()
          setOpenDeleteConfirmPopup(false)
        }}
      />
    </Box>
  )
}

const DND_KEY = "ARTICLE_CONTENT_BLOCK"
const ArticleContentBlockWrapper: FunctionComponent<
  PropsWithChildren<{
    index: number
    onChangeOrder: (dragIndex: number, nextIndex: number) => void
  }>
> = (props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [, drag] = useDrag({
    type: DND_KEY,
    item: () => ({ index: props.index }),
  })

  const [{ handlerId }, drop] = useDrop<{ index: number }, void, { handlerId: Identifier | null }>({
    accept: DND_KEY,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: { index: number }, monitor) => {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = props.index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      props.onChangeOrder(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  drag(drop(ref))

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <Box sx={{ p: 2, border: "1px dashed grey", marginBottom: 1 }}>{props.children}</Box>
    </div>
  )
}
