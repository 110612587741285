import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import PointScheduleListTableHeader from "./TableHeader"
import PointScheduleListTableRow from "./TableBody/TableRow"
import { PointScheduleListItemType } from "types/PointScheduleListItemType"

type Props = {
  pointScheduleList: PointScheduleListItemType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
  onClickTableRow: (pointSchedule: PointScheduleListItemType) => void
}

const PointScheduleListTable = ({ pointScheduleList, paginationParams, changePage, onClickTableRow }: Props) => {
  return (
    <ListTable>
      <PointScheduleListTableHeader />
      <TableBody>
        {pointScheduleList.map((point) => (
          <PointScheduleListTableRow key={point.id} point={point} onClick={() => onClickTableRow(point)} />
        ))}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default PointScheduleListTable
