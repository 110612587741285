import { httpGet, httpGetToBlob, httpPost, httpPostMultipartFile } from "../utils/httpAPI"
import { PageType } from "types/PageType"
import { OfficialModelPriceType } from "../types/OfficialModelPriceType"

export const getOfficialModelPriceList: (searchParams: any) => Promise<PageType<OfficialModelPriceType>> = (searchParams) =>
  httpGet("/official-model-price/list", searchParams)
export const uploadOfficialModelPriceList: (file: FileList) => Promise<any> = (file) =>
  httpPostMultipartFile("/official-model-price/upload", file)
export const downloadOfficialModelPriceList: (searchParams: any) => Promise<Blob> = (searchParams) =>
  httpGetToBlob("/official-model-price/download", searchParams)
export const applyOfficialModelPriceList: (req: any) => Promise<any> = (req) =>
  httpPost("/official-model-price/apply", req)
export const deleteOfficialModelPriceList: (req: any) => Promise<any> = (req) =>
  httpPost("/official-model-price/delete", req)
