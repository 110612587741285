import styled from "@emotion/styled"
import Button from "components/Button"
import { SerialNumberSearchBar } from "pages/SerialNumber/SerialNumberSearchPage/SerialNumberSearchBar"
import { useSerialNumberList } from "pages/SerialNumber/SerialNumberSearchPage/useSerialNumberList"
import { useState } from "react"
import { SerialNumberDetailModal } from "../../../components/SerialNumber/SerialNumberDetailModal"
import { SerialNumberRegisterModal } from "../SerialNumberRegisterModal"
import { SerialNumberListTable } from "./SerialNumberListTable"

export const SerialNumberSearchPage = () => {
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<string>()
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState<boolean>(false)

  const selectSerialRow = (serialNumber: string) => {
    setSelectedSerialNumber(serialNumber)
  }

  const {
    changeSearchFilterValue,
    changePageNumber,
    changeSearchKeyword,
    onSearch,
    onRefresh,
    serialList,
    paginationParams,
    searchParams,
  } = useSerialNumberList()

  return (
    <>
      {!!selectedSerialNumber && (
        <SerialNumberDetailModal
          serialNumber={selectedSerialNumber}
          closeModal={() => setSelectedSerialNumber(undefined)}
        />
      )}
      {isOpenRegisterModal && (
        <SerialNumberRegisterModal
          closeModal={() => {
            onRefresh()
            setIsOpenRegisterModal(false)
          }}
        />
      )}
      <PageHeaderWrapper>
        <InlineWrapper>
          <SerialNumberSearchBar
            searchParams={searchParams}
            changeSearchFilterValue={changeSearchFilterValue}
            changeSearchKeyword={changeSearchKeyword}
            onSearch={onSearch}
          />
          <ButtonWrapper>
            <Button onClick={() => setIsOpenRegisterModal(true)}>직접 등록</Button>
          </ButtonWrapper>
        </InlineWrapper>
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <SerialNumberListTable
          serialNumberList={serialList}
          changePage={changePageNumber}
          paginationParams={paginationParams}
          selectSerialRow={selectSerialRow}
        />
      </PageBodyWrapper>
    </>
  )
}

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const PageBodyWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const InlineWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ButtonWrapper = styled.div`
  width: 120px;
`
