import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import SearchInput from "../Search/SearchBar/SearchInput"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { UserStatusType, UserType } from "../../types/UserType"
import { PageType } from "../../types/PageType"
import { getUserList } from "../../apis/userAPI"
import SearchBar from "../Search/SearchBar"

export type SearchUserStateType = {
  searchFilter: string
  searchKeyword: string
  selectedIds: number[]
  userList: PageType<UserType>
  refresh: boolean
  loading: boolean
  page: number
  pageSize: number
}

const initialState: SearchUserStateType = {
  searchFilter: "id",
  searchKeyword: "",
  selectedIds: [],
  userList: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  refresh: false,
  loading: false,
  page: 0,
  pageSize: 25,
}

const searchMenuList: { value: string; title: string }[] = [
  { value: "id", title: "사용자ID" },
  { value: "name", title: "사용자명" },
  { value: "nickname", title: "닉네임" },
  { value: "phone", title: "핸드폰 번호" },
  { value: "email", title: "이메일" },
]

function createColumns(): GridColDef[] {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "name",
      headerName: "사용자명",
      width: 100,
    },
    {
      field: "nickname",
      headerName: "닉네임",
      width: 100,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "핸드폰 번호",
      width: 100,
    },
    {
      field: "email",
      headerName: "이메일",
      flex: 1,
      width: 150,
    },
    {
      field: "status",
      headerName: "상태",
      width: 100,
    },
    {
      field: "authType",
      headerName: "가입 경로",
      width: 100,
    },
  ]
}

const UserSelectPopup: React.FunctionComponent<{
  isMultiSelectable?: boolean
  filterStatus?: UserStatusType[]
  open: boolean
  setUsers: (user: UserType[]) => void
  close: () => void
}> = ({ isMultiSelectable, filterStatus, open, setUsers, close }) => {
  const [state, setState] = useState<SearchUserStateType>(initialState)

  const fetchUserSimpleList = () => {
    getUserList({
      status: filterStatus?.join(""),
      page: state.page,
      size: state.pageSize,
      ...(state.searchKeyword ? { [state.searchFilter]: state.searchKeyword } : {}),
    }).then((results) => {
      setState((prevState) => ({
        ...prevState,
        userList: results,
      }))
    })
  }

  useEffect(() => {
    if (!open) {
      setState(initialState)
      return
    }

    fetchUserSimpleList()
  }, [open, state.refresh, state.page, state.pageSize])

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <TitleWrapper>
        <DialogTitle>사용자 선택</DialogTitle>

        <UserSearchBar>
          <SearchBar>
            <FormControl style={{ minWidth: "150px" }}>
              <Select
                size={"small"}
                defaultValue={state.searchFilter}
                required
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    searchFilter: e.target.value,
                    searchKeyword: "",
                  }))
                }}
                MenuProps={{
                  style: { zIndex: 20000 },
                }}
              >
                {searchMenuList.map((menu) => (
                  <MenuItem value={menu.value.toString()} key={menu.value}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <SearchInput
              value={state.searchKeyword}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  searchKeyword: e.target.value,
                }))
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setState((prevState) => ({
                    ...prevState,
                    refresh: !prevState.refresh,
                  }))
                }
              }}
            />
          </SearchBar>
        </UserSearchBar>
      </TitleWrapper>
      <DialogContent style={{ width: "900px" }}>
        <Stack direction={"column"} spacing={1}>
          <Box height={"500px"}>
            <DataGrid
              checkboxSelection={isMultiSelectable}
              pagination
              paginationMode="server"
              page={state.page}
              pageSize={state.pageSize}
              rowCount={state.userList.totalElements ?? 0}
              rows={state.userList.content ?? []}
              loading={state.loading}
              onPageChange={(page) => {
                setState((prevState) => ({
                  ...prevState,
                  page: page,
                }))
              }}
              onPageSizeChange={(size) => {
                setState((prevState) => ({
                  ...prevState,
                  pageSize: size,
                }))
              }}
              style={{ width: "100%", height: "100%" }}
              columns={createColumns()}
              getRowHeight={() => 100}
              onSelectionModelChange={(ids: any) =>
                setState((prevState) => ({
                  ...prevState,
                  selectedIds: ids,
                }))
              }
              keepNonExistentRowsSelected
              // onSortModelChange={(model) => {
              //   changeSortOption(`${model[0].field},${model[0].sort}`)
              // }}
              getRowId={(row) => row.id}
              selectionModel={state.selectedIds}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          size={"small"}
          color={"primary"}
          onClick={async () => {
            const selectionUsers = state.userList.content.filter((user) => state.selectedIds.includes(user.id))

            setUsers(selectionUsers)
            close()
          }}
        >
          확인
        </Button>
        <Button variant={"contained"} size={"small"} color={"error"} onClick={() => close()}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserSelectPopup

const TitleWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const UserSearchBar = styled.div`
  padding-right: 24px;
`
