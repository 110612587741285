import {DataGrid} from "@mui/x-data-grid";
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";
import {useEffect, useState} from "react";
import {PageType} from "../types/PageType";
import _ from 'lodash'
import {GridSelectionModel} from "@mui/x-data-grid/models/gridSelectionModel";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";

const PageableDataGrid = <T, R>({columns, fetch, searchParams, mapRows, refreshCounter, checkboxSelection, onSelectionModelChange}: {
    columns: GridColumns;
    fetch: (params: any) => Promise<PageType<T>>;
    mapRows?: ((row: T) => any) | null;
    searchParams: any;
    refreshCounter?: number | null;
    checkboxSelection?: boolean | null;
    onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
}) => {
    const [pageRes, setPageRes] = useState<PageType<T>>()
    const [pageable, setPageable] = useState({page: 0, size: 50})

    useEffect(() => {
        fetch({...searchParams, ...pageable}).then(p => setPageRes(p))
    }, [pageable, searchParams, refreshCounter])


    function handleContents(rows: T[]): any[] {
        return rows
            .map(row => mapRows ? mapRows?.(row) : row)
            .map(row => {
            const names = columns.map(c => c.field)
            const values = columns.map(c => _.get(row, c.field))

            return _.zipObject(names, values)
        })
    }

    return (
        <DataGrid
            columns={columns}
            rows={handleContents(pageRes?.content ?? [])}
            pagination
            paginationMode={"server"}
            onPageChange={page => setPageable({...pageable, page: page})}
            onPageSizeChange={size => setPageable({...pageable, size: size})}
            page={pageable.page}
            pageSize={pageable.size}
            rowCount={pageRes?.totalElements ?? 0}
            checkboxSelection={checkboxSelection ?? false}
            onSelectionModelChange={onSelectionModelChange}
        />)
}

export default PageableDataGrid