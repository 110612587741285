import {httpDelete, httpGet, httpPost} from "../utils/httpAPI";
import {ShippingRequestResultType, ShippingRequestType, ShippingType, UpdateTrackingNoRequest} from "types/ShippingType";
import {PageType} from "types/PageType";
import {CreateUserMemoType, UserMemoType} from "../types/UserType";
import {CountType} from "../types/CountType";

export const getUserMemos: (searchParams?: any | null) => Promise<PageType<UserMemoType>> = (searchParams) => httpGet("/user-memos", searchParams)
export const countUserMemoByStatus: (searchParams?: any | null) => Promise<CountType[]> = (searchParams) => httpGet("/user-memos/counts", searchParams)

export const createUserMemo: (memo: CreateUserMemoType) => Promise<UserMemoType> = (memo) => httpPost("/user-memos", memo)
export const deleteUserMemo: (id: number) => Promise<void> = (id) => httpDelete(`/user-memos/${id}`)