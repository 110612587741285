import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { UpgradeServiceItemType } from "types/UpgradeServiceOrderType"
import { numberWithCommas } from "utils/NumberUtils"
import { headerItemList } from "../../TableHeader"
import CheckBox from "components/Checkbox"

type Props = {
  upgradeItem: UpgradeServiceItemType
  isChecked: boolean
  onSelect: () => void
} & HTMLAttributes<HTMLDivElement>

const UpgradeServiceListTableRow = ({ isChecked, upgradeItem, onSelect, ...props }: PropsWithChildren<Props>) => {
  return (
    <TableRow {...props}>
      <TableRowItem
        style={{ maxWidth: headerItemList[0].width }}
        onClick={(e) => {
          onSelect()
          e.stopPropagation()
        }}
      >
        <CheckBox readOnly checked={isChecked} />
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>{upgradeItem.label}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>{upgradeItem.feeType}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[3].width }}>{numberWithCommas(upgradeItem.fee)} 원</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[4].width }}>{upgradeItem.note || ""}</TableRowItem>
    </TableRow>
  )
}

export default UpgradeServiceListTableRow
