import { Button, Switch, TextField } from "@mui/material"

import MessageIcon from "@mui/icons-material/Message"
import styled from "@emotion/styled"
import { DateTimePicker } from "@mui/lab"

import { useProductDetailTextFormData } from "./useProductDetailTextFormData"
import { ProductDetailTextListItemType } from "types/ProductDetailTextListItemType"
import React, { useCallback, useMemo } from "react"
import { toast } from "react-toastify"
import { isNil } from "utils/validationUtils"
import CheckBox from "../../../../components/Checkbox"

type Props = {
  listItem?: ProductDetailTextListItemType
  refreshList: () => void
}

export const ProductDetailTextBannerForm = ({ listItem, refreshList }: Props) => {
  const {
    id,
    isDisplayed,
    setIsDisplayed,
    text,
    setText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    linkUrl,
    validateForm,
    setLinkUrl,
    resgisterProductDetailText,
    editProductDetailText,
    removeProductDetailText,
    toggleProductDetailText,
    showDomesticUser,
    setShowDomesticUser,
    showForeignUser,
    setShowForeignUser
  } = useProductDetailTextFormData(listItem)

  const isNewForm = useMemo(() => isNil(listItem), [listItem])

  const handleRegisterProductDetailText = useCallback(async () => {
    if (!validateForm()) return

    try {
      await resgisterProductDetailText()
      refreshList()
      toast.success("프로모션 텍스트가 등록되었습니다.")
    } catch {
      toast.error("프로모션 텍스트 등록에 실패했습니다.")
    }
  }, [refreshList, resgisterProductDetailText, validateForm])

  const handleEditProductDetailText = useCallback(async () => {
    if (!validateForm()) return

    try {
      await editProductDetailText()
      refreshList()
      toast.success("프로모션 텍스트가 수정되었습니다.")
    } catch {
      toast.error("프로모션 텍스트 수정에 실패했습니다.")
    }
  }, [refreshList, editProductDetailText, validateForm])

  const handleDeleteProductDetailText = useCallback(async () => {
    if (isNil(listItem)) return

    try {
      await removeProductDetailText(listItem.id)
      refreshList()
    } catch {
      toast.error("프로모션 텍스트 삭제에 실패했습니다.")
    }
  }, [listItem, removeProductDetailText, refreshList])

  const handleToggleProductDetailText = useCallback(
    async (status: boolean) => {
      if (isNil(listItem)) {
        setIsDisplayed(status)
      } else {
        try {
          await toggleProductDetailText(listItem.id, status)
          toast.success("프로모션 노출 상태를 변경했습니다.")
        } catch {
          toast.success("프로모션 노출 상태를 변경에 실패했습니다.")
        }
      }
    },
    [toggleProductDetailText, listItem, setIsDisplayed]
  )

  return (
    <Wrapper>
    <Row>
      <Switch checked={isDisplayed} onChange={(event) => handleToggleProductDetailText(event.target.checked)} />
      <MessageIcon sx={{ mr: 1 }} />
      <Label>프로모션</Label>
      <TextField
        sx={{ width: 200 }}
        size="small"
        multiline
        value={text}
        placeholder="텍스트 입력"
        onChange={(e) => {
          setText(e.target.value)
        }}
      />
      <Label>링크 URL</Label>
      <TextField
        sx={{ width: 200 }}
        multiline
        size="small"
        value={linkUrl}
        onChange={(e) => {
          setLinkUrl(e.target.value)
        }}
      />
      <Label>노출 기간</Label>
      <DateTimePicker
        value={startDate}
        inputFormat={"yyyy-MM-dd HH:mm"}
        onChange={(value) => setStartDate(value)}
        renderInput={(params) => <TextField size="small" {...params} placeholder="시작일" />}
      />
      <Label>-</Label>
      <DateTimePicker
        value={endDate}
        inputFormat={"yyyy-MM-dd HH:mm"}
        onChange={(value) => setEndDate(value)}
        renderInput={(params) => <TextField size="small" {...params} />}
      />
      <ButtonWrapper>
        <Button variant="outlined" onClick={isNewForm ? handleRegisterProductDetailText : handleEditProductDetailText}>
          {isNewForm ? "등록" : "수정"}
        </Button>
      </ButtonWrapper>
      {!isNewForm && (
        <ButtonWrapper>
          <Button variant="outlined" color="error" onClick={handleDeleteProductDetailText}>
            삭제
          </Button>
        </ButtonWrapper>
      )}
    </Row>
    <Row>
      <LabelShow>노출</LabelShow>
      <CheckBox checked={showDomesticUser}   id={`showDomesticUser${id}`}   onChange={() => setShowDomesticUser(!showDomesticUser) } />
      <CheckBoxLabel htmlFor={`showDomesticUser${id}`}  >
        내국인 회원 (국내거주 외국인 포함)
      </CheckBoxLabel>
      <CheckBox checked={showForeignUser}   id={`showForeignUser${id}`} onChange={() => setShowForeignUser(!showForeignUser) } />
      <CheckBoxLabel htmlFor={`showForeignUser${id}`}>
        외국인 회원
      </CheckBoxLabel>
    </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
`
const Row = styled.div`
    display: flex;  
    padding-top: 20px;
    width: 100%;  
`

const Label = styled.div`
  margin: 0 10px;
  font-size: 14px;
`

const LabelShow = styled.div`
  padding-left: 100px;
  padding-right: 40px;
  font-size: 14px;
`

const ButtonWrapper = styled.div`
  margin: 0 4px;
`
const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`