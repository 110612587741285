import { ModelType } from "types/ModelType"
import { useModelManagerStore } from "../../../ModelDbMananer/useModelManagerStore"
import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import { ModelManagerBrandChips } from "../../../ModelDbMananer/ModelManagerBrandChips"
import { ModelManagerSearchBar } from "../../../ModelDbMananer/ModelManagerSearchBar"
import { CurationSelectModelListTable } from "./CurationSelectModelListTable"
import styled from "@emotion/styled"
import _ from "lodash"

const ModelSelectPopup: React.FunctionComponent<{
  open: boolean
  handleClose: (modelIds: ModelType[]) => void
}> = ({open, handleClose}) => {
  const {
    brandList,
    modelListParams,
    modelList,
    fetchBrandList,
    fetchModelList,
    changeBrandSid,
    changePage,
    changeQuery,
    changeDisplay,
  } = useModelManagerStore()

  const [selectionModelIds, setSelectionModelIds] = useState<ModelType["id"][]>([])

  useEffect(() => {
    fetchBrandList()
    fetchModelList(modelListParams)
  }, [fetchBrandList, fetchModelList, modelListParams])

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <DialogTitle>모델 선택</DialogTitle>
      <DialogContent style={{width: "1100px"}}>
          <Stack direction={"row"} spacing={1}>
            <div style={{ margin: "4px 24px" }}>
              <ModelManagerBrandChips
                brands={brandList}
                selectedBrandSid={modelListParams.brandSid}
                onChangeBrandSid={changeBrandSid}
              />
            </div>
          </Stack>
        <Stack>
          <StyledDivModelListTable>
            <InlineWrapper>
              <ModelManagerSearchBar query={modelListParams.name} onChangeQuery={changeQuery} />
            </InlineWrapper>

            <CurationSelectModelListTable
              rows={modelList}
              setSelectionModelIds={setSelectionModelIds}
              selectedId={selectionModelIds}
            />
          </StyledDivModelListTable>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          size={"small"}
          color={"primary"}
          onClick={() => {
            const selectModels: ModelType[] = selectionModelIds
              .map(id => _.find(modelList.content, {id: id}))
              .filter(model => model)
              .map(model => model as ModelType)

            handleClose(selectModels)
          }}
        >
          확인
        </Button>
        <Button variant={"contained"} size={"small"} color={"error"} onClick={() => handleClose([])}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModelSelectPopup

const StyledDivModelListTable = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`

const ButtonWrapper = styled.div`
  width: 120px;
`
