import {Button, Grid} from "@mui/material";
import React from "react";
import ShowroomTabTable from "./ShowroomTable";
import {ShowroomType} from "types/ShowroomType";
import {ShowroomState} from "../index";
import {deleteShowroom, getShowrooms, hideShowroom, showShowroom} from "../../../apis/showroomAPI";
import {ShowroomPageActionCode} from "../showroomPageActionCode";
import _ from "lodash"

type ShowroomTabProps = {
    state: ShowroomState,
    dispatch: any
}

const ShowroomTab: React.FC<ShowroomTabProps> = ({dispatch, state}) => {
    function handleShowButtonClick() {
        Promise.all(state.selectionShowroomIds.map((id) => showShowroom(id))).then((results) => {
            dispatch({
                type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: state.showrooms.map((showroom) => {
                    let updated = _.find(results, {id: showroom.id})
                    if (updated) return {...showroom, onDisplay: updated.onDisplay}
                    return showroom
                })
            })
        })
    }

    function handleHideButtonClick() {
        Promise.all(state.selectionShowroomIds.map((id) => hideShowroom(id))).then((results) => {
            dispatch({
                type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: state.showrooms.map((showroom) => {
                    let updated = _.find(results, {id: showroom.id})
                    if (updated) return {...showroom, onDisplay: updated.onDisplay}
                    return showroom
                })
            })
        })
    }

    function handleAddButtonClick() {
        dispatch({type: ShowroomPageActionCode.OPEN_ADD_SHOWROOM_POPUP})
    }

    function handleDeleteButtonClick() {
        Promise.all(state.selectionShowroomIds.map((id) => deleteShowroom(id))).then(() => {
            getShowrooms().then((results) => {
                dispatch({type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: results})
            })
        })
    }

    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleShowButtonClick}>노출</Button></Grid>
                <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleHideButtonClick}>숨김</Button></Grid>

                <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleAddButtonClick}>추가</Button></Grid>
                <Grid item xs={1}><Button variant={"contained"} size={"small"} color={"error"} fullWidth onClick={handleDeleteButtonClick} >삭제</Button></Grid>
            </Grid>
            <Grid item xs={12}><ShowroomTabTable state={state} dispatch={dispatch} /></Grid>
        </Grid>
    )
}

export default ShowroomTab