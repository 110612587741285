import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {DateTime} from "luxon";
import * as React from "react";
import {useEffect, useState} from "react";
import {ShopHeroBannerType} from "types/ShopHeroBannerType";
import ShopContentsAPI from "../../../apis/shopHeroBannerAPI";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  MuiEvent,
} from "@mui/x-data-grid"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import _ from 'lodash'

function renderPreviewCell(params: any) {
    return (<Box style={{position: 'relative', height: 100, width: 50, paddingTop: 20}}>
        <img src={params?.row?.imageUrl} style={{width: '100%', position: 'absolute'}} />
    </Box>)
}

function renderStatusCell(params: any) {
    if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
    const startedAt = DateTime.fromISO(params?.row?.startedAt)
    const endedAt = DateTime.fromISO(params?.row?.endedAt)

    return (
      <Stack direction={'column'} alignItems={"self-end"}>
        <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
        <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      </Stack>
    )
}


// @ts-ignore
const BannerCreatePopup = ({open, handleClose}) => {
    const [rows, setRows] = useState<ShopHeroBannerType[]>([])

    useEffect(() => {
        if (open) {
            const now = DateTime.now().toISO()
            ShopContentsAPI.getList({onDisplay: true, endedAtGoe: now, sort: "priority,asc"}).then(result => setRows(result))
        }
    }, [open])

  const handleSubmit = () => {
    ShopContentsAPI.reorderingByDirect(rows).then((result) => {
        //setRows(result)
        handleClose()
      }
    )
  }

    const getColumns = () => {
        return [
            { field: "id", headerName: "ID", width: 80 },
            {field: 'description', headerName: "설명", flex: 3},
            {field: 'createdAt', headerName: "등록 일시", width: 180, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
            {field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
            {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
            {field: 'postingStatus', headerName: "진행 상태", width: 100, renderCell: renderStatusCell},
            {field: 'showDomesticUser',  width: 100,
              renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                  <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
                </div>
              ),
              renderHeader: () => (
                <div style={{  textAlign: "center", lineHeight: "1.2" }}>
                  내국인 회원<br />노출
                </div>
              ),
            },
            { field: "showForeignUser", width: 100,
              renderCell: (params: any) => (
                <div
                  style={{ textAlign: "center"  }}
                >
                  <span>{params.row.showForeignUser ? "Y" : "N"}</span>
                </div>
              ),
              renderHeader: () => (
                <div style={{ textAlign: "center", lineHeight: "1.2" }}>
                  외국인 회원<br />노출
                </div>
              ),
            },
            {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
            {field: 'actions', headerName: "순서(위치이동)", type: 'actions',  width: 110, getActions: (params: any) => ([
                    <GridActionsCellItem
                        icon={<ArrowUpwardIcon/>}
                        label={"위로"}
                        onClick={() => {
                            const index = _.findIndex(rows, {id: params.row.id})
                            if (index > 0) {
                              let updatedRows = [
                                ...(_.slice(rows, 0, index-1)),
                                rows[index],
                                rows[index-1],
                                ...(_.slice(rows, index+1, rows.length)),
                              ]

                              updatedRows = updatedRows.map(data => ({
                                ...data,
                                priority: null
                              })) ;

                              setRows(updatedRows);
                            }
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<ArrowDownwardIcon/>}
                        label={"아래로"}
                        onClick={() => {
                            const index = _.findIndex(rows, {id: params.row.id})
                            if (index < rows.length-1) {
                              let updatedRows = [
                                ...(_.slice(rows, 0, index)),
                                rows[index+1],
                                rows[index],
                                ...(_.slice(rows, index+2, rows.length)),
                              ]

                              updatedRows = updatedRows.map(data => ({
                                ...data,
                                priority: null
                              })) ;

                              setRows(updatedRows);
                            }
                        }}
                    />
                ])},
        ]
    }

    return (
        <Dialog open={open} maxWidth={"lg"} fullWidth>
            <DialogTitle>배너 순서 변경</DialogTitle>
            <DialogContent >
                <Box height={700}>
                    <DataGrid
                        columns={getColumns()}
                        rows={rows}
                        getRowHeight={() => 100}
                        hideFooter={true}
                        onCellEditCommit={(params) => {
                          const updatedRows = rows.map(row => {
                            if (row.id === params.id) {
                              return { ...row, priority: params.value };
                            }
                            return row;
                          });
                          setRows(updatedRows);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BannerCreatePopup