import { changeFaqOrder, getFaqCategoryList, getFaqList } from "apis/faqAPI"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { FaqCategoryType, FaqType } from "types/FaqType"

export const useFaqData = () => {
  const [categoryList, setCategoryList] = useState<FaqCategoryType[]>([])
  const [selectedCategory, setSelectedCategory] = useState<FaqCategoryType>("공통")
  const [faqList, setFaqList] = useState<FaqType[]>([])
  const [selectedFaq, setSelectedFaq] = useState<FaqType | undefined>()

  useMount(() => {
    fetchFaqCategoryList()
    fetchFaqListByCategory(selectedCategory)
  })

  const fetchFaqCategoryList = useCallback(async () => {
    const response = await getFaqCategoryList()
    setCategoryList(response)
  }, [])

  const fetchFaqListByCategory = useCallback(async (selectedCategory: FaqCategoryType) => {
    try {
      const { content } = await getFaqList({
        type: selectedCategory,
      })
      setFaqList(content)
    } catch (error) {
      toast.error("FAQ 리스트를 불러오는데 실패했습니다.")
    }
  }, [])

  const selectCategory = (category: FaqCategoryType) => {
    setSelectedCategory(category)
    fetchFaqListByCategory(category)
    setSelectedFaq(undefined)
  }

  const selectFaq = (faq: FaqType | undefined) => {
    setSelectedFaq(faq)
  }

  const refreshList = useCallback(async (modifiedFaq?: FaqType) => {
    try {
      const { content } = await getFaqList({
        type: modifiedFaq?.type || "공통",
      })
      setFaqList(content)
      setSelectedCategory(modifiedFaq?.type || "공통")
      setSelectedFaq(modifiedFaq)
    } catch (error) {
      toast.error("FAQ 리스트를 불러오는데 실패했습니다.")
    }
  }, [])

  const onChangeOrder = useCallback(
    async (prevPrioirty: number, newPriority: number) => {
      const newList = [...faqList]

      const changingFaq = newList[prevPrioirty]
      newList[prevPrioirty] = newList[newPriority]
      newList[newPriority] = changingFaq

      const changedFaqList = await changeFaqOrder(
        selectedCategory,
        newList.map((faq) => ({ id: faq.id }))
      )

      setFaqList(changedFaqList)
    },
    [faqList, selectedCategory]
  )

  return { categoryList, selectedCategory, selectCategory, faqList, selectFaq, selectedFaq, refreshList, onChangeOrder }
}
