import { FunctionComponent, useEffect, useState } from "react"
import { parseMarkdownToHtml } from "utils/markdownUtils"
import { NoticeDetailFormMarkdownHtmlPreview } from "./NoticeDetailFormMarkdownHtmlPreview"
import { NoticeDetailFormMarkdownEditor } from "./NoticeDetailFormMarkdownEditor"

export const NoticeDetailFormMarkdown: FunctionComponent<{
  mode?: "default" | "diff"
  initialMarkdown: string
  onChangeMarkdown: (markdown: string) => void
}> = ({ mode = "default", initialMarkdown, onChangeMarkdown }) => {
  const [html, setHtml] = useState("")

  useEffect(() => {
    setHtml(parseMarkdownToHtml(initialMarkdown))
  }, [initialMarkdown])

  return (
    <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <NoticeDetailFormMarkdownEditor
          mode={mode}
          initialMarkdown={initialMarkdown}
          onChangeMarkdown={(markdown: string) => {
            onChangeMarkdown(markdown)
            setHtml(parseMarkdownToHtml(markdown))
          }}
        />
      </div>
      <div style={{ width: 375, background: "red" }}>
        <NoticeDetailFormMarkdownHtmlPreview html={html} />
      </div>
    </div>
  )
}
