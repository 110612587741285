import React, {useEffect, useState} from "react";
import {ShowcaseType} from "types/ShowcaseType";
import {useLocation, useNavigate} from "react-router-dom";
import {getShowcase, updateShowcase} from "../../../apis/showcaseAPI";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack
} from "@mui/material";
import {AddCircle, AddCircleOutline, ArrowCircleDown, ArrowCircleUp, ClearOutlined, Image} from "@mui/icons-material";
import {changeAt, changeItem, insertAt} from "../../../utils/collectionUtils";
import _ from "lodash";
import SearchDetailModelPopup from "../../../components/Popup/SearchDetailModelPopup";
import {DetailModelType} from "types/DetailModelType";

const initShowcase: ShowcaseType = {
    createdAt: undefined,
    createdBy: undefined,
    description: undefined,
    name: undefined,
    onDisplay: false,
    detailModelCount: undefined,
    showroomId: undefined,
    thumbnail: undefined,
    updatedAt: undefined,
    updatedBy: undefined
}

// @ts-ignore
type ProductBlockProps = {
    detailModel: DetailModelType,
    index: number,
    handleMove: Function,
    handleRemove: Function,
    handleAdd: Function,
    handleGallery: Function
}
const ProductBlock: React.FC<ProductBlockProps> = ({
                          detailModel,
                          index,
                          handleMove,
                          handleRemove,
                          handleAdd,
                          handleGallery
                      }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    // @ts-ignore
    const handleOpenAddMenu = (event) => {
        setAnchorEl(event.target)
    }
    const handleCloseAddMenu = () => {
        setAnchorEl(null)
    }

    return (
        <Box>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid container item xs={12} justifyContent="center" alignItems="center" >
                    <Card variant={"outlined"} style={{width: '400px'}}>
                        <CardHeader
                            subheader={`[${index+1}] ${detailModel.name} ${detailModel.refNo}`}
                            action={<IconButton size={"small"} onClick={() => handleRemove(detailModel)}><ClearOutlined /></IconButton>}
                        />
                        <CardContent style={{borderTop: 'solid', color: 'rgb(0,0,0,0.1)'}}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Stack justifyContent={"center"} alignItems={"center"}>
                                        <img src={detailModel.thumbnail as string}  height={'150px'}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack direction="column"
                                           justifyContent="center"
                                           alignItems="center"
                                           height={"100%"}
                                    >
                                        <IconButton onClick={() => handleGallery(detailModel)} ><Image /></IconButton>
                                        <IconButton onClick={() => handleMove(index, index-1)} disabled={index == 0} ><ArrowCircleUp /></IconButton>
                                        <IconButton onClick={handleOpenAddMenu}><AddCircleOutline /></IconButton>
                                        <IconButton onClick={() => handleMove(index, index + 1)}><ArrowCircleDown/></IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={!!anchorEl}
                                            onClose={handleCloseAddMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => {
                                                handleAdd(index, 'UP')
                                                setAnchorEl(null)
                                            }}>해당 상세 모델 위로 추가</MenuItem>
                                            <MenuItem onClick={() => {
                                                handleAdd(index, 'REPLACE')
                                                setAnchorEl(null)
                                            }}>해당 상세 모델과 변경</MenuItem>
                                            <MenuItem onClick={() => {
                                                handleAdd(index, 'DOWN')
                                                setAnchorEl(null)
                                            }}>해당 상세 모델 아래로 추가</MenuItem>
                                        </Menu>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>)
}

const DisplayDetailModelManagement = () => {
    const [newShowcase, setNewShowcase] = useState(initShowcase)
    const [originShowcase, setOriginShowcase] = useState(initShowcase)
    const [isChanged, setIsChanged] = useState(false)

    const [openSearchDetailModelPopup, setOpenSearchDetailModelPopup] = useState(false)
    const [addAction, setAddAction] = useState(null)
    const {search} = useLocation()
    const navigate = useNavigate()

    const fetchShowcase = () => {
        // @ts-ignore
        let showcaseId: number = new URLSearchParams(search).get('showcaseId')
        getShowcase(showcaseId).then((s) => {
            setNewShowcase(s)
            setOriginShowcase(s)
        })
    }

    useEffect(() => {
        fetchShowcase()
    }, [])

    useEffect(() => {
        setIsChanged(!_.isEqual(originShowcase, newShowcase))
    }, [newShowcase.detailModels])

    const handleRemove = (detailModel: DetailModelType) => {
        setNewShowcase({...newShowcase, detailModels: _.without(newShowcase.detailModels, detailModel)})
    }

    const handleMove = (beforeIndex: number, requestIndex: number) => {
        let afterIndex

        if (requestIndex < 0) afterIndex = 0
        // @ts-ignore
        else if(requestIndex >= newShowcase.detailModels.length) afterIndex = newShowcase.detailModels.length - 1
        else afterIndex = requestIndex

        // @ts-ignore
        setNewShowcase({...newShowcase, detailModels: changeItem(newShowcase.detailModels, beforeIndex, afterIndex)})
    }

    const handleAdd = (index: number, type?: string) => {
        // @ts-ignore
        setAddAction({index: index, type: type})
        setOpenSearchDetailModelPopup(true)
    }


    const handleSearchDetailModelPopup = (detailModel?: DetailModelType|null) => {
        if (detailModel && addAction) {
            // @ts-ignore
            switch (addAction.type) {
                case 'UP':
                    // @ts-ignore
                    setNewShowcase({...newShowcase, detailModels: insertAt(newShowcase.detailModels, addAction.index - 1, detailModel)})
                    break
                case 'DOWN':
                    // @ts-ignore
                    setNewShowcase({...newShowcase, detailModels: insertAt(newShowcase.detailModels, addAction.index + 1, detailModel)})
                    break
                case 'REPLACE':
                    // @ts-ignore
                    setNewShowcase({...newShowcase, detailModels: changeAt(newShowcase.detailModels, addAction.index, detailModel)})
                    break
                default:
                    // @ts-ignore
                    setNewShowcase({...newShowcase, detailModels: insertAt(newShowcase.detailModels, 0, detailModel)})
                    break
            }

        }

        setOpenSearchDetailModelPopup(false)
    }

    const handleSave = () => {
        // @ts-ignore
        updateShowcase(newShowcase.id, newShowcase).then(() => {
            fetchShowcase()
        })
    }

    const handleGallery = (detailModel: DetailModelType) => navigate(`/detail-models/${detailModel.id}/gallery`)

    return(
        <Grid container spacing={2}>
            <SearchDetailModelPopup open={openSearchDetailModelPopup} handleClose={(detailModel?: DetailModelType|null) => {handleSearchDetailModelPopup(detailModel)}}/>
            <Grid item xs={12}>
                <Card style={{width: '100%'}}>
                    <CardHeader
                        title={`${newShowcase.name} 노출 상세 모델 편집`}
                        subheader={`현재 노출중인 상세 모델이 ${newShowcase?.detailModels?.length ? newShowcase?.detailModels?.length : 0}개 있습니다.`}
                    />
                    <CardContent sx={{p: 2, display: "flex", flexDirection: "column", width: '100%', height: '600px', overflow: 'auto'}}>
                        <Stack spacing={1}>
                            { newShowcase.detailModels && newShowcase.detailModels.length > 0 ?
                                newShowcase?.detailModels?.map((detailModel, index) => <ProductBlock
                                detailModel={detailModel}
                                index={index}
                                handleMove={handleMove}
                                handleRemove={handleRemove}
                                handleAdd={handleAdd}
                                handleGallery={handleGallery}
                                />):
                                <IconButton onClick={() => handleAdd(0)}><AddCircle /></IconButton>
                            }
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Stack direction={"row"} justifyContent="flex-end" sx={{width: '100%'}} spacing={2}>
                            <Button variant={'contained'} onClick={() => setNewShowcase(originShowcase)}>초기화</Button>
                            <Button variant={'contained'} disabled={!isChanged} onClick={handleSave}>적용</Button>
                        </Stack>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default DisplayDetailModelManagement