import styled from "@emotion/styled"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { CouponCodeListSearchParams, CouponCodeStatusCode } from "../../../../types/CouponCodeType"
import { CouponCodeListSearchType } from "../index"
import React from "react"

const searchCouponCodeMenuList: { value: keyof CouponCodeListSearchParams; title: string }[] = [
  { value: "code", title: "코드" },
  { value: "name", title: "이름" },
  // { value: "status", title: "상태" },
]

const searchCouponStatusMenuList: { value: "전체" | CouponCodeStatusCode; title: string }[] = [
  { value: "전체", title: "전체" },
  { value: CouponCodeStatusCode.활성, title: "활성" },
  { value: CouponCodeStatusCode.취소, title: "취소" },
]

type Props = {
  searchParams: CouponCodeListSearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: keyof CouponCodeListSearchParams) => void
  onSearch: (keyword: CouponCodeListSearchType["keyword"]) => void
  changeStatus: (statusValue: "전체" | CouponCodeStatusCode) => void
}

const CouponCodeListTableSearchBar = ({
  searchParams,
  changeSearchKeyword,
  changeSearchFilterValue,
  onSearch,
  changeStatus,
}: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "100px" }}>
        <InputLabel id="status_key">상태</InputLabel>
        <Select
          size={"small"}
          label={"상태"}
          value={searchParams.status}
          onChange={(e) => changeStatus(e.target.value as "전체" | CouponCodeStatusCode)}
        >
          {searchCouponStatusMenuList.map((menu) => (
            <MenuItem value={menu.value} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"code"}
          required
          onChange={(e) => changeSearchFilterValue(e.target.value as keyof CouponCodeListSearchParams)}
        >
          {searchCouponCodeMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        <SearchInput
          style={{ height: 40 }}
          value={searchParams.keyword}
          onChange={(e) => changeSearchKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(searchParams.keyword)
            }
          }}
        />
      </SearchBar>
    </Wrapper>
  )
}

export default CouponCodeListTableSearchBar

const Wrapper = styled.div`
  width: 500px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
