import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const CouponPointUsageHistoryTableHeaderItemList = [
  {
    title: "ID",
    width: "10%",
  },
  {
    title: "쿠폰 코드",
    width: "20%",
  },
  {
    title: "사용자",
    width: "20%",
  },
  {
    title: "적립 포인트",
    width: "15%",
  },
  {
    title: "사용 포인트",
    width: "15%",
  },
  {
    title: "사용 일시",
    width: "20%",
  },
]

const CouponPointUsageHistoryListTableHeader = () => {
  return (
    <ListTableHeader>
      {CouponPointUsageHistoryTableHeaderItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default CouponPointUsageHistoryListTableHeader
