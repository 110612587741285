import { httpDelete, httpGet, httpPost } from "utils/httpAPI"
import { ProductStockGroupType } from "types/ProductStockType"

export const getProductStockGroupList: (site: "쇼룸" | "랩스", date?: string) => Promise<ProductStockGroupType[]> = (
  site,
  date
) => {
  return httpGet(`/product-inventory/${site}/groups`, { date })
}

export const getSimpleProductStockGroupList: (
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
) => Promise<{ id: number; name: string; priority: number; itemCount: number }[]> = (site) => {
  return httpGet(`/product-inventory/${site}/groups`).then((result) => {
    return result
      .filter((stock: ProductStockGroupType) => !!stock.id && stock.name !== "이동중")
      .map((stock: ProductStockGroupType) => ({
        id: stock.id,
        name: stock.name,
        priority: stock.priority,
        itemCount: stock.items.length,
      }))
  })
}

export const postProductStockGroup: (site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고", name: string) => Promise<ProductStockGroupType> = (
  site,
  name
) => {
  return httpPost(`/product-inventory/${site}/groups`, { name })
}

export const putProductStockGroup: (body: { site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"; id: number; name: string }) => Promise<void> = (
  body
) => {
  return httpDelete(`/product-inventory/${body.site}/groups/${body.id}`, { name: body.name })
}

export const deleteProductStockGroup: (site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고", id: number) => Promise<void> = (site, id) => {
  return httpDelete(`/product-inventory/${site}/groups/${id}`)
}

export const reorderProductStockGroup: (site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고", body: { id: number }[]) => Promise<void> = (
  site,
  body
) => {
  return httpPost(`/product-inventory/${site}/groups/reordering`, body)
}

export const reorderProductStockItemList: (
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고",
  groupId: number | null,
  body: { productId: number }[]
) => Promise<void> = (site, groupId, body) => {
  return httpPost(`/product-inventory/${site}/groups/${groupId}/items/reordering`, body)
}

export const addProductToStockGroup: (body: {
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
  groupId: number
  productId: number
}) => Promise<ProductStockGroupType> = (body) => {
  return httpPost(`/product-inventory/${body.site}/groups/${body.groupId}/items`, { productId: body.productId })
}

export const moveProductListFromStockGroup: (body: {
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
  idFrom: number | null
  idTo: number | null
  productIdList: number[]
}) => Promise<ProductStockGroupType> = (body) => {
  return httpPost(`/product-inventory/${body.site}/groups/transfer-items`, {
    from: body.idFrom,
    to: body.idTo,
    productIds: body.productIdList,
  })
}

export const checkProductStockOpen: (body: {
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
  groupId: number
  productId: number
}) => Promise<void> = (body) => {
  return httpPost(`/product-inventory/${body.site}/groups/${body.groupId}/items/${body.productId}/open`)
}

export const uncheckProductStockOpen: (body: {
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
  groupId: number
  productId: number
}) => Promise<void> = (body) => {
  return httpDelete(`/product-inventory/${body.site}/groups/${body.groupId}/items/${body.productId}/open`)
}

export const checkProductStockClose: (body: {
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고" | "두나무금고"
  groupId: number
  productId: number
}) => Promise<void> = (body) => {
  return httpPost(`/product-inventory/${body.site}/groups/${body.groupId}/items/${body.productId}/closed`)
}

export const uncheckProductStockClose: (body: {
  site: "쇼룸" | "랩스" | "장성원시계"
  groupId: number
  productId: number
}) => Promise<void> = (body) => {
  return httpDelete(`/product-inventory/${body.site}/groups/${body.groupId}/items/${body.productId}/closed`)
}
