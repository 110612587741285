import {
  deleteProductStockGroup,
  getSimpleProductStockGroupList,
  postProductStockGroup,
  reorderProductStockGroup,
} from "apis/productStockAPI"
import { isEmpty } from "lodash"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"

export const useProductStockListData = (site: "쇼룸" | "랩스") => {
  const [newProductStockName, setNewProductStockName] = useState<string>("")
  const [productStockList, setProductStockList] = useState<
    { id: number; priority: number; name: string; itemCount: number }[]
  >([])

  const fetchProductStockList = useCallback(async () => {
    try {
      const result = await getSimpleProductStockGroupList(site)
      setProductStockList(result)
    } catch {
      toast.error("리스트를 불러오는데 실패했습니다.")
    }
  }, [site])

  const onChangeOrder = useCallback(
    async (prevPrioirty: number, newPriority: number) => {
      const newList = [...productStockList]

      const [movedItem] = newList.splice(prevPrioirty, 1)
      newList.splice(newPriority, 0, movedItem)

      setProductStockList(newList.map((item, index) => ({ ...item, priority: index })))
    },
    [productStockList]
  )

  const onChangeName = useCallback((id: number, name: string) => {
    setProductStockList((prev) => prev.map((item) => ({ ...item, name: item.id === id ? name : item.name })))
  }, [])

  const onChangeNewProductStockName = useCallback((name: string) => {
    setNewProductStockName(name)
  }, [])

  const onSaveList = useCallback(async () => {
    try {
      reorderProductStockGroup(
        site,
        productStockList.map((listItem) => ({ id: listItem.id }))
      )
    } catch {
      toast.error("저장에 실패했습니다.")
    }
  }, [productStockList, site])

  const onAddNewList = useCallback(async () => {
    if (isEmpty(newProductStockName)) return

    try {
      const result = await postProductStockGroup(site, newProductStockName)

      setProductStockList((prev) => {
        if (!result.id) return prev

        return prev.concat({
          id: result.id,
          itemCount: result.items.length,
          name: result.name,
          priority: productStockList.length + 1,
        })
      })

      setNewProductStockName("")
    } catch {
      toast.error("새로운 그룹 생성에 실패했습니다.")
    }
  }, [newProductStockName, productStockList, site])

  const onDeleteList = useCallback(
    async (id: number) => {
      const selectedListIndex = productStockList.findIndex((stock) => stock.id === id)

      if (selectedListIndex === -1) return

      if (
        !window.confirm(
          productStockList[selectedListIndex].itemCount > 0
            ? `등록된 상품이 존재합니다. '${productStockList[selectedListIndex].name}' 삭제하시겠습니까?`
            : `'${productStockList[selectedListIndex].name}' 삭제하시겠습니까?`
        )
      )
        return

      try {
        await deleteProductStockGroup(site, id)
        setProductStockList((prev) => prev.filter((item) => item.id !== id))
      } catch {
        toast.error("그룹 삭제에 실패했습니다.")
      }
    },
    [site, productStockList]
  )

  useMount(fetchProductStockList)

  return {
    productStockList,
    newProductStockName,
    onChangeOrder,
    onSaveList,
    onChangeName,
    onChangeNewProductStockName,
    onAddNewList,
    onDeleteList,
  }
}
