import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const headerItemList: {
  title: string
  width?: string
  align?: "start" | "center" | "end"
}[] = [
  {
    title: "상품 ID",
    width: "6%",
  },
  {
    title: "Ref No.",
    width: "12%",
  },
  {
    title: "시리얼 넘버",
    width: "12%",
  },
  {
    title: "지점",
    width: "6%",
    align: "center",
  },
  {
    title: "장소",
    width: "8%",
    align: "center",
  },
  {
    title: "입출고 사유",
    width: "12%",
    align: "center",
  },
  {
    title: "일시",
    width: "16%",
    align: "center",
  },
  {
    title: "비고",
    align: "center",
  },
]

const ProductTrackingLogListTableHeader = () => {
  return (
    <ListTableHeader>
      {headerItemList.map((headerItem) => (
        <ListTableHeaderItem
          key={headerItem.title}
          style={{ maxWidth: headerItem.width, justifyContent: headerItem.align || "start" }}
        >
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default ProductTrackingLogListTableHeader
