import ListTableRow from "components/Table/List/TableBody/TableRow"
import ListTableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { FunctionComponent } from "react"
import { toDateStr, toDateTimeStr } from "utils/datetimeUtil"
import { NoticeType } from "types/NoticeType"

export const NoticeListTableBodyRow: FunctionComponent<{
  columnWidths: (string | number | undefined)[]
  notice: NoticeType
  onClick: (event?: React.MouseEvent) => void
}> = ({ columnWidths, notice, onClick }) => {
  return (
    <ListTableRow style={{ cursor: "pointer" }} onClick={(event) => onClick(event)}>
      <ListTableRowItem style={{ maxWidth: columnWidths[0] }}>{notice.id}</ListTableRowItem>
      <ListTableRowItem style={{ maxWidth: columnWidths[1] }}>{notice.title}</ListTableRowItem>
      <ListTableRowItem style={{ maxWidth: columnWidths[2] }}>{toDateStr(notice.notifyDate)}</ListTableRowItem>
      <ListTableRowItem style={{ maxWidth: columnWidths[3] }}>{toDateTimeStr(notice.startedAt)}</ListTableRowItem>
      <ListTableRowItem style={{ maxWidth: columnWidths[4] }}>{toDateTimeStr(notice.endedAt)}</ListTableRowItem>
      <ListTableRowItem style={{ maxWidth: columnWidths[5] }}>
        {notice.onDisplay ? "게시중" : "게시종료"}
      </ListTableRowItem>
    </ListTableRow>
  )
}
