import {Box, Button, Stack} from "@mui/material";
import CurationTableFilter from "./CurationTableFilter";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {DateTime} from "luxon";
import EditIcon from "@mui/icons-material/Edit";

function renderPreviewCell(params: any) {
    return (<Box style={{position: 'relative', height: 100, width: 50}}>
        <img src={params?.row?.backgroundImageUrl} style={{width: '100%', position: 'absolute'}} />
        <img src={params?.row?.foregroundImageUrl} style={{width: '100%', position: 'absolute', bottom: 0}} />
        {
            params?.row?.backgroundVideoUrl ?
                <Box style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,

                }}>
                    <video
                        autoPlay playsInline loop
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center center',
                            width: '100%',
                            height: '100%',
                        }}
                        >
                        <source src={params.row.backgroundVideoUrl} />
                    </video>
                </Box>
                 : <></>
        }
    </Box>)
}

function renderStatusCell(params: any) {
    if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
    const startedAt = DateTime.fromISO(params?.row?.startedAt)
    const endedAt = DateTime.fromISO(params?.row?.endedAt)

    return (
        <Stack direction={'column'}>
            <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
            <Box>~</Box>
            <Box>{endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
        </Stack>
    )
}


interface CurationTableFilterState {
    postingStatus: string | null
}

const initCurationFilterState: CurationTableFilterState = {
    postingStatus: null
}

//@ts-ignore
const CurationTable = ({handleSelection, handleUpdateCuration, refreshCounter}) => {
    const [tableFilter, setTableFilter] = useState<CurationTableFilterState>(initCurationFilterState)
    const [rows, setRows] = useState<any[]>([])

    const getColumns = () => {
        return [
            {
                field: 'actions',
                type: 'actions',
                width: 10,
                getActions: (params: any) => ([
                    <GridActionsCellItem
                        icon = {<EditIcon />}
                        label = {"수정"}
                        onClick={() => {
                            handleUpdateCuration(params.row)
                        }}
                        showInMenu
                    />
                ])

            },
            {field: 'id', headerName: "ID", width: 70},
            {field: 'priority', headerName: "노출순서", width: 100},
            {field: 'title', headerName: "제목", flex: 3},
            {field: 'createdAt', headerName: "등록 일시", width: 180, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
            {field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
            {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
            {field: 'postingStatus', headerName: "진행 상태", width: 100, renderCell: renderStatusCell},
        ]
    }

    useEffect(() => {
    }, [tableFilter, refreshCounter])

    return (
        <Stack spacing={2}>
            <CurationTableFilter
                filter ={tableFilter}
                handleFilterChange={(changedFilter: CurationTableFilterState) => { setTableFilter(changedFilter) }}
            />
            <Box sx={{height: 720, width: '100%'}}>
                <DataGrid
                    columns={getColumns()}
                    rows={rows}
                    getRowHeight={() => 100}
                    onSelectionModelChange={handleSelection}
                    checkboxSelection
                />
            </Box>
        </Stack>
    )
}

export default CurationTable