import {Paper, Stack, Tab} from "@mui/material";
import HeroBannerTab from "./HeroBannerTab";
import {useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import CurationTab from "./CurationTab";


const HomeManager = () => {
    const [tab, setTab] = useState<string>("heroBanner")

    function handleChangeTab(newTab: string) {
        if (tab != newTab) {
            setTab(newTab)
        }
    }

    return (
        <Paper sx={{p: 2}}>
            <Stack direction={"column"} spacing={2} justifyContent={"center"}>
                <TabContext value={tab}>
                    <TabList onChange={(_e, value) => handleChangeTab(value)}>
                        <Tab value={'heroBanner'} label={'히어로 배너 관리'}/>
                        {/*<Tab value={'curation'} label={'큐레이션 관리'}/>*/}
                    </TabList>
                    <TabPanel value={"heroBanner"}><HeroBannerTab/></TabPanel>
                    <TabPanel value={"curation"}><CurationTab/></TabPanel>
                </TabContext>
            </Stack>
        </Paper>
    )
}

export default HomeManager