import { getSerialNumberList } from "apis/serialAPI"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { SerialNumberListItemType } from "types/SerialNumberType"

const FETCH_LIST_COUNT = 20

export const useSerialNumberList = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })
  const [searchParams, setSearchParams] = useState<any>({
    filter: "serialNo",
    keyword: "",
  })

  const [serialList, setSerialList] = useState<SerialNumberListItemType[]>([])

  const changeSearchFilterValue = useCallback((searchFilterValue: any) => {
    setSearchParams((prev: any) => ({ ...prev, filter: searchFilterValue }))
  }, [])

  const parseSearchParams = useCallback((searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchSerialList = useCallback(
    (pageNumber?: number, searchParams?: any) => {
      getSerialNumberList({
        size: FETCH_LIST_COUNT,
        page: pageNumber,
        ...parseSearchParams(searchParams),
      })
        .then(({ content, totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
          setSerialList(content)
          setPaginationParams((prev) => ({
            ...prev,
            number,
            totalElements,
            totalPages,
            first,
            size,
          }))
        })
        .catch(() => {
          toast.error("시리얼 넘버 리스트를 불러오는데 실패했습니다.")
        })
    },
    [parseSearchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchSerialList(pageNumber, searchParams)
    },
    [searchParams, fetchSerialList]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev: any) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(() => {
    fetchSerialList(0, searchParams)
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchSerialList, searchParams])

  const onRefresh = useCallback(() => {
    fetchSerialList(0)
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchSerialList])

  useMount(() => {
    fetchSerialList()
  })

  return {
    changeSearchFilterValue,
    changePageNumber,
    changeSearchKeyword,
    onSearch,
    onRefresh,
    serialList,
    paginationParams,
    searchParams,
  }
}
