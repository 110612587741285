import {
  getSimpleProductStockGroupList,
  moveProductListFromStockGroup,
  postProductStockGroup,
} from "apis/productStockAPI"
import { isEmpty } from "lodash"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"

export const useProductStockTargetSelectorData = (
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고",
  listId: number | null,
  productIdList: number[]
) => {
  const [newProductStockName, setNewProductStockName] = useState<string>("")
  const [selectedStock, setSelectedStock] = useState<{
    id: number
    name: string
  } | null>(null)

  const [productStockList, setProductStockList] = useState<{ id: number; name: string }[]>([])

  const fetchProductStockList = useCallback(async () => {
    try {
      const result = await getSimpleProductStockGroupList(site)
      setProductStockList(result.map((stock) => ({ id: stock.id, name: stock.name })))
    } catch {
      toast.error("리스트를 불러오는데 실패했습니다.")
    }
  }, [site])

  const onChangeNewProductStockName = useCallback((name: string) => {
    setNewProductStockName(name)
  }, [])

  const onSaveList = useCallback(async () => {
    if (!selectedStock) return

    try {
      await moveProductListFromStockGroup({
        site,
        idFrom: listId,
        idTo: selectedStock.id,
        productIdList: productIdList,
      })
    } catch {
      toast.error("저장에 실패했습니다.")
    }
  }, [site, productIdList, selectedStock, listId])

  const onSelectStock = useCallback(
    async (id: number) => {
      const selectedListIndex = productStockList.findIndex((stock) => stock.id === id)

      if (selectedListIndex === -1) return

      setSelectedStock(productStockList[selectedListIndex])
    },
    [productStockList]
  )

  const onAddNewList = useCallback(async () => {
    if (isEmpty(newProductStockName)) return

    try {
      const result = await postProductStockGroup(site, newProductStockName)

      setProductStockList((prev) => {
        if (!result.id) return prev

        return prev.concat({
          id: result.id,
          name: result.name,
        })
      })

      setNewProductStockName("")
    } catch {
      toast.error("새로운 그룹 생성에 실패했습니다.")
    }
  }, [newProductStockName, site])

  useMount(fetchProductStockList)

  return {
    productStockList,
    newProductStockName,
    selectedStock,
    onSaveList,
    onChangeNewProductStockName,
    onAddNewList,
    onSelectStock,
  }
}
