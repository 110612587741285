import { toDateTimeStr } from "utils/datetimeUtil"
import { SellOrderType } from "types/SellOrderType"
import { UserDetailType, UserMemoTargetTypeCode, UserType } from "../../../types/UserType"
import QuickMemoButton from "../../../components/QuickMemo/QuickMemoButton"
import { useEffect, useState } from "react"
import { getUserDetail } from "../../../apis/userAPI"
import { toast } from "react-toastify"
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material"
import { truncateWithCommas } from "../../../utils/NumberUtils"
import { updateSellOrder } from "../../../apis/sellOrderAPI"
import { isNil, isNotNil } from "utils/validationUtils"
import { updateProduct, updateReceiveBuyPriceOffer } from "apis/productAPI"
import { getDetailCodeValue } from "../../../apis/codeDetailAPI"
import { ProductType } from "../../../types/ProductType"

type PreProductType = Omit<ProductType, "id"> & {
  id?: number
}

type Props = {
  sellOrder?: SellOrderType
  onUpdateSellOrder?: (sellOrder: SellOrderType | undefined) => void
  product?: PreProductType
  onUpdateProduct?: (product: PreProductType) => void
}

const SellOrderInformation = ({ sellOrder, onUpdateSellOrder, product, onUpdateProduct}: Props) => {
  const [unmask, setUnmask] = useState<boolean>(false)
  const [sellOrderState, setSellOrderState] = useState<SellOrderType | null>(null)
  const [seller, setSeller] = useState<UserType | UserDetailType | null>(null)
  const [exhibitionSale, setExhibitionSale] = useState(false)
  const [receiveBuyPriceOffer, setReceiveBuyPriceOffer] = useState(false)
  const [globalSale, setGlobalSale] = useState(false)

  const updateExhibitionSale = (exhibitionSale: boolean) => {
    updateSellOrder(sellOrderState?.id, { exhibitionSale }).then(() => {
      toast.success(exhibitionSale ? "전시판매 동의 처리 되었습니다." : "전시판매 비동의 처리 되었습니다.")
      setExhibitionSale(exhibitionSale)
    })
  }

  const onUpdateReceiveBuyPriceOffer = async (receiveBuyPriceOffer: boolean) => {
    await updateReceiveBuyPriceOffer(sellOrderState?.product!!.id!!, receiveBuyPriceOffer).then(async () => {
      toast.success(receiveBuyPriceOffer ? "구매 입찰 제안 받기 기능이 설정되었습니다." : "구매 입찰 제안 받기 기능이 해제되었습니다.")
      setReceiveBuyPriceOffer(receiveBuyPriceOffer)
    }).catch((error) => {
      toast.error(`${error.message || error}`)
    })
  }

  const handleClickButtonTitleEdit = async () => {
    if (isNil(sellOrderState)) {
      toast.error("판매주문정보를 불러오는 중입니다.")
      return
    }
    if (isNil(sellOrderState.product)) {
      toast.error("판매주문에 연결된 상품이 없습니다.")
      return
    }

    const nextProductTitle = window.prompt("상품명 변경", sellOrderState.product.title)
    if (isNotNil(nextProductTitle)) {
      try {
        await updateProduct(sellOrderState.product.id, { title: nextProductTitle })
        toast.success("상품명을 변경했습니다.")
        setSellOrderState((sellOrderState) =>
          sellOrderState
            ? {
              ...sellOrderState,
              product: sellOrderState.product
                ? { ...sellOrderState.product, title: nextProductTitle }
                : sellOrderState.product,
            }
            : null,
        )
      } catch (error: any) {
        toast.error(error.message ?? error)
      }
    }
  }

  const handleClickButtonTitleSecondaryEdit = async () => {
    if (isNil(sellOrderState)) {
      toast.error("판매주문정보를 불러오는 중입니다.")
      return
    }
    if (isNil(sellOrderState.product)) {
      toast.error("판매주문에 연결된 상품이 없습니다.")
      return
    }

    const nextProductTitleSecondary = window.prompt("상품정보 변경", sellOrderState.product.titleSecondary)
    if (isNotNil(nextProductTitleSecondary)) {
      try {
        await updateProduct(sellOrderState.product.id, { titleSecondary: nextProductTitleSecondary })
        toast.success("상품정보를 변경했습니다.")
        setSellOrderState((sellOrderState) =>
          sellOrderState
            ? {
              ...sellOrderState,
              product: sellOrderState.product
                ? { ...sellOrderState.product, titleSecondary: nextProductTitleSecondary }
                : sellOrderState.product,
            }
            : null,
        )
      } catch (error: any) {
        toast.error(error.message ?? error)
      }
    }
  }

  const handleClickGlobalSale = async (check: boolean) => {

    if (isNil(sellOrderState)) {
      toast.error("판매주문정보를 불러오는 중입니다.")
      return
    }
    if (isNil(sellOrderState.product)) {
      toast.error("판매주문에 연결된 상품이 없습니다.")
      return
    }
    if (isNil(sellOrderState.seller)) {
      toast.error("판매주문에 연결된 판매자 정보가 없습니다.")
      return
    }
    if (check && !sellOrderState.seller.hasCustomsCode) {
      toast.error("사용자 정보에서 고객의 통관고유부호를 등록해주세요.")
      return
    }

    var globalSaleCap: number = 0;
    await getDetailCodeValue("GLOBAL_SALE", "GLOBAL_SALE_CAP").then((res) => {
      if (res && res != null) {
        globalSaleCap = parseFloat(res);
      }
    });

    if (check && sellOrderState.product.price!! >= globalSaleCap) {
      toast.error(`판매 가격이 ${truncateWithCommas(globalSaleCap, 4)} 만원 이상인 상품은 해외 판매가 불가합니다.`)
      return
    }

    try {
      await updateProduct(sellOrderState.product.id, { globalSale: check })

      setSellOrderState((sellOrderState) =>
        sellOrderState
          ? {
            ...sellOrderState,
            product: sellOrderState.product
              ? { ...sellOrderState.product, globalSale: check }
              : sellOrderState.product,
          }
          : null
      )
      // 부모 상태 업데이트
      if (onUpdateSellOrder) {
        onUpdateSellOrder({
          ...sellOrder,
          product: sellOrder?.product
            ? { ...sellOrder.product, globalSale: check }
            : sellOrder?.product
        })
      }
      if (onUpdateProduct) {
        if (product) {
          onUpdateProduct({
            ...product,
            globalSale: check,
          });
        }
      }

      setGlobalSale(check)

      toast.success("해외 판매 동의를 변경했습니다.")

    } catch (error: any) {
      toast.error(error.message ?? error)
    }
  }

  useEffect(() => {
    if (isNotNil(sellOrder)) {
      setSellOrderState(sellOrder)
      setExhibitionSale(Boolean(sellOrder.exhibitionSale))
      setReceiveBuyPriceOffer(Boolean(sellOrder.product?.receiveBuyPriceOffer))
      setGlobalSale(Boolean(sellOrder?.product?.globalSale))
    }
    if (isNotNil(sellOrder) && isNotNil(sellOrder.seller)) {
      getUserDetail(String(sellOrder.seller.id), unmask)
        .then((user) => setSeller(user))
        .catch((err) => {
          toast.error("회원 정보 조회에 실패했습니다.")
          console.log("Error", err)
        })
    }
  }, [sellOrder, unmask])

  return (
    <table className={"basic"}>
      <tbody>
      <tr>
        <td className={"no-border"} colSpan={6} style={{ textAlign: "end" }}>
          <FormControlLabel
            style={{ color: "#ed3030" }}
            control={<Switch color="warning" value={unmask} />}
            label="개인정보 마스킹 해제"
            checked={unmask}
            onChange={(event, checked) => {
              if (checked) {
                checked = window.confirm(
                  "업무상 고객의 개인정보를 조회합니다." +
                  "\n\n* 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 경우 " +
                  "\n5년 이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다.",
                )
              }
              setUnmask(checked)
            }}
          />
        </td>
      </tr>
      <tr>
        <td className={"ta-center"} rowSpan={8}>
          <img
            style={{ display: "block", margin: "auto", width: "200px", height: "300px", objectFit: "cover" }}
            src={sellOrderState?.product?.thumbnail}
            alt=""
          />
        </td>
        <td className={"header"}>판매주문번호</td>
        <td className={"header"}>판매신청 일시</td>
        <td className={"header"}>상품화완료 일시</td>
        <td className={"header"}>상품등록 일시</td>
        <td className={"header"}>상품 가격</td>
      </tr>
      <tr>
        <td>{sellOrderState?.id}</td>
        <td>{toDateTimeStr(sellOrderState?.acceptedAt)}</td>
        <td>{toDateTimeStr(sellOrderState?.product?.completeSetupAt)}</td>
        <td>{toDateTimeStr(sellOrderState?.onSaleAt)}</td>
        <td>{`${truncateWithCommas(sellOrderState?.product?.price, 4)} 만원`}</td>
      </tr>
      <tr>
        <td className={"header"}>판매자</td>
        <td className={"header"} colSpan={1}>
          연락처
        </td>
        <td className={"header"} colSpan={2}>
          바이버 계정
        </td>
        <td className={"header"}>판매방식</td>
      </tr>
      <tr>
        <td>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Typography>{seller?.name}</Typography>
            <QuickMemoButton target={UserMemoTargetTypeCode.USER} targetId={seller?.id} small />
          </Stack>
        </td>
        <td colSpan={1}>{seller?.phone}</td>
        <td colSpan={2}>{seller?.email}</td>
        <td>{sellOrderState?.sellOrderType}</td>
      </tr>
      <tr>
        <td className={"header"} colSpan={3}>
          상품명
        </td>
        <td className={"header"}>Ref No.</td>
        <td className={"header"}>전시판매 동의</td>
      </tr>
      <tr>
        <td>{sellOrderState?.product?.brandSid}</td>
        <td>
          {sellOrderState?.product?.title}
          <button style={{ marginLeft: 8 }} onClick={handleClickButtonTitleEdit}>
            수정
          </button>
        </td>
        <td>
          {sellOrderState?.product?.titleSecondary}
          <button style={{ marginLeft: 8 }} onClick={handleClickButtonTitleSecondaryEdit}>
            수정
          </button>
        </td>
        <td>{sellOrderState?.product?.detailModel?.fullRefNo}</td>
        <td>
          <Switch checked={exhibitionSale} onChange={(_, checked) => updateExhibitionSale(checked)} />
        </td>
      </tr>
      <tr>
        <td className={"header"}>
          구매입찰 제안 받기
        </td>
        <td className={"header"} >
          해외 판매 동의
        </td>
      </tr>
      <tr>
        <td>
          <Switch checked={receiveBuyPriceOffer} onChange={(_, checked) => onUpdateReceiveBuyPriceOffer(checked)} />
        </td>
        <td>
          <Switch checked={globalSale} onChange={(_, checked) => handleClickGlobalSale(checked)} />
        </td>
      </tr>
      </tbody>
    </table>
  )
}

export default SellOrderInformation
