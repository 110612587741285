import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

type Props = {
  isOpen: boolean
  closeModal?: () => void
  zIndex?: number
} & HTMLAttributes<HTMLDivElement>

const Modal = ({ isOpen, zIndex, closeModal, children, ...props }: PropsWithChildren<Props>) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Overlay onClick={closeModal} zIndex={zIndex} />
      <ChildrenWrapper {...props} zIndex={zIndex}>
        {children}
      </ChildrenWrapper>
    </>
  )
}

export default Modal

const Overlay = styled.div<{ zIndex?: number }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: ${({ zIndex }) => zIndex || 9998};

  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
`

const ChildrenWrapper = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 1px 20px 2px rgba(0, 0, 0, 0.08);
  z-index: ${({ zIndex }) => (zIndex ? zIndex + 1 : 9999)};

  background: white;
  border-radius: 8px;
`
