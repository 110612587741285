import {toDateTimeStr} from "../../../../utils/datetimeUtil";
import React from "react";
import {PaymentChannelTypeCode, PaymentType} from "types/PaymentType";
import {numberWithCommas} from "../../../../utils/NumberUtils"

type Props = {
  payments?: PaymentType[]
}

const Payment = ({ payments }: Props) => {

  function paymentMethodInfo(payment: PaymentType) {
    if (payment?.channelType == PaymentChannelTypeCode.현장결제) return '토스플레이스에서 확인가능'
    else if (payment?.paymentMethodType == "가상계좌") return `${payment?.bankName ?? ''} ${payment?.accountNumber ?? ''}`
    else if(payment?.paymentMethodType == "신용카드") return `${payment?.cardCompanyCode} ${payment?.cardNo}`
    else if (payment?.paymentMethodType == "계좌이체") return `${payment?.bankName ?? ''} ${payment?.accountNumber ?? ''}`
    else return  '-'
  }

  return (
    <>
      <h3>구매 결제정보</h3>
      <table className={"basic"}>
        <tbody>
        <tr>
          <td className={"header"}>결제자</td>
          <td className={"header"} colSpan={4}>
            결제수단
          </td>
          <td className={"header"}>결제방법</td>
          <td className={"header"}>결제금액</td>
          <td className={"header"}>결제완료일시</td>
          <td className={"header"}>결제취소일시</td>
          <td className={"header"}>결제상태</td>
        </tr>
          { payments?.map(payment => (
              <tr>
                <td>{payment?.userName}</td>
                <td>{payment?.channelType}</td>
                <td>{payment?.paymentMethodType}</td>
                <td colSpan={2}>{ paymentMethodInfo(payment) }</td>
                <td>{payment?.installMonth ? `${payment?.installMonth}개월` : "일시불"}</td>
                <td>{payment?.currency === 'KRW' ? numberWithCommas(payment?.amount) + '원' : payment?.currency === 'USD' ? payment?.currency + ' $' + numberWithCommas(payment?.amount) : payment?.currency + ' ' + numberWithCommas(payment?.amount)}</td>
                <td style={{fontSize: '13px'}}>{toDateTimeStr(payment?.transactionAt)}</td>
                <td style={{fontSize: '13px'}}>{toDateTimeStr(payment?.canceledAt)}</td>
                <td>{payment?.status}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default Payment
