import styled from "@emotion/styled"
import PopupPage from "components/Popup/PopupPage"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { isNil } from "utils/validationUtils"
import { useUpgradeServiceRegister } from "./useUpgradeServiceRegister"
import { useState } from "react"
import ProductSelectPopup from "components/Popup/ProductSelectPopup"
import Button from "components/Button"
import { UpgradeItemModal } from "../UpgradeItem/UpgradeItemModal"
import { isEmpty } from "lodash"
import { UpgradeItemTable } from "../UpgradeItem/UpgradeItemTable"
import { UserFindModal } from "./UserFinderModal"

type Props = {
  closeModal: () => void
}

export const UpgradeServiceOrderRegisterModal = ({ closeModal }: Props) => {
  const [isOpenProductSelectPopup, setIsOpenProductSelectPopup] = useState<boolean>(false)
  const [isOpenUserFinderPopup, setIsOpenUserFinderPopup] = useState<boolean>(false)
  const [isOpenUpgradeItemModal, setIsOpenUpgradeItemModal] = useState(false)

  const {
    selectedUser,
    isAllChecked,
    selectedUpgradeItemIndexList,
    upgradeItemList,
    selectedProduct,
    selectProduct,
    setSelectedUser,
    addUpgradeItemListstate,
    toggleUpgradeItem,
    deleteUpgradeItemListState,
    registerUpgradeServiceOrder,
    toggleAllUpgradeItem,
    onEditUpgradeServiceItem,
  } = useUpgradeServiceRegister()

  const handleRegisterUpgradeService = async () => {
    const result = await registerUpgradeServiceOrder()

    if (result) closeModal()
  }

  const convertEmptyValueToDash = (value: any) => {
    return isNil(value) ? "-" : value
  }

  return (
    <>
      {isOpenUserFinderPopup && (
        <UserFindModal onSubmit={(user) => setSelectedUser(user)} closeModal={() => setIsOpenUserFinderPopup(false)} />
      )}
      {isOpenUpgradeItemModal && (
        <UpgradeItemModal
          onRegister={(item) => addUpgradeItemListstate(item)}
          closeModal={() => setIsOpenUpgradeItemModal(false)}
          upgradeItemList={upgradeItemList}
        />
      )}
      {isOpenProductSelectPopup && (
        <ProductSelectPopup
          isMultiSelectable={false}
          isSearchable
          open={isOpenProductSelectPopup}
          setProducts={(productList) => {
            selectProduct(productList[0])
          }}
          close={()=> {
            setIsOpenProductSelectPopup(false)
          }}
        />
      )}
      <PopupPage isOpen closePage={closeModal} style={{ width: "1016px", height: "90vh" }}>
        <ContentWrapper>
          <RowWrapper
            style={{
              justifyContent: "space-between",
            }}
          >
            <PopupPageTitle>수리 주문 관리</PopupPageTitle>

            <RowWrapper style={{ border: "1px solid #CCCCCC", width: "312px" }}>
              <DetailTableColumCell title="수리 주문번호" style={{ borderRight: "1px solid #CCCCCC" }}>
                <ValueWrapper>-</ValueWrapper>
              </DetailTableColumCell>
              <DetailTableColumCell title="상태">
                <ValueWrapper>-</ValueWrapper>
              </DetailTableColumCell>
            </RowWrapper>
          </RowWrapper>
          <PopupPageScrollContainer>
            <h3>수리 주문 정보</h3>
            <RowWrapper>
              <RowWrapper style={{ border: "1px solid #CCCCCC" }}>
                <DetailTableColumCell title="수리유형" style={{ borderRight: "1px solid #CCCCCC" }}>
                  <ValueWrapper>구매 상품 수리</ValueWrapper>
                </DetailTableColumCell>
                <DetailTableColumCell title="상품번호">
                  <ValueWrapper onClick={() => setIsOpenProductSelectPopup(true)}>
                    {!selectedProduct ? (
                      <ProductSelectButton>상품 번호 선택</ProductSelectButton>
                    ) : (
                      <ChangeableText>{selectedProduct.id}</ChangeableText>
                    )}
                  </ValueWrapper>
                </DetailTableColumCell>
              </RowWrapper>
            </RowWrapper>

            <h3>주문 정보</h3>
            <RowWrapper style={{ border: "1px solid #CCCCCC" }}>
              <ThumnailImageWrapper style={{ borderRight: "1px solid #CCCCCC" }}>
                {selectedProduct?.detailModel.thumbnail && (
                  <ThumnailImage
                    style={{
                      justifyContent: "center",
                    }}
                    src={selectedProduct.detailModel.thumbnail}
                    alt="모델이미지"
                  />
                )}
              </ThumnailImageWrapper>

              <ColumnWrapper>
                <DetailTableColumCell title="상품명">
                  <RowWrapper>
                    <ValueWrapper>{convertEmptyValueToDash(selectedProduct?.brandSid)}</ValueWrapper>
                    <ValueWrapper>{convertEmptyValueToDash(selectedProduct?.title)}</ValueWrapper>
                    <ValueWrapper>{convertEmptyValueToDash(selectedProduct?.titleSecondary)}</ValueWrapper>
                  </RowWrapper>
                </DetailTableColumCell>
                <RowWrapper>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="Ref No.">
                    <ValueWrapper>{selectedProduct?.detailModel.fullRefNo}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="시리얼 넘버">
                    <ValueWrapper>{convertEmptyValueToDash(selectedProduct?.serialNo)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="회원 ID">
                    <ValueWrapper onClick={() => setIsOpenUserFinderPopup(true)}>
                      {isNil(selectedUser) ? (
                        <ProductSelectButton>회원 검색하기</ProductSelectButton>
                      ) : (
                        <ChangeableText>{selectedUser.id} </ChangeableText>
                      )}
                    </ValueWrapper>
                  </DetailTableColumCell>
                </RowWrapper>

                <RowWrapper>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="회원명">
                    <ValueWrapper>{convertEmptyValueToDash(selectedUser?.name)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="연락처">
                    <ValueWrapper>{convertEmptyValueToDash(selectedUser?.phone)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="바이버 계정">
                    <ValueWrapper>{convertEmptyValueToDash(selectedUser?.email)}</ValueWrapper>
                  </DetailTableColumCell>
                </RowWrapper>

                <RowWrapper>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="수리 접수일">
                    <ValueWrapper>-</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="결제일">
                    <ValueWrapper>-</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="수리 완료일">
                    <ValueWrapper>-</ValueWrapper>
                  </DetailTableColumCell>
                </RowWrapper>
              </ColumnWrapper>
            </RowWrapper>

            <RowWrapper style={{ margin: "10px 0", justifyContent: "space-between" }}>
              <h3>수리 항목</h3>
              <ButtonListWrapper>
                <ButtonWrapper>
                  <Button
                    disabled={isEmpty(selectedUpgradeItemIndexList)}
                    onClick={deleteUpgradeItemListState}
                    buttonSize="M"
                    buttonStyle="red-fill"
                  >
                    삭제하기
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button onClick={() => setIsOpenUpgradeItemModal(true)} buttonSize="M">
                    생성하기
                  </Button>
                </ButtonWrapper>
              </ButtonListWrapper>
            </RowWrapper>

            <UpgradeItemWrapper>
              <UpgradeItemTable
                onEditUpgradeServiceItem={onEditUpgradeServiceItem}
                isAllChecked={isAllChecked}
                toggleAllUpgradeItem={toggleAllUpgradeItem}
                upgradeItemList={upgradeItemList}
                selectedUpgradeItemIndexList={selectedUpgradeItemIndexList}
                selectRow={toggleUpgradeItem}
              />
            </UpgradeItemWrapper>
          </PopupPageScrollContainer>
          <PopupPageFooter>
            <RowWrapper style={{ justifyContent: "flex-end" }}>
              <ButtonWrapper
                style={{
                  width: "100px",
                }}
              >
                <Button buttonSize="M" onClick={handleRegisterUpgradeService}>
                  수리등록
                </Button>
              </ButtonWrapper>
              <ButtonWrapper
                style={{
                  width: "100px",
                }}
              >
                <Button disabled buttonSize="M">
                  고객 결제 요청
                </Button>
              </ButtonWrapper>
              <ButtonWrapper
                style={{
                  width: "100px",
                }}
              >
                <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
                  취소
                </Button>
              </ButtonWrapper>
            </RowWrapper>
          </PopupPageFooter>
        </ContentWrapper>
      </PopupPage>
    </>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ValueWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 6px;
`

const ButtonWrapper = styled.div`
  width: 70px;

  :not(:first-child) {
    margin-left: 8px;
  }
`

const ButtonListWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const UpgradeItemWrapper = styled.div`
  flex: 1;
`

const ProductSelectButton = styled.div`
  padding: 10px 16px;
  border-radius: 4px;

  border: 1px solid #d9d9d9;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  :hover {
    border-color: #333333;
    cursor: pointer;
  }
`

const ThumnailImage = styled.img`
  width: 234px;
  height: 234px;
`

const ThumnailImageWrapper = styled.div`
  min-width: 234px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChangeableText = styled.div`
  color: #3a74cd;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
`
