import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { SellOrderState } from "./index"
import { Button } from "@mui/material"
import { numberWithCommas, truncate } from "../../../utils/NumberUtils"
import { toDateStr, toDateTimeStr, toTimeStr } from "../../../utils/datetimeUtil"
import { ShippingStatusCode } from "../../../types/ShippingType"

function createColumns(handleConfirmButtonClick: Function): any[] {
  const getColorByStatus = (status: SellOrderStatusCode | ShippingStatusCode) => {
    switch (status) {
      case SellOrderStatusCode.판매_대기:
      case ShippingStatusCode.배송_준비:
        return "error"
      case SellOrderStatusCode.결제_대기:
      case SellOrderStatusCode.진단중:
      case SellOrderStatusCode.진단_완료:
      case ShippingStatusCode.상품_발송:
      case ShippingStatusCode.배송중:
        return "warning"
      case SellOrderStatusCode.판매중:
      case ShippingStatusCode.배송_완료:
        return "success"
      default:
        return "info"
    }
  }

    return [
        {
            field: 'id',
            headerName: "판매주문 번호",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 130,
            maxWidth: 160,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>판매주문 번호<br/> (상품 번호)</p>),
            valueGetter: (params: any) => `${params.row.id}`,
            renderCell: (params: any) => (
                <div style={{textAlign: "left"}}>
                    <p><span style={{fontWeight: "bold"}}>{params.row.id}</span>
                        <br/><span style={{color: '#aaa'}}>{params.row.product?.id}</span></p>
                </div>
            )
        },
        {
            field: 'productId',
            headerName: "상품 번호",
            hide: true,
            valueGetter: (params: any) => `${params.row.product?.id}`,
        },
        {field: 'sellOrderStatus', headerName: "진행상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 90,
            maxWidth: 120,
            valueGetter: (params: any) => {
                return `${params.row.sellOrderStatus}`
            },
            renderCell: (params: any) => (
                <div>
                    <Button size={"small"} variant={"outlined"} style={{fontWeight: "bold"}}
                            onClick={() => {handleConfirmButtonClick(params.row)}}
                            color={getColorByStatus(params.row?.sellOrderStatus)}>{params.row?.sellOrderStatus}</Button>
                </div>
            )
        },
        {
            field: "productCondition",
            headerName: "제품상태",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            valueGetter: ({ row: { product: { exposedProductInfo: { productCondition } } } }: any) => {
                return productCondition
            },
        },
        {
            field: "stampingDate",
            headerName: "스탬핑",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            valueGetter: ({ row: { product: { exposedProductInfo: { stampingDate, stampingYear } } } }: any) => {
                if(stampingDate){
                    return stampingDate
                }
                if(stampingYear){
                    return stampingYear
                }
            },
        },
        {
            field: "shippingMethod",
            headerName: "입고방식",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            valueGetter: (params: any) => {
                if(params.row.shipping?.shippingMethod === '안전배송_2'){
                    return '일양택배'
                }
                if(params.row.shipping?.shippingMethod === '안전배송'){
                    return '발렉스'
                }
                return params.row.shipping?.shippingMethod
            },
        },
/*
        {field: 'saleType', headerName: "판매방식", editable: false, sortable: false,
            flex: 1,
            minWidth: 90,
            valueGetter: (params: any) => `${params.row.product?.saleType}`,
        },
*/
      {
        field: "globalSale",
        headerName: "해외판매",
        editable: false,
        sortable: false,
        align: "center",
        flex: 1,
        minWidth: 70,
        maxWidth: 70,
        valueGetter: (params: any) => {
          return `${params.row?.product.globalSale}`
        },
        renderCell: (params: any) => (
          <div>
            {
              params.row?.product.globalSale
                ? <Button size={"small"} style={{fontWeight: params.row?.product.globalSale ? 700 : 400}}>동의</Button>
                : <Button size={"small"} style={{color: "#999999"}}>미동의</Button>
            }
          </div>
        )
      },
      {
        field: "shippingStatus",
        headerName: "입고상태",
        editable: false,
        sortable: false,
        align: "center",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        valueGetter: (params: any) => {
          return `${params.row?.shipping?.shippingStatus}`
        },
        renderCell: (params: any) => (
          <div>
            <Button
              size={"small"}
              variant={"text"}
              onClick={() => {
                handleConfirmButtonClick(params.row)
              }}
              color={getColorByStatus(params.row?.shipping?.shippingStatus)}
            >
              {params.row?.shipping?.shippingStatus}
            </Button>
          </div>
        ),
      },
        {
            field: "sellerId",
            headerName: "판매자ID",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            valueGetter: (params: any) => `${params.row.seller?.id}`,
        },
        {field: 'title', headerName: "상품명", editable: false, sortable: false,
            flex: 1,
            minWidth: 220,
            valueGetter: (params: any) => `${params.row.product?.title}`,
            renderCell: (params: any) => (
                <div>
                    <p><span style={{fontWeight: "bold"}}>{params.row.product?.title}</span>
                        <br/><span style={{color: '#aaa'}}>{params.row.product?.titleSecondary}</span></p>
                </div>
            )
        },
        {field: 'fullRefNo', headerName: "Ref No.", editable: false, sortable: false,
            flex: 1,
            minWidth: 160,
            maxWidth: 220,
            valueGetter: (params: any) => `${params.row.product?.detailModel?.fullRefNo}`,
        },
/*
        {field: 'estimatedPrice', headerName: "가격 범위 (만원)", editable: false, sortable: false,
            align: 'center',
            minWidth: 120,
            valueGetter: (params: any) => {
                const price = params.row.productDiagnosis?.viverPrices?.estimatedPriceAtOrder
                return (
                    (price?.min && price?.max)
                        ? (`${numberWithCommas(truncate(price?.min, 4))} ~ ${numberWithCommas(truncate(price?.max, 4))}`)
                        : '-'
                )
        }
        },
*/
    {
      field: "price",
      headerName: "판매희망가",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.product?.price, 4))} 만원`,
    },
    /*
        {
            field: 'sellerName',
            headerName: "판매자 정보",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
            maxWidth: 160,
            valueGetter: (params: any) => `${params.row.seller?.name}`,
            renderCell: (params: any) => (
                <div>
                    <p>{params.row.seller?.name}<br/>
                        {params.row.seller?.phone}</p>
                </div>
            )
        },
        {
            field: 'phone',
            headerName: "전화번호",
            hide: true,
            valueGetter: (params: any) => `${params.row.seller?.phone}`,
        },

       */
    {
      field: "acceptedAt",
      headerName: "판매신청일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          신청일
        </p>
      ),
      valueGetter: (params: any) => toDateTimeStr(params.value),
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.acceptedAt, false)}
            <br />
            {toTimeStr(params.row.acceptedAt, true)}
          </p>
        </div>
      ),
    },
    {
      field: "onSaleAt",
      headerName: "판매승인일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          승인일
        </p>
      ),
      valueGetter: (params: any) => toDateTimeStr(params.value),
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.onSaleAt, false)}
            <br />
            {toTimeStr(params.row.onSaleAt, true)}
          </p>
        </div>
      ),
    },
    {
      field: "cancelSaleAt",
      headerName: "판매취소일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          취소일
        </p>
      ),
      valueGetter: (params: any) => toDateTimeStr(params.value),
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.cancelSaleAt, false)}
            <br />
            {toTimeStr(params.row.cancelSaleAt, true)}
          </p>
        </div>
      ),
    },
  ]
}

type SellOrderProps = {
  state: SellOrderState
  dispatch: any
  fetch: Function
  components: any[]
}

const BeforeDiagnosisSellOrderTable: React.FC<SellOrderProps> = ({ state, dispatch, fetch, components }) => {
  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }

  const handleSelection = (ids: any[]) => {
    dispatch({ type: "SELECTION_SELLORDER_IDS", payload: ids as string[] })
  }
  const handleConfirmButtonClick = (row: SellOrderType) => {
    dispatch({ type: "SHOW_CONFIRM_POPUP_OPEN", payload: row })
  }

  return (
    <>
      <div style={{ height: "1200px", width: "100%" }}>
        <DataGrid
          components={{ Toolbar: CustomToolbar }}
          getRowId={(row) => row.id}
          columns={createColumns(handleConfirmButtonClick)}
          pagination
          {...dispatch.detailModels?.content}
          paginationMode="server"
          onPageChange={(page) => {
            fetch({ page, size: state.sellOrders.pageable?.pageSize || 25 })
          }}
          onPageSizeChange={(size) => {
            fetch({ page: 0, size })
          }}
          page={state.sellOrders.pageable?.pageNumber || 0}
          pageSize={state.sellOrders.pageable?.pageSize || 25}
          rowCount={state.sellOrders?.totalElements || 0}
          rows={state.sellOrders?.content || []}
          selectionModel={state.selectedIds}
          checkboxSelection
          disableSelectionOnClick={true}
          onSelectionModelChange={handleSelection}
          loading={state.loading}
        />
      </div>
    </>
  )
}

// @ts-ignore
export default BeforeDiagnosisSellOrderTable
