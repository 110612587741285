import { httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { DiagnosisIssueHistoryType, DiagnosisIssueListType, DiagnosisType } from "types/DiagnosisType"
import { CountType } from "types/CountType"
import { DiagnosisIssuanceType } from "../types/DiagnosisIssuanceType"
import product from "../pages/Product"
import { PageType } from "../types/PageType"

export const countDiagnoses: (searchParams: any) => Promise<CountType[]> = (searchParams) =>
  httpGet("/product-diagnoses/counts", searchParams)
export const complete: (id: number) => Promise<number | DiagnosisType | any> = (id) =>
  httpPost(`/product-diagnoses/${id}/complete`)
export const confirm: (ids: number[]) => Promise<number[] | any> = (ids) => httpPost("/product-diagnoses/confirm", ids)
export const guideResult: (ids: number[]) => Promise<number[] | any> = (ids) =>
  httpPost("/product-diagnoses/guide-result", ids)

export const getDiagnoses: () => Promise<DiagnosisType[]> = () => httpGet("/product-diagnoses")
export const getDiagnosis: (id: number) => Promise<DiagnosisType> = (id) => httpGet(`/product-diagnoses/${id}`)
export const updateDiagnosis: (id: number, diagnosis: DiagnosisType) => Promise<DiagnosisType | any> = (
  id,
  diagnosis
) => httpPut(`/product-diagnoses/${id}`, diagnosis)

export const getDiagnosisFee = (productId: number, buyerId?: number) => {
  return httpPost<
    {
      amount: number
      calculationAmount: number
      calculationType: "정가" | "정률"
      discountAmount: 0
      feePolicyType: "구매 진단 수수료" | "구매 정밀 진단 수수료"
      name: string
    }[]
  >(
    "/estimates/buy-order-diagnosis-fees",
    buyerId ? { buyerId: buyerId, product: { id: productId } } : { product: { id: productId } }
  )
}

type getDiagnosisIssuanceType = (productId: number) => Promise<DiagnosisIssuanceType>
export const getDiagnosisIssuance: getDiagnosisIssuanceType = (productId) =>
  httpGet(`/product-diagnoses/pre/authentication/issuance/${productId}`)

type getDiagnosisIssuanceManual = (userId: number, detailId: number, advancedDiagnosis: boolean) => Promise<DiagnosisIssuanceType>
export const getDiagnosisIssuanceManual: getDiagnosisIssuanceManual = (userId, detailId, advancedDiagnosis) =>
  httpGet(`/product-diagnoses/pre/authentication/issuance/manual/${userId}/${detailId}`,{
    "advancedDiagnosis":advancedDiagnosis
  })

type getDiagnosisIssuanceAuto = (diagnosisId: number) => Promise<DiagnosisIssuanceType>
export const getDiagnosisIssuanceAuto: getDiagnosisIssuanceAuto = (diagnosisId) =>
  httpGet(`/product-diagnoses/authentication/issuance/${diagnosisId}`)
type resultDiagnosisIssuance = (result: DiagnosisIssuanceType) => Promise<DiagnosisIssuanceType>
export const resultDiagnosisIssuance: resultDiagnosisIssuance = (result) =>
  httpPost("/product-diagnoses/authentication/issuance", result)

export const getDiagnosisIssueList: (params: any) => Promise<PageType<DiagnosisIssueListType>> = (params) =>
  httpGet("/product-diagnoses/issue-list", params)

export const getDiagnosisIssueHistory: (params: any) => Promise<PageType<DiagnosisIssueHistoryType>> = (params) =>
  httpGet("/product-diagnoses/issue-history", params)
