import {DataGrid, GridColumnHeaderParams, GridToolbarContainer} from '@mui/x-data-grid';
import React from "react";
import {BuyOrderState} from "./index";
import {BuyOrderStatusCode, BuyOrderType} from "types/BuyOrderType";
import {numberWithCommas, truncate} from "../../utils/NumberUtils";
import {toDateStr, toDateTimeStr, toTimeStr} from "../../utils/datetimeUtil";
import {Button, Grid} from "@mui/material";
import {ShippingStatusCode} from "types/ShippingType";
import testAPI from "../../apis/testAPI";

function createColumns(handleEditButtonClick: Function): any[] {
    const openBuyOrderShippingPopup = (row: BuyOrderType) => {
        handleEditButtonClick(row)
    }
    const getColorByStatus = (status: BuyOrderStatusCode|ShippingStatusCode) => {
        switch (status) {
            case BuyOrderStatusCode.결제_대기:
            case BuyOrderStatusCode.상품_준비:
            case ShippingStatusCode.배송_준비:
                return 'info'
            case BuyOrderStatusCode.진단중:
            case BuyOrderStatusCode.배송중:
            case ShippingStatusCode.상품_발송:
                return 'error'
            case BuyOrderStatusCode.반품_요청완료:
            case BuyOrderStatusCode.구매_취소:
                return 'warning'
            case BuyOrderStatusCode.구매_완료:
            case ShippingStatusCode.배송_완료:
                return 'success'
        }
    }

    return [
        {
            field: 'id',
            headerName: "구매주문번호",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 130,
            maxWidth: 160,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>상품번호 <br/> (구매주문번호)</p>),
            valueGetter: (params: any) => `${params.row.id}`,
            renderCell: (params: any) => (
                <div style={{textAlign: "left"}}>
                    <p><span style={{fontWeight: "bold"}}>{params.row.product?.id}</span>
                        <br/><span style={{color: '#aaa'}}>{params.row.id}</span></p>
                </div>
            )
        },
        {
            field: 'productId',
            headerName: "상품번호",
            hide: true,
            valueGetter: (params: any) => `${params.row?.product?.id}`,
        },
        {field: 'buyOrderStatus', headerName: "진행상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 90,
            maxWidth: 120,
            valueGetter: (params: any) => {
                return `${params.row.buyOrderStatus}`
            },
            renderCell: (params: any) => (
                <div>
                    <Button size={"small"} variant={"outlined"} style={{fontWeight: "bold"}}
                            onClick={() => {openBuyOrderShippingPopup(params.row)}}
                            color={getColorByStatus(params.row.buyOrderStatus)}>{params.row.buyOrderStatus}</Button>
                </div>
            )
        },
        {field: 'saleType', headerName: "판매방식", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => `${params.row.product?.saleType}`,
        },
        {
            field: "shippingMethod",
            headerName: "출고방식",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => {
                if(params.row.shipping?.shippingMethod === '안전배송_2'){
                    return '일양택배'
                }
                if(params.row.shipping?.shippingMethod === '안전배송'){
                    return '발렉스'
                }
                return params.row.shipping?.shippingMethod
            },
        },
        {
            field: "sellerId",
            headerName: "판매자 ID",
            editable: false,
            sortable: false,
            minWidth: 100,
            maxWidth: 140,
            flex: 1,
            valueGetter: (params: any) => `${params.row.product?.sellerId}`,
        },
        {field: 'title', headerName: "상품명", editable: false, sortable: false,
            minWidth: 220,
            flex: 1,
            valueGetter: (params: any) => `${params.row.product?.title}`,
        },
        {field: 'fullRefNo', headerName: "Ref No.", editable: false, sortable: false,
            flex: 1,
            minWidth: 160,
            maxWidth: 220,
            valueGetter: (params: any) => `${params.row.product?.detailModel?.fullRefNo}`,
        },
        {field: 'productPrice', headerName: "구매가", editable: false, sortable: false,
            align: 'right',
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            valueGetter: (params: any) => {
                const price = params.row.productPrice
                return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
            },
            renderCell: (params: any) => (
              <div style={{ textAlign: "center" }}>
                  <p>
                      {params.row.product?.currency == 'KRW' ? numberWithCommas(params.row.product?.price) + '원' : (params.row.product?.currency == 'USD' ? '$' + numberWithCommas(params.row.product?.price) : numberWithCommas(params.row.product?.price))}
                      <br />
                      {params.row.currency != 'KRW' ? (params.row.currency + (params.row.currency == 'USD' ? ' $' : '') + numberWithCommas(params.row.productPrice)) : ''}
                  </p>
              </div>
            ),
        },
        {field: 'amount', headerName: "결제 금액", editable: false, sortable: false,
            align: 'right',
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            valueGetter: (params: any) => {
                const price = params.row.paymentAmount
                const currency = params.row.currency
                if (currency == 'KRW') {
                    return `${numberWithCommas(price)}원`
                } else if (currency == 'USD') {
                    return `${currency} $${numberWithCommas(price)}`
                } else {
                    return `${currency} ${numberWithCommas(price)}`
                }
            }
        },
        {
            field: "buyerId",
            headerName: "구매자 ID",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
            maxWidth: 160,
            valueGetter: (params: any) => `${params.row.buyer?.id}`,
        },
        {
            field: 'phone',
            headerName: "전화번호",
            hide: true,
            valueGetter: (params: any) => `${params.row.buyer?.phone}`,
        },
        {
            field: 'orderedAt',
            headerName: "구매일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{toDateStr(params.row.orderedAt, false)}<br/>
                        {toTimeStr(params.row.orderedAt)}</p>
                </div>
            )
        },
        {
            field: 'transactionAt',
            headerName: "배송요청일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>배송<br/>요청일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{toDateStr(params.row.startShippingAt, false)}<br/>
                        {toTimeStr(params.row.startShippingAt)}</p>
                </div>
            )
        },
        {
            field: 'lastUpdateAt',
            headerName: "배송완료일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>배송<br/>완료일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.row.completeShippingAt),
            renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{toDateStr(params.row.completeShippingAt, false)}<br/>
                        {toTimeStr(params.row.completeShippingAt)}</p>
                </div>
            )
        },
    ]
}

type BuyOrderShippingProps = {
    state: BuyOrderState,
    dispatch: any,
    fetch: Function,
    components: any[],
}

const BuyOrderShippingTable: React.FC<BuyOrderShippingProps> = ({state, dispatch, fetch, components}) => {

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{justifyContent: "flex-end"}}>
                {components}
            </GridToolbarContainer>
        );
    }

    const handleSelection = (ids: any[]) => {dispatch({type: 'SELECTION_BUYORDER_IDS', payload: ids as string[]})}
    const handleEditButtonClick = (row: BuyOrderType) => {
        dispatch({type: 'SHOW_VIEW_BUYORDER_POPUP', payload: row})
    }

    const handleCompleteShippingButtonClick = () => {
        Promise.all(
            state.selectedIds.map(buyOrderId => testAPI.completeShippingByBuyOrderId(buyOrderId))
        ).then(() => {
            dispatch({type: 'REFRESH'})
        })
    }

    return (
        <Grid container spacing={1} sx={{pt: 1}}>
            <Grid item xs={12}>
            <div style={{height: 1200, width: '100%'}}>
                <DataGrid
                    components={{Toolbar: CustomToolbar}}
                    getRowId={row => row.id!}
                    columns={createColumns(handleEditButtonClick)}

                    pagination
                    {...dispatch.detailModels?.content}
                    paginationMode="server"
                    onPageChange={(page) => {
                        fetch({page, size: state.buyOrders.pageable?.pageSize || 25})
                    }}
                    onPageSizeChange={(size) => {
                        fetch({page: 0, size})
                    }}

                    page={state.buyOrders.pageable?.pageNumber || 0}
                    pageSize={state.buyOrders.pageable?.pageSize || 25}
                    rowCount={state.buyOrders?.totalElements || 0}
                    rows={state.buyOrders?.content || []}

                    selectionModel={state.selectedIds}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    onSelectionModelChange={handleSelection}
                    loading={state.loading}
                />
            </div>
            </Grid>
        </Grid>)
}

// @ts-ignore
export default BuyOrderShippingTable
