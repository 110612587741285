import ServiceBannerTable from "./ServiceBannerTable";
import ServiceBannerToolbar from "./ServiceBannerToolbar";
import {Stack} from "@mui/material";
import ServiceBannerCreatePopup from "./ServiceBannerCreatePopup";
import {useState} from "react";
import ServiceBannerUpdatePopup from "./ServiceBannerUpdatePopup";
import DeleteConfirmPopup from "../../../components/Popup/DeleteConfirmPopup";
import ShopServiceBannerAPI from "../../../apis/shopServiceBannerAPI";
import { ShopServiceBannerType } from "../../../types/ShopServiceBannerType"

const ServiceBannerTab = () => {
    const [showCreateServiceBanner, setShowCreateServiceBanner] = useState(false)
    const [updateServiceBanner, setUpdateServiceBanner] = useState<ShopServiceBannerType | null>(null)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectionIds, setSelectionIds] = useState<number[]>([])
    const [refreshCounter, setRefreshCounter] = useState(0)

    const refresh = () => setRefreshCounter(refreshCounter + 1)

    return (
        <Stack direction={"column"} spacing={1}>
            <ServiceBannerToolbar handleCreateServiceBanner={() => setShowCreateServiceBanner(true)}
                                  handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
                                  disabledDeleteButton={ selectionIds.length == 0}
            />
            <ServiceBannerTable
                handleSelection={(ids: number[]) => { setSelectionIds(ids) }}
                handleUpdateServiceBanner={(serviceBanner: ShopServiceBannerType) => setUpdateServiceBanner(serviceBanner)}
                refreshCounter={refreshCounter}
            />
            <ServiceBannerCreatePopup open={showCreateServiceBanner} handleClose={() => {
                refresh()
                setShowCreateServiceBanner(false)
            }}/>
            <ServiceBannerUpdatePopup serviceBanner={updateServiceBanner} handleClose={() => {
                refresh()
                setUpdateServiceBanner(null)
            }}/>
            <DeleteConfirmPopup open={showDeletePopup} handleClose={() => {
                refresh()
                setShowDeletePopup(false)
            }} handleConfirm={() => {
                Promise.all(selectionIds.map((id) => ShopServiceBannerAPI.delete(id)))
                    .then((results) => {
                        refresh()
                        setShowDeletePopup(false)
                    })
            }}/>
        </Stack>
    )
}

export default ServiceBannerTab