import { httpDelete, httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { PageType } from "types/PageType"

import { MdPickType, MdPickCreateBodyType } from "types/MdPickType"

export const getMdPickDetail: (curationId: number) => Promise<MdPickType> = (curationId) =>
  httpGet(`/shop/md-picks/${curationId}`)

export const getMdPickList: (params?: { [key: string]: any }) => Promise<PageType<MdPickType>> = (params) =>
  httpGet("/shop/md-picks", params)

export const updateMdPickDetail: (curationId: number, curation: MdPickCreateBodyType) => Promise<MdPickType> = (
  curationId,
  curation
) => httpPut(`/shop/md-picks/${curationId}`, curation)

export const createMdPick: (curation: MdPickCreateBodyType) => Promise<MdPickType> = (curation) =>
  httpPost("/shop/md-picks", curation)

export const deleteMdPickList: (idList: string) => Promise<void> = (idList) =>
  httpDelete("/shop/md-picks", { ids: idList })
