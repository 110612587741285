import "react-toastify/dist/ReactToastify.css"
import "./App.css"

import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import * as Sentry from "@sentry/react"

import App from "./App"
import { AuthProvider } from "./AuthContext"

if (
  process.env.NODE_ENV === "production" // build 버전인지 확인
  && process.env.REACT_APP_API_HOSTNAME === "office.viver.co.kr" // production 레벨인지 확인
) {
  Sentry.init({
    dsn: "https://3f53a951ec08492591f800e3863adadc@o1378462.ingest.sentry.io/4505162952409088",
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      })
    ]
  })
}

function render(elementRoot?: HTMLElement | null) {
  if (!(elementRoot instanceof HTMLElement)) return
  ReactDOM.createRoot(elementRoot).render(
    <BrowserRouter>
      <AuthProvider>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          theme={"colored"}
          draggable
          pauseOnHover
          style={{
            zIndex: 10001,
          }}
        />
      </AuthProvider>
    </BrowserRouter>
  )
}

render(document.getElementById("root"))
