import styled from "@emotion/styled"
import { Badge, IconButton } from "@mui/material"
import NoteAltIcon from "@mui/icons-material/NoteAlt"
import { SerialNumberDetailModal } from "../SerialNumberDetailModal"
import { useEffect, useState } from "react"
import { getSerialNumberMemoCount } from "apis/serialAPI"

type Props = {
  serialNo: string
}

export const SerialNumberView = ({ serialNo }: Props) => {
  const [memoCount, setMemoCount] = useState(0)
  const [isOpenSerialDetailModal, setIsOpenSerialDetailModal] = useState(false)

  useEffect(() => {
    getSerialNumberMemoCount(serialNo).then((response) => {
      const activeMemoCoount = response.find((item) => item.label === "활성")?.count || 0
      setMemoCount(activeMemoCoount)
    })
  }, [serialNo])

  return (
    <>
      {isOpenSerialDetailModal && (
        <SerialNumberDetailModal serialNumber={serialNo} closeModal={() => setIsOpenSerialDetailModal(false)} />
      )}
      <Wrapper>
        <InlineWrapper>
          <SerialNumber>{serialNo}</SerialNumber>
          <IconButton onClick={() => setIsOpenSerialDetailModal(true)}>
            <Badge badgeContent={memoCount} color="primary" max={9} showZero>
              <NoteAltIcon />
            </Badge>
          </IconButton>
        </InlineWrapper>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  flex: 1;
`

const SerialNumber = styled.div`
  color: #437acf;
  font-size: 15px;
  font-weight: 800;
  text-decoration: underline;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
