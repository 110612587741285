import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { RequestCancelShippingType } from "pages/Penalty/PenaltyListPage"
import ShippingRequestTable from "./ShippingRequestTable"

type Props = {
  open: boolean
  selectedShipping: RequestCancelShippingType[]
  handleClose: () => void
}

const CancelShippingRequestPopup = ({ open, selectedShipping, handleClose }: Props) => {
  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>상품 반송 요청</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <ShippingRequestTable shippings={selectedShipping} close={handleClose} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelShippingRequestPopup
