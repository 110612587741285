import styled from "@emotion/styled"
import Button from "components/Button"
import PopupPage from "components/Popup/PopupPage"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import PenaltyRefundInformation from "./PenaltyRefundInformation"
import PenaltySettlementInformation from "./PenaltySettementInformation"
import PenaltyTypeInformation from "./PenaltyTypeInformation"
import PenaltySellOrderInformation from "./SellOrderInformation"
import CancelShippingInformation from "./CancelShippingInformation"
import { usePenaltyDetail } from "./usePenaltyDetail"
import { getPenaltyShippingStatus } from "../utils"
import { PenaltyFeeType } from "types/PenaltyType"

type Props = {
  selectedPenaltyOrderId: string
  closePopup: () => void
}

const PenaltyDetailPopup = ({ selectedPenaltyOrderId, closePopup }: Props) => {
  const { penaltyDetail, refetch } = usePenaltyDetail(selectedPenaltyOrderId)

  if (!penaltyDetail) {
    return <></>
  }

  //무조건 서버에서 fee list는 두개까지만 내려오므로 '판매 업그레이드 수수료'가 아닌건 패널티로 처리
  const penaltyFee: PenaltyFeeType = penaltyDetail.breakdown.fees.filter(
    (fee) => fee.feePolicyType !== "판매 업그레이드 수수료"
  )[0]

  return (
    <PopupPage isOpen style={{ maxWidth: "1000px" }}>
      <ContentWrapper>
        <PopupPageTitle>패널티 상세 관리</PopupPageTitle>
        <PopupPageScrollContainer>
          <h2>패널티 관리 상품 정보</h2>
          <PenaltySellOrderInformation
            status={penaltyDetail.status}
            sellOrder={penaltyDetail?.sellOrder}
            createdAt={penaltyDetail.createdAt}
            completedAt={penaltyDetail.completedAt}
          />
          <Spacer />
          <h2>패널티 발생 유형 (판매포기 / 불합격)</h2>
          <PenaltyTypeInformation
            penaltyType={penaltyFee?.feePolicyType}
            initialPrice={penaltyDetail.sellOrder.product.initialPrice}
            description={penaltyDetail.description}
            penaltyAmount={penaltyFee?.amount}
            penaltyFeeRate={penaltyFee?.rate}
          />
          <Spacer />
          <h2>패널티 납부</h2>
          <PenaltySettlementInformation
            breakdown={penaltyDetail.breakdown}
            paymentAmount={penaltyDetail.paymentAmount}
            initialPrice={penaltyDetail.sellOrder.product.initialPrice}
            completedAt={penaltyDetail.completedAt}
            status={penaltyDetail.status}
          />
          <Spacer />
          <h2>패널티 조정 내역</h2>
          <PenaltyRefundInformation
            refetch={refetch}
            orderId={penaltyDetail.orderId}
            paymentAmount={penaltyDetail.paymentAmount}
            refundAmount={penaltyDetail.refundAmount}
            refundReason={penaltyDetail.refundReason}
            refundedAt={penaltyDetail.refundedAt}
          />
          <Spacer />

          <h2>배송정보</h2>
          <CancelShippingInformation
            shipping={penaltyDetail.sellOrder.shipping}
            cancelShippingStatus={getPenaltyShippingStatus(penaltyDetail)}
          />
        </PopupPageScrollContainer>

        <PopupPageFooter>
          <AlignWrapper>
            <ButtonWrapper>
              <Button buttonStyle="blue-border" onClick={closePopup}>
                닫기
              </Button>
            </ButtonWrapper>
          </AlignWrapper>
        </PopupPageFooter>
      </ContentWrapper>
    </PopupPage>
  )
}

export default PenaltyDetailPopup

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`

const ButtonWrapper = styled.div`
  width: 200px;
  text-align: right;
`

const Spacer = styled.div`
  width: 20px;
  height: 20px;
`

const AlignWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
`
