import {Button, Paper, Stack, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import BannerTab from "./BannerTab";
import {useState} from "react";
import ShopContentReorderPopup from "./ShopContentReorderPopup";
import ProductCurationTab from "./ProductCurationTab";
import DetailModelCurationTab from "./DetailModelCurationTab";
import LikedDetailModelProductCurationTab from "./LikedDetailModelProductCurationTab";
import BrandModelCurationTab from "./BrandModelCurationTab"
import SwipeBannerTab from "./SwipeBannerTab"

const ShopContentTab = () => {
    const [tab, setTab] = useState<string>("bannerTab")
    const [showReorderShopContentPopup, setShowReorderShopContentPopup] = useState(false)

    function handleChangeTab(newTab: string) {
        if (tab != newTab) {
            setTab(newTab)
        }
    }

    function handleReorderShopContentPopup() {
        setShowReorderShopContentPopup(true)
    }

    return (
        <Paper sx={{p: 2}}>
            <Stack direction={"column"} spacing={1} justifyContent={"center"}>
                <Stack   direction="row"
                         justifyContent="flex-end"
                         alignItems="center"
                         spacing={0}>
                    <Button variant={"contained"} size={'small'} onClick={handleReorderShopContentPopup}>홈 노출 순서 변경</Button>
                </Stack>
                <TabContext value={tab}>
                    <TabList onChange={(_e, value) => handleChangeTab(value)}>
                        <Tab value={'bannerTab'} label={'배너'}/>
                        <Tab value={'swipeBannerTab'} label={'스와이프배너'}/>
                        <Tab value={'productCurationTab'} label={'상품 큐레이션'}/>
                        <Tab value={'detailModelCurationTab'} label={'모델 큐레이션'}/>
                        <Tab value={'brandModelCurationTab'} label={'브랜드 인기 모델'}/>
                        {/*사용하지 않는 메뉴라 비활성 처리*/}
                        {/*<Tab value={'likedDetailModelProductCurationTab'} label={'관심 모델 상품 큐레이션'}/>*/}
                    </TabList>
                    <TabPanel value={"bannerTab"}><BannerTab/></TabPanel>
                    <TabPanel value={"swipeBannerTab"}><SwipeBannerTab/></TabPanel>
                    <TabPanel value={"productCurationTab"}><ProductCurationTab/></TabPanel>
                    <TabPanel value={"detailModelCurationTab"}><DetailModelCurationTab/></TabPanel>
                    <TabPanel value={"likedDetailModelProductCurationTab"}><LikedDetailModelProductCurationTab/></TabPanel>
                    <TabPanel value={"brandModelCurationTab"}><BrandModelCurationTab/></TabPanel>
                </TabContext>
            </Stack>
            <ShopContentReorderPopup open={showReorderShopContentPopup} handleClose={() => { setShowReorderShopContentPopup(false) }}/>
        </Paper>
    )
}

export default ShopContentTab