import { ShippingType } from "types/ShippingType"
import { UserType } from "types/UserType"
import { PaymentType } from "types/PaymentType"

export enum UpgradeServiceOrderStatus {
  결제대기 = "결제대기",
  결제완료 = "결제완료",
  결제만료 = "결제만료",
  수리중 = "수리중",
  수리완료 = "수리완료",
  등록완료 = "등록완료",
}

enum UpgradeServiceCode {
  폴리싱 = "폴리싱",
  오버홀 = "오버홀",
}
export default UpgradeServiceCode

export type UpgradeServiceOrderListItemType = {
  id: string
  status: UpgradeServiceOrderStatus
  orderType: "구매"
  title: string
  product: {
    id: number
    serialNo: string
    detailModel: {
      id: number
      refNo: string
      fullRefNo: string
      title: string
      titleSecondary: string
      name: string
      thumbnail: string
      brand: {
        sid: string
        name: string
      }
    }
  }
  totalAmount: number
  paymentAmount: number
  expireAt?: string
  notifiedAt?: string
}

export type UpgradeServiceOrderListSearchParams = {
  size?: number
  page?: number
  productId?: string
  id?: string
  status?: string
}

export type UpgradeServiceOrderDetailType = {
  id: string
  status: UpgradeServiceOrderStatus
  user: UserType
  orderType: "구매"
  items: []
  product: {
    id: number
    serialNo: string
    detailModel: {
      id: number
      refNo: string
      fullRefNo: string
      title: string
      titleSecondary: string
      name: string
      thumbnail: string
      brand: {
        sid: string
        name: string
      }
    }
  }
  totalAmount: number
  pointAmount: number
  discountAmount: number
  paymentAmount: number
  taxAmount: number
  taxFreeAmount: number
  shipping: {
    journey: ShippingType["journey"]
  }
  returnShipping: {
    journey: ShippingType["journey"]
  }
  payments: PaymentType[]
  expireAt: string
  paymentAt: string
  notifiedAt?: string
  notifiedBy: {
    id: number
    name: string
  }
  startUpgradeAt?: string
  startUpgradeBy: {
    id: number
    name: string
  }
  completeUpgradeAt?: string
  completeUpgradeBy: {
    id: number
    name: string
  }
  canceledAt?: string
  canceledBy: {
    id: number
    name: string
  }
  createdAt: string
  createdBy: {
    id: number
    name: string
  }
  updatedAt: string
  updatedBy: {
    id: number
    name: string
  }
}

export type UpgradeServiceItemType = {
  label: string
  feeType: "유상" | "무상"
  fee: number
  note?: string
}

export type PostUpgradeServiceOrderBody = {
  orderType: "구매"
  userId: number
  items: UpgradeServiceItemType[]
  productId: string
}

export type PutUpgradeServiceOrderBody = {
  items?: UpgradeServiceItemType[]
  productId?: number
  userId?: number
  orderType?: string
}
