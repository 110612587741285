import React from "react"
import { Button } from "@mui/material"

type Props = {
  onSave: () => void,
  onDelete: () => void,
}

const TranslationColumnManagerAction: React.FC<Props> = ({ onSave, onDelete }) => {
  return (
    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginBottom: "16px" }}>
      <Button
        variant={"contained"}
        color={"error"}
        size={"small"}
        style={{ margin: "0 2px" }}
        onClick={onDelete}
      >
        삭제
      </Button>
      <Button
        variant={"contained"}
        color={"info"}
        size={"small"}
        style={{ margin: "0 2px" }}
        onClick={onSave}
      >
        저장
      </Button>
    </div>
  )
}

export default TranslationColumnManagerAction