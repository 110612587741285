import styled from "@emotion/styled"
import * as Icons from "@mui/icons-material"
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material"
import React, { FunctionComponent, useContext, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import authRouteInfo, { findRoute } from "../../authRouteInfo"
import { AuthContext } from "../../AuthContext"

interface PropsType {
  asideWidth: number
  isOpenAside: boolean
  toggleIsOpenAside: () => void
}
const LayoutNavigationTitleBar: FunctionComponent<PropsType> = ({ asideWidth, isOpenAside, toggleIsOpenAside }) => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()

  const { user, logout } = useContext(AuthContext)
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null)

  const params = useParams()
  const title = useMemo(() => {
    let routeInfo
    if (Object.keys(params).length > 0) {
      const parts = location.pathname.split("/")
      parts[parts.length - 1] = ":" + Object.keys(params)[0]
      routeInfo = findRoute(parts.join("/"))
    } else {
      routeInfo = findRoute(location.pathname)
    }

    return routeInfo ? routeInfo.title : "Not Found"
  }, [location.pathname])

  const handleProfileClick = (event: React.MouseEvent<HTMLDivElement>) => setProfileMenuAnchorEl(event.currentTarget)
  const handleProfileMenuClose = () => setProfileMenuAnchorEl(null)

  return (
    <StyledAppBar theme={theme} asideWidth={asideWidth} isOpenAside={isOpenAside}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleIsOpenAside}
          sx={{
            marginRight: "36px",
            ...(isOpenAside && { display: "none" }),
          }}
        >
          <Icons.Menu />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <Menu anchorEl={profileMenuAnchorEl} open={profileMenuAnchorEl != null} onClose={handleProfileMenuClose}>
            <MenuItem onClick={() => logout?.(() => navigate("/login"))}>로그아웃</MenuItem>
          </Menu>
          <Avatar
            src={user?.profileImageUrl}
            sx={{ width: 30, height: 30 }}
            style={{ cursor: "pointer" }}
            onClick={handleProfileClick}
          />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  )
}
export default LayoutNavigationTitleBar

const StyledAppBar = styled(AppBar)(
  ({ theme, asideWidth, isOpenAside }: { theme: Theme; asideWidth: number; isOpenAside: boolean }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isOpenAside && {
      marginLeft: asideWidth,
      width: `calc(100% - ${asideWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
)
