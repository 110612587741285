import { toast } from "react-toastify"
import {
  CouponAvailableDateTypeCode,
  CouponCodeBodyType,
  CouponCodeStatusCode,
  CouponCodeType,
  CouponTypeCode,
} from "types/CouponCodeType"
import { DateTime } from "luxon"

export const initialCouponCodeState: CouponCodeType = {
  code: "",
  status: CouponCodeStatusCode.활성,
  name: "",
  type: CouponTypeCode.포인트지급,
  maxUsageCount: 1,
  usageCount: 0,
  points: 0,
  availableDateType: CouponAvailableDateTypeCode.만료일_직접_지정,
  availableDays: 30,
  availableDate: DateTime.now().plus({ days: 30 }).toISODate(),
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().plus({ days: 7 }).toISO(),
  signupBasedAvailableDays: undefined,
  createdAt: "",
  createdBy: 0,
  updatedAt: "",
  updatedBy: 0,
}

export const parseCouponCodeBody = (form: CouponCodeType): CouponCodeBodyType => {
  return {
    code: form.code,
    status: form.status,
    name: form.name,
    type: form.type,
    maxUsageCount: form.maxUsageCount,
    points: form.points,
    availableDateType: form.availableDateType,
    availableDays: form.availableDays,
    availableDate: form.availableDate,
    startedAt: form.startedAt,
    endedAt: form.endedAt,
    signupBasedAvailableDays: form.signupBasedAvailableDays,
  }
}

export const validateCouponCodeForm = (form: CouponCodeBodyType) => {
  const omittableKey = [
    "code",
    "usageCount",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
    "signupBasedAvailableDays",
  ]
  let isValid = true

  for (const [key, value] of Object.entries(form)) {
    if (!value && !omittableKey.includes(key)) {
      toast.error(mapRequiredFormKeyToErrorMessage(key))
      isValid = false
      console.log(`${key}: ${isValid}`)
    }
  }

  return isValid
}

const mapRequiredFormKeyToErrorMessage = (key: string) => {
  switch (key) {
    case "name":
      return "이름을 입력해주세요."
    case "status":
      return "쿠폰 상태를 선택해주세요"
    case "type":
      return "쿠폰 타입을 선택해주세요"
    case "maxUsageCount":
      return "최대 발행 수량을 입력해주세요."
    case "points":
      return "지급할 포인트를 입력해주세요."
    case "availableDateType":
    case "availableDays":
    case "availableDate":
      return "지급될 포인트의 유효기간을 입력해주세요."
    case "startedAt":
      return "쿠폰 코드의 발행시작일시를 입력해주세요."
    case "endedAt":
      return "쿠폰 코드의 발행종료일시를 입력해주세요."
  }
}
