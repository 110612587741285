import React, { useEffect, useReducer, useState } from "react"
import { findAdminUser, updateAdminUser } from "../../../apis/adminUserAPI"
import {
  AdminAuthorityType,
  AdminGroupType,
  AdminUserListSearchParams,
  AdminUserType,
} from "../../../types/AdminUserType"
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { toast } from "react-toastify"

type AdminUserDetailPagePopupProps = {
  open: boolean
  adminUserId?: number
  handleClose: () => void
  groupCodes: AdminGroupType[]
  authorities: AdminAuthorityType[]
}

export declare interface AdminUserDetailState {
  adminUser: AdminUserType
  loading?: boolean
}

const initState: AdminUserDetailState = {
  adminUser: {
    id: undefined,
    name: undefined,
    email: undefined,
    profileImageUrl: undefined,
    menus: [],
    registrationId: undefined,
    authorities: [],
    groupName: undefined,
    menuViewId: undefined,
  },
  loading: false,
}

interface AdminUserDetailAction {
  type: string
  payload?: any
}

function reducer(state: AdminUserDetailState, action: AdminUserDetailAction) {
  switch (action.type) {
    case "FETCH_ADMIN_USER_BY_ID":
      return { ...state, adminUser: action.payload }
    case "CHANGE_ADMIN_USER_NAME":
      return { ...state, adminUser: { ...state.adminUser, name: action.payload } }
    case "CHANGE_ADMIN_USER_GROUP":
      return {
        ...state,
        adminUser: { ...state.adminUser, groupName: action.payload.groupName, menuViewId: action.payload.id },
      }
    case "CHANGE_ADMIN_USER_AUTHORITIES":
      return { ...state, adminUser: { ...state.adminUser, authorities: action.payload } }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const AdminUserDetailPagePopup: React.FC<AdminUserDetailPagePopupProps> = ({
  open,
  adminUserId,
  handleClose,
  groupCodes,
  authorities,
}) => {
  const [state, dispatch] = useReducer(reducer, initState)

  useEffect(() => {
    if (open && adminUserId) {
      dispatch({ type: "START_LOADING" })
      findAdminUser(adminUserId)
        .then((res) => {
          dispatch({ type: "FETCH_ADMIN_USER_BY_ID", payload: res })
        })
        .finally(() => dispatch({ type: "END_LOADING" }))
    }
  }, [open, adminUserId])

  const handleSave = async () => {
    if (adminUserId) {
      await updateAdminUser(adminUserId, {
        id: state.adminUser.id,
        name: state.adminUser.name,
        authorities: state.adminUser.authorities,
        menuViewId: state.adminUser.menuViewId,
      })
      toast.success("회원 수정이 완료되었습니다.")
      handleClose()
    }
  }

  if (state.loading) return <CircularProgress />

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography fontWeight={"bold"} fontSize={"30px"}>
            어드민 유저 정보
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>이름</td>
              <td className={"header"}>이메일</td>
              <td className={"header"}>그룹</td>
            </tr>
            <tr>
              <td>
                <TextField
                  value={state.adminUser.name}
                  onChange={(e) => dispatch({ type: "CHANGE_ADMIN_USER_NAME", payload: e.target.value })}
                  fullWidth
                />
              </td>
              <td>{state.adminUser?.email}</td>
              <td>
                <Select
                  value={state.adminUser.groupName}
                  onChange={(e) => {
                    dispatch({
                      type: "CHANGE_ADMIN_USER_GROUP",
                      payload: groupCodes.find((value) => value.groupName == e.target.value),
                    })
                  }}
                  fullWidth
                >
                  {groupCodes.map((code) => (
                    <MenuItem key={code.id} value={code.groupName}>
                      {code.groupName}
                    </MenuItem>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className={"header"}>
                권한
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Autocomplete
                  fullWidth
                  multiple
                  value={authorities.filter((auth) =>
                    state.adminUser.authorities?.find((it: string) => it === auth.id)
                  )}
                  getOptionLabel={(option) => option.name}
                  options={authorities}
                  onChange={(_, value) => {
                    dispatch({ type: "CHANGE_ADMIN_USER_AUTHORITIES", payload: value.map((auth) => auth.id) })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                      }}
                    />
                  )}
                ></Autocomplete>
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={handleSave}>
          수정
        </Button>
        <Button color="error" onClick={handleClose}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AdminUserDetailPagePopup
