export type SettlementType = {
  id?:number;
  orderId:string;
  userId:number;
  confirmStatus:'대기'|'승인';
  settlementStatus:SettlementStatusCode;
  productPrice:number;
  amount:number;
  feeAmount:number;
  payoutDate:string; // 지급 요청일
  confirmedAt:string; //지급 승인일
  product:{
    id:number;
    title:string;
    titleSecondary:string
  };
  payoutId?:number;
}

export const enum SettlementStatusCode {
  지급_대기 = "지급 대기",
  지급_처리중 = "지급 처리중",
  지급_요청_완료 = "지급 요청 완료",
  지급_취소 = "지급 취소",
  지급_완료 = "지급 완료",
  지급_거절 = "지급 거절"
}
