import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { DateTime } from "luxon"
import { ProductState } from "./index"
import { numberWithCommas, truncateWithCommas } from "../../utils/NumberUtils"
import { toDateStr, toDateTimeStr, toTimeStr } from "../../utils/datetimeUtil"
import { Button, Stack } from "@mui/material"
import { OrderType, SellOrderListSearchParams, SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { downloadExcel } from "../../utils/excelUtil"
import { getSellOrderList } from "../../apis/sellOrderAPI"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

function createColumns(handleEditButtonClick: Function): any[] {
  const openProductPopup = (row: SellOrderType) => {
    handleEditButtonClick(row)
  }
  const getColorByStatus = (status: SellOrderStatusCode) => {
    switch (status) {
      case SellOrderStatusCode.판매중:
        return "error"
      case SellOrderStatusCode.결제_대기:
        return "warning"
      case SellOrderStatusCode.판매_완료:
        return "success"
    }
  }

  return [
    {
      field: "id",
      headerName: "판매주문번호",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 130,
      maxWidth: 160,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          상품 번호 <br /> (판매주문 번호)
        </p>
      ),
      valueGetter: (params: any) => `${params.row.id}`,
      renderCell: (params: any) => (
        <div style={{ textAlign: "left" }}>
          <p>
            <span style={{ fontWeight: "bold" }}>{params.row.product?.id}</span>
            <br />
            <span style={{ color: "#aaa" }}>{params.row.id}</span>
          </p>
        </div>
      ),
    },
    {
      field: "productId",
      headerName: "상품번호",
      hide: true,
      valueGetter: (params: any) => `${params.row.product?.id}`,
    },
    {
      field: "status",
      headerName: "진행상태",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.sellOrderStatus}`
      },
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openProductPopup(params.row)
            }}
            color={getColorByStatus(params.row.sellOrderStatus)}
          >
            {params.row.sellOrderStatus}
          </Button>
        </div>
      ),
    },
    {
      field: "onDisplay",
      headerName: "노출상태",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 60,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.product?.onDisplay ? "노출" : "숨김"}`,
      renderCell: (params: any) => (
        <div>
          <Button size={"small"} variant={"text"} color={params.row.product?.onDisplay ? "success" : "error"}>
            {params.row.product?.onDisplay ? "노출" : "숨김"}
          </Button>
        </div>
      ),
    },
    {
      field: "saleType",
      headerName: "판매방식",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 100,
      maxWidth: 140,
      valueGetter: (params: any) => `${params.row.product?.saleType}`,
    },
    // {
    //     field: "repurchaseGuarantee",
    //     headerName: "매입보증",
    //     editable: false,
    //     sortable: false,
    //     align: "center",
    //     flex: 1,
    //     minWidth: 60,
    //     maxWidth: 80,
    //     valueGetter: (params: any) => `${params.row.product?.repurchaseGuarantee ? "O" : "-"}`,
    // },
    {
      field: "exhibitionSale",
      headerName: "전시판매",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 70,
      maxWidth: 70,
      valueGetter: (params: any) => {
        return `${params.row?.exhibitionSale}`
      },
      renderCell: (params: any) => (
        <div>
          {params.row?.exhibitionSale ? (
            <Button size={"small"} style={{ fontWeight: params.row?.exhibitionSale ? 700 : 400 }}>
              동의
            </Button>
          ) : (
            <Button size={"small"} style={{ color: "#999999" }}>
              미동의
            </Button>
          )}
        </div>
      ),
    },
    {
      field: "globalSale",
      headerName: "해외판매",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.product?.globalSale ? "동의" : "미동의"}`,
      renderCell: (params: any) => (
        <div>
          <Button size={"small"} variant={"text"} color={params.row.product?.globalSale ? "success" : "error"}>
            {params.row.product?.globalSale ? "동의" : "미동의"}
          </Button>
        </div>
      ),
    },
    {
      field: "brandName",
      headerName: "브랜드",
      editable: false,
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
      valueGetter: (params: any) => `${params.row.product?.brand?.name}`,
    },
    {
      field: "title",
      headerName: "상품명",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 220,
      valueGetter: (params: any) => `${params.row.product?.title}`,
      renderCell: (params: any) => (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>{params.row.product?.title}</span>
            <br />
            <span style={{ color: "#aaa" }}>{params.row.product?.titleSecondary}</span>
          </p>
        </div>
      ),
    },
    {
      field: "fullRefNo",
      headerName: "Ref No.",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 160,
      maxWidth: 220,
      valueGetter: (params: any) => `${params.row.product?.detailModel?.fullRefNo}`,
    },
    {
      field: "estimatedPrice",
      headerName: "바이버제안가",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.productDiagnosis?.viverPrices?.finalPrice
        return price ? `${truncateWithCommas(price, 4)} 만원` : "-"
      },
    },
    {
      field: "userDesiredPrice",
      headerName: "상점판매가",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.product?.price
        return price ? `${truncateWithCommas(price, 4)} 만원` : "-"
      },
    },
    {
      field: "percent",
      headerName: "괴리율",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 110,
      valueGetter: (params: any) => {
        const estimated = params.row.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.avg
        const price = params.row.product?.price
        return estimated ? ((price / estimated) * 100).toFixed(1) : ""
      },
      renderCell: (params: any) => {
        const estimated = params.row.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.avg
        const price = params.row.product?.price
        const percent = estimated ? (price / estimated - 1) * 100 : null

        if (percent == null) return <p>-</p>
        else if (percent > 0) return <p style={{ color: "red" }}>▲ {percent.toFixed(1)}%</p>
        else if (percent < 0) return <p style={{ color: "blue" }}>▼ {percent.toFixed(1)}%</p>
        else return <p>{percent}%</p>
      },
    },
    // {
    //     field: "sellerName",
    //     headerName: "판매자 정보",
    //     editable: false,
    //     sortable: false,
    //     flex: 1,
    //     minWidth: 120,
    //     maxWidth: 160,
    //     valueGetter: (params: any) => `${params.row.seller?.name}`,
    //     renderCell: (params: any) => (
    //         <div>
    //             <p>
    //                 {params.row.seller?.name}
    //                 <br/>
    //                 {params.row.seller?.phone}
    //             </p>
    //         </div>
    //     ),
    // },
    // {
    //     field: "phone",
    //     headerName: "전화번호",
    //     hide: true,
    //     valueGetter: (params: any) => `${params.row.seller?.phone}`,
    // },
    // {
    //     field: "buyerName",
    //     headerName: "구매자 정보",
    //     editable: false,
    //     sortable: false,
    //     flex: 1,
    //     minWidth: 120,
    //     valueGetter: (params: any) => `${params.row.buyOrder?.buyer?.name}`,
    //     renderCell: (params: any) => (
    //         <div>
    //             <p>
    //                 {params.row.buyOrder?.buyer?.name}
    //                 <br/>
    //                 {params.row.buyOrder?.buyer?.phone}
    //             </p>
    //         </div>
    //     ),
    // },
    // {
    //     field: "phone",
    //     headerName: "전화번호",
    //     hide: true,
    //     valueGetter: (params: any) => `${params.row.buyOrder?.buyer?.phone}`,
    // },
    {
      field: "acceptedAt",
      headerName: "판매신청일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          신청일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "-",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.acceptedAt, false)}
            <br />
            {toTimeStr(params.row.acceptedAt)}
          </p>
        </div>
      ),
    },
    {
      field: "onSaleAt",
      headerName: "상품등록일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          상품
          <br />
          등록일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.onSaleAt, false)}
            <br />
            {toTimeStr(params.row.onSaleAt)}
          </p>
        </div>
      ),
    },
    {
      field: "completeSaleAt",
      headerName: "판매완료일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          완료일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.completeSaleAt, false)}
            <br />
            {toTimeStr(params.row.completeSaleAt)}
          </p>
        </div>
      ),
    },
  ]
}

type ProductProps = {
  state: ProductState
  dispatch: any
  fetch: Function
  components: any[]
}

const ProductTable: React.FC<ProductProps> = ({ state, dispatch, fetch, components }) => {
  const [currentSearchParams] = useSearchParams()

  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }

  const handleSelection = (ids: any[]) => {
    dispatch({ type: "SELECTION_PRODUCT_IDS", payload: ids as string[] })
  }
  const handleEditButtonClick = (row: SellOrderType) => {
    dispatch({ type: "SHOW_EDIT_PRODUCT_POPUP", payload: row })
  }

  const handleDownloadExcel = () => {

    if (state.startDateFilter === undefined) {
      toast.error("시작일을 선택해주세요")
      return false
    }
    if (state.endDateFilter === undefined) {
      toast.error("종료일을 선택해주세요")
      return false
    }

    let newParams: SellOrderListSearchParams = {
      sellOrderType: [OrderType.위탁, OrderType.진단전, OrderType.직매입],
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) || [
        SellOrderStatusCode.판매중,
        SellOrderStatusCode.결제_대기,
        SellOrderStatusCode.판매_완료,
        SellOrderStatusCode.페널티_대기,
        SellOrderStatusCode.판매_포기,
      ],
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      sellerName: currentSearchParams.get("sellerName") ?? undefined,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getSellOrderList(newParams).then((orders) => {
      const data = orders.map((order) => {
        const orderedAt = order.orderedAt ? DateTime.fromISO(order.orderedAt) : null
        const onSaleAt = order.onSaleAt ? DateTime.fromISO(order.onSaleAt) : null
        const completeSaleAt = order.completeSaleAt ? DateTime.fromISO(order.completeSaleAt) : null

        const duringOnSaleHour = orderedAt && onSaleAt ? onSaleAt.diff(orderedAt, "hours").toObject()?.hours : ""
        const duringCompleteSaleHour =
          onSaleAt && completeSaleAt ? completeSaleAt.diff(onSaleAt, "hours").toObject()?.hours : ""
        const duringAllHour =
          orderedAt && completeSaleAt ? completeSaleAt.diff(orderedAt, "hours").toObject()?.hours : ""

        return {
          "상품 번호": order.product?.id,
          "판매주문 번호": order.id,
          "진행 상태": order.sellOrderStatus,
          "노출 상태": order.product?.onDisplay == true ? "노출" : "숨김",
          "판매 방식": order.product?.saleType,
          "전시 판매": order.exhibitionSale ? '동의' :'미동의',
          "해외 판매": order.product?.globalSale ? '동의' :'미동의',
          구분: order.product?.exposedProductInfo?.productCondition,
          브랜드: `${order.product?.brand?.name}`,
          상품명: `${order.product?.title} ${order.product?.titleSecondary}`,
          "Ref No.": order.product?.detailModel.fullRefNo,
          "바이버 제안가 (만원)": order.productDiagnosis?.viverPrices?.finalPrice
            ? numberWithCommas(order.productDiagnosis?.viverPrices?.finalPrice / 10000)
            : "",
          "상점판매가 (만원)": order.product?.price ? numberWithCommas(order.product?.price / 10000) : "",
          "부속품 이슈": "",
          연도: order.product?.exposedProductInfo?.stampingYear,
          "판매자 정보": `${order.seller?.name} ${order.seller?.phone}`,
          "구매자 정보": `${order.buyOrder?.buyer?.name ?? ""} ${order.buyOrder?.buyer?.phone ?? ""}`,
          "판매 신청일": `${toDateTimeStr(order.orderedAt) ?? ""}`,
          "상품 등록일": `${toDateTimeStr(order.onSaleAt) ?? ""}`,
          "판매 완료/취소 일": `${toDateTimeStr(order.completeSaleAt) ?? ""}`,
          구매일: toDateTimeStr(order.buyOrder?.orderedAt) ?? "",
          비고: "",
          "판매신청-상품등록 소요 시간 (시간)": duringOnSaleHour,
          "상품등록-판매완료 소요 시간 (시간)": duringCompleteSaleHour,
          "전체 소요 시간": duringAllHour,
        }
      })

      downloadExcel(data, "상품관리_판매중상품")
    })
  }

  return (
    <>
      <Stack spacing={1}>
        <Stack direction={"row"} justifyContent="flex-end" spacing={1}>
          <Button variant={"contained"} color={"primary"} size={"small"} onClick={handleDownloadExcel}>
            엑셀 다운로드
          </Button>
        </Stack>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            getRowId={(row) => row.id!}
            columns={createColumns(handleEditButtonClick)}
            pagination
            {...dispatch.detailModels?.content}
            paginationMode="server"
            onPageChange={(page) => {
              fetch({ page, size: state.sellOrders.pageable?.pageSize || 25 })
            }}
            onPageSizeChange={(size) => {
              fetch({ page: 0, size })
            }}
            page={state.sellOrders.pageable?.pageNumber || 0}
            pageSize={state.sellOrders.pageable?.pageSize || 25}
            rowCount={state.sellOrders?.totalElements || 0}
            rows={state.sellOrders?.content || []}
            selectionModel={state.selectedIds}
            checkboxSelection
            disableSelectionOnClick={true}
            onSelectionModelChange={handleSelection}
            loading={state.loading}
          />
        </div>
      </Stack>
    </>
  )
}

// @ts-ignore
export default ProductTable
