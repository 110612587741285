import HomeReviewTable from "./HomeReviewTable";
import HomeReviewToolbar from "./HomeReviewToolbar";
import {Stack} from "@mui/material";
import HomeReviewCreatePopup from "./HomeReviewCreatePopup";
import {useState} from "react";
import HomeReviewUpdatePopup from "./HomeReviewUpdatePopup";
import {ShopHomeReviewType} from "types/ShopHomeReviewType";
import DeleteConfirmPopup from "../../../components/Popup/DeleteConfirmPopup";
import ShopHomeReviewAPI from "../../../apis/shopHomeReviewAPI";


const HomeReviewTab = () => {
  const [showCreateHomeReview, setShowCreateHomeReview ] = useState(false)
  const [updateHomeReview, setUpdateHomeReview] = useState<ShopHomeReviewType | null>(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectionIds, setSelectionIds] = useState<number[]>([])
  const [refreshCounter, setRefreshCounter] = useState(0)

  const refresh = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Stack direction={"column"} spacing={1}>
      <HomeReviewToolbar handleCreateHomeReview={() => setShowCreateHomeReview(true)}
                         handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
                         disabledDeleteButton={ selectionIds.length == 0}
      />
      <HomeReviewTable
        handleSelection={(ids: number[]) => { setSelectionIds(ids) }}
        handleUpdateHomeReview={(homeReview: ShopHomeReviewType) => setUpdateHomeReview(homeReview)}
        refreshCounter={refreshCounter}
      />
      <HomeReviewCreatePopup open={showCreateHomeReview} handleClose={() => {
        refresh()
        setShowCreateHomeReview(false)
      }}/>
      <HomeReviewUpdatePopup homeReview={updateHomeReview} handleClose={() => {
        refresh()
        setUpdateHomeReview(null)
      }}/>
      <DeleteConfirmPopup open={showDeletePopup} handleClose={() => {
        refresh()
        setShowDeletePopup(false)
      }} handleConfirm={() => {
        Promise.all(selectionIds.map((id) => ShopHomeReviewAPI.delete(id)))
          .then((results) => {
            refresh()
            setShowDeletePopup(false)
          })
      }}/>
    </Stack>
  )
}

export default HomeReviewTab