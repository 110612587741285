import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { TermCategoryType } from "types/TermType"

type Props = {
  selectedType: TermCategoryType
  selectType: (category: TermCategoryType) => void
}

const termTypeList: { key: TermCategoryType; title: string }[] = [
  { key: "이용약관", title: "이용 약관" },
  { key: "이용정책", title: "이용 정책" },
]

export const TermCategoryBar = ({ selectedType, selectType }: Props) => {
  return (
    <Wrapper>
      {termTypeList.map((termType, index) => (
        <TermTypeItem
          key={`${termType}_${index}`}
          isSelected={selectedType === termType.key}
          onClick={() => selectType(termType.key)}
        >
          {termType.title}
        </TermTypeItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const TermTypeItem = styled.div<{ isSelected: boolean }>`
  border-radius: 21px;
  padding: 6px 12px;
  margin-right: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background: #1c2229;
        color: #fff;
        border: 1px solid #1c2229;
      `
    } else {
      return css`
        background: #fff;
        color: #666f78;
        border: 1px solid #ced2d6;
      `
    }
  }}
`
