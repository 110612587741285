import { useState } from "react"
import styled from "@emotion/styled"
import Button from "components/Button"
import { isNaN } from "lodash"
import { toast } from "react-toastify"
import { findUserByPhoneNumber } from "apis/userAPI"

type Props = {
  userIdList: number[]
  changeState: (key: string, value: any) => void
}

const UserIdSelector = ({ userIdList, changeState }: Props) => {
  const [inputValue, setInputValue] = useState<string>("")

  const addUser = () => {
    findUserByPhoneNumber([inputValue])
      .then((res) => {
        const newUserId = res[0].id
        if (userIdList.includes(newUserId)) {
          toast.error("이미 추가된 아이디입니다.")
          return
        }

        if (!newUserId) {
          toast.error("유저 정보를 찾을 수 없습니다.")
          return
        }

        setInputValue("")
        changeState("userIds", [...userIdList, newUserId])
      })
      .catch(() => toast.error("유저 정보를 불러오는데 실패했습니다."))
  }

  return (
    <Wrapper>
      <SelectorWrapper>
        <input
          type={"text"}
          placeholder="유저 휴대폰번호를 입력해주세요."
          value={inputValue}
          onChange={(e) => {
            const inputValue = e.target.value

            if (isNaN(Number(inputValue))) {
              return
            }

            setInputValue(inputValue)
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addUser()
            }
          }}
        />
        <Button buttonStyle="blue-border" disabled={!inputValue} onClick={addUser}>
          입력
        </Button>
      </SelectorWrapper>
    </Wrapper>
  )
}

export default UserIdSelector

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectorWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: center;

  input {
    width: 80%;
    outline: none;
    height: 40px;
    padding: 10px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    margin-right: 10px;
  }
`
