import { BuyOfferPenalty, PenaltyDetail, PenaltyListItem, PenaltyListSearchParams } from "types/PenaltyType"
import { PageType } from "types/PageType"
import { httpGet, httpPost } from "utils/httpAPI"
import { ShippingStatusCode } from "../types/ShippingType"

export const getPenaltyList: (params: PenaltyListSearchParams) => Promise<PageType<PenaltyListItem>> = (
  params: PenaltyListSearchParams
) => httpGet(`/user-penalties?`, params)

export const getPenaltyCountList: (params?: {
  sellerId?: string,
  sellerName?: string,
  startDate?: string,
  endDate?: string,
  cancelShippingStatus?: ShippingStatusCode
  cancelShippingIsNull?: boolean,
  includeRequiredManagement?: boolean,
}) => Promise<{ label: string; count: number }[]> = (params) => httpGet("/user-penalties/counts", params)

export const getPenaltyDetail: (orderId: string) => Promise<PenaltyDetail> = (orderId) =>
  httpGet(`/user-penalties/${orderId}`)

export const refundPenalty: (
  orderId: string,
  payload: { refundAmount: number; refundReason: string }
) => Promise<void> = (orderId, payload) => httpPost(`/user-penalties/${orderId}/refund`, payload)

export const resetUserBuyOfferPenalty: (
  payload: { id: number  }
) => Promise<BuyOfferPenalty> = (payload) => httpPost(`/user-penalties/user-buy-offer-penalty/reset`, payload)
