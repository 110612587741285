import { Paper, Stack, Tab } from "@mui/material"
import HeroBannerTab from "./HeroBannerTab"
import { useState } from "react"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import ShopContentTab from "./ShopContentTab"
import SearchKeywordTab from "./SearchKeywordTab"
import MdPickTab from "./MdPickTab"
import EtcTab from "./EtcTab"
import HomeReviewTab from "./HomeReviewTab"
import ServiceBannerTab from "./ServiceBannerTab"
import BrandLogoTab from "./BrandLogoTab"

const HomeManager = () => {
  const [tab, setTab] = useState<string>("heroBanner")

  function handleChangeTab(newTab: string) {
    if (tab !== newTab) {
      setTab(newTab)
    }
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction={"column"} spacing={2} justifyContent={"center"}>
        <TabContext value={tab}>
          <TabList onChange={(_e, value) => handleChangeTab(value)}>
            <Tab value={"heroBanner"} label={"히어로 배너 관리"} />
            <Tab value={"shopContent"} label={"샵 컨텐츠 관리"} />
            <Tab value={"search-keyword"} label={"샵 추천 키워드 관리"} />
            <Tab value={"md-pick"} label={"MD PICK 관리"} />
            <Tab value={"service-banner"} label={"서비스배너 관리"} />
            <Tab value={"brand-logo"} label={"브랜드 로고관리"} />
            <Tab value={"home-review"} label={"홈화면 후기 관리"} />
            <Tab value={"etc"} label={"기타관리"} />
          </TabList>
          <TabPanel value={"heroBanner"}>
            <HeroBannerTab />
          </TabPanel>
          <TabPanel value={"shopContent"}>
            <ShopContentTab />
          </TabPanel>
          <TabPanel value={"search-keyword"}>
            <SearchKeywordTab />
          </TabPanel>
          <TabPanel value={"md-pick"}>
            <MdPickTab />
          </TabPanel>
          <TabPanel value={"service-banner"}>
            <ServiceBannerTab />
          </TabPanel>
          <TabPanel value={"brand-logo"}>
            <BrandLogoTab />
          </TabPanel>
          <TabPanel value={"home-review"}>
            <HomeReviewTab />
          </TabPanel>
          <TabPanel value={"etc"} style={{ padding: 0, margin: 0 }}>
            <EtcTab />
          </TabPanel>
        </TabContext>
      </Stack>
    </Paper>
  )
}

export default HomeManager
