import { FunctionComponent, useCallback, useEffect, useState } from "react"

import { ModelManagerBrandChips } from "./ModelManagerBrandChips"
import { ModelManagerModelListTable } from "./ModelManagerModelListTable"
import { ModelUpdatePopup } from "./ModelUpdatePopup/ModelUpdatePopup"
import { useModelManagerStore } from "./useModelManagerStore"
import { ModelListItemType } from "types/ModelListItemType"
import { ModelManagerTab } from "pages/ModelManager/ModelManagerTab"
import Button from "components/Button"
import styled from "@emotion/styled"
import { ModelManagerSearchBar } from "./ModelManagerSearchBar"

const ModelDbManager: FunctionComponent = () => {
  const {
    brandList,
    modelListParams,
    modelList,
    fetchBrandList,
    fetchModelList,
    changeBrandSid,
    changePage,
    changeQuery,
    changeDisplay,
  } = useModelManagerStore()
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)

  const [modelListItemToUpdate, setModelListItemToUpdate] = useState<ModelListItemType | null>(null)

  const openUpdateModelPopUp = useCallback((model: ModelListItemType) => {
    setModelListItemToUpdate(model)
    setIsOpenPopup(true)
  }, [])

  const closeUpdateModelPopup = useCallback(() => {
    setModelListItemToUpdate(null)
    setIsOpenPopup(false)
  }, [])

  useEffect(() => {
    fetchBrandList()
    fetchModelList(modelListParams)
  }, [fetchBrandList, fetchModelList, modelListParams])

  return (
    <>
      {isOpenPopup && (
        <ModelUpdatePopup
          brand={modelListParams.brandSid}
          modelListItem={modelListItemToUpdate}
          onClickCancel={closeUpdateModelPopup}
          onClickConfirm={() => {
            closeUpdateModelPopup()
            fetchModelList(modelListParams)
          }}
        />
      )}

      <ModelManagerTab />
      <div style={{ margin: "4px 24px" }}>
        <ModelManagerBrandChips
          brands={brandList}
          selectedBrandSid={modelListParams.brandSid}
          onChangeBrandSid={changeBrandSid}
        />
      </div>
      <div style={{ height: 16 }} />
      <StyledDivModelListTable>
        <InlineWrapper>
          <ModelManagerSearchBar query={modelListParams.name} onChangeQuery={changeQuery} />

          <ButtonWrapper>
            <Button buttonSize="M" style={{ height: "35px" }} onClick={() => setIsOpenPopup(true)}>
              모델 추가
            </Button>
          </ButtonWrapper>
        </InlineWrapper>

        <ModelManagerModelListTable
          modelListParams={modelListParams}
          modelList={modelList}
          onChangePage={changePage}
          onClickUpdateModel={openUpdateModelPopUp}
          onChangeDisplay={changeDisplay}
        />
      </StyledDivModelListTable>
    </>
  )
}

export default ModelDbManager

const StyledDivModelListTable = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`

const ButtonWrapper = styled.div`
  width: 120px;
`
