import React, { useEffect, useReducer } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { CurrencyCode, CurrencyCodeList } from "../../../types/ProductType"
import { getExchangeRatesWithFeesByCurrencyList } from "../../../apis/askingPriceAPI"
import { ExchangeRateFeesType } from "../../../types/ExchangeRateFeesType"
import { PageType } from "../../../types/PageType"
import ExchangeRateFeesTable from "./ExchangeRateFeesTable"

export type ExchangeRateFeesListState = {
  result: PageType<ExchangeRateFeesType>
  currency?: CurrencyCode | "ALL";
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  refresh: boolean
  loading: boolean
}

const initState: ExchangeRateFeesListState = {
  result: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  currency: "ALL",
  startDateFilter: undefined,
  endDateFilter: undefined,
  refresh: false,
  loading: false,
}

interface ExchangeRateFeesListAction {
  type: string
  payload?: any
}

function reducer(state: ExchangeRateFeesListState, action: ExchangeRateFeesListAction): ExchangeRateFeesListState {
  switch (action.type) {
    case "FETCH_RESULT":
      return { ...state, result: action.payload }
    case "CHANGE_CURRENCY":
      return { ...state, currency: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const ExchangeRateFeesListPage = () => {
  const [state, dispatch] = useReducer(reducer, initState)

  const fetchLists = (searchParams: any = {}) => {
    let newParams = {
      ...searchParams,
      currency: state.currency == "ALL" ? null : state.currency,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate()
    }

    dispatch({ type: "START_LOADING" })
    getExchangeRatesWithFeesByCurrencyList(newParams)
      .then((result) => dispatch({ type: "FETCH_RESULT", payload: result }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  useEffect(() => {
    fetchLists({
      page: state.result.pageable?.pageNumber || 0,
      size: state.result.pageable?.pageSize || 25,
    })
  }, [state.refresh])

  return <Grid container spacing={2}>

    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Grid item xs={12}>
          <Stack spacing={1} direction={"column"}>
            <Stack direction={"row"} justifyContent={"flex-start"}  >
              <Stack direction={"row"}  >
                <FormControl style={{ minWidth: "100%" }}>
                  <InputLabel id="search_key">통화구분</InputLabel>
                  <Select
                    size={"small"}
                    value={state.currency || "ALL"}
                    onChange={(e) => {
                      dispatch({ type: "CHANGE_CURRENCY", payload: e.target.value })
                      dispatch({ type: "REFRESH" })
                    }}
                  >
                    <MenuItem value={"ALL"}  >
                      {"전체"}
                    </MenuItem>
                    {CurrencyCodeList.map((menu) => (
                      <MenuItem value={menu.value} >
                        {menu.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack spacing={1} direction={"row"} sx={{ lineHeight: "45px", paddingLeft: "30px", paddingTop:"10px" }}>
                <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                  <DesktopDatePicker
                    label="시작일"
                    inputFormat="yyyy-MM-dd"
                    value={state.startDateFilter ?? null}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_START_DATE", payload: params })
                      dispatch({ type: "REFRESH" })
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                  <DesktopDatePicker
                    label="종료일"
                    inputFormat="yyyy-MM-dd"
                    value={state.endDateFilter ?? null}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_END_DATE", payload: params })
                      dispatch({ type: "REFRESH" })
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ pt: 1 }}>
          <ExchangeRateFeesTable state={state} dispatch={dispatch} fetch={fetchLists} components={[]} />
        </Grid>
      </Paper>
    </Grid>
  </Grid>
}

export default ExchangeRateFeesListPage
