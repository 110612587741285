import { useRef, useEffect, useState } from "react"
import * as monaco from "monaco-editor"
import { isNil } from "lodash"
import styled from "@emotion/styled"

type Props = {
  body: string
  changeHtmlBody: (html: string) => void
}

export const HtmlEditor = ({ body, changeHtmlBody }: Props) => {
  const [html, setHtml] = useState(body)
  const containerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<monaco.editor.ICodeEditor>()

  useEffect(() => {
    if (containerRef.current instanceof HTMLElement) {
      editorRef.current = monaco.editor.create(containerRef.current, {
        value: body,
        renderWhitespace: "all",
        scrollBeyondLastLine: false,
        lineDecorationsWidth: 0,
        wordWrap: "bounded",
        autoIndent: "none",
        minimap: { enabled: false },
        language: "HTML",
      })
    }

    return () => {
      editorRef.current?.dispose()
    }
  }, [body])

  useEffect(() => {
    const editorTextModel = editorRef.current?.getModel()
    if (isNil(editorRef.current) || isNil(editorTextModel)) {
      return
    }

    editorRef.current.onDidChangeModelContent(() => {
      setHtml(editorTextModel.getValue())
    })

    editorRef.current.onDidBlurEditorText(() => {
      changeHtmlBody(html)
    })
  }, [changeHtmlBody, html])

  return (
    <InlineWrapper>
      <HTMLContainer ref={containerRef} style={{ marginRight: "15px" }} />
      <HTMLContainer dangerouslySetInnerHTML={{ __html: html }} style={{ padding: "10px" }} />
    </InlineWrapper>
  )
}

const InlineWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const HTMLContainer = styled.div`
  max-width: 45%;
  border: 1px solid #ccc;
  height: 350px;
  flex: 1;
  overflow: hidden;
  border-radius: 4px;
`
