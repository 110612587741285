import { FunctionComponent } from "react"
import { StyledAbsoluteBox, StyledBox, StyledInputText, StyledStix, StyledTxt } from "./styled"
import { SelectOptions } from "./SelectOptions"
const COLUMN_HEIGHT  = 7
const WATER_RESISTANCE_OPTION_ITEMS = ["Fail", "Pass", "직접입력"]
const USER_WRITE_LABEL = "방수직접입력"
export type AppraisalCertificatePerformanceType = {
  label: string
  labelKo:string
  key: string
  value: string
  userWriteValue?:string
  range: string
}
export const DiagnosisPrintPerformanceCheck:FunctionComponent<{
  items:AppraisalCertificatePerformanceType[]
  handleChange:(label:string, key:string, value:string)=>void
  editable:boolean
}> = ({items, handleChange, editable}) => {

  return (
    items.length > 0 ? (
      <StyledBox $flexDirection="column" $paddingLeft={12} >
        {
          items.map((performanceItem, index) => (
            performanceItem.label === USER_WRITE_LABEL ? null : (
              <PerformanceItem
                key={`${performanceItem.key}_${index}`}
                changeEvent={(label, key, changeValue) =>{
                  handleChange(label, key, changeValue)
                }}
                item={performanceItem}
                isFirst={index === 0}
                isLast={index === items.length-2}
                editable={editable}
              />
            )
          ))
        }
      </StyledBox>
    ) : null
  )
}

const PerformanceItem:FunctionComponent<{
  item: AppraisalCertificatePerformanceType
  changeEvent:(c:string, key:string, changeValue:string)=>void
  isFirst?:boolean
  isLast?:boolean
  editable?:boolean
}> = ({ item, changeEvent, isFirst, isLast, editable }) => {
  const waterResistanceLabel = "Water resistance"
  return (
    <StyledBox $gap={19} $width={"100%"} $alignItems="stretch" >
      <StyledBox
        $width={74}
        $flexShrink={0}
        $gap={2}
        $alignItems="center"
        $paddingTop={isFirst ? 4 : 2} $paddingBottom={isLast ? 4 : 2}
        className="is-design"
      >
        <StyledTxt $color="#000" $fontSize={7} $weight={700} $lineHeight={COLUMN_HEIGHT}>
          {item.label}
        </StyledTxt>
        <StyledTxt $color="#545454" $fontSize={5} $lineHeight={COLUMN_HEIGHT}>
          {item.labelKo}
        </StyledTxt>
      </StyledBox>
      <StyledBox
        $width={"100%"}
        $paddingLeft={7}
        $paddingRight={3}
        $justifyContent={"space-between"}
        $alignItems="stretch"
      >
        <StyledBox $width={68} $alignItems="center">
          <StyledInputText
            readOnly={!editable}
            $color="#000"
            $fontSize={6}
            $lineHeight={COLUMN_HEIGHT}
            value={item.range}
            onChange={(event)=>{
              changeEvent(item.label, "range", event.target.value)
            }}
            style={{padding: 0}}
          />
        </StyledBox>
        {
          item.label === waterResistanceLabel ? (
            <StyledBox $width={112} $justifyContent={"center"}  $isBg={true} $alignItems="center" >
              <StyledStix $isItalic={true} $color="#000" $fontSize={6} $lineHeight={COLUMN_HEIGHT} $weight={700}>
                {
                  item.value === "직접입력" ? item.userWriteValue : item.value
                }
              </StyledStix>
              {
                editable ? (
                  <StyledAbsoluteBox
                    $width={"100%"}
                    className="not-printing"
                    style={{background:"#f8f8f8"}}
                  >
                    <StyledBox>
                      <SelectOptions
                        items={[...WATER_RESISTANCE_OPTION_ITEMS]}
                        selectedIndex={WATER_RESISTANCE_OPTION_ITEMS.findIndex(key => key === item.value)}
                        handleChange={(event) => {
                          const changeValue = event.target.value;
                          changeEvent(item.label, "value", changeValue)
                        }}
                      />
                      {
                        item.value !== "직접입력" ? (
                          <StyledStix
                            $isItalic={true}
                            $color="#000"
                            $fontSize={6}
                            $lineHeight={COLUMN_HEIGHT}
                            $weight={700}
                            $align="center"
                          >
                            {item.value}
                          </StyledStix>
                        ) : (
                          <StyledInputText
                            $isItalic={true}
                            $color="#000"
                            $fontSize={6}
                            $lineHeight={COLUMN_HEIGHT}
                            $weight={700}
                            $align="center"
                            value={item.userWriteValue}
                            onChange={(event)=>{
                              changeEvent(item.label, "userWriteValue", event.target.value)
                            }}
                            placeholder={"직접입력"}
                            style={{
                              letterSpacing: 0.2
                            }}
                          />
                        )
                      }

                    </StyledBox>
                  </StyledAbsoluteBox>
                ) : null
              }
            </StyledBox>
          ) : (
            <StyledBox $width={112} $justifyContent={"center"} $isBg={true} $alignItems="center" >
              <StyledInputText
                readOnly={!editable}
                $isItalic={true}
                $color="#000"
                $fontSize={6}
                $lineHeight={COLUMN_HEIGHT}
                $weight={700}
                $align="center"
                value={item.value}
                onChange={(event)=>{
                  changeEvent(item.label, "value", event.target.value)
                }}
                style={{
                  letterSpacing: 0.2
                }}
              />
            </StyledBox>
          )
        }
      </StyledBox>
    </StyledBox>
  )
}
