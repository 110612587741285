import React, { createContext, useState } from "react"
import { logout, me } from "./apis/accountAPI"
import { AdminUserType } from "./types/AdminUserType"

type AuthContextType = {
  user?: AdminUserType | null
  initialAuthChecked: boolean
  checkAuth?: (callback?: VoidFunction) => void
  logout?: (callback?: VoidFunction) => void
}

export const AuthContext = createContext<AuthContextType>({
  initialAuthChecked: false,
})

type AuthProviderProps = {}

export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = ({ children }) => {
  const [user, setUser] = useState<AdminUserType | null>(null)
  const [initialAuthChecked, setInitialAuthChecked] = useState<boolean>(false)

  return (
    <AuthContext.Provider
      value={{
        user: user,
        initialAuthChecked: initialAuthChecked,
        checkAuth: async (callback) => {
          me()
            .then((user) => setUser(user))
            .catch((exception) => {
              setUser(null)
              throw exception
            })
            .finally(() => {
              setInitialAuthChecked(true)
              callback?.()
            })
        },
        logout: (callback) => {
          logout()
            .then(() => {})
            .finally(() => {
              setUser(null)
              callback?.()
            })
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
