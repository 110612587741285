import styled from "@emotion/styled"
import { PointReasonType, PointStatusCode } from "types/PointType"
import { PaginationParamsType } from "types/PageType"
import { PointScheduleListItemType, PointScheduleListSearchParams } from "types/PointScheduleListItemType"
import { useCallback, useState } from "react"
import { useMount } from "react-use"
import { getPointScheduleList } from "apis/pointAPI"
import PointScheduleListSearchBar from "./SearchBar"
import PointScheduleListTable from "./PointScheduleTable"
import Button from "components/Button"
import EditPointSchedulePopup from "./EditPointSchdulePopup"

export type PointScheduleListSearchType = {
  filter: keyof PointScheduleListSearchParams
  keyword: string | PointStatusCode | PointReasonType
}

const POINT_SCHEDULE_LIST_FETCH_SIZE = 20

const PointSchedulePage = () => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
  const [pointScheduleList, setPointScheduleList] = useState<PointScheduleListItemType[]>([])
  const [searchParams, setSearchParams] = useState<PointScheduleListSearchType>({
    filter: "status",
    keyword: "",
  })

  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: POINT_SCHEDULE_LIST_FETCH_SIZE,
  })

  const [selectedPointSchedule, setSelectedPointSchedule] = useState<PointScheduleListItemType | undefined>()

  const parseSearchParams = useCallback((searchParams?: PointScheduleListSearchType) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchPointScheduleList = useCallback(
    (pageNumber?: number, searchParams?: PointScheduleListSearchType) => {
      getPointScheduleList({
        size: POINT_SCHEDULE_LIST_FETCH_SIZE,
        page: pageNumber,
        ...parseSearchParams(searchParams),
      }).then(
        ({ content, totalElements = 0, totalPages = 0, first, size = POINT_SCHEDULE_LIST_FETCH_SIZE, number = 0 }) => {
          setPointScheduleList(content || [])
          setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
        }
      )
    },
    [parseSearchParams]
  )

  const changeSearchFilterValue = useCallback(
    (searchFilterValue: keyof PointScheduleListSearchParams) => {
      setSearchParams({ filter: searchFilterValue, keyword: "" })
      fetchPointScheduleList()
    },
    [fetchPointScheduleList]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(
    (keyword: PointScheduleListSearchType["keyword"]) => {
      fetchPointScheduleList(0, { ...searchParams, keyword })
      setPaginationParams((prev) => ({ ...prev, number: 0 }))
    },
    [fetchPointScheduleList, searchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber - 1 }))
      fetchPointScheduleList(pageNumber - 1, searchParams)
    },
    [searchParams, fetchPointScheduleList]
  )

  const closePopupPage = () => {
    setIsEditPopupOpen(false)
    setSelectedPointSchedule(undefined)
  }

  const onClickTableRow = (pointSchedule: PointScheduleListItemType) => {
    setSelectedPointSchedule(pointSchedule)
    setIsEditPopupOpen(true)
  }

  useMount(() => {
    fetchPointScheduleList()
  })

  return (
    <>
      <EditPointSchedulePopup
        isOpen={isEditPopupOpen}
        closePopup={closePopupPage}
        selectedPointSchedule={selectedPointSchedule}
        fetchPointScheduleList={fetchPointScheduleList}
      />
      <PageHeaderWrapper>
        <ButtonWrapper>
          <Button onClick={() => setIsEditPopupOpen(true)}>포인트 지급 예약 추가</Button>
        </ButtonWrapper>
        <PointScheduleListSearchBar
          searchParams={searchParams}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <PointScheduleListTable
        pointScheduleList={pointScheduleList}
        paginationParams={paginationParams}
        changePage={changePageNumber}
        onClickTableRow={onClickTableRow}
      />
    </>
  )
}

export default PointSchedulePage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 24px 0;
  margin-bottom: 20px;
`

const ButtonWrapper = styled.div``
