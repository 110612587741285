import { FaqCategoryType, FaqCreateBody, FaqListParams, FaqType } from "types/FaqType"
import { PageType } from "types/PageType"
import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"

export const getFaqCategoryList: () => Promise<FaqCategoryType[]> = () => httpGet(`/faq/types`)

export const getFaqList: (params: FaqListParams) => Promise<PageType<FaqType>> = (params) => httpGet(`/faq`, params)

export const getFaqDetail: (faqId: number) => Promise<FaqType> = (faqId) => httpGet(`/faq/${faqId}`)

export const deleteFaq: (faqId: number) => Promise<void> = (faqId) => httpDelete(`/faq/${faqId}`)

export const createFaq: (body: FaqCreateBody) => Promise<FaqType> = (body) => httpPost(`/faq`, body)

export const updateFaq: (faqId: number, body: FaqCreateBody) => Promise<FaqType> = (faqId, body) =>
  httpPut(`/faq/${faqId}`, body)

export const changeFaqOrder: (faqCategory: string, body: { id: FaqType["id"] }[]) => Promise<FaqType[]> = (
  faqCategory,
  body
) => httpPut(`/faq/priority?type=${faqCategory}`, body)
