import styled from "@emotion/styled"
import { UserType } from "types/UserType"
import Modal from "components/Modal"
import UserIdSelector from "./UserIdSelector"

type Props = {
  closeModal: () => void
  onSubmit: (user: UserType) => void
}

export const UserFindModal = ({ closeModal, onSubmit }: Props) => {
  return (
    <Modal isOpen closeModal={closeModal} zIndex={10000}>
      <ModalContentWrapper>
        <ModalBodyWrapper>
          <Title>회원 검색</Title>
          <UserIdSelector
            selectUser={(state) => {
              onSubmit(state)
              closeModal()
            }}
          />
        </ModalBodyWrapper>
      </ModalContentWrapper>
    </Modal>
  )
}

const ModalContentWrapper = styled.div`
  width: 400px;
  min-height: 200px;
`

const ModalBodyWrapper = styled.div`
  padding: 31px 24px;
  flex: 1;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`
