import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { toDateTimeStr } from "utils/datetimeUtil"
import { headerItemList } from "../../TableHeader"

type Props = {
  memoListItem: any
} & HTMLAttributes<HTMLDivElement>

const SerialNumberMemoTableRow = ({ memoListItem, ...props }: PropsWithChildren<Props>) => {
  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: headerItemList[0].width }}>{memoListItem.memo}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>{memoListItem.createdBy.name}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>{toDateTimeStr(memoListItem.createdAt)}</TableRowItem>
    </TableRow>
  )
}

export default SerialNumberMemoTableRow
