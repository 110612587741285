import {Badge, Button, ButtonGroup, IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import QuickMemoPopup from "../QuickMemoPopup";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {countUserMemoByStatus} from "../../../apis/userMemoAPI";
import _ from 'lodash'
import {UserMemoTargetTypeCode} from "../../../types/UserType";

type props = {
    target: UserMemoTargetTypeCode,
    targetId?: string | number,
    small?: boolean
}

const QuickMemoButton: React.FC<React.PropsWithChildren<props>> = ({ children, target, targetId,small }) => {
    const [showPopup, setShowPopup] = useState(false)
    const [memoCount, setMemoCount] = useState(0)

    const handleClick = () => {
        setShowPopup(true)
    }

    const handleCloseMemoPopup = () => {
        setShowPopup(false)
        fetchMemoCount()
    }

    const fetchMemoCount = () => {
        const searchParams = {
            userId: target == UserMemoTargetTypeCode.USER ? (targetId as number) : null,
            productId: target == UserMemoTargetTypeCode.PRODUCT ? (targetId as number) : null,
        }
        countUserMemoByStatus(searchParams).then((countListByStatus) => {
            const count = _.find(countListByStatus, {label: '활성'})
            setMemoCount(count?.count ?? 0)
        })
    }

    useEffect(() => {
        targetId && fetchMemoCount()
    }, [targetId])

    return (
        <>
            { small == true ?
                <IconButton onClick={handleClick}>
                    <Badge badgeContent={memoCount} color="primary" max={9} showZero>
                        <NoteAltIcon />
                    </Badge>
                </IconButton>
                :
                <ButtonGroup size={'small'} variant={"contained"} >
                    <Button style={{width: '70px'}} size={'small'} variant={"contained"} onClick={handleClick}>
                        메모
                    </Button>
                    <Button>0</Button>
                </ButtonGroup>
            }
            <QuickMemoPopup open={showPopup} target={target} targetId={targetId} handleClose={handleCloseMemoPopup} />
        </>

    )
}

export default QuickMemoButton