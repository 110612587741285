import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const headerItemList: {
  title: string
  width?: string
}[] = [
  {
    title: "상품번호\n(수리 주문번호)",
    width: "10%",
  },
  {
    title: "진행상태",
    width: "10%",
  },
  {
    title: "상품명",
    width: "20%",
  },
  {
    title: "수리 유형",
    width: "10%",
  },
  {
    title: "주문 내역",
    width: "10%",
  },
  {
    title: "결제 금액",
    width: "10%",
  },
]

const UpgradeServiceListTableHeader = () => {
  return (
    <ListTableHeader>
      {headerItemList.map((headerItem) => {
        return (
          <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
            {headerItem.title}
          </ListTableHeaderItem>
        )
      })}
    </ListTableHeader>
  )
}

export default UpgradeServiceListTableHeader
