import { MenuItem, Select } from "@mui/material"
import { FunctionComponent, useCallback, useState } from "react"
import SearchInput from "components/Search/SearchBar/SearchInput"
import styled from "@emotion/styled"
import Button from "components/Button"
import { DetailModelListItemType } from "types/DetailModelListItemType"
import CheckBox from "components/Checkbox"
import { isNotEmpty } from "utils/validationUtils"
import { detailModelSearchOptionList } from "./useDetailModelManagerStore"

const registrationStatusFilterList: {
  value: DetailModelListItemType["registrationStatus"]
  title: string
}[] = [
  { value: "검수완료", title: "검수완료" },
  { value: "검수대기", title: "검수대기" },
  { value: "이미지제작", title: "이미지 제작" },
  { value: "등록대기", title: "등록대기" },
]

export const DetailModelManagerSearchBar: FunctionComponent<{
  listParams: {
    page: number
    brandSid: string
    title?: string
    id?: string
    registrationStatus: string
    fullRefNo?: string
  }
  onChangeSearchKeyValue: (key: string, value?: string) => void
  onChangeRegistrationStatus: (registrationStatus: string) => void
  onClickOpenCreatePopup: () => void
}> = ({ listParams, onChangeSearchKeyValue, onChangeRegistrationStatus, onClickOpenCreatePopup }) => {
  const [selectedMenu, setSelecedMenu] = useState<string>(detailModelSearchOptionList[0].key)
  const [detailModelQuery, setDetailModelQuery] = useState(listParams.title)

  const toggleStatusFilter = useCallback(
    (status: string) => {
      const statusList = listParams.registrationStatus.split(",").filter(isNotEmpty)
      const statusString = statusList.includes(status)
        ? statusList.filter((prev) => prev !== status).join(",")
        : statusList.concat(status).join(",")

      onChangeRegistrationStatus(statusString)
    },
    [listParams.registrationStatus, onChangeRegistrationStatus]
  )

  return (
    <StyledDivDetailModelManagerSearchBar>
      <Select
        value={selectedMenu}
        size="small"
        sx={{ width: 135 }}
        onChange={(e) => {
          console.log(e.target.value)
          setSelecedMenu(e.target.value)
        }}
      >
        {detailModelSearchOptionList.map((option) => (
          <MenuItem value={option.key} key={option.key}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
      <SearchInput
        style={{ height: 40 }}
        value={detailModelQuery || ""}
        onChange={(event) => setDetailModelQuery(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onChangeSearchKeyValue(selectedMenu, detailModelQuery)
          }
        }}
      />
      <div style={{ width: 16 }} />
      <StatusContainer>
        {registrationStatusFilterList.map((statusFilter) => {
          return (
            <StatusCheckBoxItem key={statusFilter.title} onChange={() => toggleStatusFilter(statusFilter.value)}>
              <CheckBox
                id={statusFilter.value}
                checked={listParams.registrationStatus.split(",").includes(statusFilter.value)}
                readOnly
              />
              <label htmlFor={statusFilter.value}>{statusFilter.title}</label>
            </StatusCheckBoxItem>
          )
        })}
      </StatusContainer>
      <div style={{ width: 120 }}>
        <Button onClick={onClickOpenCreatePopup}>상세 모델 추가</Button>
      </div>
    </StyledDivDetailModelManagerSearchBar>
  )
}

const StyledDivDetailModelManagerSearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const StatusContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 46px 0 36px;
`

const StatusCheckBoxItem = styled.div`
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 12px;
  }

  label {
    font-size: 14px;
    color: #1c2229;
    margin-left: 4px;
  }
`
