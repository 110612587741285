import {useEffect, useState} from "react"
import {CouponCodeType} from "types/CouponCodeType"
import {initialCouponCodeState, parseCouponCodeBody, validateCouponCodeForm} from "./utils";
import {postCouponCode, putCouponCode} from "../../../../apis/couponCodeAPI";
import {toast} from "react-toastify";

export type CouponCodeStateKeyType = keyof typeof initialCouponCodeState
export type CouponCodeStateValueType = typeof initialCouponCodeState[CouponCodeStateKeyType]

export type CouponCodeEditorType = "EDIT" | "CREATE"

type Props = {
  editorType: CouponCodeEditorType
  editingCouponCode?: CouponCodeType
  onFinishRequest: () => void
}

export const useEditCouponCodeData = ({ editingCouponCode, editorType, onFinishRequest }: Props) => {
  const [couponCodeState, setCouponCodeState] = useState<CouponCodeType>(
    editingCouponCode || initialCouponCodeState
  )

  useEffect(() => {
    setCouponCodeState(editingCouponCode || initialCouponCodeState)
  }, [editingCouponCode])

  const changeCouponCodeState = (key: CouponCodeStateKeyType, value: CouponCodeStateValueType) => {
    setCouponCodeState((prev) => ({ ...prev, [key]: value }))
  }

  const onSubmit = () => {
    console.log('submit')
    if (!validateCouponCodeForm(couponCodeState)) {
      return
    }

    console.log(editorType)
    if (editorType === "CREATE") {
      postCouponCode(parseCouponCodeBody(couponCodeState))
        .then(() => {
          toast.success("쿠폰 코드 생성이 완료되었습니다.")
          onFinishRequest()
          setCouponCodeState(initialCouponCodeState)
        })
        .catch(() => toast.error("쿠폰 코드 생성에 실패했습니다."))
    }

    if (editorType === "EDIT") {
      putCouponCode(parseCouponCodeBody(couponCodeState), couponCodeState.code!!)
        .then(() => {
          toast.success("쿠폰 코드가 수정되었습니다.")
          onFinishRequest()
          setCouponCodeState(initialCouponCodeState)
        })
        .catch(() => toast.error("쿠폰 코드 수정에 실패했습니다."))
    }
  }

  return {
    couponCodeState,
    changeCouponCodeState,
    onSubmit,
  }
}
