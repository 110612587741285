import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const PopupPageTitle = ({ children }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Title>{children}</Title>
}

export default PopupPageTitle

const Title = styled.h1`
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-weight: 700;
  font-size: 30px;

  padding-bottom: 16px;
`
