import { isNil } from "lodash"
import * as monaco from "monaco-editor"
import { FunctionComponent, useEffect, useRef } from "react"

export const NoticeDetailFormMarkdownEditor: FunctionComponent<{
  mode?: "default" | "diff"
  initialMarkdown: string
  onChangeMarkdown: (markdown: string) => void
}> = ({ mode = "default", initialMarkdown, onChangeMarkdown }) => {
  return (
    <>
      {mode === "default" && (
        <NoticeDetailFormMarkdownStandaloneCodeEditor
          initialMarkdown={initialMarkdown}
          onChangeMarkdown={onChangeMarkdown}
        />
      )}
      {mode === "diff" && (
        <NoticeDetailFormMarkdownDiffEditor initialMarkdown={initialMarkdown} onChangeMarkdown={onChangeMarkdown} />
      )}
    </>
  )
}

const NoticeDetailFormMarkdownStandaloneCodeEditor: FunctionComponent<{
  initialMarkdown: string
  onChangeMarkdown: (markdown: string) => void
}> = ({ initialMarkdown, onChangeMarkdown }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>()

  // initialMarkdown 업데이트 시 새로운 editor가 생성됩니다
  useEffect(() => {
    if (containerRef.current instanceof HTMLElement) {
      editorRef.current = monaco.editor.create(containerRef.current, {
        value: initialMarkdown,
        renderWhitespace: "all",
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
      })
    }
    return () => {
      editorRef.current?.dispose()
    }
  }, [initialMarkdown])

  useEffect(() => {
    const editor = editorRef.current
    const editorTextModel = editor?.getModel()

    editorTextModel?.onDidChangeContent(() => {
      if (!isNil(editor)) {
        onChangeMarkdown(editor.getValue())
      }
    })
  }, [onChangeMarkdown])

  return <div ref={containerRef} style={{ flex: 1, background: "red" }} />
}

const NoticeDetailFormMarkdownDiffEditor: FunctionComponent<{
  initialMarkdown: string
  onChangeMarkdown: (markdown: string) => void
}> = ({ initialMarkdown, onChangeMarkdown }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<monaco.editor.IDiffEditor>()

  // initialMarkdown 업데이트 시 새로운 editor가 생성됩니다
  useEffect(() => {
    if (containerRef.current instanceof HTMLElement) {
      editorRef.current = monaco.editor.createDiffEditor(containerRef.current, {
        ignoreTrimWhitespace: false,
        enableSplitViewResizing: false,
        renderSideBySide: true,
        renderWhitespace: "all",
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
      })
      editorRef.current.setModel({
        original: monaco.editor.createModel(initialMarkdown),
        modified: monaco.editor.createModel(initialMarkdown),
      })
    }
    return () => {
      editorRef.current?.dispose()
    }
  }, [initialMarkdown])

  useEffect(() => {
    const editor = editorRef.current?.getModifiedEditor()
    const editorTextModel = editor?.getModel()

    editorTextModel?.onDidChangeContent(() => {
      if (!isNil(editor)) {
        onChangeMarkdown(editor.getValue())
      }
    })
  }, [onChangeMarkdown])

  return <div ref={containerRef} style={{ flex: 1 }} />
}
