import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import "../../App.css"
import { getProductRequest } from "../../apis/productRequestAPI"
import { ResourceType } from "../../types/ResourceType"

type EditBuyOrderPopupProps = {
  open: boolean
  productRequestId: string
  handleClose: Function
}

const ViewProductRequestPopup: React.FC<EditBuyOrderPopupProps> = ({ open, productRequestId, handleClose }) => {
  const [gallery, setGallery] = useState<ResourceType[]>([])

  useEffect(() => {
    if (open) {
      getProductRequest(productRequestId!).then((productRequest) => {
        setGallery(productRequest.gallery)
      })
    }
  }, [open])

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <Typography fontWeight={"bold"} fontSize={"30px"}>
          상품 이미지
        </Typography>
      </DialogTitle>
      <DialogContent>
        {gallery?.map((row) => (
          <img style={{ marginRight: "10px", width: "200px", height: "300px", objectFit: "cover", border: "1px solid #1c2229" }} src={row.url} alt={""} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          size={"medium"}
          variant={"contained"}
          onClick={() => {
            setGallery([])
            handleClose()
          }}
        >
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewProductRequestPopup
