import { FunctionComponent } from "react"
import { SelectChangeEvent } from "@mui/material/Select"
import { FormControl, MenuItem, Select } from "@mui/material"
import { StyledTxt } from "./styled"

export const SelectOptions:FunctionComponent<{
  selectedIndex: number,
  items:string[],
  handleChange:(event:SelectChangeEvent)=>void
}>= ({
       selectedIndex,
       items,
       handleChange
     }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 120, width:"100%" }} size="small" style={{margin:0}}>
      <Select
        value={items[selectedIndex] ? items[selectedIndex] : ""}
        label=""
        onChange={handleChange}
        sx={{border:"none", height:"1.5rem", }}
        MenuProps={{
          style: { zIndex: 10001 },
        }}
      >
        {
          items.map((item, index) => (
            <MenuItem value={item} key={index} selected={selectedIndex === index}>
              <StyledTxt $fontSize={6} $lineHeight={10} $color="#000">
                {item}
              </StyledTxt>
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}