import { getUpgradeServiceOrderCountList, getUpgradeServiceOrderList } from "apis/upgradeServiceOrderAPI"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { UpgradeServiceOrderListItemType } from "types/UpgradeServiceOrderType"

const FETCH_LIST_COUNT = 20

export const useUpgradeServiceOrderList = () => {
  const [countList, setCountList] = useState<{ list: { label: string; count: number }[]; totalCount: number }>({
    list: [],
    totalCount: 0,
  })
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })

  const [searchParams, setSearchParams] = useState<any>({
    filter: "productId",
    keyword: "",
  })

  const [filterStatus, setFilterStatus] = useState<string>()

  const [upgradeServiceOrderList, setUpgradeServiceList] = useState<UpgradeServiceOrderListItemType[]>([])

  const changeSearchFilterValue = useCallback((searchFilterValue: any) => {
    setSearchParams((prev: any) => ({ ...prev, filter: searchFilterValue }))
  }, [])

  const parseSearchParams = useCallback((searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchUpgradeServiceCountList = useCallback(() => {
    getUpgradeServiceOrderCountList().then((response) => {
      setCountList({
        list: response,
        totalCount: response.reduce((prev, acc) => (prev += acc.count), 0),
      })
    })
  }, [])

  const fetchUpgradeServiceList = useCallback(
    (pageNumber?: number, searchParams?: any) => {
      getUpgradeServiceOrderList({
        size: FETCH_LIST_COUNT,
        page: pageNumber,
        ...parseSearchParams(searchParams),
      })
        .then(({ content, totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
          setUpgradeServiceList(content)
          setPaginationParams((prev) => ({
            ...prev,
            number,
            totalElements,
            totalPages,
            first,
            size,
          }))
        })
        .catch(() => {
          toast.error("수리 주문 리스트를 불러오는데 실패했습니다.")
        })
    },
    [parseSearchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchUpgradeServiceList(pageNumber, searchParams)
    },
    [searchParams, fetchUpgradeServiceList]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev: any) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(() => {
    fetchUpgradeServiceList(0, searchParams)
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
    setFilterStatus(undefined)
  }, [fetchUpgradeServiceList, searchParams])

  const onRefresh = useCallback(() => {
    fetchUpgradeServiceList(0)
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
    setFilterStatus(undefined)
  }, [fetchUpgradeServiceList])

  const onSelectStatus = (status: string | undefined) => {
    setFilterStatus(status)
    fetchUpgradeServiceList(0, { filter: "status", keyword: status })
  }

  useMount(() => {
    fetchUpgradeServiceList()
    fetchUpgradeServiceCountList()
  })

  return {
    changeSearchFilterValue,
    changePageNumber,
    changeSearchKeyword,
    onSearch,
    onRefresh,
    onSelectStatus,
    upgradeServiceOrderList,
    paginationParams,
    searchParams,
    countList,
    filterStatus,
  }
}
