import styled from "@emotion/styled"
import { Dialog } from "@mui/material"
import { Typography } from "@viver-inc/viver-ui"
import Button from "components/Button"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { FunctionComponent, useState } from "react"
import { View } from "react-native"

type ShippingType = {
  name: string
  phone: string
  zipCode: string
  address: string
  addressDetail: string
}

export const ShippingRegeditPopup: FunctionComponent<{
  shipping: ShippingType | null
  onClickCancel: () => void
  onClickConfirm: (shipping: ShippingType) => void
}> = ({ shipping, onClickCancel, onClickConfirm }) => {
  const [name, setName] = useState(shipping?.name ?? "")
  const [phone, setPhone] = useState(shipping?.phone ?? "")
  const [zipCode, setZipCode] = useState(shipping?.zipCode ?? "")
  const [address, setAddress] = useState(shipping?.address ?? "")
  const [addressDetail, setAddressDetail] = useState(shipping?.addressDetail ?? "")

  return (
    <Dialog maxWidth="md" open onClose={onClickCancel}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>배송지 변경</h3>
        </ModalContentHeader>
        <ModalContentBody>
          <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
            <View style={{ width: 100 }}>
              <Typography variant="body14" weight="r">
                고객명
              </Typography>
            </View>
            <SearchInput disabled={false} value={name} onChange={(e) => setName(e.target.value)} placeholder="홍길동" />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
            <View style={{ width: 100 }}>
              <Typography variant="body14" weight="r">
                전화번호
              </Typography>
            </View>
            <SearchInput
              disabled={false}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="01012341234"
            />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
            <View style={{ width: 100 }}>
              <Typography variant="body14" weight="r">
                우편번호
              </Typography>
            </View>
            <SearchInput
              disabled={false}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder="06014"
            />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
            <View style={{ width: 100 }}>
              <Typography variant="body14" weight="r">
                도로명 주소
              </Typography>
            </View>
            <SearchInput
              disabled={false}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="서울특별시 강남구 선릉로 818"
            />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
            <View style={{ width: 100 }}>
              <Typography variant="body14" weight="r">
                상세주소
              </Typography>
            </View>
            <SearchInput
              disabled={false}
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
              placeholder="4층 주식회사바이버"
            />
          </View>
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button
              buttonStyle="blue-fill"
              buttonSize="M"
              disabled={!name || !phone || !zipCode || !address || !addressDetail}
              onClick={() => onClickConfirm({ name, phone, zipCode, address, addressDetail })}
            >
              변경
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={() => onClickCancel()}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Dialog>
  )
}

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`
