import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import {HTMLAttributes, PropsWithChildren} from "react"
import styled from "@emotion/styled"
import {couponCodeTableHeaderItemList} from "pages/CouponCode/CouponCodeListPage/CouponCodeListTable/TableHeader"
import {toDateStr, toTimeStr} from "utils/datetimeUtil"
import {numberWithCommas} from "utils/NumberUtils"
import {CouponAvailableDateTypeCode, CouponCodeType} from "types/CouponCodeType"
import {Button} from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  couponCode: CouponCodeType
} & HTMLAttributes<HTMLDivElement>

const CouponCodeListTableRow = ({ couponCode, ...props }: PropsWithChildren<Props>) => {
  const navigate = useNavigate()

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[0].width }}>{couponCode.code}</TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[1].width }}>
        <StatusText style={{ fontWeight: 800 }} status={couponCode.status}>
          {couponCode.status}
        </StatusText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[2].width }}>{couponCode.name}</TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[3].width }}>{couponCode.type}</TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[4].width, justifyContent: "center" }}>
        <Button
          size={"small"}
          variant={"outlined"}
          href={`/coupon-codes?tab=coupon-usage&filter=code&keyword=${couponCode.code}`}
          onClick={(event) => {
            event.stopPropagation()
        }}>
          {numberWithCommas(couponCode.usageCount) || '-'} / {numberWithCommas(couponCode.maxUsageCount)}
        </Button>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[5].width, justifyContent: "center" }}>
        <Button
          size={"small"}
          color={"warning"}
          variant={"outlined"}
          href={`/coupon-codes?tab=point-usage&filter=code&keyword=${couponCode.code}`}
          onClick={(event) => {
            event.stopPropagation()
        }}>
          이력 조회
        </Button>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[6].width, justifyContent: "end" }}>
        {numberWithCommas(couponCode.points)} P
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[7].width, justifyContent: "end" }}>
        {
          couponCode.availableDateType == CouponAvailableDateTypeCode.만료일_직접_지정
            ? `${toDateStr(couponCode.availableDate, true)} 까지`
            : `쿠폰 사용일 D+${couponCode.availableDays}`
        }
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[8].width, justifyContent: "end" }}>
        {toDateStr(couponCode.startedAt, false)}<br/>
        {toTimeStr(couponCode.startedAt, true)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[9].width, justifyContent: "end" }}>
        {toDateStr(couponCode.endedAt, false)}<br/>
        {toTimeStr(couponCode.endedAt, true)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[10].width, justifyContent: "end" }}>
        {toDateStr(couponCode.createdAt, false)}<br/>
        {toTimeStr(couponCode.createdAt, true)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeTableHeaderItemList[11].width, justifyContent: "end" }}>
        {toDateStr(couponCode.updatedAt, false)}<br/>
        {toTimeStr(couponCode.updatedAt, true)}
      </TableRowItem>
    </TableRow>
  )
}

export default CouponCodeListTableRow

const StatusText = styled.div<{ status?: CouponCodeType["status"] }>`
  color: ${({ status }) =>
    status === "활성" ? "#1976d2" : "#AAAAAA"};
  text-decoration: ${({ status }) => {
    if (!status) {
      return
    }

    return status === "활성" ? "unset" : "line-through"
  }};
`
