import React from "react";
import {SellOrderType} from "types/SellOrderType";

type BeforeDiagnosisProductImagesProps = {
    sellOrder?: SellOrderType,
    editable?: Boolean,
    setEditable: Function,
    updateRepresentativeImageByGalleryIndex: Function
}

const BeforeDiagnosisProductGallery: React.FC<BeforeDiagnosisProductImagesProps> = ({
    sellOrder, editable, setEditable, updateRepresentativeImageByGalleryIndex
}) => {
    return (
        <div style={{
            display: "grid",
            gap: "10px",
            gridTemplateColumns: "auto auto auto auto",
        }}>
            {(sellOrder?.product?.gallery || []).map((item, idx) => (
                <div style={{
                    position: "relative",
                    width: "300px",
                    height: "330px",
                    textAlign: "left"
                }}>
                    {
                    editable &&
                    <p style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "30px",
                        height: "30px",
                        margin: 0
                    }}><input type="radio"
                              checked={(idx == 0)}
                              disabled={!editable}
                              onClick={() => {
                                  setEditable(false)
                                  updateRepresentativeImageByGalleryIndex(idx)
                                      .finally(() => setEditable(true))
                              }}
                    /> 대표 사진 설정</p>
                    }
                    <p style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        background: "#00000088",
                        borderRadius: "20px",
                        padding: "0 8px",
                        margin: "0px",
                        fontSize: "12px",
                        color: "#FFF"
                    }}>{idx + 1} /{sellOrder?.product?.gallery?.length}</p>
                    <img
                        src={`${item.url}`}
                        srcSet={`${item.url}`}
                        alt={item.url}
                        loading="lazy"
                        style={{objectFit: "contain", width: "300px", height: "300px", border: "1px solid #DDD"}}
                        onClick={() => {
                            window.open(`${item.url}`, "_blank")
                        }}
                    />
                </div>
            ))}
        </div>
    )
}

export default BeforeDiagnosisProductGallery