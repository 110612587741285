import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import styled from "@emotion/styled"
import { TranslationColumnManagerState } from "./useTranslationColumnManager"

type SchemaType = "viver"

const schemaList: { value: SchemaType; title: string }[] = [{ value: "viver", title: "VIVER" }]

interface TranslationColumnManagerSearchBarProps {
  state: TranslationColumnManagerState
  dispatch: Dispatch<SetStateAction<TranslationColumnManagerState>>
}

const TranslationColumnManagerSearchBar: React.FC<TranslationColumnManagerSearchBarProps> = (props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <InputLabel id="schema">스키마</InputLabel>
        <Select
          size={"small"}
          value={props.state.schema}
          required
          onChange={(e) => {
            props.dispatch((prevState) => ({
              ...prevState,
              schema: e.target.value as SchemaType,
            }))
          }}
        >
          {schemaList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ width: 20 }}></div>
      <FormControl style={{ minWidth: "200px" }}>
        <InputLabel id="table" shrink={Boolean(props.state.tableName)}>
          테이블
        </InputLabel>
        <Select
          size={"small"}
          value={props.state.tableName}
          required
          onChange={(e) => {
            props.dispatch((prevState) => ({
              ...prevState,
              tableName: e.target.value as string,
            }))
          }}
        >
          {props.state.tableList?.map((table) => (
            <MenuItem value={table.tableName} key={table.tableName}>
              {table.tableName}
              {table.tableAlias && " - " + table.tableAlias}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  )
}

export default TranslationColumnManagerSearchBar

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
