import styled from "@emotion/styled"
import { useEffect, useRef } from "react"
import { parseMarkdownToHtml } from "utils/markdownUtils"

type Props = {
  markdownState: string
  handleDeleteTerm: () => void
  handleSaveTerm: () => void
  isSavable: boolean
  isNewTerm: boolean
}

const DeleteIconSrc = require("images/24_delete.png")

export const TermPreview = ({ markdownState, handleDeleteTerm, handleSaveTerm, isSavable, isNewTerm }: Props) => {
  const markdownPreviewRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const ifrmaeContentDocument = markdownPreviewRef.current?.contentDocument
    if (ifrmaeContentDocument) {
      ifrmaeContentDocument.open()
      ifrmaeContentDocument.write(parseMarkdownToHtml(markdownState))
      ifrmaeContentDocument.close()
    }
  }, [markdownState])

  return (
    <Wrapper>
      <ContentWrapper>
        <PreviewLabel>미리보기</PreviewLabel>
        <PreviewContainer>
          <MarkdownIframe ref={markdownPreviewRef} title="term_preview" />
        </PreviewContainer>
      </ContentWrapper>
      <BottomWrapper>
        <DeleteButtonWrapper>
          {isNewTerm && <ImageWrapper />}
          <DeleteButton onClick={handleDeleteTerm}>
            <img src={DeleteIconSrc} alt="delete-icon" />
            삭제
          </DeleteButton>
        </DeleteButtonWrapper>

        <Button disabled={!isSavable} onClick={handleSaveTerm}>
          저장
        </Button>
      </BottomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

const ContentWrapper = styled.div`
  display: inline-flex;

  flex: 1;
  padding: 40px 56px 16px 48px;
`

const PreviewLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #3b464f;
`

const PreviewContainer = styled.div`
  background: #ffffff;
  border: 1px solid #ced2d6;
  border-radius: 4px;
  margin-left: 32px;

  display: flex;
  flex-direction: column;
  width: 624px;
  height: 800px;
  padding: 24px;
  overflow: scroll;
`

const BottomWrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 24px;
`

const DeleteButtonWrapper = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 72px;
  height: 40px;
`

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  width: 72px;

  color: #666f78;
  font-size: 15px;
  padding: 8px;
  outline: none;
  background: #fff;
  border: none;

  img {
    height: 24px;
    width: 24px;
    margin-right: 4px;
  }
`

const Button = styled.button`
  width: 120px;
  height: 40px;
  background: #1c2229;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 6px;

  :disabled {
    background: #f7f8fa;
    color: #ced2d6;
  }
`

const MarkdownIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`
