import styled from "@emotion/styled"

import { TermDetailPostBody } from "types/TermType"
import { MarkdownEditor } from "./MarkdownEditor"
import { DatePicker } from "@mui/lab"
import { Stack, TextField } from "@mui/material"
import { DateTime } from "luxon"
import { toDateStr } from "utils/datetimeUtil"

type Props = {
  termDetail: TermDetailPostBody
  changeTermDetail: (key: string, value: any) => void
  changeMarkdownState: (text: string) => void
}

export const TermEditor = ({ termDetail, changeTermDetail, changeMarkdownState }: Props) => {
  return (
    <Wrapper>
      <Title>{termDetail.type}</Title>
      <Label>고지일</Label>
        <DatePicker<DateTime>
          value={termDetail.notifyDate || null}
          inputFormat={"yyyy-MM-dd"}
          onChange={(newValue) => {
            changeTermDetail("notifyDate", newValue ? toDateStr(newValue.toISO()) : null)
          }}
          renderInput={({ inputProps, ...restParams }) => (
            <TextField
              {...restParams}
              variant="standard"
              size={"small"}
              inputProps={{
                ...inputProps,
                placeholder: "YYYY-MM-DD",
              }}
            />
          )}
        />

      <Label>적용일</Label>
        <Stack direction={"row"} spacing={1}>
          <DatePicker<DateTime>
            value={termDetail.startDate}
            inputFormat={"yyyy-MM-dd"}
            onChange={(newValue) => {
              changeTermDetail("startDate", newValue ? toDateStr(newValue.toISO()) : toDateStr(DateTime.now().toISO()))
            }}
            renderInput={({ inputProps, ...restParams }) => (
              <TextField
                {...restParams}
                variant="standard"
                size={"small"}
                inputProps={{
                  ...inputProps,
                  placeholder: "YYYY-MM-DD",
                }}
              />
            )}
          />
          <div style={{ padding: "0 8px" }}>~</div>
          <DatePicker<DateTime>
            inputFormat={"yyyy-MM-dd"}
            value={termDetail.endDate || null}
            onChange={(newValue) => {
              changeTermDetail("endDate", newValue ? toDateStr(newValue.toISO()) : null)
            }}
            renderInput={({ inputProps, ...restParams }) => {
              return (
                <TextField
                  {...restParams}
                  variant="standard"
                  size={"small"}
                  inputProps={{
                    ...inputProps,
                    placeholder: "YYYY-MM-DD",
                  }}
                />
              )
            }}
          />
        </Stack>
      <div style={{ height: 40 }} />

      <Line />

      <MarkdownEditor initialContent={termDetail.content} changeMarkdownState={changeMarkdownState} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 24px 20px 20px;
  flex: 1;
  min-width: 360px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
`

const Label = styled.div`
  font-size: 16px;
  color: #3b464f;
  margin: 32px 0 10px;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
`
