import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const PopupPageFooter = ({ children }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Footer>{children}</Footer>
}

export default PopupPageFooter

const Footer = styled.div`
  width: 100%;
  padding-top: 16px;
`
