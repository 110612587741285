import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import {
  ShopCurationType,
  ShopCurationProductType,
  ShopCurationBrandType,
  ShopCurationBrandModelType,
} from "types/ShopCurationType"
import { Box, Button, Input, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import styled from "@emotion/styled"
import { DateTime } from "luxon"

type Props = {
  models: ShopCurationBrandModelType[] | null
  setBrands: Dispatch<SetStateAction<ShopCurationBrandType>>
  handleOnChangeState: (partialState: Partial<ShopCurationType>) => void
  openShowProductSelectPopup: () => void
}

const CurationBrandModelTable = ({ models, setBrands, handleOnChangeState, openShowProductSelectPopup }: Props) => {
  const [selectionCurationModelIds, setSelectionCurationModelIds] = useState<number[]>([])


  const handleChangeLink = (link: string, id: number) => {
    if (models == null) return

    const updatedModels = models.map((model: ShopCurationBrandModelType, index) => {
      if (model.model?.id === id) {
        return { ...model, link: link }
      }
      return model
    })

    setBrands((prevState) => ({
      ...prevState,
      models: [
        ...updatedModels,
      ],
    }))

    // handleOnChangeState({
    //   models: updatedModels,
    // })
  }

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        valueGetter: (params: any) => `${params.row.model.id}`,
      },
      {
        field: "model.name",
        headerName: "모델명",
        width: 200,
        valueGetter: (params: any) => `${params.row.model.name}`,
      },
      {
        field: "model.link",
        headerName: "링크",
        width: 160,
        flex: 1,
        renderCell: (params: any) => {
          return <Input
            placeholder={"https://"}
            value={params.row.link}
            onChange={(e) => handleChangeLink(e.target.value, params.row.model.id)} />
        },
      },
      {
        field: "model.thumbnail",
        headerName: "썸네일",
        width: 100,
        renderCell: (params: any) => <img src={params.row.model.thumbnail} style={{ height: "100%" }} />,
      },
    ]

    return [
      ...defaultColumns,
      { field: "priority", headerName: "순서(수동입력)", width: 100, editable: true, type: "number" },
      {
        field: "actions",
        type: "actions",
        headerName: "순서(위치이동)",
        width: 120,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<ArrowUpwardIcon />}
            label={"위로"}
            onClick={() => {
              if (models == null) return

              const index = models.findIndex((brand) => brand && brand.id === params.row.id)
              if (index <= 0) return

              let nextProducts = [
                ...models.slice(0, index - 1),
                models[index],
                models[index - 1],
                ...models.slice(index + 1),
              ]

              nextProducts = nextProducts.map(data => ({
                ...data,
                priority: null,
              }))

              setBrands((prevState) => ({
                ...prevState,
                models: [
                  ...nextProducts,
                ],
              }))

              //handleOnChangeState({ models: nextProducts })
            }}
          />,
          <GridActionsCellItem
            icon={<ArrowDownwardIcon />}
            label={"아래로"}
            onClick={() => {
              if (models == null) return

              const index = models.findIndex((brand) => brand && brand.id === params.row.id)
              if (index === models.length - 1) return

              let nextProducts = [
                ...models.slice(0, index),
                models[index + 1],
                models[index],
                ...models.slice(index + 2),
              ]

              nextProducts = nextProducts.map(data => ({
                ...data,
                priority: null,
              }))

              setBrands((prevState) => ({
                ...prevState,
                models: [
                  ...nextProducts,
                ],
              }))
              // handleOnChangeState()
            }}
          />,
        ],
      },
    ]
  }, [handleOnChangeState, models])

  return (
    <Box>
      <Stack height={"100%"}>
        <ButtonWrapper>
          <Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={openShowProductSelectPopup}
            style={{ marginRight: 10 }}
          >
            추가
          </Button>
          <Button
            variant={"contained"}
            color={"error"}
            size={"small"}
            disabled={selectionCurationModelIds.length < 1}
            onClick={() => {
              if (models == null) return

              const nextProducts = models.filter(
                (model) => model && !selectionCurationModelIds.includes(model.model?.id as number),
              )

              setBrands((prevState) => ({
                ...prevState,
                models: [
                  ...nextProducts,
                ],
              }))
              //handleOnChangeState({ brands: nextProducts })
            }}
          >
            삭제
          </Button>
        </ButtonWrapper>
      </Stack>
      <DataGrid
        getRowId={(row: any) => row.model.id}
        style={{
          height: "500px",
        }}
        columns={columns}
        onSelectionModelChange={(ids) => {
          setSelectionCurationModelIds(ids as number[])
        }}
        disableSelectionOnClick
        checkboxSelection={true}
        getRowHeight={() => 80}
        rows={models ?? []}
        hideFooter
        onCellEditCommit={(params) => {
          if(models != null){
            const index = models.findIndex(({ model }) => model && model.id === params.id)
            if (index < 0) return

            const updatedModels = [...models];
            updatedModels[index] = {
              ...updatedModels[index],
              priority: params.value
            };

            // const sortedRows = [...updatedModels].sort((a, b) => {
            //   if (a.priority && b.priority) {
            //     return a.priority - b.priority;
            //   } else {
            //     return 0;
            //   }
            // });

            setBrands((prevState) => ({
              ...prevState,
              models: [
                ...updatedModels,
              ],
            }))
            //handleOnChangeState({ brands: updatedModels })
          }
        }}
      />


    </Box>
  )
}

export default CurationBrandModelTable

const ButtonWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
`
