import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import {ShopBrandLogoType} from "types/ShopBrandLogoType";


export default {
    create: (brandLogo: ShopBrandLogoType): Promise<ShopBrandLogoType> => httpPost("/shop/brand-logo", brandLogo),
    update: (id: number, brandLogo: ShopBrandLogoType): Promise<ShopBrandLogoType> => httpPut(`/shop/brand-logo/${id}`, brandLogo),
    get: (id: number): Promise<ShopBrandLogoType> => httpGet(`/shop/brand-logo/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/shop/brand-logo/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<ShopBrandLogoType>> => httpGet("/shop/brand-logo", params),
    getList: (params?:  { [key: string]: any }): Promise<ShopBrandLogoType[]> => httpGet("/shop/brand-logo/list", params),
    reordering: (list: ShopBrandLogoType[]): Promise<ShopBrandLogoType[]> => httpPut(`/shop/brand-logo/reordering`, list),
}