export declare interface ShopHeroBannerType {
    id?: number | null,
    description: string | null,
    imageUrl: string | null,
    linkUrl: string | null,
    onDisplay: boolean | null,
    priority: number | null,
    startedAt: string | null,
    endedAt: string | null,
    createdAt?: string | null,
    bannerType?: BannerTypeCode | null,
    bannerBody?: string | null,
    isMotionOff: boolean ,
    thumbnailUrl?: string | null,
    videoUrl?: string | null,
    themeModeType: ThemeModeTypeCode | null,
    isMagazine: boolean,
    title1?: string | null,
    title2?: string | null,
    title3?: string | null,
    showDomesticUser?: boolean,
    showForeignUser?: boolean
}

export const enum BannerTypeCode {
    IMAGE = "IMAGE",
    // HTML = "HTML",
    VIDEO = "VIDEO",
}

export const BannerTypeCodeList = [
    { value: BannerTypeCode.IMAGE, title: "이미지" },
    // { value: BannerTypeCode.HTML, title: "HTML" },
    { value: BannerTypeCode.VIDEO, title: "동영상(mp4)" },
];


export const enum ThemeModeTypeCode {
    LIGHT = "LIGHT",
    DARK = "DARK",
}

export const ThemeModeTypeCodeList = [
    { value: ThemeModeTypeCode.LIGHT, title: "Light" },
    { value: ThemeModeTypeCode.DARK, title: "Dark" },
];
