import { FunctionComponent } from "react"
import { ProductType } from "types/ProductType"

const ProductInformation: FunctionComponent<{
  product?: Omit<ProductType, "id">
}> = ({ product }) => {
  return (
    <table className={"basic"}>
      <tbody>
        <tr>
          <td className={"ta-center"} rowSpan={3}>
            <img
              style={{ display: "block", margin: "auto", width: "200px", height: "300px", objectFit: "cover" }}
              src={product?.thumbnail}
              alt=""
            />
          </td>
          <td className={"header"} colSpan={3}>
            상품명
          </td>
          <td className={"header"}>Ref No.</td>
        </tr>
        <tr>
          <td>{product?.brandSid}</td>
          <td>{product?.title}</td>
          <td>{product?.titleSecondary}</td>
          <td>{product?.detailModel?.fullRefNo}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default ProductInformation
