import {Button, Grid} from "@mui/material";
import ShowcaseTable from "./ShowcaseTable";
import React, {useEffect, useState} from "react";
import {ShowcaseType} from "types/ShowcaseType";
import AddShowcasePopup from "../../../components/Popup/AddShowcasePopup";
import {deleteShowcase, hideShowcase, showShowcase} from "../../../apis/showcaseAPI";

type ShowcaseTabProps = {
    showcases: ShowcaseType[],
    selectionShowcaseIds: number[]
    handleSelection?: Function
    handleShowcaseChanged?: Function,
}

type ShowcaseTableState = {
    selectionShowcaseIds: number[],
    openAddShowcasePopup: boolean
}
const initShowcaseTableState: ShowcaseTableState = {
    selectionShowcaseIds: [],
    openAddShowcasePopup: false
}


const ShowcaseTab: React.FC<ShowcaseTabProps> = ({showcases, selectionShowcaseIds, handleSelection, handleShowcaseChanged}) => {
    const [state, setState] = useState<ShowcaseTableState>(initShowcaseTableState)

    const openAddShowcasePopup = () => { setState({...state, openAddShowcasePopup: true})}
    const closeAddShowcasePopup = () => {
        setState({...state, openAddShowcasePopup: false})
        handleShowcaseChanged?.()
    }

    const handleDeleteShowcaseButtonClick = (id: number|number[]) => {
        Promise.all((typeof id === 'number' ? [id] : id).map(showcaseId => deleteShowcase(showcaseId))).then(() => {
            handleShowcaseChanged?.()
        })
    }

    const handleShowShowcaseButtonClick = (id: number|number[]) => {
        Promise.all((typeof id === 'number' ? [id] : id).map(showcaseId => showShowcase(showcaseId))).then(() => {
            handleShowcaseChanged?.()
        })
    }

    const handleHideShowcaseButtonClick =  (id: number|number[]) => {
        Promise.all((typeof id === 'number' ? [id] : id).map(showcaseId => hideShowcase(showcaseId))).then(() => {
            handleShowcaseChanged?.()
        })
    }

    const _handleSelection = (ids: number[]) => {
        setState({...state, selectionShowcaseIds: ids})
        handleSelection?.(ids)
    }

    return (
        <Grid item xs={12} container spacing={2}>
            <AddShowcasePopup open={state.openAddShowcasePopup} handleClose={closeAddShowcasePopup} />
            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={() => handleShowShowcaseButtonClick(state.selectionShowcaseIds)}>노출</Button></Grid>
                <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={() => handleHideShowcaseButtonClick(state.selectionShowcaseIds)}>숨김</Button></Grid>

                <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={() => openAddShowcasePopup()}>추가</Button></Grid>
                <Grid item xs={1}><Button variant={"contained"} size={"small"} color={"error"} fullWidth onClick={() => handleDeleteShowcaseButtonClick(state.selectionShowcaseIds)} >삭제</Button></Grid>
            </Grid>
            <Grid item xs={12}><ShowcaseTable
                showcases={showcases}
                selectionShowcaseIds={selectionShowcaseIds}
                handleSelection={_handleSelection}
                handleShowcaseChanged={handleShowcaseChanged}
            />
            </Grid>
        </Grid>
    )
}

// @ts-ignore
export default ShowcaseTab