import {PaginationParamsType} from "types/PageType"
import {useCallback, useState} from "react"
import styled from "@emotion/styled"
import {useMount} from "react-use"
import {CouponCodeUsageHistorySearchParams, CouponCodeUsageHistoryType} from "types/CouponCodeUsageHistoryType";
import {getCouponCodeUsageHistoryList} from "../../../apis/couponCodeAPI";
import CouponCodeUsageHistoryListTableSearchBar from "./SearchBar";
import CouponCodeUsageHistoryListTable from "./PointListTable";
import {useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";

export type CouponCodeUsageHistorySearchType = {
  filter: keyof CouponCodeUsageHistorySearchParams
  keyword: string
}

const FETCH_SIZE = 20

const CouponCodeUsageHistoryPage = () => {
  const [pointList, setCouponCodeUsageHistory] = useState<CouponCodeUsageHistoryType[]>([])
  const [searchParams, setSearchParams] = useState<CouponCodeUsageHistorySearchType>({filter: "code", keyword: ""})

  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_SIZE,
  })

  const parseSearchParams = useCallback((searchParams?: CouponCodeUsageHistorySearchType) => {
    if (!searchParams) {
      return {}
    }
    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchCouponCodeUsageHistory = useCallback(
    (pageNumber?: number, searchParams?: CouponCodeUsageHistorySearchType) => {
        getCouponCodeUsageHistoryList({ size: FETCH_SIZE, page: pageNumber, ...parseSearchParams(searchParams) }).then(
        ({ content, totalElements = 0, totalPages = 0, first, size = FETCH_SIZE, number = 0 }) => {
          setCouponCodeUsageHistory(content || [])
          setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
          if (!totalElements) toast.info("조회 결과가 없습니다.")
        }
      )
    },
    [parseSearchParams]
  )

  const changeSearchFilterValue = useCallback(
    (searchFilterValue: keyof CouponCodeUsageHistorySearchParams) => {
      setSearchParams({ filter: searchFilterValue, keyword: "" })
      fetchCouponCodeUsageHistory()
    },
    [fetchCouponCodeUsageHistory]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(
    (keyword: CouponCodeUsageHistorySearchType["keyword"]) => {
      fetchCouponCodeUsageHistory(0, { ...searchParams, keyword })
      setPaginationParams((prev) => ({ ...prev, number: 0 }))
    },
    [fetchCouponCodeUsageHistory, searchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber - 1 }))
      fetchCouponCodeUsageHistory(pageNumber - 1, searchParams)
    },
    [searchParams, fetchCouponCodeUsageHistory]
  )

  const [queryParam] = useSearchParams()
  useMount(() => {
    const keyword = queryParam.get("keyword") as string
    if(keyword) {
        setSearchParams({ ...searchParams, keyword })
        onSearch(keyword)
    } else
      fetchCouponCodeUsageHistory()
  })

  return (
    <>
      <PageHeaderWrapper>
        <CouponCodeUsageHistoryListTableSearchBar
          searchParams={searchParams}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <CouponCodeUsageHistoryListTable histories={pointList} paginationParams={paginationParams} changePage={changePageNumber} />
    </>
  )
}

export default CouponCodeUsageHistoryPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 16px;
  margin-bottom: 20px;
`
