import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import React, { useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import UploadIcon from "@mui/icons-material/Upload"

const Input = styled("input")({
  display: "none",
})

type ThumbnailButtonProps = {
  imageUrl?: string | null
  videoUrl?: string | null
  handleImage: (files: FileList) => void
  handleDeleteImage: Function
  width?: string
  height?: string
  buttonHeight?: string | undefined
  text?: string
  objectFit?: string
}

// @ts-ignore
type UploadButtonProps = {
  handleImage: (files: FileList) => void
  width?: string
  height?: string
  variant?: "text" | "outlined" | "contained" | undefined
  text?: string
  isActive: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
}
const BaseStyledComponent = styled("div")<{ isActive: boolean }>(({ isActive }) => ({
  backgroundColor: isActive ? "#d1d1d1" : "",
  display: "flex",
  cursor: "pointer",
  textAlign: "center",
  transition: "background-color 0.3s",
}))

const StyledLabel = styled(BaseStyledComponent.withComponent("label"))({})

const StyledImg = styled(BaseStyledComponent.withComponent("img"))({})

const UploadButton: React.FC<UploadButtonProps> = ({
  handleImage,
  width,
  height,
  variant,
  text,
  isActive,
  setActive,
}) => {
  const refId = useRef(Math.random())

  return (
    <StyledLabel
      isActive={isActive}
      onDragEnter={(event) => {
        event.preventDefault()
        event.stopPropagation()
        setActive(true)
      }}
      onDragLeave={(event) => {
        event.preventDefault()
        event.stopPropagation()
        setActive(false)
      }}
      onDragOver={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
      onDrop={(event) => {
        event.preventDefault()
        event.stopPropagation()
        setActive(false)
        if (event?.dataTransfer.files != null) handleImage(event?.dataTransfer.files)
      }}
      htmlFor={`contained-button-file-${refId.current}`}
    >
      <Input
        accept="image/*|video/mp4"
        id={`contained-button-file-${refId.current}`}
        multiple
        type="file"
        onChange={(params) => {
          if (params?.target?.files != null) handleImage(params?.target?.files)
        }}
      />
      <Button variant={variant} component="span" fullWidth sx={{ width: width, height: height }}>
        {text ? text : <UploadIcon />}
      </Button>
    </StyledLabel>
  )
}

const ImageUploadButton: React.FC<ThumbnailButtonProps> = ({
  imageUrl,
  videoUrl,
  width,
  height,
  handleImage,
  handleDeleteImage,
  text,
  objectFit = "contain",
  buttonHeight,
}) => {
  const [isActive, setActive] = useState<boolean>(false)

  var button = (
    <UploadButton
      handleImage={handleImage}
      width={width}
      height={height}
      variant={"outlined"}
      text={text}
      isActive={isActive}
      setActive={setActive}
    />
  )
  if (imageUrl != null && imageUrl != "") {
    button = (
      <Box>
        <Stack style={{ alignContent: "center" }} spacing={1}>
          <StyledImg
            isActive={isActive}
            src={imageUrl}
            style={{
              width: "100%",
              maxWidth: width,
              height: height,
              alignSelf: "center",
              // @ts-ignore
              objectFit: objectFit,
              border: "1px solid #ddd",
            }}
            onDragEnter={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setActive(true)
            }}
            onDragLeave={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setActive(false)
            }}
            onDragOver={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
            onDrop={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setActive(false)
              if (event?.dataTransfer.files != null) handleImage(event?.dataTransfer.files)
            }}
          />
          <Stack direction="row" justifyContent="center" spacing={1}>
            <UploadButton
              handleImage={handleImage}
              variant={"contained"}
              text={"변경"}
              height={buttonHeight}
              isActive={isActive}
              setActive={setActive}
            />
            <label>
              <Button
                variant={"contained"}
                color={"error"}
                onClick={() => handleDeleteImage()}
                fullWidth
                sx={{ height: buttonHeight }}
              >
                삭제
              </Button>
            </label>
          </Stack>
        </Stack>
      </Box>
    )
  }
  if (videoUrl != null && videoUrl != "") {
    button = (
      <Box>
        <Stack style={{ alignContent: "center" }} spacing={1}>
          <video
            style={{ width: "100%", maxWidth: width, height: height, objectFit: "contain" }}
            autoPlay
            loop
            playsInline
          >
            <source src={videoUrl} />
          </video>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <UploadButton
              handleImage={handleImage}
              variant={"contained"}
              text={"변경"}
              height={buttonHeight}
              isActive={isActive}
              setActive={setActive}
            />
            <label>
              <Button
                variant={"contained"}
                color={"error"}
                onClick={() => handleDeleteImage()}
                fullWidth
                sx={{ height: buttonHeight }}
              >
                삭제
              </Button>
            </label>
          </Stack>
        </Stack>
      </Box>
    )
  }

  return <Box>{button}</Box>
}

export default ImageUploadButton
