import { AdminGroupType } from "../../../types/AdminUserType"
import { PageType } from "../../../types/PageType"
import React, { useEffect, useReducer } from "react"
import { findAdminGroupList, findAdminMenuCodes } from "../../../apis/adminGroupAPI"
import { Grid, Paper } from "@mui/material"
import AdminGroupTable from "./AdminGroupTable"
import AdminGroupDetailPopup from "./AdminGroupDetailPopup"

export interface AdminGroupListState {
  selectedId?: number
  menus: string[]
  adminGroups: PageType<AdminGroupType>
  isShowAdminGroupDetailPopup: boolean
  refresh: boolean
  loading: boolean
  pageSize: number
  pageNumber: number
}

const initState: AdminGroupListState = {
  selectedId: undefined,
  menus: [],
  adminGroups: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  isShowAdminGroupDetailPopup: false,
  refresh: false,
  loading: false,
  pageSize: 25,
  pageNumber: 0,
}

interface AdminGroupAction {
  type: string
  payload?: any
}

function reducer(state: AdminGroupListState, action: AdminGroupAction) {
  switch (action.type) {
    case "FETCH_ADMIN_GROUPS":
      return { ...state, adminGroups: action.payload }
    case "FETCH_ADMIN_GROUP_MENUS":
      return { ...state, menus: action.payload }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
    case "TOGGLE_ADMIN_GROUP_DETAIL_POPUP":
      return {
        ...state,
        isShowAdminGroupDetailPopup: !state.isShowAdminGroupDetailPopup,
        selectedId: action.payload,
      }
    case "CHANGE_PAGE_NUMBER":
      return {
        ...state,
        pageNumber: action.payload,
        refresh: !state.refresh,
      }
    case "CHANGE_PAGE_SIZE":
      return { ...state, pageNumber: 0, pageSize: action.payload, refresh: !state.refresh }
  }

  return state
}

const AdminGroupListPage = () => {
  const [state, dispatch] = useReducer(reducer, initState)

  const fetchAllAdminGroups = () => {
    dispatch({ type: "START_LOADING" })
    findAdminGroupList()
      .then((res) => dispatch({ type: "FETCH_ADMIN_GROUPS", payload: res }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchAdminGroupMenus = () => {
    findAdminMenuCodes().then((menus) => dispatch({ type: "FETCH_ADMIN_GROUP_MENUS", payload: menus }))
  }

  useEffect(() => {
    fetchAdminGroupMenus()
  }, [])

  useEffect(() => {
    fetchAllAdminGroups()
  }, [state.refresh])

  return (
    <Grid container spacing={2}>
      <AdminGroupDetailPopup
        open={state.isShowAdminGroupDetailPopup}
        adminGroupId={state.selectedId}
        handleClose={() => {
          dispatch({ type: "TOGGLE_ADMIN_GROUP_DETAIL_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
        menus={state.menus}
      />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <AdminGroupTable state={state} dispatch={dispatch} components={[]} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AdminGroupListPage
