import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import {ShopHeroBannerType} from "types/ShopHeroBannerType";


export default {
    create: (banner: ShopHeroBannerType): Promise<ShopHeroBannerType> => httpPost("/shop/hero-banners", banner),
    update: (id: number, banner: ShopHeroBannerType): Promise<ShopHeroBannerType> => httpPut(`/shop/hero-banners/${id}`, banner),
    get: (id: number): Promise<ShopHeroBannerType> => httpGet(`/shop/hero-banners/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/shop/hero-banners/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<ShopHeroBannerType>> => httpGet("/shop/hero-banners", params),
    getList: (params?:  { [key: string]: any }): Promise<ShopHeroBannerType[]> => httpGet("/shop/hero-banners/list", params),
    reordering: (list: ShopHeroBannerType[]): Promise<ShopHeroBannerType[]> => httpPut(`/shop/hero-banners/reordering`, list),
    reorderingByDirect: (list: ShopHeroBannerType[]): Promise<ShopHeroBannerType[]> => httpPut(`/shop/hero-banners/reordering/direct`, list),
}