import {useCallback, useState} from "react"
import styled from "@emotion/styled"
import {toast} from "react-toastify"
import {PaginationParamsType} from "types/PageType"
import {useMount} from "react-use"
import {UserCollectionSearchParams, UserCollectionType} from "../../../types/UserCollectionType";
import {getUserCollectionList} from "../../../apis/userCollectionAPI";
import UserCollectionListTableSearchBar from "./SearchBar";
import UserCollectionListTable from "./UserCollectionListTable";
import UserCollectionPopup from "../UserCollectionPopup";
import {PointListSearchType} from "../../Point/PointListPage";

export type UserCollectionListFilterKeyType = keyof UserCollectionSearchParams
export type UserCollectionSearchType = { filter: UserCollectionListFilterKeyType; keyword: string }

const FETCH_LIST_COUNT = 20

const UserListPage = () => {
  const [userCollectionList, setUserCollectionList] = useState<UserCollectionType[]>([])
  const [selectedUserCollectionId, setSelectedUserCollectionId] = useState<number>()
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })

  const [searchParams, setSearchParams] = useState<UserCollectionSearchType>({
    filter: "userId",
    keyword: "",
  })

  const changeSearchFilterValue = useCallback((searchFilterValue: UserCollectionListFilterKeyType) => {
    setSearchParams((prev) => ({ ...prev, filter: searchFilterValue }))
  }, [])

  const parseSearchParams = useCallback((searchParams?: UserCollectionSearchType) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchUserCollectionList = useCallback(
    (pageNumber?: number, searchParams?: UserCollectionSearchType) => {
      getUserCollectionList({
        size: FETCH_LIST_COUNT,
        page: pageNumber,
        ...parseSearchParams(searchParams),
      })
        .then(({ content, totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
          setUserCollectionList(content)
          setPaginationParams((prev) => ({
            ...prev,
            number,
            totalElements,
            totalPages,
            first,
            size,
          }))
        })
        .catch((err) => {
          toast.error("콜렉션 정보를 불러오는데 실패했습니다.")
          console.log("ERROR", err)
        })
    },
    [parseSearchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchUserCollectionList(pageNumber, searchParams)
    },
    [searchParams, fetchUserCollectionList]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback((keyword: UserCollectionSearchType["keyword"]) => {
    fetchUserCollectionList(0, { ...searchParams, keyword})
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchUserCollectionList, searchParams])

  useMount(() => {
    fetchUserCollectionList()
  })

  return (
    <>
      <PageHeaderWrapper>
        <TotalUser>
          전체<b style={{ marginLeft: 10, color: "#1976d2" }}>{paginationParams.totalElements || "-"}</b>건
        </TotalUser>
        <UserCollectionListTableSearchBar
          searchParams={searchParams}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <UserCollectionListTable
          userCollectionList={userCollectionList}
          selectUserCollectionId={(userCollectionId) => setSelectedUserCollectionId(userCollectionId)}
          paginationParams={paginationParams}
          changePage={(page) => changePageNumber(page - 1)}
        />
      </PageBodyWrapper>
      {!!selectedUserCollectionId && <UserCollectionPopup id={selectedUserCollectionId} closePopup={() => setSelectedUserCollectionId(0)} />}
    </>
  )
}

export default UserListPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const PageBodyWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const TotalUser = styled.div`
  font-weight: 800;
  display: inline-flex;
  width: 50%;
`
