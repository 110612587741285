import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import styled from "@emotion/styled"
import { TranslationColumnManagerState } from "./index"

type SchemaType = "viver"

const schemaList: { value: SchemaType; title: string }[] = [{ value: "viver", title: "VIVER" }]

interface TranslationManagerSearchBarProps {
  state: TranslationColumnManagerState
  dispatch: Dispatch<SetStateAction<TranslationColumnManagerState>>
}

const TranslationManagerSearchBar: React.FC<TranslationManagerSearchBarProps> = (props) => {
  return (
    <Wrapper>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: 8 }}>
        <div style={{ display: "flex", width: "100%", flex: 1, gap: 8 }}>
          <FormControl style={{ minWidth: "150px", display: "none" }}>
            <InputLabel id="schema">스키마</InputLabel>
            <Select
              size={"small"}
              value={props.state.schema}
              required
              onChange={(e) => {
                props.dispatch((prevState) => ({
                  ...prevState,
                  schema: e.target.value as SchemaType,
                }))
              }}
            >
              {schemaList.map((menu) => (
                <MenuItem value={menu.value.toString()} key={menu.title}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: "200px" }}>
            <InputLabel id="table" shrink={Boolean(props.state.tableName)}>
              테이블
            </InputLabel>
            <Select
              size={"small"}
              value={props.state.tableName}
              required
              onChange={(e) => {
                props.dispatch((prevState) => ({
                  ...prevState,
                  tableName: e.target.value as string,
                  columnName: "all",
                }))
              }}
            >
              {props.state.tableList?.map((table) => (
                <MenuItem value={table.tableName} key={table.tableName}>
                  {table.tableAlias && table.tableAlias + " - "}
                  {table.tableName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: "200px" }}>
            <InputLabel id="table" shrink={Boolean(props.state.columnName)}>
              컬럼
            </InputLabel>
            <Select
              size={"small"}
              value={props.state.columnName}
              required
              onChange={(e) => {
                props.dispatch((prevState) => ({
                  ...prevState,
                  columnName: e.target.value as string,
                }))
              }}
            >
              <MenuItem value={"all"} key={"column-all"}>
                전체
              </MenuItem>
              {props.state.columnList?.map((table) => (
                <MenuItem value={table.columnName} key={table.columnName}>
                  {table.columnName}
                  {table.columnNameAlias && " - " + table.columnNameAlias}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label={"키 값 입력"}
            size={"small"}
            value={props.state.searchKey}
            defaultValue={""}
            onChange={(e) => {
              props.dispatch((prevState) => ({
                ...prevState,
                searchKey: e.target.value,
              }))
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") props.dispatch((prevState) => ({ ...prevState, refresh: !prevState.refresh }))
            }}
          />
          <div style={{ width: 10 }}></div>
          <FormControl style={{ minWidth: "100px" }}>
            <InputLabel id="table" shrink={Boolean(props.state.tableName)}>
              언어
            </InputLabel>
            <Select
              label={"언어"}
              size={"small"}
              value={props.state.language.key}
              required
              onChange={(e) => {
                const selectedKey = e.target.value as string
                const selectedLanguage = props.state.languageList.find((language) => language.key === selectedKey)

                if (selectedLanguage) {
                  props.dispatch((prevState) => ({
                    ...prevState,
                    language: selectedLanguage,
                  }))
                }
              }}
            >
              {props.state.languageList?.map((language) => (
                <MenuItem value={language.key} key={language.key}>
                  {language.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ flex: 1 }}>
            <TextField
              label={"내용 입력"}
              size={"small"}
              value={props.state.searchContent}
              defaultValue={""}
              onChange={(e) => {
                props.dispatch((prevState) => ({
                  ...prevState,
                  searchContent: e.target.value,
                }))
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") props.dispatch((prevState) => ({ ...prevState, refresh: !prevState.refresh }))
              }}
            />
          </FormControl>
          <FormControl style={{ flex: 1 }}>
            <TextField
              label={"번역 내용 입력"}
              size={"small"}
              value={props.state.searchTranslationContent}
              defaultValue={""}
              onChange={(e) => {
                props.dispatch((prevState) => ({
                  ...prevState,
                  searchTranslationContent: e.target.value,
                }))
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") props.dispatch((prevState) => ({ ...prevState, refresh: !prevState.refresh }))
              }}
            />
          </FormControl>
        </div>
        <div>
          <Button
            variant={"outlined"}
            color={"info"}
            size={"large"}
            onClick={() => {
              props.dispatch((prevState) => ({
                ...prevState,
                refresh: !prevState.refresh,
              }))
            }}
          >
            조회
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default TranslationManagerSearchBar

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
