import styled from "@emotion/styled"
import Modal from "components/Modal"
import { DraggableProductStockListItem } from "./DraggableProductStockListItem"
import Button from "components/Button"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useProductStockListData } from "./useProductStockListData"

type Props = {
  closeModal: () => void
  site: "쇼룸" | "랩스"
}

export const ProductStockListManagerPopup = ({ closeModal, site }: Props) => {
  const {
    productStockList,
    newProductStockName,
    onSaveList,
    onChangeOrder,
    onChangeName,
    onChangeNewProductStockName,
    onAddNewList,
    onDeleteList,
  } = useProductStockListData(site)

  return (
    <Modal isOpen closeModal={closeModal}>
      <ContentWrapper>
        <Title>리스트 관리</Title>
        <ListWrapper>
          <DndProvider backend={HTML5Backend}>
            {productStockList.map((item) => (
              <DraggableProductStockListItem
                key={item.id}
                stockListItem={item}
                onChangeOrder={onChangeOrder}
                onChangeName={onChangeName}
                onDeleteList={onDeleteList}
              />
            ))}
          </DndProvider>
          <InlineWrapper>
            <div style={{ width: "24px" }} />
            <Input
              placeholder="리스트 추가"
              value={newProductStockName}
              onChange={(e) => onChangeNewProductStockName(e.target.value)}
            />
            <Icon src={require("../../../images/24_plus.png")} onClick={onAddNewList} />
          </InlineWrapper>
        </ListWrapper>
        <InlineWrapper style={{ marginTop: "20px", justifyContent: "flex-end" }}>
          <ButtonWrapper>
            <Button
              onClick={async () => {
                await onSaveList()
                closeModal()
              }}
            >
              저장
            </Button>
          </ButtonWrapper>
        </InlineWrapper>
      </ContentWrapper>
    </Modal>
  )
}

const ContentWrapper = styled.div`
  width: 324px;
  padding: 24px 7px 9px 24px;
`

const Title = styled.div`
  color: #212121;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Input = styled.input`
  width: 204px;
  height: 32px;

  margin: 0 10px 0 6px;
  padding: 7px 10px;
  border-radius: 2px;
  border: 1px solid #d4d4d4;
  outline: none;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  width: 90px;
`
