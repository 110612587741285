import React, {useEffect, useState} from "react";
import {FormControlLabel, Switch} from "@mui/material";
import {SellOrderType} from "../../../types/SellOrderType";
import {UserDetailType, UserMemoTargetTypeCode, UserType} from "../../../types/UserType";
import QuickMemoButton from "../../../components/QuickMemo/QuickMemoButton";
import {toDateTimeStr} from "../../../utils/datetimeUtil";
import UserIdButton from "../../../components/UserIdButton";
import {getUserDetail} from "../../../apis/userAPI";
import {toast} from "react-toastify";

type Props = {
    sellOrder?: SellOrderType,
}

const SellOrderInformation: React.FC<Props> = ({sellOrder}) => {
    const [seller, setSeller] = useState<UserType|UserDetailType|undefined>(sellOrder?.seller)
    const [unmask, setUnmask] = useState<boolean>(false)

    useEffect(() => {
        sellOrder?.seller && getUserDetail("" + sellOrder?.seller?.id, unmask)
            .then((user) => setSeller(user))
            .catch((err) => {
                toast.error("회원 정보 조회에 실패했습니다.")
                console.log("Error", err)
            })
    }, [sellOrder, unmask])

    return (
        <>
            <table className={"basic"}>
                <tbody>
                <tr>
                    <td className={"no-border"} colSpan={6} style={{textAlign: "end"}}>
                        <FormControlLabel
                            style={{color: "#ed3030"}}
                            control={<Switch color="warning" value={unmask}/>}
                            label="개인정보 마스킹 해제"
                            checked={unmask}
                            onChange={(event, checked) => {
                                if (checked) {
                                    checked = window.confirm("업무상 고객의 개인정보를 조회합니다." +
                                        "\n\n* 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 경우 " +
                                        "\n5년 이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다.")
                                }
                                setUnmask(checked)
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={"header"}>판매주문번호</td>
                    <td className={"header"}>상품번호</td>
                    <td className={"header"}>판매신청 일시</td>
                    <td className={"header"}>픽업요청 일시</td>
                    <td className={"header"}>입고확인 일시</td>
                    <td className={"header"}>진행상태</td>
                </tr>
                <tr>
                    <td>{sellOrder?.id}</td>
                    <td>
                        {sellOrder?.product?.id}
                        <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={sellOrder?.product?.id} small />
                    </td>
                    <td>{toDateTimeStr(sellOrder?.acceptedAt)}</td>
                    <td>{toDateTimeStr(sellOrder?.deliveryRequestedAt)}</td>
                    <td>{toDateTimeStr(sellOrder?.waitingDiagnosisAt)}</td>
                    <td>{sellOrder?.sellOrderStatus}</td>
                </tr>
                <tr>
                    <td className={"header"}>판매자 ID</td>
                    <td className={"header"}>판매자명</td>
                    <td className={"header"}>연락처</td>
                    <td className={"header"} colSpan={2}>
                        바이버 계정
                    </td>
                    <td className={"header"}>판매방식</td>
                </tr>
                <tr>
                    <td>
                        <UserIdButton id={seller?.id}/>
                        <QuickMemoButton target={UserMemoTargetTypeCode.USER} targetId={seller?.id} small />
                    </td>
                    <td>{seller?.name}</td>
                    <td>{seller?.phone}</td>
                    <td colSpan={2}>{seller?.email}</td>
                    <td>{sellOrder?.sellOrderType}</td>
                </tr>
                </tbody>
            </table>
        </>
    )
}

export default SellOrderInformation