import { EventApplyType } from "types/EventApplyType"
import { EventRequestBodyType } from "types/EventRequestBodyType"
import { EventType } from "types/EventType"
import { PageType } from "types/PageType"
import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"

export const getEventList: (searchParams: any) => Promise<PageType<EventType>> = (searchParams) =>
  httpGet("/events", searchParams)

export const getEventDetail: (eventId: number) => Promise<EventType> = (eventId) => httpGet(`/events/${eventId}`)

export const createEvent: (eventForm: EventRequestBodyType) => Promise<EventType> = (eventForm) =>
  httpPost(`/events`, eventForm)

export const updateEventDetail: (eventForm: EventRequestBodyType, eventId: number) => Promise<EventType> = (
  eventForm,
  eventId
) => httpPut(`/events/${eventId}`, eventForm)

export const deleteEvent: (eventId: number) => Promise<void> = (eventId) => httpDelete(`/events/${eventId}`)

export const getEventApplies = (eventId: number, paginationParams?: { page: number; size: number }) =>
  httpGet<PageType<EventApplyType>>(`/events/${eventId}/applies`, paginationParams)


export const getEventApplyList = (eventId: number) =>
  httpGet<EventApplyType[]>(`/events/${eventId}/apply/list`)