import { Identifier } from "dnd-core"
import { useDrag, useDrop } from "react-dnd"
import { useRef } from "react"

import styled from "@emotion/styled"

import { SelectableProductStockItemType } from "./ProductStockTable"

type Props = {
  id: number | null
  onChangeGroup: (prevId: number | null, newId: number | null, listItem: SelectableProductStockItemType) => void
}

export const ProductStockTableEmptyItem = ({ id, onChangeGroup }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, connectDrag] = useDrag({
    type: "STOCK_ITEM",
    item: id,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
  })

  const [, connectDrop] = useDrop<{ id: number | null; item: any }, void, { handlerId: Identifier | null }>({
    accept: "STOCK_ITEM",
    drop: (item) => {
      if (item.id !== id) {
        onChangeGroup(item.id, id, item.item)
      }
    },
  })

  connectDrag(ref)
  connectDrop(ref)

  return (
    <EmptyWrapper ref={ref} data-handler-id={handlerId}>
      결과가 없습니다.
    </EmptyWrapper>
  )
}

const EmptyWrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
