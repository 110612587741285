import styled from "@emotion/styled"
import { useRef } from "react"
import { Identifier } from "dnd-core"
import { useDrag, useDrop } from "react-dnd"

type Props = {
  stockListItem: { id: number; priority: number; name: string }
  onChangeName: (id: number, name: string) => void
  onChangeOrder: (prevPriority: number, newPriority: number) => void
  onDeleteList: (id: number) => void
}

export const DraggableProductStockListItem = ({ stockListItem, onChangeOrder, onChangeName, onDeleteList }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, connectDrag] = useDrag({
    type: "STOCK_ITEM",
    item: stockListItem,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
  })

  const [, connectDrop] = useDrop<
    { id: number; priority: number; name: string },
    void,
    { handlerId: Identifier | null }
  >({
    accept: "STOCK_ITEM",
    drop: (item) => {
      if (item.priority !== stockListItem.priority) {
        onChangeOrder(item.priority, stockListItem.priority)
      }
    },
  })

  connectDrag(ref)
  connectDrop(ref)

  return (
    <Wrapper ref={ref} data-handler-id={handlerId}>
      <Icon src={require("../../../images/24_align_justify.png")} />
      <Input value={stockListItem.name} onChange={(e) => onChangeName(stockListItem.id, e.target.value)} />
      <Icon src={require("../../../images/24_delete_gray.png")} onClick={() => onDeleteList(stockListItem.id)} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 4px;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
`

const Input = styled.input`
  width: 204px;
  height: 32px;

  margin: 0 10px 0 6px;
  padding: 7px 10px;
  border-radius: 2px;
  border: 1px solid #d4d4d4;
  outline: none;
`
