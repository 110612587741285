import { useEffect, useState } from "react"
import {
  getTranslationTableDetails,
  getTranslationTables, setTranslationTableColumnDelete, setTranslationTableColumnSave,
} from "../../apis/translationAPI"
import { TranslationTableType } from "../../types/TranslationTableType"
import { TranslationTableDetailType } from "../../types/TranslationTableDetailType"
import { PageType } from "../../types/PageType"

type SchemaType = "viver"

export type TranslationColumnManagerState = {
  schema: SchemaType,  // 데이터베이스 스키마 이름
  tableName: string,  // 데이터베이스 테이블 이름
  tableList: TranslationTableType[],  // 번역 테이블 목록
  tableDetailList: PageType<TranslationTableDetailType>,  // 번역 테이블 세부 목록
  columnNameList: string[],  // 열 이름 목록
  refresh: boolean,  // 상태 새로고침 플래그
  page: number,  // 현재 페이지 번호
  size: number  // 페이지당 항목 수
}

const initialState: TranslationColumnManagerState = {
  schema: "viver",
  tableName: "",
  tableList: [],
  tableDetailList: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  columnNameList: [],
  refresh: false,
  page: 0,
  size: 25,
}

const useTranslationColumnManager = () => {
  const [state, setState] = useState<TranslationColumnManagerState>(initialState)

  const setTableColumnTargetSave = () => {
    setTranslationTableColumnSave({
      schema: state.schema,
      tableName: state.tableName,
      columnNameList: state.columnNameList,
    }).then(() => {
      setState((prevState => ({
        ...prevState,
        refresh: !state.refresh,
      })))
    })
  }

  const setTableColumnTargetDelete = () => {
    setTranslationTableColumnDelete({
      schema: state.schema,
      tableName: state.tableName,
      columnNameList: state.columnNameList,
    }).then(() => {
      setState((prevState => ({
        ...prevState,
        refresh: !state.refresh,
      })))
    })
  }

  const fetchTableDetails = () => {
    getTranslationTableDetails({
      schema: state.schema,
      tableName: state.tableName,
      page: state.page,
      size: state.size,
    }).then((result) => {
      setState((prevState) => ({
        ...prevState,
        tableDetailList: result,
      }))
    })
  }

  const fetchTables = () => {
    getTranslationTables({
      schema: state.schema,
    }).then(result => {
      setState((prevState) => ({
        ...prevState,
        tableList: result,
        tableName: result[0].tableName,
      }))
    })
  }

  useEffect(() => {
    fetchTables()
  }, [state.schema])

  useEffect(() => {
    if (state.tableName)
      fetchTableDetails()
  }, [state.tableName, state.page, state.size, state.refresh])

  return {
    state,
    setState,
    setTableColumnTargetSave,
    setTableColumnTargetDelete,
  }
}

export default useTranslationColumnManager