import { PaginationParamsType } from "types/PageType"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  PenaltyListItem,
  PenaltyListSearchParams,
  PenaltySearchDateFilter,
  PenaltySearchFilterParams,
  PenaltyStatusCode,
} from "types/PenaltyType"
import { ShippingStatusCode } from "types/ShippingType"
import { getPenaltyCountList, getPenaltyList } from "apis/penaltyAPI"
import { CountType } from "types/CountType"
import { useSearchParams } from "react-router-dom"
import { DateTime } from "luxon"
import { SellOrderListSearchParams } from "../../../types/SellOrderType"

const PENALTY_LIST_SIZE = 20

export type CheckablePenalityListItem = PenaltyListItem & { isChecked: boolean }

export const usePenaltyList = () => {
  const [currentSearchParams] = useSearchParams()
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [countList, setCountList] = useState<CountType[]>([])
  const [penaltyList, setPenaltyList] = useState<CheckablePenalityListItem[]>([])
  const [isFilteringShippingRequested, setIsFilteringShippingRequested] = useState(false)
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: PENALTY_LIST_SIZE,
  })

  const [searchKey, setSearchKey ] = useState<"sellerId" | "sellerName">("sellerId");
  const [searchValue, setSearchValue] = useState<string | number>("");

  const [searchDateFilter, setSearchDateFilter] = useState<PenaltySearchDateFilter>({
    startDate: null,
    endDate: null,
  })

  const statusSearchParams = useMemo(() => {
    return { status: currentSearchParams.get("status") }
  }, [currentSearchParams])

  const filterShippingRequestedParams = useMemo(() => {
    return isFilteringShippingRequested
      ? {
        status: PenaltyStatusCode.완료,
        cancelShippingStatus: ShippingStatusCode.배송_준비,
        shippingStatus: ShippingStatusCode.배송_완료,
      }
      : {}
  }, [isFilteringShippingRequested])

  const fetchPenaltyList = useCallback(() => {
    getPenaltyList({
      size: PENALTY_LIST_SIZE,
      page: paginationParams.number,
      [searchKey]: searchValue,
      startDate: searchDateFilter.startDate?.toISODate(),
      endDate: searchDateFilter.endDate?.toISODate(),
      ...statusSearchParams,
      ...filterShippingRequestedParams,
      includeRequiredManagement: true,
    }).then(({ content, totalElements = 0, totalPages = 0, first, size = PENALTY_LIST_SIZE, number = 0 }) => {
      setPenaltyList(content.map((listItem) => ({ ...listItem, isChecked: false })) || [])
      setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
    })
  }, [paginationParams.number, statusSearchParams, filterShippingRequestedParams, isRefresh, searchDateFilter])

  const fetchPenaltyCountList = useCallback(() => {
    getPenaltyCountList({
      [searchKey]: searchValue,
      startDate: searchDateFilter.startDate?.toISODate(),
      endDate: searchDateFilter.endDate?.toISODate(),
      includeRequiredManagement: true,
    }).then((response) => {
      const totalCount = response.reduce((prev, current) => {
        return (prev += current.count)
      }, 0)

      setCountList([
        {
          label: "전체",
          count: totalCount,
          link: "/penalty",
          selected: true,
        },
        ...response.map((item) => ({
          label: item.label,
          count: item.count,
          link: `/penalty?status=${item.label}`,
          selected: false,
        })),
      ])
    })
  }, [])

  const changePage = useCallback((pageNumber: number) => {
    setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
  }, [])

  const togglePenaltyForShipping = (selectedPenalty: CheckablePenalityListItem) => {
    setPenaltyList((prev) => {
      return prev.map((prevPanelty) => ({
        ...prevPanelty,
        isChecked: selectedPenalty.orderId === prevPanelty.orderId ? !prevPanelty.isChecked : prevPanelty.isChecked,
      }))
    })
  }

  const toggleAllPenaltyForShipping = () => {
    const hasNoCheckedItem = !penaltyList.find((penalty) => penalty.isChecked)

    setPenaltyList((prev) => {
      return prev.map((prevPenalty) => ({
        ...prevPenalty,
        isChecked: hasNoCheckedItem,
      }))
    })
  }


  const refreshList = useCallback(() => {
    Promise.all([fetchPenaltyList(), fetchPenaltyCountList()])
  }, [fetchPenaltyList, fetchPenaltyCountList])


  useEffect(() => {
    refreshList()
  }, [refreshList])

  return {
    isLoading,
    refreshList,
    countList,
    penaltyList,
    paginationParams,
    searchDateFilter,
    setSearchDateFilter,
    searchKey,
    setSearchKey,
    searchValue,
    setSearchValue,
    setIsRefresh,
    changePage,
    currentStatus: statusSearchParams.status,
    isFilteringShippingRequested,
    setIsFilteringShippingRequested,
    togglePenaltyForShipping,
    toggleAllPenaltyForShipping,
    setPaginationParams,
    selectedPenaltyForShipping: penaltyList.filter((penalty) => penalty.isChecked),
  }
}
