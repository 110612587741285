import { HTMLAttributes, PropsWithChildren } from "react"

import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { toDateTimeStr } from "utils/datetimeUtil"
import { EventApplyType } from "types/EventApplyType"
import { eventApplyListTableHeaderItemList } from "./EventApplyListTableHeader"

type Props = {
  eventApply: EventApplyType
} & HTMLAttributes<HTMLDivElement>

export const EventApplyListTableRow = ({ eventApply, ...props }: PropsWithChildren<Props>) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: eventApplyListTableHeaderItemList[0].width }}>{eventApply.user.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: eventApplyListTableHeaderItemList[1].width }}>
        {converEmtpyValueToDash(eventApply.user.email)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventApplyListTableHeaderItemList[2].width }}>
        {converEmtpyValueToDash(eventApply.user.name)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventApplyListTableHeaderItemList[3].width }}>
        {converEmtpyValueToDash(eventApply.user.phone)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventApplyListTableHeaderItemList[4].width }}>
        {eventApply.extras ? JSON.stringify(eventApply.extras) : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventApplyListTableHeaderItemList[5].width }}>
        {toDateTimeStr(eventApply.createdAt)}
      </TableRowItem>
    </TableRow>
  )
}
