import styled from "@emotion/styled"
import { Colors } from "@viver-inc/viver-ui"

type CalloutProps = {
  type: "info" | "warning" | "error" | "none"
  title: string
  children: React.ReactNode
}

const getEmoji = (type: "info" | "warning" | "error" | "none"): string => {
  switch (type) {
    case "info":
      return "ℹ️"
    case "warning":
      return "⚠️"
    case "error":
      return "❌"
    case "none":
      return ""
    default:
      return ""
  }
}

const Callout: React.FC<CalloutProps> = ({ type, title, children }) => {
  const emoji = getEmoji(type)
  return (
    <StyledCallout type={type}>
      <Title>
        {emoji} {title}
      </Title>
      <div>{children}</div>
    </StyledCallout>
  )
}

export default Callout

const StyledCallout = styled.div<Pick<CalloutProps, "type">>`
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  color: ${(props) =>
    props.type === "info"
      ? "#31708f"
      : props.type === "warning"
        ? "#8a6d3b"
        : props.type === "error"
          ? "#a94442"
          : props.type === "none"
            ? Colors.GRAY_080
            : "#000"};
  background-color: ${(props) =>
    props.type === "info"
      ? "#d9edf7"
      : props.type === "warning"
        ? "#fcf8e3"
        : props.type === "error"
          ? "#f2dede"
          : props.type === "none"
            ? Colors.GRAY_020
            : "#fff"};
  border-color: ${(props) =>
    props.type === "info"
      ? "#bce8f1"
      : props.type === "warning"
        ? "#faebcc"
        : props.type === "error"
          ? "#ebccd1"
          : props.type === "none"
            ? Colors.BLACK
            : "#eee"};
`

const Title = styled.h4`
  margin: 0 0 10px 0;
`
