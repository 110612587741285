import { AdminUserType } from "./AdminUserType"
import { BuyOrderStatusCode, BuyOrderStatusGroupType } from "./BuyOrderType"
import { DiagnosisTypeCode, ProductType, SaleTypeCode } from "./ProductType"
import { UserType } from "./UserType"
import { ImageLabelType } from "../pages/Diagnoses/AttachmentImageList"

export const enum DiagnosisStatusCode {
  진단서_작성_대기 = "진단서 작성 대기",
  진단서_작성중 = "진단서 작성중",
  진단서_작성_완료 = "진단서 작성 완료",
  진단서_확정 = "진단서 확정",
}

export const enum DiagnosisResultCode {
  합격 = "합격",
  조건부_합격 = "조건부 합격",
  불합격 = "불합격",
  보류 = "보류",
}
export const enum DiagnosisGradeCode {
  양호 = "양호",
  보통 = "보통",
  업그레이드_필요 = "업그레이드 필요",
  판매_불가 = "판매 불가",
}
export const enum ProductConditionCode {
  미착용 = "미착용",
  중고 = "중고",
}
export const enum OfficialGuaranteeCode {
  있음 = "있음",
  없음 = "없음",
}
export const enum AccessoriesConditionCode {
  있음 = "있음",
  없음 = "없음",
  일부파손 = "일부파손",
}

export const enum OriginalBoxConditionCode {
  있음 = "있음",
  없음 = "없음",
}

export const enum ResourceContentTypeCode {
  IMG = "IMG",
  VID = "VID",
  TEXT = "TEXT",
}
export const enum PenaltyReasonTypeCode {
  가품 = "가품",
  취급불가_커스텀 = "취급불가 커스텀",
  부품_교체_필요 = "부품 교체 필요",
  상품화_불가 = "상품화 불가",
  상태_불일치 = "상태 불일치",
  위탁_전환 = "위탁 전환",
  다른상품전달 = "다른상품전달",
}

export declare interface ComponentsType {
  staffConfirmed: StaffConfirmedType
  matched: boolean
  mismatchedReason?: string
  note?: string
}

export declare interface StaffConfirmedType {
  productCondition: ProductConditionCode
  officialGuarantee: OfficialGuaranteeCode
  originalBoxCondition: OriginalBoxConditionCode
  accessoriesCondition: AccessoriesConditionCode
  accessories: Accessories
  stampingDate?: string
  onDisplayStampingYear: boolean
  note?: string
}

export declare interface Accessories {
  outerBox?: boolean
  innerBox?: boolean
  coscCert?: boolean
  userManual?: boolean
  userManualKor?: boolean
  userManualForeign?: boolean
  guaranteeCase?: boolean
  guaranteeManual?: boolean
  medal?: boolean
  summary?: string
  note?: string
}

export declare interface ConditionsType {
  parts: PartConditionType[]
  note?: string
}

export declare interface PartConditionType {
  label: string
  grade: DiagnosisGradeCode
  measured?: string
  note?: string
  imageRequired: boolean
}

export declare interface UpgradeRequiredType {
  label: string
  required: boolean
  action: boolean
  fee: number
  note?: string
}

export declare interface AttachmentType {
  id: number
  label: string
  type: ResourceContentTypeCode
  url?: string
  required: boolean
  onDisplay: boolean
}

export declare interface PriceRangeType {
  min?: number
  max?: number
  avg?: number
  median?: number
}

export declare interface BeforeAfterType {
  before?: number
  after?: number
  delta?: number
  reason?: string
}

export declare interface PricesType {
  estimatedPriceAtOrder?: PriceRangeType
  estimatedPriceAtDiagnosis?: PriceRangeType
  afterDiagnosisPrice?: BeforeAfterType
  afterUpgradePrice?: BeforeAfterType
  suggestedCorrection?: number
  suggestedPrice?: number
  finalPrice?: number
  purchasedPrice?: number
}

export const defaultImageLabels: ImageLabelType[] = [
  { label: "시리얼 넘버", required: true, onDisplaySelectable: false, uploadName: "0" },
  { label: "전면", required: true, onDisplaySelectable: true, uploadName: "1" },
  { label: "측면(크라운)", required: false, onDisplaySelectable: true, uploadName: "2" },
  { label: "후면(클라스프)", required: false, onDisplaySelectable: true, uploadName: "3" },
  { label: "측면(크라운 반대편)", required: false, onDisplaySelectable: true, uploadName: "4" },
  { label: "후면(뒷백)", required: false, onDisplaySelectable: true, uploadName: "5" },
  { label: "후면(전체)", required: false, onDisplaySelectable: true, uploadName: "6" },
  { label: "풀패키지샷", required: false, onDisplaySelectable: true, uploadName: "7" },
]

export const additionalImageLabels: ImageLabelType[] = [
  { label: "추가1", required: false, onDisplaySelectable: true, uploadName: "8" },
  { label: "추가2", required: false, onDisplaySelectable: true, uploadName: "9" },
  { label: "추가3", required: false, onDisplaySelectable: true, uploadName: "10" },
  { label: "추가4", required: false, onDisplaySelectable: true, uploadName: "11" },
  { label: "추가5", required: false, onDisplaySelectable: true, uploadName: "12" },
  { label: "추가6", required: false, onDisplaySelectable: true, uploadName: "13" },
  { label: "추가7", required: false, onDisplaySelectable: true, uploadName: "14" },
  { label: "추가8", required: false, onDisplaySelectable: true, uploadName: "15" },
  { label: "추가9", required: false, onDisplaySelectable: true, uploadName: "16" },
  { label: "추가10", required: false, onDisplaySelectable: true, uploadName: "17" },
  { label: "추가11", required: false, onDisplaySelectable: true, uploadName: "18" },
  { label: "추가12", required: false, onDisplaySelectable: true, uploadName: "19" },
  { label: "추가13", required: false, onDisplaySelectable: true, uploadName: "20" },
  { label: "추가14", required: false, onDisplaySelectable: true, uploadName: "21" },
  { label: "추가15", required: false, onDisplaySelectable: true, uploadName: "22" },
  { label: "추가16", required: false, onDisplaySelectable: true, uploadName: "23" },
]

export type PurchasedBuyOrderType = {
  id: string
  buyOrderStatus: BuyOrderStatusCode
  buyOrderStatusGroup: BuyOrderStatusGroupType
  buyer: UserType
  completedAt: string
  product: ProductType & { productDiagnosis: DiagnosisType }
}

export declare interface DiagnosisType {
  id: number
  seller?: UserType
  receivedAt?: string
  version: number

  purchasedBuyOrder?: PurchasedBuyOrderType
  sellerId?: number
  title?: string
  serialNo?: string
  status?: DiagnosisStatusCode
  result?: DiagnosisResultCode
  grade?: DiagnosisGradeCode
  failedReason?: string
  penaltyReasonType?: PenaltyReasonTypeCode
  components: ComponentsType
  appearanceCondition?: ConditionsType
  internalCondition?: ConditionsType
  images?: AttachmentType[]
  upgradeRequired?: UpgradeRequiredType[]
  generalReview?: string
  viverPrices?: PricesType
  userDesiredPrice?: number
  provisionalPreSettlement?: {
    standardPriceForPreSettlement: number // 선정산 견적 기준가
    preSettlementPrice: number // 최종 정산 견적가
    estimatedPriceAtRequest?: {
      // 선정산 신청 당시 최적판매가
      min: number
      max: number
      median: number
    }
  }
  confirmedPreSettlement?: {
    id?: number
    status: "작성대기" | "작성완료"
    estimatedPriceAtRequest: {
      min: number
      max: number
      median: number
    }
    standardPriceForPreSettlement?: number
    preSettlementPrice?: number
  } & (
    | {
        calculationType: "정률"
        rate: number
      }
    | {
        calculationType: "정가"
        amount: number
      }
  )
  diagnosedAt?: String
  diagnosedBy?: AdminUserType
  confirmedAt?: string
  confirmedBy?: AdminUserType
  notifiedAt?: string
  notifiedBy?: AdminUserType
  advancedDiagnosis?: boolean
}

export declare interface DiagnosisIssueListType {
  productId: number
  buyOrderId: string
  sellOrderId: string
  diagnosisId: number
  saleType: SaleTypeCode
  buyOrderStatus: BuyOrderStatusCode
  diagnosisStatus: DiagnosisStatusCode
  diagnosisType: DiagnosisTypeCode
  productName: string
  buyerId: number
  orderedAt: string
}

export type DiagnosisIssueListSearchParam = {
  status?: DiagnosisStatusCode[]
  buyOrderStatus?: BuyOrderStatusCode[]
  page?: number
  size?: number
}

export declare interface DiagnosisIssueHistoryType {
  id: number
  verifyNo: string
  verifyDate: string
  diagnosisId: number
  productId: number
  saleType: SaleTypeCode
  diagnosisType: DiagnosisTypeCode
  productName: string
  serialNo: string
  buyOrderId: string
  sellOrderId: string
  buyerId: number
  sellerId: number
  issuedByNickName: string
  issuedAt: string
}

export type DiagnosisIssueHistorySearchParam = {
  issuedAtStartDate?: string
  issuedAtEndDate?: string
  productId?: string
  diagnosisId?: string
  diagnosisVerifyNo?: string
  buyOrderId?: string
  buyerId?: number
  sellerId?: number
  issuedByNickName?: string
  serialNo?: string
  page?: number
  size?: number
}
