import React from "react"
import {
  DataGrid,
  GridAlignment,
  GridColTypeDef,
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from "@mui/x-data-grid"
import { TranslationKeyType } from "../../types/TranslationKeyType"
import { TranslationColumnManagerState } from "./index"
import { setManualTranslation } from "../../apis/translationAPI"
import { InputBase, InputBaseProps, Paper, Popper } from "@mui/material"

interface TranslationManagerTableProps {
  state: TranslationColumnManagerState
  dispatch: React.Dispatch<React.SetStateAction<TranslationColumnManagerState>>
}

const TranslationManagerTable: React.FC<TranslationManagerTableProps> = ({ state, dispatch }) => {
  // @ts-ignore
  const EditTextarea = (props: GridRenderEditCellParams<any, string>) => {
    const { id, field, value, colDef, hasFocus } = props
    const [valueState, setValueState] = React.useState(value)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>()
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)
    const apiRef = useGridApiContext()

    React.useLayoutEffect(() => {
      if (hasFocus && inputRef) {
        inputRef.focus()
      }
    }, [hasFocus, inputRef])

    const handleRef = React.useCallback((el: HTMLElement | null) => {
      setAnchorEl(el)
    }, [])

    const handleChange = React.useCallback<NonNullable<InputBaseProps["onChange"]>>(
      (event) => {
        const newValue = event.target.value
        setValueState(newValue)
        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event)
      },
      [apiRef, field, id]
    )

    return (
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={6}
                value={valueState}
                sx={{ textarea: { resize: "both" }, width: "100%" }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    )
  }

  const multilineColumn: GridColTypeDef = {
    type: "string",
    renderEditCell: (params) => <EditTextarea {...params} />,
  }

  const getColumns = () => {
    return [
      {
        field: "id",
        headerName: "ID",
        hide: true,
      },
      {
        field: "area1Name",
        headerName: "테이블",
        width: 150,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "area2Name",
        headerName: "컬럼",
        width: 150,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "area3Name",
        headerName: "키 값",
        width: 150,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "languageCode",
        headerName: "언어",
        width: 100,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "originValue",
        headerName: "내용",
        width: 400,
        flex: 1,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "i18nValue",
        headerName: "번역 내용",
        width: 400,
        flex: 1,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
        editable: true,
        ...multilineColumn,
      },
    ]
  }

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.i18nValue !== oldRow.i18nValue) {
      setManualTranslation(Number(newRow.id), {
        languageCode: newRow.languageCode,
        i18nValue: newRow.i18nValue,
      })
    }
    return newRow
  }

  return (
    <DataGrid<TranslationKeyType>
      experimentalFeatures={{ newEditingApi: true }}
      getRowId={(row) => row.id + "_" + row.languageCode}
      columns={getColumns()}
      //onCellEditCommit={handleCellEditCommit}
      processRowUpdate={processRowUpdate}
      getRowHeight={() => 70}
      pagination
      paginationMode={"server"}
      onSelectionModelChange={(ids: any) =>
        dispatch((prevState) => ({
          ...prevState,
          columnNameList: ids,
        }))
      }
      onPageChange={(page) => {
        dispatch((prevState) => ({
          ...prevState,
          page: page,
        }))
      }}
      onPageSizeChange={(size) => {
        dispatch((prevState) => ({ ...prevState, size: size }))
      }}
      style={{ width: "100%", height: "100%" }}
      page={state.page}
      pageSize={state.size}
      rowCount={state.tableDetailList.totalElements || 0}
      rows={state.tableDetailList.content}
    />
  )
}

export default TranslationManagerTable
