import { Button, Stack } from "@mui/material"

type Props = {
  handleCreate: () => void
  handleDeleteButton: () => void
  disabledDeleteButton: boolean
}

const SwipeBannerToolbar = ({
                           handleCreate,
                           handleDeleteButton,
                           disabledDeleteButton,
                         }: Props) => {
  return (
    <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
      <Button variant={"contained"} size={"small"} onClick={handleCreate}>
        추가
      </Button>
      <Button
        variant={"contained"}
        size={"small"}
        onClick={handleDeleteButton}
        color={"error"}
        disabled={disabledDeleteButton}
      >
        삭제
      </Button>
    </Stack>
  )
}

export default SwipeBannerToolbar
