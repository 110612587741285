import { DetailModelType } from "types/DetailModelType"
import { useState } from "react"
import { getDetailModels } from "apis/detailModelAPI"
import { SelectedModelType } from "../useSerialNumberRegister"

export const useModelSearchInput = () => {
  const [refNo, setRefNo] = useState<string>("")
  const [modelList, setModelList] = useState<SelectedModelType[]>([])

  const fetchDetailModels = (fullRefNo: String) => {
    return getDetailModels({ fullRefNo: fullRefNo, onDisplay: true, size: 500 })
  }

  const convertDetailModel = (model: DetailModelType): SelectedModelType => {
    return {
      label: `${model.title} ${model.titleSecondary} [${model.fullRefNo}]`,
      id: model.id?.toString() || "",
      brand: model.brand?.sid || "",
      title: model.title || "",
      titleSecondary: model.titleSecondary || "",
      image: model.thumbnail || "",
      refNo: model.fullRefNo || "",
    }
  }

  const changeRefNo = (fullRefNo: string) => {
    setRefNo(fullRefNo)
    fetchDetailModels(fullRefNo).then((res) => setModelList(res.content.map(convertDetailModel)))
  }

  return { modelList, refNo, changeRefNo }
}
