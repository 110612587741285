import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";

import React, {useEffect} from "react";
import {ShippingType} from "types/ShippingType";
import ShippingRequestTable from "./ShippingRequestTable";

type ShippingRequestPopupProps = {
    open: boolean,
    type: 'RETURN'|'NORMAL',
    selectedShipping: ShippingType[],
    handleClose: Function,
}

const ShippingRequestPopup: React.FC<ShippingRequestPopupProps> = ({open, type, selectedShipping, handleClose}) => {

    useEffect(() => {
        // @ts-ignore
        if (open) {
            // state.
        }
    }, [open])

    return <Dialog open={open} maxWidth={"xl"} fullWidth>
        <DialogTitle>상품 {type === 'RETURN' ? '픽업' : '배송'} 요청</DialogTitle>
        <DialogContent>
            <Grid container spacing={0}>
                <ShippingRequestTable type={type} shippings={selectedShipping} close={handleClose}/>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"error"} onClick={() => handleClose()}>닫기</Button>
        </DialogActions>
    </Dialog>
}

export default ShippingRequestPopup
