import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { toDateTimeStr } from "utils/datetimeUtil"
import styled from "@emotion/styled"
import { EventType } from "types/EventType"
import { eventTableHeaderItemList } from "../../TableHeader"
import { isNil } from "utils/validationUtils"
import { Button } from "@mui/material"
import { toast } from "react-toastify"

type Props = {
  event: EventType
  onClickRow: (eventId: number) => void
} & HTMLAttributes<HTMLDivElement>

const EventListTableRow = ({ event, onClickRow }: PropsWithChildren<Props>) => {
  return (
    <TableRow onClick={() => onClickRow(event.id)}>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[0].width }}>{event.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[1].width }}>
        <ThumnailImage src={event.bannerImageUrl} />
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[2].width }}>{event.title}</TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[3].width }}>{event.bodyType}</TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[4].width }}>
        <DisplayStatusText onDisplay={event.onDisplay}>{event.onDisplay ? "노출중" : "숨김"}</DisplayStatusText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[5].width }}>
        <DateTimeWrapper>{toDateTimeStr(event.notifiedAt)}</DateTimeWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[6].width }}>
        {isNil(event.startedAt) ? (
          "-"
        ) : (
          <ColumnWrapper>
            <DateTimeWrapper>{toDateTimeStr(event.startedAt)}</DateTimeWrapper>
            {event.endedAt && <DateTimeWrapper>{" ~ "}{toDateTimeStr(event.endedAt)}</DateTimeWrapper>}
          </ColumnWrapper>
        )}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: eventTableHeaderItemList[7].width }}>
        <Button
          onClick={async (e) => {
            e.stopPropagation()

            const url = `viver://mypage/event/${event.id}`
            if ("clipboard" in navigator) await navigator.clipboard.writeText(url)
            else document.execCommand("copy", true, url)
            toast.success("복사되었습니다.")
          }}
        >
          URL 복사
        </Button>
      </TableRowItem>
    </TableRow>
  )
}

export default EventListTableRow

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DateTimeWrapper = styled.div`
  max-width: 180px;
`

const ThumnailImage = styled.img`
  width: 100%;
  max-height: 60px;
  object-fit: cover;
`

const DisplayStatusText = styled.div<{ onDisplay: boolean }>`
  font-weight: 600;
  color: ${({ onDisplay }) => (onDisplay ? "#1976d2" : "rgba(0,0,0,0.5)")};
`
