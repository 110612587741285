import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {DatePicker} from "@mui/lab";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {SearchOutlined} from "@mui/icons-material";
import SearchDetailModelPopup from "../../../components/Popup/SearchDetailModelPopup";
import {DetailModelType} from "types/DetailModelType";
import {getBrands} from "../../../apis/brandAPI";
import {BrandType} from "types/BrandType";

// @ts-ignore
const Filter = ({searchParams, handleChangeSearchParams}) => {
    const [brandList, setBrandList] = useState<BrandType[]>([])

    useEffect(() => {
        getBrands().then(brandList => setBrandList(brandList))
    }, [])

    function DateRangePicker() {
        return (
                <Stack direction={"row"} justifyContent={'flex-end'} spacing={1}>
                    <DatePicker
                        label="시작 날짜"
                        value={searchParams.metricsDateGoe ? new Date(searchParams.metricsDateGoe) : new Date()}
                        onChange={(newValue) => {
                            // @ts-ignore
                            const loe = DateTime.fromISO(searchParams.metricsDateLoe) < newValue ? newValue.toISODate() : searchParams.metricsDateLoe
                            // @ts-ignore
                            handleChangeSearchParams({metricsDateGoe: newValue.toISODate(), metricsDateLoe: loe})
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="끝 날짜"
                        value={searchParams.metricsDateLoe ? new Date(searchParams.metricsDateLoe) : new Date()}
                        onChange={(newValue) => {
                            // @ts-ignore
                            const goe = DateTime.fromISO(searchParams.metricsDateGoe) > newValue ? newValue.toISODate() : searchParams.metricsDateGoe
                            // @ts-ignore
                            handleChangeSearchParams({metricsDateGoe: goe, metricsDateLoe: newValue.toISODate()})
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
        )
    }

    function ProductConditionSelector() {
        return (
            <FormControl fullWidth>
                <InputLabel>상태</InputLabel>
                <Select
                    defaultValue={searchParams.productCondition ?? 'ALL'}
                    onChange={(e) => handleChangeSearchParams({productCondition: e.target.value == 'ALL' ? null : e.target.value}) }
                >
                    <MenuItem value={'ALL'}>전체</MenuItem>
                    <MenuItem value={'중고'}>중고</MenuItem>
                    <MenuItem value={'미착용'}>미착용</MenuItem>
                </Select>
            </FormControl>)
    }

    function BrandSelector() {
        return (
            <FormControl fullWidth>
                <InputLabel>브랜드</InputLabel>
                <Select
                    defaultValue={searchParams.brandSid ?? 'ALL'}
                    onChange={(e) => handleChangeSearchParams({brandSid: e.target.value == 'ALL' ? null : e.target.value})}
                >

                    <MenuItem value={'ALL'}>전체</MenuItem>
                    {brandList.map(brand => (<MenuItem value={brand.sid}>{brand.name}</MenuItem>) )}
                </Select>
            </FormControl>
        )
    }

    const [detailModel, setDetailModel] = useState<DetailModelType|null>(null)
    const [showDetailModelPopup, setShowDetailModelPopup] = useState(false)
    function DetailModelSelector() {

        function handleClose(detailModel: DetailModelType) {
            setDetailModel(detailModel)
            handleChangeSearchParams({detailModelId: detailModel?.id})
            setShowDetailModelPopup(false)
        }

        return (
            <>
                <TextField
                    fullWidth
                    id="standard-bare"
                    variant="outlined"
                    label={"상세 모델"}
                    value={`${detailModel?.title??''}` }
                    onClick={() => setShowDetailModelPopup(true)}
                    InputProps={{endAdornment: (<IconButton onClick={() => {setShowDetailModelPopup(true)}}><SearchOutlined /></IconButton>),}}
                />
                <SearchDetailModelPopup open={showDetailModelPopup} handleClose={handleClose} />
            </>
        )
    }

    function RefNoSearchField() {
        return (
                <TextField
                    fullWidth
                    size="small"
                    id="standard-bare"
                    variant="outlined"
                    placeholder={"레퍼런스 넘버 입력"}
                    label={"레퍼런스 넘버"}
                    value={searchParams.refNo}
                    onChange={(e) => handleChangeSearchParams({refNo: e.target.value})}
                />
        )
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={3}> <BrandSelector /> </Grid>
            <Grid item xs={3}> <ProductConditionSelector /> </Grid>
            <Grid item xs={3}> <DetailModelSelector /> </Grid>
            <Grid item xs={3}> <DateRangePicker /> </Grid>
        </Grid>)
}

export default Filter