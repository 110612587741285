import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack} from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts'
import AskingPriceDetailTable from "./AskingPriceDetailTable";
import DateRangeFilter from "./DateRangeFilter";
import {useEffect, useState} from "react";
import {getAskingPriceList, updateAskingPrice, aggregateAskingPrice} from "../../../../apis/askingPriceAPI";
import {DateTime} from "luxon";
import _ from "lodash";
import {AskingPriceType} from "types/AskingPriceType";
import SimpleResellItemTable from "./SimpleResellItemTable";
import {getResellItemList, updateResellItem} from "../../../../apis/resellItemAPI";
import {LoadingButton} from "@mui/lab";

const initChartOptions: any = {
    chart: {
        height: '200px'
    },
    xAxis: {
        type: 'datetime'
    },
    title: {
        text: ''
    },
    series: []
}

const initSearchParams = {
    metricsDateGoe: DateTime.local().minus({days: 30}).toISODate(),
    metricsDateLoe: DateTime.local().minus({days: 2}).toISODate(),
    productCondition: '중고',
    detailModelId: null
}


// @ts-ignore
const AskingPriceEditorPopup = ({editTarget, handleClose}) => {
    const [chartOptions, setChartOptions] = useState(initChartOptions)
    const [askingPriceList, setAskingPriceList] = useState<AskingPriceType[]>([])
    const [searchParams, setSearchParams] = useState(initSearchParams)
    const [selectionAskingPriceMetrics, setSelectionAskingPriceMetrics] = useState(null)
    const [resellItems, setResellItems] = useState([])
    const [aggregateLoading, setAggregateLoading] = useState(false)

    function changeChartOptions(title: string, series: any[]) {
        setChartOptions({...chartOptions.chartOptions, xAxis: { type: 'datetime' }, title: {text: title}, series: series})
    }

    function fetchAskingPriceDailyMetrics() {
        if (searchParams.detailModelId) {
            getAskingPriceList(searchParams).then((result) => setAskingPriceList(result))
        }
    }

    function handleRowUpdate(newRow: any, oldRow: any) {
        if (!_.isEqual(newRow, oldRow)) {
            const changed = [
                newRow.maxAskingPrice != oldRow.maxAskingPrice ? 'maxAskingPrice' : null,
                newRow.minAskingPrice != oldRow.minAskingPrice ? 'minAskingPrice' : null,
                newRow.avgAskingPrice != oldRow.avgAskingPrice ? 'avgAskingPrice' : null,
            ]
            updateAskingPrice({
                ...oldRow,
                maxAskingPrice: newRow.maxAskingPrice != oldRow.maxAskingPrice ? newRow.maxAskingPrice : oldRow.maxAskingPrice,
                minAskingPrice: newRow.minAskingPrice != oldRow.minAskingPrice ? newRow.minAskingPrice : oldRow.minAskingPrice,
                avgAskingPrice: newRow.avgAskingPrice != oldRow.avgAskingPrice ? newRow.avgAskingPrice : oldRow.avgAskingPrice,
                changed: changed.filter((str) => str)
            }).then(() => {
                fetchAskingPriceDailyMetrics()
            })
        }

        newRow =  {
            ...newRow,
            manualMaxAskingPrice: newRow.maxAskingPrice !=  oldRow.maxAskingPrice ? newRow.maxAskingPrice : oldRow.manualMaxAskingPrice,
            manualMinAskingPrice: newRow.minAskingPrice !=  oldRow.minAskingPrice ? newRow.minAskingPrice : oldRow.manualMinAskingPrice,
            manualAvgAskingPrice: newRow.avgAskingPrice !=  oldRow.avgAskingPrice ? newRow.avgAskingPrice : oldRow.manualAvgAskingPrice,
        }

        return newRow
    }

    function handleRowsUpdate(rows: any[]) {
        Promise.all(
            rows.map(row => updateAskingPrice(row))
        ).then(results => fetchAskingPriceDailyMetrics())
    }

    function handleResellItemChange(row: any) {
        updateResellItem(row.id, row).then(() => {})
    }

    function aggregate() {
        setAggregateLoading(true)
        aggregateAskingPrice({
            // @ts-ignore
            detailModelId: selectionAskingPriceMetrics?.detailModel?.id,
            // @ts-ignore
            startDate: selectionAskingPriceMetrics?.metricsDate,
            // @ts-ignore
            endDate: selectionAskingPriceMetrics?.metricsDate,
        }).then(() => {
            fetchAskingPriceDailyMetrics()
            setAggregateLoading(false)
        })
    }

    useEffect(() => {
        if (editTarget) { setSearchParams({...initSearchParams, ...editTarget, detailModel: {title: editTarget?.title}}) }
        else {
            setSearchParams({...initSearchParams})
            setAskingPriceList([])
            setSelectionAskingPriceMetrics(null)
            setResellItems([])
            setAggregateLoading(false)
        }
    }, [editTarget])

    useEffect(() => {
        const avg = {
            name: '평균',
            data: askingPriceList?.map((metrics) => [DateTime.fromSQL(metrics.metricsDate, {zone: 'UTC'}).toMillis(), metrics.manualAvgAskingPrice ?? metrics.avgAskingPrice]) ?? [],
        }

        const range = {
            name: '시세범위',
            data: askingPriceList?.map((metrics) => [DateTime.fromSQL(metrics.metricsDate, {zone: 'UTC'}).toMillis(), metrics.manualMinAskingPrice ?? metrics.minAskingPrice, metrics.manualMaxAskingPrice ?? metrics.maxAskingPrice]) ?? [],
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false
            }
        }
        changeChartOptions('', [avg, range])
    }, [askingPriceList])

    useEffect(() => {
        if (searchParams?.detailModelId) {
            fetchAskingPriceDailyMetrics()
        }
    }, [searchParams])

    useEffect(() => {
        if(selectionAskingPriceMetrics) {
            // @ts-ignore
            getResellItemList({
                // @ts-ignore
                detailModelId: selectionAskingPriceMetrics?.detailModel?.id,
                // @ts-ignore
                registerDateLoe: selectionAskingPriceMetrics.metricsDate,
                // @ts-ignore
                registerDateGoe: selectionAskingPriceMetrics.metricsDate,
                // @ts-ignore
                itemStatus: selectionAskingPriceMetrics.productCondition == '미착용' ? 'NEW' : 'USED'
            }).then((results) => {
                // @ts-ignore
                setResellItems(results)
            })
        } else {
            setResellItems([])
        }
    }, [selectionAskingPriceMetrics])


    return(
        <Dialog open={!!editTarget?.detailModelId} maxWidth={'lg'} fullWidth>
            <DialogTitle>시세 편집</DialogTitle>
            <DialogContent>
                <Stack direction={'column'} spacing={2}>
                    <Box sx={{marginTop: '10px'}}>
                        <DateRangeFilter
                            searchParams={searchParams}
                            handleChangeSearchParams={(newParams: any) => {setSearchParams({...searchParams, ...newParams})}}
                        />
                    </Box>
                    <HighchartsReact
                        containerProps={{ style: {height: '200px'} }}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AskingPriceDetailTable
                                rows={askingPriceList ?? []}
                                handleRowUpdate={handleRowUpdate}
                                handleRowsUpdate={handleRowsUpdate}
                                handleRowSelection={(row: any) => setSelectionAskingPriceMetrics(row)}
                                isLoading={false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <Stack justifyContent={'flex-end'} direction={'row'}>
                                    <LoadingButton disabled={selectionAskingPriceMetrics == null} loading={aggregateLoading} variant={'contained'} color={'primary'} size={'small'} onClick={() => aggregate()}>재집계</LoadingButton>
                                </Stack>
                                <SimpleResellItemTable
                                    rows={resellItems}
                                    handleRowsUpdate={handleResellItemChange}
                                    handleRowSelection={() => {}}
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'outlined'} onClick={handleClose}>닫기</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AskingPriceEditorPopup