import { httpPostMultipartFile } from "../utils/httpAPI"

export const commonResourceUpload: (
  file: FileList,
  options?: [{ key: string; value: any }]
) => Promise<{
  id: number
  url: string
  resourceContentType: "IMG"
  associatedBy?: string
}> = (file, options) => httpPostMultipartFile("/resources", file, options)

export const commonResourceUploadFile: (
  file: File,
  options?: [{ key: string; value: any }]
) => Promise<{
  id: number
  url: string
  resourceContentType: "IMG"
  associatedBy?: string
}> = (file, options) => httpPostMultipartFile("/resources", [file], options)
