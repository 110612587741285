import styled from "@emotion/styled"
import { UserBuyOrderType, UserSellOrderType } from "types/UserType"
import DetailTableBody from "components/Table/Detail/TableBody"
import DetailTableRowCell from "components/Table/Detail/TableBody/TableCell/Row"
import {
  FINISHED_BUY_ORDER_STATUS_LIST,
  FINISHED_SELL_ORDER_STATUS_LIST,
  ON_GOING_BUY_ORDER_STATUS_LIST,
  ON_GOING_SELL_ORDER_STATUS_LIST,
  UserOrderList,
} from "pages/User/UserDetailPopupPage"
import { OrderType } from "types/SellOrderType"

type Props = {
  userBuyOrderList: UserOrderList<UserBuyOrderType>
  userSellOrderList: UserOrderList<UserSellOrderType>
  userId: number
}

const UserTransactionTable = ({ userBuyOrderList, userSellOrderList, userId }: Props) => {
  const filterOnGoingSellOrderByOrderType = (list: UserSellOrderType[], orderType: OrderType) => {
    return list.filter((item) => item.sellOrderType === orderType)
  }

  return (
    <DetailTableBody>
      <TitleWrapper>회원 거래 정보</TitleWrapper>
      <RowWrapper>
        <SubHeaderCellWrapper>
          <SubHeader>구매</SubHeader>
          <ColumnWrapper>
            <DetailTableRowCell
              style={{ borderBottom: "1px solid #ccc", height: "55px" }}
              titleWidth="150px"
              title="진행중"
            >
              <Link
                href={`/buy-orders?buyerId=${userId}&status=${ON_GOING_BUY_ORDER_STATUS_LIST.join(",")}`}
                target={"_blank"}
              >
                {userBuyOrderList.onGoingList.length}건
              </Link>
            </DetailTableRowCell>
            <DetailTableRowCell style={{ height: "55px" }} titleWidth="150px" title="구매종료">
              <Link
                href={`/buy-orders?buyerId=${userId}&status=${FINISHED_BUY_ORDER_STATUS_LIST.join(",")}`}
                target={"_blank"}
              >
                {userBuyOrderList.finishedList.length}건
              </Link>
            </DetailTableRowCell>
          </ColumnWrapper>
        </SubHeaderCellWrapper>

        <SubHeaderCellWrapper>
          <SubHeader>판매</SubHeader>
          <ColumnWrapper>
            <DetailTableRowCell style={{ borderBottom: "1px solid #ccc" }} titleWidth="150px" title="판매중">
              <ColumnWrapper>
                <Link
                  href={`${
                    window.location.origin
                  }/sell-orders/consignment?sellerId=${userId}&status=${ON_GOING_SELL_ORDER_STATUS_LIST.join(",")}`}
                  target={"_blank"}
                >
                  위탁 {filterOnGoingSellOrderByOrderType(userSellOrderList.onGoingList, OrderType.위탁).length}건
                </Link>
                <Link
                  href={`${
                    window.location.origin
                  }/sell-orders/before-diagnosis?sellerId=${userId}&status=${ON_GOING_SELL_ORDER_STATUS_LIST.join(
                    ","
                  )}`}
                  target={"_blank"}
                >
                  진단전 {filterOnGoingSellOrderByOrderType(userSellOrderList.onGoingList, OrderType.진단전).length}건
                </Link>
              </ColumnWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth="150px" title="판매종료">
              <Link
                href={`${
                  window.location.origin
                }/products?sellerId=${userId}&status=${FINISHED_SELL_ORDER_STATUS_LIST.join(",")}`}
                target={"_blank"}
              >
                {userSellOrderList.finishedList.length}건
              </Link>
            </DetailTableRowCell>
          </ColumnWrapper>
        </SubHeaderCellWrapper>
      </RowWrapper>
    </DetailTableBody>
  )
}

export default UserTransactionTable

const TitleWrapper = styled.div`
  padding: 10px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  font-weight: 800;
`

const SubHeaderCellWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 110px;
  padding: 10px;
  background: #eee;

  font-weight: 700;
  font-size: 1rem;
  border-right: 1px solid #ccc;
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Link = styled.a`
  flex: 1;
  text-decoration: underline;
  font-weight: 600;
  font-size: 0.825rem;
  text-align: center;
  cursor: pointer;
  color: #000;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`
