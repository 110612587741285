import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import {
  Accessories,
  AccessoriesConditionCode,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  ProductConditionCode,
} from "types/DiagnosisType"
import { BrandType } from "../../../types/BrandType"

type Props = {
  serialNo: string
  accessories?: Accessories
  brand: BrandType
  stampingDate: string
  productCondition?: ProductConditionCode
  originalBoxCondition?: OriginalBoxConditionCode
  officialGuarantee?: OfficialGuaranteeCode
  accessoriesCondition?: AccessoriesConditionCode
  note?: string
}

const PreviousProductCondition = ({
  serialNo,
  brand,
  accessories,
  stampingDate,
  productCondition,
  officialGuarantee,
  accessoriesCondition,
  originalBoxCondition,
}: Props) => {
  return (
    <table className={"basic"}>
      <tbody>
        <tr>
          <td className={"header"} colSpan={1} width={"15%"}>
            시리얼 넘버
          </td>
          <td className={"header"} width={"15%"}>
            제품상태
          </td>
          <td className={"header"} width={"15%"}>
            보증서
          </td>
          <td className={"header"} width={"15%"}>
            정품박스
          </td>
          <td className={"header"} width={"25%"}>
            부속품
          </td>
          <td className={"header"}>스탬핑 년도</td>
        </tr>
        <tr>
          <td colSpan={1}>
            <TextField variant="standard" type={"text"} required error={!serialNo} value={serialNo} />
          </td>
          <td colSpan={1}>
            <RadioGroup row sx={{ justifyContent: "center" }}>
              {[ProductConditionCode.미착용, ProductConditionCode.중고].map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={value}
                  checked={productCondition === value}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </td>
          <td colSpan={1}>
            <RadioGroup row sx={{ justifyContent: "center" }}>
              {[OfficialGuaranteeCode.있음, OfficialGuaranteeCode.없음].map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={value}
                  checked={officialGuarantee === value}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </td>
          <td colSpan={1}>
            <RadioGroup row sx={{ justifyContent: "center" }}>
              {[OriginalBoxConditionCode.있음, OriginalBoxConditionCode.없음].map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={value}
                  checked={originalBoxCondition === value}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </td>

          <td colSpan={1}>
            <RadioGroup row sx={{ justifyContent: "center" }}>
              {[AccessoriesConditionCode.있음, AccessoriesConditionCode.없음, AccessoriesConditionCode.일부파손].map(
                (value) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    label={value}
                    checked={accessoriesCondition === value}
                    control={<Radio />}
                  />
                )
              )}
            </RadioGroup>
          </td>

          <td colSpan={1}>{stampingDate}</td>
        </tr>
        <tr>
          <td className={"header"} colSpan={4}>
            상세 부속품 체크리스트
            <br />
          </td>
          <td className={"header"} colSpan={2}>
            특이사항
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            {/*<div style={{ width: "400px", margin: "10px auto" }}>*/}
            {brand.accessories?.map(({ value, key }) => (
              <FormControlLabel key={value} label={value} checked={!!accessories?.[key]} control={<Checkbox />} />
            ))}
            {/*</div>*/}
          </td>
          <td colSpan={2}>
            <TextField variant="standard" type={"text"} fullWidth multiline value={accessories?.note || ""} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default PreviousProductCondition
