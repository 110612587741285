import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material"
import React, {useEffect, useState} from "react"
import "../../App.css"
import {BuyOrderType} from "types/BuyOrderType"
import {approveRefund, getBuyOrder, rejectRefund, requestRefund} from "../../apis/buyOrderAPI"
import {toDateTimeStr} from "../../utils/datetimeUtil"
import {numberWithCommas} from "../../utils/NumberUtils"
import {ShippingRequestType} from "types/ShippingType"
import {CurrencyCode, SaleTypeCode} from "types/ProductType"
import {requestShipping} from "../../apis/shippingAPI"
import {UserDetailType, UserMemoTargetTypeCode, UserType} from "../../types/UserType"
import QuickMemoButton from "../../components/QuickMemo/QuickMemoButton"
import {getUserDetail} from "../../apis/userAPI"
import {toast} from "react-toastify"
import {SerialNumberView} from "components/SerialNumber/SerialNumberView"

type EditBuyOrderPopupProps = {
  open: boolean
  selectedBuyOrder: BuyOrderType | undefined
  handleClose: Function
}

const ViewBuyOrderPopup: React.FC<EditBuyOrderPopupProps> = ({ open, selectedBuyOrder, handleClose }) => {
  const [buyOrder, setBuyOrder] = useState<BuyOrderType>(selectedBuyOrder || {})
  const [buyer, setBuyer] = useState<UserType | UserDetailType | undefined>(selectedBuyOrder?.buyer)
  const [unmask, setUnmask] = useState<boolean>(false)

  const handleRequestShipping = (manually: boolean = false) => {
    if (window.confirm(`구매주문번호 [${buyOrder.id}]의 배송을 요청합니다.`)) {
      const req: ShippingRequestType = {
        items: [{ id: buyOrder?.shipping?.id!! }],
        manually: manually,
      }

      requestShipping(req).then((res) => {
        let msg = `* 성공 ${res.succeed?.length || 0}건\n`
        msg += res.succeed?.map((it) => `${it.id}`)?.join("\n") || ""
        msg += "\n"
        msg += `* 실패 ${res.failure?.length || 0}건\n`
        msg += res.failure?.map((it) => `${it.id} (${it.code}: ${it.message})`)?.join("\n") || ""
        alert(msg)
        handleClose()
      })
    }
  }

  useEffect(() => {
    setUnmask(false)
    if (open) {
      getBuyOrder(selectedBuyOrder?.id!).then((order) => {
        setBuyOrder(order)
      })
    } else {
      setBuyOrder({})
    }
  }, [open])

  useEffect(() => {
    setBuyer(buyOrder?.buyer)
  }, [buyOrder])

  useEffect(() => {
    if (buyer?.id)
      getUserDetail("" + buyer?.id, unmask)
        .then((user) => setBuyer(user))
        .catch((err) => {
          toast.error("회원 정보 조회에 실패했습니다.")
          console.log("Error", err)
        })
  }, [unmask])

  const handleRequestRefund = () => {
    requestRefund(buyOrder.id!).then((res) => {
      alert(`${buyOrder?.id} 반품 요청 성공`)
      setBuyOrder(res)
    })
  }

  const handleApproveRefund = () => {
    approveRefund(buyOrder.id!).then((res) => {
      alert(`${buyOrder?.id} 반품 승인 성공`)
      setBuyOrder(res)
    })
  }

  const handleRejectRefund = () => {
    rejectRefund(buyOrder.id!).then((res) => {
      alert(`${buyOrder?.id} 반품 거절 성공`)
      setBuyOrder(res)
    })
  }

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td colSpan={3} className={"no-border ta-left"}>
                <Typography fontWeight={"bold"} fontSize={"30px"}>
                  구매신청 상세정보
                </Typography>
              </td>
              <td className={"header"}>배송상태</td>
              <td className={"header"}>주문상태</td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"}></td>
              <td>{buyOrder.shipping?.shippingStatus}</td>
              <td>{buyOrder.buyOrderStatus}</td>
            </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        <h3>구매 주문 정보</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"no-border"} colSpan={6} style={{ textAlign: "end" }}>
                <FormControlLabel
                  style={{ color: "#ed3030" }}
                  control={<Switch color="warning" value={unmask} />}
                  label="개인정보 마스킹 해제"
                  checked={unmask}
                  onChange={(event, checked) => {
                    if (checked) {
                      checked = window.confirm(
                        "업무상 고객의 개인정보를 조회합니다." +
                          "\n\n* 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 경우 " +
                          "\n5년 이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다."
                      )
                    }
                    setUnmask(checked)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className={"header"}>상품번호</td>
              <td className={"header"}>구매주문번호</td>
              <td className={"header"}>주문일시</td>
              <td className={"header"}>결제일시</td>
              <td className={"header"}>판매방식</td>
              <td className={"header"}>매입보증</td>
            </tr>
            <tr>
              <td>
                {buyOrder.product?.id}
                <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={buyOrder?.product?.id} small />
              </td>
              <td>{buyOrder.id}</td>
              <td>{toDateTimeStr(buyOrder.orderedAt)}</td>
              <td>{toDateTimeStr(buyOrder.payment?.transactionAt)}</td>
              <td>{buyOrder.product?.saleType}</td>
              <td>
                {buyOrder.product?.repurchaseGuaranteePeriod
                  ? `${buyOrder.product?.repurchaseGuaranteePeriod}일 재매입 보증 (${(
                      buyOrder.product?.repurchaseGuaranteeRate! * 100
                    ).toFixed(0)}%)`
                  : "해당없음"}
              </td>
            </tr>
            <tr>
              <td className={"header"}>구매자</td>
              <td className={"header"}>연락처</td>
              <td className={"header"} colSpan={4}>
                바이버 계정
              </td>
            </tr>
            <tr>
              <td>
                {buyer?.name}
                <QuickMemoButton target={UserMemoTargetTypeCode.USER} targetId={buyer?.id} small />
              </td>
              <td>{buyer?.phone}</td>
              <td colSpan={4}>{buyer?.email}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={3}>
                상품명
              </td>
              <td className={"header"} colSpan={2}>
                Reference No.
              </td>
              <td className={"header"}>시리얼 넘버</td>
            </tr>
            <tr>
              <td colSpan={1}>{buyOrder.product?.brandSid}</td>
              <td colSpan={2}>
                {buyOrder.product?.title} {buyOrder.product?.titleSecondary}
              </td>
              <td colSpan={2}>
                {buyOrder.product?.detailModel?.refNo} / {buyOrder.product?.detailModel?.fullRefNo}
              </td>
              <td>
                {buyOrder.product?.serialNo ? <SerialNumberView serialNo={buyOrder.product.serialNo} /> : "미기입"}
              </td>
            </tr>
            <tr>
              <td className={"header"}>구매가</td>
              <td className={"header"}>서비스 수수료</td>
              <td className={"header"}>배송비</td>
              <td className={"header"}>사용 포인트</td>
              <td className={"header"}>총 할인 금액</td>
              <td className={"header"}>총 결제 금액</td>
            </tr>
            <tr>
              <td>
                {buyOrder.product?.currency === CurrencyCode.KR ? numberWithCommas(buyOrder.product?.price) + '원' : buyOrder.product?.currency === CurrencyCode.US ? buyOrder.product?.currency + ' $' + numberWithCommas(buyOrder.product?.price) : buyOrder.product?.currency + ' ' + numberWithCommas(buyOrder.product?.price)}
                <br/>
                {buyOrder.currency != 'KRW' ? (buyOrder.currency === 'USD' ? buyOrder.currency + ' $' + numberWithCommas(buyOrder.productPrice) : buyOrder.currency + ' ' + numberWithCommas(buyOrder.productPrice)) : ''}
              </td>
              <td>{buyOrder.currency === 'KRW' ? (numberWithCommas((buyOrder.buyingFee || 0) - (buyOrder.buyingFeeDiscountAmount || 0))) + '원' : buyOrder.currency === 'USD' ? buyOrder.currency + ' $' + (numberWithCommas((buyOrder.buyingFee || 0) - (buyOrder.buyingFeeDiscountAmount || 0))) : buyOrder.currency + ' ' + (numberWithCommas((buyOrder.buyingFee || 0) - (buyOrder.buyingFeeDiscountAmount || 0)))}</td>
              <td>{buyOrder.currency === 'KRW' ? (numberWithCommas((buyOrder.shippingFee || 0) - (buyOrder.shippingFeeDiscountAmount || 0))) || "-" + '원' : buyOrder.currency === 'USD' ? buyOrder.currency + ' $' + (numberWithCommas((buyOrder.shippingFee || 0) - (buyOrder.shippingFeeDiscountAmount || 0))) : buyOrder.currency + ' ' + (numberWithCommas((buyOrder.shippingFee || 0) - (buyOrder.shippingFeeDiscountAmount || 0)))}</td>
              <td>{buyOrder.currency === 'KRW' ? numberWithCommas(buyOrder.pointAmount) || "-" + '원' : buyOrder.currency === 'USD' ? buyOrder.currency + ' $' + numberWithCommas(buyOrder.pointAmount) : buyOrder.currency + ' ' + numberWithCommas(buyOrder.pointAmount)}</td>
              <td>{buyOrder.currency === 'KRW' ? numberWithCommas(buyOrder.discountAmount) || "-" + '원' : buyOrder.currency === 'USD' ? buyOrder.currency + ' $' + numberWithCommas(buyOrder.discountAmount) : buyOrder.currency + ' ' + numberWithCommas(buyOrder.discountAmount)}</td>
              <td className={"bold"}>{buyOrder.currency === 'KRW' ? numberWithCommas(buyOrder.paymentAmount) + '원' : buyOrder.currency === 'USD' ? buyOrder.currency + ' $' + numberWithCommas(buyOrder.paymentAmount) : buyOrder.currency + ' ' + numberWithCommas(buyOrder.paymentAmount)}</td>
            </tr>
          </tbody>
        </table>

        <h3>결제정보</h3>
        <table className={"basic"}>
          <tbody>
          <tr>
            <td className={"header"}>결제자</td>
            <td className={"header"} colSpan={4}>
              결제수단
            </td>
            <td className={"header"}>결제방법</td>
            <td className={"header"}>결제완료일시</td>
            <td className={"header"}>결제취소일시</td>
              <td className={"header"}>결제상태</td>
            </tr>
            <tr>
              <td>{buyOrder.payment?.userName}</td>
              <td>{buyOrder.payment?.channelType}</td>
              <td>{buyOrder.payment?.paymentMethodType}</td>
              <td colSpan={2}>
                {buyOrder.payment?.paymentMethodType == "가상계좌"
                  ? `${buyOrder.payment?.bankName} ${buyOrder.payment?.accountNumber}`
                  : `${buyOrder.payment?.cardCompanyCode} ${buyOrder.payment?.cardNo}`}
              </td>
              <td>{buyOrder.payment?.installMonth ? `${buyOrder.payment?.installMonth}개월` : "일시불"}</td>
              <td>{toDateTimeStr(buyOrder.payment?.transactionAt)}</td>
              <td>{toDateTimeStr(buyOrder.payment?.canceledAt)}</td>
              <td>{buyOrder.payment?.status}</td>
            </tr>
          </tbody>
        </table>

        <h3>배송정보</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>배송상태</td>
              <td className={"header"}>운송장번호</td>
              <td className={"header"}>배송요청일시</td>
              <td className={"header"}>배송시작일시</td>
              <td className={"header"}>배송완료일시</td>
            </tr>
            <tr>
              <td>{buyOrder.shipping?.shippingStatus}</td>
              <td>{buyOrder.shipping?.trackingNo || "-"}</td>
              <td>{toDateTimeStr(buyOrder.shipping?.waitingAt)}</td>
              <td>{toDateTimeStr(buyOrder.shipping?.startedAt)}</td>
              <td>{toDateTimeStr(buyOrder.shipping?.completedAt)}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={5}>
                배송현황
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                {buyOrder.shipping?.journey?.map((it) => `[${it.when}] ${it.where} - ${it.how} `).join("<br/>")}
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button
          size={"medium"}
          variant={"contained"}
          onClick={() => handleRequestRefund()}
          disabled={buyOrder?.buyOrderStatus != "구매 완료" || buyOrder.product?.saleType != SaleTypeCode.직접판매}
        >
          반품 요청
        </Button>
        <Button
          size={"medium"}
          variant={"contained"}
          onClick={() => handleApproveRefund()}
          disabled={buyOrder?.buyOrderStatus != "반품 요청 완료" || buyOrder.product?.saleType != SaleTypeCode.직접판매}
        >
          반품 승인
        </Button>
        <Button
          size={"medium"}
          variant={"contained"}
          onClick={() => handleRejectRefund()}
          disabled={buyOrder?.buyOrderStatus != "반품 요청 완료" || buyOrder.product?.saleType != SaleTypeCode.직접판매}
        >
          반품 거절
        </Button>

        <Button
          size={"medium"}
          disabled={buyOrder.shipping?.shippingStatus != "배송 준비"}
          variant={"contained"}
          onClick={() => handleRequestShipping(true)}
        >
          직접 배송요청
        </Button>
        {/*
        <Button
          size={"medium"}
          disabled={buyOrder.shipping?.shippingStatus != "배송 준비"}
          variant={"contained"}
          onClick={() => handleRequestShipping()}
        >
          VALEX 배송요청
        </Button>
*/}
        <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewBuyOrderPopup
