import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField} from "@mui/material"
import {DataGrid} from "@mui/x-data-grid"
import {getDetailModels} from "apis/detailModelAPI"
import React, {useEffect, useMemo, useState} from "react"
import {ProductType} from "types/ProductType"
import {currencyFormat} from "utils/NumberUtils"
import {DetailModelType} from "../../types/DetailModelType";
import {PageType} from "../../types/PageType";
import row from "../Table/Detail/TableBody/TableCell/Row";
import _ from 'lodash'

type SearchParams = {
    page: number,
    size: number,
    onDisplay: boolean,
    containsOnSaleProduct: boolean,
    searchText: string | null

}
const DetailModelSelectPopup: React.FunctionComponent<{
    open: boolean
    handleClose: (detailModelIds: DetailModelType[]) => void
    isMultiSelectable?: boolean
}> = ({open, handleClose, isMultiSelectable = true}) => {
    const columns = useMemo(
        () => [
            {field: "id", headerName: "ID", width: 70},
            {
                field: "thumbnail",
                headerName: "썸네일",
                width: 100,
                renderCell: (params: any) => (
                    <Box>
                        <img style={{width: "100%"}} src={params.row.thumbnail} alt={""}/>
                    </Box>
                ),
            },
            {field: "fullRefNo", headerName: "레퍼런스", width: 160},
            {field: "title", headerName: "상세 모델명", flex: 1},
            {field: "titleSecondary", headerName: "상세 모델명", flex: 1},
            {field: "onSaleProductCount", headerName: "판매 상품수", type: 'number', width: 100},
        ],
        []
    )
    const pageSize = 100
    const [searchParams, setSearchParams] = useState<SearchParams>({
        page: 0,
        size: 100,
        onDisplay: true,
        containsOnSaleProduct: true,
        searchText: null
    })
    const [tempSearchText, setTempSearchText] = useState<string|null>(null)
    const [detailModelPage, setDetailModelPage] = useState<PageType<DetailModelType>>({content: []})
    const [selectionDetailModelIds, setSelectionDetailModelIds] = useState<DetailModelType["id"][]>([])

    useEffect(() => {
        if (open) {
            fetchDetailModels()
        }
    }, [open, searchParams])

    const fetchDetailModels = () => {
        getDetailModels({...searchParams}).then((result) => setDetailModelPage(result))
    }

    return (
        <Dialog open={open} maxWidth={"lg"}>
            <DialogTitle>상세 모델 선택</DialogTitle>
            <DialogContent style={{width: "1100px"}}>
                <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                        <TextField placeholder={'레퍼런스, 상세 모델명 검색어 입력'} value={tempSearchText} onChange={e => setTempSearchText(e.target.value as string)} fullWidth size={'small'}/>
                        <Button variant={'contained'} onClick={() => setSearchParams({...searchParams, searchText: tempSearchText})}>검색</Button>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                        <FormControlLabel label={'노출중인 상세 모델만 보기'} control={<Checkbox checked={searchParams.onDisplay} onChange={(e) => setSearchParams({...searchParams, onDisplay: e.target.checked})}/>}/>
                        <FormControlLabel label={'판매 상품 있는 상세 모델만 보기'} control={<Checkbox checked={searchParams.containsOnSaleProduct} onChange={e => setSearchParams({...searchParams, containsOnSaleProduct: e.target.checked})}/>}/>
                    </Stack>
                    <Box height={"500px"}>
                        <DataGrid
                            pagination
                            paginationMode="server"
                            pageSize={pageSize}
                            onPageChange={(newPage) => {
                                setSearchParams({...searchParams, page: newPage})
                            }}
                            onPageSizeChange={(newPageSize) => {
                                setSearchParams({...searchParams, page: 0, size: newPageSize})
                            }}
                            rowCount={detailModelPage?.totalElements || 0}

                            style={{width: "100%", height: "100%"}}
                            columns={columns}
                            rows={detailModelPage.content}
                            getRowHeight={() => 90}
                            checkboxSelection={isMultiSelectable}
                            onSelectionModelChange={(ids: any) => setSelectionDetailModelIds(ids)}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    size={"small"}
                    color={"primary"}
                    onClick={() => {
                        const selectModels: DetailModelType[] = selectionDetailModelIds
                            .map(id => _.find(detailModelPage.content, {id: id}))
                            .filter(detailModel => detailModel)
                            .map(detailModel => detailModel as DetailModelType)

                        handleClose(selectModels)
                    }}
                >
                    확인
                </Button>
                <Button variant={"contained"} size={"small"} color={"error"} onClick={() => handleClose([])}>
                    취소
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DetailModelSelectPopup
