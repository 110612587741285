import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ShopCurationType, ShopCurationProductType, ShopSwipeBannerType } from "types/ShopCurationType"
import { Box, Button, Stack } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import styled from "@emotion/styled"

type Props = {
  isManual: boolean
  detailModels: ShopCurationProductType[]
  handleOnChangeState: (partialState: Partial<ShopCurationType>) => void
  openShowProductSelectPopup: () => void
}

const CurationProductTable = ({ detailModels, handleOnChangeState, openShowProductSelectPopup, isManual }: Props) => {
  const [selectionProductIds, setSelectionCurationProductIds] = useState<number[]>([])

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        valueGetter: (params: any) => `${params.row.product.id}`,
      },
      {
        field: "product.title",
        headerName: "상품명",
        flex: 3,
        valueGetter: (params: any) => `${params.row.product.title}`,
      },
      {
        field: "product.status",
        headerName: "상태",
        width: 100,
        valueGetter: (params: any) => `${params.row.product.status}`,
      },
      {
        field: "product.price",
        headerName: "가격",
        width: 100,
        valueGetter: (params: any) => `${params.row.product.price}`,
      },
      {
        field: "product.globalSale",
        headerName: "해외판매",
        width: 80,
        valueGetter: (params: any) => `${params.row.product.globalSale ? "Y" : "N"}`,
      },
      {
        field: "product.thumbnail",
        headerName: "썸네일",
        width: 85,
        renderCell: (params: any) => <img src={params.row.product.thumbnail} style={{ height: "100%" }} />,
      },
    ]

    if (isManual) {
      return [
        ...defaultColumns,
        {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
        {
          field: "actions",
          type: "actions",
          headerName: "순서(위치이동)",
          width: 120,
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<ArrowUpwardIcon />}
              label={"위로"}
              onClick={() => {
                if (!detailModels.length) return

                const index = detailModels.findIndex(({ product }) => product && product.id === params.row.product.id)
                if (index <= 0) return

                let nextProducts = [
                  ...detailModels.slice(0, index - 1),
                  detailModels[index],
                  detailModels[index - 1],
                  ...detailModels.slice(index + 1),
                ]

                nextProducts = nextProducts.map(banner => ({
                  ...banner,
                  priority: null
                })) ;

                handleOnChangeState({ products: nextProducts })
              }}
            />,
            <GridActionsCellItem
              icon={<ArrowDownwardIcon />}
              label={"아래로"}
              onClick={() => {
                if (!detailModels.length) return

                const index = detailModels.findIndex(({ product }) => product && product.id === params.row.product.id)
                if (index === detailModels.length-1) return

                let nextProducts = [
                  ...detailModels.slice(0, index),
                  detailModels[index + 1],
                  detailModels[index],
                  ...detailModels.slice(index + 2),
                ]

                nextProducts = nextProducts.map(banner => ({
                  ...banner,
                  priority: null
                })) ;

                handleOnChangeState({ products: nextProducts })
              }}
            />,
          ],
        },
      ]
    }

    return defaultColumns
  }, [isManual, handleOnChangeState, detailModels])

  return (
    <Box>
      {isManual && (
        <Stack height={"100%"}>
          <ButtonWrapper>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={openShowProductSelectPopup}
              style={{ marginRight: 10 }}
            >
              추가
            </Button>
            <Button
              variant={"contained"}
              color={"error"}
              size={"small"}
              disabled={selectionProductIds.length < 1}
              onClick={() => {
                if (!detailModels.length) return

                const nextProducts = detailModels.filter(
                  ({ product }) => product && !selectionProductIds.includes(product.id)
                )
                handleOnChangeState({ products: nextProducts })
              }}
            >
              삭제
            </Button>
          </ButtonWrapper>
        </Stack>
      )}
      <DataGrid
        getRowId={(row: any) => row.product.id}
        style={{
          height: "500px",
        }}
        columns={columns}
        onSelectionModelChange={(ids) => {
          setSelectionCurationProductIds(ids as number[])
        }}
        checkboxSelection={isManual}
        getRowHeight={() => 80}
        rows={detailModels ?? []}
        hideFooter
        onCellEditCommit={(params) => {
          const index = detailModels.findIndex(({ product }) => product && product.id === params.id)
          if (index < 0) return

          const updatedDetailModels = [...detailModels];
          updatedDetailModels[index] = {
            ...updatedDetailModels[index],
            priority: params.value // newValue에 새로운 priority 값을 할당해야 합니다.
          };

          handleOnChangeState({ products: updatedDetailModels })
        }}
      />
    </Box>
  )
}

export default CurationProductTable

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
