import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, TextField, Typography} from "@mui/material";
import RowBlock from "../../../components/RowBlock";
import {DateTimePicker} from "@mui/lab";
import ImageUploadButton from "../../../components/ImageUploadButton";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {HomeHeroBannerType} from "types/HomeHeroBannerType";
import * as React from "react";
import { upload } from '../../../apis/resourceAPI'
import HomeHeroBannerAPI from "../../../apis/homeHeroBannerAPI";

const initState: HomeHeroBannerType = {
    preface: 'NOTICE',
    title: '제목을 입력 하세요',
    titleSecondary: '부제목을 입력 하세요.',
    foregroundImageUrl: null,
    backgroundImageUrl: null,
    backgroundVideoUrl: null,
    linkUrl: null,
    onDisplay: true,
    priority: null,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO(),
    createdAt: null,
}

const prefaceStyle: React.CSSProperties = {
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',

    color: '#FFFFFF',
}
const titleStyle: React.CSSProperties = {
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '44px',


    color: '#FFFFFF',
}
const titleSecondaryStyle: React.CSSProperties = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',

    /* Color/White */

    color: '#FFFFFF',
}

const titleAreaStyle: React.CSSProperties = {
    position: 'absolute',
    width: '320px',
    height: '150px',
    left: '24px',
    bottom: '10px',
}
const magazineScrollStyle: React.CSSProperties = {
    position: 'absolute',
    width: '8px',
    height: '18px',
    left: '331px',
    top: '600px',
}

// @ts-ignore
const BannerUpdatePopup = ({banner, handleClose}) => {
    const [state, setState] = useState(initState)

    useEffect(() => {
        if (banner != null) {
            setState(banner)
        } else {
            setState(initState)
        }
    }, [banner])

    // @ts-ignore
    const handlebackgroundVideoUrl = (image) => {
        upload(image).then((result) => {
            setState({
                ...state,
                foregroundImageUrl: result.url,
            })
        })
    }

    // @ts-ignore
    const handleBackground = (resource) => {
        upload(resource).then((result) => {
            if (result.resourceContentType == 'IMG') {
                setState({...state, backgroundImageUrl: result.url, backgroundVideoUrl: null})
            } else if (result.resourceContentType == 'VID'){
                setState({...state, backgroundVideoUrl: result.url, backgroundImageUrl: null})
            }
        })
    }

    const handleSubmit = () => {
        if (
            (state.backgroundImageUrl != null || state.backgroundVideoUrl != null) &&
            state.linkUrl != null
        ) {
            console.log(state)
            if (state.id != null) {
                HomeHeroBannerAPI.update(state.id, state).then(() => {handleClose()})
            }
        }
    }

    return (
        <Dialog open={banner != null} maxWidth={"xl"}>
            <DialogTitle>배너 수정</DialogTitle>
            <DialogContent>
                <Stack direction={'row'}>
                    <Stack spacing={3}>
                        <RowBlock title={"배너 이미지"}><ImageUploadButton height={'200px'} handleImage={handlebackgroundVideoUrl} imageUrl={state.foregroundImageUrl}  handleDeleteImage={() => {setState({...state, foregroundImageUrl: null})}}/></RowBlock>
                        <RowBlock title={"배경 이미지/영상"}><ImageUploadButton height={'200px'} handleImage={handleBackground} imageUrl={state.backgroundImageUrl} videoUrl={state.backgroundVideoUrl} handleDeleteImage={() => {setState({...state,  backgroundImageUrl: null, backgroundVideoUrl: null})}}/></RowBlock>

                        <RowBlock title={"서문"}><TextField size={'small'} fullWidth value={state.preface} onChange={(e) => setState({...state, preface: e.target.value})}/></RowBlock>
                        <RowBlock title={"대제목"}><TextField size={'small'} multiline maxRows={2} fullWidth value={state.title} onChange={(e) => setState({...state, title: e.target.value})}/></RowBlock>
                        <RowBlock title={"소제목"}><TextField size={'small'} fullWidth value={state.titleSecondary} onChange={(e) => setState({...state, titleSecondary: e.target.value})}/></RowBlock>
                        <RowBlock title={"링크 URL"}><TextField size={'small'} fullWidth value={state.linkUrl} onChange={(e) => setState({...state, linkUrl: e.target.value})} placeholder={'https://'}/></RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={'row'} justifyContent={'center'}>
                                <Switch checked={state.onDisplay ?? false} onChange={(e) => setState({...state, onDisplay: e.target.checked})}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'center'}>
                                    <DateTimePicker onChange={(e) => {
                                        setState({
                                            ...state,
                                            startedAt: e?.toISO() ?? DateTime.now().toISO()
                                        })
                                    }} value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 시작'}/>
                                    <Typography>~</Typography>
                                    <DateTimePicker onChange={(e) => {
                                        setState({
                                            ...state,
                                            endedAt: e?.toISO() ?? DateTime.now().toISO()
                                        })
                                    }} value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 종료'}/>
                                </Stack>
                        </RowBlock>
                    </Stack>

                    <Box position={'relative'} width={'370px'} height={'728px'}>
                        <Box style={{
                            position: 'absolute',
                            width: '360px',
                            height: '728px',
                            left: '0px',
                            top: '0px',
                            border: 'solid',
                            overflow: 'hidden',
                            background: '#33333333'
                        }}>
                            { state.backgroundImageUrl ? <img src={state.backgroundImageUrl} style={{position: 'absolute'}} width={'100%'}/> : <></>}
                            {
                                state?.backgroundVideoUrl ?
                                    <Box style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,

                                    }}>
                                        <video
                                            autoPlay playsInline loop muted
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'center center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <source src={state.backgroundVideoUrl} />
                                        </video>
                                    </Box>
                                    : <></>
                            }
                            { state.foregroundImageUrl ? <img src={state.foregroundImageUrl} style={{position: 'absolute', bottom: '0px'}} width={'100%'}/> : <></>}

                            <Stack style={titleAreaStyle}>
                                <Typography style={prefaceStyle}>{state.preface ?? ""}</Typography>
                                {(state.title ?? "").split("\n").map(t => (<Typography style={titleStyle}>{t}</Typography>))}
                                <Typography style={titleSecondaryStyle}>{state.titleSecondary ?? ""}</Typography>
                            </Stack>

                            <Box style={magazineScrollStyle}>
                                <svg width="10" height="88" viewBox="0 0 10 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 82L5 86L9 82" stroke="white" stroke-width="1.5"/>
                                    <path d="M1 75L5 79L9 75" stroke="white" stroke-width="1.5"/>
                                    <path d="M1 68L5 72L9 68" stroke="white" stroke-width="1.5"/>
                                    <path d="M0.515625 59.1328L9 59.1328L9 57.9023L2.85937 57.9023L2.85937 57.832L8.98828 55.3359L8.98828 54.3164L2.87109 51.832L2.87109 51.75L9 51.75L9 50.5195L0.515625 50.5195L0.515625 52.0898L7.08984 54.7734L7.08984 54.8789L0.515625 57.5625L0.515625 59.1328ZM9 48.0586L6.76172 47.2852L6.76172 43.9805L9 43.207L9 41.8359L0.515624 44.8945L0.515624 46.3828L9 49.4297L9 48.0586ZM5.68359 46.916L2.0625 45.668L2.0625 45.6094L5.68359 44.3555L5.68359 46.916ZM3.19922 35.4258L3.19922 34.1016C1.54101 34.3887 0.398436 35.8066 0.398437 37.6406C0.398437 39.832 2.05078 41.4375 4.75781 41.4375C7.44726 41.4375 9.11719 39.8789 9.11719 37.582C9.11719 35.5195 7.76367 34.043 5.61328 34.043L4.65234 34.043L4.65234 37.3828L5.71875 37.3828L5.71875 35.2969C7.07812 35.3203 7.93945 36.2168 7.93359 37.5703C7.93945 39.0703 6.80859 40.1543 4.75781 40.1602C2.70117 40.1543 1.57617 39.0586 1.58203 37.6289C1.57617 36.5098 2.19726 35.7422 3.19922 35.4258ZM9 32.0977L6.76172 31.3242L6.76172 28.0195L9 27.2461L9 25.875L0.515624 28.9336L0.515624 30.4219L9 33.4687L9 32.0977ZM5.68359 30.9551L2.0625 29.707L2.0625 29.6484L5.68359 28.3945L5.68359 30.9551ZM9 25.0195L9 18.9492L7.88672 18.9492L7.88672 23.4375L1.37109 18.9844L0.515623 18.9844L0.515623 25.0664L1.6289 25.0664L1.6289 20.5664L8.14453 25.0195L9 25.0195ZM0.515623 16.1953L0.515623 17.4844L9 17.4844L9 16.1953L0.515623 16.1953ZM0.515623 7.62891L0.515623 8.89453L6.72656 8.89453L6.72656 8.97656L0.515623 13.2773L0.515623 14.4727L9 14.4727L9 13.1836L2.80078 13.1836L2.80078 13.1133L9 8.8125L9 7.62891L0.515623 7.62891ZM9 5.89453L9 0.527343L7.88672 0.527343L7.88672 4.60547L5.29687 4.60547L5.29687 0.855468L4.19531 0.855468L4.19531 4.60547L1.6289 4.60547L1.6289 0.574219L0.515622 0.574219L0.515623 5.89453L9 5.89453Z" fill="white"/>
                                </svg>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BannerUpdatePopup