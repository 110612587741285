import { httpDelete, httpGet, httpPost, httpPostMultipartFile, httpPut } from "../utils/httpAPI"
import { ModelType } from "types/ModelType"
import { PageType } from "types/PageType"
import { ModelListItemType } from "types/ModelListItemType"
import { UploadResultType } from "types/UploadResultType"

export const createModel = (Model: Omit<ModelType, "id">): Promise<ModelType> => httpPost("/models", Model)
export const getModels: (params?: { [key: string]: any }) => Promise<PageType<ModelType>> = (params) =>
  httpGet("/models", params)
export const deleteModel: (id: number) => Promise<string> = (id) => httpDelete(`/models/${id}`)
export const showModel: (id: number) => Promise<string> = (id) => httpPost(`/models/${id}/show`)
export const hideModel: (id: number) => Promise<string> = (id) => httpPost(`/models/${id}/hide`)
export const updateModel: (id: number, Model: Omit<ModelType, "id">) => Promise<ModelType> = (id, Model) =>
  httpPut(`/models/${id}`, Model)

export const getModel: (id: number) => Promise<ModelListItemType> = (id) => httpGet(`/models/${id}`)
export const getModelList: (params: {
  page: number
  brand: string
  name?: string
  size: number
}) => Promise<PageType<ModelListItemType>> = (params) => httpGet("/models", params)

export const uploadThumbnail: (fileList: FileList) => Promise<UploadResultType> = (file) =>
  httpPostMultipartFile("/models/thumbnail", file)

export const updateDisplay: (id: number, onDisplay: boolean) => Promise<ModelListItemType> = (id, onDisplay) =>
  httpPut(`/models/${id}`, { onDisplay })
