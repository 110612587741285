import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const headerItemList = [
  {
    title: "회원ID",
    width: "8%",
  },
  {
    title: "로그인 방식",
    width: "10%",
  },
  {
    title: "Email",
    width: "22%",
  },
  {
    title: "이름",
    width: "10%",
  },
  {
    title: "닉네임",
    width: "10%",
  },
  {
    title: "연락처",
    width: "10%",
  },
  {
    title: "상태",
    width: "10%",
  },
  {
    title: "외국인",
    width: "8%",
  },
  {
    title: "회원구분",
    width: "8%",
  },
  {
    title: "회원가입",
    width: "10%",
  },
  {
    title: "해외 판매",
    width: "8%",
  },
  {
    title: "메모",
    width: "5%",
  },
]

const UserListTableHeader = () => {
  return (
    <ListTableHeader>
      {headerItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default UserListTableHeader
