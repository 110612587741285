import { FunctionComponent, useState } from "react";
import LayoutNavigationAside from "./LayoutNavigationAside";

import LayoutNavigationTitleBar from "./LayoutNavigationTitleBar";

interface PropsType {
  asideWidth: number;
}
const LayoutNavigation: FunctionComponent<PropsType> = ({ asideWidth }) => {
  const [isOpenAside, setIsOpenAside] = useState(true);

  const toggleIsOpenAside = () => {
    setIsOpenAside((isOpenAside) => !isOpenAside);
  };

  return (
    <>
      <LayoutNavigationTitleBar
        asideWidth={asideWidth}
        isOpenAside={isOpenAside}
        toggleIsOpenAside={toggleIsOpenAside}
      />
      <LayoutNavigationAside
        asideWidth={asideWidth}
        isOpenAside={isOpenAside}
        toggleIsOpenAside={toggleIsOpenAside}
      />
    </>
  );
};
export default LayoutNavigation;
