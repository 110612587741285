import { DiagnosisType, PricesType } from "types/DiagnosisType"
import { numberWithCommas } from "utils/NumberUtils"
import { TextField } from "@mui/material"
import { useEffect } from "react"
import { isNil } from "lodash"

type Props = {
  penaltyPrice: {
    canceled: number
    disqualified: number
  }
  viverPrices?: PricesType
  changeDiagnosisState: (key: keyof DiagnosisType, newState: any) => void
  productPrice: number
  isPenaltyRequired: boolean
  repurchaseGuaranteeRate?: number
}

const RePurchaseGuaranteedPriceInformation = ({
  penaltyPrice,
  productPrice,
  viverPrices,
  changeDiagnosisState,
  isPenaltyRequired,
  repurchaseGuaranteeRate,
}: Props) => {
  const getDeductedPrice = () => {
    if (!!viverPrices?.purchasedPrice && !!viverPrices?.estimatedPriceAtOrder?.max) {
      return numberWithCommas(viverPrices.purchasedPrice - viverPrices.estimatedPriceAtOrder.max)
    }

    return "-"
  }

  useEffect(() => {
    changeDiagnosisState("viverPrices", { ...viverPrices, suggestedPrice: viverPrices?.estimatedPriceAtOrder?.max })
  }, [viverPrices?.estimatedPriceAtOrder?.max])

  return (
    <>
      <h2>상품 가격 정보</h2>

      <h3>진단 전 고객 노출 금액</h3>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"header"} colSpan={1}>
              이전 상품 구매가
            </td>
            <td className={"ta-center"} colSpan={3}>{`${numberWithCommas(viverPrices?.purchasedPrice) || "-"} 원`}</td>{" "}
          </tr>

          <tr>
            <td className={"header"} colSpan={1}>
              최대 매입가{" "}
              {!isNil(repurchaseGuaranteeRate) &&
                repurchaseGuaranteeRate > 0 &&
                `(${(repurchaseGuaranteeRate * 100).toFixed()}%)`}
            </td>
            <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
              viverPrices?.estimatedPriceAtOrder?.max
            )} 원`}</td>
            <td className={"header"} colSpan={1}>
              감가금액{" "}
              {!isNil(repurchaseGuaranteeRate) &&
                repurchaseGuaranteeRate > 0 &&
                `(${((1 - repurchaseGuaranteeRate) * 100).toFixed()}%)`}
            </td>
            <td className={"ta-center"} colSpan={1}>{`${getDeductedPrice()} 원`}</td>
          </tr>
        </tbody>
      </table>

      <h3>진단 후 매물 기준 판매가</h3>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"header"}>보정 전 감정가</td>
            <td>{numberWithCommas(viverPrices?.estimatedPriceAtOrder?.max) || "-"} 원</td>
            <td className={"header"}>보정 금액</td>
            <td>
              <TextField
                variant="standard"
                type={"number"}
                inputMode={"decimal"}
                value={Number(viverPrices?.afterDiagnosisPrice?.delta) || 0}
                onChange={(event) => {
                  const delta = Number(event.target.value)
                  const afterPrice = !!viverPrices?.estimatedPriceAtOrder?.max
                    ? viverPrices.estimatedPriceAtOrder.max - delta
                    : delta

                  const newPrice = {
                    ...viverPrices,
                    afterDiagnosisPrice: {
                      ...viverPrices?.afterDiagnosisPrice,
                      delta,
                      after: afterPrice,
                    },
                    finalPrice: afterPrice,
                    suggestedPrice: afterPrice,
                  }

                  changeDiagnosisState("viverPrices", newPrice)
                }}
              />{" "}
              원
            </td>
          </tr>
          <tr>
            <td className={"header"}>보정 후 감정가</td>
            <td>{numberWithCommas(viverPrices?.finalPrice) || "-"} 원</td>
            <td className={"header"}>보정 사유</td>
            <td>
              <TextField
                variant="standard"
                type={"text"}
                fullWidth={true}
                value={viverPrices?.afterDiagnosisPrice?.reason}
                onChange={(event) => {
                  changeDiagnosisState("viverPrices", {
                    ...viverPrices,
                    afterDiagnosisPrice: { ...viverPrices?.afterDiagnosisPrice, reason: event.target.value },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      {isPenaltyRequired && (
        <>
          <h3>패널티 금액</h3>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>패널티 기준가</td>
                <td>{numberWithCommas(productPrice) || "-"} 원</td>
                <td className={"header"}>패널티 금액</td>
                <td>{numberWithCommas(penaltyPrice.disqualified) || "0"} 원</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <h3>바이버 매입금액</h3>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"header"}>바이버 매입금액</td>
            <td>{numberWithCommas(viverPrices?.finalPrice) || "-"} 원</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default RePurchaseGuaranteedPriceInformation
