import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const pointTableHeaderItemList = [
  {
    title: "ID",
    width: "10%",
  },
  {
    title: "지급일시",
    width: "10%",
  },
  {
    title: "유저ID",
    width: "10%",
  },
  {
    title: "타입",
    width: "5%",
  },
  {
    title: "상태",
    width: "5%",
  },
  {
    title: "지급 사유",
    width: "10%",
  },
  {
    title: "지급 메세지",
    width: "30%",
  },
  {
    title: "포인트",
    width: "10%",
  },
  {
    title: "만료일",
    width: "10%",
  },
]

const PointListTableHeader = () => {
  return (
    <ListTableHeader>
      {pointTableHeaderItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default PointListTableHeader
