import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import RowBlock from "components/RowBlock"
import { DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import {
  ShopBrandModelCurationCreateBodyType,
  ShopCurationCreateBodyType,
  ShopCurationType,
} from "types/ShopCurationType"
import _ from "lodash"
import CurationBrandTable from "./CurationBrandTable"
import { toast } from "react-toastify"

import shopBrandModelCurationAPI from "../../../../../apis/shopBrandModelCurationAPI"
import { isNil, isNotNil } from "../../../../../utils/validationUtils"
import CurationBrandModelEditorPopup from "../BrandModelPopup/CurationBrandModelEditorPopup"
import CheckBox from "../../../../../components/Checkbox"
import styled from "@emotion/styled"


const initCurationState: ShopBrandModelCurationCreateBodyType = {
  title: null,
  titleSecondary: null,
  bannerImageUrl: null,
  brands: [],
  onDisplay: true,
  priority: null,
  type: "BRAND_MODEL_CURATION",
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().toISO(),
  showDomesticUser: true,
  showForeignUser: false
}

export type CurationStateType = ShopBrandModelCurationCreateBodyType | ShopCurationType

type Props = {
  curationId: number | null
  handleClose: () => void
  editorType: "EDIT" | "CREATE"
}

const CurationBrandEditorPopup = ({ curationId, handleClose, editorType }: Props) => {
  const [showCreateBrand, setShowCreateBrand] = useState(false)
  const [state, setState] = useState<CurationStateType>(initCurationState)
  const [showModelSelectPopup, setShowModelSelectPopup] = useState(false)
  const [editableBrandId, setEditableBrandId] = useState<number | null>(null)


  const handleOnChangeState = (partialState: Partial<ShopCurationType>) => {
    setState((state) => (state ? { ...state, ...partialState } : state))
  }

  const checkIfIsValidPayload = (state: CurationStateType) => {
    if (_.isNil(state)) {
      return false
    }

    if (_.isNil(state.title)) {
      toast.error("제목을 입력해주세요")
      return false
    }

    return true
  }

  const callApiByEditorType = async (state: CurationStateType) => {
    if (editorType === "EDIT") {
      await shopBrandModelCurationAPI.update(state as ShopCurationType)
    } else {
      await shopBrandModelCurationAPI.create(state as ShopCurationCreateBodyType)
    }
  }

  const handleOnClickSubmitButton = async () => {
    if (_.isNil(state)) return

    if (!checkIfIsValidPayload(state)) {
      return
    }

    await callApiByEditorType(state)
    handleClose()
  }

  const fetchCurationModel = (curationId: number) => {
    shopBrandModelCurationAPI.getDetail(curationId).then((curation) => {
      setState(curation)
    })
  }

  useEffect(() => {
    if (!!curationId) {
      fetchCurationModel(curationId)
    } else {
      setState(initCurationState)
    }
  }, [curationId])

  return (
    <Dialog open={true} maxWidth={"lg"} fullWidth>
      <DialogTitle>큐레이션 {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <RowBlock title={"제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.title}
              onChange={(e) => handleOnChangeState({ title: e.target.value })}
              placeholder={"큐레이션 제목"}
            />
          </RowBlock>
          <RowBlock title={"부제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.titleSecondary}
              onChange={(e) => handleOnChangeState({ titleSecondary: e.target.value })}
              placeholder={"큐레이션 부제목"}
            />
          </RowBlock>
          <RowBlock title={"노출 여부"}>
            <Stack direction={"row"}>
              <Switch
                checked={state ? Boolean(state.onDisplay) : false}
                onChange={(e) => handleOnChangeState({ onDisplay: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 기간"}>
            <Stack direction={"row"} spacing={2}>
              <DateTimePicker
                onChange={(dateTime) => {
                  handleOnChangeState({
                    startedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                  })
                }}
                value={state?.startedAt ? DateTime.fromISO(state.startedAt) : null}
                renderInput={(props) => <TextField {...props} />}
                label={"게시 시작"}
                inputFormat={"yyyy-MM-dd HH:mm"}
              />
              <Typography>~</Typography>
              <DateTimePicker
                onChange={(dateTime) => {
                  handleOnChangeState({
                    endedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                  })
                }}
                value={state?.endedAt ? DateTime.fromISO(state.endedAt) : null}
                renderInput={(props) => <TextField {...props} />}
                label={"게시 종료"}
                inputFormat={"yyyy-MM-dd HH:mm"}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출"}   >
            <Stack direction={'row'} alignItems={'center'}   >
              <CheckBox checked={state?.showDomesticUser}   id="showDomesticUser"  onChange={() => setState((pre) => ({...state, showDomesticUser: !state.showDomesticUser})) } />
              <CheckBoxLabel htmlFor="showDomesticUser" >
                내국인 회원 (국내거주 외국인 포함)
              </CheckBoxLabel>
              <CheckBox checked={state?.showForeignUser}   id="showForeignUser"  onChange={() => setState((pre) => ({...state, showForeignUser: !state.showForeignUser})) } />
              <CheckBoxLabel htmlFor="showForeignUser" >
                외국인 회원
              </CheckBoxLabel>
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 브랜드"}>
            <CurationBrandTable
              brands={state?.brands || []}
              handleOnChangeState={handleOnChangeState}
              openShowBrandModelSelectPopup={() => {
                setEditableBrandId(null)
                setShowCreateBrand(true)
              }}
              setEditableBrandId={setEditableBrandId}
            />
          </RowBlock>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleOnClickSubmitButton}>
          {editorType === "EDIT" ? "수정" : "등록"}
        </Button>
        <Button variant={"contained"} color={"error"} onClick={handleClose}>
          취소
        </Button>
      </DialogActions>

      {showCreateBrand &&
        <CurationBrandModelEditorPopup
          brandId={editableBrandId}
          brandData={state.brands?.filter(brand => brand.id === editableBrandId)[0] ?? null}
          handleOnChangeState={handleOnChangeState}
          handleClose={(brands) => {
            setShowCreateBrand(false)

            if (_.isNil(brands)) return
            const brandRows = state.brands ?? []

            const idx = brandRows.findIndex(brand => brand.id === brands.id)

            const prevObject = brandRows.slice(0, idx)
            const nextObject = brandRows.slice(idx + 1)

            // 수정
            if (idx !== -1) {
              handleOnChangeState({
                brands: [...prevObject,
                  brands,
                  ...nextObject,
                ],
              })

            } else {
              brands.id = ((state.brands?.length || 0) + 1) * -1

              handleOnChangeState({
                brands: [
                  ...brandRows,
                  brands,
                ],
              })
            }

            // if(existsBrand.length > 0){
            //
            // }else{
            //   handleOnChangeState({ brands: [...state.brands ?? [], brands] })
            // }

            // const deduplicatedModels = models.filter((model) => !brandIds.includes(model.id))
            // const duplicatedModels = models.filter((model) => brandIds.includes(model.id))
            //
            // if (duplicatedModels.length) {
            //   window.alert(
            //     `이미 등록된 모델 ${duplicatedModels.length}개를 제외하고 ` +
            //     `${deduplicatedModels.length}개의 모델을 추가합니다.` +
            //     `\n추가모델ID: ${deduplicatedModels.map((model) => model.id).join(", ") || "-"}` +
            //     `\n제외모델ID: ${duplicatedModels.map((model) => model.id).join(", ")}`
            //   )
            // }

            // const nextModelRows = [
            //   ...brandRows,
            //   ...deduplicatedModels.map((model, index) => ({
            //     model: model,
            //     priority: brandRows.length + index + 1,
            //   })),
            // ]

          }}
          editorType={isNil(editableBrandId) ? "CREATE" : "EDIT"}
        />
      }

    </Dialog>
  )
}


const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`

export default CurationBrandEditorPopup
