import styled from "@emotion/styled"
import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import EventListTableRow from "./TableBody/TableRow"
import EventListTableHeader from "./TableHeader"
import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import { EventType } from "types/EventType"

type Props = {
  eventList: EventType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
  selectEvent: (eventId: number) => void
}

const EventListTable = ({ eventList, paginationParams, changePage, selectEvent }: Props) => {
  return (
    <ListTable>
      <EventListTableHeader />
      <TableBody>
        {eventList.length > 0 ? (
          eventList.map((event) => (
            <EventListTableRow key={event.id.toString()} event={event} onClickRow={selectEvent} />
          ))
        ) : (
          <EmptyWrapper>
            <p>결과가 없습니다.</p>
          </EmptyWrapper>
        )}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page - 1)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default EventListTable

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
`
