import {
  PointScheduleListItemType,
  PointScheduleUserSourceType,
  PointScheduleStatusCode,
} from "types/PointScheduleListItemType"
import PopupPage from "components/Popup/PopupPage"
import { PointScheduleEditorType, useEditPointScheduleData } from "./useEditPointScheduleData"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import Button from "components/Button"
import styled from "@emotion/styled"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { toDateStr } from "utils/datetimeUtil"
import { DateTime } from "luxon"
import { FormControl, MenuItem, Select } from "@mui/material"
import { useMemo } from "react"
import { PointReasonType } from "types/PointType"
import CsvFileUploader from "./CsvFileUploader"
import UserIdSelector from "./UserIdSelector"

const userSourceOption: { value: PointScheduleUserSourceType; title: string }[] = [
  {
    value: "ID",
    title: "유저 휴대폰번호 입력",
  },
  { value: "CSV", title: "CSV 업로드" },
]

const reasonTypeOption: { value: PointReasonType; title: string }[] = [
  {
    value: "이벤트 적립",
    title: "이벤트 적립",
  },
  {
    value: "수동 적립",
    title: "수동 적립",
  },
  {
    value: "회원 가입 적립",
    title: "회원 가입 적립",
  },
  {
    value: "구매 완료 적립",
    title: "구매 완료 적립",
  },
  {
    value: "구매 리뷰 작성 적립",
    title: "구매 리뷰 작성 적립",
  },
  {
    value: "판매 리뷰 작성 적립",
    title: "판매 리뷰 작성 적립",
  },
  {
    value: "컬렉션 등록 적립",
    title: "컬렉션 등록 적립",
  },
  {
    value: "친구초대",
    title: "친구초대",
  }
]

type Props = {
  selectedPointSchedule?: PointScheduleListItemType
  isOpen: boolean
  closePopup: () => void
  fetchPointScheduleList: () => void
}

const EditPointSchedulePopup = ({ selectedPointSchedule, isOpen, closePopup, fetchPointScheduleList }: Props) => {
  const editorType = useMemo((): PointScheduleEditorType => {
    return !selectedPointSchedule ? "CREATE" : "EDIT"
  }, [selectedPointSchedule])

  const onFinishRequest = () => {
    closePopup()
    fetchPointScheduleList()
  }

  const { pointScheduleState, changePointScheduleState, onSubmit, onCancel } = useEditPointScheduleData({
    editorType,
    editingPointSchedule: selectedPointSchedule,
    onFinishRequest,
  })

  return (
    <PopupPage isOpen={isOpen} style={{ width: "500px", height: "850px" }}>
      <ContentWrapper>
        <PopupPageTitle>포인트 지급 예약 {editorType === "CREATE" ? "생성" : "수정"}</PopupPageTitle>
        <PopupPageScrollContainer>
          {pointScheduleState.status && (
            <OptionWrapper>
              <h3>예약 상태</h3>
              <StatusText status={pointScheduleState.status}>{pointScheduleState.status}</StatusText>
            </OptionWrapper>
          )}
          <OptionWrapper>
            <h3>지급 타입</h3>
            <FormControl>
              <Select
                size={"small"}
                value={pointScheduleState.reasonType}
                required
                onChange={(e) => {
                  changePointScheduleState("reasonType", e.target.value as PointReasonType)
                }}
                MenuProps={{
                  style: { zIndex: 10001 },
                }}
              >
                {reasonTypeOption.map((menu) => (
                  <MenuItem value={menu.value} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </OptionWrapper>
          <OptionWrapper>
            <h3>지급 메세지</h3>
            <TextArea
              value={pointScheduleState.reasonMessage}
              onChange={(e) => changePointScheduleState("reasonMessage", e.target.value)}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>유저 선택 방식</h3>
            <FormControl>
              <Select
                size={"small"}
                value={pointScheduleState.userSourceType}
                required
                onChange={(e) => {
                  changePointScheduleState("userSourceType", e.target.value as PointScheduleUserSourceType)
                }}
                MenuProps={{
                  style: { zIndex: 10001 },
                }}
              >
                {userSourceOption.map((menu) => (
                  <MenuItem value={menu.value} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </OptionWrapper>

          {pointScheduleState.userSourceType === "CSV" && (
            <CsvFileUploader
              userSource={pointScheduleState.userSource}
              changePointScheduleState={changePointScheduleState}
            />
          )}
          {pointScheduleState.userSourceType === "ID" && (
            <OptionWrapper>
              <UserIdSelector
                userIdList={pointScheduleState.userIds || []}
                changePointScheduleState={changePointScheduleState}
              />
            </OptionWrapper>
          )}
          {!!pointScheduleState.userIds.length && (
            <OptionWrapper>
              <h3>지급할 유저 ID ({pointScheduleState.userIds.length}명)</h3>
              <SelectedIdListWrapper>
                {pointScheduleState?.userIds.map((userId) => (
                  <SelectedIdItem key={userId}>
                    {userId}
                    <button
                      className="deleteButton"
                      onClick={() =>
                        changePointScheduleState(
                          "userIds",
                          pointScheduleState.userIds.filter((prevId) => prevId !== userId)
                        )
                      }
                    >
                      X
                    </button>
                  </SelectedIdItem>
                ))}
              </SelectedIdListWrapper>
            </OptionWrapper>
          )}
          <OptionWrapper>
            <h3>지급 포인트</h3>
            <SearchInput
              value={pointScheduleState.points}
              onChange={(e) => {
                const inputValue = Number(e.target.value)

                if (isNaN(inputValue)) {
                  return
                }

                changePointScheduleState("points", inputValue)
              }}
            />
          </OptionWrapper>

          <OptionWrapper>
            <h3>포인트 예정일</h3>
            <DatePicker
              type="datetime-local"
              value={
                pointScheduleState.scheduleAt
                  ? DateTime.fromISO(pointScheduleState.scheduleAt).toLocal().toISO().split(".")[0]
                  : ""
              }
              min={toDateStr(DateTime.now().toISO(), true)}
              onChange={(e) => {
                changePointScheduleState("scheduleAt", DateTime.fromISO(e.target.value).toISO())
              }}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>포인트 만료일</h3>
            <DatePicker
              type="date"
              value={pointScheduleState.availableDate}
              min={toDateStr(DateTime.now().toISO(), true)}
              onChange={(e) => {
                changePointScheduleState("availableDate", toDateStr(e.target.value, true) || "")
              }}
            />
          </OptionWrapper>
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <ButtonListWrapper>
            {editorType === "EDIT" && pointScheduleState.status === PointScheduleStatusCode.대기 && (
              <ButtonWrapper style={{ width: "100px" }}>
                <Button buttonStyle="red-fill" onClick={() => onCancel(pointScheduleState.id)}>
                  예약 취소
                </Button>
              </ButtonWrapper>
            )}
            {pointScheduleState.status !== PointScheduleStatusCode.완료 && (
              <ButtonWrapper style={{ width: "80px" }}>
                <Button onClick={onSubmit}>{editorType === "CREATE" ? "생성" : "수정"}</Button>
              </ButtonWrapper>
            )}
            <ButtonWrapper style={{ width: "80px" }}>
              <Button buttonStyle="blue-border" onClick={closePopup}>
                닫기
              </Button>
            </ButtonWrapper>
          </ButtonListWrapper>
        </PopupPageFooter>
      </ContentWrapper>
    </PopupPage>
  )
}

export default EditPointSchedulePopup

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`

const ButtonWrapper = styled.div`
  :not(:last-child) {
    margin-right: 10px;
  }
`

const ButtonListWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h3 {
    margin: 5px 0;
    font-size: 16px;
  }
`

const DatePicker = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid #eee;
  outline: none;
  border-radius: 4px;
  padding: 10px;
`

const TextArea = styled.textarea`
  flex: 1;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  outline: none;
  padding: 10px;
  display: flex;
  algin-items: center;
`

const StatusText = styled.div<{ status: PointScheduleStatusCode }>`
  font-weight: 700;
  color: ${({ status }) => {
    switch (status) {
      case PointScheduleStatusCode.대기:
        return "#AAAAAA"
      case PointScheduleStatusCode.취소:
        return "#000000"
      case PointScheduleStatusCode.실패:
        return "#d32f2f"
      case PointScheduleStatusCode.완료:
        return "#1976d2"
    }
  }};
`
const SelectedIdListWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  max-width: calc(500px - 32px);
  flex-wrap: wrap;
`

const SelectedIdItem = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 5px 10px;
  background: rgba(224, 224, 224, 1);
  font-size: 12px;
  margin: 0 5px 5px 0;

  button.deleteButton {
    margin-left: 5px;
    font-size: 12px;
  }
`
