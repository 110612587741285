import styled from "@emotion/styled"
import { Spacer } from "components/Spacer"

import { useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useSearchParams } from "react-router-dom"
import { ProductStockListManagerPopup } from "./ProductStockListMangerPopup"
import { ProductStockManagerHeader } from "./ProductStockManagerHeader"

import { ProductStockTable } from "./ProductStockTable"
import { useProductStockData } from "./useProductStockData"
import * as XLSX from "xlsx"
import { DateTime } from "luxon"

const headerMenu: { title: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"; key: "쇼룸" | "랩스" | "장성원시계" | "두나무금고" }[] = [
  { title: "쇼룸", key: "쇼룸" },
  { title: "랩스", key: "랩스" },
  { title: "장성원시계", key: "장성원시계" },
  { title: "두나무금고", key: "두나무금고" },
]

export const ProductStockManager = () => {
  const [isOpenListManager, setIsOpenListManager] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const downloadExcel = () => {
    const data = productStockGroupList.flatMap(g => (
      g.items.map(
        it => ({
          "기준일자": date || DateTime.now().toFormat("yyyy-MM-dd"),
          "장소": g.site,
          "그룹": g.name || "그룹 미지정",
          "상품번호": it.item.id,
          "시리얼 넘버": it.item.serialNo,
          "판매방식": it.item.saleType,
          "상품명": `${it.item.title} ${it.item.titleSecondary}`,
          "RefNo": it.item.detailModel.refNo,
          "최종 입출고 상태": it.item.location || "",
          "최종 입출고 일시": it.item.locationUpdatedAt || "",
          "OPEN": it.whenOpenCheckedBy?.name || "",
          "CLOSE": it.whenClosedCheckedBy?.name || "",
        }),
      )
    ))
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, `재고관리_${site}_${date || DateTime.now().toFormat("yyyyMMdd")}.xlsx`)
  }
  const {
    site,
    date,
    productStockGroupList,
    isEditable,
    onSelectTab,
    onSelectDate,
    onChangeGroup,
    onChangeOrder,
    fetchProductStockList,
    onCheckClose,
    onCheckOpen,
  } = useProductStockData()

  useEffect(() => {
    const nextSearchParams = new URLSearchParams()

    nextSearchParams.set("site", site || "쇼룸")
    setSearchParams(nextSearchParams)
  }, [setSearchParams, site])

  return (
    <>
      {isOpenListManager && (
        <ProductStockListManagerPopup
          site={site}
          closeModal={async () => {
            await fetchProductStockList()
            setIsOpenListManager(false)
          }}
        />
      )}
      <PageHeaderWrapper>
        {headerMenu.map((header) => (
          <Option
            key={header.key}
            selected={searchParams.get("site") === header.key}
            onClick={() => onSelectTab(header.key)}
          >
            {header.title}
          </Option>
        ))}
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <ProductStockManagerHeader
          date={date}
          isEditable={isEditable}
          openListManager={() => setIsOpenListManager(true)}
          onChangeDate={onSelectDate}
          onClickDownload={downloadExcel}
        />
        <DndProvider backend={HTML5Backend}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {productStockGroupList.map((stock) => {
              return (
                <div key={stock.name}>
                  <ProductStockTable
                    id={stock.id}
                    site={site}
                    list={stock.items}
                    isEditable={isEditable}
                    count={stock.items.length}
                    label={stock.name}
                    onChangeGroup={onChangeGroup}
                    onChangeOrder={onChangeOrder}
                    onCheckOpen={onCheckOpen}
                    onCheckClose={onCheckClose}
                    fetchProductStockList={fetchProductStockList}
                  />
                  <Spacer space={32} />
                </div>
              )
            })}
          </div>
        </DndProvider>
      </PageBodyWrapper>
    </>
  )
}

const PageHeaderWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    background: #fff;
    align-items: center;
    padding: 24px 30px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const Option = styled.div<{ selected: boolean }>`
    ont-size: 14px;
    color: #000;
    text-decoration: none;
    padding-right: 40px;
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    cursor: pointer;
`

const PageBodyWrapper = styled.div`
    width: 100%;
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`
