import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, TextField, Typography} from "@mui/material"
import RowBlock from "components/RowBlock"
import {DateTimePicker} from "@mui/lab"
import {DateTime} from "luxon"
import {useEffect, useState} from "react"
import {ShopCurationCreateBodyType, ShopCurationType} from "types/ShopCurationType"
import shopContentAPI from "apis/shopContentAPI"
import _ from "lodash"
import DetailModelSelectPopup from "components/Popup/DetailModelSelectPopup"
import {ProductConditionCode} from "types/DiagnosisType"
import {toast} from "react-toastify"


const initCurationState: ShopCurationCreateBodyType = {
  title: null,
  titleSecondary: null,
  products: [],
  minQuantity: null,
  maxQuantity: null,
  onDisplay: true,
  priority: null,
  type: "LIKED_DETAIL_MODEL_PRODUCT_CURATION",
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().toISO(),
  excludeSoldOut: true,
  curationType: "수동",
  saleType: [],
  productCondition: [ProductConditionCode.미착용, ProductConditionCode.중고],
}

type CurationStateType = ShopCurationCreateBodyType | ShopCurationType

type Props = {
  curationId: number | null
  handleClose: () => void
  editorType: "EDIT" | "CREATE"
}

const CurationEditorPopup = ({ curationId, handleClose, editorType }: Props) => {
  const [state, setState] = useState<CurationStateType>(initCurationState)
  const [showProductSelectPopup, setShowProductSelectPopup] = useState(false)

  const handleOnChangeState = (partialState: Partial<ShopCurationType>) => {
    setState((state) => (state ? { ...state, ...partialState } : state))
  }

  const checkIfIsValidPayload = (state: CurationStateType) => {
    if (_.isNil(state)) {
      return false
    }

    if (_.isNil(state.title)) {
      toast.error("큐레이션 제목을 입력해주세요")
      return false
    }

    if (_.isNil(state.titleSecondary)) {
      toast.error("큐레이션 부제목을 입력해주세요")
      return false
    }

    if (_.isNil(state.minQuantity)) {
      toast.error("최소 노출개수를 입력해주세요")
      return false
    }

    if (_.isNil(state.maxQuantity)) {
      toast.error("최대 노출개수를 입력해주세요")
      return false
    }

    return true
  }

  const callApiByEditorType = async (state: CurationStateType) => {
    if (editorType === "EDIT") {
      await shopContentAPI.update(state as ShopCurationType)
    } else {
      await shopContentAPI.create(state as ShopCurationCreateBodyType)
    }
  }

  const handleOnClickSubmitButton = async () => {
    if (_.isNil(state)) return

    if (!checkIfIsValidPayload(state)) {
      return
    }

    await callApiByEditorType(state)
    handleClose()
  }

  const fetchCurationDetail = (curationId: number) => {
    shopContentAPI.getDetail(curationId).then((curation) => {
      setState(curation)
    })
  }

  useEffect(() => {
    if (!!curationId) {
      fetchCurationDetail(curationId)
    } else {
      setState(initCurationState)
    }
  }, [curationId])

  return (
    <Dialog open={true} maxWidth={"lg"} fullWidth>
      <DialogTitle>관심 모델 기반 상품 큐레이션 {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <RowBlock title={"제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.title}
              onChange={(e) => handleOnChangeState({ title: e.target.value })}
              placeholder={"큐레이션 제목"}
            />
          </RowBlock>
          <RowBlock title={"부제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.titleSecondary}
              onChange={(e) => handleOnChangeState({ titleSecondary: e.target.value })}
              placeholder={"큐레이션 부제목"}
            />
          </RowBlock>
          <RowBlock title={"최소 노출 갯수"}>
            <TextField
              size={"small"}
              value={state?.minQuantity}
              fullWidth
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (inputValue) {
                  handleOnChangeState({ minQuantity: inputValue })
                }
              }}
              placeholder={"3"}
            />
          </RowBlock>
          <RowBlock title={"최대 노출 갯수"}>
            <TextField
              size={"small"}
              value={state?.maxQuantity}
              fullWidth
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (inputValue) {
                  handleOnChangeState({ maxQuantity: inputValue })
                }
              }}
              placeholder={"10"}
            />
          </RowBlock>
          <RowBlock title={"노출 여부"}>
            <Stack direction={"row"}>
              <Switch
                checked={state ? Boolean(state.onDisplay) : false}
                onChange={(e) => handleOnChangeState({ onDisplay: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"품절 상세 모델 제외"}>
            <Stack direction={"row"}>
              <Switch
                  checked={state ? Boolean(state.excludeSoldOut) : false}
                  onChange={(e) => handleOnChangeState({ excludeSoldOut: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 기간"}>
              <Stack direction={"row"} spacing={2}>
                <DateTimePicker
                  onChange={(dateTime) => {
                    handleOnChangeState({
                      startedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                    })
                  }}
                  value={state?.startedAt ? DateTime.fromISO(state.startedAt) : null}
                  renderInput={(props) => <TextField {...props} />}
                  label={"게시 시작"}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                />
                <Typography>~</Typography>
                <DateTimePicker
                  onChange={(dateTime) => {
                    handleOnChangeState({
                      endedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                    })
                  }}
                  value={state?.endedAt ? DateTime.fromISO(state.endedAt) : null}
                  renderInput={(props) => <TextField {...props} />}
                  label={"게시 종료"}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                />
              </Stack>
          </RowBlock>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleOnClickSubmitButton}>
          {editorType === "EDIT" ? "수정" : "등록"}
        </Button>
        <Button variant={"contained"} color={"error"} onClick={handleClose}>
          취소
        </Button>
      </DialogActions>

      <DetailModelSelectPopup
        open={showProductSelectPopup}
        handleClose={(detailModels) => {
          if (_.isNil(state)) return
          const prevDetailModels = state.detailModels ?? []
          const addedDetailModels = detailModels.map((detailModel) => ({ onDisplay: true, priority: 100, detailModel: detailModel }))
          const nextDetailModels = [...addedDetailModels, ...prevDetailModels]
          handleOnChangeState({ detailModels: nextDetailModels })
          setShowProductSelectPopup(false)
        }}
      />
    </Dialog>
  )
}

export default CurationEditorPopup
