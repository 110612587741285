import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import {CouponPointUsageHistorySearchType} from "../index";
import {CouponPointUsageHistorySearchParams} from "../../../../types/CouponPointUsageHistoryType";

const searchCouponPointUsageHistoryMenuList: { value: keyof CouponPointUsageHistorySearchParams; title: string }[] = [
  { value: "code", title: "코드" },
]

type Props = {
  searchParams: CouponPointUsageHistorySearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: keyof CouponPointUsageHistorySearchParams) => void
  onSearch: (keyword: CouponPointUsageHistorySearchType["keyword"]) => void
}

const CouponPointUsageHistoryListTableSearchBar = ({ searchParams, changeSearchKeyword, changeSearchFilterValue, onSearch }: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"code"}
          required
          onChange={(e) => changeSearchFilterValue(e.target.value as keyof CouponPointUsageHistorySearchParams)}
        >
          {searchCouponPointUsageHistoryMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        {searchParams.filter === "code" && (
          <SearchInput
            style={{ height: 40 }}
            value={searchParams.keyword}
            onChange={(e) => changeSearchKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(searchParams.keyword)
              }
            }}
          />
        )}
      </SearchBar>
    </Wrapper>
  )
}

export default CouponPointUsageHistoryListTableSearchBar

const Wrapper = styled.div`
  width: 500px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
