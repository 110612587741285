import {DatePicker} from "@mui/lab";
import {Stack, TextField} from "@mui/material";
import {DateTime} from "luxon";
import React, {useState} from "react";


type DateChangeEvent = {
    startDate: string,
    endDate: string
}

type DateRangePickerProps = {
    startDate?: string | null,
    endDate?: string | null,
    onChange?: (event: DateChangeEvent) => void
}

type State = {
    startDate: DateTime,
    endDate: DateTime
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({startDate, endDate, onChange}) => {
    const [state, setState] = useState<State>({
        startDate: startDate == null ? DateTime.now().minus({day: 7}) : DateTime.fromISO(startDate),
        endDate: endDate == null ? DateTime.now() : DateTime.fromISO(endDate)
    })


    return (
            <Stack direction={"row"} justifyContent={'flex-end'} spacing={1}>
                <DatePicker<DateTime>
                    label="시작 날짜"
                    value={state.startDate}
                    inputFormat={'yyyy.MM.dd'}
                    onChange={(newValue) => {
                       setState({
                           ...state,
                           startDate: newValue ?? DateTime.now()
                       })

                        if (onChange) {
                            onChange({
                                startDate: (newValue ?? state.startDate).toISODate(),
                                endDate: state.endDate.toISODate()
                            })
                        }
                    }}
                    renderInput={(params) => <TextField size={'small'} {...params} />}
                />
                <DatePicker<DateTime>
                    label="끝 날짜"
                    inputFormat={'yyyy.MM.dd'}
                    value={state.endDate}
                    onChange={(newValue) => {
                        setState({
                            ...state,
                            endDate : newValue ?? DateTime.now()
                        })

                        if (onChange) {
                            onChange({
                                startDate: state.startDate.toISODate(),
                                endDate: (newValue ?? state.endDate).toISODate()
                            })
                        }
                    }}
                    renderInput={(params) => <TextField size={'small'} {...params} />}
                />
            </Stack>
    )
}

export default DateRangePicker