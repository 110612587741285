import { PageType } from "types/PageType"
import {
  PostUpgradeServiceOrderBody,
  PutUpgradeServiceOrderBody,
  UpgradeServiceOrderDetailType,
  UpgradeServiceOrderListItemType,
  UpgradeServiceOrderListSearchParams,
} from "types/UpgradeServiceOrderType"

import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"

export const getUpgradeServiceOrderList: (
  params: UpgradeServiceOrderListSearchParams
) => Promise<PageType<UpgradeServiceOrderListItemType>> = (params) => httpGet(`/upgrade-service-orders`, params)

export const getUpgradeServiceOrderCountList: () => Promise<{ label: string; count: number }[]> = () =>
  httpGet(`/upgrade-service-orders/counts`)

export const getUpgradeServiceOrderDetail: (orderId: string) => Promise<UpgradeServiceOrderDetailType> = (orderId) =>
  httpGet(`/upgrade-service-orders/${orderId}`)

export const postUpgradeServiceOrder: (body: PostUpgradeServiceOrderBody) => Promise<UpgradeServiceOrderDetailType> = (
  body
) => httpPost(`/upgrade-service-orders`, body)

export const putUpgradeServiceOrder: (
  orderId: string,
  body: PutUpgradeServiceOrderBody
) => Promise<UpgradeServiceOrderDetailType> = (orderId, body) => httpPut(`/upgrade-service-orders/${orderId}`, body)

export const notifyUpgradeServiceToUser: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/upgrade-service-orders/${orderId}/notify`)

export const startUpgradeService: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/upgrade-service-orders/${orderId}/start-upgrade`)

export const finishUpgradeService: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/upgrade-service-orders/${orderId}/complete-upgrade`)

export const expireUpgradeService: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/upgrade-service-orders/${orderId}/expire`)

export const deleteUpgradeServiceOrderList: (orderIdList: string[]) => Promise<void> = (orderIdList) =>
  httpDelete(`/upgrade-service-orders`, { ids: orderIdList.join() })

export const deleteUpgradeServiceOrder: (orderId: string) => Promise<void> = (orderId) =>
  httpDelete(`/upgrade-service-orders/${orderId}`)
