import {Button, Grid, Paper} from "@mui/material"
import BrandTable from "./BrandTable"
import {useEffect, useReducer} from 'react'
import AddBrandPopup from "./AddBrandPopup";
import EditBrandPopup from "./EditBrandPopup";
import {deleteBrand, getBrands, hideBrand, showBrand} from "../../apis/brandAPI";
import {BrandType} from "types/BrandType";

interface BrandState {
    brands: BrandType[],
    selectBrandIds: string[],
    isShowAddPopup: boolean,
    isShowEditPopup: boolean,
    editBrand: BrandType | null,
    refresh: boolean
}

const initState: BrandState = {
    brands: [],
    selectBrandIds: [],
    isShowAddPopup: false,
    isShowEditPopup: false,
    editBrand: null,
    refresh: false
}

interface BrandAction {
    type: string,
    payload?: any
}

function reducer(state: BrandState, action: BrandAction): BrandState {
    switch (action.type) {
        case 'FETCH_BRANDS': return {...state, brands: action.payload}
        case 'SELECT_BRAND_IDS': return {...state, selectBrandIds: action.payload}
        case 'SHOW_ADD_BRAND_POPUP': return {...state, isShowAddPopup: true}
        case 'HIDE_ADD_BRAND_POPUP': return {...state,  isShowAddPopup: false}
        case 'SHOW_EDIT_BRAND_POPUP': return {...state,  isShowEditPopup: true, editBrand: action.payload}
        case 'HIDE_EDIT_BRAND_POPUP': return {...state,  isShowEditPopup: false}
        case 'REFRESH': return {...state, refresh: !state.refresh}
    }

    return state;
}

const Brand = () => {
    const [state, dispatch] = useReducer(reducer, initState)

    const fetchBrands = () => {getBrands({size: 100}).then(brands => dispatch({type: 'FETCH_BRANDS', payload: brands}))}

    useEffect(() => {
        fetchBrands()
    }, [state.refresh])

    const handleDeleteButtonClick = () => Promise.all(state.selectBrandIds.map((id) => deleteBrand(id))).then(() => fetchBrands())
    const handleHideButtonClick = () => Promise.all(state.selectBrandIds.map((id) => hideBrand(id))).then(() => fetchBrands())
    const handleShowButtonClick = () => Promise.all(state.selectBrandIds.map((id) => showBrand(id))).then(() => fetchBrands())
    const handleAddButtonClick = () => dispatch({type: 'SHOW_ADD_BRAND_POPUP'})

    return (
        <Grid container spacing={2}>
            <AddBrandPopup open={state.isShowAddPopup} handleClose={() => {
                dispatch({type: 'HIDE_ADD_BRAND_POPUP'})
                dispatch({type: 'REFRESH'})
            }}/>
            <EditBrandPopup open={state.isShowEditPopup} editBrand={state.editBrand as BrandType} handleClose={() => {
                dispatch({type: 'HIDE_EDIT_BRAND_POPUP'})
                dispatch({type: 'REFRESH'})
            }}/>

            <Grid item xs={12}>
                <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                        <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleShowButtonClick}>노출</Button></Grid>
                        <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleHideButtonClick}>숨김</Button></Grid>

                        <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleAddButtonClick}>추가</Button></Grid>
                        <Grid item xs={1}><Button variant={"contained"} size={"small"} color={"error"} fullWidth onClick={handleDeleteButtonClick} >삭제</Button></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <BrandTable
                            rows ={state.brands}
                            dispatch ={dispatch}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>)
}

export default Brand