import {Button, Divider, Stack} from "@mui/material";


// @ts-ignore
const BannerTableFilter = ({filter, handleFilterChange}) => {
    return (
        <Stack direction={'row'} justifyContent={'flex-start'}  divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            <Button onClick={ () => {handleFilterChange({...filter, postingStatus: null})} }>전체</Button>
            <Button onClick={ () => {handleFilterChange({...filter, postingStatus: '노출중'})} }>노출중</Button>
            <Button onClick={ () => {handleFilterChange({...filter, postingStatus: '노출 예정'})} }>노출 예정</Button>
            <Button onClick={ () => {handleFilterChange({...filter, postingStatus: '노출 종료'})} }>노출 종료</Button>
        </Stack>
    )
}

export default BannerTableFilter