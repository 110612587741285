import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

type Props = {
  keyword: string
  handleConfirm: () => void
  handleClose: () => void
}

export const SearchKeywordDeleteConfirmPopup = ({ keyword, handleConfirm, handleClose }: Props) => {
  return (
    <Dialog open maxWidth={"sm"}>
      <DialogTitle>키워드 삭제 확인</DialogTitle>
      <DialogContent>
        키워드 <b>`{keyword}`</b>를 삭제 하시겠습니까?
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"error"} onClick={handleConfirm}>
          삭제
        </Button>
        <Button variant={"contained"} color={"info"} onClick={handleClose}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}
