export enum ShowroomPageActionCode {
    CHANGE_TAB,
    FETCH_SHOWROOMS,
    FETCH_SHOWCASES,
    SELECTION_SHOWROOM_IDS,
    SELECTION_SHOWCASE_IDS,
    UPDATE_SHOWROOM,
    UPDATE_SHOWCASE,
    UPDATE_PRODUCT,

    OPEN_ADD_SHOWROOM_POPUP,
    CLOSE_ADD_SHOWROOM_POPUP,

    OPEN_EDIT_SHOWROOM_POPUP,
    CLOSE_EDIT_SHOWROOM_POPUP,

    OPEN_ADD_SHOWCASE_POPUP,
    CLOSE_ADD_SHOWCASE_POPUP,

    OPEN_EDIT_SHOWCASE_POPUP,
    CLOSE_EDIT_SHOWCASE_POPUP,
    EDIT_SHOWCASE,
}