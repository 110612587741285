import styled from "@emotion/styled"
import Button from "components/Button"
import { SerialNumberMemoListTable } from "./SerialNumberMemoListTable"
import { useSerialNumberMemo } from "./useSerialNumberMemo"

type Props = {
  serialNo: string
}

export const SerialNumberMemo = ({ serialNo }: Props) => {
  const { newMemo, changeNewMemo, changePageNumber, onRegisterNewMemo, memoList, paginationParams } =
    useSerialNumberMemo(serialNo)

  return (
    <Wrapper>
      <SerialNumberMemoListTable
        changePage={changePageNumber}
        paginationParams={paginationParams}
        memoList={memoList}
      />
      <MemoInputWrapper>
        <MemoInput
          placeholder="추가할 메모를 입력해주세요."
          value={newMemo}
          onChange={(e) => changeNewMemo(e.target.value)}
        />
        <MemoButtonWrapper>
          <Button disabled={!newMemo} style={{ height: "50px" }} onClick={onRegisterNewMemo}>
            추가
          </Button>
        </MemoButtonWrapper>
      </MemoInputWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MemoInputWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  margin-top: 16px;
`

const MemoInput = styled.input`
  outline: none;
  border: 1px solid #c5c5c5;
  flex: 1;
  padding: 16px 13px;
  border-radius: 4px;
`

const MemoButtonWrapper = styled.div`
  width: 87px;
`
