import { getProductDetailTextList } from "apis/productNotice"
import { useCallback, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { ProductDetailTextListItemType } from "types/ProductDetailTextListItemType"

export const useProductDetailTextList = () => {
  const [isNewFormOpend, setIsNewFormOpend] = useState<boolean>(false)
  const [productDetailTextList, setProductDetailTextList] = useState<ProductDetailTextListItemType[]>([])

  const fetchProductDetailTextList = useCallback(async () => {
    try {
      const result = await getProductDetailTextList({ page: 0 })
      setProductDetailTextList(result.content)
    } catch {
      toast.error("프로모션 텍스트 조회에 실패했습니다.")
      setProductDetailTextList([])
    }
  }, [])

  const isRegisterEnabled = useMemo(() => productDetailTextList.length < 5, [productDetailTextList])

  useMount(fetchProductDetailTextList)

  return {
    isRegisterEnabled,
    productDetailTextList,
    refreshList: fetchProductDetailTextList,
    isNewFormOpend,
    setIsNewFormOpend,
  }
}
