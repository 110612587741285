import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import {ShopServiceBannerType} from "types/ShopServiceBannerType";


export default {
    create: (serviceBanner: ShopServiceBannerType): Promise<ShopServiceBannerType> => httpPost("/shop/service-banner", serviceBanner),
    update: (id: number, serviceBanner: ShopServiceBannerType): Promise<ShopServiceBannerType> => httpPut(`/shop/service-banner/${id}`, serviceBanner),
    get: (id: number): Promise<ShopServiceBannerType> => httpGet(`/shop/service-banner/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/shop/service-banner/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<ShopServiceBannerType>> => httpGet("/shop/service-banner", params),
    getList: (params?:  { [key: string]: any }): Promise<ShopServiceBannerType[]> => httpGet("/shop/service-banner/list", params),
    reordering: (list: ShopServiceBannerType[]): Promise<ShopServiceBannerType[]> => httpPut(`/shop/service-banner/reordering`, list),
}