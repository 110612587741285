import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {DateTime} from "luxon";
import * as React from "react";
import {useEffect, useState} from "react";
import {HomeHeroBannerType} from "types/HomeHeroBannerType";
import HomeHeroBannerAPI from "../../../apis/homeHeroBannerAPI";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import _ from 'lodash'

function renderPreviewCell(params: any) {
    return (<Box style={{position: 'relative', height: 100, width: 50}}>
        <img src={params?.row?.backgroundImageUrl} style={{width: '100%', position: 'absolute'}} />
        <img src={params?.row?.foregroundImageUrl} style={{width: '100%', position: 'absolute', bottom: 0}} />
        {
            params?.row?.backgroundVideoUrl ?
                <Box style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,

                }}>
                    <video
                        autoPlay playsInline loop muted
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <source src={params.row.backgroundVideoUrl} />
                    </video>
                </Box>
                : <></>
        }
    </Box>)
}

function renderStatusCell(params: any) {
    if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
    const startedAt = DateTime.fromISO(params?.row?.startedAt)
    const endedAt = DateTime.fromISO(params?.row?.endedAt)

    return (
        <Stack direction={'column'}>
            <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
            <Box>~</Box>
            <Box>{endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
        </Stack>
    )
}


// @ts-ignore
const BannerCreatePopup = ({open, handleClose}) => {
    const [rows, setRows] = useState<HomeHeroBannerType[]>([])

    useEffect(() => {
        if (open) {
            const now = DateTime.now().toISO()
            HomeHeroBannerAPI.getList({onDisplay: true, endedAtGoe: now, sort: "priority,asc"}).then(result => setRows(result))
        }
    }, [open])

    const handleSubmit = () => {
        HomeHeroBannerAPI.reordering(rows).then(() => handleClose())
    }

    const getColumns = () => {
        return [
            {field: 'title', headerName: "제목", flex: 3},
            {field: 'createdAt', headerName: "등록 일시", width: 180, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
            {field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
            {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
            {field: 'postingStatus', headerName: "진행 상태", width: 100, renderCell: renderStatusCell},
            {field: 'actions', type: 'actions', headerName: "", width: 100, getActions: (params: any) => ([
                    <GridActionsCellItem
                        icon={<ArrowUpwardIcon/>}
                        label={"위로"}
                        onClick={() => {
                            const index = _.findIndex(rows, {id: params.row.id})
                            if (index > 0) {
                                setRows([
                                    ...(_.slice(rows, 0, index-1)),
                                    rows[index],
                                    rows[index-1],
                                    ...(_.slice(rows, index+1, rows.length)),
                                ])
                            }
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<ArrowDownwardIcon/>}
                        label={"아래로"}
                        onClick={() => {
                            const index = _.findIndex(rows, {id: params.row.id})
                            if (index < rows.length) {
                                setRows([
                                    ...(_.slice(rows, 0, index)),
                                    rows[index+1],
                                    rows[index],
                                    ...(_.slice(rows, index+2, rows.length)),
                                ])
                            }
                        }}
                    />
                ])},
        ]
    }

    return (
        <Dialog open={open} maxWidth={"lg"} fullWidth>
            <DialogTitle>배너 순서 변경</DialogTitle>
            <DialogContent >
                <Box height={700}>
                    <DataGrid
                        columns={getColumns()}
                        rows={rows}
                        getRowHeight={() => 100}
                        hideFooter={true}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BannerCreatePopup