import styled from "@emotion/styled"
import { isNil } from "lodash"
import { FunctionComponent } from "react"
import { toISO } from "utils/datetimeUtil"

export const NoticeDetailFormDateRange: FunctionComponent<{
  startedAt?: string | null
  endedAt?: string | null
  onChangeStartedAt: (startedAt: string | null) => void
  onChangeEndedAt: (endedAt: string | null) => void
}> = ({ startedAt, endedAt, onChangeStartedAt, onChangeEndedAt }) => {
  const [valueStartedAt] = !isNil(startedAt) ? startedAt.split(/(\+|Z)/) : []
  const [valueEndedAt] = !isNil(endedAt) ? endedAt.split(/(\+|Z)/) : []

  return (
    <div>
      <InputDateTime
        type="datetime-local" // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
        value={valueStartedAt} // YYYY-MM-DDThh:mm
        onChange={(event) => {
          const nextStartedAt = !isNil(event.target.value) ? toISO(event.target.value) : null
          onChangeStartedAt(nextStartedAt)
        }}
      />
      <span> ~ </span>
      <InputDateTime
        type="datetime-local" // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
        value={valueEndedAt} // YYYY-MM-DDThh:mm
        onChange={(event) => {
          const nextEndedAt = !isNil(event.target.value) ? toISO(event.target.value) : null
          onChangeEndedAt(nextEndedAt)
        }}
      />
    </div>
  )
}

const InputDateTime = styled.input`
  display: inline-block;
  height: 40px;
  width: 200px;
  border: 1px solid #eee;
  outline: none;
  border-radius: 4px;
  padding: 10px;
`
