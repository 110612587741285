import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import "../../App.css"
import {BuyOrderType} from "types/BuyOrderType"
import {getBuyOrder,} from "../../apis/buyOrderAPI"
import BuyOrder from "./ViewPopup/BuyOrder";
import Payment from "./ViewPopup/Payment";
import BottomButtons from "./ViewPopup/BottomButtons";
import {SaleTypeCode} from "types/ProductType";
import {getSellOrderByProductId} from "../../apis/sellOrderAPI";
import {SellOrderType} from "types/SellOrderType";
import IncomingShipping from "./ViewPopup/IncommingShipping";
import OutgoingShipping from "./ViewPopup/OutgoingShipping";
import Seller from "./ViewPopup/Seller";
import {UserMemoTargetTypeCode} from "../../types/UserType";
import QuickMemoButton from "../../components/QuickMemo/QuickMemoButton";

type EditBuyOrderPopupProps = {
  open: boolean
  selectedBuyOrderId?: string
  handleClose: Function
}

const ViewBuyOrderPopup: React.FC<EditBuyOrderPopupProps> = ({ open, selectedBuyOrderId, handleClose }) => {
  const [buyOrder, setBuyOrder] = useState<BuyOrderType>()
  const [sellOrder, setSellOrder] = useState<SellOrderType|undefined>({})
  const [refresh, setRefresh] = useState<Boolean>(false)

  useEffect(() => {
    if (open && selectedBuyOrderId) {
      getBuyOrder(selectedBuyOrderId!).then((order) => {
        setBuyOrder(order)
        getSellOrderByProductId(order.product!.id!).then((resp) => setSellOrder(resp))
      })
    } else {
      setBuyOrder({})
    }
  }, [open, refresh])

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <table className={"basic"}>
          <tbody>
          <tr>
            <td colSpan={3} className={"no-border ta-left"}>
              <Typography fontWeight={"bold"} fontSize={"30px"}>
                구매주문 정보
              </Typography>
            </td>
            <td className={"header"}>상품번호</td>
            <td className={"header"}>상품 상태</td>
            <td className={"header"}>배송 상태</td>
            <td className={"header"}>주문 상태</td>
          </tr>
          <tr>
            <td colSpan={3} className={"no-border"}/>
            <td>
              {buyOrder?.product?.id}
              <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={buyOrder?.product?.id} small />
            </td>
            <td>{buyOrder?.product?.exposedProductInfo?.productCondition}</td>
            <td>{buyOrder?.shipping?.shippingStatus}</td>
            <td className={"bold"}>{buyOrder?.buyOrderStatus}</td>
          </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        <BuyOrder buyOrder={buyOrder}/>
        <Payment payments={buyOrder?.payments}/>
        {
          // 진단전판매는 입고배송 정보 노출
          buyOrder?.product?.saleType == SaleTypeCode.진단전판매 &&
          <>
            <Seller sellOrder={sellOrder}/>
            <IncomingShipping sellOrder={sellOrder} setSellOrder={setSellOrder} handleClose={handleClose}/>
          </>
        }
        <OutgoingShipping buyOrder={buyOrder} setBuyOrder={setBuyOrder} setRefresh={setRefresh} handleClose={handleClose}/>

      </DialogContent>
      <DialogActions>
        {buyOrder && <BottomButtons buyOrder={buyOrder} setBuyOrder={setBuyOrder} handleClose={handleClose}/>}
      </DialogActions>
    </Dialog>
  )
}

export default ViewBuyOrderPopup