import styled from "@emotion/styled"
import { UpgradeServiceOrderStatus } from "types/UpgradeServiceOrderType"
import Button from "components/Button"

type Props = {
  upgradeServiceStatus: UpgradeServiceOrderStatus
  closeModal: () => void
  updateUpgradeServiceItemList: () => void
  isNotified: boolean
  notifyUpgradePaymentToUser: () => void
  requestFinishUpgradeService: () => void
  requestStartUpgradeService: () => void
  requestExpireUpgradeService: () => void
  requestDeleteUpgradeService: () => void
}
export const ModalButtonListByStatus = ({
  upgradeServiceStatus,
  isNotified,
  closeModal,
  updateUpgradeServiceItemList,
  notifyUpgradePaymentToUser,
  requestStartUpgradeService,
  requestFinishUpgradeService,
  requestExpireUpgradeService,
  requestDeleteUpgradeService,
}: Props) => {
  switch (upgradeServiceStatus) {
    case UpgradeServiceOrderStatus.등록완료:
      return (
        <RowWrapper style={{ justifyContent: "flex-end" }}>
          <ButtonWrapper
            style={{
              width: "47px",
            }}
          >
            <Button buttonStyle="red-fill" buttonSize="M" onClick={requestDeleteUpgradeService}>
              삭제
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button disabled={isNotified} onClick={updateUpgradeServiceItemList} buttonSize="M">
              변경내역 저장
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button buttonSize="M" buttonStyle="red-fill" disabled={isNotified} onClick={notifyUpgradePaymentToUser}>
              고객 결제 요청
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
              취소
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      )

    case UpgradeServiceOrderStatus.결제대기:
      return (
        <RowWrapper style={{ justifyContent: "flex-end" }}>
          <ButtonWrapper
            style={{
              width: "74px",
            }}
          >
            <Button buttonStyle="red-fill" buttonSize="M" onClick={requestExpireUpgradeService}>
              주문 만료
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button disabled buttonSize="M">
              수리 진행
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button buttonSize="M" disabled>
              수리 확정
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
              취소
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      )
    case UpgradeServiceOrderStatus.결제완료:
      return (
        <RowWrapper style={{ justifyContent: "flex-end" }}>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={requestStartUpgradeService} buttonSize="M">
              수리 진행
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button buttonSize="M" disabled>
              수리 확정
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
              취소
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      )

    case UpgradeServiceOrderStatus.수리중:
      return (
        <RowWrapper style={{ justifyContent: "flex-end" }}>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button disabled buttonSize="M">
              수리 진행
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={requestFinishUpgradeService} buttonSize="M">
              수리 확정
            </Button>
          </ButtonWrapper>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
              취소
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      )

    default:
      return (
        <RowWrapper style={{ justifyContent: "flex-end" }}>
          <ButtonWrapper
            style={{
              width: "100px",
            }}
          >
            <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
              닫기
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      )
  }
}

const ButtonWrapper = styled.div`
  width: 70px;

  :not(:first-child) {
    margin-left: 8px;
  }
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`
