import styled from "@emotion/styled"
import Button from "components/Button"
import CheckBox from "components/Checkbox"
import Modal from "components/Modal"
import { toDateTimeStr } from "utils/datetimeUtil"
import { SerialNumberMemo } from "./SerialNumberMemo"
import { useSerialNumberDetail } from "./useSerialNumberDetail"
import { isNotEmpty } from "../../../utils/validationUtils"
import { numberWithCommas } from "../../../utils/NumberUtils"
import UserIdButton from "../../UserIdButton"
import React from "react"
import { OrderType } from "../../../types/SellOrderType"

type Props = {
  serialNumber: string
  closeModal: () => void
}

export const SerialNumberDetailModal = ({ serialNumber, closeModal }: Props) => {
  const { serialNumberDetail, orderHistory, toggleBlackListFlag } = useSerialNumberDetail(serialNumber)

  console.log(orderHistory)
  return (
    <Modal isOpen closeModal={closeModal}>
      <ModalContentWrapper>
        <ModalBodyWrapper>
          <ModalTitle>시리얼 넘버 정보</ModalTitle>
          {!!serialNumberDetail ? (
            <>
              <SerialInfoTableWrapper>
                <table className={"basic"}>
                  <tbody>
                  <td className={"ta-center"} style={{ width: "180px", padding: 0 }} rowSpan={6}>
                    <ThumnailImage src={serialNumberDetail.detailModel.thumbnail} alt="thunmnail image" />
                  </td>
                  <tr>
                    <td className={"header"}>등록일</td>
                    <td colSpan={4}>{serialNumberDetail.createdAt && toDateTimeStr(serialNumberDetail.createdAt)}</td>
                  </tr>
                  <tr>
                    <td className={"header"}>시리얼 넘버</td>
                    <td colSpan={4}>{serialNumberDetail.serialNo}</td>
                  </tr>
                  <tr>
                    <td rowSpan={2} className={"header"}>
                      상품명
                    </td>
                    <td rowSpan={2} colSpan={1}>
                      {serialNumberDetail.detailModel.brand.name}
                    </td>
                    <td rowSpan={2} colSpan={2}>
                      {serialNumberDetail.detailModel.title}
                    </td>
                    <td rowSpan={2} colSpan={1}>
                      {serialNumberDetail.detailModel.titleSecondary}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td className={"header"}>Ref No.</td>
                    <td colSpan={4}>{serialNumberDetail.detailModel.fullRefNo}</td>
                  </tr>
                  </tbody>
                </table>
              </SerialInfoTableWrapper>

              <BlackListCheckWrapper>
                <CheckBox checked={serialNumberDetail?.blacklist} id="black-list" onChange={toggleBlackListFlag} />
                <CheckBoxLabel htmlFor="black-list" onChange={toggleBlackListFlag}>
                  블랙리스트
                </CheckBoxLabel>
              </BlackListCheckWrapper>

              <OrderHistoryTitle>시리얼번호({serialNumber}) : 바이버 내 거래이력</OrderHistoryTitle>
              <OrderHistoryWrapper>
                <table className={"basic"}>
                  <tbody>
                  <tr>
                    <td className={"header"} style={{ width: 100 }}>상품번호</td>
                    <td className={"header"} style={{ width: 100 }}>판매방식</td>
                    <td className={"header"} style={{ width: 100 }}>판매자ID</td>
                    <td className={"header"}>판매신청일</td>
                    <td className={"header"} style={{ width: 80 }}>구매자ID</td>
                    <td className={"header"}>결제완료일</td>
                    <td className={"header"} style={{ width: 100 }}>진단감정</td>
                    <td className={"header"} style={{ width: 140 }}>결제금액</td>
                  </tr>

                  {isNotEmpty(orderHistory) ?
                    orderHistory.map((order) => {
                      let diagnosisUrl = ""
                      if (order.sellOrderType === OrderType.진단전) {
                        // 비포 진단서
                        diagnosisUrl = `/diagnoses/before-diagnosis?productId=${order.productId}`
                      }
                      else {
                        // 애프터 진단서
                        diagnosisUrl = `/all-products?productId=${order.productId}`
                      }
                      return <tr>
                        <td>
                          <a
                            href={diagnosisUrl}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            {order.productId}
                          </a>
                        </td>
                        <td>{order.sellOrderType}</td>
                        <td><UserIdButton id={order.sellerId} /></td>
                        <td>{toDateTimeStr(order.acceptedAt)}</td>
                        <td><UserIdButton id={order.buyerId} /></td>
                        <td>{toDateTimeStr(order.transactionAt)}</td>
                        <td>{order.diagnosisTypeCode}</td>
                        <td style={{ textAlign: "end" }}>{numberWithCommas(order.paymentAmount)}</td>
                      </tr>
                    }) :
                    <tr>
                      <td colSpan={8}>없음</td>
                    </tr>
                  }

                  </tbody>
                </table>
              </OrderHistoryWrapper>
              <SerialNumberMemoTitle>시리얼 넘버 메모</SerialNumberMemoTitle>
              <SerialNumberMemo serialNo={serialNumberDetail.serialNo} />
            </>
          ) : (
            <EmptyScreen>등록된 정보가 없습니다.</EmptyScreen>
          )}
        </ModalBodyWrapper>
        <ModalFooterWrapper>
          <CloseButtonWrapper>
            <Button onClick={closeModal}>확인</Button>
          </CloseButtonWrapper>
        </ModalFooterWrapper>
      </ModalContentWrapper>
    </Modal>
  )
}

const ModalContentWrapper = styled.div`
    width: 1080px;
`
const ModalBodyWrapper = styled.div`
    padding: 18px 33px;
    height: 800px;
    overflow-y: scroll;
`

const ModalFooterWrapper = styled.div`
    width: 100%;
    padding: 8px 6px;
    display: inline-flex;
    justify-content: flex-end;
`

const CloseButtonWrapper = styled.div`
    width: 60px;
`

const ModalTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
`

const SerialInfoTableWrapper = styled.div`
    width: 100%;
    margin-top: 31px;

    td {
        padding: 9px 20px;
        text-align: left;
    }
`

const BlackListCheckWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
`

const OrderHistoryWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
`

const CheckBoxLabel = styled.label`
    margin-left: 10px;
    font-size: 15px;
`

const SerialNumberMemoTitle = styled.div`
    font-size: 17px;
    font-weight: 700;
    margin: 37px 0 14px 0;
`

const OrderHistoryTitle = styled.div`
    flex: 1;
    justify-content: flex-start;
    align-content: flex-start;
    font-size: 17px;
    font-weight: 700;
    margin: 37px 0 14px 0;
`

const ThumnailImage = styled.img`
    width: 180px;
    height: 180px;
`

const EmptyScreen = styled.div`
    flex: 1;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
`
