import styled from "@emotion/styled"
import { TermCategoryBar } from "./TermCategoryBar"
import { TermEditor } from "./TermEditor"
import { TermPreview } from "./TermPreview"
import { useTermData } from "./useTermData"
import { TermListSelect } from "./TermListSelect"

export const TermListPage = () => {
  const {
    selectedType,
    selectType,
    termDetailState,
    changeTermDatail,
    isSavable,
    handleSaveTerm,
    handleDeleteTerm,
    termList,
    selectedTermDetail,
    selectTerm,
    markdownState,
    onChangeMakdownState,
  } = useTermData()

  return (
    <PageWrapper>
      <InlineWrapper>
        <TermCategoryBar selectType={selectType} selectedType={selectedType} />

        <TermListSelect termList={termList} selectedTerm={selectedTermDetail} selectTerm={selectTerm} />
      </InlineWrapper>
      <ContentWrapper>
        <TermEditor
          termDetail={termDetailState}
          changeTermDetail={changeTermDatail}
          changeMarkdownState={onChangeMakdownState}
        />
        <div style={{ width: "24px", flexShrink: 0 }} />
        <TermPreview
          isNewTerm={!selectedTermDetail}
          markdownState={markdownState}
          isSavable={isSavable}
          handleSaveTerm={handleSaveTerm}
          handleDeleteTerm={handleDeleteTerm}
        />
      </ContentWrapper>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
`

const ContentWrapper = styled.div`
  display: inline-flex;
  margin-top: 24px;
  align-items: flex-start;
`

const InlineWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`
