import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const ListTableHeader = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

export default ListTableHeader

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 10px 0;
`
