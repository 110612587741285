import {DataGrid, GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import {DateTime} from "luxon";
import {IconButton, Switch, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useEffect, useState} from "react";
import {ShowroomType} from "types/ShowroomType";
import {ShowroomPageActionCode} from "../showroomPageActionCode";
import {deleteShowroom, getShowrooms, hideShowroom, showShowroom} from "../../../apis/showroomAPI";
import {ShowroomState} from "../index";

function createColumns(handleEditButtonClick?: Function|null, handleDeleteButtonClick?: Function|null, dispatch?: Function): any[] {
    return [
        {
            field: 'actions',
            type: 'actions',
            width: 30,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon = {<EditIcon />}
                    label = {"수정"}
                    onClick={() => handleEditButtonClick?.(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon = {<DeleteIcon />}
                    label = {"삭제"}
                    onClick={() => handleDeleteButtonClick?.(params.row)}
                    showInMenu
                />
            ]
        },
        {
            field: 'onDisplay',
            headerName: "노출상태",
            editable: false,
            width: 70,
            renderCell: (params: any) => {
                return <Switch
                    checked={params.row.onDisplay}
                    onChange={(_event, checked) => {
                        (checked ? showShowroom(params.id as number) : hideShowroom(params.id as number)).then(() => {
                            dispatch?.({type: ShowroomPageActionCode.UPDATE_SHOWROOM, payload: {...params.row, onDisplay: checked}})
                        })
                    }}
                />
            }
        },
        {
            field: 'name',
            headerName: "쇼룸명",
            editable: false,
            minWidth: 300,
            flex: 0.5,
            // @ts-ignore
            renderCell: (params) => {
                return (
                    <Typography style={{cursor: "pointer"}} onClick={() => {
                        // @ts-ignore
                        dispatch({type: ShowroomPageActionCode.SELECTION_SHOWROOM_IDS, payload: [params.row.id]})
                        setTimeout(() => {
                            // @ts-ignore
                            dispatch({type: ShowroomPageActionCode.CHANGE_TAB, payload: "showcase"})
                        },150)
                    }}>{params.row.name}</Typography>
                )
            }
        },
        {
            field: 'address',
            headerName: "주소",
            editable: false,
            minWidth: 100,
            flex: 0.5
        },
        {
            field: 'phone',
            headerName: "전화번호",
            editable: false,
            width: 120,
        },
        {
            field: 'showcaseCount',
            headerName: "전시 쇼케이스수",
            editable: false,
            type: "number",
            width: 105,
        },
        {
            field: 'detailModelCount',
            headerName: "전시 상세 모델수",
            editable: false,
            type: "number",
            width: 115,
        },
        {
            field: 'createdBy',
            headerName: "생성자",
            editable: false,
            valueGetter: (params: any) => `${params.row.createdBy.name}`,
            width: 100,
        },
        {
            field: 'createdAt',
            headerName: "생성일",
            editable: false,
            width: 100,
            valueFormatter: (params: any) => `${DateTime.fromISO(params.value).toISODate()}`
        },
        {
            field: 'updatedBy',
            headerName: "수정자",
            editable: false,
            valueGetter: (params: any) => `${params.row.updatedBy.name}`,
            width: 100
        },
        {
            field: 'updatedAt',
            headerName: "수정일",
            editable: false,
            width: 100,
            valueFormatter: (params: any) => `${DateTime.fromISO(params.value).toISODate()}`
        }
    ]
}

type ShowroomTabTableProps = {
    state: ShowroomState
    dispatch: any
}

const ShowroomTabTable:React.FC<ShowroomTabTableProps> = ({state, dispatch}) => {
    const handleSelection = (ids: any[]) => {dispatch({type: ShowroomPageActionCode.SELECTION_SHOWROOM_IDS, payload: ids as number[]})}

    function handleEdit(showroom: ShowroomType) {
        dispatch({type: ShowroomPageActionCode.OPEN_EDIT_SHOWROOM_POPUP, payload: showroom})
    }

    function handleDelete(showroom: ShowroomType) {
        deleteShowroom(showroom.id as number).then(() => {
            getShowrooms().then((results) => dispatch({type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: results}))
        })
    }

    return (
        <div style={{height: 500, width: '100%'}}>
        <DataGrid
            columns={createColumns(handleEdit, handleDelete, dispatch)}
            rows={state.showrooms}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick={true}
            selectionModel={state.selectionShowroomIds}
            onSelectionModelChange={handleSelection}
        />
        </div>
    )
}

export default ShowroomTabTable