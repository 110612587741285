import { Switch } from "@mui/material"
import { BoxHorizontal, BoxVertical, Typography } from "@viver-inc/viver-ui"
import { upload, uploadFile } from "apis/resourceAPI"
import React, { FunctionComponent } from "react"
import { EventFormStateTemplateBodyItemHeaderImageType } from "types/EventFormStateType"
import ImageUploadButton from "../../../../components/ImageUploadButton"
import styled from "@emotion/styled"

export const TemplateEditorHeaderImage: FunctionComponent<{
  templateBodyHeaderImage: EventFormStateTemplateBodyItemHeaderImageType
  setTemplateBodyHeaderImage: (templateBodyHeaderImage: EventFormStateTemplateBodyItemHeaderImageType) => void
}> = ({ templateBodyHeaderImage, setTemplateBodyHeaderImage }) => {
  return (
    <BoxVertical borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10}  bottom={10}>
      <Typography variant="sub18" weight="sb">
        헤더이미지
      </Typography>
      <BoxVertical >
        <BoxHorizontal alignItems="center">
          <Switch
            checked={templateBodyHeaderImage.onDisplay}
            onChange={(e, check) => {
              setTemplateBodyHeaderImage({ ...templateBodyHeaderImage, onDisplay: check })
            }}
          />
          <Typography variant="desc14" weight="r">
            노출
          </Typography>
        </BoxHorizontal>
        <BoxVertical flex={1} paddingHorizontal={10}>
          <TemplateEditorHeaderImageItem
            imageUrl={templateBodyHeaderImage.header.imageUrl!!}
            imageUrlEnglish={templateBodyHeaderImage.header.imageUrlEnglish!!}
            imageUrlJapanese={templateBodyHeaderImage.header.imageUrlJapanese!!}
            setImageUrl={(imageUrl) =>
              setTemplateBodyHeaderImage({
                ...templateBodyHeaderImage,
                header: { ...templateBodyHeaderImage.header, imageUrl },
              })
            }
            setImageUrlEnglish={(imageUrlEnglish) =>
              setTemplateBodyHeaderImage({
                ...templateBodyHeaderImage,
                header: { ...templateBodyHeaderImage.header, imageUrlEnglish },
              })
            }
            setImageUrlJapanese={(imageUrlJapanese) =>
              setTemplateBodyHeaderImage({
                ...templateBodyHeaderImage,
                header: { ...templateBodyHeaderImage.header, imageUrlJapanese },
              })
            }
          />
        </BoxVertical>
      </BoxVertical>
    </BoxVertical>
  )
}

const TemplateEditorHeaderImageItem: FunctionComponent<{
  imageUrl: string | null
  imageUrlEnglish: string | null
  imageUrlJapanese: string | null
  setImageUrl: (imageUrl: string | null) => void
  setImageUrlEnglish: (imageUrl: string | null) => void
  setImageUrlJapanese: (imageUrl: string | null) => void
}> = ({ imageUrl, imageUrlEnglish, imageUrlJapanese, setImageUrl, setImageUrlEnglish, setImageUrlJapanese }) => {

  const handleOpenedImage = (image:any) => {
    upload(image,[{ key: "path", value: "resources/event" }]).then((result) => {
      setImageUrl(result.url)
    })
  }

  const handleOpenedImageEnglish = (image:any) => {
    upload(image,[{ key: "path", value: "resources/event" }]).then((result) => {
      setImageUrlEnglish(result.url)
    })
  }

  const handleOpenedImagelJapanese = (image:any) => {
    upload(image,[{ key: "path", value: "resources/event" }]).then((result) => {
      setImageUrlJapanese(result.url)
    })
  }

  return (
    <BoxVertical flex={1} paddingHorizontal={10} paddingVertical={10} >
      <ImageItemWrapper >
        <BoxHorizontal paddingVertical={10} paddingHorizontal={10}>
          <ImageWrapper>
            <BoxVertical alignItems={"center"}>
              <Typography variant="desc16" weight="m" marginBottom={10}>
                한국어
              </Typography>
              <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageUrl} handleImage={handleOpenedImage}  handleDeleteImage={()=>{setImageUrl(null)}} />
            </BoxVertical>
          </ImageWrapper>
          <ImageWrapper>
            <BoxVertical alignItems={"center"}>
              <Typography variant="desc16" weight="m" marginBottom={10}>
                영어
              </Typography>
              <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageUrlEnglish} handleImage={handleOpenedImageEnglish}  handleDeleteImage={()=>{setImageUrlEnglish(null)}} />
            </BoxVertical>
          </ImageWrapper>
          <ImageWrapper>
            <BoxVertical alignItems={"center"}>
              <Typography variant="desc16" weight="m" marginBottom={10}>
                일본어
              </Typography>
              <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageUrlJapanese} handleImage={handleOpenedImagelJapanese}  handleDeleteImage={()=>{setImageUrlJapanese(null)}} />
            </BoxVertical>
          </ImageWrapper>
        </BoxHorizontal>
      </ImageItemWrapper>
    </BoxVertical>
  )
}

const ImageItemWrapper = styled.div`
    margin-bottom: 10px; 
    background-color: #eeeeee;
`
const ImageWrapper = styled.div`
    margin-top: 5px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
    
`