import BrandLogoTable from "./BrandLogoTable";
import BrandLogoToolbar from "./BrandLogoToolbar";
import {Stack} from "@mui/material";
import BrandLogoCreatePopup from "./BrandLogoCreatePopup";
import {useState} from "react";
import BrandLogoUpdatePopup from "./BrandLogoUpdatePopup";
import DeleteConfirmPopup from "../../../components/Popup/DeleteConfirmPopup";
import ShopBrandLogoAPI from "../../../apis/shopBrandLogoAPI";
import { ShopBrandLogoType } from "../../../types/ShopBrandLogoType"

const BrandLogoTab = () => {
    const [showCreateBrandLogo, setShowCreateBrandLogo] = useState(false)
    const [updateBrandLogo, setUpdateBrandLogo] = useState<ShopBrandLogoType | null>(null)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectionIds, setSelectionIds] = useState<number[]>([])
    const [refreshCounter, setRefreshCounter] = useState(0)

    const refresh = () => setRefreshCounter(refreshCounter + 1)

    return (
        <Stack direction={"column"} spacing={1}>
            <BrandLogoToolbar handleCreateBrandLogo={() => setShowCreateBrandLogo(true)}
                                  handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
                                  disabledDeleteButton={ selectionIds.length == 0}
            />
            <BrandLogoTable
                handleSelection={(ids: number[]) => { setSelectionIds(ids) }}
                handleUpdateBrandLogo={(BrandLogo: ShopBrandLogoType) => setUpdateBrandLogo(BrandLogo)}
                refreshCounter={refreshCounter}
            />
            <BrandLogoCreatePopup open={showCreateBrandLogo} handleClose={() => {
                refresh()
                setShowCreateBrandLogo(false)
            }}/>
            <BrandLogoUpdatePopup BrandLogo={updateBrandLogo} handleClose={() => {
                refresh()
                setUpdateBrandLogo(null)
            }}/>
            <DeleteConfirmPopup open={showDeletePopup} handleClose={() => {
                refresh()
                setShowDeletePopup(false)
            }} handleConfirm={() => {
                Promise.all(selectionIds.map((id) => ShopBrandLogoAPI.delete(id)))
                    .then((results) => {
                        refresh()
                        setShowDeletePopup(false)
                    })
            }}/>
        </Stack>
    )
}

export default BrandLogoTab