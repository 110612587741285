import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

type Props = { title?: string; width?: string; titleWidth?: string } & HTMLAttributes<HTMLDivElement>

const DetailTableColumCell = ({ title, width = "100%", children, ...props }: PropsWithChildren<Props>) => {
  return (
    <Wrapper className="table-column-cell" width={width} {...props}>
      {title && <TitleWrapper>{title}</TitleWrapper>}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  )
}

export default DetailTableColumCell

const Wrapper = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #eee;
  font-weight: 700;
  font-size: 16px;
  padding: 10px;
`

const ChildrenWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 10px;
  font-size: 16px;
`
