import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const ListTableRowItem = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

export default ListTableRowItem

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex-shrink: 0;
`
