import styled from "@emotion/styled"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { ShippingStatusText } from "pages/Penalty/PenaltyListPage/PenaltyListTable/TableBody/TableRow"
import { CancelShippingCode } from "types/PenaltyType"
import { ShippingType } from "types/ShippingType"

type Props = {
  shipping: ShippingType
  cancelShippingStatus?: CancelShippingCode | string
}

const CancelShippingInformation = ({ shipping, cancelShippingStatus }: Props) => {
  return (
    <TableWrapper>
      <InlineWrapper>
        <DetailTableColumCell title="배송상태">
          <ShippingStatusText shippingStatus={cancelShippingStatus}>{cancelShippingStatus || "-"}</ShippingStatusText>
        </DetailTableColumCell>
        <DetailTableColumCell title="이름">{shipping?.name}</DetailTableColumCell>
        <DetailTableColumCell title="연락처">{shipping?.phone}</DetailTableColumCell>
        <DetailTableColumCell title="배송주소">{`${shipping.address} ${
          shipping.addressDetail || ""
        }`}</DetailTableColumCell>
      </InlineWrapper>
    </TableWrapper>
  )
}

export default CancelShippingInformation

const TableWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  div.table-column-cell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`
