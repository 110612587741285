import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, Grid,
  Switch,
  TextField,
} from "@mui/material"
import { NewsLetterType } from "../../types/NewsLetterType"
import { DatePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { validateUrl } from "../../utils/validationUtils"
import { toast } from "react-toastify"
import { createNewsLetter, updateNewsLetter } from "../../apis/newsletterAPI"

type NewsLetterManageModalProps = {
  visible:boolean,
  onRequestClose:()=>void
  refresh:()=>void
  selectedItem:NewsLetterType | null
}
export const NewsLetterManagePopup:React.FC<NewsLetterManageModalProps> = (props)=>{
  const [url, setUrl] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [publishDate, setPublishDate] = useState<string>('')
  const [onDisplay, setOnDisplay] = useState(false)

  useEffect(()=>{
    if(props.visible){
      if(props.selectedItem !== null){
        setUrl(props.selectedItem.url)
        setTitle(props.selectedItem.title)
        setPublishDate(props.selectedItem.publishDate)
        setOnDisplay(props.selectedItem.onDisplay)
      } else {
        setUrl('')
        setTitle('')
        setPublishDate(DateTime.now().toFormat('yyyy-MM-dd'))
        setOnDisplay(true)
      }
    }
  }, [props.selectedItem, props.visible])

  const onPressSave = useCallback(()=>{
    //validateUrl
    const isValidUrl = validateUrl(url);
    if(!isValidUrl) {
      toast.error('URL을 확인해보세요.')
      return
    }

    if(title.length === 0){

      toast.error('제목이 입력되지 않았습니다.')
      return;
    }

    if(props.selectedItem !== null){
      updateNewsLetter(props.selectedItem.id, title, url, publishDate, onDisplay)
        .then(()=>{
          toast.success('뉴스 수정에 성공했습니다.')
          props.refresh()
          props.onRequestClose()
        })
      return;
    }
    createNewsLetter(title, url, publishDate).then(()=>{
      toast.success('뉴스 생성에 성공했습니다.')
      props.refresh()
      props.onRequestClose()
    })

  }, [props, publishDate, title, url])
  return (
    <Dialog open={props.visible} maxWidth={"sm"} fullWidth>
      <DialogTitle style={{ alignItems:'center', justifyContent:'space-between'}}>
        {props.selectedItem === null ? '뉴스 추가하기' : '뉴스 수정하기'}
      </DialogTitle>
      <DialogContent>

        <Grid item xs={12}>
          <TextField
            required
            value={url}
            label={'URL'}
            fullWidth
            margin={"dense"}
            placeholder={'URL을 입력해주세요'}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            value={title}
            label={'기사 제목'}
            fullWidth
            margin={"dense"}
            placeholder={'기사제목을 입력해주세요'}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} marginTop={1}>
            <DatePicker
              onChange={(e) => {
                if(e !== null)
                  setPublishDate(e.toFormat('yyyy-MM-dd'))
              }}
              label={'노출일'}
              value={DateTime.fromISO(publishDate)}
              inputFormat={"yyyy-MM-dd"}
              renderInput={(props) => <TextField size={"medium"} {...props} />}
            />
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <FormControlLabel
            control={
              <Switch
                checked={onDisplay}
                onChange={(params) => setOnDisplay(params.target.checked)}
              />
            }
            label={'노출여부'}
          />
        </Grid>
      </DialogContent>
      <DialogActions >
        <Button color={"primary"} onClick={onPressSave}>
          {props.selectedItem !== null ? '수정하기':'저장하기'}
        </Button>
        <Button color={"error"} onClick={props.onRequestClose}>
          닫기
        </Button>
      </DialogActions>

    </Dialog>
  )

}
