import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ShopCurationType, ShopCurationProductType, ShopCurationBrandType } from "types/ShopCurationType"
import { Box, Button, Input, Stack } from "@mui/material"
import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import styled from "@emotion/styled"
import { DateTime } from "luxon"
import EditIcon from "@mui/icons-material/Edit"

type Props = {
  brands: ShopCurationBrandType[]
  handleOnChangeState: (partialState: Partial<ShopCurationType>) => void
  openShowBrandModelSelectPopup: () => void
  setEditableBrandId: Dispatch<SetStateAction<number | null>>
}

function renderStatusCell(params: any) {
  if (params?.row?.postingStatus === "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
  const startedAt = DateTime.fromISO(params?.row?.startedAt)
  const endedAt = DateTime.fromISO(params?.row?.endedAt)

  return (
    <Stack direction={"column"} alignItems={"self-end"}>
      <Box>{startedAt.toFormat("yyyy-MM-dd HH:mm:ss")}</Box>
      <Box>~ {endedAt.toFormat("yyyy-MM-dd HH:mm:ss")}</Box>
    </Stack>
  )
}

const CurationBrandTable = ({
                              brands,
                              handleOnChangeState,
                              openShowBrandModelSelectPopup,
                              setEditableBrandId,
                            }: Props) => {
  const [selectionCurationModelIds, setSelectionCurationModelIds] = useState<number[]>([])

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        field: "edit",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={"수정"}
            onClick={() => {
              openShowBrandModelSelectPopup()
              setEditableBrandId(params.row.id)
            }}
            showInMenu
          />,
        ],
      },
      {
        field: "brand.title",
        headerName: "브랜드명",
        width: 200,
        flex: 1,
        valueGetter: (params: any) => `${params.row.title}`,
      },
      {
        field: "modelCount",
        headerName: "모델 수",
        width: 80,
        valueGetter: (params: any) => {
          return params.row.models?.length ?? 0
        },
      },
      { field: "postingPeriod", headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell },
      { field: "postingStatus", headerName: "진행 상태", width: 80, renderCell: renderStatusCell },
      {
        field: "brand.bannerImageUrl",
        headerName: "썸네일",
        width: 100,
        renderCell: (params: any) => <img src={params.row.bannerImageUrl} style={{ height: "100%" }} alt={""} />,
      },
    ]

    return [
      ...defaultColumns,
      { field: "priority", headerName: "순서(수동입력)", width: 100, editable: true, type: "number" },
      {
        field: "actions",
        type: "actions",
        headerName: "순서(위치이동)",
        width: 120,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<ArrowUpwardIcon />}
            label={"위로"}
            onClick={() => {
              if (!brands.length) return

              const index = brands.findIndex((brand) => brand && brand.id === params.row.id)
              if (index <= 0) return

              let nextProducts = [
                ...brands.slice(0, index - 1),
                brands[index],
                brands[index - 1],
                ...brands.slice(index + 1),
              ]

              nextProducts = nextProducts.map(data => ({
                ...data,
                priority: null,
              }))

              handleOnChangeState({ brands: nextProducts })
            }}
          />,
          <GridActionsCellItem
            icon={<ArrowDownwardIcon />}
            label={"아래로"}
            onClick={() => {
              if (!brands.length) return

              const index = brands.findIndex((brand) => brand && brand.id === params.row.id)
              if (index === brands.length - 1) return

              let nextProducts = [
                ...brands.slice(0, index),
                brands[index + 1],
                brands[index],
                ...brands.slice(index + 2),
              ]

              nextProducts = nextProducts.map(data => ({
                ...data,
                priority: null,
              }))

              handleOnChangeState({ brands: nextProducts })
            }}
          />,
        ],
      },
    ]
  }, [handleOnChangeState, brands])

  return (
    <Box>
      <Stack height={"100%"}>
        <ButtonWrapper>
          <Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={openShowBrandModelSelectPopup}
            style={{ marginRight: 10 }}
          >
            추가
          </Button>
          <Button
            variant={"contained"}
            color={"error"}
            size={"small"}
            disabled={selectionCurationModelIds.length < 1}
            onClick={() => {
              if (!brands.length) return

              const nextProducts = brands.filter(
                (brand) => brand && !selectionCurationModelIds.includes(brand.id as number),
              )

              handleOnChangeState({ brands: nextProducts })
            }}
          >
            삭제
          </Button>
        </ButtonWrapper>
      </Stack>
      <DataGrid
        getRowId={(row: any) => row.id}
        style={{
          height: "500px",
        }}
        columns={columns}
        onSelectionModelChange={(ids) => {
          setSelectionCurationModelIds(ids as number[])
        }}
        disableSelectionOnClick
        checkboxSelection={true}
        getRowHeight={() => 80}
        rows={brands ?? []}
        hideFooter
        onCellEditCommit={(params) => {
          const index = brands.findIndex((brand) => {
            return brand && brand.id === params.id
          })
          if (index < 0) return

          const updatedBrands = [...brands]
          updatedBrands[index] = {
            ...updatedBrands[index],
            priority: params.value,
          }

          handleOnChangeState({ brands: updatedBrands })
        }}
      />


    </Box>
  )
}

export default CurationBrandTable

const ButtonWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
`
