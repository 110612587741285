import styled from "@emotion/styled"
import { Pagination, Switch } from "@mui/material"
import { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"

import ListTable from "components/Table/List"
import ListTableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { ModelListItemType } from "types/ModelListItemType"
import { isNotNil } from "utils/validationUtils"

const modelListTableHeaderItemList: { title: string; width?: string }[] = [
  { title: "ID", width: "80px" },
  { title: "대표사진", width: "120px" },
  { title: "모델명" },
  { title: "노출", width: "80px" },
  { title: "", width: "80px" },
]

export const ModelManagerModelListTable: FunctionComponent<{
  modelListParams: {
    page: number
    brandSid: string
    name: string
  }
  modelList: {
    content: ModelListItemType[]
    totalPages: number
  }
  onChangePage: (page: number) => void
  onClickUpdateModel: (model: ModelListItemType) => void
  onChangeDisplay: (model: ModelListItemType) => void
}> = ({ modelListParams, modelList, onChangePage, onClickUpdateModel, onChangeDisplay }) => {
  const navigate = useNavigate()

  return (
    <ListTable>
      <ListTableHeader>
        {modelListTableHeaderItemList.map(({ title, width }, index) => (
          <ListTableHeaderItem key={index} style={{ maxWidth: width }}>
            {title}
          </ListTableHeaderItem>
        ))}
      </ListTableHeader>
      <ListTableBody>
        {modelList.content.length < 1 ? (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        ) : (
          modelList.content.map((model) => (
            <TableRow
              key={model.id}
              style={{ height: 48, cursor: "pointer" }}
              onClick={() => {
                const urlSearchParams = new URLSearchParams()
                urlSearchParams.set("brandSid", modelListParams.brandSid)
                navigate({
                  pathname: `/model-manager/model-db/${model.id}`,
                  search: urlSearchParams.toString(),
                })
              }}
            >
              <TableRowItem style={{ maxWidth: modelListTableHeaderItemList[0].width }}>{model.id}</TableRowItem>
              <TableRowItem style={{ maxWidth: modelListTableHeaderItemList[1].width, height: 48, overflow: "hidden" }}>
                {isNotNil(model.thumbnail) && <img style={{ width: 104 }} src={model.thumbnail} alt={model.name} />}
              </TableRowItem>
              <TableRowItem style={{ maxWidth: modelListTableHeaderItemList[2].width }}>{model.name}</TableRowItem>
              <TableRowItem
                style={{ maxWidth: modelListTableHeaderItemList[3].width }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <Switch checked={model.onDisplay} onChange={() => onChangeDisplay(model)} />
              </TableRowItem>
              <TableRowItem
                style={{
                  maxWidth: modelListTableHeaderItemList[4].width,
                  justifyContent: "center",
                  color: "#159AAC",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  onClickUpdateModel(model)
                }}
              >
                수정
              </TableRowItem>
            </TableRow>
          ))
        )}
      </ListTableBody>
      <ListTableFooter>
        <Pagination
          count={modelList.totalPages}
          page={modelListParams.page}
          onChange={(_, page) => {
            onChangePage(page)
          }}
        />
      </ListTableFooter>
    </ListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
