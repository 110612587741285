import { useState } from "react"
import { EventType } from "types/EventType"
import { PaginationParamsType } from "types/PageType"
import EventListTable from "pages/Event/ListPage/EventListTable"
import styled from "@emotion/styled"
import { getEventList } from "apis/eventAPI"
import Button from "components/Button"
import { EventEditorPopup } from "../EventEditorPopup"
import { useMount } from "react-use"

const EVENT_LIST_SIZE = 20

const initialPaginationParams = {
  number: 0,
  totalElements: 0,
  totalPages: 1,
  first: true,
  size: EVENT_LIST_SIZE,
}

export const EventListPage = () => {
  const [isOpenEventEditorPopup, setIsOpenEventEditorPopup] = useState<boolean>(false)
  const [eventList, setEventList] = useState<EventType[]>([])
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>()
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>(initialPaginationParams)

  const httpGetEventList = async (page: number) => {
    getEventList({ page }).then((eventList) => {
      const { content, ...paginationParams } = eventList
      setEventList(content)
      setPaginationParams({
        ...paginationParams,
        number: paginationParams.number ?? initialPaginationParams.number,
        totalElements: paginationParams.totalElements ?? initialPaginationParams.totalElements,
        totalPages: paginationParams.totalPages ?? initialPaginationParams.totalPages,
        size: paginationParams.size ?? initialPaginationParams.size,
      })
    })
  }

  const changePage = (page: number) => {
    httpGetEventList(page)
  }

  const openEventEditorPopup = () => {
    setIsOpenEventEditorPopup(true)
  }

  const closeEventEditorPopup = () => {
    httpGetEventList(paginationParams.number)
    setSelectedEventId(undefined)
    setIsOpenEventEditorPopup(false)
  }

  useMount(() => {
    httpGetEventList(paginationParams.number)
  })

  return (
    <>
      <EventEditorPopup
        isOpen={isOpenEventEditorPopup}
        selectedEventId={selectedEventId}
        closePopup={closeEventEditorPopup}
      />
      <PageHeader>
        <ButtonWrapper>
          <Button onClick={openEventEditorPopup}>이벤트 추가</Button>
        </ButtonWrapper>
      </PageHeader>
      <TableWrapper>
        <EventListTable
          paginationParams={paginationParams}
          eventList={eventList}
          changePage={changePage}
          selectEvent={(eventId) => {
            setSelectedEventId(eventId)
            openEventEditorPopup()
          }}
        />
      </TableWrapper>
    </>
  )
}

const TableWrapper = styled.div`
  flex: 1;
`

const PageHeader = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: inline-flex;
  margin: 20px 0;
`

const ButtonWrapper = styled.div`
  width: 200px;
`
