import { ThemeModeTypeCode } from "./ShopHeroBannerType"

export type MyPageBannerType = {
  id: number
  onDisplay: boolean
  bannerImageUrl: string | null
  bannerLinkUrl: string
  priority: number
  startedAt?: string
  endedAt?: string
  exposureAreas?: string | null
  themeModeType: ThemeModeTypeCode | null
  title1?: string | null
  title2?: string | null
  showDomesticUser?: boolean
  showForeignUser?: boolean
}

export const enum BannerExposureAreaCode {
  MYPAGE_HOME = "MYPAGE_HOME" ,
  MYPAGE_EVENT = "MYPAGE_EVENT",
  MODEL_DETAIL = "MODEL_DETAIL",
  SELL = "SELL",
  PAYMENT = "PAYMENT"
}

export const BannerExposureAreaCodeList = [
  { value: BannerExposureAreaCode.MYPAGE_HOME.toString(), title: "마이페이지_홈" },
  { value: BannerExposureAreaCode.MYPAGE_EVENT.toString(), title: "마이페이지_이벤트" },
  { value: BannerExposureAreaCode.MODEL_DETAIL.toString(), title: "모델상세" },
  { value: BannerExposureAreaCode.SELL.toString(), title: "판매하기" },
  { value: BannerExposureAreaCode.PAYMENT.toString(), title: "결제영역" }
];
