import {Box, Paper, Stack} from "@mui/material";
import PageableDataGrid from "../../components/PageableDataGrid";
import {assignDetailModel, getKakakuProductPage} from "../../apis/kakakuProductAPI";
import {useSearchParams} from "react-router-dom";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import React, {useState} from "react";
import DetailModelSimpleSelectPopup from "../../components/Popup/DetailModelSelectPopupByRefNo";

const KakakuProductMappingManager = () => {
    const [searchParams, setSearchParams] = useSearchParams({})
    const [refreshCounter, setRefreshCounter] = useState(0)
    const [selectedKakakuProduct, setSelectedKakakuProduct] = useState<any|null>(null)

    const getColumns = () => [
        {field: 'id', headerName: "카카쿠 상품 ID", width: 200, renderCell: (params: any) => {
                return <a href={`https://kakaku.com/item/${params.id}` } target={'_blank'}>{params.id}</a>
        }},
        {field: "name", headerName: "카카쿠 상품명", width: 300},
        {field: 'refNo', headerName: "레퍼런스", width: 200},
        {
            field: "actions",
            type: "actions",
            width: 10,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label={"수정"}
                    onClick={() => {
                        console.log(params)
                        setSelectedKakakuProduct({
                            id: params.id,
                            refNo: params?.row?.refNo
                        })
                    }}
                />,
            ],
        },
        {field: 'detailModel', headerName: "상세모델", flex: 1},
    ]

    return (<Paper sx={{width: '100%'}}>
        <Stack>
            <Box sx={{width: '100%', height: 735}}>
                <PageableDataGrid
                    columns={getColumns()}
                    fetch={getKakakuProductPage}
                    mapRows={(row) => {
                        return {...row, detailModel: `${row?.detailModel?.title ?? ''} ${row?.detailModel?.titleSecondary ?? ''}`}
                    }}
                    searchParams={searchParams}
                    refreshCounter={refreshCounter}
                />
            </Box>
        </Stack>
        <DetailModelSimpleSelectPopup
            open={!!selectedKakakuProduct}
            searchText={selectedKakakuProduct?.refNo}
            handleClose={(detailModels) => {
                let detailModel = detailModels?.[0]
                if (detailModel) {
                    assignDetailModel(selectedKakakuProduct.id, {detailModel})
                        .then(() => {
                            setSelectedKakakuProduct(null)
                            setRefreshCounter(refreshCounter+1)
                        })
                } else {
                    setSelectedKakakuProduct(null)
                }
            }}
        />
    </Paper>)
}

export default KakakuProductMappingManager