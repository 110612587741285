export const translateAppraisalResultKo = (value:string) => {
  switch (value) {
    case "Genuine":
      return "정품"
    case "Fake":
      return "가품"
    case "Partly Fake":
      return "일부 가품"
    case "Undeterminable":
      return "판정 불가"
  }
}