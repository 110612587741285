import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useMemo } from "react"
import { DiagnosisResultCode, DiagnosisType } from "types/DiagnosisType"
import { OrderType } from "types/SellOrderType"

type Props = {
  isRePurchaseGuaranteed?: boolean
  orderType?: OrderType
  result?: DiagnosisResultCode
  changeDiagnosisState: (key: keyof DiagnosisType, newState: any) => void
}

const DiagnosisResult = ({ result, changeDiagnosisState, isRePurchaseGuaranteed = false, orderType }: Props) => {
  const resultOptionList = useMemo(() => {
    if (isRePurchaseGuaranteed) {
      return [
        { value: DiagnosisResultCode.합격, label: "매입보증 판매 승인" },
        { value: DiagnosisResultCode.불합격, label: "불합격" },
      ]
    }

    if (orderType === OrderType.진단전) {
      return [
        { value: DiagnosisResultCode.합격, label: "합격" },
        { value: DiagnosisResultCode.불합격, label: "불합격" },
        { value: DiagnosisResultCode.보류, label: "보류" },
      ]
    } else {
      return [
        { value: DiagnosisResultCode.합격, label: "합격" },
        { value: DiagnosisResultCode.조건부_합격, label: "조건부 합격" },
        { value: DiagnosisResultCode.불합격, label: "불합격" },
      ]
    }
  }, [isRePurchaseGuaranteed, orderType])

  return (
    <>
      <h2>진단결과</h2>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event) => changeDiagnosisState("result", event.target.value)}
              >
                {resultOptionList.map(({ value, label }) => (
                  <FormControlLabel value={value} label={label} checked={result === value} control={<Radio />} />
                ))}
              </RadioGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default DiagnosisResult
