import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, FormControlLabel,
  MenuItem, Radio, RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import RowBlock from "components/RowBlock"

import React, { useMemo, useRef, useState } from "react"

import _ from "lodash"
import ProductSelectPopup from "components/Popup/ProductSelectPopup"
import MdPickProductTable from "../MdPickProductTable"

import { ProductConditionCode } from "types/DiagnosisType"

import { ProductStatusCode } from "types/ProductType"

import { MdPickCreateBodyType, MdPickType } from "types/MdPickType"
import styled from "@emotion/styled"

import { useMdPickEditorData } from "./useMdPickEditorData"
import { isNotEmpty } from "utils/validationUtils"
import ImageUploadButton from "../../../../components/ImageUploadButton"
import { ThemeModeTypeCodeList } from "../../../../types/ShopHeroBannerType"
import CheckBox from "../../../../components/Checkbox"

export const exposureMenuList: { value: MdPickType["curationType"]; title: string }[] = [
  { value: "수동", title: "수동" },
  { value: "최신입고순", title: "최신 입고순" },
]

export const productConditionOptions: { id: ProductConditionCode; label: string }[] = [
  { id: ProductConditionCode.미착용, label: "미착용" },
  { id: ProductConditionCode.중고, label: "중고" },
]

type Props = {
  curationId: number | null
  handleClose: () => void
}

const MdPickEditorPopup = ({ curationId, handleClose }: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  const [showProductSelectPopup, setShowProductSelectPopup] = useState(false)

  const {
    formState,
    handleOnChangeState,
    handleChangeCurationType,
    handleChangeThemeModeType,
    handleChangeImage,
    handleDeleteImage,
    callApiByEditorType,
    onAddImage,
    checkIfIsValidPayload,
  } = useMdPickEditorData(curationId)

  const editorType = useMemo(() => {
    return curationId ? "EDIT" : "CREATE"
  }, [curationId])

  const handleOnClickSubmitButton = async () => {
    if (_.isNil(formState)) return

    if (!checkIfIsValidPayload(formState)) return

    await callApiByEditorType(formState)
    handleClose()
  }

  const handleClickInput = () => {
    if (!ref.current) return

    ref.current.click()
  }

  // const handleChangeImage = async () => {
  //   const files = ref.current?.files
  //
  //   if (files) onAddImage(files)
  // }


  return (
    <Dialog open={true} maxWidth={"lg"} fullWidth>
      <DialogTitle>MD PICK {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <RowBlock title={"제목"}>
            <TextField
              required
              size={"small"}
              fullWidth
              value={formState.title}
              onChange={(e) => handleOnChangeState({ title: e.target.value })}
              placeholder={"큐레이션 제목을 작성 해 주세요 (서비스에 노출되지 않습니다.) "}
            />
          </RowBlock>
          <RowBlock title={"노출 기준"}>
            <FormControl fullWidth>
              <Select
                size={"small"}
                defaultValue={"수동"}
                required
                value={formState.curationType || "수동"}
                onChange={(e) => handleChangeCurationType(e.target.value as MdPickCreateBodyType["curationType"])}
              >
                {exposureMenuList.map((menu) => (
                  <MenuItem value={menu.value.toString()} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </RowBlock>

          <RowBlock title={"최소 노출 갯수"}>
            <TextField
              size={"small"}
              value={formState.minQuantity}
              fullWidth
              onChange={(e) => {
                handleOnChangeState({ minQuantity: Number(e.target.value) })
              }}
              placeholder={"3"}
            />
          </RowBlock>
          <RowBlock title={"최대 노출 갯수"}>
            <TextField
              size={"small"}
              value={formState.maxQuantity}
              fullWidth
              onChange={(e) => {
                handleOnChangeState({ maxQuantity: Number(e.target.value) })
              }}
              placeholder={"10"}
            />
          </RowBlock>
          <RowBlock title={"상단 이미지"}>
            {/*<div>*/}
            {/*  {isNotEmpty(formState.imageUrl) && <Image src={formState.imageUrl} alt="Md Pick 이미지" style={{ maxWidth: '330px', height: '330px'}}*/}
            {/*    // style={{  maxWidth: '330px', height: '330px', alignSelf:"center",*/}
            {/*    // // @ts-ignore*/}
            {/*    // objectFit: objectFit, border: '1px solid #ddd'}}*/}
            {/*  /> }*/}
            {/*  <InvisibleInput ref={ref} type="file" onChange={handleChangeImage} />*/}
            {/*</div>*/}
            {/*<div style={{ display: "inline-flex", alignItems: "center", paddingTop: "10px" }}>*/}
            {/*  <Button variant={"contained"} color={"error"} onClick={handleClickInput}>*/}
            {/*    {isNotEmpty(formState.imageUrl) ? "변경" : "추가"}*/}
            {/*  </Button>*/}
            {/*</div>*/}
            <Stack direction={'column'} alignItems={'flex-start'} spacing={2} alignContent={'space-between'} >
              <ImageUploadButton height={'330px'} width={'330px'}  handleImage={handleChangeImage} imageUrl={formState.imageUrl}  handleDeleteImage={handleDeleteImage}/>
            </Stack>
          </RowBlock>
          <RowBlock title={"타입"}>
            <Stack direction={'row'}  justifyContent={'flex-start'}>
              <RadioGroup row sx={{ justifyContent: "center" }}
                          onChange={(e, value) => handleChangeThemeModeType(value as MdPickCreateBodyType["themeModeType"])}
              >
                {ThemeModeTypeCodeList.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    label={item.title}
                    checked={formState.themeModeType === item.value}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </RowBlock>
          <RowBlock title={"텍스트"}>
            <Stack direction={"column"} spacing={1}>
              <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={formState.title1}
                         onChange={(e) => handleOnChangeState({ title1: e.target.value })}
                         placeholder={'첫째줄 카피 최대 50자리까지 (띄어쓰기 포함)'}/>
              <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={formState.title2}
                         onChange={(e) => handleOnChangeState({ title2: e.target.value })}
                         placeholder={'둘째줄 카피 최대 50자리까지 (띄어쓰기 포함)'}/>
              <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={formState.title3}
                         onChange={(e) => handleOnChangeState({ title3: e.target.value })}
                         placeholder={'소문구 카피 최대 50자리까지 (띄어쓰기 포함)'}/>
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 여부"}>
            <Stack direction={"row"}>
              <Switch
                checked={formState.onDisplay}
                onChange={(e) => handleOnChangeState({ onDisplay: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출"}   >
            <Stack direction={'row'} alignItems={'center'}   >
              <CheckBox checked={formState?.showDomesticUser}   id="showDomesticUser"  onChange={(e) => handleOnChangeState({ showDomesticUser: !formState?.showDomesticUser })} />
              <CheckBoxLabel htmlFor="showDomesticUser" >
                내국인 회원 (국내거주 외국인 포함)
              </CheckBoxLabel>
              <CheckBox checked={formState?.showForeignUser}   id="showForeignUser" onChange={(e) => handleOnChangeState({ showForeignUser: !formState?.showForeignUser })} />
              <CheckBoxLabel htmlFor="showForeignUser" >
                외국인 회원
              </CheckBoxLabel>
            </Stack>
          </RowBlock>
          {formState.curationType === "수동" && (
            <RowBlock title={"노출 상품"}>
              <MdPickProductTable
                productList={formState.products}
                handleOnChangeState={handleOnChangeState}
                openShowProductSelectPopup={() => setShowProductSelectPopup(true)}
              />
            </RowBlock>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleOnClickSubmitButton}>
          {editorType === "EDIT" ? "수정" : "등록"}
        </Button>
        <Button variant={"contained"} color={"error"} onClick={handleClose}>
          취소
        </Button>
      </DialogActions>

      {showProductSelectPopup && (
        <ProductSelectPopup
          isSearchable
          filterStatus={[ProductStatusCode.판매중]}
          open={showProductSelectPopup}
          setProducts={(productList) => {
            const productRows = formState.products ?? []
            const productIds = productRows.map(({ product }) => product.id)
            const deduplicatedProducts = productList.filter((product) => !productIds.includes(product.id))
            const duplicatedProducts = productList.filter((product) => productIds.includes(product.id))

            if (duplicatedProducts.length) {
              window.alert(
                `이미 등록된 상품 ${duplicatedProducts.length}개를 제외하고 ` +
                `${deduplicatedProducts.length}개의 상품을 추가합니다.` +
                `\n추가상품ID: ${deduplicatedProducts.map((product) => product.id).join(", ") || "-"}` +
                `\n제외상품ID: ${duplicatedProducts.map((product) => product.id).join(", ")}`
              )
            }

            const nextProductRows = [
              ...productRows,
              ...deduplicatedProducts.map((product, index) => ({
                onDisplay: true,
                product: product,
                priority: productRows.length + index + 1,
              })),
            ]
            handleOnChangeState({ products: nextProductRows })
          }}
          close={() => {
            setShowProductSelectPopup(false)
          }}
        />
      )}
    </Dialog>
  )
}

export default MdPickEditorPopup

const InvisibleInput = styled.input`
    visibility: hidden;
    width: 0px;
    height: 0px;
`

const Image = styled.img`
    width: 500px;
`
const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`