import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material"
import RowBlock from "../../../../components/RowBlock";
import {DateTimePicker} from "@mui/lab";
import ImageUploadButton from "../../../../components/ImageUploadButton";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import {upload} from '../../../../apis/resourceAPI'
import ShopFrontPopupBannerAPI from "../../../../apis/shopFrontPopupBannerAPI"
import { ShopFrontPopupBannerType } from "../../../../types/ShopFrontPopupBannerType"
import { isNil } from "../../../../utils/validationUtils"
import { toast } from "react-toastify"
import CheckBox from "../../../../components/Checkbox"
import styled from "@emotion/styled"

const initState: ShopFrontPopupBannerType = {
    description: null,
    imageUrl: null,
    imageUrlEnglish: null,
    imageUrlJapanese: null,
    linkUrl: null,
    onDisplay: true,
    priority: null,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO(),
    createdAt: null,
    isStopWatchingToday: false,
    showDomesticUser: true,
    showForeignUser: false
}


// @ts-ignore
const FrontPopUpdatePopup = ({banner, handleClose}) => {
    const [state, setState] = useState(initState)

    useEffect(() => {
        if (banner != null) {
            setState(banner)
        } else {
            setState(initState)
        }
    }, [banner])

    const handleBannerImage = (image:any) => {
        upload(image).then((result) => {
            setState({
                ...state,
                imageUrl: result.url,
            })
        })
    }

    const handleBannerImageEnglish = (image:any) => {
        upload(image).then((result) => {
            setState({
                ...state,
                imageUrlEnglish: result.url,
            })
        })
    }

    const handleBannerImageJapanese = (image:any) => {
        upload(image).then((result) => {
            setState({
                ...state,
                imageUrlJapanese: result.url,
            })
        })
    }

    const handleSubmit = () => {
        if (isNil(state.imageUrl)) {
            toast.error("한국어 이미지를 선택하세요.")
            return
        }
        if (state.id == null) {
            toast.error("배너 ID 정보가 없습니다.")
            return
        }

        ShopFrontPopupBannerAPI.update(state.id!!, state).then(() => {handleClose()})
    }

    return (
        <Dialog open={banner != null} maxWidth={"lg"} fullWidth>
            <DialogTitle>배너 수정</DialogTitle>
            <DialogContent>
                <Stack direction={'row'}>
                    <Stack spacing={3} style={{width:'100%', height:'750px'}}>
                        <RowBlock title={"설명"}><TextField size={'small'} fullWidth value={state.description} onChange={(e) => setState({...state, description: e.target.value})}/></RowBlock>

                        <RowBlock title={"이미지"}>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Box textAlign="center">
                                    <Typography variant="subtitle1">한국어</Typography>
                                    <ImageUploadButton
                                      height="280px"
                                      width="280px"
                                      handleImage={handleBannerImage}
                                      imageUrl={state.imageUrl}
                                      handleDeleteImage={() => {
                                          setState({ ...state, imageUrl: null });
                                      }}
                                    />
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="subtitle1">영어</Typography>
                                    <ImageUploadButton
                                      height="280px"
                                      width="280px"
                                      handleImage={handleBannerImageEnglish}
                                      imageUrl={state.imageUrlEnglish}
                                      handleDeleteImage={() => {
                                          setState({ ...state, imageUrlEnglish: null });
                                      }}
                                    />
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="subtitle1">일본어</Typography>
                                    <ImageUploadButton
                                      height="280px"
                                      width="280px"
                                      handleImage={handleBannerImageJapanese}
                                      imageUrl={state.imageUrlJapanese}
                                      handleDeleteImage={() => {
                                          setState({ ...state, imageUrlJapanese: null });
                                      }}
                                    />
                                </Box>
                            </Box>
                        </RowBlock>
                        <RowBlock title={"링크 URL"}><TextField size={'small'} fullWidth value={state.linkUrl} onChange={(e) => setState({...state, linkUrl: e.target.value})} placeholder={'https://'}/></RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={'row'} >
                                <Switch checked={state.onDisplay ?? false} onChange={(e) => setState({...state, onDisplay: e.target.checked})}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'flex-start'}>
                                    <DateTimePicker onChange={(e) => {
                                        setState({
                                            ...state,
                                            startedAt: e?.toISO() ?? DateTime.now().toISO()
                                        })
                                    }} value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 시작'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                                    <Typography>~</Typography>
                                    <DateTimePicker onChange={(e) => {
                                        setState({
                                            ...state,
                                            endedAt: e?.toISO() ?? DateTime.now().toISO()
                                        })
                                    }} value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 종료'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                                </Stack>
                        </RowBlock>
                        <RowBlock title={"노출"}   >
                            <Stack direction={'row'} alignItems={'center'}   >
                                <CheckBox checked={state?.showDomesticUser}   id="showDomesticUser"  onChange={() => setState((pre) => ({...state, showDomesticUser: !state.showDomesticUser})) } />
                                <CheckBoxLabel htmlFor="showDomesticUser" >
                                    내국인 회원 (국내거주 외국인 포함)
                                </CheckBoxLabel>
                                <CheckBox checked={state?.showForeignUser}   id="showForeignUser"  onChange={() => setState((pre) => ({...state, showForeignUser: !state.showForeignUser})) } />
                                <CheckBoxLabel htmlFor="showForeignUser" >
                                    외국인 회원
                                </CheckBoxLabel>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"닫기 설정"}>
                            <Stack direction={'row'} justifyContent={'left'}>
                                <RadioGroup
                                  row
                                  sx={{ justifyContent: "left" }}
                                  onChange={(e) => {
                                      setState({...state, isStopWatchingToday: e.target.value == "true"})
                                  }}
                                >
                                    <FormControlLabel
                                      value="true"
                                      label="오늘 그만 보기"
                                      checked={state.isStopWatchingToday == true}
                                      control={<Radio />}
                                    />
                                    <FormControlLabel
                                      value="false"
                                      label="닫기"
                                      checked={state.isStopWatchingToday == false}
                                      control={<Radio />}
                                    />
                                </RadioGroup>
                            </Stack>
                        </RowBlock>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`

export default FrontPopUpdatePopup