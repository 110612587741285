import { Paper, Tab } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import OfficialPriceTab from "./OfficialPriceTab"
import PriceSummaryTab from "./PriceSummaryTab"
import OfficialModelTab from "./OfficialModelTab"

interface RetailPriceItemState {
  tab: string
}

const initState = {
  tab: "officialPrice",
}

const RetailPriceManager = () => {
  const [state, setState] = useState(initState)
  const [searchParams, setSearchParams] = useSearchParams()
  let { search } = useLocation()

  function handleChangeTab(newTab: string) {
    if (state.tab != newTab) {
      setState({ ...initState, tab: newTab })
    }
    setSearchParams([["tab", newTab]])
  }

  useEffect(() => {
    handleChangeTab(new URLSearchParams(search).get("tab") ?? initState.tab)
  }, [])

  return (
    <Paper sx={{ p: 2 }}>
      <TabContext value={state.tab}>
        <TabList onChange={(_e, value) => handleChangeTab(value)}>
          <Tab value={"officialPrice"} label={`(수집)공식출시가 관리(수집월별)`}></Tab>
          <Tab value={"officialModel"} label={`(수집)공식출시가 관리(모델별)`}></Tab>
          <Tab value={"priceSummary"} label={`(운영)공식출시가 관리`}></Tab>
        </TabList>
        <TabPanel value={"officialPrice"}> <OfficialPriceTab /> </TabPanel>
        <TabPanel value={"officialModel"}> <OfficialModelTab /> </TabPanel>
        <TabPanel value={"priceSummary"}> <PriceSummaryTab /> </TabPanel>
      </TabContext>
    </Paper>
  )
}

export default RetailPriceManager