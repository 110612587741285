import { FunctionComponent } from "react"
import { StyledAbsoluteBox, StyledBox, StyledInputText, StyledStix, StyledTxt } from "./styled"
import { SelectOptions } from "./SelectOptions"
import {APPRAISAL_RESULTS, AppraisalResultType} from "./common"
export type AppraisalCertificateExteriorType = {
  group: {
    label: string
    labelKo:string
    key: string
    value: string
  }[]
  result: AppraisalResultType
  resultKey: string
}
const COLUMN_HEIGHT=7;
export const DiagnosisPrintExterior:FunctionComponent<{
  exteriors:AppraisalCertificateExteriorType[]
  handelChange:(label:string, value:string) => void
  handleResultChange:(index:number, value:string) => void
  editable:boolean
}> = ({
  exteriors,
  handleResultChange,
  handelChange,
  editable
}) => {
  return (
    exteriors.length > 0 ? (
      <StyledBox $flexDirection="column" $paddingLeft={12} $gap={4}>
        {
          exteriors.map((item, itemIndex) => (
            <StyledBox $flexDirection="column" $width={"100%"} key={itemIndex}>
              {
                item.group.map((group, groupIndex) => (
                  <StyledBox $gap={19} $width={"100%"} key={`${group.key}_${groupIndex}`} $alignItems="center">
                    <StyledBox
                      $width={74}
                      $flexShrink={0}
                      $gap={2}
                      $alignItems="center"
                      $marginBottom={groupIndex === 0 ? 3 : 1}
                      className="is-design"
                    >
                      {
                        groupIndex === 0 ? (
                          <StyledTxt $color="#000" $fontSize={7} $weight={700} $lineHeight={COLUMN_HEIGHT}>
                            {group.label}
                          </StyledTxt>
                        ) : (
                          <StyledBox $alignItems="center" $paddingLeft={4}>
                            <StyledTxt $color="#000" $fontSize={7} $weight={400} $lineHeight={COLUMN_HEIGHT}>
                              -&nbsp;
                            </StyledTxt>
                            <StyledTxt $color="#000" $fontSize={7} $weight={400} $lineHeight={COLUMN_HEIGHT}>
                              {group.label}
                            </StyledTxt>
                          </StyledBox>
                        )
                      }
                      <StyledTxt $color="#545454" $fontSize={5} $lineHeight={COLUMN_HEIGHT}>
                        {group.labelKo}
                      </StyledTxt>
                    </StyledBox>
                    <StyledBox
                      $width={"100%"}
                      $paddingTop={groupIndex === 0 ? 3 : 2}
                      $paddingBottom={item.group.length - 1 === groupIndex ? 4 : 0}
                      $paddingLeft={9}
                      $paddingRight={61}
                      $isBg={true}
                    >
                      <StyledInputText
                        $isItalic={true}
                        $color="#000"
                        $fontSize={6}
                        $lineHeight={COLUMN_HEIGHT}
                        readOnly={!editable}
                        onChange={(event)=>{
                          handelChange(group.label, event.target.value)
                        }}
                        value={group.value}
                        style={{paddingLeft:0}}
                        placeholder={group.label === "Links" && editable ? "N/A" : ""}
                        className={group.value === "미노출" ? "not-printing" : ""}
                      />
                    </StyledBox>
                  </StyledBox>
                ))
              }
              <StyledBox
                $width={52}
                $height={COLUMN_HEIGHT}
                $marginRight={7}
                $justifyContent="center"
                style={{
                  position:"absolute",
                  right:0,
                  top: "50%",
                  transform: "translateY(-50%)"
                }}
              >
                <StyledStix
                  $isItalic={true}
                  $color="#000"
                  $fontSize={6}
                  $lineHeight={COLUMN_HEIGHT}
                  $weight={700}
                  $align="center"
                  style={{
                    letterSpacing: 0.2
                  }}
                  className={item.result === "미노출" ? "not-printing" : ""}
                >
                  {item.result}
                </StyledStix>

                {
                  item.result !== null && editable ? (
                    <StyledAbsoluteBox
                      $width={"100%"}
                      className="not-printing"
                    >
                      <SelectOptions
                        items={[...APPRAISAL_RESULTS]}
                        selectedIndex={APPRAISAL_RESULTS.findIndex(value => value === item.result)}
                        handleChange={(event)=>{
                          handleResultChange(itemIndex, event.target.value)
                        }}
                      />
                    </StyledAbsoluteBox>
                  ) : null
                }
              </StyledBox>
            </StyledBox>
          ))
        }
      </StyledBox>
    ) : null
  )
}