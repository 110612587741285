import styled from "@emotion/styled"
import CheckBox from "components/Checkbox"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const headerItemList: {
  title: string
  width?: string
}[] = [
  {
    title: "체크박스",
    width: "5%",
  },
  {
    title: "수리 항목",
    width: "10%",
  },
  {
    title: "결제",
    width: "10%",
  },
  {
    title: "비용",
    width: "15%",
  },
  {
    title: "비고",
    width: "40%",
  },
]
type Props = {
  isAllChecked: boolean
  toggleAllUpgradeItem: () => void
}

const UpgradeServiceListTableHeader = ({ toggleAllUpgradeItem, isAllChecked }: Props) => {
  return (
    <>
      <HeaderTitle>수리 항목</HeaderTitle>
      <ListTableHeader>
        {headerItemList.map((headerItem) => {
          if (headerItem.title === "체크박스") {
            return (
              <ListTableHeaderItem key="체크박스" style={{ maxWidth: headerItem.width }}>
                <CheckBox onChange={toggleAllUpgradeItem} checked={isAllChecked} />
              </ListTableHeaderItem>
            )
          }
          return (
            <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
              {headerItem.title}
            </ListTableHeaderItem>
          )
        })}
      </ListTableHeader>
    </>
  )
}

export default UpgradeServiceListTableHeader

const HeaderTitle = styled.div`
  padding: 8px 20px;
  background: #eeeeee;
  margin-top: -4px;
  font-weight: 700;
  font-size: 16px;
`
