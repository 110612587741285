import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useMemo } from "react"
import { DiagnosisType, PenaltyReasonTypeCode } from "types/DiagnosisType"
import { OrderType } from "types/SellOrderType"

type Props = {
  isRePurchaseGuaranteed?: boolean
  orderType?: OrderType
  penaltyReasonType?: PenaltyReasonTypeCode
  changeDiagnosisState: (key: keyof DiagnosisType, newState: any) => void
}

const PenaltyInformation = ({
  penaltyReasonType,
  changeDiagnosisState,
  isRePurchaseGuaranteed = false,
  orderType,
}: Props) => {
  const penaltyReasonOptionList = useMemo(() => {
    if (isRePurchaseGuaranteed) {
      return [
        { value: PenaltyReasonTypeCode.가품, label: "가품" },
        { value: PenaltyReasonTypeCode.다른상품전달, label: "다른 상품 전달" },
      ]
    }

    return [
      { value: PenaltyReasonTypeCode.가품, label: "가품" },
      { value: PenaltyReasonTypeCode.취급불가_커스텀, label: "취급불가 커스텀" },
    ]
  }, [isRePurchaseGuaranteed])

  const noPenaltyReasonOptionList = useMemo(() => {
    if (isRePurchaseGuaranteed) {
      return [{ value: null, label: "해당사항 없음" }]
    }

    if (orderType === OrderType.진단전) {
      return [
        { value: PenaltyReasonTypeCode.위탁_전환, label: "위탁 전환" },
        { value: null, label: "해당사항 없음" },
      ]
    } else {
      return [
        { value: PenaltyReasonTypeCode.부품_교체_필요, label: "부품 교체 필요" },
        { value: PenaltyReasonTypeCode.상품화_불가, label: "상품화 불가" },
        { value: null, label: "해당사항 없음" },
      ]
    }
  }, [orderType, isRePurchaseGuaranteed])

  return (
    <>
      <h2>판매 불가 페널티</h2>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"header"}>페널티 발생</td>
            <td className={"header"}>페널티 없음</td>
          </tr>
          <tr>
            <td>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event) => {
                  changeDiagnosisState("penaltyReasonType", event.target.value)
                }}
              >
                {penaltyReasonOptionList.map(({ value, label }) => (
                  <FormControlLabel
                    key={label}
                    value={value}
                    label={label}
                    checked={penaltyReasonType === value}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </td>
            <td>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event) => {
                  const targetValue = event.target.value

                  changeDiagnosisState("penaltyReasonType", !targetValue ? null : targetValue)
                }}
              >
                {noPenaltyReasonOptionList.map(({ value, label }) => {
                  const getCheckedValue = () => {
                    if (!penaltyReasonType && label === "해당사항 없음") {
                      return true
                    }

                    return penaltyReasonType === value
                  }
                  return (
                    <FormControlLabel
                      key={label}
                      value={value}
                      label={label}
                      checked={getCheckedValue()}
                      control={<Radio />}
                    />
                  )
                })}
              </RadioGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PenaltyInformation
