import { Grid, Button, Stack, FormControl, InputLabel, Select, MenuItem, TextField, Paper } from "@mui/material"
import React, { useEffect, useState } from "react"
import { SettlementTable } from "./SettlementTable"
import { PageType } from "../../types/PageType"
import { SettlementStatusCode, SettlementType } from "../../types/SettlementType"
import { CountType } from "../../types/CountType"
import { useSearchParams } from "react-router-dom"
import { downloadSettlementExcel, getSettlementList, SettlementListSearchParams } from "../../apis/settlementAPI"
import { DateTime } from "luxon"
import { DesktopDatePicker } from "@mui/lab"
import { toast } from "react-toastify"

export type SettlementState = {
  selectIds: string[]
  settlements:PageType<SettlementType>
  settlementCount:CountType[]
  searchFilterKey:'productId' | 'userId' | 'payoutId'
  searchFilterValue:string
  refresh:boolean;
  loading:boolean;
  dateFilterKey:'paymentRequestDate' | 'paymentApproveDate'
  startDateFilter?: DateTime | null
  endDateFilter?: DateTime | null
  status?:string
}

const initialState:SettlementState = {
  selectIds:[],
  settlements:{
    content:[],
    pageable:{pageSize:25, pageNumber:0},
    totalPages:0,
    totalElements:0,
    numberOfElements:0,
  },
  settlementCount: [],
  searchFilterKey: 'productId',
  searchFilterValue: '',
  refresh: false,
  loading: false,
  dateFilterKey: 'paymentRequestDate',
  startDateFilter: undefined,
  endDateFilter: undefined,
  status: '전체'
}

export const SettlementManager:React.FC = ()=>{

  const settlementStatus: SettlementStatusCode[] = [
    SettlementStatusCode.지급_대기,
    SettlementStatusCode.지급_처리중,
    SettlementStatusCode.지급_요청_완료,
    SettlementStatusCode.지급_취소,
    SettlementStatusCode.지급_완료,
    SettlementStatusCode.지급_거절
  ]

  const [state, setState] = useState<SettlementState>(initialState)
  const [currentSearchParams] = useSearchParams()

  const fetchSettlementList = (searchParams:SettlementListSearchParams = {})=>{

    let newParams: SettlementListSearchParams = {
      ...searchParams,
      dateCriteria: state.dateFilterKey,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      status: (state.status?.split(",") as SettlementStatusCode[]) || settlementStatus
    }
    if (state.searchFilterKey === "productId") newParams.productId = state.searchFilterValue
    if (state.searchFilterKey === "userId") newParams.userId = state.searchFilterValue
    if (state.searchFilterKey === "payoutId") newParams.payoutId = state.searchFilterValue

    if (state.status === "전체") newParams.status = undefined

    getSettlementList(newParams).then((settlementItem)=>{
      setState((prevState)=>({
        ...prevState,
        settlements:settlementItem
      }))
    })
  }

  useEffect(()=>{
    fetchSettlementList({
      page: state.settlements.pageable?.pageNumber ?? 0,
      size: state.settlements.pageable?.pageSize ?? 25
    })
  }, [state.refresh, currentSearchParams])

  const handleExcelDownloadClick = (params: any = {}) => {
    if (state.startDateFilter === undefined) {
      toast.error("시작일을 선택해주세요.")
      return false
    }
    if (state.endDateFilter === undefined) {
      toast.error("종료일을 선택해주세요.")
      return false
    }

    let newParams: SettlementListSearchParams = {
      ...params,
      dateCriteria: state.dateFilterKey,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      status: (state.status?.split(",") as SettlementStatusCode[]) || settlementStatus
    }
    if (state.searchFilterKey === "productId") newParams.productId = state.searchFilterValue
    if (state.searchFilterKey === "userId") newParams.userId = state.searchFilterValue
    if (state.searchFilterKey === "payoutId") newParams.payoutId = state.searchFilterValue

    if (state.status === "전체") newParams.status = undefined

    downloadSettlementExcel(newParams).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `정산관리_정산.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select<'productId'|'userId'|'payoutId'>
                      value={state.searchFilterKey || ""}
                      onChange={(params) => {
                        console.log(`currentSearchParams: ${currentSearchParams}`)
                        setState((prevState)=>({
                          ...prevState,
                          searchFilterKey:params.target.value as 'productId'|'userId'|'payoutId',
                          searchFilterValue:'',
                        }))
                      }}
                    >
                      <MenuItem value={"payoutId"}>정산 ID</MenuItem>
                      <MenuItem value={"productId"}>상품번호</MenuItem>
                      <MenuItem value={"userId"}>판매자 ID</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={state.searchFilterValue || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setState((prevState)=>({
                          ...prevState,
                          refresh:!prevState.refresh
                        }))
                      }
                    }}
                    onChange={(params) => {
                      setState((prevState)=>({
                       ...prevState,
                       searchFilterValue:params.target.value
                      }))
                    }}
                  />
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="date_key">검색기간</InputLabel>
                    <Select<'paymentRequestDate'|'paymentApproveDate'>
                      value={state.dateFilterKey || ""}
                      onChange={(params) => {
                        setState((prevState)=>({
                          ...prevState,
                          dateFilterKey:params.target.value as 'paymentRequestDate' | 'paymentApproveDate',
                          refresh:!prevState.refresh
                        }))
                      }}
                    >
                      <MenuItem value={"paymentRequestDate"}>지급 요청일</MenuItem>
                      <MenuItem value={"paymentApproveDate"}>지급 승인일</MenuItem>
                    </Select>
                  </FormControl>
                  <DesktopDatePicker
                    label="시작일"
                    inputFormat="yyyy-MM-dd"
                    value={state.startDateFilter ?? null}
                    onChange={(params) => {
                      setState((prevState)=>({
                        ...prevState,
                        startDateFilter:params,
                        refresh:!prevState.refresh
                      }))
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                  <DesktopDatePicker
                    label="종료일"
                    inputFormat="yyyy-MM-dd"
                    value={state.endDateFilter ?? null}
                    onChange={(params) => {
                      setState((prevState)=>({
                        ...prevState,
                        endDateFilter:params,
                        refresh:!prevState.refresh
                      }))
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                  <Button
                    variant={"outlined"}
                    size={"small"}
                    style={{ margin: "0 2px" }}
                    onClick={handleExcelDownloadClick}
                  >
                    엑셀 다운로드
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <SettlementTable
              state={state}
              dispatch={setState}
              components={[
              ]}
              fetch={fetchSettlementList}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
