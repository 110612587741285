import { postSerialNumber } from "apis/serialAPI"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { isNotEmpty, isNotNil } from "utils/validationUtils"

export type SelectedModelType = {
  label: string
  id: string
  brand: string
  title: string
  titleSecondary: string
  image: string
  refNo: string
}

export const useSerialNumberRegister = () => {
  const [selectedModel, setSelectedModel] = useState<SelectedModelType | null>(null)
  const [serialNumber, setSerialNumber] = useState("")
  const [isBlackList, setIsBlackList] = useState<boolean>(false)

  const onSelectModel = (selectModel: SelectedModelType | null) => {
    setSelectedModel(selectModel)
  }

  const changeSerialNumber = (serialNumber: string) => {
    setSerialNumber(serialNumber)
  }

  const isSubmittable = useMemo(() => {
    return isNotNil(selectedModel) && isNotEmpty(serialNumber)
  }, [selectedModel, serialNumber])

  const toggleBlackListFlag = () => {
    setIsBlackList((prev) => !prev)
  }

  const onRegisterSerial = async () => {
    if (!selectedModel || !serialNumber) return

    try {
      await postSerialNumber({
        serialNo: serialNumber,
        detailModel: { id: Number(selectedModel.id) },
        blacklist: isBlackList,
      })
      toast.success("등록이 완료되었습니다.")
    } catch {
      toast.error("등록에 실패했습니다.")
    }
  }

  return {
    selectedModel,
    serialNumber,
    onSelectModel,
    changeSerialNumber,
    isSubmittable,
    toggleBlackListFlag,
    isBlackList,
    onRegisterSerial,
  }
}
