import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import Button from "components/Button"
import Modal from "components/Modal"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { isNaN } from "lodash"
import { useCallback, useMemo, useState } from "react"
import { UpgradeServiceItemType } from "types/UpgradeServiceOrderType"

type Props = {
  closeModal: () => void
  onEdit: (item: UpgradeServiceItemType) => void
  item: UpgradeServiceItemType
  upgradeItemList: UpgradeServiceItemType[]
}

const typeFilterMenuList: { value: string; title: string }[] = [
  { value: "폴리싱", title: "폴리싱" },
  { value: "오버홀", title: "오버홀" },
  { value: "기타", title: "기타" },
]

const paymentFilterMenuList: { value: "유상" | "무상"; title: string }[] = [
  { value: "유상", title: "유상" },
  { value: "무상", title: "무상" },
]

export const UpgradeItemEditModal = ({ closeModal, onEdit, item, upgradeItemList }: Props) => {
  const [memo, setMemo] = useState<string>(item.note || "")
  const [selectedType, setSelectedType] = useState<string>(item.label)
  const [selectedPayment, setSelectedPayment] = useState<"유상" | "무상">(item.feeType)
  const [paymentAmount, setPaymentAmount] = useState<number>(item.fee)

  const isSumittable = useMemo(() => {
    return !!selectedPayment && !!selectedType && !!paymentAmount && paymentAmount > 0
  }, [selectedType, selectedPayment, paymentAmount])

  const validateForm = useCallback(() => {
    if (selectedType === "기타") return true

    if (upgradeItemList.some((item) => item.label === selectedType)) {
      window.alert("중복된 수리 항목은 생성할 수 없습니다.")
      return false
    }

    return true
  }, [selectedType, upgradeItemList])

  const handleEditUpgradeItem = () => {
    if (!isSumittable) return
    if (!validateForm()) return

    onEdit({
      label: selectedType || "",
      feeType: selectedPayment || "유상",
      fee: paymentAmount || 0,
      note: memo,
    })
    closeModal()
  }

  return (
    <Modal isOpen closeModal={closeModal} zIndex={10000}>
      <ModalContentWrapper>
        <ModalBodyWrapper>
          <Title>수리 항목 생성</Title>
          <ColumnWrapper style={{ border: "1px solid #CCCCCC" }}>
            <RowWrapper>
              <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="항목">
                <ValueWrapper>
                  <FormControl style={{ minWidth: "250px" }}>
                    <Select
                      size={"small"}
                      required
                      value={selectedType}
                      placeholder="항목을 선택해주세요."
                      MenuProps={{
                        style: { zIndex: 10003 },
                      }}
                      onChange={(e) => setSelectedType(e.target.value as string)}
                    >
                      {typeFilterMenuList.map((menu) => (
                        <MenuItem value={menu.value.toString()} key={menu.title}>
                          {menu.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ValueWrapper>
              </DetailTableColumCell>
              <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="결제">
                <ValueWrapper>
                  <FormControl style={{ minWidth: "250px" }}>
                    <Select
                      size={"small"}
                      required
                      value={selectedPayment}
                      placeholder="결제유형을 선택해주세요."
                      MenuProps={{
                        style: { zIndex: 10003 },
                      }}
                      onChange={(e) => setSelectedPayment(e.target.value as "유상" | "무상")}
                    >
                      {paymentFilterMenuList.map((menu) => (
                        <MenuItem value={menu.value.toString()} key={menu.title}>
                          {menu.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ValueWrapper>
              </DetailTableColumCell>
              <DetailTableColumCell title="결제 금액">
                <ValueWrapper>
                  <Input
                    value={paymentAmount}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) return

                      setPaymentAmount(Number(e.target.value))
                    }}
                  />
                </ValueWrapper>
              </DetailTableColumCell>
            </RowWrapper>
            <DetailTableColumCell title="비고">
              <ValueWrapper>
                <TextArea value={memo} onChange={(e) => setMemo(e.target.value)} />
              </ValueWrapper>
            </DetailTableColumCell>
          </ColumnWrapper>
        </ModalBodyWrapper>
        <ModalFooterWrapper>
          <ButtonListWrapper>
            <ButtonWrapper>
              <Button onClick={closeModal} buttonSize="M" buttonStyle="blue-border">
                취소
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button disabled={!isSumittable} onClick={handleEditUpgradeItem} buttonSize="M">
                수정하기
              </Button>
            </ButtonWrapper>
          </ButtonListWrapper>
        </ModalFooterWrapper>
      </ModalContentWrapper>
    </Modal>
  )
}

const ModalContentWrapper = styled.div`
  width: 888px;
  min-height: 445px;
`

const ModalBodyWrapper = styled.div`
  padding: 31px 24px;
  flex: 1;
`

const ModalFooterWrapper = styled.div`
  width: 100%;
  padding: 8px 6px;
  display: inline-flex;
  justify-content: flex-end;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ValueWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 6px;
`

const TextArea = styled.textarea`
  flex: 1;
  height: 126px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  display: flex;
  algin-items: center;
`

const ButtonWrapper = styled.div`
  width: 70px;

  :last-child {
    margin-left: 8px;
  }
`

const ButtonListWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0;
  border-bottom: 1px solid #333333;
`
