import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

type Props = { title?: string; width?: string; titleWidth?: string } & HTMLAttributes<HTMLDivElement>

const DetailTableRowCell = ({
  title,
  width = "100%",
  titleWidth = "50%",
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Wrapper width={width} {...props}>
      {title && <TitleWrapper titleWidth={titleWidth}>{title}</TitleWrapper>}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  )
}

export default DetailTableRowCell

const Wrapper = styled.div<{ width: string }>`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => width};
`

const TitleWrapper = styled.div<{ titleWidth: string }>`
  width: ${({ titleWidth }) => titleWidth};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #eee;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 10px;
`

const ChildrenWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 10px;
  font-size: 0.825rem;
`
