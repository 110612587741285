import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import RowBlock from "components/RowBlock"
import { DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import {
  ShopBrandModelCurationCreateBodyType, ShopCurationBrandModelType,
  ShopCurationBrandType,
  ShopCurationCreateBodyType,
  ShopCurationType,
} from "types/ShopCurationType"
import _ from "lodash"
import CurationBrandModelTable from "./CurationBrandModelTable"
import { toast } from "react-toastify"
import { upload } from "../../../../../apis/resourceAPI"
import ImageUploadButton from "../../../../../components/ImageUploadButton"

import shopBrandModelCurationAPI from "../../../../../apis/shopBrandModelCurationAPI"
import ModelSelectPopup from "../ModelSelectPopup"
import { CurationStateType } from "../BrandPopup/CurationBrandEditorPopup"
import { isNil, isNotNil } from "../../../../../utils/validationUtils"

const initBrandState: ShopCurationBrandType = {
  id: null,
  title: null,
  titleSecondary: null,
  shopContentId: null,
  models: [],
  onDisplay: true,
  priority: null,
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().toISO(),
  bannerImageUrl: null,
  bannerImagePcUrl: null,
  postingStatus: null,
}
//
// export type CurationStateType = ShopBrandModelCurationCreateBodyType | ShopCurationType
//

type Props = {
  handleOnChangeState: (partialState: Partial<ShopCurationType>) => void
  brandId: number | null
  handleClose: (brands: ShopCurationBrandType | null) => void
  brandData: ShopCurationBrandType | null
  editorType: "EDIT" | "CREATE"
}

const CurationBrandModelEditorPopup = ({ handleOnChangeState, brandData, brandId, handleClose, editorType }: Props) => {
  const [brands, setBrands] = useState<ShopCurationBrandType>(initBrandState)
  const [showModelSelectPopup, setShowModelSelectPopup] = useState(false)
  //@ts-ignore
  const handleBrandImage = (image) => {
    upload(image).then((result) => {
      setBrands({
        ...brands,
        bannerImageUrl: result.url,
      })
    })
  }

  // @ts-ignore
  const handleBrandPcImage = (image) => {
    upload(image).then((result) => {
      setBrands({
        ...brands,
        bannerImagePcUrl: result.url,
      })
    })
  }

  const checkIfIsValidPayload = (state: ShopCurationBrandType) => {
    if (_.isNil(state)) {
      return false
    }

    if (_.isNil(state.title)) {
      toast.error("브랜드명을 입력해주세요")
      return false
    }

    if (_.isNil(state.bannerImageUrl)) {
      toast.error("브랜드 이미지를 선택해주세요.")
      return false
    }

    // if (_.isNil(state.bannerImagePcUrl)) {
    //   toast.error("브랜드 PC 이미지를 선택해주세요.")
    //   return false
    // }

    return true
  }

  // const callApiByEditorType = async (state: CurationStateType) => {
  //   if (editorType === "EDIT") {
  //     await shopBrandModelCurationAPI.update(state as ShopCurationType)
  //   } else {
  //     await shopBrandModelCurationAPI.create(state as ShopCurationCreateBodyType)
  //   }
  // }

  const handleOnClickSubmitButton = async () => {
    if (_.isNil(brands)) return

    if (!checkIfIsValidPayload(brands)) {
      return
    }

    handleClose(brands)
  }

  // const fetchCurationModel = (curationId: number) => {
  //   shopBrandModelCurationAPI.getBrandDetail(curationId).then((curation) => {
  //     setBrands(curation)
  //   })
  // }

  useEffect(() => {
    if (editorType === 'CREATE') {
      //fetchCurationModel(brandId)
      setBrands(initBrandState)
    } else {
      setBrands(brandData!!)
    }
  }, [brandData])

  return (
    <Dialog open={true} maxWidth={"lg"} fullWidth>
      <DialogTitle>브랜드 {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <RowBlock title={"브랜드명"}>
            <TextField
              size={"small"}
              fullWidth
              value={brands?.title}
              onChange={(e) => {
                setBrands((prevState) => ({
                  ...prevState,
                  title: e.target.value,
                }))
                //handleOnChangeState({ title: e.target.value })
              }}
              placeholder={"화면에 노출되는 브랜드명"}
            />
          </RowBlock>
          <RowBlock title={"브랜드 이미지"}>
            <Stack direction={"column"} alignItems={"flex-start"} spacing={2} alignContent={"space-between"}>
              <ImageUploadButton height={"160px"} width={"160px"} handleImage={handleBrandImage}
                                 imageUrl={brands?.bannerImageUrl}
                                 handleDeleteImage={() => {
                                   // setState({ ...state, bannerImageUrl: null, })
                                   setBrands((prevState) => ({
                                     ...prevState,
                                     bannerImageUrl: null,
                                   }))
                                 }} />
            </Stack>
          </RowBlock>
          {/*<RowBlock title={"브랜드 PC 이미지"}>*/}
          {/*  <Stack direction={"column"} alignItems={"flex-start"} spacing={2} alignContent={"space-between"}>*/}
          {/*    <ImageUploadButton height={"160px"} width={"160px"} handleImage={handleBrandPcImage}*/}
          {/*                       imageUrl={brands?.bannerImagePcUrl}*/}
          {/*                       handleDeleteImage={() => {*/}
          {/*                         // setState({ ...state, bannerImagePcUrl: null })*/}
          {/*                         setBrands((prevState) => ({*/}
          {/*                           ...prevState,*/}
          {/*                           bannerImagePcUrl: null,*/}
          {/*                         }))*/}
          {/*                       }} />*/}
          {/*  </Stack>*/}
          {/*</RowBlock>*/}
          <RowBlock title={"노출 여부"}>
            <Stack direction={"row"}>
              <Switch
                checked={brands ? Boolean(brands?.onDisplay) : false}
                onChange={(e) => {
                  setBrands((prevState) => ({
                    ...prevState,
                    onDisplay: e.target.checked,
                  }))
                  //handleOnChangeState({ onDisplay: e.target.checked })
                }}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 기간"}>
            <Stack direction={"row"} spacing={2}>
              <DateTimePicker
                onChange={(dateTime) => {
                  setBrands((prevState) => ({
                    ...prevState,
                    startedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                  }))
                  // handleOnChangeState({
                  //   startedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                  // })
                }}
                value={brands?.startedAt ? DateTime.fromISO(brands.startedAt) : null}
                renderInput={(props) => <TextField {...props} />}
                label={"게시 시작"}
                inputFormat={"yyyy-MM-dd HH:mm"}
              />
              <Typography>~</Typography>
              <DateTimePicker
                onChange={(dateTime) => {
                  setBrands((prevState) => ({
                    ...prevState,
                    endedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                  }))
                  // handleOnChangeState({
                  //   endedAt: dateTime ? dateTime?.toISO() : DateTime.now().toISO(),
                  // })
                }}
                value={brands?.endedAt ? DateTime.fromISO(brands.endedAt) : null}
                renderInput={(props) => <TextField {...props} />}
                label={"게시 종료"}
                inputFormat={"yyyy-MM-dd HH:mm"}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 모델"}>
            <CurationBrandModelTable
              models={brands?.models || []}
              handleOnChangeState={handleOnChangeState}
              setBrands={setBrands}
              openShowProductSelectPopup={() => setShowModelSelectPopup(true)}
            />
          </RowBlock>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleOnClickSubmitButton}>
          {editorType === "EDIT" ? "수정" : "등록"}
        </Button>
        <Button variant={"contained"} color={"error"} onClick={() => {
          handleClose(null)
        }}>
          취소
        </Button>
      </DialogActions>

      <ModelSelectPopup
        open={showModelSelectPopup}
        handleClose={(models) => {
          if (_.isNil(models)) return
          const modelRows = brands.models ?? []
          const modelIds = modelRows.map((model) => model?.model?.id).filter(isNotNil)
          const deduplicatedModels = models.filter((model) => !modelIds.includes(model.id))
          const duplicatedModels = models.filter((model) => modelIds.includes(model.id))

          if (duplicatedModels.length) {
            window.alert(
              `이미 등록된 모델 ${duplicatedModels.length}개를 제외하고 ` +
              `${deduplicatedModels.length}개의 모델을 추가합니다.` +
              `\n추가모델ID: ${deduplicatedModels.map((model) => model.id).join(", ") || "-"}` +
              `\n제외모델ID: ${duplicatedModels.map((model) => model.id).join(", ")}`,
            )
          }

          const nextModelRows = [
            ...modelRows,
            ...deduplicatedModels.map((model, index) => ({
              model: model,
              priority: null,
              onDisplay: true,
              link: null,
              shopContentBrandId: brandId,
              id: model.id,
            })),
          ]
          setBrands((prevState) => ({
            ...prevState,
            models: nextModelRows,
          }))

          //handleOnChangeState({ brands: nextModelRows })
          setShowModelSelectPopup(false)
        }}
      />
    </Dialog>
  )
}

export default CurationBrandModelEditorPopup
