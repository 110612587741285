import styled from "@emotion/styled"
import { Pagination } from "@mui/material"
import { getNotices } from "apis/noticeAPI"
import Button from "components/Button"
import ListTable from "components/Table/List"
import ListTableFooter from "components/Table/List/TableFooter"
import { FunctionComponent, useRef, useState } from "react"
import { useMount } from "react-use"
import { NoticeType } from "types/NoticeType"
import { NoticeDetailCreatePopup, NoticeDetailCreatePopupRefAttributes } from "./NoticeDetailCreatePopup"
import { NoticeDetailUpdatePopup, NoticeDetailUpdatePopupRefAttributes } from "./NoticeDetailUpdatePopup"
import { NoticeListTableBody } from "./NoticeListTableBody"
import { NoticeListTableBodyRow } from "./NoticeListTableBodyRow"
import { NoticeListTableHeader } from "./NoticeListTableHeader"

const columnWidths = [100, undefined, 100, 150, 150, 100]

export const NoticeListPage: FunctionComponent = () => {
  const noticeCreatePopupRef = useRef<NoticeDetailCreatePopupRefAttributes>(null)
  const noticeUpdatePopupRef = useRef<NoticeDetailUpdatePopupRefAttributes>(null)

  const [noticeListItems, setNoticeListItems] = useState<NoticeType[]>([])
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)

  const fetchNoticeList = async (nextPageNumber: number) => {
    setPageNumber(nextPageNumber)
    const nextNoticeList = await getNotices({ size: 25, page: nextPageNumber })
    setNoticeListItems(nextNoticeList.content)
    setTotalPages(nextNoticeList.totalPages ?? 0)
  }

  useMount(async () => {
    fetchNoticeList(0)
  })

  return (
    <>
      <PageHeaderWrapper>
        <div>
          <Button onClick={() => noticeCreatePopupRef.current?.open()}>공지사항 작성</Button>
        </div>
      </PageHeaderWrapper>
      <ListTable>
        <NoticeListTableHeader columnWidths={columnWidths} />
        <NoticeListTableBody
          data={noticeListItems}
          renderItem={({ item }) => (
            <NoticeListTableBodyRow
              key={item.id}
              columnWidths={columnWidths}
              notice={item}
              onClick={() => {
                noticeUpdatePopupRef.current?.open(item.id)
              }}
            />
          )}
        />
        <ListTableFooter>
          <Pagination count={totalPages} onChange={(e, page) => fetchNoticeList(page - 1)} page={pageNumber + 1} />
        </ListTableFooter>
      </ListTable>
      <NoticeDetailCreatePopup
        ref={noticeCreatePopupRef}
        onClose={() => {
          fetchNoticeList(pageNumber)
        }}
      />
      <NoticeDetailUpdatePopup
        ref={noticeUpdatePopupRef}
        onClose={() => {
          fetchNoticeList(pageNumber)
        }}
      />
    </>
  )
}

const PageHeaderWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px;
`
