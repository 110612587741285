import React, { ReactElement, useCallback } from "react"
import { NewsLetterType } from "../../../types/NewsLetterType"
import { Button, Stack } from "@mui/material"
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import { PageType } from "../../../types/PageType"

function createColumns(handleClickOnDisplay:(item:NewsLetterType)=>void, handleClickShowArticle:(item:NewsLetterType)=>void): any[] {
  return [
    {
      field: "id",
      headerName: "뉴스 ID",
      minWidth: 130,
      maxWidth: 160,
      valueGetter: (params: any) => `${params.row.id}`,
    },
    {
      field: "onDisplay",
      headerName: "노출상태",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 60,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.onDisplay ? "노출" : "숨김"}`,
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"text"}
            color={params.row.onDisplay ? "success" : "error"}
            onClick={()=>handleClickOnDisplay(params.row)}>
            {params.row.onDisplay ? "노출" : "숨김"}
          </Button>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "뉴스 title",
      flex: 1,
      valueGetter: (params: any) => `${params.row.title}`,
    },
    {
      field: "publishDate",
      headerName: "뉴스 발매일",
      minWidth: 150,
      valueGetter: (params: any) => `${params.row.publishDate}`,
    },
    {
      field: "url",
      headerName: "사이트 이동",
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"text"}
            onClick={()=>handleClickShowArticle(params.row)}>
            이동하기
          </Button>
        </div>
      ),
    },
  ]
}
export const NewsLetterTable:React.FC<{
  content:PageType<NewsLetterType>
  loading:boolean,
  selectedIds:string[]
  fetch:(params:{page:number, size:number})=>void
  onClickRowItem:(item:NewsLetterType)=>void
  onSelectionModelChange:(ids:(string|number)[])=>void
  components:ReactElement[]
}> = (props)=>{

  const handleClickShowArticle = useCallback((item:NewsLetterType)=>{
    window.open(item.url,  "_blank", "noopener, noreferrer")
  }, [])

  const CustomToolbar = ()=> {
    return (
      <GridToolbarContainer style={{justifyContent: "flex-end"}}>
        {props.components}
      </GridToolbarContainer>
    );
  }


  return (
   <>
     <Stack spacing={1}>
       <div style={{height: 1200, width: "100%"}}>
         <DataGrid
           components={{Toolbar: CustomToolbar}}
           getRowId={(row) => row.id!}
           columns={createColumns(props.onClickRowItem, handleClickShowArticle)}
           pagination
           paginationMode="server"
           onPageChange={(page) => {
             props.fetch({page:page, size: props.content.pageable?.pageSize || 25})
           }}
           onPageSizeChange={(size) => {
             props.fetch({page: 0, size})
           }}
           page={props.content.pageable?.pageNumber || 0}
           pageSize={props.content.pageable?.pageSize || 25}
           rowCount={props.content?.totalElements || 0}
           rows={props.content?.content || []}
           checkboxSelection
           disableSelectionOnClick={true}
           selectionModel={props.selectedIds}
           onSelectionModelChange={props.onSelectionModelChange}
           loading={props.loading}
         />
       </div>
     </Stack>
   </>
 )
}
