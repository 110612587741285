import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import UpgradeItemTableHeader from "./TableHeader"
import UpgradeItemTableRow from "./TableBody/TableRow"
import styled from "@emotion/styled"
import { UpgradeServiceItemType } from "types/UpgradeServiceOrderType"
import { numberWithCommas } from "utils/NumberUtils"
import { useMemo, useState } from "react"
import { UpgradeItemEditModal } from "pages/UpgradeService/UpgradeItem/UpgradeItemEditModal"
import { isNotNil } from "utils/validationUtils"
import { isNil } from "lodash"

type Props = {
  selectedUpgradeItemIndexList: number[]
  upgradeItemList: UpgradeServiceItemType[]
  selectRow: (index: number) => void
  toggleAllUpgradeItem: () => void
  isAllChecked: boolean
  onEditUpgradeServiceItem: (item: UpgradeServiceItemType, index: number) => void
}

export const UpgradeItemTable = ({
  upgradeItemList,
  selectedUpgradeItemIndexList,
  selectRow,
  toggleAllUpgradeItem,
  isAllChecked,
  onEditUpgradeServiceItem,
}: Props) => {
  const [selectedUpgradeItem, setSelectedUpgradeItem] = useState<{ item?: UpgradeServiceItemType; index?: number }>({
    item: undefined,
    index: undefined,
  })
  const totalAmount = useMemo(() => {
    return upgradeItemList.reduce((prev, acc) => prev + acc.fee, 0)
  }, [upgradeItemList])

  return (
    <>
      {!!selectedUpgradeItem.item && isNotNil(selectedUpgradeItem.index) && (
        <UpgradeItemEditModal
          upgradeItemList={upgradeItemList}
          item={selectedUpgradeItem.item}
          closeModal={() => setSelectedUpgradeItem({ item: undefined, index: undefined })}
          onEdit={(newItem) => {
            if (isNil(selectedUpgradeItem.index)) return

            onEditUpgradeServiceItem(newItem, selectedUpgradeItem.index)
          }}
        />
      )}
      <ListTable style={{ minWidth: "unset" }}>
        <UpgradeItemTableHeader isAllChecked={isAllChecked} toggleAllUpgradeItem={toggleAllUpgradeItem} />
        <TableBody>
          {upgradeItemList.map((upgradeItem, index) => (
            <UpgradeItemTableRow
              key={index}
              upgradeItem={upgradeItem}
              onSelect={() => selectRow(index)}
              onClick={() => setSelectedUpgradeItem({ item: upgradeItem, index })}
              isChecked={selectedUpgradeItemIndexList.includes(index)}
            />
          ))}
        </TableBody>
        <FooterWrapper>
          <FooterLabel>총 비용</FooterLabel>
          <FooterValue>{numberWithCommas(totalAmount)}원</FooterValue>
        </FooterWrapper>
      </ListTable>
    </>
  )
}

const FooterWrapper = styled.div`
  margin-bottom: -4px;
  display: inline-flex;
`

const FooterLabel = styled.div`
  padding: 8px 20px;
  background: #eeeeee;
  font-weight: 700;
  font-size: 16px;
  width: 156px;
`

const FooterValue = styled.div`
  display: flex;
  padding: 8px 20px;
  algin-items: center;
  font-size: 15px;
`
