import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const ListTableHeaderItem = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

export default ListTableHeaderItem

const Wrapper = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  flex-shrink: 0;
  white-space: pre-wrap;

  :not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
`
