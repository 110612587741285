import React, { useCallback, useEffect, useMemo, useState } from "react"
import { ProvisionalPreSettlementDetailType, ProvisionalPreSettlementType } from "../../../types/PreSettlementType"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { ProductInfoView } from "./ProductInfoView"
import { TitleView } from "./TitleView"
import { InputProvisionalSettlementView } from "./InputProvisionalSettlementView"
import { isNil } from "../../../utils/validationUtils"
import {
  getProvisionalPreSettlementDetailInfo,
  notifyProvisionalPreSettlementsResult,
  rejectProvisionalPreSettlements,
  writeProvisionalPreSettlements,
} from "../../../apis/provisionalPreSettlementAPI"
import { toast } from "react-toastify"
import { precisionRound } from "../../../utils/NumberUtils"
import { getSellOrder, transformToConsigmentSellOrder } from "../../../apis/sellOrderAPI"
import { ProvisionalPreSettlementInfoView } from "./ProvisionalPreSettlementInfoView"
import { SellOrderType } from "../../../types/SellOrderType"

type EditProvisionalPreSettlementProps = {
  open:boolean;
  selectedProvisionalPreSettlementId: ProvisionalPreSettlementType['id']
  handleClose:()=>void;
}
export const EditProvisionalPreSettlement:React.FC<EditProvisionalPreSettlementProps> = (props)=>{
  const [preCalculatedBasePrice, setPreCalculatedBasePrice] = useState(0);
  const [isRatio, setIsRatio] = useState(true)
  const [preSettlementValue, setPreSettlementValue] = useState(0)
  const [sellOrder, setSellOrder] = useState<SellOrderType>()

  const [detailInfo, setDetailInfo] = useState<ProvisionalPreSettlementDetailType|null>(null)
  const onPressCompleteWrite = useCallback(async ()=>{

    if(isNil(detailInfo)) return;

    const checked=  window.confirm('견적서를 작성하시겠습니까?')
    if(!checked) return;


    try{
      const getFinalPrice = ():number=>{

        if(isRatio){
          //정률
          return preCalculatedBasePrice * (preSettlementValue / 100)
        }

        return preSettlementValue;
      }

      await writeProvisionalPreSettlements(props.selectedProvisionalPreSettlementId,{
        estimatedPriceAtRequest:detailInfo.estimatedPriceAtRequest,
        standardPriceForPreSettlement: preCalculatedBasePrice,
        calculationType:isRatio ? '정률':'정가',
        amount:isRatio ? 0 : preSettlementValue,
        rate:isRatio ? preSettlementValue : 0,
        preSettlementPrice:getFinalPrice()
      })

      toast.success('견적서 작성이 완료되었습니다')
      props.handleClose()
    }catch (ex){
      toast.error(`선정산 견적 작성에 실패했습니다.\n${(ex as Error).message}`)
    }
  }, [detailInfo, isRatio, preCalculatedBasePrice, preSettlementValue, props])

  const onPressNotifyResult = useCallback(async ()=>{
    if(isNil(detailInfo)) return;

    const checked=  window.confirm('선정산 견적을 고객에게 안내하시겠습니까? ')
    if(!checked) return;

    try{
      await notifyProvisionalPreSettlementsResult(props.selectedProvisionalPreSettlementId)
      toast.success('고객 안내가 완료되었습니다')
      props.handleClose()
    }catch(ex){
      toast.error('고객 안내에 실패했습니다')
    }
  }, [detailInfo, props])

  const onPressReject = useCallback(async ()=>{
    if(isNil(detailInfo)) return;

    const checked=  window.confirm('선정산을 반려하시겠습니까? ')
    if(!checked) return;

    try{
      await rejectProvisionalPreSettlements(props.selectedProvisionalPreSettlementId)
      toast.success('선정산 반려가 완료되었습니다')
      props.handleClose()
    }catch(ex){
      toast.error('선정산 반려에 실패했습니다')
    }

  }, [detailInfo, props])

  const onPressTransformToConsigmentSellOrder = useCallback(async ()=>{
    if(detailInfo===null) return;

    const checked = window.confirm('위탁주문으로 변경 하시겠습니까?');

    if(!checked) return;



    try{
      await transformToConsigmentSellOrder(detailInfo.orderId)
      toast.success('위탁판매로 전환을 완료하였습니다.')
      props.handleClose()
    }catch(ex){
      toast.error('위탁판매 전환에 실패했습니다.')
    }

  }, [detailInfo, props])

  useEffect(()=>{
    if(!props.open) return ;
    setDetailInfo(null)
    getProvisionalPreSettlementDetailInfo(props.selectedProvisionalPreSettlementId).then((result)=>{
      setDetailInfo(result);
      setIsRatio(result.status === '작성대기'? true : result.calculationType === '정률')
      setPreSettlementValue(result.status === '작성대기'? 0: (result.calculationType === '정률'? precisionRound(result.rate * 100) : result.preSettlementPrice))
      setPreCalculatedBasePrice(result.status === '작성대기' ? (result.estimatedPriceAtRequest.median ?? 0) :result.standardPriceForPreSettlement)

      getSellOrder(result.orderId).then(setSellOrder)
    })
  }, [props.selectedProvisionalPreSettlementId, props.open])

  const provisionalSettlementPerson = useMemo(()=>{
    if(isNil(detailInfo)) return '-';

    if(detailInfo.status === '작성대기'){
      return '-'
    }

    if(detailInfo.status === '지급반려'){
      return detailInfo.rejectedBy.name
    }

    return detailInfo.estimatedBy.name
  }, [detailInfo])

  return (
    <Dialog open={props.open} maxWidth={'xl'} fullWidth onClose={props.handleClose}>

      <DialogTitle>
       <TitleView
         status={detailInfo?.status ?? '작성대기'}
         provisionalSettlementPerson={provisionalSettlementPerson}
         alertPerson={detailInfo?.status === '안내완료' ? detailInfo.notifiedBy.name : '-'}
       />
      </DialogTitle>

      <DialogContent>
        {detailInfo !== null && (
          <>
            <ProductInfoView item={detailInfo} sellOrder={sellOrder}/>
            {detailInfo.status === '위탁전환대기' || detailInfo.status === '위탁전환완료' ? (
              <ProvisionalPreSettlementInfoView item={detailInfo}/>
            ) : (
              <InputProvisionalSettlementView
                expectedPriceRange={{
                  min:detailInfo.estimatedPriceAtRequest.min,
                  max:detailInfo.estimatedPriceAtRequest.max
                }}
                recommendedSellPrice={detailInfo.estimatedPriceAtRequest.median}
                preCalculatedBasePrice={preCalculatedBasePrice}
                setPreCalculatedBasePrice={setPreCalculatedBasePrice}
                isRatio={isRatio}
                setIsRatio={setIsRatio}
                preSettlementValue={preSettlementValue}
                setPreSettlementValue={setPreSettlementValue}
              />
            )}
          </>
        )}


      </DialogContent>

      <DialogActions>
        {(detailInfo?.status === '위탁전환대기' )&& (
          <Button
            size={'medium'}
            variant={'contained'}
            onClick={onPressTransformToConsigmentSellOrder}>
            위탁 전환하기
          </Button>
        )}

        {detailInfo?.status === '위탁전환완료' && (
          <Button
            size={'medium'}
            variant={'contained'}
            onClick={props.handleClose}>
            닫기
          </Button>
        )}
        {(detailInfo?.status === '안내완료' || detailInfo?.status === '지급반려' || detailInfo?.status ==='작성완료' || detailInfo?.status === '작성대기') && (
          <>
            <Button
              size={'medium'}
              variant={'contained'}
              color={'error'}
              disabled={detailInfo?.status === '안내완료' || detailInfo?.status === '지급반려'}
              onClick={onPressReject}
            >
              지급 반려 안내
            </Button>

            <Button
              size={'medium'}
              variant={'contained'}
              disabled={detailInfo?.status === '안내완료' || detailInfo?.status === '지급반려'}
              onClick={onPressCompleteWrite}
            >
              견적 작성 완료
            </Button>


            <Button
              size={'medium'}
              variant={'contained'}
              disabled={detailInfo?.status !== '작성완료'}
              onClick={onPressNotifyResult}>
              견적 안내
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
