import { FunctionComponent, useEffect, useRef } from "react"

export const NoticeDetailFormMarkdownHtmlPreview: FunctionComponent<{ html: string }> = ({ html }) => {
  const markdownPreviewRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const ifrmaeContentDocument = markdownPreviewRef.current?.contentDocument
    if (ifrmaeContentDocument) {
      ifrmaeContentDocument.open()
      ifrmaeContentDocument.write(html)
      ifrmaeContentDocument.close()
    }
  }, [html])

  return (
    <iframe
      ref={markdownPreviewRef}
      style={{
        width: "100%",
        height: "100%",
        border: "none",
      }}
      title="NoticeDetailUpdatePopupFormMarkdownHtmlPreview"
    />
  )
}
