import {ModelType} from "./ModelType";

export const enum ContentBlockTypeCode {
    HTML = 'HTML',
    H1 = 'H1',
    H2 = 'H2',
    TEXT = 'TEXT',
    DASH = 'DASH',
    HR = 'HR',
    MARGIN = 'MARGIN',
    REFERENCE = 'REFERENCE',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    YOUTUBE = 'YOUTUBE',
    GALLERY = 'GALLERY',
    CONTENT = 'CONTENT',
    DETAIL_MODEL = 'DETAIL_MODEL',
    PRODUCT = 'PRODUCT',
}

export declare interface Article {
    id?: number,
    category?: string,
    title: string,
    titleSecondary?: string,
    mainImageUrl?: string,
    headerImageUrl?: string,
    headerVideoUrl?: string,
    body?: ContentBlock[],
    searchTags?: string,
    onDisplay: boolean,
    publishDate?: string,
    startedAt?: string,
    endedAt?: string,
    priority?: number,
    exposeRelatedProducts: boolean,
    exposeRelatedContents: boolean,
    authorId?: number,
    author?: Author,
    relatedModels?: ModelType[],
}
export declare interface Question {
    id?: number,
    iconUrl?: string,
    question?: string,
    answer?: ContentBlock[],
    html?: string,
    onDisplay?: boolean,
    publishDate?: string,
    startedAt?: string,
    endedAt?: string,
    authors?: Author[],
    priority?: number,
}
export declare interface ContentBlock {
    type: ContentBlockTypeCode,
    value?: string,
    margin?: boolean,
    resources?: string[],
    poster?: string[],
    caption?: string,
    contentIds?: number[],
    detailModelIds?: number[],
    productIds?: number[],
}
export declare interface Author {
    id?: number,
    name?: string,
    profile?: string,
    job?: string,
    belong?: string,
    bio?: string,
}