import { PageType } from "types/PageType"
import { ProductDetailTextListItemType } from "types/ProductDetailTextListItemType"
import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"

export const postProductDetailText = (body: {
  title: string
  linkUrl: string
  onDisplay: boolean
  startedAt: string
  endedAt: string | null
  showDomesticUser: boolean
  showForeignUser: boolean
}) => {
  return httpPost(`/product-notices`, body)
}

export const getProductDetailTextList = ({
  size = 20,
  page,
}: {
  size?: number
  page: number
}): Promise<PageType<ProductDetailTextListItemType>> => {
  return httpGet(`/product-notices`, { size, page })
}

export const putProductDetailText = (
  id: number,
  body: {
    title: string
    linkUrl: string
    onDisplay: boolean
    startedAt: string
    endedAt: string | null
    showDomesticUser: boolean
    showForeignUser: boolean
  }
) => {
  return httpPut(`/product-notices/${id}`, body)
}

export const deleteProductDetailText = (id: number) => {
  return httpDelete(`/product-notices/${id}`)
}
