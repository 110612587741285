import PopupPage from "components/Popup/PopupPage"
import styled from "@emotion/styled"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import Button from "components/Button"
import {useCallback, useState} from "react"
import {UserDetailType} from "types/UserType"
import {getUserDetail,} from "apis/userAPI"
import {toast} from "react-toastify"
import {useMount} from "react-use"
import {getUserCollection} from "../../../apis/userCollectionAPI";
import {UserCollectionType} from "../../../types/UserCollectionType";
import UserCollectionContent from "./UserCollectionContent";
import {DetailModelType} from "../../../types/DetailModelType";
import {getDetailModel} from "../../../apis/detailModelAPI";

type Props = {
  id: number
  closePopup: () => void
}

export type UserOrderList<T> = {
  onGoingList: T[]
  finishedList: T[]
}


const UserCollectionPopup = ({ id, closePopup }: Props) => {
  const [user, setUser] = useState<UserDetailType>()
  const [detailModel, setDetailModel] = useState<DetailModelType>()
  const [userCollection, setUserCollection] = useState<UserCollectionType>({})

  const fetchUserCollection = useCallback(() => {
    getUserCollection(id)
      .then((userCollection) => {
        setUserCollection(userCollection)

        if (userCollection?.userId)
          getUserDetail(userCollection.userId.toString())
              .then((user) => setUser(user))
              .catch((err) => {
                toast.error("회원 정보 조회에 실패했습니다.")
                console.log("Error", err)
              })

        if (userCollection?.detailModelId)
          getDetailModel(userCollection?.detailModelId)
              .then((detailModel) => setDetailModel(detailModel))
              .catch((err) => {
                toast.error("상세모델 조회에 실패했습니다.")
                console.log("Error", err)
              })

      })
      .catch((err) => {
        toast.error("회원 콜렉션 정보 조회에 실패했습니다.")
        console.log("Error", err)
      })
  }, [id])


  useMount(() => {
    fetchUserCollection()
  })

  if (!userCollection) {
    return <></>
  }

  return (
    <PopupPage isOpen={!!id}>
      <Wrapper>
        <PopupPageTitle>콜렉션 정보</PopupPageTitle>
        <PopupPageScrollContainer>
          <UserCollectionContent
            user={user}
            userCollection={userCollection}
            detailModel={detailModel}
          />
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <Button buttonStyle="black-border" onClick={closePopup}>
            닫기
          </Button>
        </PopupPageFooter>
      </Wrapper>
    </PopupPage>
  )
}

export default UserCollectionPopup

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`
