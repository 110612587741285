import styled from "@emotion/styled"
import CheckBox from "components/Checkbox"
import { isNaN } from "lodash"

type Props = {
  changeProductGuaranteeState: (
    key: "repurchaseGuarantee" | "repurchaseGuaranteePeriod" | "repurchaseGuaranteeRate",
    value: boolean | number | undefined
  ) => void
  repurchaseGuarantee?: boolean
  repurchaseGuaranteePeriod?: number
  repurchaseGuaranteeRate?: number
  toggleReSellGuaranteeOption: () => void
}

export const ReSellGuaranteedOption = ({
  changeProductGuaranteeState,
  repurchaseGuarantee,
  repurchaseGuaranteePeriod = 90,
  repurchaseGuaranteeRate = 0.9,
  toggleReSellGuaranteeOption,
}: Props) => {
  return (
    <table className="basic">
      <tbody>
        <tr>
          <td className={"header"}>매입보증</td>
          <td className={"header"}>매입보증 기간</td>
          <td className={"header"}>최대 할인율</td>
        </tr>
        <tr>
          <td>
            <CheckBox checked={repurchaseGuarantee} onChange={() => toggleReSellGuaranteeOption()} />
          </td>
          <td>
            <Input
              disabled={!repurchaseGuarantee}
              value={repurchaseGuaranteePeriod}
              onChange={(e) => {
                const numberValue = Number(e.target.value)
                if (isNaN(numberValue)) {
                  return
                }

                changeProductGuaranteeState("repurchaseGuaranteePeriod", numberValue)
              }}
            />
            일
          </td>
          <td>
            <Input
              disabled={!repurchaseGuarantee}
              value={repurchaseGuaranteeRate * 100}
              onChange={(e) => {
                const numberValue = Number(e.target.value)
                if (isNaN(numberValue) || numberValue > 100 || numberValue < 0) {
                  return
                }

                changeProductGuaranteeState("repurchaseGuaranteeRate", numberValue / 100)
              }}
            />
            %
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const Input = styled.input`
  outline: none;
  padding: 5px;
  width: 50px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  :disabled {
    background: rgba(0, 0, 0, 0.1);
  }
`
