import {httpGet, httpPost, httpPut} from "../utils/httpAPI"
import { ResellItemType } from "types/ResellItemType"
import { PageType } from "types/PageType"
import { BrandType } from "types/BrandType"
import {DetailModelType} from "../types/DetailModelType";

export interface SearchParams {
  detailModelId?: number,
  isDetailModelNull?: boolean,
  size?: number
  page?: number
}

interface KreamProductAssignType {
  detailModel: DetailModelType
}

export const getKreamProductPage = (params?: SearchParams) =>
  httpGet<PageType<ResellItemType>>("/kream/products", params)
export const assignDetailModel = (id: number, dto: KreamProductAssignType) =>
  httpPost<ResellItemType>(`/kream/products/${id}/assign-detail-model`, dto)
