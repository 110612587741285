import React from 'react';

interface TableauVizProps {
    src: string;
    width?: string;
    height?: string;
    hideTabs?: boolean;
    toolbar?: string;
}

const TableauViz: React.FC<TableauVizProps> = ({src, width = '100%', height = '500px', hideTabs = false, toolbar = 'top'}) => {
    return (
        <div dangerouslySetInnerHTML={{
            __html: "        <tableau-viz\n" +
                "            id='tableau-viz'\n" +
                `            src=${src}\n` +
                `            width=${width}\n` +
                `            height=${height}\n` +
                `            hide-tabs=${hideTabs}\n` +
                `            toolbar=${toolbar}\n` +
                "        ></tableau-viz>"
        }}></div>
    );
};

export default TableauViz;