import { useState, useEffect, useCallback } from "react"

// 타입 정의
type PrintMargins = {
  pageTop: number,
  pageLeft: number,
  dateTop: number,
  exteriorTop: number,
  resultLeft: number,
  resultKoLeft: number,
  resultFontSize: number,
  performanceTop: number,
  interiorTop: number,
  descriptionFontSize:number,
};

export type PrintMarginsKey =
  | "pageTop"
  | "pageLeft"
  | "dateTop"
  | "exteriorTop"
  | "resultLeft"
  | "resultKoLeft"
  | "resultFontSize"
  | "performanceTop"
  | "interiorTop"
  | "descriptionFontSize"

const defaultMargins: PrintMargins = {
  pageTop: 41,
  pageLeft: 41,
  dateTop: 28,
  exteriorTop: 13,
  resultLeft: 27,
  resultKoLeft: 9,
  resultFontSize: 7,
  performanceTop: 14,
  interiorTop: 17,
  descriptionFontSize: 8,
};

// 커스텀 훅 정의
export const useDiagnosisPrintMargin = () => {
  const [margins, setMargins] = useState<PrintMargins>(() => {
    const savedMargins = localStorage.getItem("DiagnosisPrintMargin");
    return savedMargins ? { ...defaultMargins, ...JSON.parse(savedMargins)} : defaultMargins;
  });

  useEffect(() => {
    localStorage.setItem("DiagnosisPrintMargin", JSON.stringify(margins));
  }, [margins]);

  const getMargin = useCallback( (key: PrintMarginsKey): number => {
    return margins[key];
  },[margins]);


  const setMargin = (key: PrintMarginsKey, value: number) => {
    setMargins(prevMargins => ({
      ...prevMargins,
      [key]: value,
    }));
  };

  const resetMargin = () => {
    if( window.confirm("수정하신 프린트 여백 및 글씨 크기 설정이 초기화 됩니다.\n 초기화 하시겠습니까?") ){
      setMargins(prevMargins => ({
        ...prevMargins, ...defaultMargins
      }))
    }
  }



  return {
    margins,
    resetMargin,
    getMargin,
    setMargin,
  };
};