import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import "../../../App.css"
import {Author, Article, ContentBlock, ContentBlockTypeCode, Question} from "types/MagazineType"
import RowBlock from "../../../components/RowBlock"
import { DatePicker, DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { toast } from "react-toastify"
import { createArticle, deleteArticle, getArticle, getAuthors, getArticles, updateArticle } from "../../../apis/magazineAPI"
import ImageUploadButton from "../../../components/ImageUploadButton"
import { upload } from "../../../apis/resourceAPI"
import { DetailModelType } from "types/DetailModelType"
import { getDetailModels } from "../../../apis/detailModelAPI"
import { ProductType } from "types/ProductType"
import { getProducts } from "../../../apis/productAPI"
import ArticleHtmlPreview from "./ArticleHtmlPreview";
import ArticleJsonEditor from "./ArticleJsonEditor";
import SearchDetailModelPopup from "../../../components/Popup/SearchDetailModelPopup";
import styled from "@emotion/styled";
import SearchArticlesPopup from "../../../components/Popup/SearchMagazineArticlePopup";
import SearchQuestionsPopup from "../../../components/Popup/SearchMagazineQuestionPopup";
import { ArticleContentBlockEditor } from "./ArticleContentBlockEditor"

const viverBaseUrl = "https://www.viver.co.kr"


type EditArticlePopupProps = {
  open: boolean
  selectedArticle?: Article
  handleClose: Function
}

const EditArticlePopup: React.FC<EditArticlePopupProps> = ({ open, selectedArticle, handleClose }) => {
  // @ts-ignore
  const [article, setArticle] = useState<Article>(selectedArticle || {})
  const [editable, setEditable] = useState<boolean>(false)
  const [authors, setAuthors] = useState<Author[]>(selectedArticle?.author ? [selectedArticle?.author] : [])
  const [body, setBody] = useState<string>("[]")
  const [html, setHtml] = useState<string>()
  const [selectedText, setSelectedText] = useState<string>()

  const [editorMode, setEditorMode] = useState<"json" | "contentBlock">("contentBlock")
  const [openSearchDetailModelPopup, setOpenSearchDetailModelPopup] = useState<boolean>(false)
  const [openSearchArticlesPopup, setOpenSearchArticlesPopup] = useState<boolean>(false)
  const [openSearchQuestionsPopup, setOpenSearchQuestionsPopup] = useState<boolean>(false)
  const [embedDetailModels, setEmbedDetailModels] = useState<DetailModelType[]>([])
  const [embedProducts, setEmbedProducts] = useState<ProductType[]>([])
  const [embedArticles, setEmbedArticles] = useState<Article[]>([])

  const handleMainImageUrl = (image: FileList) => {
    upload(image, [{key: "path", value: "resources/content"}]).then((result) => {
      setArticle({ ...article, mainImageUrl: result.url })
    })
  }
  const handleHeaderImageUrl = (image: FileList) => {
    upload(image, [{key: "path", value: "resources/content"}]).then((result) => {
      setArticle({ ...article, headerImageUrl: result.url })
    })
  }
  const handleHeaderVideoUrl = (video: FileList) => {
    upload(video, [{key: "path", value: "resources/content"}]).then((result) => {
      setArticle({ ...article, headerVideoUrl: result.url })
    })
  }

  const addArticleLink = () => {
    if (!selectedText)
      toast.error("먼저 링크를 삽입할 텍스트를 선택하세요.")
    else {
      setOpenSearchArticlesPopup(true)
    }
  }

  const addQuestionLink = () => {
    if (!selectedText)
      toast.error("먼저 링크를 삽입할 텍스트를 선택하세요.")
    else {
      setOpenSearchQuestionsPopup(true)
    }
  }

  const addBlocks = (...added: ContentBlock[]) => {
    try {
      let blocks: ContentBlock[] = JSON.parse(body).concat(added)
      article.body = blocks
      setBody(JSON.stringify(blocks))
    } catch (err) {
      toast.error("본문 형식이 올바르지 않습니다.")
    }
  }

  const addDetailModelBlock = () => {
    setOpenSearchDetailModelPopup(true)
  }
  /*
    const addProductBlock = () => {
        addBlock({type: ArticleBlockTypeCode.PRODUCT, productIds: [
                embedProducts.pop()?.id!
            ]})
    }
    const addArticleBlock = () => {
        addBlock({type: ContentBlockTypeCode.CONTENT, contentIds: [
                embedArticles.pop()?.id!
        ]})
    }
*/
  const remove = (e: any) => {
    deleteArticle(article?.id!)
      .then((res) => {
        toast.success("취소 완료")
        handleClose()
      })
      .catch((error) => {
        toast.error(`취소 실패 (${error.message || error})`)
      })
  }
  const save = (e: any) => {
    let newArticle = { ...article, body: JSON.parse(body), html: html }

    // @ts-ignore
    if (article?.id) {
      newArticle = { ...newArticle, priority: 1000 - article?.id }
      updateArticle(article?.id!, newArticle)
        .then((res) => {
          toast.success("수정 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`수정 실패 (${error.message || error})`)
        })
    } else {
      createArticle(newArticle)
        .then((res) => {
          toast.success("작성 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`등록 실패 (${error.message || error})`)
        })
    }
  }

  useEffect(() => {
    if (open) {
      // @ts-ignore
      getAuthors().then((res) => setAuthors(res))
      if (selectedArticle) {
        // @ts-ignore
        getArticle(selectedArticle.id!).then((res) => {
          // @ts-ignore
          const it: Article = {...res, authorId: res.author.id}
          setArticle(it)
          article.body = it.body
          setBody(JSON.stringify(it.body))
            // @ts-ignore
          const detailModelIds: number[] =
            it.body
              ?.filter((it) => it.type === ContentBlockTypeCode.DETAIL_MODEL)
              ?.flatMap((it) => it.detailModelIds) || []
          // @ts-ignore
          const productIds: string[] =
            it.body?.filter((it) => it.type === ContentBlockTypeCode.PRODUCT)?.flatMap((it) => it.productIds) || []
          // @ts-ignore
          const articleIds: number[] =
            it.body?.filter((it) => it.type === ContentBlockTypeCode.CONTENT)?.flatMap((it) => it.contentIds) || []

          if (detailModelIds.length)
            getDetailModels({ id: detailModelIds }).then((res) => setEmbedDetailModels(res.content))
          if (productIds.length) getProducts({ id: productIds }).then((res) => setEmbedProducts(res.content))
          if (articleIds.length) getArticles({ id: articleIds }).then((res) => setEmbedArticles(res.content))
        })
        setEditable(true)
      }
    } else {
      // @ts-ignore
      setArticle({})
      setBody("[]")
      setAuthors([])
      setEditable(true)
    }
  }, [open])

  // @ts-ignore
  return (
    <Dialog open={open} maxWidth={"lg"} fullWidth>
      <SearchDetailModelPopup
        open={openSearchDetailModelPopup}
        handleClose={(selected: DetailModelType) => {
          if (selected) {
            setEmbedDetailModels([...embedDetailModels, selected])
            addBlocks({ type: ContentBlockTypeCode.DETAIL_MODEL, detailModelIds: [selected.id!] })
          }
          setOpenSearchDetailModelPopup(false)
        }}
      />
      <SearchArticlesPopup
        open={openSearchArticlesPopup}
        handleClose={(selectedArticle: Article) => {
          if (selectedArticle) {
            if (selectedText != null) {
              const newBody = body.replace(selectedText, `<a href=\\\"${viverBaseUrl}/contents/${selectedArticle.id}\\\">${selectedText}</a>`)
              setArticle({...article, body: JSON.parse(newBody) as ContentBlock[]})
            }
          }
          setOpenSearchArticlesPopup(false)
        }}
      />
      <SearchQuestionsPopup
        open={openSearchQuestionsPopup}
        handleClose={(selectedQuestion: Question) => {
          if (selectedQuestion) {
            if (selectedText != null) {
              const newBody = body.replace(selectedText, `<a href=\\\"${viverBaseUrl}/contents/qna/${selectedQuestion.id}\\\">${selectedText}</a>`)
              setArticle({...article, body: JSON.parse(newBody) as ContentBlock[]})
            }
          }
          setOpenSearchQuestionsPopup(false)
        }}
      />
      <DialogTitle>
        <Typography fontWeight={"bold"} fontSize={"30px"}>
          {selectedArticle ? "아티클 상세" : "아티클 등록"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction={"row"}>
          <Grid item xs={6}>
            <Stack spacing={3} direction={"column"}>
              {/*<RowBlock title={"상태"}><Typography>{content?.status || '신규'}</Typography></RowBlock>*/}
              <RowBlock title={"리소스"}>
                <Grid container direction={"row"}>
                  <ImageUploadButton
                    width={"100px"}
                    height={"100px"}
                    objectFit={"cover"}
                    text={"카드 이미지"}
                    handleImage={handleMainImageUrl}
                    imageUrl={article.mainImageUrl}
                    handleDeleteImage={() => {
                      setArticle({ ...article, mainImageUrl: undefined })
                    }}
                  />
                  <ImageUploadButton
                    width={"100px"}
                    height={"100px"}
                    objectFit={"cover"}
                    text={"헤더 이미지"}
                    handleImage={handleHeaderImageUrl}
                    imageUrl={article.headerImageUrl}
                    handleDeleteImage={() => {
                      setArticle({ ...article, headerImageUrl: undefined })
                    }}
                  />
                  <ImageUploadButton
                    width={"100px"}
                    height={"100px"}
                    objectFit={"cover"}
                    text={"헤더 비디오"}
                    handleImage={handleHeaderVideoUrl}
                    imageUrl={article.headerVideoUrl}
                    handleDeleteImage={() => {
                      setArticle({ ...article, headerVideoUrl: undefined })
                    }}
                  />
                </Grid>
              </RowBlock>
              <RowBlock title={"카테고리"}>
                <TextField
                  size={"small"}
                  fullWidth
                  multiline={false}
                  disabled={!editable}
                  value={article.category}
                  onChange={(e) => {
                    setArticle({ ...article, category: e.target.value })
                  }}
                />
              </RowBlock>
              <RowBlock title={"제목"}>
                <TextField
                  size={"small"}
                  fullWidth
                  minRows={2}
                  multiline={true}
                  disabled={!editable}
                  value={article.title}
                  onChange={(e) => {
                    setArticle({ ...article, title: e.target.value })
                  }}
                />
              </RowBlock>
              <RowBlock title={"부제"}>
                <TextField
                  size={"small"}
                  fullWidth
                  multiline={true}
                  disabled={!editable}
                  value={article.titleSecondary}
                  onChange={(e) => {
                    setArticle({ ...article, titleSecondary: e.target.value })
                  }}
                />
              </RowBlock>
              <RowBlock title={"발행 일자"}>
                  <DatePicker
                    disabled={!editable}
                    onChange={(e) =>
                      setArticle({ ...article, publishDate: e?.toISODate() ?? DateTime.now().toISODate() })
                    }
                    value={DateTime.fromISO(article.publishDate ?? DateTime.now().toISODate())}
                    inputFormat={"yyyy-MM-dd"}
                    renderInput={(props) => <TextField size={"small"} {...props} />}
                  />
              </RowBlock>
              <RowBlock title={"에디터 모드"}>
                JSON 편집기
                <Switch
                  checked={editorMode === "contentBlock"}
                  onChange={(e, check) => {
                    setEditorMode(check ? "contentBlock" : "json")
                  }}
                />
                블록 편집기
              </RowBlock>
              <RowBlock title={"본문"}>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.H1, value: "타이틀" })}
                >
                  타이틀
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.H2, value: "서브타이틀" })}
                >
                  서브타이틀
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.TEXT, value: "텍스트" })}
                >
                  텍스트
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks(
                    { type: ContentBlockTypeCode.MARGIN, value: "80" },
                    { type: ContentBlockTypeCode.DASH }
                  )}
                >
                  단락 구분선
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.HR })}
                >
                  얇은 구분선
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.MARGIN, value: "24" })}
                >
                  여백
                </Button>
                <label htmlFor={`contained-button-file-image`}>
                  <FileInput accept="image/*" id={`contained-button-file-image`} multiple type="file" onChange={(params) => {
                    if (params?.target?.files != null) {
                      upload(params.target.files, [{key: "path", value: "resources/content"}])
                        .then((result) => {
                          addBlocks(
                              { type: ContentBlockTypeCode.MARGIN, value: "24" },
                              { type: ContentBlockTypeCode.IMAGE, resources: [result.url], caption: "", margin: true },
                              { type: ContentBlockTypeCode.MARGIN, value: "24" },
                          )
                        })
                    }
                    params.target.value = ""
                  }}/>
                  <Button component="span" size={"small"} variant={"outlined"}>
                    이미지
                  </Button>
                </label>

                <label htmlFor={`contained-button-file-video`}>
                  <FileInput accept="video/*" id={`contained-button-file-video`} multiple type="file" onChange={(params) => {
                    if (params?.target?.files != null) {
                      upload(params.target.files, [{key: "path", value: "resources/content"}])
                        .then((result) => {
                          addBlocks(
                            { type: ContentBlockTypeCode.MARGIN, value: "24" },
                            { type: ContentBlockTypeCode.VIDEO, resources: [result.url], caption: "", margin: false },
                            { type: ContentBlockTypeCode.MARGIN, value: "24" },
                          )
                        })
                    }
                    params.target.value = ""
                  }}/>
                  <Button component="span" size={"small"} variant={"outlined"}>
                    동영상
                  </Button>
                </label>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() =>
                  {
                    const id = window.prompt("본문에 삽입할 YouTube 링크를 입력하세요", "https://www.youtube.com/watch?v=IM_kwpFP-8g")
                        ?.split(/[=\/]/).pop()

                    if (!!id)
                      addBlocks(
                        { type: ContentBlockTypeCode.MARGIN, value: "24" },
                        { type: ContentBlockTypeCode.YOUTUBE, resources: [`${id}`], caption: "", margin: false },
                        { type: ContentBlockTypeCode.MARGIN, value: "24" },
                      )
                  }}
                >
                  유튜브
                </Button>
                <Button size={"small"} variant={"outlined"} onClick={() => addDetailModelBlock()}>
                  상세모델
                </Button>
                <Button
                    size={"small"}
                    variant={"outlined"}
                    onClick={() => addBlocks(
                        {type: ContentBlockTypeCode.MARGIN, value: "40"},
                        {type: ContentBlockTypeCode.REFERENCE, value: "이번 아티클은 OO에서 발표한 &lt;###&gt;을 바탕으로 작성되었습니다.", resources: ["https://www.viver.co.kr"]}
                    )}
                >
                  출처
                </Button>
                {editorMode === "json" && (
                  <>
                    <div style={{ height: 10 }} />
                    <Button size={"small"} variant={"outlined"} onClick={() => addArticleLink()}>
                      매거진 링크
                    </Button>
                    <Button size={"small"} variant={"outlined"} onClick={() => addQuestionLink()}>
                      Q&A 링크
                    </Button>
                    <div style={{ height: 10 }} />
                    <ArticleJsonEditor
                      initialContentBlock={article.body || []}
                      onChangeJson={(json) => {
                        setBody(json)
                      }}
                      onChangeCursorSelection={(text) => {
                        setSelectedText(text)
                      }}
                    />
                  </>
                )}
                {editorMode === "contentBlock" && (
                  <>
                    <div style={{ height: 10 }} />
                    <ArticleContentBlockEditor
                      contentBlocks={article.body || []}
                      embedDetailModels={embedDetailModels}
                      onChangeContentBlocks={(contentBlocks) => {
                        setArticle((article) => ({ ...article, body: contentBlocks }))
                        setBody(JSON.stringify(contentBlocks))
                      }}
                    />
                  </>
                )}
              </RowBlock>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} direction={"column"}>
              <RowBlock title={"홈 화면 노출"}>
                <Switch
                  checked={article.onDisplay}
                  disabled={!editable}
                  onChange={(e, check) => {
                    setArticle({ ...article, onDisplay: check })
                  }}
                />
              </RowBlock>
              <RowBlock title={"연관 항목 표시"}>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={article.exposeRelatedProducts}
                          disabled={!editable}
                          onChange={(e, check) => {
                            setArticle({ ...article, exposeRelatedProducts: check })
                          }}
                        />
                      }
                      label="연관 상품"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={article.exposeRelatedContents}
                          disabled={!editable}
                          onChange={(e, check) => {
                            setArticle({ ...article, exposeRelatedContents: check })
                          }}
                        />
                      }
                      label="연관 콘텐츠"
                    />
                  </FormGroup>
                </FormControl>
              </RowBlock>
              <RowBlock title={"작성자"}>
                <Select
                  fullWidth
                  size={"small"}
                  disabled={!editable}
                  value={"" + article.author?.id}
                  onChange={(e) => {
                    const updated = authors.find((it) => it.id == parseInt(e.target.value))
                    setArticle({ ...article, authorId: updated?.id, author: updated })
                  }}
                >
                  {authors.map((it) => (
                    <MenuItem value={it.id} key={it.id}>
                      {it.name}
                    </MenuItem>
                  ))}
                </Select>
              </RowBlock>
              <RowBlock title={"게시 시각"}>
                <>
                    <DateTimePicker
                      label={"시작"}
                      disabled={!editable}
                      onChange={(e) => setArticle({ ...article, startedAt: e?.toISO() })}
                      value={(article.startedAt && DateTime.fromISO(article.startedAt)) || null}
                      inputFormat={"yyyy-MM-dd HH:mm"}
                      renderInput={(props) => <TextField size={"small"} sx={{ width: "50%" }} {...props} />}
                    />
                    <DateTimePicker
                      label={"종료"}
                      disabled={!editable}
                      onChange={(e) => setArticle({ ...article, endedAt: e?.toISO() })}
                      value={(article.endedAt && DateTime.fromISO(article.endedAt)) || null}
                      inputFormat={"yyyy-MM-dd HH:mm"}
                      renderInput={(props) => <TextField size={"small"} sx={{ width: "50%" }} {...props} />}
                    />
                </>
              </RowBlock>
              <RowBlock title={"미리보기"}>
                  <ArticleHtmlPreview
                    article={article}
                    body={body}
                    embedDetailModels={embedDetailModels}
                    embedProducts={embedProducts}
                    embedArticles={embedArticles}
                    onRender={(renderedHtml) => {setHtml(renderedHtml)}}
                  />
              </RowBlock>
              {article.id && (
                <RowBlock title={"URL"}>
                  <TextField
                    size={"small"}
                    fullWidth
                    value={`viver://contents/${article.id}`}
                    onFocus={(e) => e.target.select()}
                    onClick={async (e) => {
                      const text = `viver://contents/${article.id}`
                      if ("clipboard" in navigator) await navigator.clipboard.writeText(text)
                      else document.execCommand("copy", true, text)
                      toast.success("복사되었습니다.")
                    }}
                  />
                </RowBlock>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} disabled={!editable} variant={"contained"} color={"info"} onClick={save}>
          저장하기
        </Button>
        {/*{selectedContent && <Button size={"medium"} disabled={!editable} variant={"contained"} color={"error"} onClick={remove}>삭제</Button>}*/}
        <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const FileInput = styled('input')({
  display: 'none',
});

export default EditArticlePopup
