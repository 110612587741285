import { Box, Button, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { DateTime } from "luxon"
import ShopFrontPopupBannerAPI from "../../../../apis/shopFrontPopupBannerAPI"
import { ShopFrontPopupBannerType } from "../../../../types/ShopFrontPopupBannerType"
import { PageType } from "../../../../types/PageType"
import FrontPopTableFilter from "./FrontPopTableFilter"

function renderPreviewCell(params: any) {
  return (
      <Box style={{position: 'relative', height: 100, width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img src={params?.row?.imageUrl} style={{width: '100%', position: 'absolute'}} />
      </Box>
  )
}

function renderPreviewCellEnglish(params: any) {
  return (
      <Box style={{position: 'relative', height: 100, width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img src={params?.row?.imageUrlEnglish} style={{width: '100%', position: 'absolute'}} />
      </Box>
  )
}

function renderPreviewCellJapanese(params: any) {
  return (
      <Box style={{position: 'relative', height: 100, width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img src={params?.row?.imageUrlJapanese} style={{width: '100%', position: 'absolute'}} />
      </Box>
  )
}
function renderStatusCell(params: any) {
  if (params?.row?.postingStatus === "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}
function renderStopWatchingTodayCell(params: any) {
  return <Box>{params?.row?.isStopWatchingToday ? "오늘그만보기" : "닫기"}</Box>
}

function renderPostingPeriodCell(params: any) {
  const startedAt = DateTime.fromISO(params?.row?.startedAt)
  const endedAt = DateTime.fromISO(params?.row?.endedAt)

  return (
    <Stack direction={'column'} alignItems={"self-end"}>
      <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
    </Stack>
  )
}

interface FrontPopTableFilterState {
  postingStatus: string | null,
  page: number | null,
  size: number | null
}

const initFrontPopFilterState: FrontPopTableFilterState = {
  postingStatus: null,
  page: 0,
  size: 50
}

const FrontPopTable: React.FunctionComponent<{
  handleSelection: (ids: number[]) => void
  handleUpdateBanner: (banner: ShopFrontPopupBannerType) => void
  refreshCounter: number
}> = ({ handleSelection, handleUpdateBanner, refreshCounter }) => {
  const [tableFilter, setTableFilter] = useState<FrontPopTableFilterState>(initFrontPopFilterState)
  const [rows, setRows] = useState<PageType<ShopFrontPopupBannerType>>({content:[]})

  const getColumns = () => {
    return [
      {
        field: 'actions',
        type: 'actions',
        width: 10,
        getActions: (params: any) => ([
          <GridActionsCellItem
            icon = {<EditIcon />}
            label = {"수정"}
            onClick={() => {
              handleUpdateBanner(params.row)
            }}
            showInMenu
          />
        ])
      },
      {field: 'id', headerName: "ID", width: 70},
      // {field: 'priority', headerName: "노출순서", width: 80},
      {field: 'description', headerName: "설명", flex: 3},
      {field: 'createdAt', headerName: "등록 일시", width: 160, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
      {field: 'postingPeriod', headerName: "노출 기간", width: 160, renderCell: renderPostingPeriodCell},
      {field: 'preview', headerName: "미리보기(한국어)", width: 120, renderCell: renderPreviewCell},
      {field: 'previewEnglish', headerName: "미리보기(영어)", width: 120, renderCell: renderPreviewCellEnglish},
      {field: 'previewJapanese', headerName: "미리보기(일본어)", width: 120, renderCell: renderPreviewCellJapanese},
      {field: 'postingStatus', headerName: "진행 상태", width: 100, renderCell: renderStatusCell},
      {field: 'showDomesticUser',  width: 80,
        renderCell: (params: any) => (
          <div style={{textAlign: "center"}}>
            <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
          </div>
        ),
        renderHeader: () => (
          <div style={{  textAlign: "center", lineHeight: "1.2" }}>
            내국인 회원<br />노출
          </div>
        ),
      },
      { field: "showForeignUser", width: 80,
        renderCell: (params: any) => (
          <div
            style={{ textAlign: "center"  }}
          >
            <span>{params.row.showForeignUser ? "Y" : "N"}</span>
          </div>
        ),
        renderHeader: () => (
          <div style={{ textAlign: "center", lineHeight: "1.2" }}>
            외국인 회원<br />노출
          </div>
        ),
      },
      {field: 'isStopWatchingToday', headerName: "오늘 그만 여부", width: 100, renderCell: renderStopWatchingTodayCell},
    ]
  }

  const fetch = (tableFilter: any = {}) => {
    ShopFrontPopupBannerAPI.getPage(tableFilter).then(setRows)
  }

  useEffect(() => {
    fetch(tableFilter)
  }, [tableFilter, refreshCounter])

  return (
    <Stack spacing={2}>
      <FrontPopTableFilter
        filter ={tableFilter}
        handleFilterChange={(changedFilter: FrontPopTableFilterState) => { setTableFilter(changedFilter) }}
      />
      <Box sx={{ height: 720, width: "100%" }}>
        <DataGrid
          columns={getColumns()}
          rows={rows.content}
          getRowHeight={() => 70}
          onSelectionModelChange={(ids: any) => {
            handleSelection(ids as number[])
          }}
          checkboxSelection
          paginationMode="server"
          onPageChange={(page) => {
            setTableFilter({...tableFilter, page: page})
          }}
          onPageSizeChange={(size) => {
            setTableFilter({...tableFilter, size: size})
          }}

          page={rows.pageable?.pageNumber}
          pageSize={rows.pageable?.pageSize}
          rowCount={rows?.totalElements}
        />
      </Box>
    </Stack>
  )
}

export default FrontPopTable
