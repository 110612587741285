import { Navigate } from "react-router-dom";
import Login from "./pages/Login";

const authRouteInfo = [
    {
        element: <Login/>,
        path: "/login"
    },
    {
        path: "*",
        element: <Navigate to={"/login"} replace/>
    }
]

export default authRouteInfo