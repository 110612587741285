import styled from "@emotion/styled"
import { Colors } from "@viver-inc/viver-ui"
import { Spacer } from "components/Spacer"
import { ProductDetailTextBannerForm } from "./ProductDetailTextBannerForm"
import { useProductDetailTextList } from "./useProductDetailTextList"

const plusIcon = require("images/24_plus_circle.png")

export const ProductDetailTextBannerList = () => {
  const { productDetailTextList, refreshList, isRegisterEnabled, isNewFormOpend, setIsNewFormOpend } =
    useProductDetailTextList()

  return (
    <div>
      {productDetailTextList.map((productDetailText) => {
        return (
          <ProductDetailTextBannerForm
            key={productDetailText.id}
            listItem={productDetailText}
            refreshList={refreshList}
          />
        )
      })}

      {isRegisterEnabled && (
        <NewFormAddButtonWrapper onClick={() => setIsNewFormOpend(true)}>
          <Line />
          <IconWrapper>
            <Icon src={plusIcon} alt="plusIcon" />
          </IconWrapper>
        </NewFormAddButtonWrapper>
      )}

      {isNewFormOpend && isRegisterEnabled && <ProductDetailTextBannerForm refreshList={refreshList} />}
      <Spacer space={20} />
    </div>
  )
}

const NewFormAddButtonWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${Colors.GRAY_040};
`

const IconWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`
const Icon = styled.img`
  width: 28px;
  height: 28px;
`
