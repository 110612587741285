import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import React, {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import QRTableToPrint from "./QRTableToPrint";
import {Print} from "@mui/icons-material";

type ProductQrPopupProps = {
    open: boolean,
    productId?: number,
    handleClose: Function,
}

const ProductQrPopup: React.FC<ProductQrPopupProps> = ({open, productId, handleClose}) => {

    const handlePrintClick = useReactToPrint({
        // @ts-ignore
        content: () => componentRef.current,
        copyStyles: true,
    });

    const componentRef = useRef()

    return <Dialog open={open} maxWidth={"sm"} fullWidth>
        <DialogTitle>상품 QR</DialogTitle>
        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{alignSelf: 'center' }} >
                    {/*@ts-ignore*/}
                    <div ref={componentRef}>
                        <QRTableToPrint productId={productId}/>
                    </div>
                    <Grid textAlign={"right"}>
                    <Button variant={"contained"} size={"medium"} sx={{margin: '10px 0 0 10px'}} onClick={handlePrintClick}><Print/>&nbsp;인쇄</Button>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"error"} onClick={() => handleClose()}>닫기</Button>
        </DialogActions>
    </Dialog>
}

export default ProductQrPopup
