import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FunctionComponent } from "react"
import { isNil } from "utils/validationUtils"

export const DetailModelManagerBrandChips: FunctionComponent<{
  brands: { sid?: string; name?: string }[]
  selectedBrandSid: string
  onChangeBrandSid: (brandSid: string) => void
}> = ({ brands, selectedBrandSid, onChangeBrandSid }) => {
  return (
    <StyledDivDetailModelManagerBrandChips>
      {brands.map((brand, index) => {
        if (isNil(brand.sid)) {
          return null
        }

        return (
          <BrandChip
            key={index}
            label={brand.name ?? brand.sid ?? ""}
            selected={selectedBrandSid === brand.sid}
            onClick={() => onChangeBrandSid(brand.sid!)}
          />
        )
      })}
    </StyledDivDetailModelManagerBrandChips>
  )
}

const BrandChip: FunctionComponent<{
  label: string
  selected: boolean
  onClick: () => void
}> = ({ label, selected, onClick }) => {
  return (
    <StyledDivDetailModelBrandChip isSelected={selected} onClick={onClick}>
      {label}
    </StyledDivDetailModelBrandChip>
  )
}

const StyledDivDetailModelManagerBrandChips = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledDivDetailModelBrandChip = styled.div<{ isSelected: boolean }>`
  border-radius: 21px;
  padding: 6px 12px;
  margin-top: 8px;
  margin-right: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background: #1c2229;
        color: #fff;
        border: 1px solid #1c2229;
      `
    } else {
      return css`
        background: #fff;
        color: #666f78;
        border: 1px solid #ced2d6;
      `
    }
  }}
`
