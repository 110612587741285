import styled from "@emotion/styled"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import React, { useEffect, useState } from "react"
import { BrandType } from "../../../../types/BrandType"
import { getBrands } from "../../../../apis/brandAPI"
import { DesktopDatePicker } from "@mui/lab"

type Props = {
  searchParams: any
  changeBrandSid: (brandSid: string) => void
  changeSearchFilter: (filter: string) => void
  changeSearchWord: (keyword: string) => void
  changeDetailModelExists: (detailModelExists: string) => void
  changeMetricsMonth: (metricsMonth: string) => void
  changeApplied: (changeApplied: string) => void
  onSearch: () => void
}

export const OfficialPriceSearchBar = ({
                                                    searchParams,
                                                    changeBrandSid,
                                                    changeSearchFilter,
                                                    changeSearchWord,
                                                    changeDetailModelExists,
                                                    changeMetricsMonth,
                                                    changeApplied,
                                                    onSearch,
                                                  }: Props) => {

  const [brandList, setBrandList] = useState<BrandType[]>([])

  useEffect(() => {
    getBrands().then(brandList => setBrandList(brandList))
  }, [])

  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth() + 1

  const YEAR_LIST: string[] = Array.from(Array(currentYear - 2021).keys()).map(year => (year + 2022).toString())
  const MONTH_LIST: string[] = Array.from(Array(12).keys()).map(month => (month + 1).toString())
  const searchYearMonthList = (YEAR_LIST.map((year: string) => (MONTH_LIST.map((month: string) => `${year}-${month.padStart(2, "0")}`)))).flat().filter((item: string) => (item >= "2022-07" && item <= `${currentYear}-${currentMonth.toString().padStart(2, "0")}`)).reverse()

  return (
    <Wrapper>
      <FormControl style={{ minWidth: "180px" }}>
        <InputLabel>브랜드</InputLabel>
        <Select
          size={"small"}
          defaultValue={"ALL"}
          required
          value={searchParams.brandSid}
          onChange={(e) => changeBrandSid(e.target.value)}
        >
          <MenuItem value={"ALL"}>전체</MenuItem>
          {brandList.map((brand) => (<MenuItem value={brand.sid}>{brand.name}</MenuItem>))}
        </Select>
      </FormControl>
      <FormControl style={{ minWidth: "150px" }}>
        <InputLabel>검색항목</InputLabel>
        <Select
          size={"small"}
          defaultValue={"fullRefNo"}
          required
          value={searchParams.searchFilter}
          onChange={(e) => changeSearchFilter(e.target.value)}
        >
          <MenuItem value={"fullRefNo"}>Full Ref.No</MenuItem>
          <MenuItem value={"modelName"}>모델명</MenuItem>
        </Select>
      </FormControl>
      <SearchBar>
        <SearchInput
          style={{ height: 40 }}
          value={searchParams.searchWord}
          onChange={(e) => changeSearchWord(e.target.value)}
        />
      </SearchBar>
      <FormControl style={{ minWidth: "100px" }}>
        <InputLabel>모델생성 YN</InputLabel>
        <Select
          size={"small"}
          defaultValue={"ALL"}
          required
          value={searchParams.detailModelExists}
          onChange={(e) => changeDetailModelExists(e.target.value)}
        >
          <MenuItem value={"ALL"}>전체</MenuItem>
          <MenuItem value={"true"}>Y</MenuItem>
          <MenuItem value={"false"}>N</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ minWidth: "170px" }}>
        <InputLabel>공식출시가 수집월</InputLabel>
        <Select
          size={"small"}
          defaultValue={"ALL"}
          required
          value={searchParams.metricsMonth}
          onChange={(params) => changeMetricsMonth(params.target.value)}
        >
          <MenuItem value={"ALL"}>전체</MenuItem>
          {searchYearMonthList.map((item: string) => (<MenuItem value={item}>{item}</MenuItem>))}
        </Select>
      </FormControl>
      <FormControl style={{ minWidth: "120px"}}>
        <InputLabel>공식출시가반영 YN</InputLabel>
        <Select
          size={"small"}
          defaultValue={"ALL"}
          required
          value={searchParams.applied}
          onChange={(e) => changeApplied(e.target.value)}
        >
          <MenuItem value={"ALL"}>전체</MenuItem>
          <MenuItem value={"true"}>Y</MenuItem>
          <MenuItem value={"false"}>N</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ minWidth: "80px" }}>
        <Button variant={"contained"} onClick={onSearch}>검색</Button>
      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
`
