import styled from "@emotion/styled"
import { Pagination, Switch } from "@mui/material"
import React, { FunctionComponent, useState } from "react"
import { useNavigate } from "react-router-dom"

import ListTable from "components/Table/List"
import ListTableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { ModelListItemType } from "types/ModelListItemType"
import { isNil, isNotNil } from "utils/validationUtils"
import CheckBox from "../../../../components/Checkbox"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { DateTime } from "luxon"
import { BrandType } from "../../../../types/BrandType"
import { ModelType } from "../../../../types/ModelType"


function createColumns(): any[] {
  return [
    { field: "id", headerName: "ID", editable: false, minWidth: 100, flex: 0.1 },
    {
      field: "thumbnail", headerName: "대표사진", editable: false, minWidth: 100, flex: 0.3,
      renderCell: (params: any) => {

        return isNotNil(params.value) &&
          <img style={{ width: 104, height: 100 }} src={params.row.thumbnail} alt={params.row.name} />
      },
    },
    { field: "name", headerName: "모델명", editable: false, minWidth: 100, flex: 1 },
  ]
}


type ModelProps = {
  rows: { content: ModelListItemType[]; totalPages: number; },
  setSelectionModelIds: React.Dispatch<React.SetStateAction<number[]>>,
  selectedId: number[]
}

export const CurationSelectModelListTable: React.FC<ModelProps> = ({ rows, selectedId, setSelectionModelIds }) => {


  return (
    <div style={{ height: 800, width: "100%" }}>
      {rows.content.length > 0 &&
      <DataGrid
        getRowId={(row) => row.id}
        columns={createColumns()}
        rows={rows.content}
        pageSize={20}
        checkboxSelection
        disableSelectionOnClick={true}
        selectionModel={selectedId}
        onSelectionModelChange={(rows) => {
          setSelectionModelIds(rows as number[])
        }}
      />}

    </div>
    /*<ListTable>
      <ListTableHeader>
        {modelListTableHeaderItemList.map(({ title, width }, index) => (
          <ListTableHeaderItem key={index} style={{ maxWidth: width }}>
            {title}
          </ListTableHeaderItem>
        ))}
      </ListTableHeader>
      <ListTableBody>
        {modelList.content.length < 1 ? (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        ) : (
          modelList.content.map((model) => (
            <TableRow
              key={model.id}
              style={{ height: 48, cursor: "pointer" }}
              // onClick={() => {
              //   const urlSearchParams = new URLSearchParams()
              //   urlSearchParams.set("brandSid", modelListParams.brandSid)
              //   navigate({
              //     pathname: `/model-manager/model-db/${model.id}`,
              //     search: urlSearchParams.toString(),
              //   })
              // }}
            >
              {/!*<TableRowItem style={{ maxWidth: modelListTableHeaderItemList[0].width }}><CheckBox readOnly checked={!!listItem.item.whenOpen} /></TableRowItem>*!/}
              <TableRowItem style={{ maxWidth: modelListTableHeaderItemList[0].width }}>{model.id}</TableRowItem>
              <TableRowItem style={{ maxWidth: modelListTableHeaderItemList[1].width, height: 48, overflow: "hidden" }}>
                {isNotNil(model.thumbnail) && <img style={{ width: 104 }} src={model.thumbnail} alt={model.name} />}
              </TableRowItem>
              <TableRowItem style={{ maxWidth: modelListTableHeaderItemList[2].width }}>{model.name}</TableRowItem>
            </TableRow>
          ))
        )}
      </ListTableBody>
      <ListTableFooter>
        <Pagination
          count={modelList.totalPages}
          page={modelListParams.page}
          onChange={(_, page) => {
            onChangePage(page)
          }}
        />
      </ListTableFooter>
    </ListTable>*/
  )
}

const EmptyWrapper = styled.div`
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
