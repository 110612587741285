import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const headerItemList: {
  title: string
  width?: string
}[] = [
  {
    title: "시리얼 넘버",
    width: "140px",
  },
  {
    title: "브랜드",
    width: "140px",
  },
  {
    title: "모델명",
    width: "320px",
  },
  {
    title: "Ref No.",
  },
  {
    title: "특이사항",
    width: "170px",
  },
  {
    title: "메모",
    width: "170px",
  },
]

const SerialNumberListTableHeader = () => {
  return (
    <ListTableHeader>
      {headerItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default SerialNumberListTableHeader
