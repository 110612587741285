import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import React, { FunctionComponent, useState } from "react"
import { toast } from "react-toastify"
import { findUserByPhoneNumber, getUserDetail } from "apis/userAPI"
import Button from "components/Button"
import { UserDetailType } from "types/UserType"

export const UserIdSelectPopup: FunctionComponent<{
  onClickCancel: () => void
  onClickConfirm: (userId: number, user: UserDetailType) => void
}> = ({ onClickCancel, onClickConfirm }) => {
  const [searchFilterKey, setSearchFilterKey] = useState<string>("-")
  const [searchFilterValue, setSearchFilterValue] = useState<string>("")
  const [userDetail, setUserDetail] = useState<UserDetailType | null>(null)

  const findUser = async () => {
    try {
      if (searchFilterKey == "-" ) {
        return toast.error( "조회 항목을 선택해주세요.")
      }
      if (!searchFilterValue) {
        return toast.error( "검색어를 입력해주세요.")
      }

      let userId = searchFilterValue
      if ( searchFilterKey == "sellerPhone") {
        const [user] = await findUserByPhoneNumber([searchFilterValue])
        if (!user.id) {
          setUserDetail(null)
          return toast.error("판매자 정보를 찾을 수 없습니다.")
        }
        userId = user.id.toString();
      }

      const userDetail = await getUserDetail(userId, false)
      if (!userDetail.id) {
        setUserDetail(null)
        return toast.error("판매자 정보를 찾을 수 없습니다.")
      }
      setUserDetail(userDetail)

    } catch {
      setUserDetail(null)
      return toast.error("판매자 정보를 찾을 수 없습니다.")
    }
  }

  const submit = () => {
    if (!userDetail) return toast.error("선택된 판매자가 없습니다.")
    onClickConfirm(userDetail.id, userDetail)
  }

  return (
    <Dialog maxWidth="md" open onClose={onClickCancel}>
      <DialogTitle>
        <Typography fontSize={24} fontWeight={700}>
          판매자 조회
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row", width: 656, height: 56 }}>
          <Box style={{ width: 200 }}>
            <Select
              value={searchFilterKey || ""}
              fullWidth
              onChange={(event) => {
                setSearchFilterKey(event.target.value)
                setSearchFilterValue("")
                setUserDetail(null)
                }
              }
            >
              <MenuItem value={"-"}>--조회항목--</MenuItem>
              <MenuItem value={"sellerId"}>판매자 ID</MenuItem>
              <MenuItem value={"sellerPhone"}>핸드폰번호</MenuItem>
            </Select>
          </Box>
          <Box style={{ width: 6 }} />
          <Box style={{ width: 300 }}>
            <TextField
              type="text"
              value={searchFilterValue}
              placeholder="검색어를 입력해주세요."
              fullWidth
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  findUser()
                }
              }}
              onChange={(event) => setSearchFilterValue(event.target.value)}
            />
          </Box>
          <Box style={{ width: 6 }} />
          <Box style={{ width: 150 }}>
            <Button style={{ height: 56 }} buttonStyle="blue-border" onClick={findUser}>
              검색
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: 656, height: 180, alignItems: "center" }}>

          {userDetail ? (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Box sx={{ alignSelf: "flex-start" }}>
                <Typography fontWeight={700}>판매자 정보</Typography>
              </Box>
              <Box sx={{ width: 32 }} />
              <Box sx={{ flex: 1 }}>
                <Typography>ID: {userDetail.id}</Typography>
                <Box sx={{ height: 8 }} />
                <Typography>
                  정산계좌: {userDetail.bankAccount?.bank.name} {userDetail.bankAccount?.accountNumber}{" "}
                  {userDetail.bankAccount?.accountHolder}
                </Typography>
                <Box sx={{ height: 8 }} />
                <Typography>기본배송지: {userDetail.address?.address} {userDetail.address?.addressDetail}</Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
        <DialogActions>
          <Box sx={{ width: 90 }}>
            <Button disabled={!userDetail} onClick={submit}>
              등록
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
