import styled from "@emotion/styled"
import { Link, useLocation } from "react-router-dom"

const pageTabList: { title: string; key: "register" | "model-db"; path: string }[] = [
  {
    title: "등록요청",
    key: "register",
    path: "/model-manager/register",
  },
  {
    title: "모델DB",
    key: "model-db",
    path: "/model-manager/model-db",
  },
]

export const ModelManagerTab = () => {
  const { pathname } = useLocation()

  return (
    <PageTabContainer>
      {pageTabList.map((pageTab) => {
        return (
          <PageTab key={pageTab.key} selected={pathname.includes(pageTab.path)} to={pageTab.path}>
            {pageTab.title}
          </PageTab>
        )
      })}
    </PageTabContainer>
  )
}

const PageTabContainer = styled.div`
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  padding: 24px 30px;

  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
`

const PageTab = styled(Link)<{ selected?: boolean }>`
  color: #000;
  font-size: 14px;
  margin-right:40px;
  text-decoration:none;
  font-weight: ${({ selected }) => (selected ? "700" : 400)}; 
 };
`
