import { FunctionComponent, PropsWithChildren } from "react"
import Typography from "@mui/material/Typography"

interface PropsType {}
const CardTitle: FunctionComponent<PropsWithChildren<PropsType>> = (props) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  )
}
export default CardTitle
