import { UserType } from "types/UserType"
import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import UserListTableRow from "pages/User/UserListPage/UserListTable/TableBody/TableRow"
import UserListTableHeader from "pages/User/UserListPage/UserListTable/TableHeader"
import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"

type Props = {
  userList: UserType[]
  selectUserId: (userId: string) => void
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
}

const UserListTable = ({ selectUserId, userList, paginationParams, changePage }: Props) => {
  return (
    <ListTable>
      <UserListTableHeader />
      <TableBody>
        {userList.map((user) => (
          <UserListTableRow key={user.id} user={user} onClick={() => selectUserId(user.id.toString())} />
        ))}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default UserListTable
