import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ShowroomType} from "types/ShowroomType";
import {getShowrooms} from "../../apis/showroomAPI";
import {updateShowcase, uploadThumbnail} from "../../apis/showcaseAPI";
import {ShowcaseType} from "types/ShowcaseType";
import ImageUploadButton from "../ImageUploadButton";
import DialogBlock from "../DialogBlock";
import {put} from "../../utils/objectUtils";

type AddShowcasePopupProps = {
    open: boolean,
    showcase: ShowcaseType | null,
    handleClose: Function
}

type AddShowcasePopupState = {
    showcase: ShowcaseType|null,
    showrooms: ShowroomType[]
}
const initState: AddShowcasePopupState = {
    showcase: null,
    showrooms: []
}

const AddShowcasePopup: React.FC<AddShowcasePopupProps> = ({open, showcase, handleClose}) => {
    const [state, setState] = useState<AddShowcasePopupState>(initState)

    useEffect(() => {
        if (open) {
            getShowrooms().then((results) => {
                setState({...initState, showcase: (showcase ? {...showcase} : null), showrooms: results})
            })
        }
    }, [open])

    const handleImage = (image: FileList) => {
        uploadThumbnail(image).then((result) => {
            setState(put(state, 'showcase.thumbnail', result.url))
        })
    }
    const handleDeleteImage = () => {
        setState(put(state, 'showcase.thumbnail', null))
    }

    const verifyShowcase = (showcase?: ShowcaseType | null) => {
        return showcase != null &&
            showcase?.name != null &&
            showcase?.onDisplay != null &&
            showcase?.showroomId != null
    }

    return <Dialog open={open} maxWidth={"sm"} fullWidth>
        <DialogTitle>쇼케이스 수정</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DialogContentText>
                        수정할 쇼케이스 정보를 입력해주세요.
                    </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>쇼룸 선택</InputLabel>
                        <Select
                            required
                            label="쇼룸 선택"
                            placeholder={"수정할 쇼룸을 선택 해주세요."}
                            value={state.showcase?.showroomId}
                            defaultValue={showcase?.showroomId}
                            onChange={(params) => setState(put(state, 'showcase.showroomId', params.target.value as number))} >
                            {state.showrooms.map((showroom) => (<MenuItem value={showroom.id}>{showroom.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label={"쇼케이스 이름"}
                        placeholder={"ex) 좌측 1번 쇼케이스"}
                        InputLabelProps={{shrink: true,}}
                        value={state.showcase?.name}
                        onChange={(params) => setState(put(state, 'showcase.name', params.target.value))}
                        autoFocus
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id={"description"}
                        label={"쇼케이스 설명"}
                        InputLabelProps={{shrink: true,}}
                        value={state.showcase?.description}
                        onChange={(params) => setState(put(state, 'showcase.description', params.target.value))}
                        autoFocus
                        multiline
                        rows={5}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <DialogBlock title={'QR 노출 여부'}>
                        <Box display={'flex'} justifyContent="center" alignItems="center" width={'100%'}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Typography width={'100px'} textAlign={'right'}>노출 안함</Typography>
                                <Switch
                                    checked={state.showcase?.onDisplay}
                                    onChange={(_event, checked) => setState(put(state, 'showcase.onDisplay', checked))}
                                />
                                <Typography width={'100px'} >노출</Typography>
                            </Stack>
                        </Box>
                    </DialogBlock>
                </Grid>
                <Grid item xs={12}>
                    <DialogBlock title={'썸네일'}>
                        <Box display={'flex'} justifyContent="center" alignItems="center" width={'100%'}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <ImageUploadButton width={'400px'} height={'400px'} handleImage={handleImage} imageUrl={state.showcase?.thumbnail} handleDeleteImage={handleDeleteImage}/>
                            </Stack>
                        </Box>
                    </DialogBlock>
                </Grid>
            </Grid>
            <DialogActions>
                <Button color={"error"} onClick={() => handleClose()}>취소</Button>
                <Button color={"primary"} onClick={() => {
                    if (verifyShowcase(state.showcase)) {
                        // @ts-ignore
                        updateShowcase(state.showcase?.id, state.showcase).then((_showcase) => {
                            handleClose()
                        })
                    }
                }}>수정</Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

export default AddShowcasePopup