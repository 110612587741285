import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import CheckBox from "components/Checkbox"

import SearchInput from "components/Search/SearchBar/SearchInput"
import { ModelRequestListItemType } from "types/ModelRequestType"

const searchMenuList: { value: string; title: string }[] = [
  { value: "fullRefNo", title: "ref No." },
  { value: "brandSid", title: "브랜드" },
  { value: "title", title: "상세모델명" },
]

const statusFilterList: { value: ModelRequestListItemType["status"]; title: string }[] = [
  { value: "등록요청", title: "요청" },
  { value: "등록중", title: "등록중" },
  { value: "요청취소", title: "취소" },
  { value: "등록거절", title: "거절" },
  { value: "등록완료", title: "완료" },
]

type Props = {
  selectedStatusFilter: ModelRequestListItemType["status"][]
  changeStatusFilter: (status: ModelRequestListItemType["status"]) => void
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: string) => void
  onSearch: () => void
}

const ModelRegistratiionRequestSearchBar = ({
  selectedStatusFilter,
  changeStatusFilter,
  changeSearchKeyword,
  changeSearchFilterValue,
  onSearch,
}: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"fullRefNo"}
          required
          onChange={(e) => changeSearchFilterValue(e.target.value)}
        >
          {searchMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchInput
        style={{
          flex: 1,
        }}
        onChange={(e) => changeSearchKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSearch()
        }}
      />

      <StatusContainer>
        {statusFilterList.map((statusFilter) => (
          <StatusCheckBoxItem key={statusFilter.title} onChange={() => changeStatusFilter(statusFilter.value)}>
            <CheckBox id={statusFilter.value} checked={selectedStatusFilter.includes(statusFilter.value)} readOnly />
            <label htmlFor={statusFilter.value}>{statusFilter.title}</label>
          </StatusCheckBoxItem>
        ))}
      </StatusContainer>
    </Wrapper>
  )
}

export default ModelRegistratiionRequestSearchBar

const Wrapper = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: center;
`

const StatusContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 42px;
`

const StatusCheckBoxItem = styled.div`
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 12px;
  }

  label {
    font-size: 14px;
    color: #1c2229;
    margin-left: 4px;
  }
`
