import styled from "@emotion/styled"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { ForeignerSearchType, UserListFilterKeyType, UserSearchType } from "pages/User/UserListPage"
import { UserListSearchParams, UserTypeCode, UserTypeCodeList } from "types/UserType"
import React from "react"

const searchUserFilterMenuList: { value: UserListFilterKeyType; title: string }[] = [
  { value: "id", title: "사용자 ID" },
  { value: "email", title: "이메일" },
  { value: "nickname", title: "닉네임" },
  { value: "status", title: "상태" },
  { value: "name", title: "이름" },
  { value: "phone", title: "전화번호" },
  { value: "userType", title: "회원구분" },
]

type Props = {
  searchParams: UserSearchType
  searchForeigner: ForeignerSearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: keyof UserListSearchParams) => void
  changeForeignerValue: (foreigner: ForeignerSearchType) => void
  onSearch: (keyword: UserSearchType["keyword"], foreigner: ForeignerSearchType) => void
}

const UserListTableSearchBar = ({
  searchParams,
  searchForeigner,
  changeSearchKeyword,
  changeSearchFilterValue,
  changeForeignerValue,
  onSearch,
}: Props) => {
  const numericFields = ["id"]

  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"name"}
          required
          onChange={(e) => {
            changeSearchKeyword("")
            changeSearchFilterValue(e.target.value as UserListFilterKeyType)
          }}
        >
          {searchUserFilterMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        {searchParams.filter === "userType" && (
          <FormControl style={{ minWidth: "100%" }}>
            <Select
              size={"small"}
              required
              onChange={(e) => {
                const keyword = e.target.value as UserTypeCode
                changeSearchKeyword(keyword)
                onSearch(keyword, searchForeigner)
              }}
            >
              {UserTypeCodeList.map((menu) => (
                <MenuItem value={menu.value} key={menu.title}>
                  {menu.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {searchParams.filter != "userType" && (
          <SearchInput
            style={{ height: 40 }}
            value={searchParams.keyword}
            onChange={(params) => {
              const value = numericFields.includes(searchParams.filter || "")
                ? params.target.value.replace(/[^0-9]/g, "")
                : params.target.value

              changeSearchKeyword(value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(searchParams.keyword, searchForeigner)
              }
            }}
          />
        )}
      </SearchBar>
      <FormControl sx={{ width: "160px" }} size={"small"}>
        <InputLabel id="search_key">외국인</InputLabel>
        <Select
          value={searchForeigner || "A"}
          onChange={(e) => {
            const searchForeigner = e.target.value as ForeignerSearchType

            changeForeignerValue(searchForeigner)
            onSearch(searchParams.keyword, searchForeigner)
          }}
        >
          <MenuItem value={"A"}>전체</MenuItem>
          <MenuItem value={"Y"}>외국인</MenuItem>
          <MenuItem value={"N"}>내국인</MenuItem>
        </Select>
      </FormControl>
    </Wrapper>
  )
}

export default UserListTableSearchBar

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  max-width: 600px;
`
