import { httpGet, httpPut } from "../utils/httpAPI"
import { ResellItemType } from "types/ResellItemType"
import { PageType } from "types/PageType"
import { BrandType } from "types/BrandType"

export interface ResellItemSearchParams {
  refNo?: string | null
  refNoIsNull?: boolean | null

  detailModelId?: number | null

  lastPriceGoe?: number | null
  lastPriceLoe?: number | null
  lastPriceIsNull?: boolean | null

  yearGoe?: number | null
  yearLoe?: number | null
  year?: number | null
  yearIsNull?: boolean | null

  confirmStatus?: string | null
  scrapSources?: string[] | null

  searchText?: string | null

  registerDateGoe?: string | null
  registerDateLoe?: string | null

  itemStatus?: string | null
  writerId?: string | null

  brandSid?: BrandType["sid"] | null
}

export const getResellItemPage = (params?: ResellItemSearchParams) =>
  httpGet<PageType<ResellItemType>>("/resell-items", params)
export const getResellItemList = (params: ResellItemSearchParams) =>
  httpGet<ResellItemType[]>("/resell-items/list", params)
export const updateResellItem = (id: number, resellItem: ResellItemType) =>
  httpPut<ResellItemType>(`/resell-items/${id}`, resellItem)
