import ListTableBody from "components/Table/List/TableBody"
import { FunctionComponent } from "react"
import { NoticeType } from "types/NoticeType"

export const NoticeListTableBody: FunctionComponent<{
  data: NoticeType[]
  renderItem: FunctionComponent<{ item: NoticeType; index: number }>
}> = ({ data, renderItem }) => {
  return <ListTableBody>{data.map((item, index) => renderItem({ item, index }))}</ListTableBody>
}
