import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from "react"

import {Button, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {ShippingRequestResultType, ShippingRequestType, ShippingStatusCode, ShippingType} from "types/ShippingType";
import {getShippings, requestShipping} from "../../../../apis/shippingAPI";
import {toast} from "react-toastify";
import { downloadExcel, downloadIlyangExcel } from "../../../../utils/shippingToExcelUtil"
import {numberWithCommas} from "../../../../utils/NumberUtils";


function createColumns(): any[] {
    return [
        {
            field: 'orderId',
            editable: false,
            sortable: false,
            minWidth: 160,
            headerName: "주문번호",
            renderCell: (params: any) => (
                <div style={{textAlign: "left"}}>
                    <p>{params.row.orderId}</p>
                </div>
            )
        },
        {
            field: 'shippingType',
            headerName: "배송형태",
            editable: false,
            sortable: false,
            minWidth: 70,
            maxWidth: 70,
        },

        {
            field: 'shippingMethod',
            headerName: "입고방식",
            editable: false,
            sortable: false,
            minWidth: 120,
            maxWidth: 120,
            renderCell: (params: any) => {
                const getShippingMethodText = ()=>{

                    if(params.row.shippingMethod === '안전배송_2'){
                        return '일양택배'
                    }
                    if(params.row.shippingMethod === '안전배송'){
                        return '발렉스'
                    }
                    return params.row.shippingMethod
                }

                return (
                  <div style={{ textAlign: "center" }}>
                    <p>{getShippingMethodText()}</p>
                  </div>
                )
            }
        },
        {
            field: 'shippingStatus',
            editable: false,
            sortable: false,
            minWidth: 72,
            maxWidth: 72,
            headerName: "배송상태",
            renderCell: (params: any) => (
                <div style={{textAlign: "center", fontWeight: "bold"}}>
                    <p>{params.row.shippingStatus}</p>
                </div>
            )
        },
        {
            field: 'name',
            headerName: "판매자명",
            editable: false,
            sortable: false,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            field: 'zipCode',
            headerName: "우편번호",
            editable: false,
            sortable: false,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            field: 'address',
            headerName: "주소",
            editable: false,
            sortable: false,
            minWidth: 180,
            maxWidth: 180,
        },
        {
            field: 'addressDetail',
            headerName: "상세주소",
            editable: false,
            sortable: false,
            minWidth: 180,
            maxWidth: 180,
        },
        {
            field: 'phone',
            headerName: "휴대폰번호",
            editable: false,
            sortable: false,
            minWidth: 120,
            maxWidth: 120,
        },
        {
            field: 'productTitle',
            headerName: "상품명",
            editable: false,
            sortable: false,
            minWidth: 200,
            maxWidth: 200,
        },
        {
            field: 'weight',
            headerName: "무게",
            editable: true,
            sortable: false,
            minWidth: 60,
            maxWidth: 60,
        },
        {
            field: 'quantity',
            headerName: "수량",
            editable: true,
            sortable: false,
            minWidth: 60,
            maxWidth: 60,
        },
        {
            field: 'price',
            headerName: "가격",
            editable: true,
            sortable: false,
            valueGetter: (params: any) => `${numberWithCommas(params.row.price)}`,
            minWidth: 100,
            maxWidth: 100,
        },
    ]
}

type SellOrderProps = {
    shippings: ShippingType[],
    close: Function,
    type: 'RETURN'|'NORMAL',
}

const ShippingRequestTable: React.FC<SellOrderProps> = ({shippings, type, close}) => {

    const [selectedIds, setSelectedIds] = useState<number[]>(shippings.map(it => it.id!!))
    const [includeInTransit, setIncludeInTransit] = useState<boolean>(false)

    useEffect(() => {
    }, [shippings])

    const handleDownloadValexExel = useCallback ( ()=> {
        const shippingIds = shippings.filter(it => selectedIds.includes(it.id!)).map(it => it.id)
        getShippings({"id": shippingIds, "unmasking": true})
          .then((result)=> result.content)
          .then((result)=> result.filter((item)=> item.shippingMethod === '안전배송'))
          .then((result)=> {
              if ( result.length=== 0) {
                  toast.error("해당하는 주문이 없습니다.")
                  throw new Error('해당하는 주문이 없습니다.')
              }
              return result;
          })
          .then((result) => downloadExcel(result))
    }, [selectedIds, shippings])

    const handleDownloadIlyangExcel = useCallback(()=>{
        const shippingIds = shippings.filter(it => selectedIds.includes(it.id!)).map(it => it.id)
        getShippings({"id": shippingIds, "unmasking": true})
          .then((result)=> result.content)
          .then((result)=> result.filter((item)=> item.shippingMethod === '안전배송_2'))
          .then((result)=> {
              if ( result.length=== 0) {
                  toast.error("해당하는 주문이 없습니다.")
                  throw new Error("해당하는 주문이 없습니다.")
              }
              return result;
          })
          .then((result) => downloadIlyangExcel(result))
    }, [selectedIds, shippings])

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{justifyContent: "flex-end"}}>
                <ToggleButtonGroup
                    exclusive
                    aria-label="includeInTransit"
                    style={{margin: '0 10px', height: '32px'}}
                    onChange={(event, value) => {setIncludeInTransit(value === 'include')}}
                >
                    <ToggleButton value="include" selected={includeInTransit} size={"small"} color={"error"}>전체</ToggleButton>
                    <ToggleButton value="exclude" selected={!includeInTransit} size={"small"} color={"success"}>배송대기</ToggleButton>
                </ToggleButtonGroup>
                {type === 'NORMAL' && (
                  <Button variant={"outlined"} size={"small"} style={{margin: '0 2px'}} onClick={handleDownloadIlyangExcel}>일양 다운로드</Button>
                )}
                <Button variant={"outlined"} size={"small"} style={{margin: '0 2px'}} onClick={handleDownloadValexExel}>발렉스 다운로드</Button>
                <Button disabled={includeInTransit} variant={"contained"} size={"small"} style={{margin: '0 2px'}} onClick={() => handRequestClick(true)}>
                    {type === 'NORMAL' ? '배송중':"입고중"}으로 변경
                </Button>
                {/*<Button variant={"contained"} size={"small"} style={{margin: '0 2px'}} onClick={() => handRequestClick(false)}>VALEX 배송 요청</Button>*/}
            </GridToolbarContainer>
        );
    }

    const handleSelection = (ids: any[]) => {
        setSelectedIds(ids)
    }

    const handRequestClick = (manually: boolean = false) => {
        if (!selectedIds.length) {
            toast.error('배송을 요청할 주문을 선택해주세요')
            return
        }

        // TODO: 판매주문 픽업 / 구매주문 배송 구분하여 둘 다 쓸 수 있도록 수정
        if (window.confirm(`${selectedIds.length}건의 픽업을 요청합니다.`)) {
            const req: ShippingRequestType = {
                items: shippings.filter(it => selectedIds.includes(it.id!))
                    .map(it => {return {id: it.id}}),
                manually: manually
            }

            requestShipping(req).then(res => {
                // @ts-ignore
                shippings = shippings.filter(it => (res.succeed?.find(s => s.orderId == it.orderId)) == false)

                const r = (res as ShippingRequestResultType)
                let msg = `* 성공 ${r.succeed?.length || 0}건\n`
                msg += r.succeed?.map(it => `${it.id}`)?.join("\n") || ''
                msg += "\n"
                msg += `* 실패 ${r.failure?.length || 0}건\n`
                msg += r.failure?.map(it => `${it.id} (${it.code}: ${it.message})`)?.join("\n")  || ''
                alert(msg)
                close()
            })
        }
    }

    return (
        <>
            <div style={{height: '80vh', width: '100%'}}>
                <DataGrid
                    columns={createColumns()}
                    components={{Toolbar: CustomToolbar}}
                    rows={includeInTransit ? shippings : shippings.filter(it => it.shippingStatus == ShippingStatusCode.배송_준비)}
                    getRowId={row => row.id!}

                    checkboxSelection
                    selectionModel={selectedIds}
                    disableSelectionOnClick={true}
                    onSelectionModelChange={handleSelection}
                />
            </div>
        </>)
}

export default ShippingRequestTable
