import { MyPageBannerType } from "types/MyPageBannerType"
import { PageType } from "types/PageType"
import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"

export const getMyPageBannerList = (params?: { [key: string]: any }): Promise<PageType<MyPageBannerType>> => {
  return httpGet(`/my-page/banners`, params)
}

export const getMyPageBannerDetail = (id: number): Promise<MyPageBannerType> => {
  return httpGet(`/my-page/banners/${id}`)
}

export const postMyPageBanner = (body: Omit<MyPageBannerType, "id">) => {
  return httpPost("/my-page/banners", body)
}

export const putMyPageBanner = (id: number, body: Partial<Omit<MyPageBannerType, "id">>) => {
  return httpPut(`/my-page/banners/${id}`, body)
}

export const deleteMyPageBannerList = (ids: number[]) => {
  return httpDelete(`/my-page/banners/`, { ids: ids.join(",") })
}
