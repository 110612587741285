import {Stack} from "@mui/material";
import React, {useState} from "react";
import SellOrderReviewTable from "./SellOrderReviewTable";
import {SellOrderReviewRecommendType, SellOrderReviewType} from "../../../types/SellOrderReviewType";
import SellOrderReviewAPI from "../../../apis/sellOrderReviewAPI";
import SellOrderReviewToolbar from "./SellOrderReviewToolbar";

const SellOrderReviewTab = () => {
    const [updateSellOrderReview, setUpdateSellOrderReview] = useState<SellOrderReviewType | null>(null)
    const [selectionIds, setSelectionIds] = useState<string[]>([])
    const [refreshCounter, setRefreshCounter] = useState(0)
    const [recommendReview, setRecommendReview] = useState<SellOrderReviewRecommendType>()

    const refresh = () => setRefreshCounter(refreshCounter + 1)

    const updateRecommend = (reviewIds: String[], recommend: boolean) => {
        var params = {ids: reviewIds, recommend: recommend}
        SellOrderReviewAPI.updateRecommend(params).then((result) => {
            refresh()
        })
    }
    const handleHideButtonClick = () => {updateRecommend(selectionIds, false)}
    const handleShowButtonClick = () => {updateRecommend(selectionIds, true)}

    return (
        <Stack direction={"column"} spacing={1}>
            <SellOrderReviewToolbar
                handleShowButtonClick={handleShowButtonClick}
                handleHideButtonClick={handleHideButtonClick}
            />
            <SellOrderReviewTable
                handleSelection={(ids: string[]) => { setSelectionIds(ids) }}
                handleUpdateSellOrderReview={(sellOrderReview: SellOrderReviewType) => setUpdateSellOrderReview(sellOrderReview)}
                refreshCounter={refreshCounter}
            />
        </Stack>
    )

}

export default SellOrderReviewTab