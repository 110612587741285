import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, TextField, Typography} from "@mui/material";
import RowBlock from "../../../components/RowBlock";
import { DatePicker, DateTimePicker, DesktopDatePicker } from "@mui/lab"
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import {ShopHomeReviewType} from "types/ShopHomeReviewType";
import ShopHomeReviewAPI from  "../../../apis/shopHomeReviewAPI";
import { isNil } from "../../../utils/validationUtils"
import { toast } from "react-toastify"
import styled from "@emotion/styled"
import { isEmpty } from "lodash"

const initState: ShopHomeReviewType = {
    reviewContent: "",
    reviewedDate: DateTime.now().toISODate(),
    priority: null,
    onDisplay: true,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO()
}

// @ts-ignore
const HomeReviewUpdatePopup = ({homeReview, handleClose}) => {
    const [state, setState] = useState(initState)

    useEffect(() => {
        if (homeReview != null) {
            setState(homeReview)
        } else {
            setState(initState)
        }
    }, [homeReview])

    const handleSubmit = () => {
        if (isNil(state.id)) {
            toast.error("수정할 정보가 없습니다. 다시 조회 후 수정 하세요.")
            return
        }
        if (isNil(state.reviewContent) || isEmpty(state.reviewContent)) {
            toast.error("내용을 입력하세요.")
            return
        }
        if (isNil(state.reviewedDate)) {
            toast.error("후기날짜를 입력하세요.")
            return
        }
        if (isNil(state.startedAt)) {
            toast.error("노출 시작일자를 입력하세요.")
            return
        }
        if (isNil(state.endedAt)) {
            toast.error("노출 종료일자를 입력하세요.")
            return
        }

        if (state.startedAt > state.endedAt) {
            toast.error("노출 기간을 확인하세요.")
            return
        }

        ShopHomeReviewAPI.update(state.id!!, state).then(() => {handleClose()})
    }

    return (
        <Dialog open={homeReview != null} maxWidth={false} >
            <DialogTitle>쇼룸 고객 방문 후기 수정</DialogTitle>
            <DialogContent>
                <Stack direction={'row'}>
                    <Stack spacing={3}>
                        <RowBlock title={"내용"}>
                            <TextArea value={state.reviewContent} onChange={(e) => {
                                if (e.target.value.length <= 400) {
                                    setState({ ...state, reviewContent: e.target.value })
                                }
                            }
                            } placeholder="최대 400자까지 입력 가능합니다." />
                            <p>입력 가능한 글자 수: {400 - state.reviewContent.length}</p>
                        </RowBlock>
                        <RowBlock title={"후기날짜"}>
                            <DatePicker
                              onChange={(e) => {
                                  setState({ ...state, reviewedDate: e?.toISODate() ?? DateTime.now().toISODate() })
                              }}
                              value={DateTime.fromISO(state.reviewedDate ?? DateTime.now().toISODate())}
                              inputFormat={"yyyy-MM-dd"}
                              renderInput={(props) => <TextField size={"small"} sx={{ width: "150px" }}  {...props} />}
                            />
                        </RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={'row'} justifyContent={'flex-start'}>
                                <Switch checked={state.onDisplay ?? false} onChange={(e) => setState({...state, onDisplay: e.target.checked})}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'flex-start'}>
                                <DateTimePicker onChange={(e) => {
                                    setState({
                                        ...state,
                                        startedAt: e?.toISO() ?? DateTime.now().toISO()
                                    })
                                }} value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 시작'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                                <Typography>~</Typography>
                                <DateTimePicker onChange={(e) => {
                                    setState({
                                        ...state,
                                        endedAt: e?.toISO() ?? DateTime.now().toISO()
                                    })
                                }} value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 종료'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                            </Stack>
                        </RowBlock>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

const TextArea = styled.textarea`
  background: #ffffff;
  border: 1px solid #ced2d6;
  border-radius: 4px;
  width: 100%;
  min-height: 272px;
  outline: none;
  resize: vertical;
  padding: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`


export default HomeReviewUpdatePopup