import { createFaq, deleteFaq, updateFaq } from "apis/faqAPI"

import { useCallback, useEffect, useMemo, useState } from "react"
import { FaqCategoryType, FaqCreateBody, FaqType } from "types/FaqType"

const initialBody: FaqCreateBody = {
  type: "공통",
  title: "",
  body: "",
  onDisplay: true,
  priority: 0,
}

type Props = {
  selectedFaq: FaqType | undefined
  selectedCategory: FaqCategoryType
}

export const useFaqEditor = ({ selectedCategory, selectedFaq }: Props) => {
  const [faqState, setFaqState] = useState<FaqCreateBody>(initialBody)

  useEffect(() => {
    if (!!selectedFaq) {
      setFaqState({
        type: selectedFaq.type,
        title: selectedFaq.title,
        body: selectedFaq.body,
        onDisplay: true,
        priority: selectedFaq.priority,
      })
    } else {
      setFaqState({ ...initialBody, type: selectedCategory })
    }
  }, [selectedFaq, selectedCategory])

  const changeValue = (key: string, value: any) => {
    setFaqState((prev) => ({ ...prev, [key]: value }))
  }

  const isSavable = useMemo(() => {
    if (!selectedFaq) {
      return faqState.body && faqState.title && faqState.type
    } else {
      return (
        selectedFaq.title !== faqState.title || selectedFaq.body !== faqState.body || selectedFaq.type !== faqState.type
      )
    }
  }, [selectedFaq, faqState])

  const saveFaqDetail = useCallback(async () => {
    if (!window.confirm("질문을 저장하시겠습니까?")) return

    if (selectedFaq) {
      return await updateFaq(selectedFaq.id, faqState)
    } else {
      return await createFaq(faqState)
    }
  }, [selectedFaq, faqState])

  const deleteFaqDetail = useCallback(() => {
    if (!selectedFaq) return

    if (!window.confirm("질문을 삭제하시겠습니까?")) return

    deleteFaq(selectedFaq.id)
  }, [selectedFaq])

  return { faqState, changeValue, isSavable, saveFaqDetail, deleteFaqDetail }
}
