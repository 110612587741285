import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const pointScheduleHeader = [
  {
    title: "ID",
    width: "5%",
  },
  {
    title: "예약 상태",
    width: "10%",
  },
  {
    title: "지급 예정 일시",
    width: "10%",
  },
  {
    title: "대상 지정방식",
    width: "15%",
  },
  {
    title: "지급 대상",
    width: "10%",
  },
  {
    title: "지급 사유",
    width: "10%",
  },
  {
    title: "지급 메세지",
    width: "20%",
  },
  {
    title: "포인트",
    width: "10%",
  },
  {
    title: "만료일",
    width: "10%",
  },
  {
    title: "푸시 발송",
    width: "10%",
  },
]

const PointScheduleListTableHeader = () => {
  return (
    <ListTableHeader>
      {pointScheduleHeader.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default PointScheduleListTableHeader
