import { Box, Button, Stack } from "@mui/material"
import CurationTableFilter from "pages/ShopManager/ShopContentTab/BrandModelCurationTab/CurationTableFilter"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { ShopCurationType } from "types/ShopCurationType"
import EditIcon from "@mui/icons-material/Edit"
import { PageType } from "../../../../types/PageType"
import ShopBrandModelCurationAPI from "../../../../apis/shopBrandModelCurationAPI"
import { DateTime } from "luxon"

function renderPreviewCell(params: any) {
  return (<img src={params?.row?.bannerImageUrl} style={{ width: "100%" }}  alt={params?.row?.id}/>)
}

function renderStatusCell(params: any) {
  if (params?.row?.postingStatus === "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
  const startedAt = DateTime.fromISO(params?.row?.startedAt)
  const endedAt = DateTime.fromISO(params?.row?.endedAt)

  return (
    <Stack direction={'column'} alignItems={"self-end"}>
      <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
    </Stack>
  )
}

interface CurationTableFilterState {
  type: string,
  postingStatus: string | null,
  page: number | null,
  size: number | null
}

const initCurationTableFilter: CurationTableFilterState = {
  type: "BRAND_MODEL_CURATION",
  postingStatus: null,
  page: 0,
  size: 50,
}

const CurationTable: React.FunctionComponent<{
  handleSelection: (ids: number[]) => void
  handleUpdateCurationId: (curationId: number) => void
  refreshCounter: number
}> = ({ handleSelection, handleUpdateCurationId, refreshCounter }) => {
  const [tableFilter, setTableFilter] = useState<CurationTableFilterState>(initCurationTableFilter)
  const [curations, setCurations] = useState<PageType<ShopCurationType>>({ content: [] })

  const getColumns = () => {
    return [
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={"수정"}
            onClick={() => {
              handleUpdateCurationId(params.row.id)
            }}
            showInMenu
          />,
        ],
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "title", headerName: "브랜드명", width: 300, flex: 1 },
      {
        field: "brandCount",
        headerName: "브랜드 수",
        maxWidth: 75,
        valueGetter: (params: any) => {
          return params.row.brands.length
        },
      },
      {
        field: "createdAt",
        headerName: "등록 일시",
        width: 180,
        valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,
      },
      { field: "postingPeriod", headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell },
      { field: "postingStatus", headerName: "진행 상태", width: 80, renderCell: renderStatusCell },
      {field: 'showDomesticUser',  width: 80,
        renderCell: (params: any) => (
          <div style={{textAlign: "center"}}>
            <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
          </div>
        ),
        renderHeader: () => (
          <div style={{  textAlign: "center", lineHeight: "1.2" }}>
            내국인 회원<br />노출
          </div>
        ),
      },
      { field: "showForeignUser", width: 80,
        renderCell: (params: any) => (
          <div
            style={{ textAlign: "center"  }}
          >
            <span>{params.row.showForeignUser ? "Y" : "N"}</span>
          </div>
        ),
        renderHeader: () => (
          <div style={{ textAlign: "center", lineHeight: "1.2" }}>
            외국인 회원<br />노출
          </div>
        ),
      }
    ]
  }

  const fetch = (tableFilter: any = {}) => {
    ShopBrandModelCurationAPI.getPage(tableFilter).then(setCurations)
  }

  useEffect(() => {
    fetch(tableFilter)
  }, [tableFilter, refreshCounter])


  return (
    <Stack spacing={2}>
      <CurationTableFilter
        filter={tableFilter}
        handleFilterChange={(changedFilter: CurationTableFilterState) => {
          setTableFilter(changedFilter)
        }}
      />
      <Box sx={{ height: 720, width: "100%" }}>
        <DataGrid
          columns={getColumns()}
          getRowHeight={() => 70}
          onSelectionModelChange={(ids: any) => {
            handleSelection(ids as number[])
          }}
          checkboxSelection
          paginationMode="server"
          onPageChange={(page) => {
            setTableFilter({ ...tableFilter, page: page })
          }}
          onPageSizeChange={(size) => {
            setTableFilter({ ...tableFilter, size: size })
          }}

          page={curations.pageable?.pageNumber}
          pageSize={curations.pageable?.pageSize}
          rowCount={curations?.totalElements}
          rows={curations?.content}
        />
      </Box>
    </Stack>
  )
}

export default CurationTable
