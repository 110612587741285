import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import _ from "lodash"
import { useContext, useLayoutEffect } from "react"
import { useLocation, useRoutes } from "react-router-dom"

import authRouteInfo from "./authRouteInfo"
import guestRouteInfo from "./guestRouteInfo"
import { AuthContext } from "./AuthContext"

const App = () => {
  const location = useLocation()
  const { user, checkAuth, initialAuthChecked } = useContext(AuthContext)
  const authRoutes = useRoutes(authRouteInfo)
  const guestRoutes = useRoutes(guestRouteInfo)
  HighchartsMore(Highcharts)

  useLayoutEffect(() => {
    checkAuth?.()
  }, [location.pathname])

  return initialAuthChecked ? (_.isEmpty(user) ? guestRoutes : authRoutes) : null
}
export default App
