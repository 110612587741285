import { Button, Divider, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material"
import { ThemeModeTypeCodeList } from "../../../../types/ShopHeroBannerType"
import React from "react"
import { BannerExposureAreaCodeList } from "../../../../types/MyPageBannerType"


// @ts-ignore
const MyPageBannerFilter = ({filter, handleFilterChange}) => {

  const selectedStyle = {
    textDecoration: 'underline',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
  };

  const unselectedStyle = {
    textDecoration: 'none',
    fontWeight: 'normal',
    backgroundColor: 'transparent'
  };

  return (
    <Stack direction={'row'}  justifyContent={"space-between"}>
      <Stack direction={'row'} justifyContent={'flex-start'}  divider={<Divider orientation="vertical" flexItem />} spacing={1}>
        <Button style={filter.postingStatus === null ? selectedStyle : unselectedStyle}    onClick={ () => {handleFilterChange({...filter, postingStatus: null})} }>전체</Button>
        <Button style={filter.postingStatus === '노출중' ? selectedStyle : unselectedStyle}   onClick={ () => {handleFilterChange({...filter, postingStatus: '노출중'})} }>노출중</Button>
        <Button style={filter.postingStatus === '노출 예정' ? selectedStyle : unselectedStyle} onClick={ () => {handleFilterChange({...filter, postingStatus: '노출 예정'})} }>노출 예정</Button>
        <Button style={filter.postingStatus === '노출 종료' ? selectedStyle : unselectedStyle} onClick={ () => {handleFilterChange({...filter, postingStatus: '노출 종료'})} }>노출 종료</Button>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-start'}  divider={<Divider orientation="vertical" flexItem />} spacing={1}>
        <RadioGroup row sx={{ justifyContent: "center" }}
                    onChange={(event, value) =>{
                      handleFilterChange({...filter, exposureArea: value})}
        }
        >
          <FormControlLabel
            key={"all"}
            value={"all"}
            label={"전체"}
            checked= {filter.exposureArea === "all"}
            control={<Radio />}
          />
          {BannerExposureAreaCodeList.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              label={item.title}
              checked= {filter.exposureArea === item.value}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </Stack>
    </Stack>
  )
}

export default MyPageBannerFilter