import { FunctionComponent, useState } from "react"
import { DiagnosisPrintPopup } from "./DiagnosisPrintPopup"
import { getDiagnosisIssuance } from "../../apis/diagnosisAPI"

export const DiagnosisPrintOpen:FunctionComponent = () => {
  const [printPopupOpen, setPrintPopupOpen] = useState(false);
  getDiagnosisIssuance(21276).then((value) => {
  console.log(value)
})
  return (
    <div>
      <DiagnosisPrintPopup isOpen={printPopupOpen} closePopup={()=>{setPrintPopupOpen(false)}} productId={21276} />
      <button type="button" onClick={()=>{setPrintPopupOpen(true)}}>팝업열기 임시 화면.</button>
    </div>
  )
}