import {Stack} from "@mui/material";
import {useState} from "react";
import DeleteConfirmPopup from "../../../../components/Popup/DeleteConfirmPopup";
import FrontPopToolbar from "./FrontPopToolbar"
import FrontPopTable from "./FrontPopTable"
import { ShopFrontPopupBannerType } from "../../../../types/ShopFrontPopupBannerType"
import shopFrontPopupBannerAPI from "../../../../apis/shopFrontPopupBannerAPI"
import FrontPopCreatePopup from "./FrontPopCreatePopup"
import FrontPopUpdatePopup from "./FrontPopUpdatePopup"


const FrontPopTab = () => {
  const [showCreateBanner, setShowCreateBanner] = useState(false)
  const [updateBanner, setUpdateBanner] = useState<ShopFrontPopupBannerType | null>(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectionIds, setSelectionIds] = useState<number[]>([])
  const [refreshCounter, setRefreshCounter] = useState(0)
    const refresh = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Stack direction={"column"} spacing={1}>
      <FrontPopToolbar
        handleCreateBanner={() => setShowCreateBanner(true)}
        handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
        disabledDeleteButton={ selectionIds.length == 0}
      />
      <FrontPopTable
        handleSelection={(ids: number[]) => { setSelectionIds(ids) }}
        handleUpdateBanner={(banner: ShopFrontPopupBannerType) => setUpdateBanner(banner)}
        refreshCounter={refreshCounter}
      />
      <FrontPopCreatePopup open={showCreateBanner} handleClose={() => {
        refresh()
        setShowCreateBanner(false)
      }}/>
      <FrontPopUpdatePopup banner={updateBanner} handleClose={() => {
        refresh()
        setUpdateBanner(null)
      }}/>
      <DeleteConfirmPopup open={showDeletePopup} handleClose={() => {
        refresh()
        setShowDeletePopup(false)
      }} handleConfirm={() => {
        Promise.all(selectionIds.map((id) => shopFrontPopupBannerAPI.delete(id)))
          .then((results) => {
            refresh()
            setShowDeletePopup(false)
          })
      }}/>
    </Stack>
  )
}

export default FrontPopTab