export const VISIBLE_APPRAISAL_RESULTS = ["Genuine","Fake","Partly Fake","Undeterminable"] as const;
export const STATE_RESULTS = ["Excellent", "Very Good", "Good", "Poor", "Very Poor","미노출"] as const;
export const APPRAISAL_RESULTS = ["Genuine","Fake","Partly Fake","Undeterminable","미노출"] as const;
export type VisibleAppraisalResultType = typeof VISIBLE_APPRAISAL_RESULTS[number];
export type StateResultType = typeof STATE_RESULTS[number]
export type AppraisalResultType = typeof APPRAISAL_RESULTS[number]

export const COMMON_LABELS = [
  // resultDetailModelTags
  {
    key: "무브먼트",
    en: "MOVEMENT",
    ko: "MOVEMENT"
  },
  {
    key: "브랜드",
    en: "MANUFACTURER",
    ko: "MANUFACTURER"
  },
  {
    key: "MANUFACTURED IN",
    en: "MANUFACTURED IN",
    ko: "MANUFACTURED IN"
  },
  {
    key: "레퍼런스번호",
    en: "REFERENCE NO.",
    ko: "REFERENCE NO."
  },
  {
    key: "serialNo",
    en: "SERIAL NO.",
    ko: "SERIAL NO."
  },

  // resultExteriors
  {
    key: "케이스 소재",
    en: "Case",
    ko: "케이스"
  },
  {
    key: "베젤 소재",
    en: "Bezel",
    ko: "베젤"
  },
  {
    key: "크라운 소재",
    en: "Crown",
    ko: "크라운"
  },
  {
    key: "글라스",
    en: "Glass",
    ko: "글라스"
  },
  {
    key: "브레이슬릿 소재",
    en: "Bracelet",
    ko: "브레이슬릿"
  },
  {
    key: "브레이슬릿 소재 링크",
    en: "Links",
    ko: "링크"
  },
  {
    key: "클라스프",
    en: "Clasp",
    ko: "클라스프"
  },
  {
    key: "다이얼",
    en: "Dial",
    ko: "다이얼"
  },
  {
    key: "다이얼 색상",
    en: "Color",
    ko: "컬러"
  },
  {
    key: "핸즈",
    en: "Hands",
    ko: "핸즈"
  },

  // resultPerformanceCheck
  {
    key: "일오차",
    en: "Accuracy",
    ko: "오차"
  },
  {
    key: "비트에러",
    en: "Beat error",
    ko: "비트에러"
  },
  {
    key: "진동각도",
    en: "Amplitude",
    ko: "진동각"
  },
  {
    key: "방수",
    en: "Water resistance",
    ko: "방수"
  },

  // resultInterior
  {
    key: "칼리버",
    en: "Caliber",
    ko: "칼리버"
  },
  {
    key: "로터",
    en: "Rotor",
    ko: "로터"
  },
  {
    key: "밸런스휠",
    en: "Balance wheel",
    ko: "밸런스 휠"
  },
  {
    key: "헤러스프링",
    en: "Hair spring",
    ko: "헤어스프링"
  },
  {
    key: "팔렛포크",
    en: "Pallet fork",
    ko: "팔렛포크"
  },
  {
    key: "이스케이프먼트",
    en: "Escapement",
    ko: "이스케이프먼트"
  },
  {
    key: "메인플레이트",
    en: "Main plate",
    ko: "메인플레이트"
  },
  {
    key: "배럴",
    en: "Barrel",
    ko: "베럴"
  },
]

export const EXTERIOR_GROUP = [
  {
    group: ["케이스 소재", "베젤 소재", "크라운 소재"],
    result: "케이스 결과"
  },
  {
    group: ["글라스"],
    result: "글라스 결과"
  },
  {
    group: ["브레이슬릿 소재", "브레이슬릿 소재 링크", "클라스프"],
    result: "브레이슬릿 결과"
  },
  {
    group: ["다이얼","다이얼 색상","핸즈"],
    result: "다이얼 결과"
  }
]