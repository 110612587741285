import { HTMLAttributes, PropsWithChildren } from "react"
import styled from "@emotion/styled"

const DetailTableBody = ({ children }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper>{children}</Wrapper>
}

export default DetailTableBody

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #ccc;
`
