import styled from "@emotion/styled"
import { FaqCategoryType, FaqType } from "types/FaqType"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import { DraggableFaqListItem } from "./DraggableFaqListItem"

type Props = {
  faqList: FaqType[]
  category: FaqCategoryType
  selectedFaq: FaqType | undefined
  selectFaq: (faq: FaqType) => void
  onChangeOrder: (prevPriority: number, newPriority: number) => void
}

export const FaqList = ({ faqList, category, selectFaq, selectedFaq, onChangeOrder }: Props) => {
  return (
    <Wrapper>
      <CategoryTitle>{category} 질문</CategoryTitle>
      <FaqListWrapper>
        <DndProvider backend={HTML5Backend}>
          {faqList.map((faq) => {
            return (
              <DraggableFaqListItem
                key={faq.id}
                selectFag={selectFaq}
                faqItem={faq}
                isSelected={selectedFaq?.id === faq.id}
                onChangeOrder={onChangeOrder}
              />
            )
          })}
        </DndProvider>
      </FaqListWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 360px;
`

const CategoryTitle = styled.div`
  padding: 24px 24px 0px 24px;
  color: #333333;
  font-size: 18px;
  font-weight: 700;
`

const FaqListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 32px 0;
`
