import { DateTime } from "luxon"

export function toDateTimeStr(isoDateTime?: string): string | undefined {
  return !isoDateTime ? "-" : DateTime.fromISO(isoDateTime!!).toFormat("yyyy-MM-dd HH:mm:ss")
}

export function toDateStr(isoDateTime?: string, fullYear: boolean = true): string | undefined {
  return isoDateTime
    ? fullYear
      ? DateTime.fromISO(isoDateTime!!).toFormat("yyyy-MM-dd")
      : DateTime.fromISO(isoDateTime!!).toFormat("yy-MM-dd")
    : "-"
}
export function toTimeStr(isoDateTime?: string, second: boolean = true): string | undefined {
  return isoDateTime
    ? second
      ? DateTime.fromISO(isoDateTime!!).toFormat("HH:mm:ss")
      : DateTime.fromISO(isoDateTime!!).toFormat("HH:mm")
    : ""
}

export function toISODateTime(dt?: string): string | undefined {
  return dt ? DateTime.fromFormat(dt, "yyyy-MM-dd'T'hh:mm").toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : undefined
}

export const getDiffDays = (dday: string, from?: string) => {
  const dateFrom = from ? DateTime.fromISO(from) : DateTime.now()

  const diff = dateFrom.diff(DateTime.fromISO(dday), "days", {})

  if (diff.days < 0) return Math.floor(diff.days) * -1

  return Math.floor(diff.days)
}

export const getIsPassedDueDate = (dday: string) => {
  return DateTime.fromISO(dday).startOf("day").toMillis() < DateTime.now().startOf("day").toMillis()
}
/**
 * ISO 8601-compliant string representationa
 * @param datetimeString yyyy-MM-dd'T'hh:mm, 분 이하는 옵셔널
 * @return yyyy-MM-dd'T'hh:mm:ss{+타임존_OFFSET | 'Z'} milliseconds 생략
 */
export function toISO(datetimeString: string) {
  return DateTime.fromISO(datetimeString).toISO({ suppressMilliseconds: true })
}
