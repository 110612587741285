import styled from "@emotion/styled"
import { PenaltyDetail } from "types/PenaltyType"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { toDateTimeStr } from "utils/datetimeUtil"
import { StatusText } from "pages/Penalty/PenaltyListPage/PenaltyListTable/TableBody/TableRow"

type Props = {
  sellOrder: PenaltyDetail["sellOrder"]
  createdAt: string
  completedAt?: string
  status: PenaltyDetail["status"]
}

const PenaltySellOrderInformation = ({ status, sellOrder, createdAt, completedAt }: Props) => {
  return (
    <TableWrapper>
      <InlineWrapper>
        <DetailTableColumCell title={"판매주문번호"}>{sellOrder.id}</DetailTableColumCell>
        <DetailTableColumCell title={"상품번호"}>{sellOrder.product.id}</DetailTableColumCell>
        <DetailTableColumCell title={"판매 신청일시"}>{toDateTimeStr(sellOrder.orderedAt)}</DetailTableColumCell>
        <DetailTableColumCell title={"패널티 발생일"}>{toDateTimeStr(createdAt)}</DetailTableColumCell>
        <DetailTableColumCell title={"패널티 완납일"}>
          {completedAt ? toDateTimeStr(completedAt) : "-"}
        </DetailTableColumCell>
        <DetailTableColumCell title={"진행상태"}>
          <StatusText status={status}>{status}</StatusText>
        </DetailTableColumCell>
      </InlineWrapper>
      <InlineWrapper>
        <DetailTableColumCell title={"판매자"}>{sellOrder.seller.name}</DetailTableColumCell>
        <DetailTableColumCell title={"연락처"}>{sellOrder.seller.phone || "-"}</DetailTableColumCell>
        <DetailTableColumCell title={"계정"}>{sellOrder.seller.email || "-"}</DetailTableColumCell>
        <DetailTableColumCell title={"판매방식"}>
          <b>{sellOrder.sellOrderType}</b>
        </DetailTableColumCell>
      </InlineWrapper>
      <InlineWrapper>
        <DetailTableColumCell
          title={"상품명"}
        >{`${sellOrder.product.brand.sid} ${sellOrder.product.title} ${sellOrder.product.titleSecondary}`}</DetailTableColumCell>
        <DetailTableColumCell title={"Ref No."}>{sellOrder.product.detailModel.fullRefNo}</DetailTableColumCell>
      </InlineWrapper>
    </TableWrapper>
  )
}

export default PenaltySellOrderInformation

const TableWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  div.table-column-cell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`
