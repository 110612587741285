import React, {useState} from "react";
import UserDetailPopupPage from "../pages/User/UserDetailPopupPage";
import {Button} from "@mui/material";

type UserIdProps = {
  id?: number,
}

const UserIdButton: React.FC<UserIdProps> = ({id}) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {open && <UserDetailPopupPage userId={"" + id} closePopup={() => setOpen(false)}/>}
        <Button style={{fontSize: "16px", fontWeight:700, textDecoration:"underline"}} variant={"text"} size={"small"} onClick={() => setOpen(true)}>
        <span>{id}</span>
      </Button>
    </>
  )
}

export default UserIdButton