import {
  checkProductStockOpen,
  uncheckProductStockOpen,
  getProductStockGroupList,
  moveProductListFromStockGroup,
  uncheckProductStockClose,
  checkProductStockClose,
  reorderProductStockItemList,
} from "apis/productStockAPI"
import { DateTime } from "luxon"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { ProductStockGroupType, ProductStockItemType } from "types/ProductStockType"
import { toDateStr } from "utils/datetimeUtil"

export const useProductStockData = () => {
  const [productStockGroupList, setProductStockGroupList] = useState<ProductStockGroupType[]>([])
  const [searchParams, setSearchParams] = useSearchParams()

  const site = useMemo(() => {
    return (searchParams.get("site") as "쇼룸" | "랩스") || "쇼룸"
  }, [searchParams])

  const date = useMemo(() => {
    return searchParams.get("date") || undefined
  }, [searchParams])

  const fetchProductStockList = useCallback(async () => {
    try {
      const result = await getProductStockGroupList(site, date)
      setProductStockGroupList(result)
    } catch (e) {
      toast.error("데이터를 불러오는데 실패했습니다.")
    }
  }, [site, date])

  const onChangeGroup = useCallback(
    async (prevId: number | null, newId: number | null, listItem: ProductStockItemType) => {
      const newList = productStockGroupList.map((stock) => {
        if (stock.id === prevId) {
          return { ...stock, items: stock.items.filter(({ item }) => item.id !== listItem.item.id) }
        }

        if (stock.id === newId) {
          return { ...stock, items: stock.items.concat(listItem) }
        }

        return stock
      })
      try {
        await moveProductListFromStockGroup({
          site,
          idFrom: prevId,
          idTo: newId,
          productIdList: [listItem.item.id],
        })
        setProductStockGroupList(newList)
      } catch {
        toast.error("상품 이동에 실패했습니다.")
      }
    },
    [productStockGroupList, site]
  )

  const onChangeOrder = useCallback(
    async (id: number | null, prevIndex: number, newIndex: number) => {
      const changingStockIndex = productStockGroupList.findIndex((stock) => stock.id === id)

      if (productStockGroupList[changingStockIndex].id === null) return

      if (changingStockIndex === -1) return

      const changedList = productStockGroupList.map((stock, index) => {
        if (index === changingStockIndex) {
          const newList = [...stock.items]

          const [movedItem] = newList.splice(prevIndex, 1)

          newList.splice(newIndex, 0, movedItem)
          return { ...stock, items: newList }
        }

        return stock
      })

      try {
        await reorderProductStockItemList(
          site,
          changedList[changingStockIndex].id,
          changedList[changingStockIndex].items.map(({ item }) => ({ productId: item.id }))
        )

        setProductStockGroupList(changedList)
      } catch {
        toast.error("위치 변경에 실패했습니다.")
      }
    },
    [productStockGroupList, site]
  )

  const onCheckOpen = useCallback(
    async (groupId: number, productId: number, isOpen: boolean) => {
      try {
        if (isOpen) {
          await uncheckProductStockOpen({ site, groupId, productId })
        } else {
          await checkProductStockOpen({ site, groupId, productId })
        }

        fetchProductStockList()
      } catch {
        toast.error("상태 변경에 실패했습니다.")
      }
    },
    [site, fetchProductStockList]
  )

  const onCheckClose = useCallback(
    async (groupId: number, productId: number, isClose: boolean) => {
      try {
        if (isClose) {
          await uncheckProductStockClose({ site, groupId, productId })
        } else {
          await checkProductStockClose({ site, groupId, productId })
        }
        fetchProductStockList()
      } catch {
        toast.error("상태 변경에 실패했습니다.")
      }
    },
    [site, fetchProductStockList]
  )

  const onSelectTab = useCallback(
    (site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고") => {
      setSearchParams({ site })
    },
    [setSearchParams]
  )

  const isEditable = useMemo(() => {
    if (!date) return true

    return date === toDateStr(DateTime.now().toISO())
  }, [date])

  const onSelectDate = useCallback(
    (date: string | undefined) => {
      const nextSearchParams = new URLSearchParams()

      nextSearchParams.set("date", String(date))
      nextSearchParams.set("site", String(site))

      setSearchParams(nextSearchParams)
    },
    [setSearchParams, site]
  )

  useEffect(() => {
    fetchProductStockList()
  }, [fetchProductStockList])

  return {
    isEditable,
    productStockGroupList,
    site,
    date,
    onSelectTab,
    onSelectDate,
    onChangeGroup,
    onChangeOrder,
    fetchProductStockList,
    onCheckClose,
    onCheckOpen,
  }
}
