import { FunctionComponent } from "react"

export const DetailModelTitleInput: FunctionComponent<{
  title: string
  titleSecondary: string
  onChangeTitleSecondary: (titleSecondary: string) => void
}> = ({ title, titleSecondary, onChangeTitleSecondary }) => {
  return (
    <div>
      <DetailModelPrimaryTitleTextField label="타이틀" inputValue={title} />
      <div style={{ height: 12 }} />
      <DetailModelSecondaryTitleTextField
        label="서브타이틀"
        inputValue={titleSecondary}
        inputPlaceholder="ex) 41mm, 블랙, 오이스터 브레이슬릿"
        onChangeInputValue={onChangeTitleSecondary}
      />
    </div>
  )
}

const DetailModelPrimaryTitleTextField: FunctionComponent<{
  label: string
  inputValue: string
}> = ({ label, inputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <div>{inputValue}</div>
    </div>
  )
}

const DetailModelSecondaryTitleTextField: FunctionComponent<{
  required?: boolean
  label: string
  inputValue: string
  inputPlaceholder?: string
  onChangeInputValue: (inputValue: string) => void
}> = ({ required, label, inputValue, inputPlaceholder, onChangeInputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
      <div style={{ width: 120 }}>
        <span>{label}</span>
        {required && <span style={{ color: "#CC2929" }}>*</span>}
      </div>
      <div style={{ width: 8 }} />
      <input
        style={{
          flex: 1,
          height: 38,
          border: "none",
          borderBottom: "1px solid #1C2229",
          outline: "none",
          fontSize: 14,
          fontFamily: "Pretendard",
          lineHeight: "18px",
        }}
        type="text"
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={(event) => onChangeInputValue(event.target.value)}
      />
    </div>
  )
}
