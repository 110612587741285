import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const PopupPageScrollContainer = ({ children }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <Wrapper>{children}</Wrapper>
}

export default PopupPageScrollContainer

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
`
