import ListTable from "components/Table/List"
import ListTableBody from "components/Table/List/TableBody"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import styled from "@emotion/styled"
import { Pagination } from "@mui/material"
import CheckBox from "components/Checkbox"
import ListTableFooter from "components/Table/List/TableFooter"
import { useCallback, useState } from "react"
import { ProductStockTableListItem } from "./ProductStockTableListItem"
import Button from "components/Button"
import { ProductStockTargetSelectorPopup } from "./ProductStockTargetSelectorPopup"
import { ProductStockTableEmptyItem } from "./ProductStockTableEmptyItem"
import { ProductStockItemType } from "types/ProductStockType"
import { toDateTimeStr } from "utils/datetimeUtil"
import _ from "lodash"
import { isNotEmpty } from "utils/validationUtils"

export const stockTableHeaderItemList: {
  key: string
  title: string
  width?: string
  getValue?: (param: SelectableProductStockItemType, onClick?: any) => any
}[] = [
  {
    key: "drag",
    title: "drag",
    width: "55px",
    getValue: () => <Icon src={require("../../images/24_align_justify.png")} />,
  },
  {
    key: "isSelected",
    title: "checkbox",
    width: "55px",
    getValue: (param) => <CheckBox checked={param.isSelected} readOnly />,
  },
  { key: "id", title: "상품번호", width: "88px", getValue: (param) => param.item.id },
  { key: "serialNo", title: "시리얼 넘버", width: "88px", getValue: (param) => param.item.serialNo },
  { key: "sellType", title: "판매방식", width: "88px", getValue: (param) => param.item.saleType },
  { key: "title", title: "상품명", getValue: (param) => `${param.item.title} ${param.item.titleSecondary}` },
  { key: "refNo", title: "Ref No", getValue: (param) => param.item.detailModel.refNo },
  { key: "finalOn", title: "최종 입출고 상태", getValue: (param) => param.item.location },
  { key: "finalOut", title: "최종 입출고 일시", getValue: (param) => toDateTimeStr(param.item.locationUpdatedAt) },
  {
    key: "open",
    title: "OPEN",
    width: "102px",
  },
  {
    key: "close",
    title: "CLOSE",
    width: "102px",
  },
]

export type SelectableProductStockItemType = ProductStockItemType & { isSelected: boolean; priority: number }

type Props = {
  id: number | null
  label: string
  list: ProductStockItemType[]
  count: number
  site: "쇼룸" | "랩스" | "장성원시계" | "두나무금고"
  isEditable: boolean
  onChangeGroup: (prevId: number | null, newId: number | null, listItem: SelectableProductStockItemType) => void
  onChangeOrder: (id: number | null, prevIndex: number, newIndex: number) => void
  onCheckOpen: (groupId: number, productId: number, isOpen: boolean) => void
  onCheckClose: (groupId: number, productId: number, isClose: boolean) => void
  fetchProductStockList: () => void
}

export const ProductStockTable = ({
  id,
  label,
  count,
  list,
  site,
  isEditable,
  onChangeGroup,
  onCheckOpen,
  onCheckClose,
  onChangeOrder,
  fetchProductStockList,
}: Props) => {
  const [isOpenListSelector, setIsOpenListSelector] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState<ProductStockItemType[]>([])

  const toggleAllProductList = useCallback(() => {
    const hasCheckedItem = selectedProduct.length > 0
    hasCheckedItem ? setSelectedProduct([]) : setSelectedProduct(list)
  }, [selectedProduct, setSelectedProduct, list])

  const getHeaderItem = useCallback(
    (title: string) => {
      if (title === "checkbox") {
        return (
          <CheckBox onChange={toggleAllProductList} checked={isNotEmpty(list) && _.isEqual(list, selectedProduct)} />
        )
      }

      if (title === "drag") {
        return <></>
      }

      return title
    },
    [toggleAllProductList, list, selectedProduct]
  )

  const getIsSelectedProduct = useCallback(
    (listItem: ProductStockItemType) => {
      return !!selectedProduct.find(({ item }) => item.id === listItem.item.id)
    },
    [selectedProduct]
  )

  const onSelectItem = useCallback(
    (listItem: ProductStockItemType) => {
      if (getIsSelectedProduct(listItem)) {
        setSelectedProduct((prev) => prev.filter(({ item }) => item.id !== listItem.item.id))
      } else {
        setSelectedProduct((prev) => prev.concat(listItem))
      }
    },
    [getIsSelectedProduct]
  )

  const onClickTargetSelectorPopup = useCallback(() => {
    if (selectedProduct.length === 0) {
      window.alert("선택된 상품이 없습니다.")
      return
    }

    setIsOpenListSelector(true)
  }, [selectedProduct])

  const clearSelectedProductList = useCallback(() => {
    setSelectedProduct([])
  }, [])

  return (
    <>
      {isOpenListSelector && (
        <ProductStockTargetSelectorPopup
          selectedProductCount={selectedProduct.length}
          site={site}
          listId={id}
          clearSelectedProductList={clearSelectedProductList}
          closeModal={async () => {
            await fetchProductStockList()
            setIsOpenListSelector(false)
          }}
          productIdList={selectedProduct.map(({ item }) => item.id)}
        />
      )}
      <InlineWrapper>
        <Label>
          {label}
          <b>{count}</b>
        </Label>
        <ButtonWrapper>
          <Button buttonStyle="blue-border" buttonSize="M" onClick={onClickTargetSelectorPopup} disabled={!isEditable}>
            선택 상품 이동
          </Button>
        </ButtonWrapper>
      </InlineWrapper>

      <ListTable>
        <ListTableHeader>
          {stockTableHeaderItemList.map((headerItem) => (
            <ListTableHeaderItem
              key={headerItem.title}
              style={{ maxWidth: headerItem.width, justifyContent: "center" }}
            >
              {getHeaderItem(headerItem.title)}
            </ListTableHeaderItem>
          ))}
        </ListTableHeader>
        <ListTableBody>
          {list.length > 0 ? (
            list.map((listItem, index) => (
              <ProductStockTableListItem
                key={index}
                listItem={{
                  id,
                  item: { ...listItem, isSelected: getIsSelectedProduct(listItem), priority: index },
                }}
                isEditable={isEditable}
                onChangeGroup={onChangeGroup}
                onChangeOrder={onChangeOrder}
                onCheckClose={onCheckClose}
                onCheckOpen={onCheckOpen}
                onSelectItem={onSelectItem}
              />
            ))
          ) : (
            <ProductStockTableEmptyItem id={id} onChangeGroup={onChangeGroup} />
          )}
        </ListTableBody>
        <ListTableFooter>
          <Pagination count={1} onChange={(e, page) => console.log(page - 1)} page={true ? 0 + 1 : 1} />
        </ListTableFooter>
      </ListTable>
    </>
  )
}

const Label = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;

  b {
    color: #3a74cd;
    margin-left: 4px;
  }
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  aling-items: center;
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
  width: 95px;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
`
