import React from "react"
import { DataGrid, GridAlignment, GridColTypeDef, GridRowModel, useGridApiContext } from "@mui/x-data-grid"
import { TranslationTableDetailType } from "../../types/TranslationTableDetailType"
import { TranslationColumnManagerState } from "./useTranslationColumnManager"
import { setTranslationTableAlias } from "../../apis/translationAPI"
import { InputBase, InputBaseProps, Paper, Popper } from "@mui/material"

type Props = {
  state: TranslationColumnManagerState
  dispatch: React.Dispatch<React.SetStateAction<TranslationColumnManagerState>>
}

const TranslationColumnManagerTable: React.FC<Props> = ({ state, dispatch }) => {
  // @ts-ignore
  const EditTextarea = (props: GridRenderEditCellParams<any, string>) => {
    const { id, field, value, colDef, hasFocus } = props
    const [valueState, setValueState] = React.useState(value)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>()
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)
    const apiRef = useGridApiContext()

    React.useLayoutEffect(() => {
      if (hasFocus && inputRef) {
        inputRef.focus()
      }
    }, [hasFocus, inputRef])

    const handleRef = React.useCallback((el: HTMLElement | null) => {
      setAnchorEl(el)
    }, [])

    const handleChange = React.useCallback<NonNullable<InputBaseProps["onChange"]>>(
      (event) => {
        const newValue = event.target.value
        setValueState(newValue)
        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event)
      },
      [apiRef, field, id]
    )

    return (
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={6}
                value={valueState}
                sx={{ textarea: { resize: "both" }, width: "100%" }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    )
  }

  const multilineColumn: GridColTypeDef = {
    type: "string",
    renderEditCell: (params) => <EditTextarea {...params} />,
  }

  const getColumns = () => {
    return [
      {
        field: "tableSchema",
        headerName: "스키마",
        width: 100,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "tableName",
        headerName: "테이블",
        width: 250,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "columnName",
        headerName: "컬럼",
        width: 400,
        flex: 1,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "translationTypeCode",
        headerName: "번역 타입 코드",
        width: 150,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "isTranslationTarget",
        headerName: "번역대상 여부",
        width: 150,
        valueGetter: (params: { row: { isTranslationTarget: boolean } }) => {
          return `${params.row.isTranslationTarget ? "예" : "아니오"}`
        },
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "isAutoTranslation",
        headerName: "자동번역 여부",
        width: 150,
        valueGetter: (params: { row: { isAutoTranslation: boolean } }) => {
          return `${params.row.isAutoTranslation ? "예" : "아니오"}`
        },
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "isDelete",
        headerName: "삭제 여부",
        width: 150,
        valueGetter: (params: { row: { isDelete: boolean } }) => {
          return `${params.row.isDelete ? "예" : "아니오"}`
        },
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
      },
      {
        field: "tableNameAlias",
        headerName: "별칭-테이블",
        width: 200,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
        editable: true,
        ...multilineColumn,
      },
      {
        field: "columnNameAlias",
        headerName: "별칭-컬럼",
        width: 200,
        headerAlign: "center" as GridAlignment,
        align: "center" as GridAlignment,
        editable: true,
        ...multilineColumn,
      },
    ]
  }

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    console.log(newRow)
    if (newRow.tableNameAlias !== oldRow.tableNameAlias || newRow.columnNameAlias !== oldRow.columnNameAlias) {
      setTranslationTableAlias({
        columnName: newRow.columnName,
        tableName: newRow.tableName,
        tableNameAlias: newRow.tableNameAlias,
        columnNameAlias: newRow.columnNameAlias,
      })
    }
    return newRow
  }

  return (
    <DataGrid<TranslationTableDetailType>
      experimentalFeatures={{ newEditingApi: true }}
      getRowId={(row) => row.columnName}
      columns={getColumns()}
      processRowUpdate={processRowUpdate}
      getRowHeight={() => 70}
      pagination
      paginationMode={"server"}
      onSelectionModelChange={(ids: any) => dispatch((prevState) => ({ ...prevState, columnNameList: ids }))}
      checkboxSelection
      onPageChange={(page) => dispatch((prevState) => ({ ...prevState, page }))}
      onPageSizeChange={(size) => dispatch((prevState) => ({ ...prevState, size }))}
      style={{ width: "100%", height: "100%" }}
      page={state.page}
      pageSize={state.size}
      rowCount={state.tableDetailList.totalElements || 0}
      rows={state.tableDetailList.content}
    />
  )
}

export default TranslationColumnManagerTable
