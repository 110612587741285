import {Button, Grid, Paper, Stack} from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import React, {useState} from "react";
import {DateTime} from "luxon";
import {downloadFinanceReport, ReportRangeSearchParam} from "../../apis/reportAPI"


const initParams = {
    startDate: DateTime.now().minus({day: 7}).toISODate(),
    endDate: DateTime.now().toISODate()
}


const FinanceManager = () => {
    const [searchParams, setSearchParams] = useState(initParams)

    const handleExcelDownloadClick = async () => {
        try {
            const blob = await downloadFinanceReport({startDate: searchParams.startDate, endDate: searchParams.endDate})
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `finance_${searchParams.startDate}_${searchParams.endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading excel:', error);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                    <Stack spacing={2}>
                        <DateRangePicker
                            startDate={searchParams.startDate}
                            endDate={searchParams.endDate}
                            onChange={(change) => {
                                setSearchParams({...searchParams, startDate: change.startDate, endDate: change.endDate})
                            }}
                        />
                        <Stack direction={'row'} justifyContent={"flex-end"} spacing={1}>
                            <Button variant={"contained"} size={"small"} onClick={handleExcelDownloadClick}>엑셀 다운로드</Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>

        </Grid>)
}

export default FinanceManager