import { AdminUserListSearchParams, AdminUserType, UpdateAdminUserType } from "../types/AdminUserType"
import { httpGet, httpPost } from "../utils/httpAPI"
import { PageType } from "../types/PageType"

export const findAdminUserList: (searchParams: AdminUserListSearchParams) => Promise<PageType<AdminUserType>> = (
  searchParams
) => httpGet("/admin/users", searchParams)

export const findAdminUser: (id: number) => Promise<AdminUserType> = (id: number) => httpGet(`/admin/users/${id}`)

export const updateAdminUser: (id: number, dto: UpdateAdminUserType) => Promise<void> = (
  id: number,
  dto: UpdateAdminUserType
) => httpPost(`/admin/users/${id}`, dto)
