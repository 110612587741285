import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React, { useState } from "react"
import { downloadPointReport } from "../../apis/reportAPI"
import styled from "@emotion/styled"


const PointReport = () => {

  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth()

  const YEAR_LIST: string[] = Array.from(Array(currentYear - 2021).keys()).map(year => (year + 2022).toString())
  const MONTH_LIST: string[] = Array.from(Array(12).keys()).map(month => (month + 1).toString())
  const searchYearMonthList = (YEAR_LIST.map((year: string) => (MONTH_LIST.map((month: string) => `${year}-${month.padStart(2, "0")}`)))).flat().filter((item: string) => (item >= "2022-08" && item <= `${currentYear}-${currentMonth.toString().padStart(2, "0")}`)).reverse()

  const [targetMonth, setTargetMonth] = useState(searchYearMonthList[0])

  const handleExcelDownloadClick = async () => {
    try {
      const blob = await downloadPointReport({ targetMonth: targetMonth })
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `point_report_${targetMonth}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error("Error downloading excel:", error)
    }
  }

  return (
    <SearchBarWrapper>
      <Wrapper>
        <FormControl style={{ minWidth: "180px", marginRight: "15px" }}>
          <InputLabel>조회 월</InputLabel>
          <Select
            size={"small"}
            defaultValue={"ALL"}
            required
            value={targetMonth}
            onChange={(params) => setTargetMonth(params.target.value)}
          >
            {searchYearMonthList.map((item: string) => (<MenuItem value={item}>{item}</MenuItem>))}
          </Select>
        </FormControl>
          <Button variant={"contained"} size={"small"} onClick={handleExcelDownloadClick}>엑셀 다운로드</Button>
      </Wrapper>
    </SearchBarWrapper>)
}

const SearchBarWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 28px;
`


const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
`

export default PointReport