import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"
import { useState } from "react"
import { UserMemoType } from "types/UserType"

type Props = {
  isOpen: boolean
  closeModal: () => void
  memos: UserMemoType[]
  saveMemo: (memo: string) => void
  deleteMemo: (memoID: number) => void
}

const UserMemoChangeModal = ({ isOpen, closeModal, saveMemo, deleteMemo, memos }: Props) => {
  const [inputValue, setInputValue] = useState("")

  const clickSaveMemoButton = () => {
    saveMemo(inputValue)
    setInputValue("")
    closeModal()
  }

  const confirmToDeleteMemo = (memoId: number) => {
    if (window.confirm("메모를 삭제하시겠습니까?")) {
      deleteMemo(memoId)
      closeModal()
      return
    }

    return
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>메모 관리</h3>
          <div>회원 메모를 관리할 수 있습니다.</div>
        </ModalContentHeader>
        <ModalContentBody>
          {!!memos.length && (
            <MemoListWRapper>
              {memos.map((memoObject) => (
                <MemoWrapper key={memoObject.id}>
                  {memoObject.memo}
                  <MemoDeleteButtonWrapper>
                    <Button buttonStyle="red-fill" buttonSize="S" onClick={() => confirmToDeleteMemo(memoObject.id)}>
                      삭제
                    </Button>
                  </MemoDeleteButtonWrapper>
                </MemoWrapper>
              ))}
            </MemoListWRapper>
          )}
          <TextArea value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button buttonStyle="blue-fill" buttonSize="M" disabled={!inputValue} onClick={clickSaveMemoButton}>
              추가
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default UserMemoChangeModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`

const MemoListWRapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const MemoWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  margin: 4px 0;
`

const MemoDeleteButtonWrapper = styled.div`
  margin-left: 10px;
`

const TextArea = styled.textarea`
  flex: 1;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  outline: none;
  padding: 10px;
  display: flex;
  algin-items: center;
`
