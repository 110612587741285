import { FunctionComponent, useState } from "react"
import { EventFormStateType } from "types/EventFormStateType"
import styled from "@emotion/styled"

export const TemplatePreview: FunctionComponent<{ eventFormState: EventFormStateType }> = ({ eventFormState }) => {
  const [openNoticeImage, setOpenNoticeImage] = useState(false)
  const [language, setLanguage] = useState('ko');

 const getImageUrlByLanguage = (imageKorean: any, imageUrlEnglish: any, imageUrlJapenese: any) => {
   let imageUrl = imageKorean
   if (language === "ja") {
     imageUrl = imageUrlJapenese?.trim() || imageUrlEnglish?.trim() || imageKorean
   } else if (language === "en") {
     imageUrl = imageUrlEnglish?.trim() || imageKorean
   }
   return imageUrl
  }

  return (
    <>
    <div style={{ flex: 1, position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          overflowY: "auto",
          fontSize: 0,
        }}
      >

        {/* 언어 선택 버튼 */}
        <div style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}>
          <button
            onClick={() => setLanguage("ko")}
            style={{
              margin: "0 5px",
              padding: "10px 20px",
              border: "1px solid #ccc",
              backgroundColor: language === "ko" ? "#007BFF" : "#fff",
              color: language === "ko" ? "#fff" : "#000",
              cursor: "pointer",
            }}
          >
            한국어
          </button>
          <button
            onClick={() => setLanguage("en")}
            style={{
              margin: "0 5px",
              padding: "10px 20px",
              border: "1px solid #ccc",
              backgroundColor: language === "en" ? "#007BFF" : "#fff",
              color: language === "en" ? "#fff" : "#000",
              cursor: "pointer",
            }}
          >
            영어
          </button>
          <button
            onClick={() => setLanguage("ja")}
            style={{
              margin: "0 5px",
              padding: "10px 20px",
              border: "1px solid #ccc",
              backgroundColor: language === "ja" ? "#007BFF" : "#fff",
              color: language === "ja" ? "#fff" : "#000",
              cursor: "pointer",
            }}
          >
            일본어
          </button>
        </div>


        {eventFormState.templateBodyImage.images
          .filter((image) => image.onDisplay)
          .map((image, index) => {
            let imageUrl = getImageUrlByLanguage(image.imageUrl, image.imageUrlEnglish, image.imageUrlJapanese)
            return (
                <img key={`${index}-${imageUrl}`} style={{ width: "100%" }} src={imageUrl!!} alt="" />
            )
          })}
        {eventFormState.templateBodyNoticeImage.onDisplay &&
          (openNoticeImage ? (

            <>
              <img
                style={{ width: "100%" }}
                src={getImageUrlByLanguage(eventFormState.templateBodyNoticeImage.notice.openedImageUrl,
                  eventFormState.templateBodyNoticeImage.notice.openedImageUrlEnglish,
                  eventFormState.templateBodyNoticeImage.notice.openedImageUrlJapanese)}
                alt=""
                onClick={() => {
                  setOpenNoticeImage(false)
                }}
              />
              <img
                style={{ width: "100%" }}
                src={getImageUrlByLanguage(eventFormState.templateBodyNoticeImage.notice.mainImageUrl
                , eventFormState.templateBodyNoticeImage.notice.mainImageUrlEnglish
                , eventFormState.templateBodyNoticeImage.notice.mainImageUrlJapanese)}
                alt="" />
            </>
          ) : (
            <img
              style={{ width: "100%" }}
              src={getImageUrlByLanguage(eventFormState.templateBodyNoticeImage.notice.closedImageUrl
                , eventFormState.templateBodyNoticeImage.notice.closedImageUrlEnglish
                , eventFormState.templateBodyNoticeImage.notice.closedImageUrlJapanese)}
              alt=""
              onClick={() => {
                setOpenNoticeImage(true)
              }}
            />
          ))}
        {eventFormState.templateBodyHeaderImage.onDisplay && (
          <img
            style={{ width: "100%" }}
            src={getImageUrlByLanguage(eventFormState.templateBodyHeaderImage.header.imageUrl
              , eventFormState.templateBodyHeaderImage.header.imageUrlEnglish
              , eventFormState.templateBodyHeaderImage.header.imageUrlJapanese)}
            alt="" />
        )}
        {eventFormState.templateBodyProductTab.onDisplay && (
          <div
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: 14,
            }}
          >
            상품탭 "상품관리" 에서 등록상품 확인
          </div>
        )}
      </div>
    </div>
    </>
  )
}
