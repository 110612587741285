import {Button, Grid, Paper} from "@mui/material"
import CategoryTable from "./CategoryTable"
import {useEffect, useReducer} from 'react'
import AddCategoryPopup from "./AddCategoryPopup";
import EditCategoryPopup from "./EditCategoryPopup";
import {CategoryType} from "types/CategoryType";
import {deleteCategory, getCategories, hideCategory, showCategory} from "../../apis/categoryAPI";

interface CategoryState {
    categories: CategoryType[],
    selectCategoryIds: string[],
    isShowAddPopup: boolean,
    isShowEditPopup: boolean,
    editCategory: CategoryType | null,
    refresh: boolean
}

const initState: CategoryState = {
    categories: [],
    selectCategoryIds: [],
    isShowAddPopup: false,
    isShowEditPopup: false,
    editCategory: null,
    refresh: false
}

interface CategoryAction {
    type: string,
    payload?: any
}

function reducer(state: CategoryState, action: CategoryAction): CategoryState {
    switch (action.type) {
        case 'FETCH_CATEGORIES': return {...state, categories: action.payload}
        case 'SELECT_CATEGORY_IDS': return {...state, selectCategoryIds: action.payload}
        case 'SHOW_ADD_CATEGORY_POPUP': return {...state, isShowAddPopup: true}
        case 'HIDE_ADD_CATEGORY_POPUP': return {...state,  isShowAddPopup: false}
        case 'SHOW_EDIT_CATEGORY_POPUP': return {...state,  isShowEditPopup: true, editCategory: action.payload}
        case 'HIDE_EDIT_CATEGORY_POPUP': return {...state,  isShowEditPopup: false}
        case 'REFRESH': return {...state, refresh: !state.refresh}
    }

    return state;
}

const Category = () => {
    const [state, dispatch] = useReducer(reducer, initState)

    const fetchCategories = () => {getCategories().then(categories => dispatch({type: 'FETCH_CATEGORIES', payload: categories}))}

    useEffect(() => {
        fetchCategories()
    }, [state.refresh])

    const handleDeleteButtonClick = () => Promise.all(state.selectCategoryIds.map((id) => deleteCategory(id))).then(() => fetchCategories())
    const handleHideButtonClick = () => Promise.all(state.selectCategoryIds.map((id) => hideCategory(id))).then(() => fetchCategories())
    const handleShowButtonClick = () => Promise.all(state.selectCategoryIds.map((id) => showCategory(id))).then(() => fetchCategories())
    const handleAddButtonClick = () => dispatch({type: 'SHOW_ADD_CATEGORY_POPUP'})

    return (
        <Grid container spacing={2}>
            <AddCategoryPopup open={state.isShowAddPopup} handleClose={() => {
                dispatch({type: 'HIDE_ADD_CATEGORY_POPUP'})
                dispatch({type: 'REFRESH'})
            }}/>
            <EditCategoryPopup open={state.isShowEditPopup} editCategory={state.editCategory as CategoryType} handleClose={() => {
                dispatch({type: 'HIDE_EDIT_CATEGORY_POPUP'})
                dispatch({type: 'REFRESH'})
            }}/>

            <Grid item xs={12}>
                <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                        <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleShowButtonClick}>노출</Button></Grid>
                        <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleHideButtonClick}>숨김</Button></Grid>

                        <Grid item xs={1}><Button variant={"contained"} size={"small"} fullWidth onClick={handleAddButtonClick}>추가</Button></Grid>
                        <Grid item xs={1}><Button variant={"contained"} size={"small"} color={"error"} fullWidth onClick={handleDeleteButtonClick} >삭제</Button></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryTable
                            rows ={state.categories}
                            dispatch ={dispatch}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>)
}

export default Category