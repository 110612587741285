import React, { Dispatch, SetStateAction, useCallback } from "react"
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid"
import { Button, Grid } from "@mui/material"
import { ProvisionalPreSettlementType } from "../../../types/PreSettlementType"
import { ProvisionalPreSettlementManagerState } from "../ProvisionalPreSettlementManager"



function createColumns(handleEditButtonClick:(row:ProvisionalPreSettlementType)=>void): GridColDef[] {
  const getColorByStatus:(status:ProvisionalPreSettlementType['status'])=> 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' = (status) => {
    switch (status) {
      case "작성대기":
        return 'error'
      case "작성완료":
        return 'success'
      case "안내완료":
      case "위탁전환대기":
      case "위탁전환완료":
        return 'primary'
      case "지급반려":
        return 'warning'
    }
  }

  return [
    {
      field: 'userId',
      headerName: "판매자 ID",
      editable: false,
      sortable: false,
      maxWidth: 120,
      flex: 1,
      valueGetter: (params: { row:ProvisionalPreSettlementType }) => {
        return params.row.sellerId
      }
    },
    {
      field: 'productId',
      headerName: "상품 번호",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 130,
      maxWidth: 160,
      renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>상품번호 <br/> (판매주문번호)</p>),
      valueGetter: (params: { row: ProvisionalPreSettlementType }) => `${params.row.product.id}`,
      renderCell: (params: { row:ProvisionalPreSettlementType }) => (
        <div style={{textAlign: "left"}}>
          <p><span style={{fontWeight: "bold"}}>{params.row.product.id}</span>
            <br/><span style={{color: '#aaa'}}>{params.row.orderId}</span></p>
        </div>
      )
    },
    {
      field: 'status',
      headerName: "진행상태",
      editable: false,
      sortable: false,
      align: 'center',
      flex: 1,
      maxWidth: 100,
      valueGetter: (params: { row: ProvisionalPreSettlementType }) => {
        return `${params.row.status}`
      },
      renderCell: (params: { row: ProvisionalPreSettlementType }) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{fontWeight: "bold"}}
            onClick={()=> handleEditButtonClick(params.row)}
            color={getColorByStatus(params.row.status)}>
            {params.row.status}
          </Button>
        </div>
      )
    },
    {
      field: 'productName',
      headerName: "상품명",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>상품번호 <br/> (판매주문번호)</p>),
      valueGetter: (params: { row: ProvisionalPreSettlementType }) => `${params.row.product.id}`,
      renderCell: (params: { row:ProvisionalPreSettlementType }) => (
        <div style={{textAlign: "left"}}>
          <p><span style={{fontWeight: "bold"}}>{params.row.product.title}</span>
            <br/><span style={{color: '#aaa'}}>{params.row.product.titleSecondary}</span></p>
        </div>
      )
    },
    {
      field: 'refNo',
      headerName: "Ref. No",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 150,
      valueGetter: (params: { row:ProvisionalPreSettlementType }) => params.row.product.detailModel.fullRefNo,
    },
  ]
}

type ProvisionalPreSettlementTableProps = {
  state:ProvisionalPreSettlementManagerState,
  dispatch: Dispatch<SetStateAction<ProvisionalPreSettlementManagerState>>
  fetch:(params:{page:number, size:number})=>void
}

export const ProvisionalPreSettlementTable:React.FC<ProvisionalPreSettlementTableProps> = (props)=>{

  const onPressEditProvisionalPreSettlement = useCallback<(params:ProvisionalPreSettlementType)=>void>((params)=>{
    props.dispatch((prevState)=>({
      ...prevState,
      isShowManagePopup:true,
      selectedProvisionalPreSettlement:params
    }))
  }, [props])

  return (
    <Grid container spacing={1} sx={{pt: 1}}>
      <Grid item xs={12}>
        <div style={{height: 1200, width: '100%'}}>
          <DataGrid<ProvisionalPreSettlementType>
            getRowId={row => row.id}
            columns={createColumns(onPressEditProvisionalPreSettlement)}

            pagination
            paginationMode="server"
            onPageChange={(page) => {
              props.fetch({page, size: props.state.preSettlementItems.pageable?.pageSize || 25})
            }}
            onPageSizeChange={(size) => {
              props.fetch({page: 0, size})
            }}

            page={props.state.preSettlementItems.pageable?.pageNumber ?? 0}
            pageSize={props.state.preSettlementItems.pageable?.pageSize ?? 25}
            rowCount={props.state.preSettlementItems.totalElements ?? 0}
            rows={props.state.preSettlementItems.content ?? []}

            disableSelectionOnClick={true}
            loading={props.state.loading}
          />

        </div>
      </Grid>
    </Grid>
  )

}
