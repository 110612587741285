import { deleteTerm, getTermList, postTermDetail, updateTermDetail } from "apis/termAPI"
import { DateTime } from "luxon"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { TermCategoryType, TermDetailPostBody, TermType } from "types/TermType"

export const useTermData = () => {
  const [selectedType, setSelectedType] = useState<TermCategoryType>("이용약관")
  const [selectedTermDetail, setSelectedTermDetail] = useState<TermType>()
  const [termList, setTermList] = useState<TermType[]>([])
  const [termDetailState, setTermDetailState] = useState<TermDetailPostBody>({
    type: "이용약관",
    title: "",
    content: "",
    startDate: "",
    notifyDate: "",
    required: false,
    endDate: undefined,
  })

  const [markdownState, setMarkdownState] = useState("")

  const selectType = (type: TermCategoryType) => {
    setSelectedType(type)
    fetchAvailableTerm(type)
  }

  const copyLastestTerm = useCallback(() => {
    if (!termList.length) {
      setTermDetailState({
        type: "이용약관",
        title: "",
        content: "",
        required: false,
        startDate: DateTime.now().plus({ days: 1 }).toISO(),
        notifyDate: undefined,
        endDate: undefined,
      })
    } else {
      const lastestTerm = termList[0]
      setTermDetailState({
        ...lastestTerm,
        startDate: DateTime.now().plus({ days: 1 }).toISO(),
        notifyDate: undefined,
        endDate: undefined,
      })
    }
  }, [termList])

  const selectTerm = useCallback(
    (term: TermType | string) => {
      if (typeof term === "string") {
        setSelectedTermDetail(undefined)
        copyLastestTerm()
      } else {
        setSelectedTermDetail(term)
        setTermDetailState({
          type: term.type,
          subType: term.subType,
          title: term.title,
          subtitle: term.subType,
          content: term.content,
          notifyDate: term.notifyDate,
          startDate: term.startDate,
          required: term.required,
          endDate: term.endDate,
        })
      }
    },
    [copyLastestTerm]
  )

  const fetchAvailableTerm = useCallback(
    async (type: TermCategoryType) => {
      const { content } = await getTermList({ types: [type] })

      const firstTerm = content[0]

      setTermList(content)
      selectTerm(firstTerm)
    },
    [selectTerm]
  )

  const changeTermDatail = (key: string, value: any) => {
    setTermDetailState((prev) => ({ ...prev, [key]: value }))
  }

  const validateForm = useCallback((): boolean => {
    if (!termDetailState.notifyDate) {
      toast.error("고지일을 입력해주세요")
      return false
    }

    if (
      selectedTermDetail &&
      DateTime.fromISO(selectedTermDetail.startDate).startOf("day").toMillis() <=
        DateTime.now().startOf("day").toMillis()
    ) {
      toast.error("이미 시행중이거나 만료된 약관은 수정할 수 없습니다.")
      return false
    }

    return true
  }, [termDetailState, selectedTermDetail])

  const handleDeleteTerm = useCallback(async () => {
    if (!selectedTermDetail) return

    if (!window.confirm("약관을 삭제하시겠습니까?")) return

    try {
      await deleteTerm(selectedTermDetail.id)
      toast.success("약관을 삭제했습니다.")
      fetchAvailableTerm(selectedType)
    } catch (err) {
      toast.error("약관 삭제에 실패했습니다.")
    }
  }, [selectedTermDetail, selectedType, fetchAvailableTerm])

  const createTerm = useCallback(async () => {
    if (!window.confirm("약관을 등록하시겠습니까?")) return

    try {
      await postTermDetail({ ...termDetailState, content: markdownState })
      fetchAvailableTerm(selectedType)
      toast.success("약관이 생성되었습니다.")
    } catch (err) {
      toast.error("약관 생성에 실패했습니다.")
    }
  }, [termDetailState, selectedType, markdownState, fetchAvailableTerm])

  const updateTerm = useCallback(
    async (termId: number) => {
      if (!window.confirm("약관을 수정하시겠습니까?")) return

      try {
        await updateTermDetail(termId, { ...termDetailState, content: markdownState })
        fetchAvailableTerm(selectedType)

        toast.success("약관이 수정되었습니다.")
      } catch (err) {
        toast.error("약관 수정에 실패했습니다.")
      }
    },
    [termDetailState, selectedType, markdownState, fetchAvailableTerm]
  )

  const handleSaveTerm = useCallback(async () => {
    if (!validateForm()) return

    if (!selectedTermDetail) {
      createTerm()
    } else {
      updateTerm(selectedTermDetail.id)
    }
  }, [createTerm, updateTerm, selectedTermDetail, validateForm])

  const isSavable = useMemo(() => {
    if (!selectedTermDetail) return true

    return (
      selectedTermDetail.title !== termDetailState.title ||
      selectedTermDetail.content !== markdownState ||
      selectedTermDetail.startDate !== termDetailState.startDate ||
      selectedTermDetail.endDate !== termDetailState.endDate ||
      selectedTermDetail.notifyDate !== termDetailState.notifyDate
    )
  }, [selectedTermDetail, termDetailState, markdownState])

  const onChangeMakdownState = (markdown: string) => {
    setMarkdownState(markdown)
  }

  useMount(() => {
    fetchAvailableTerm("이용약관")
  })

  useEffect(() => {
    setMarkdownState(termDetailState.content)
  }, [termDetailState])

  return {
    selectedType,
    selectType,
    termDetailState,
    changeTermDatail,
    isSavable,
    handleDeleteTerm,
    handleSaveTerm,
    termList,
    selectedTermDetail,
    selectTerm,
    markdownState,
    onChangeMakdownState,
  }
}
