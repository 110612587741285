import { CSSProperties, InputHTMLAttributes } from "react"
import styled from "@emotion/styled"
import { Colors } from "@viver-inc/viver-ui"
import { css } from "@emotion/react"
const DESIGN_FONTSIZE = 8
const PRINT_FONTSIZE = 12
export const HAS_DESIGN_PRINT = true
const getScreenEm = (px:number) => px/DESIGN_FONTSIZE
const getPrintEm = (px:number) => px/PRINT_FONTSIZE
const getPaddingOrMargin = (
	props: PaddingOrMarginProps,
	side: "Left" | "Right" | "Bottom" | "Top",
	type: "padding" | "margin"
) => {
	const printValue = props[`$${type}${side}Print`];
	const normalValue = props[`$${type}${side}`];
	return printValue ? getPrintEm(printValue) + "rem" : normalValue ? getPrintEm(normalValue) + "rem" : 0;
};

type PaddingOrMarginProps = {
	$paddingTop?:number,
	$paddingBottom?:number,
	$paddingLeft?:number,
	$paddingRight?:number,
	$marginTop?:number,
	$marginBottom?:number,
	$marginLeft?:number,
	$marginRight?:number,

	$paddingTopPrint?:number,
	$paddingBottomPrint?:number,
	$paddingLeftPrint?:number,
	$paddingRightPrint?:number,
	$marginTopPrint?:number,
	$marginBottomPrint?:number,
	$marginLeftPrint?:number,
	$marginRightPrint?:number,
}

type BoxType = {
  $height?:number,
  $width?:number | string,
	$isBg?:boolean,
} & PaddingOrMarginProps

type FlexBoxType = {
  $flexDirection?:CSSProperties["flexDirection"],
  $gap?:number,
  $justifyContent?:CSSProperties["justifyContent"],
  $alignItems?:CSSProperties["alignItems"],
  $flexShrink?:number,
	$display?:"flex" | "inline-flex",
}
type TextType = {
  $fontSize?: number,
  $color?: string,
  $weight?: number,
  $isItalic?: boolean,
  $lineHeight?: number,
  $align?: CSSProperties["textAlign"],
  $display?:CSSProperties["display"]
}

export const StyledWrap = styled.div<{pageTop:number, pageLeft:number}>`
	font-family: "Pretendard", sans-serif;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10%;
	padding: 31px 31px 0 31px;
	background-color: ${Colors.WHITE};
	position: relative;
	letter-spacing: -0.01em;
	.MuiSelect-select{
		padding-top: 0;
		padding-bottom: 0;
	}
	@media print {
		aspect-ratio: 1.42/1;
		page-break-after: avoid;
		padding: ${props=>props.pageTop}px 31px 0 ${props=>props.pageLeft}px;
		.not-printing{
			display: none;
		}
		.is-design{
			opacity: ${HAS_DESIGN_PRINT ? 0 : 1};
		}
	}
	dt, dd, p, h1, h2, h3, h4, h5, h6, p, blockquote {
		padding: 0;
		margin: 0;
	}
`
export const StyledSection = styled.div`
	width: 100%;
	box-sizing: border-box;
`
export const StyledBox = styled.div<BoxType & FlexBoxType>`
	position: relative;
	box-sizing: border-box;
	display: ${props => props.$display || 'flex'};
	flex-direction: ${props => props.$flexDirection || "row"};
	justify-content: ${props => props.$justifyContent || "flex-start"};
	align-items: ${props => props.$alignItems || "flex-start"};
	gap: ${props => props.$gap ? getScreenEm(props.$gap)+"rem" : 0};
	width: ${props => props.$width ?
			typeof props.$width === 'string' ? props.$width
					:  getScreenEm(props.$width)+"rem"
			: "auto"
	};
	height: ${props => props.$height ? getScreenEm(props.$height)+"rem" : "auto"};
	padding-bottom: ${props => props.$paddingBottom ? getScreenEm(props.$paddingBottom)+"rem" : 0};
	padding-top: ${props => props.$paddingTop ? getScreenEm(props.$paddingTop)+"rem" : 0};
	padding-left: ${props => props.$paddingLeft ? getScreenEm(props.$paddingLeft)+"rem" : 0};
	padding-right: ${props => props.$paddingRight ? getScreenEm(props.$paddingRight)+"rem" : 0};
	margin-bottom: ${props => props.$marginBottom ? getScreenEm(props.$marginBottom)+"rem" : 0};
	margin-top: ${props => props.$marginTop ? getScreenEm(props.$marginTop)+"rem" : 0};
	margin-left: ${props => props.$marginLeft ? getScreenEm(props.$marginLeft)+"rem" : 0};
	margin-right: ${props => props.$marginRight ? getScreenEm(props.$marginRight)+"rem" : 0};
	flex-shrink: ${props => props.$flexShrink ?? 1};
	background-color: ${props => props.$isBg ? "#f8f8f8" : "transparent"};
	@media print {
		gap: ${props => props.$gap ? getPrintEm(props.$gap)+"rem" : 0};
		width: ${props => props.$width ?
				typeof props.$width === 'string' ? props.$width
						:  getPrintEm(props.$width)+"rem"
				: "auto"
		};
		height: ${props => props.$height ? getPrintEm(props.$height)+"rem" : "auto"};
		background-color: ${props => props.$isBg && !HAS_DESIGN_PRINT ? "#f8f8f8" : "transparent"};
		
		padding-bottom: ${props => getPaddingOrMargin(props, 'Bottom', 'padding')};
		padding-top: ${props => getPaddingOrMargin(props, 'Top', 'padding')};
		padding-left: ${props => getPaddingOrMargin(props, 'Left', 'padding')};
		padding-right: ${props => getPaddingOrMargin(props, 'Right', 'padding')};
		margin-bottom: ${props => getPaddingOrMargin(props, 'Bottom', 'margin')};
		margin-top: ${props => getPaddingOrMargin(props, 'Top', 'margin')};
		margin-left: ${props => getPaddingOrMargin(props, 'Left', 'margin')};
		margin-right: ${props => getPaddingOrMargin(props, 'Right', 'margin')};
	}
`

export const StyledAbsoluteBox = styled(StyledBox)`
	position: absolute;
	background-color: #fff;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
`

export const StyledTxt = styled.span<TextType>`
	font-size: ${props => props.$fontSize ? getScreenEm(props.$fontSize) : 1}rem;
	line-height: ${props =>
			props.$lineHeight ?
					props.$fontSize?  props.$lineHeight / props.$fontSize
							:  getScreenEm(props.$lineHeight)
					: 1
	};
	display: ${props => props.$display || "flex"};
	color: ${props => props.$color || Colors.GRAY_100};
	font-weight: ${props => props.$weight || 400};
	font-style: ${props => props.$isItalic ? "italic" : "normal"};
	text-align: ${props => props.$align || "left"};
	@media print {
		font-size: ${props => props.$fontSize ? getPrintEm(props.$fontSize) : 1}rem;
		line-height: ${props =>
				props.$lineHeight ?
						props.$fontSize?  props.$lineHeight / props.$fontSize
								:  getPrintEm(props.$lineHeight)
						: 1
		};
	}
`
export const StyledStix = styled(StyledTxt)`
	font-family: "STIXGeneral", "Noto Serif KR-DIAGNOSIS-PRINT", serif;
	
`

export const StyledInputText = (props:InputHTMLAttributes<HTMLInputElement> & TextType & BoxType) => {
	const {
		$fontSize,
		$lineHeight,
		$display,
		$color,
		$weight,
		$isItalic,
		$align,
		...restProps
	} = props;
	return (
		<input type="text" css={css`
			font-size: ${props.$fontSize ? getScreenEm(props.$fontSize) : 1}rem;
			line-height: ${
				props.$lineHeight ?
					props.$fontSize ? props.$lineHeight / props.$fontSize
						: getScreenEm(props.$lineHeight)
					: 1
			};
			display: ${props.$display || "flex"};
			color: ${props.$color || Colors.GRAY_100};
			font-weight: ${props.$weight || 400};
			font-style: ${props.$isItalic ? "italic" : "normal"};
			text-align: ${props.$align || "left"};
			background-color: transparent;
			border: none;
			width: 100%;
			font-family: "STIXGeneral", "Noto Serif KR-DIAGNOSIS-PRINT", serif;
			&:focus {
				outline: none;
			}

			@media print {
				font-size: ${props.$fontSize ? getPrintEm(props.$fontSize) : 1}rem;
				line-height: ${
					props.$lineHeight ? props.$fontSize ? props.$lineHeight / props.$fontSize
							: getPrintEm(props.$lineHeight)
						: 1
				};
			}
		`} {...restProps} />
	)
}
