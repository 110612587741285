import { deleteMdPickList, getMdPickList } from "apis/mdPickAPI"
import { useCallback, useEffect, useState } from "react"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"

const FETCH_SIZE = 20

export const useMdPickData = () => {
  const [selectedIdList, setSelectedIdList] = useState<number[]>([])

  const [mdPickList, setMdPickList] = useState<any>([])
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 0,
    size: FETCH_SIZE,
  })
  const [filterPostingStatus, setFilterPostingStatus] = useState<string | null>(null)

  const fetchMdPickList = useCallback(async (pageNumber: number) => {
    getMdPickList({
      page: pageNumber,
      size: FETCH_SIZE,
      postingStatus: filterPostingStatus
    }).then(({ content, number = 0, totalElements = 0, totalPages = 0 }) => {
      setMdPickList(content)
      setPaginationParams((prev) => ({
        ...prev,
        number: number,
        totalElements: totalElements,
        totalPages: totalPages,
      }))
    })
  }, [filterPostingStatus])

  const refresh = useCallback(() => {
    fetchMdPickList(0)
  }, [fetchMdPickList])

  const onChangePostingStatus = (postingStatus:string | null) => {
    setFilterPostingStatus(postingStatus)
  }

  const onChangePage = useCallback(
    (number: number) => {
      fetchMdPickList(number)
    },
    [fetchMdPickList]
  )

  const onSelectMdPick = useCallback((ids: number[]) => {
    setSelectedIdList(ids)
  }, [])

  const onDeleteSelectedMdPick = useCallback(async () => {
    await deleteMdPickList(selectedIdList.join(","))
    refresh();
  }, [selectedIdList])


  useEffect(() => {
    fetchMdPickList(0);
  }, [filterPostingStatus, fetchMdPickList]);

  useMount(refresh)

  return {
    mdPickList,
    selectedIdList,
    paginationParams,
    filterPostingStatus,
    fetchMdPickList,
    refresh,
    onChangePostingStatus,
    onChangePage,
    onSelectMdPick,
    onDeleteSelectedMdPick,
  }
}
