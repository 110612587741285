import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {DateTime} from "luxon";
import {Switch} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ShowcaseType} from "types/ShowcaseType";
import AddShowcasePopup from "../../../components/Popup/AddShowcasePopup";
import EditShowcasePopup from "../../../components/Popup/EditShowcasePopup";
import {put} from "../../../utils/objectUtils";
import {deleteShowcase, hideShowcase, showShowcase} from "../../../apis/showcaseAPI";

function createColumns(openEditShowcasePopup?: Function|null,
                       handleDeleteButtonClick?: Function|null,
                       handleShowButtonClick?: Function|null,
                       handleHideButtonClick?: Function|null,
                       navigateDisplayProductManagementPage?: Function): any[] {
    return [
        {
            field: 'actions',
            type: 'actions',
            width: 30,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon = {<EditIcon />}
                    label = {"노출 상세 모델 관리"}
                    onClick={() => navigateDisplayProductManagementPage?.(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon = {<EditIcon />}
                    label = {"수정"}
                    onClick={() => openEditShowcasePopup?.(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon = {<DeleteIcon />}
                    label = {"삭제"}
                    onClick={() => handleDeleteButtonClick?.(params.row.id)}
                    showInMenu
                />
            ]
        },
        {
            field: 'onDisplay',
            headerName: "노출상태",
            editable: false,
            width: 70,
            renderCell: (params: any) => {
                return <Switch
                    checked={params.row.onDisplay}
                    onChange={(_event, checked) => {
                        if (checked) handleShowButtonClick?.(params.id)
                        else handleHideButtonClick?.(params.id)
                    }}
                />
            }
        },
        {
            field: 'showroomName',
            headerName: "쇼룸명",
            editable: false,
            minWidth: 300,
            valueGetter: (params: any) => `${params.row.showroom.name}`,
            flex: 0.5
        },
        {
            field: 'name',
            headerName: "쇼케이스명",
            editable: false,
            minWidth: 300,
            flex: 0.5
        },
        {
            field: 'description',
            headerName: "설명",
            editable: false,
            minWidth: 300,
            flex: 0.5
        },
        {
            field: 'detailModelCount',
            headerName: "전시 상세 모델수",
            editable: false,
            type: "number",
            width: 115,
        },
        {
            field: 'createdBy',
            headerName: "생성자",
            editable: false,
            valueGetter: (params: any) => `${params.row.createdBy.name}`,
            width: 100,
        },
        {
            field: 'createdAt',
            headerName: "생성일",
            editable: false,
            width: 100,
            valueFormatter: (params: any) => `${DateTime.fromISO(params.value).toISODate()}`
        },
        {
            field: 'updatedBy',
            headerName: "수정자",
            editable: false,
            valueGetter: (params: any) => `${params.row.updatedBy.name}`,
            width: 100
        },
        {
            field: 'updatedAt',
            headerName: "수정일",
            editable: false,
            width: 100,
            valueFormatter: (params: any) => `${DateTime.fromISO(params.value).toISODate()}`
        }
    ]
}


type ShowroomTabTableProps = {
    showcases: ShowcaseType[],
    selectionShowcaseIds: number[],
    handleSelection?: Function,
    handleShowcaseChanged?: Function,
}

type ShowcaseTableState = {
    selectionShowcaseIds: number[],
    openAddShowcasePopup: boolean,
    openEditShowcasePopup: boolean,
    editShowcase: ShowcaseType | null
}
const initState: ShowcaseTableState = {
    selectionShowcaseIds: [],
    openAddShowcasePopup: false,
    openEditShowcasePopup: false,
    editShowcase: null
}

const ShowcaseTable: React.FC<ShowroomTabTableProps> = ({showcases, selectionShowcaseIds, handleSelection,handleShowcaseChanged}) => {
    const [state, setState] = useState({...initState, selectionShowcaseIds: selectionShowcaseIds})

    const _handleSelection = (ids: any[]) => {
        setState({...state, selectionShowcaseIds: ids})
        handleSelection?.(ids as number[])
    }

    const navigate = useNavigate()
    const navigateDisplayProductManagementPage = (showcase: ShowcaseType) => {
        navigate(`/showroom-manager/display-detail-models?showcaseId=${showcase.id}`)
    }

    const openEditShowcasePopup = (showcase: ShowcaseType) => {
        setState({...state, openEditShowcasePopup: true, editShowcase: showcase})
    }

    const handleDeleteButtonClick = (id: number) => {
        deleteShowcase(id).then(() => handleShowcaseChanged?.())
    }

    const handleShowButtonClick = (id: number) => {
        showShowcase(id).then(() => {handleShowcaseChanged?.()})
    }

    const handleHideButtonClick = (id: number) => {
        hideShowcase(id).then(() => handleShowcaseChanged?.())
    }

    return (
        <div style={{height: 500, width: '100%'}}>
        <AddShowcasePopup open={state.openAddShowcasePopup} handleClose={() => {
            setState(put(state, 'openAddShowcasePopup', false))
            handleShowcaseChanged?.()
        }}/>
        <EditShowcasePopup open={state.openEditShowcasePopup} showcase={state.editShowcase} handleClose={() => {
            setState(put(state, 'openEditShowcasePopup', false))
            handleShowcaseChanged?.()
        }} />

        <DataGrid
            columns={createColumns(
                openEditShowcasePopup,
                handleDeleteButtonClick,
                handleShowButtonClick,
                handleHideButtonClick,
                navigateDisplayProductManagementPage)}
            rows={showcases}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick={true}
            selectionModel={state.selectionShowcaseIds}
            onSelectionModelChange={_handleSelection}
        />
        </div>
    )
}

export default ShowcaseTable