import styled from "@emotion/styled"
import { refundPenalty } from "apis/penaltyAPI"
import Button from "components/Button"
import Modal from "components/Modal"
import { useState } from "react"
import { toast } from "react-toastify"

type Props = {
  isOpen: boolean
  closeModal: () => void
  orderId: string
  refetch: () => void
}

const PenaltyRefundModal = ({ isOpen, closeModal, orderId, refetch }: Props) => {
  const [refundAmount, setRefundAmount] = useState<number>(0)
  const [refundReason, setRefundReason] = useState<string>("")

  const requestRefund = () => {
    refundPenalty(orderId, { refundAmount, refundReason })
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((e) => toast.error(e.message))
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <h3>패널티 조정</h3>
        <FieldWrapper>
          <h4>환불 금액</h4>
          <InlineWrapper>
            <Input
              value={refundAmount}
              onChange={(e) => {
                const inputValue = e.target.value

                if (isNaN(Number(inputValue))) {
                  return
                }

                setRefundAmount(Number(inputValue))
              }}
            />
            원
          </InlineWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <h4>환불 이유</h4>
          <TextArea value={refundReason} onChange={(e) => setRefundReason(e.target.value)} />
        </FieldWrapper>

        <ButtonList>
          <ButtonWrapper>
            <Button buttonStyle="blue-border" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={requestRefund} disabled={refundAmount < 1 || !refundReason}>
              조정하기
            </Button>
          </ButtonWrapper>
        </ButtonList>
      </ModalContent>
    </Modal>
  )
}

export default PenaltyRefundModal

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 400px;

  h3 {
    margin: 0 0 16px 0;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  h4 {
    margin: 0 0 10px 0;
  }
`

const ButtonList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

const ButtonWrapper = styled.div`
  width: 100px;
  :not(:last-child) {
    margin-right: 10px;
  }
`

const Input = styled.input`
  width: 90%;
  outline: none;
  height: 40px;
  padding: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  margin-right: 10px;
`

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  width: 100%;
  height: 50px;
  outline: none;
`

const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`
