import styled from "@emotion/styled"
import { Dialog, DialogTitle, DialogContent, Stack, Box, DialogActions, Button } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid"
import { ProductStatusCode, ProductType } from "types/ProductType"
import { useEffect, useMemo, useState } from "react"
import { getProducts } from "apis/productAPI"
import { isEqual } from "lodash"
import ProductSelectPopup from "./ProductSelectPopup"
import { ArrowUpward, ArrowDownward, DeleteForever } from "@mui/icons-material"

export const ProductListManagementPopup: React.FunctionComponent<{
  open: boolean
  productIds: ProductType["id"][]
  setProducts: (product: ProductType[]) => void
  close: () => void
}> = ({ open, productIds, setProducts, close }) => {
  const [openProductSelectPopup, setOpenProductSelectPopup] = useState(false)
  const [productRows, setProductRows] = useState<ProductType[]>([])
  const [isEdit, setIsEdit] = useState(false)

  const columns = useMemo<GridColumns<ProductType>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => `${params.row.id}`,
      },
      {
        field: "title",
        headerName: "상품명",
        flex: 3,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => `${params.row.title}`,
      },
      {
        field: "status",
        headerName: "상태",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => `${params.row.status}`,
      },
      {
        field: "price",
        headerName: "가격",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => `${params.row.price}`,
      },
      {
        field: "globalSale",
        headerName: "해외판메",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params: any): string => `${params.row.globalSale ? "Y" : "N"}`,
      },
      {
        field: "thumbnail",
        headerName: "썸네일",
        width: 85,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <img
            src={params.row.thumbnail}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="product thumbnail"
          />
        ),
      },
      {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
      {
        field: "actions",
        type: "actions",
        headerName: "순서(위치이동)",
        width: 150,
        sortable: false,
        disableColumnMenu: true,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<ArrowUpward />}
            label={"위로"}
            onClick={() => {
              if (!productRows.length) return

              const index = productRows.findIndex((product) => product && product.id === params.row.id)
              if (index === 0) return

              let nextProductRows = [
                ...productRows.slice(0, index - 1),
                productRows[index],
                productRows[index - 1],
                ...productRows.slice(index + 1),
              ]

              nextProductRows = nextProductRows.map(data => ({
                ...data,
                priority: null
              })) ;

              setProductRows(nextProductRows)
            }}
          />,
          <GridActionsCellItem
            icon={<ArrowDownward />}
            label={"아래로"}
            onClick={() => {
              if (!productRows.length) return

              const index = productRows.findIndex((product) => product && product.id === params.row.id)
              if (index === productRows.length - 1) return

              let nextProductRows = [
                ...productRows.slice(0, index),
                productRows[index + 1],
                productRows[index],
                ...productRows.slice(index + 2),
              ]

              nextProductRows = nextProductRows.map(data => ({
                  ...data,
                  priority: null
              })) ;

              setProductRows(nextProductRows)
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteForever />}
            label={"제거"}
            onClick={() => {
              setProductRows((productRows) => productRows.filter((productRow) => productRow.id !== params.row.id))
            }}
          />,
        ],
      },
    ],
    [productRows]
  )

  useEffect(() => {
    if (!open) return

    getProducts({ id: productIds, size: productIds.length }).then((response) => {
      const allProducts = response.content

      // 화면의 복잡성으로 이 화면에서는 정렬 숫자 값을 조회시 순서대로 보여준다.
      const productListSortByIdOrder = productIds.map((productId, index) => {
        const product = allProducts.find((product) => product.id === productId)!;
        return { ...product, priority: index * 10 + 10 };
      });

      setProductRows(productListSortByIdOrder)
    })
  }, [open, productIds])

  return (
    <>
      <Dialog open={open} maxWidth={"lg"} style={{ zIndex: 10000 }}>
        <TitleWrapper>
          <DialogTitle>상품목록 관리</DialogTitle>
          <Stack direction={"row"} justifyContent={"flex-end"} paddingRight={"20px"}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={() => {
                setOpenProductSelectPopup(true)
              }}
            >
              상품추가
            </Button>
          </Stack>
        </TitleWrapper>
        <DialogContent style={{ width: "900px" }}>
          <Stack direction={"column"} spacing={1}>
            <Box height={"500px"}>
              <DataGrid
                style={{ width: "100%", height: "100%" }}
                columns={columns}
                rows={productRows}
                getRowId={(productRow) => productRow.id}
                getRowHeight={() => 80}
                disableSelectionOnClick
                onCellEditCommit={(params:any) => {
                  const index = productRows.findIndex((data) => data && data.id === params.id)
                  if (index < 0) return

                  const updatedProducts = [...productRows];
                  updatedProducts[index] = {
                    ...updatedProducts[index],
                    priority: params.value
                  };
                  setProductRows(updatedProducts)
                  setIsEdit(true)
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            size={"small"}
            color={"primary"}
            disabled={isEdit ? false : isEqual(
              productRows.map((productRow) => productRow.id),
              productIds
              )}
            onClick={() => {
              // 화면의 복잡성으로 확인 시점에 정렬값으로 재 배치하여 순서대로 DB에 저장하게 한다.
              // 실제 입력한 정렬 숫자 값은 이 화면에서는 저장 되지 않고, 화면상에서 정렬 용도로만 활용한다.
              const preSortedRows = productRows.map(data => ({
                ...data,
                priority: data.priority ? data.priority +1 : 1
              })) ;

              const sortedRows = [...preSortedRows].sort((a, b) => {

                if (a.priority && b.priority) {
                  return a.priority - b.priority;
                } else {
                  return 0;
                }
              });
              setProducts(sortedRows)
              close()
            }}
          >
            확인
          </Button>
          <Button variant={"contained"} size={"small"} color={"error"} onClick={() => close()}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
      {openProductSelectPopup && (
        <ProductSelectPopup
          open={openProductSelectPopup}
          isSearchable
          filterStatus={[ProductStatusCode.판매중]}
          setProducts={(products) => {
            const productIds = productRows.map((product) => product.id)
            const deduplicatedProducts = products.filter((product) => !productIds.includes(product.id))
            const duplicatedProducts = products.filter((product) => productIds.includes(product.id))

            if (duplicatedProducts.length) {
              window.alert(
                `이미 등록된 상품 ${duplicatedProducts.length}개를 제외하고 ` +
                  `${deduplicatedProducts.length}개의 상품을 추가합니다.` +
                  `\n추가상품ID: ${deduplicatedProducts.map((product) => product.id).join(", ") || "-"}` +
                  `\n제외상품ID: ${duplicatedProducts.map((product) => product.id).join(", ")}`
              )
            }

            const applySort_DeduplicatedProducts = deduplicatedProducts.map((data,index) => ({
              ...data,
              priority:  ( productRows.length + 1 + index) * 10
            })) ;

            setProductRows((productRows) => [...productRows, ...applySort_DeduplicatedProducts])
          }}
          close={() => {
            setOpenProductSelectPopup(false)
          }}
        />
      )}
    </>
  )
}

const TitleWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
