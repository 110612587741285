import SwipeBannerTable from "./SwipeBannerTable"
import SwipeBannerToolbar from "./SwipeBannerToolbar"
import { Stack } from "@mui/material"
import { useState } from "react"
import DeleteConfirmPopup from "components/Popup/DeleteConfirmPopup"
import ShopBannerAPI from "apis/shopBannerAPI"
import SwipeBannerEditorPopup from "./SwipeBannerEditorPopup"
import { isNil } from "utils/validationUtils"

const SwipeBannerTab = () => {
  const [showCreate, setShowCreate] = useState(false)
  const [editableId, setEditableId] = useState<number | null>(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectionIds, setSelectionIds] = useState<number[]>([])
  const [refreshCounter, setRefreshCounter] = useState(0)

  const refresh = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Stack direction={"column"} spacing={1}>
      <SwipeBannerToolbar
        handleCreate={() => setShowCreate(true)}
        handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
        disabledDeleteButton={!selectionIds.length}
      />
      <SwipeBannerTable
        handleSelection={(ids: number[]) => {
          setSelectionIds(ids)
        }}
        handleUpdateId={(id) => {
          setEditableId(id)
          setShowCreate(true)
        }}
        refreshCounter={refreshCounter}
      />
      {showCreate && (
        <SwipeBannerEditorPopup
          id={editableId}
          handleClose={() => {
            refresh()
            setShowCreate(false)
            setEditableId(null)
          }}
          editorType={isNil(editableId) ? "CREATE" : "EDIT"}
        />
      )}
      <DeleteConfirmPopup
        open={showDeletePopup}
        handleClose={() => {
          refresh()
          setShowDeletePopup(false)
        }}
        handleConfirm={() => {
          Promise.all(selectionIds.map((id) => ShopBannerAPI.delete(id))).then((results) => {
            refresh()
            setShowDeletePopup(false)
          })
        }}
      />
    </Stack>
  )
}

export default SwipeBannerTab
