import styled from "@emotion/styled"
import { MenuItem, Select } from "@mui/material"
import { DateTime } from "luxon"
import { TermType } from "types/TermType"
import { toDateStr } from "utils/datetimeUtil"

type Props = {
  termList: TermType[]
  selectedTerm?: TermType
  selectTerm: (term: TermType | string) => void
}

const AddIconSrc = require("images/16_plus_circle.png")

export const TermListSelect = ({ termList, selectedTerm, selectTerm }: Props) => {
  const getLabelByDate = (term: TermType): "만료" | "예정" | "시행중" => {
    if (
      term.endDate &&
      DateTime.fromISO(term.endDate).startOf("day").toMillis() < DateTime.now().startOf("day").toMillis()
    )
      return "만료"

    if (DateTime.fromISO(term.startDate).startOf("day").toMillis() > DateTime.now().startOf("day").toMillis())
      return "예정"

    return "시행중"
  }

  const memuList = termList.map((menu) => (
    <MenuItem value={menu.id} key={menu.id}>
      <Text>
        {toDateStr(menu.startDate)} {`(${getLabelByDate(menu)})`}
      </Text>
    </MenuItem>
  ))

  return (
    <Select
      size={"small"}
      style={{
        width: "210px",
        height: "36px",
        background: "#fff",
      }}
      value={selectedTerm?.id || "새로운 약관"}
      onChange={(e) => {
        const termId = e.target.value

        if (typeof termId === "string") {
          selectTerm(termId)
        } else {
          const selectedTerm = termList.find((term) => term.id === termId)
          if (selectedTerm) selectTerm(selectedTerm)
        }
      }}
    >
      {selectedTerm
        ? memuList.concat(
            <MenuItem value={"new_term"} key={"new_menu"}>
              <TextIconWrapper>
                <Text>약관수정 추가</Text>
                <Icon src={AddIconSrc} />
              </TextIconWrapper>
            </MenuItem>
          )
        : memuList}
    </Select>
  )
}

const Text = styled.div``

const Icon = styled.img`
  width: 16px;
  height: 16px;
`

const TextIconWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`
