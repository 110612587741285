import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { deleteProductDetailText, postProductDetailText, putProductDetailText } from "apis/productNotice"
import { isNil } from "utils/validationUtils"
import { ProductDetailTextListItemType } from "types/ProductDetailTextListItemType"

export const useProductDetailTextFormData = (listItem: ProductDetailTextListItemType | undefined) => {
  const [id, setId] = useState<number>(0)
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false)
  const [showDomesticUser, setShowDomesticUser] = useState<boolean>(true)
  const [showForeignUser, setShowForeignUser] = useState<boolean>(false)
  const [text, setText] = useState<string>("")
  const [startDate, setStartDate] = useState<DateTime | null>(null)
  const [endDate, setEndDate] = useState<DateTime | null>(null)
  const [linkUrl, setLinkUrl] = useState<string>("")

  const validateForm = useCallback(() => {
    if (isEmpty(text)) {
      toast.error("프로모션 텍스트를 입력해주세요.")
      return false
    }

    if (isNil(startDate)) {
      toast.error("노출 시작일을 지정해주세요.")
      return false
    }

    return true
  }, [text, startDate])

  const initializeForm = useCallback(() => {
    setId(0)
    setIsDisplayed(false)
    setShowDomesticUser(true)
    setShowForeignUser(false)
    setText("")
    setStartDate(null)
    setEndDate(null)
    setLinkUrl("")
  }, [])

  const resgisterProductDetailText = useCallback(async () => {
    await postProductDetailText({
      title: text,
      linkUrl,
      onDisplay: isDisplayed,
      startedAt: startDate!.toISO(),
      endedAt: endDate?.toISO() || null,
      showDomesticUser: showDomesticUser,
      showForeignUser: showForeignUser
    })
    initializeForm()
  }, [text, startDate, endDate, linkUrl, isDisplayed, initializeForm, showDomesticUser, showForeignUser])

  const editProductDetailText = useCallback(async () => {
    if (isNil(listItem)) return

    await putProductDetailText(listItem.id, {
      title: text,
      linkUrl,
      onDisplay: isDisplayed,
      startedAt: startDate!.toISO(),
      endedAt: endDate?.toISO() || null,
      showDomesticUser: showDomesticUser,
      showForeignUser: showForeignUser
    })
  }, [text, startDate, endDate, linkUrl, isDisplayed, listItem, showDomesticUser, showForeignUser])

  const removeProductDetailText = useCallback(async (id: number) => {
    if (!window.confirm("삭제하시겠습니까?")) return

    await deleteProductDetailText(id)
  }, [])

  const toggleProductDetailText = useCallback(
    async (id: number, status: boolean) => {
      if (isNil(listItem)) return

      setIsDisplayed(status)

      await putProductDetailText(id, {
        title: listItem.title,
        linkUrl: listItem.linkUrl,
        onDisplay: status,
        startedAt: listItem.startedAt,
        endedAt: listItem.endedAt || null,
        showDomesticUser: showDomesticUser,
        showForeignUser: showForeignUser
      })
    },
    [listItem]
  )

  useEffect(() => {
    if (isNil(listItem)) return

    setId(listItem.id)
    setIsDisplayed(listItem.onDisplay)
    setShowDomesticUser(listItem.showDomesticUser)
    setShowForeignUser(listItem.showForeignUser)
    setIsDisplayed(listItem.onDisplay)
    setText(listItem.title)
    setLinkUrl(listItem.linkUrl)
    setStartDate(DateTime.fromISO(listItem.startedAt))
    setEndDate(isNil(listItem.endedAt) ? null : DateTime.fromISO(listItem.endedAt))
  }, [listItem])

  return {
    id,
    isDisplayed,
    setIsDisplayed,
    text,
    setText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    linkUrl,
    setLinkUrl,
    resgisterProductDetailText,
    editProductDetailText,
    removeProductDetailText,
    toggleProductDetailText,
    validateForm,
    showDomesticUser,
    setShowDomesticUser,
    showForeignUser,
    setShowForeignUser
  }
}
