import MdPickTable from "./MdPickTable"
import MdPickToolBar from "./MdPickToolBar"
import { Stack } from "@mui/material"
import { useState } from "react"

import DeleteConfirmPopup from "components/Popup/DeleteConfirmPopup"

import { useMdPickData } from "./useMdPickData"
import MdPickEditorPopup from "./MdPickEditorPopup/MdPickEditorPopup"

const MdPickTab = () => {
  const [showCreateCuration, setShowCreateCuration] = useState(false)
  const [editableCurationId, setEditableCurationId] = useState<number | null>(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const {
    mdPickList,
    paginationParams,
    filterPostingStatus,
    selectedIdList,
    onChangePostingStatus,
    onChangePage,
    refresh,
    onSelectMdPick,
    onDeleteSelectedMdPick,
  } = useMdPickData()

  return (
    <Stack direction={"column"} spacing={1}>
      <MdPickToolBar
        handleCreateCuration={() => setShowCreateCuration(true)}
        handleDeleteButton={() => setShowDeletePopup(selectedIdList.length > 0)}
        filterPostingStatus={filterPostingStatus}
        handleChangePostingStatus={(postingStatus:string|null) => {onChangePostingStatus(postingStatus)}}
        disabledDeleteButton={!selectedIdList.length}
      />
      <MdPickTable
        list={mdPickList}
        paginationParams={paginationParams}
        handleSelection={(ids: number[]) => {
          onSelectMdPick(ids)
        }}
        handleUpdateCurationId={(curationId) => {
          setEditableCurationId(curationId)
          setShowCreateCuration(true)
        }}
        handleChangePage={onChangePage}
      />
      {showCreateCuration && (
        <MdPickEditorPopup
          curationId={editableCurationId}
          handleClose={() => {
            refresh()
            setShowCreateCuration(false)
            setEditableCurationId(null)
          }}
        />
      )}

      <DeleteConfirmPopup
        open={showDeletePopup}
        handleClose={() => {
          refresh()
          setShowDeletePopup(false)
        }}
        handleConfirm={async () => {
          await onDeleteSelectedMdPick()
          refresh()
          setShowDeletePopup(false)
        }}
      />
    </Stack>
  )
}
// 해외 판매 동의 여부 시작
export default MdPickTab
