import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Tab, TextField } from "@mui/material"

import { useReducer, useState } from "react"
import { DesktopDatePicker, TabContext, TabList, TabPanel } from "@mui/lab"
import BuyOrderReviewTab from "./BuyOrderReviewTab"
import SellOrderReviewTab from "./SellOrderReviewTab"
import { PageType } from "../../types/PageType"
import { SellOrderType } from "../../types/SellOrderType"
import { CountType } from "../../types/CountType"
import { DateTime } from "luxon"
import { useSearchParams } from "react-router-dom"
import { SellOrderState } from "../SellOrder/BeforeDiagnosis"
import { ShippingStatusCode } from "../../types/ShippingType"

export interface ReviewState {
  selectedIds: string[]
  sellOrders: PageType<SellOrderType>
  sellOrderCounts: CountType[]
  shippingStatusCode: ShippingStatusCode[]
  isConfirmPopup: boolean
  isShippingRequestPopupOpen: boolean
  isConfirmReceivingPopupOpen: boolean
  editSellOrder?: SellOrderType
  startDateFilter: DateTime | null
  endDateFilter: DateTime | null
  searchFilterKey: string | null
  searchFilterValue: string | null
  refresh: boolean
  loading: boolean
}

const initState: (orderId?: string | null) => SellOrderState = (orderId) => {
  let state: SellOrderState = {
    selectedIds: [],
    sellOrders: {
      content: [],
      pageable: {
        pageSize: 25,
        pageNumber: 0,
      },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    sellOrderCounts: [],
    shippingStatusCode: [
      ShippingStatusCode.배송_준비,
      ShippingStatusCode.배송중,
      ShippingStatusCode.배송_완료,
      ShippingStatusCode.상품_발송,
    ],
    isConfirmPopup: false,
    isShippingRequestPopupOpen: false,
    isConfirmReceivingPopupOpen: false,
    editSellOrder: undefined,
    startDateFilter: null,
    endDateFilter: null,
    searchFilterKey: "fullRefNo",
    searchFilterValue: null,
    refresh: false,
    loading: false,
  }

  if (orderId) {
    state.searchFilterKey = "id"
    state.searchFilterValue = orderId
  }

  return state
}

interface ReviewAction {
  type: string
  payload?: any
}

const Review = () => {
  const [tab, setTab] = useState<string>("buy-order-review")
  const [currentSearchParams] = useSearchParams()

  function handleChangeTab(newTab: string) {
    if (tab !== newTab) {
      setTab(newTab)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack direction={"column"} spacing={2} justifyContent={"center"}>
              <TabContext value={tab}>
                <TabList onChange={(_e, value) => handleChangeTab(value)}>
                  <Tab value={"buy-order-review"} label={"구매 후기 관리"} />
                  <Tab value={"sell-order-review"} label={"판매 후기 관리"} />
                </TabList>
                <TabPanel value={"buy-order-review"}>
                  <BuyOrderReviewTab />
                </TabPanel>
                <TabPanel value={"sell-order-review"}>
                  <SellOrderReviewTab />
                </TabPanel>
              </TabContext>
            </Stack>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Review
