import { useCallback, useEffect, useState } from "react"
import { ModelListItemType } from "types/ModelListItemType"
import { ModelType } from "types/ModelType"
import { createModel, updateModel } from "apis/modelAPI"
import { isNil } from "utils/validationUtils"

type ModelStateType = Omit<ModelType, "id"> & { id?: number }

export const useModelUpdateData = (model: ModelListItemType | null, brandSid: string) => {
  const [modelState, setModelState] = useState<ModelStateType>({
    brand: { sid: brandSid },
    category: { sid: "WATCH" },
    name: "",
    thumbnail: null,
    thumbnailBackground: null,
    thumbnailBackgroundSquare: null,
    priority: 9999,
    onDisplay: false,
  })

  useEffect(() => {
    if (isNil(model)) return

    setModelState({
      id: model.id,
      brand: { sid: brandSid },
      category: { sid: "WATCH" },
      name: model.name,
      thumbnail: model.thumbnail,
      thumbnailBackground: model.thumbnailBackground,
      thumbnailBackgroundSquare: model.thumbnailBackgroundSquare,
      priority: model.priority,
      onDisplay: model.onDisplay,
    })
  }, [model, brandSid])

  const changeState = useCallback((key: string, value: any) => {
    setModelState((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleSaveModel = useCallback(async () => {
    if (!modelState.id) {
      return createModel(modelState)
    } else {
      return updateModel(modelState.id, modelState)
    }
  }, [modelState])

  return { modelState, changeState, handleSaveModel }
}
