import { httpDelete, httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { PageType } from "../types/PageType"
import { NewsLetterType } from "../types/NewsLetterType"

export const getNewsLetters: (searchParams?:{page:number, size:number}) => Promise<PageType<NewsLetterType>> = (searchParams) => httpGet(`/newsletters`, searchParams)

export const createNewsLetter = (title:string, url:string, publishDate:string) =>{
  return httpPost('/newsletters', {
    title,
    url,
    publishDate
  })
}

export const deleteNewsLetters = (ids:string[])=>{

  return httpDelete('/newsletters', {
    ids:ids.join(',')
  })
}

export const updateNewsLetter = (id:number, title:string, url:string, publishDate:string, onDisplay:boolean) =>{
  return httpPut(`/newsletters/${id}`, {
    title,
    url,
    publishDate,
    onDisplay
  })
}
