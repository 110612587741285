import { Button, FormControlLabel, Grid, Paper, Switch, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { AppVersion } from "types/AppVersionType"
import { getAppVersions, updateAppVersion } from "../../apis/appAPI"
import { toast } from "react-toastify"

const AppManager = () => {
  const [appVersions, setAppVersions] = useState<AppVersion[]>([])
  const [edit, setEdit] = useState<boolean[]>([true, true])

  useEffect(() => {
    getAppVersions().then((res) => setAppVersions(res))
  }, [])

  return (
    <Grid container spacing={2} direction={"row"}>
      {appVersions.map((it, idx) => (
        <Grid item xs={6}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <h1>{it.platform}</h1>
            <TextField
              fullWidth
              style={{ margin: "10px 0" }}
              disabled={edit[idx]}
              label={"Latest"}
              inputMode={"decimal"}
              value={it.latest}
              onChange={(e) => {
                let updated = [...appVersions]
                updated[idx]["latest"] = parseInt(e.target.value)
                setAppVersions(updated)
              }}
            />
            <TextField
              fullWidth
              style={{ margin: "10px 0" }}
              disabled={edit[idx]}
              label={"Minimum"}
              inputMode={"decimal"}
              value={it.minimum}
              onChange={(e) => {
                let updated = [...appVersions]
                updated[idx]["minimum"] = parseInt(e.target.value)
                setAppVersions(updated)
              }}
            />
            <TextField
              fullWidth
              style={{ margin: "10px 0" }}
              disabled={edit[idx]}
              label={"공지사항 타이틀"}
              value={it.title}
              onChange={(e) => {
                let updated = [...appVersions]
                updated[idx]["title"] = e.target.value
                setAppVersions(updated)
              }}
            />
            <TextField
              fullWidth
              style={{ margin: "10px 0" }}
              multiline={true}
              disabled={edit[idx]}
              label={"공지사항 컨텐츠"}
              value={it.content}
              onChange={(e) => {
                let updated = [...appVersions]
                updated[idx]["content"] = e.target.value
                setAppVersions(updated)
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={edit[idx]}
                    checked={it.isDisplay}
                    onChange={(_, check) => {
                      let updated = [...appVersions]
                      updated[idx]["isDisplay"] = check
                      setAppVersions(updated)
                    }}
                  />
                }
                label="노출 여부"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={edit[idx]}
                    checked={it.isShutdown}
                    onChange={(_, check) => {
                      let updated = [...appVersions]
                      updated[idx]["isShutdown"] = check
                      setAppVersions(updated)
                    }}
                  />
                }
                label="강제종료 여부"
              />
            </div>
            <Button
              onClick={(_) => {
                if (!edit[idx]) {
                  updateAppVersion(appVersions[idx].platform, appVersions[idx])
                    .then((_) => {
                      toast.success("저장 완료")
                      setEdit((edit) => {
                        return { ...edit, [idx]: !edit[idx] }
                      })
                    })
                    .catch((error) => {
                      toast.error(`저장 실패 (${error.message || error})`)
                    })
                } else {
                  setEdit((edit) => {
                    return { ...edit, [idx]: !edit[idx] }
                  })
                }
              }}
            >
              {edit[idx] ? "수정" : "저장"}
            </Button>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default AppManager
