import {Grid, Paper, Stack, Tab} from "@mui/material";
import {useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import BuyPriceOfferGroupByProductTab from "./BuyPriceOfferGroupByProductTab";
import BuyPriceOfferTab from "./BuyPriceOfferTab";
import BuyPriceOfferDetailPopup from "./BuyPriceOfferDetailPopup";

const BuyPriceOfferManager = () => {
    const [tab, setTab] = useState<string>("buyPriceOffer")

    function handleChangeTab(newTab: string) {
        if (tab !== newTab) {
            setTab(newTab)
        }
    }
    return (
        <Paper sx={{p: 2}}>
            <Stack direction={"column"} spacing={2} justifyContent={"center"}>
                <TabContext value={tab}>
                    <TabList onChange={(_e, value) => handleChangeTab(value)}>
                        <Tab value={"buyPriceOffer"} label={"구매 가격 제안"} />
                        <Tab value={"buyPriceOfferGroupByProduct"} label={"상품별 구매 가격 제안"} />
                    </TabList>
                    <TabPanel value={"buyPriceOffer"}><BuyPriceOfferTab /></TabPanel>
                    <TabPanel value={"buyPriceOfferGroupByProduct"}><BuyPriceOfferGroupByProductTab /></TabPanel>
                </TabContext>
            </Stack>
        </Paper>
    )
}

export default BuyPriceOfferManager