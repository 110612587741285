import styled from "@emotion/styled"
import { Autocomplete, TextField } from "@mui/material"
import { SelectedModelType } from "../useSerialNumberRegister"
import { useModelSearchInput } from "./useModelSearchInput"

type Props = {
  onSelectModel: (selectedModel: SelectedModelType | null) => void
}

export const ModelSearchInput = ({ onSelectModel }: Props) => {
  const { modelList, changeRefNo } = useModelSearchInput()

  return (
    <Wrapper>
      <Autocomplete
        disablePortal
        options={modelList}
        fullWidth
        renderInput={(params) => <TextField {...params} placeholder="레퍼런스 번호로 검색" />}
        onInputChange={(e, value) => changeRefNo(value)}
        onChange={(e, value) => onSelectModel(value)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;

  .css-eozd4h-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    height: 36px;
    display: flex;
    align-items: center;
    outline: none;

    input {
      padding: 0 15px;
    }
  }
`
