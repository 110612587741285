import { Button, Grid, InputLabel, Paper, TextField } from "@mui/material"
import { useEffect, useReducer } from "react"
import { PageType } from "types/PageType"
import { UserNotificationScheduleType } from "types/UserNotificationScheduleType"
import { useLocation } from "react-router-dom"
import { DateTime } from "luxon"
import { DesktopDatePicker } from "@mui/lab"
import UserNotificationScheduleTable from "./UserNotificationScheduleTable"
import EditUserNotificationSchedulePopup from "./EditUserNotificationSchedulePopup"
import {getSchedules} from "../../apis/notificationAPI";

export interface ScheduleState {
  selectedIds: string[]
  schedules: PageType<UserNotificationScheduleType>
  isShowViewPopup: boolean
  editSchedule?: UserNotificationScheduleType
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  searchFilterKey?: string
  searchFilterValue?: string
  refresh: boolean
  loading: boolean
}

const initState: ScheduleState = {
  selectedIds: [],
  schedules: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  isShowViewPopup: false,
  editSchedule: undefined,
  startDateFilter: DateTime.now().minus({ month: 1 }),
  endDateFilter: DateTime.now(),
  searchFilterKey: undefined,
  searchFilterValue: undefined,
  refresh: false,
  loading: false,
}

interface UserNotificationScheduleAction {
  type: string
  payload?: any
}

function reducer(state: ScheduleState, action: UserNotificationScheduleAction): ScheduleState {
  switch (action.type) {
    case "SELECTION_SCHEDULE_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_SCHEDULE":
      return { ...state, schedules: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SHOW_VIEW_SCHEDULE_POPUP":
      return { ...state, isShowViewPopup: true, editSchedule: action.payload }
    case "HIDE_VIEW_SCHEDULE_POPUP":
      return { ...state, isShowViewPopup: false }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const PushManager = () => {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initState)

  const fetchUserNotificationSchedules = (searchParams: any = {}) => {
    let newParams = {
      ...searchParams,
      status: new URLSearchParams(location.search).get("status"),
      startAt: state.startDateFilter && state.startDateFilter.toISODate(),
      endAt: state.endDateFilter && state.endDateFilter.toISODate(),
    }
    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getSchedules(newParams).then((schedule) => dispatch({ type: "FETCH_SCHEDULE", payload: schedule }))
  }

  const handleNewPushClick = (event: any) => {
    dispatch({ type: "SHOW_VIEW_SCHEDULE_POPUP", payload: undefined })
  }

  useEffect(() => {
    fetchUserNotificationSchedules()
  }, [state.refresh])

  return (
    <Grid container spacing={2}>
      <EditUserNotificationSchedulePopup
        open={state.isShowViewPopup}
        selectedScheduleId={state?.editSchedule?.id}
        handleClose={() => {
          dispatch({ type: "HIDE_VIEW_SCHEDULE_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
              <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>발송 일정</InputLabel>
              <DesktopDatePicker
                label="시작일"
                inputFormat="yyyy-MM-dd"
                value={state.startDateFilter}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_START_DATE", payload: params })
                  dispatch({ type: "REFRESH" })
                }}
                renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
              />
              <DesktopDatePicker
                label="종료일"
                inputFormat="yyyy-MM-dd"
                value={state.endDateFilter}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_END_DATE", payload: params })
                  dispatch({ type: "REFRESH" })
                }}
                renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
              />
          </Grid>
          <Grid item xs={12}>
            <UserNotificationScheduleTable
              state={state}
              dispatch={dispatch}
              fetch={fetchUserNotificationSchedules}
              components={[
                <Button
                  variant={"contained"}
                  color={"error"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleNewPushClick}
                >
                  푸시메시지 등록
                </Button>,
              ]}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PushManager
