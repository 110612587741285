import { Box, Button, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid"
import React, { useMemo, useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { toDateStr, toDateTimeStr, toTimeStr } from "utils/datetimeUtil"
import { MdPickType } from "types/MdPickType"
import { toast } from "react-toastify"
import { PaginationParamsType } from "types/PageType"

function renderStatusCell(onDisplay: boolean) {
  if (onDisplay) return <Button color={"error"}>노출중</Button>
  return <Button disabled>노출 종료</Button>
}

function renderPreviewCell(params: any) {
  return (<img src={params?.row?.imageUrl} style={{width: '100%', margin: '5px' }} />)
}

const MdPickTable: React.FunctionComponent<{
  list: MdPickType[]
  handleSelection: (ids: number[]) => void
  handleUpdateCurationId: (curationId: number) => void
  handleChangePage: (page: number) => void
  paginationParams: PaginationParamsType
}> = ({ handleSelection, handleUpdateCurationId, list, paginationParams, handleChangePage }) => {
  const columns = useMemo((): GridColumns<MdPickType> => {
    return [
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={"수정"}
            onClick={() => {
              handleUpdateCurationId(params.row.id)
            }}
            showInMenu
          />,
        ],
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "title", headerName: "제목", flex: 2 },
      { field: "curationType", headerName: "노출기준", width: 100 },
      { field: "minQuantity", headerName: "최소갯수", maxWidth: 90 },
      { field: "maxQuantity", headerName: "최대갯수", maxWidth: 90 },
      {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
      {
        field: "createdAt",
        headerName: "등록일시",
        maxWidth: 200,
        valueGetter: (params: any) => toDateTimeStr(params.row.createdAt),
        renderCell: (params: any) => (
          <div style={{ textAlign: "center" }}>
            <p>
              {toDateStr(params.row.createdAt, false)}
              <br />
              {toTimeStr(params.row.createdAt)}
            </p>
          </div>
        ),
      },

      {
        field: "postingStatus",
        headerName: "노출 상태",
        width: 100,
        renderCell: (params: any) => renderStatusCell(params.row.onDisplay),
      },
      {field: 'showDomesticUser',  width: 80,
        renderCell: (params: any) => (
          <div style={{textAlign: "center"}}>
            <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
          </div>
        ),
        renderHeader: () => (
          <div style={{  textAlign: "center", lineHeight: "1.2" }}>
            내국인 회원<br />노출
          </div>
        ),
      },
      { field: "showForeignUser", width: 80,
        renderCell: (params: any) => (
          <div
            style={{ textAlign: "center"  }}
          >
            <span>{params.row.showForeignUser ? "Y" : "N"}</span>
          </div>
        ),
        renderHeader: () => (
          <div style={{ textAlign: "center", lineHeight: "1.2" }}>
            외국인 회원<br />노출
          </div>
        ),
      },
      {
        field: "url",
        headerName: "URL 복사",
        width: 100,
        renderCell: (params: any) => (
          <Button
            onClick={async (e) => {
              e.stopPropagation()

              const url = `viver://md-pick/${params.row.id}`
              if ("clipboard" in navigator) await navigator.clipboard.writeText(url)
              else document.execCommand("copy", true, url)
              toast.success("복사되었습니다.")
            }}
          >
            URL 복사
          </Button>
        ),
      },
    ]
  }, [handleUpdateCurationId])

  return (
    <Stack spacing={2}>
      <Box sx={{ height: 720, width: "100%" }}>
        <DataGrid<MdPickType>
          columns={columns}
          rowHeight={70}
          getRowId={(row) => row.id}
          onSelectionModelChange={(ids: any) => {
            handleSelection(ids as number[])
          }}
          checkboxSelection
          pagination
          pageSize={paginationParams.size}
          paginationMode="server"
          page={paginationParams.number}
          onPageChange={handleChangePage}
          rows={list}
          rowCount={paginationParams.totalElements}
        />
      </Box>
    </Stack>
  )
}

export default MdPickTable
