import CurationTable from "./CurationTable"
import CurationToolbar from "./CurationToolbar"
import { Stack } from "@mui/material"
import { useState } from "react"
import BannerReorderPopup from "pages/ShopManager/ShopContentTab/ProductCurationTab/CurationReorderPopup"
import DeleteConfirmPopup from "components/Popup/DeleteConfirmPopup"
import ShopBannerAPI from "apis/shopBannerAPI"
import CurationEditorPopup from "pages/ShopManager/ShopContentTab/ProductCurationTab/CurationEditorPopup"
import { isNil } from "utils/validationUtils"

const ProductCurationTab = () => {
  const [showCreateCuration, setShowCreateCuration] = useState(false)
  const [editableCurationId, setEditableCurationId] = useState<number | null>(null)
  const [showReorderCuration, setShowReorderCuration] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectionIds, setSelectionIds] = useState<number[]>([])
  const [refreshCounter, setRefreshCounter] = useState(0)

  const refresh = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Stack direction={"column"} spacing={1}>
      <CurationToolbar
        handleCreateCuration={() => setShowCreateCuration(true)}
        handleReorderCuration={() => setShowReorderCuration(true)}
        handleDeleteButton={() => setShowDeletePopup(selectionIds.length > 0)}
        disabledDeleteButton={!selectionIds.length}
      />
      <CurationTable
        handleSelection={(ids: number[]) => {
          setSelectionIds(ids)
        }}
        handleUpdateCurationId={(curationId) => {
          setEditableCurationId(curationId)
          setShowCreateCuration(true)
        }}
        refreshCounter={refreshCounter}
      />
      {showCreateCuration && (
        <CurationEditorPopup
          curationId={editableCurationId}
          handleClose={() => {
            refresh()
            setShowCreateCuration(false)
            setEditableCurationId(null)
          }}
          editorType={isNil(editableCurationId) ? "CREATE" : "EDIT"}
        />
      )}
      <BannerReorderPopup
        open={showReorderCuration}
        handleClose={() => {
          refresh()
          setShowReorderCuration(false)
        }}
      />
      <DeleteConfirmPopup
        open={showDeletePopup}
        handleClose={() => {
          refresh()
          setShowDeletePopup(false)
        }}
        handleConfirm={() => {
          Promise.all(selectionIds.map((id) => ShopBannerAPI.delete(id))).then((results) => {
            refresh()
            setShowDeletePopup(false)
          })
        }}
      />
    </Stack>
  )
}

export default ProductCurationTab
