import React, { useCallback, useEffect, useState } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { PageType } from "../../types/PageType"
import { CountType } from "../../types/CountType"
import { ProvisionalPreSettlementSearchParams, ProvisionalPreSettlementType } from "../../types/PreSettlementType"
import { ProvisionalPreSettlementTable } from "./ProvisionalPreSettlementTable"
import { countProvisionalPreSettlements, getProvisionalPreSettlement } from "../../apis/provisionalPreSettlementAPI"
import { useSearchParams } from "react-router-dom"
import Counters from "../SellOrder/Counters"
import { EditProvisionalPreSettlement } from "./ProvisionalPreSettlementManageDialog"

export type ProvisionalPreSettlementManagerState = {
  preSettlementItems: PageType<ProvisionalPreSettlementType>
  settlementCount: CountType[]
  searchFilterKey: "sellerName" | "productId"
  searchFilterValue: string
  refresh: boolean
  loading: boolean
  isShowManagePopup: boolean
  selectedProvisionalPreSettlement: ProvisionalPreSettlementType | null
}

const initialState: ProvisionalPreSettlementManagerState = {
  isShowManagePopup: false,
  preSettlementItems: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  settlementCount: [],
  searchFilterKey: "productId",
  searchFilterValue: "",
  refresh: false,
  loading: false,
  selectedProvisionalPreSettlement: null,
}

export const ProvisionalPreSettlementManager: React.FC = () => {
  const [state, setState] = useState<ProvisionalPreSettlementManagerState>(initialState)
  const [currentSearchParams] = useSearchParams()
  const numericFields = ["productId"]

  const fetchProvisionalPreSettlementList = useCallback((searchParams: ProvisionalPreSettlementSearchParams) => {
    getProvisionalPreSettlement(searchParams).then((result) => {
      setState((prevState) => ({
        ...prevState,
        preSettlementItems: result,
      }))
    })
  }, [])

  const fetchProvisionalPreSettlementCount = () => {
    countProvisionalPreSettlements().then((result) => {
      const countWithLink = result.map((count) => ({
        ...count,
        link: `/provisional-pre-settlements?status=${count.label}`,
      }))

      const totalCount: CountType = {
        link: "/provisional-pre-settlements",
        label: "전체",
        count: countWithLink.reduce((prev, current) => {
          return (prev += current.count || 0)
        }, 0),
      }

      setState((prevState) => ({
        ...prevState,
        settlementCount: [totalCount, ...countWithLink],
      }))
    })
  }

  useEffect(() => {
    fetchProvisionalPreSettlementList({
      page: state.preSettlementItems.pageable?.pageNumber ?? 0,
      size: state.preSettlementItems.pageable?.pageSize ?? 25,
      status: currentSearchParams.get("status") ?? undefined,
    })
    fetchProvisionalPreSettlementCount()
  }, [state.preSettlementItems.pageable?.pageNumber, state.preSettlementItems.pageable?.pageSize, currentSearchParams])

  const onFilterSearch = useCallback(() => {
    fetchProvisionalPreSettlementList({
      page: 0,
      size: 25,
      [state.searchFilterKey]: state.searchFilterValue,
      status: currentSearchParams.get("status") ?? undefined,
    })
  }, [state.searchFilterKey, state.searchFilterValue, currentSearchParams, fetchProvisionalPreSettlementList])

  return (
    <Grid container spacing={2}>
      {state.selectedProvisionalPreSettlement !== null && (
        <EditProvisionalPreSettlement
          open={state.isShowManagePopup}
          selectedProvisionalPreSettlementId={state.selectedProvisionalPreSettlement.id}
          handleClose={() => {
            setState((prevState) => ({
              ...prevState,
              refresh: !prevState.refresh,
              isShowManagePopup: false,
            }))
          }}
        />
      )}
      <Grid item xs={12}>
        <Counters params={state.settlementCount} selectedLabel={currentSearchParams.get("status")} />
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select<ProvisionalPreSettlementManagerState["searchFilterKey"]>
                      value={state.searchFilterKey || ""}
                      onChange={(params) => {
                        setState((prevState) => ({
                          ...prevState,
                          searchFilterKey: params.target
                            .value as ProvisionalPreSettlementManagerState["searchFilterKey"],
                          searchFilterValue: "",
                        }))
                      }}
                    >
                      <MenuItem value={"productId"}>상품번호</MenuItem>
                      <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={state.searchFilterValue || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onFilterSearch()
                      }
                    }}
                    onChange={(params) => {
                      const value = numericFields.includes(state.searchFilterKey || "")
                        ? params.target.value.replace(/[^0-9]/g, "")
                        : params.target.value

                      setState((prevState) => ({
                        ...prevState,
                        searchFilterValue: value,
                      }))
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ProvisionalPreSettlementTable
              state={state}
              dispatch={setState}
              fetch={fetchProvisionalPreSettlementList}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
