import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { getModelList, updateDisplay } from "apis/modelAPI"
import { ModelListItemType } from "types/ModelListItemType"
import { isNotEmpty } from "utils/validationUtils"
import { toast } from "react-toastify"
import { BrandType } from "types/BrandType"
import { getBrands } from "apis/brandAPI"

export const useModelManagerStore = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [brandList, setBrandList] = useState<BrandType[]>([])

  const modelListParams = useMemo(() => {
    const page = Number(searchParams.get("page")) || 1
    const brandSid = searchParams.get("brandSid") || "ROLEX"
    const name = searchParams.get("name") || ""
    return { page, brandSid, name }
  }, [searchParams])

  const [modelList, setModelList] = useState<{
    content: ModelListItemType[]
    totalPages: number
  }>({ content: [], totalPages: 0 })

  const fetchBrandList = useCallback(async () => {
    const brandList = await getBrands()
    setBrandList(brandList)
  }, [])

  const fetchModelList = useCallback(async (params: { page: number; brandSid: string; name: string }) => {
    const modelList = await getModelList({
      page: params.page - 1,
      brand: params.brandSid,
      name: params.name,
      size: 15,
    })
    setModelList({
      content: modelList.content,
      totalPages: modelList.totalPages ?? 1,
    })
  }, [])

  const changePage = useCallback(
    (page: number) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(page))
      setSearchParams(nextSearchParams)
    },
    [searchParams, setSearchParams]
  )
  const changeBrandSid = useCallback(
    (brandSid: string) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(1))
      nextSearchParams.set("brandSid", brandSid)
      setSearchParams(nextSearchParams)
    },
    [searchParams, setSearchParams]
  )
  const changeQuery = useCallback(
    (name: string) => {
      const nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set("page", String(1))

      if (isNotEmpty(name)) {
        nextSearchParams.set("name", name)
      } else {
        nextSearchParams.delete("name")
      }
      setSearchParams(nextSearchParams)
    },
    [searchParams, setSearchParams]
  )

  const changeDisplay = useCallback(async (model: ModelListItemType) => {
    try {
      const updatedModelListItem = await updateDisplay(model.id, !model.onDisplay)
      setModelList((prev) => ({
        ...prev,
        content: prev.content.map((listItem) => {
          return listItem.id === updatedModelListItem.id ? updatedModelListItem : listItem
        }),
      }))
    } catch {
      toast.error("노출 변경에 실패했습니다.")
    }
  }, [])

  return useMemo(
    () => ({
      brandList,
      modelListParams,
      modelList,
      fetchBrandList,
      fetchModelList,
      changePage,
      changeBrandSid,
      changeQuery,
      changeDisplay,
    }),
    [
      brandList,
      changeBrandSid,
      changeDisplay,
      changePage,
      changeQuery,
      fetchBrandList,
      fetchModelList,
      modelList,
      modelListParams,
    ]
  )
}
