import { useCallback, useState } from "react"
import UserDetailPopupPage from "pages/User/UserDetailPopupPage"
import UserListTableSearchBar from "pages/User/UserListPage/SearchBar"
import UserListTable from "./UserListTable"
import styled from "@emotion/styled"
import { getUserList } from "apis/userAPI"
import { UserListSearchParams, UserType, UserTypeCode } from "types/UserType"
import { toast } from "react-toastify"
import { PaginationParamsType } from "types/PageType"
import { useMount } from "react-use"

export type UserListFilterKeyType = keyof UserListSearchParams
export type UserSearchType = { filter: UserListFilterKeyType; keyword: string | UserTypeCode }

const FETCH_LIST_COUNT = 20
export type ForeignerSearchType = "A" | "Y" | "N"

const UserListPage = () => {
  const [userList, setUserList] = useState<UserType[]>([])
  const [selectedUserId, setSelectedUserId] = useState("")
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })

  const [searchParams, setSearchParams] = useState<UserSearchType>({
    filter: "name",
    keyword: "",
  })
  const [searchForeigner, setSearchForeigner] = useState<ForeignerSearchType>("A")

  const changeSearchFilterValue = useCallback((searchFilterValue: UserListFilterKeyType) => {
    setSearchParams((prev) => ({ ...prev, filter: searchFilterValue }))
  }, [])

  const parseSearchParams = useCallback((searchParams?: UserSearchType) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchUserList = useCallback(
    (pageNumber?: number, searchParams?: UserSearchType, searchForeigner?: ForeignerSearchType) => {
      getUserList({
        size: FETCH_LIST_COUNT,
        page: pageNumber,
        foreigner: searchForeigner === "Y" ? "true" : searchForeigner === "N" ? "false" : undefined,
        ...parseSearchParams(searchParams),
      })
        .then(({ content, totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
          setUserList(content)
          setPaginationParams((prev) => ({
            ...prev,
            number,
            totalElements,
            totalPages,
            first,
            size,
          }))
        })
        .catch((err) => {
          toast.error("회원정보를 불러오는데 실패했습니다.")
          console.log("ERROR", err)
        })
    },
    [parseSearchParams, searchForeigner]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchUserList(pageNumber, searchParams)
    },
    [searchParams, fetchUserList, searchForeigner]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const changeForeignerValue = useCallback((foreigner: ForeignerSearchType) => {
    setSearchForeigner(foreigner)
  }, [])

  const onSearch = useCallback(
    (keyword: UserSearchType["keyword"], searchForeigner?: ForeignerSearchType) => {
      fetchUserList(0, { ...searchParams, keyword }, searchForeigner)
      setPaginationParams((prev) => ({ ...prev, number: 0 }))
    },
    [fetchUserList, searchParams, searchForeigner]
  )

  useMount(() => {
    fetchUserList()
  })

  return (
    <>
      <PageHeaderWrapper>
        <TotalUser>
          전체<b style={{ marginLeft: 10, color: "#1976d2" }}>{paginationParams.totalElements || "-"}</b>명
        </TotalUser>
        <UserListTableSearchBar
          searchParams={searchParams}
          searchForeigner={searchForeigner}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          changeForeignerValue={changeForeignerValue}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <UserListTable
          userList={userList}
          selectUserId={(userId) => setSelectedUserId(userId)}
          paginationParams={paginationParams}
          changePage={(page) => changePageNumber(page - 1)}
        />
      </PageBodyWrapper>
      {selectedUserId && <UserDetailPopupPage userId={selectedUserId} closePopup={() => setSelectedUserId("")} />}
    </>
  )
}

export default UserListPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const PageBodyWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const TotalUser = styled.div`
  font-weight: 800;
  display: inline-flex;
  width: 50%;
`
