import { Box, Button, Stack } from "@mui/material"
import SwipeBannerTableFilter from "pages/ShopManager/ShopContentTab/SwipeBannerTab/SwipeBannerTableFilter"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import ShopBannerCurationAPI from "apis/shopBannerCurationAPI"
import { ShopCurationType } from "types/ShopCurationType"
import EditIcon from "@mui/icons-material/Edit"
import { toDateStr, toDateTimeStr, toTimeStr } from "utils/datetimeUtil"
import { DateTime } from "luxon"
import { PageType } from "../../../../types/PageType"

function renderStatusCell(params: any) {
  if (params?.row?.postingStatus === "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
  const startedAt = DateTime.fromISO(params?.row?.startedAt)
  const endedAt = DateTime.fromISO(params?.row?.endedAt)

  return (
    <Stack direction={'column'} alignItems={"self-end"}>
      <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
    </Stack>
  )
}
interface BannerTableFilterState {
  type: string,
  postingStatus: string | null,
  page: number | null,
  size: number | null
}

const initBannerFilterState: BannerTableFilterState = {
  type: "SWIPE_BANNER",
  postingStatus: null,
  page: 0,
  size: 50
}

const SwipeBannerTable: React.FunctionComponent<{
  handleSelection: (ids: number[]) => void
  handleUpdateId: (id: number) => void
  refreshCounter: number
}> = ({ handleSelection, handleUpdateId, refreshCounter }) => {
  const [tableFilter, setTableFilter] = useState<BannerTableFilterState>(initBannerFilterState)
  const [banners, setBanners] = useState<PageType<ShopCurationType>>({content: []})

  const getColumns = () => {
    return [
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={"수정"}
            onClick={() => {
              handleUpdateId(params.row.id)
            }}
            showInMenu
          />,
        ],
      },
      { field: "id", headerName: "ID", width: 80 },
      { field: "title", headerName: "제목", flex: 1 },
      { field: "description", headerName: "설명", flex: 2 },
      {
        field: "createdAt",
        headerName: "등록일시",
        maxWidth: 150,
        valueGetter: (params: any) => toDateTimeStr(params.row.createdAt),
        renderCell: (params: any) => (
          <div style={{textAlign: "center"}}>
            <p>{toDateStr(params.row.createdAt, false)}<br/>
              {toTimeStr(params.row.createdAt)}</p>
          </div>
        )
      },
      { field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
      { field: "postingStatus", headerName: "진행 상태", width: 100, renderCell: renderStatusCell },
      { field: "priority", headerName: "노출순서", maxWidth: 75 },
      {field: 'showDomesticUser',  width: 80,
        renderCell: (params: any) => (
          <div style={{textAlign: "center"}}>
            <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
          </div>
        ),
        renderHeader: () => (
          <div style={{  textAlign: "center", lineHeight: "1.2" }}>
            내국인 회원<br />노출
          </div>
        ),
      },
      { field: "showForeignUser", width: 80,
        renderCell: (params: any) => (
          <div
            style={{ textAlign: "center"  }}
          >
            <span>{params.row.showForeignUser ? "Y" : "N"}</span>
          </div>
        ),
        renderHeader: () => (
          <div style={{ textAlign: "center", lineHeight: "1.2" }}>
            외국인 회원<br />노출
          </div>
        ),
      }
    ]
  }

  useEffect(() => {
    ShopBannerCurationAPI.getPage(tableFilter).then((result) => {
      setBanners(result)
    })
  }, [tableFilter, refreshCounter])

  return (
    <Stack spacing={2}>
      <SwipeBannerTableFilter
        filter={tableFilter}
        handleFilterChange={(changedFilter: BannerTableFilterState) => {
          setTableFilter(changedFilter)
        }}
      />
      <Box sx={{ height: 720, width: "100%" }}>
        <DataGrid
          columns={getColumns()}
          getRowHeight={() => 70}
          onSelectionModelChange={(ids: any) => {
            handleSelection(ids as number[])
          }}
          checkboxSelection

          paginationMode="server"
          onPageChange={(page) => {
            setTableFilter({...tableFilter, page: page})
          }}
          onPageSizeChange={(size) => {
            setTableFilter({...tableFilter, size: size})
          }}

          page={banners.pageable?.pageNumber}
          pageSize={banners.pageable?.pageSize}
          rowCount={banners?.totalElements}
          rows={banners?.content}
        />
      </Box>
    </Stack>
  )
}

export default SwipeBannerTable
