import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import React, { useCallback, useEffect, useReducer, useState } from "react"
import DiagnosisTable from "./DiagnosisTable"
import { countDiagnoses, guideResult } from "../../apis/diagnosisAPI"
import { PageType } from "types/PageType"
import { downloadSellOrderExcel, getSellOrderList, getSellOrders } from "../../apis/sellOrderAPI"
import {
  OrderType,
  SellOrderListSearchParams,
  SellOrderStatusCode,
  SellOrderStatusGroupType,
  SellOrderType,
} from "types/SellOrderType"
import { toApplyCounts } from "../../utils/SellOrderTypeConverter"
import { useLocation } from "react-router-dom"
import { DateTime } from "luxon"
import { CountType } from "types/CountType"
import Counters from "../SellOrder/Counters"
import { DesktopDatePicker } from "@mui/lab"
import { toast } from "react-toastify"
import { DiagnosisStatusCode } from "types/DiagnosisType"
import { SaleTypeCode } from "types/ProductType"
import EditRePurchaseGuaranteedDiagnosisPopup from "./RePurchaseGuaranteed/EditRePurchaseGuaranteedDiagnosisPopup"
import EditBeforeDiagnosisDiagnosisPopup from "./BeforeDiagnosis/EditBeforeDiagnosisDiagnosisPopup"
import EditConsignmentDiagnosisPopup from "./Consignment/EditConsignmentDiagnosisPopup"
import { isNotNil } from "../../utils/validationUtils"
import { downloadExcel } from "../../utils/excelUtil"
import { toDateTimeStr } from "../../utils/datetimeUtil"

export interface DiagnosisState {
  selectedIds: string[]
  sellOrders: PageType<SellOrderType>
  diagnosisCount: CountType[]
  isShowViewPopup: boolean
  isShowEditPopup: boolean
  editSellOrder?: SellOrderType
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  searchFilterKey?: string
  searchFilterValue?: string
  searchOrderStatusKey?: string
  searchNeedNotifyKey?: string
  sort?: string
  refresh: boolean
  loading: boolean
}

const initState: (orderId?: string | null) => DiagnosisState = (orderId) => {
  let state: DiagnosisState = {
    selectedIds: [],
    sellOrders: {
      content: [],
      pageable: { pageSize: 25, pageNumber: 0 },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    diagnosisCount: [],
    isShowViewPopup: false,
    isShowEditPopup: false,
    editSellOrder: undefined,
    startDateFilter: undefined,
    endDateFilter: undefined,
    searchFilterKey: "productId",
    searchFilterValue: undefined,
    searchOrderStatusKey: "전체",
    searchNeedNotifyKey: "ALL",
    sort: "waitingDiagnosisAt,desc",
    refresh: false,
    loading: false,
  }

  if (orderId) {
    state.searchFilterKey = "id"
    state.searchFilterValue = orderId
  }

  return state
}

interface DiagnosisAction {
  type: string
  payload?: any
}

function reducer(state: DiagnosisState, action: DiagnosisAction): DiagnosisState {
  switch (action.type) {
    case "SELECTION_DIAGNOSIS_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_SELLORDER":
      return { ...state, sellOrders: action.payload }
    case "FETCH_DIAGNOSIS_COUNT":
      return { ...state, diagnosisCount: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SHOW_EDIT_DIAGNOSIS_POPUP":
      return { ...state, isShowEditPopup: true, editSellOrder: action.payload }
    case "HIDE_EDIT_DIAGNOSIS_POPUP":
      return { ...state, isShowEditPopup: false }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "CHANGE_SEARCH_ORDER_STATUS_KEY":
      return { ...state, searchOrderStatusKey: action.payload }
    case "CHANGE_NEED_NOTIFY_KEY":
      return { ...state, searchNeedNotifyKey: action.payload }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const Diagnoses = (sellOrderType: OrderType[]) => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const numericFields = ["productId", "sellerId"]

  const [state, dispatch] = useReducer(reducer, initState(urlSearchParams.get("orderId")))
  const [autoPopup, setAutoPopup] = useState<string | null>(urlSearchParams.get("productId"))

  const diagnosesStatusList = [
    DiagnosisStatusCode.진단서_작성_대기,
    DiagnosisStatusCode.진단서_작성중,
    DiagnosisStatusCode.진단서_작성_완료,
    DiagnosisStatusCode.진단서_확정,
  ]

  const getSellOrderStatusList = () => {
    if (state.searchOrderStatusKey === "전체") {
      return sellOrderType.indexOf(OrderType.진단전) > -1
        ? [
            SellOrderStatusCode.입고_완료,
            SellOrderStatusCode.진단중,
            SellOrderStatusCode.진단_완료,
            SellOrderStatusCode.판매_완료,
            SellOrderStatusCode.판매_포기,
            SellOrderStatusCode.페널티_대기,
            SellOrderStatusCode.판매_불합격,
          ]
        : [
            SellOrderStatusCode.입고_완료,
            SellOrderStatusCode.진단중,
            SellOrderStatusCode.진단_완료,
            SellOrderStatusCode.판매_대기,
            SellOrderStatusCode.판매중,
            SellOrderStatusCode.결제_대기,
            SellOrderStatusCode.판매_완료,
            SellOrderStatusCode.판매_포기,
            SellOrderStatusCode.페널티_대기,
            SellOrderStatusCode.판매_불합격,
          ]
    } else if (state.searchOrderStatusKey === "판매 포기") {
      return [SellOrderStatusCode.판매_포기]
    } else {
      return sellOrderType.indexOf(OrderType.진단전) > -1
        ? [
            SellOrderStatusCode.입고_완료,
            SellOrderStatusCode.진단중,
            SellOrderStatusCode.진단_완료,
            SellOrderStatusCode.판매_완료,
            SellOrderStatusCode.페널티_대기,
            SellOrderStatusCode.판매_불합격,
          ]
        : [
            SellOrderStatusCode.입고_완료,
            SellOrderStatusCode.진단중,
            SellOrderStatusCode.진단_완료,
            SellOrderStatusCode.판매_대기,
            SellOrderStatusCode.판매중,
            SellOrderStatusCode.결제_대기,
            SellOrderStatusCode.판매_완료,
            SellOrderStatusCode.페널티_대기,
            SellOrderStatusCode.판매_불합격,
          ]
    }
  }

  const fetchSellOrders = (searchParams: any = {}) => {
    let newParams = {
      sort: state.sort,
      ...searchParams,
      diagnosisStatus: urlSearchParams.get("status") || diagnosesStatusList,
      status: getSellOrderStatusList(),
      productId: urlSearchParams.get("productId") ?? undefined,
      sellOrderType: sellOrderType,
      userNotified: state.searchNeedNotifyKey === "ALL" ? null : state.searchNeedNotifyKey,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getSellOrders(newParams)
      .then((sellOrders) => {
        if (isNotNil(autoPopup)) {
          dispatch({ type: "SHOW_EDIT_DIAGNOSIS_POPUP", payload: sellOrders.content[0] })
          setAutoPopup(null)
        }

        dispatch({ type: "FETCH_SELLORDER", payload: sellOrders })
      })
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = (searchParams: any = {}) => {
    countDiagnoses({
      ...searchParams,
      sellOrderStatus: getSellOrderStatusList(),
      sellOrderType: sellOrderType,
      userNotified: state.searchNeedNotifyKey === "ALL" ? null : state.searchNeedNotifyKey,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }).then((result) => {
      dispatch({
        type: "FETCH_DIAGNOSIS_COUNT",
        payload: toApplyCounts(result, diagnosesStatusList, ".", [], false),
      })
    })
  }

  const handleExcelDownloadClick = (params: any = {}) => {
    if (state.startDateFilter === undefined) {
      toast.error("시작일을 선택해주세요")
      return false
    }
    if (state.endDateFilter === undefined) {
      toast.error("종료일을 선택해주세요")
      return false
    }

    let newParams = {
      sort: state.sort,
      diagnosisStatus: urlSearchParams.get("status") || diagnosesStatusList,
      status: getSellOrderStatusList(),
      productId: urlSearchParams.get("productId") ?? undefined,
      sellOrderType: sellOrderType,
      userNotified: state.searchNeedNotifyKey === "ALL" ? null : state.searchNeedNotifyKey,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }
    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getSellOrderList(newParams).then((sellOrders) => {
      if (sellOrders.length == 0) {
        toast.error("다운로드 할 내역이 없습니다.")
        return false
      }
      let data = sellOrders.map((it) => {
        return {
          판매주문번호: it.id,
          상품번호: it?.product?.id,
          진단상태: it?.productDiagnosis?.status,
          진행상태: it.sellOrderStatus,
          결과전달: it.productDiagnosis?.notifiedAt ? "완료" : "필요",
          진단결과: it.productDiagnosis?.result,
          해외판매: it.product?.globalSale ? "동의" : "미동의",
          판매자ID: it.seller?.id,
          상품명: `${it?.product?.title} ${it?.product?.titleSecondary}`,
          "Ref No.": it?.product?.detailModel?.fullRefNo,
          담당자: it?.productDiagnosis?.diagnosedBy?.name,
          최적판매가: it?.productDiagnosis?.viverPrices?.finalPrice,
          판매신청일: toDateTimeStr(it?.acceptedAt),
          입고확인일: toDateTimeStr(it?.waitingDiagnosisAt),
          안내완료일: toDateTimeStr(it?.productDiagnosis?.notifiedAt),
        }
      })
      downloadExcel(data, sellOrderType.length > 1 ? `진단관리_위탁직매입` : `진단관리_진단전`)
    })

    // downloadSellOrderExcel({
    //   ...params,
    //   startDate: state.startDateFilter && state.startDateFilter.toISODate(),
    //   endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    //   types: sellOrderType,
    //   statusGroup: sellOrderType.indexOf(OrderType.진단전) > -1 ? SellOrderStatusGroupType.진단_진단전 : SellOrderStatusGroupType.진단_위탁,
    // }).then((blob) => {
    //   const url = window.URL.createObjectURL(new Blob([blob]))
    //   const link = document.createElement("a")
    //   link.href = url
    //   const fileName = sellOrderType.length > 1 ? `진단관리_` + sellOrderType[0] + `판매.xlsx` : `진단관리_` + sellOrderType + `판매.xlsx`
    //   link.setAttribute("download", fileName)
    //   document.body.appendChild(link)
    //   link.click()
    // })
  }

  useEffect(() => {
    fetchSellOrders({
      page: state.sellOrders.pageable?.pageNumber || 0,
      size: state.sellOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, location.search])

  const getDiagnosesByOrderId = (orderIds: string[]) => {
    return state.sellOrders.content.filter((it) => orderIds.includes(it.id!!)).map((it) => it.productDiagnosis!!)
  }

  const handleGuideResultClick = () => {
    if (!state.selectedIds.length) {
      toast.error("대상 판매주문을 선택해주세요")
      return
    }

    const diagnoses = getDiagnosesByOrderId(state.selectedIds).map((it) => it.id)
    guideResult(diagnoses)
      .then(() => {
        toast.success("고객 알림 완료")
      })
      .catch((error) => {
        toast.error(`고객 알림 실패 (${error.message || error})`)
      })
      .finally(() => dispatch({ type: "REFRESH" }))
  }

  const renderEditPopupByOrderType = useCallback(() => {
    if (!state.editSellOrder) {
      return
    }

    if (state.editSellOrder.product?.saleType === SaleTypeCode.매입보증판매) {
      return (
        <EditRePurchaseGuaranteedDiagnosisPopup
          open={state.isShowEditPopup}
          selectedSellOrder={state.editSellOrder}
          handleClose={() => {
            dispatch({ type: "HIDE_EDIT_DIAGNOSIS_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )
    }

    if (state.editSellOrder.product?.saleType === SaleTypeCode.진단전판매) {
      return (
        <EditBeforeDiagnosisDiagnosisPopup
          open={state.isShowEditPopup}
          selectedSellOrder={state.editSellOrder}
          handleClose={() => {
            dispatch({ type: "HIDE_EDIT_DIAGNOSIS_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )
    }

    return (
      <EditConsignmentDiagnosisPopup
        open={state.isShowEditPopup}
        selectedSellOrder={state.editSellOrder}
        handleClose={() => {
          dispatch({ type: "HIDE_EDIT_DIAGNOSIS_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
    )
  }, [state.editSellOrder, state.isShowEditPopup])

  return (
    <Grid container spacing={2}>
      {renderEditPopupByOrderType()}
      <Grid item xs={12}>
        <Counters params={state.diagnosisCount} selectedLabel={urlSearchParams.get("status")} />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
            <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>판매 신청일</InputLabel>
            <DesktopDatePicker
              label="시작일"
              inputFormat="yyyy-MM-dd"
              value={state.startDateFilter ?? null}
              onChange={(params) => {
                dispatch({ type: "CHANGE_START_DATE", payload: params })
                dispatch({ type: "REFRESH" })
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
            />
            <DesktopDatePicker
              label="종료일"
              inputFormat="yyyy-MM-dd"
              value={state.endDateFilter ?? null}
              onChange={(params) => {
                dispatch({ type: "CHANGE_END_DATE", payload: params })
                dispatch({ type: "REFRESH" })
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
            />
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_order_key">판매상태</InputLabel>
              <Select
                value={state.searchOrderStatusKey || "전체"}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_SEARCH_ORDER_STATUS_KEY", payload: params.target.value })
                  dispatch({ type: "REFRESH" })
                }}
              >
                <MenuItem value={"전체"}>전체</MenuItem>
                <MenuItem value={"판매 포기"}>판매 포기</MenuItem>
                <MenuItem value={"판매 포기 제외"}>판매 포기 제외</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_order_key">결과전달</InputLabel>
              <Select
                value={state.searchNeedNotifyKey || "ALL"}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_NEED_NOTIFY_KEY", payload: params.target.value })
                  dispatch({ type: "REFRESH" })
                }}
              >
                <MenuItem value={"ALL"}>전체</MenuItem>
                <MenuItem value={"Y"}>필요</MenuItem>
                <MenuItem value={"N"}>완료</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_key">검색항목</InputLabel>
              <Select
                value={state.searchFilterKey || ""}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                }}
              >
                <MenuItem value={"id"}>판매주문번호</MenuItem>
                <MenuItem value={"sellerId"}>판매자 ID</MenuItem>
                <MenuItem value={"productId"}>상품번호</MenuItem>
                <MenuItem value={"productTitle"}>상품명</MenuItem>
                <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                <MenuItem value={"sellerPhone"}>판매자 연락처</MenuItem>
                <MenuItem value={"productSaleType"}>판매방식</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "220px" }}
              size={"small"}
              value={state.searchFilterValue || ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch({ type: "SEARCH" })
                }
              }}
              onChange={(params) => {
                const value = numericFields.includes(state.searchFilterKey || "")
                  ? params.target.value.replace(/[^0-9]/g, "")
                  : params.target.value

                dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: value })
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 10 }}>
            <DiagnosisTable
              state={state}
              dispatch={dispatch}
              fetch={fetchSellOrders}
              components={[
                <Button
                  variant={"outlined"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleExcelDownloadClick}
                >
                  엑셀 다운로드
                </Button>,
                <Button
                  variant={"contained"}
                  color={"error"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleGuideResultClick}
                >
                  고객에게 결과 전달
                </Button>,
              ]}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Diagnoses
