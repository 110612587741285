import styled from "@emotion/styled"
import { Modal, Switch } from "@mui/material"
import { BoxHorizontal, BoxVertical, BtnFixBorder, BtnFixGhost, Typography } from "@viver-inc/viver-ui"
import React, { FunctionComponent, useState } from "react"
import { EventFormStateTemplateBodyItemProductTabType } from "types/EventFormStateType"
import { initialEventForm } from ".."
import { ProductListManagementPopup } from "components/Popup/ProductListManagementPopup"
import Button from "../../../../components/Button"

export const TemplateEditorProductTab: FunctionComponent<{
  templateBodyProductTab: EventFormStateTemplateBodyItemProductTabType
  setTemplateBodyProductTab: (templateBodyProductTab: EventFormStateTemplateBodyItemProductTabType) => void
}> = ({ templateBodyProductTab, setTemplateBodyProductTab }) => {
  return (
    <BoxVertical borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10}  bottom={10}>
      <Typography variant="sub18" weight="sb">
        상품 (탭이 1개면 탭UI 생략)
      </Typography>
      <BoxVertical >
        <BoxHorizontal alignItems="center">
          <Switch
            checked={templateBodyProductTab.onDisplay}
            onChange={(e, check) => {
              setTemplateBodyProductTab({ ...templateBodyProductTab, onDisplay: check })
            }}
          />
          <Typography variant="desc14" weight="r">
            노출
          </Typography>
        </BoxHorizontal>
        <BoxVertical flex={1} paddingHorizontal={10} justifyContent="center">
        {templateBodyProductTab.products.map((productTab, index) => (
          <TemplateEditorProductTabItem
            key={index}
            index={index}
            productTab={productTab}
            setProductTab={(productTab) => {
              setTemplateBodyProductTab({
                ...templateBodyProductTab,
                products: templateBodyProductTab.products.map((_productTab, _index) => {
                  if (index === _index) {
                    return productTab
                  }
                  return _productTab
                }),
              })
            }}
            onClickDeleteProductTabItem={() => {
              setTemplateBodyProductTab({
                ...templateBodyProductTab,
                products: templateBodyProductTab.products.filter((_productTab, _index) => index !== _index),
              })
            }}
          />
        ))}
          {/*추가 버튼*/}
          <ButtonWrapper>
            <Button
              buttonSize="M"
              disabled={templateBodyProductTab.products.length >= 10}
              buttonStyle="blue-fill"
              onClick={() => {
                setTemplateBodyProductTab({
                  ...templateBodyProductTab,
                  products: [...templateBodyProductTab.products, initialEventForm.templateBodyProductTab.products[0]],
                })
              }}>
              추가
            </Button>
          </ButtonWrapper>

      </BoxVertical>
      </BoxVertical>
    </BoxVertical>
  )
}

const TemplateEditorProductTabItem: FunctionComponent<{
  index: number
  productTab: EventFormStateTemplateBodyItemProductTabType["products"][0]
  setProductTab: (productTab: EventFormStateTemplateBodyItemProductTabType["products"][0]) => void
  onClickDeleteProductTabItem: () => void
}> = ({ index, productTab, setProductTab, onClickDeleteProductTabItem }) => {
  const [openProductSelectPopup, setOpenProductSelectPopup] = useState(false)

  return (
    <BoxHorizontal alignItems="center">
      <BoxVertical flex={1} justifyContent="center">
        <BoxHorizontal alignItems="center" paddingVertical={5}>
          <BoxHorizontal width={10}>{null}</BoxHorizontal>
          <Input
            value={productTab.title}
            placeholder="탭이름"
            onChange={(event) => {
              setProductTab({ ...productTab, title: event.target.value })
            }}
          />
          <BtnFixBorder
            size="xsmall"
            property="text"
            label="상품관리"
            onPress={() => {
              setOpenProductSelectPopup(true)
            }}
          />
          {index !== 0 && (
            <>
              <BoxHorizontal width={5}>{null}</BoxHorizontal>
              <BtnFixBorder size="xsmall" property="text" label="제거" onPress={onClickDeleteProductTabItem} />
            </>
          )}
        </BoxHorizontal>
      </BoxVertical>

      <Modal
        open={openProductSelectPopup}
        onClose={() => {
          setOpenProductSelectPopup(false)
        }}
      >
        <ProductListManagementPopup
          open={openProductSelectPopup}
          productIds={productTab.products.map((productTabItem) => productTabItem.productId)}
          setProducts={(products) => {
            setProductTab({
              ...productTab,
              products: products.map((product) => ({ productId: product.id })),
            })
          }}
          close={() => {
            setOpenProductSelectPopup(false)
          }}
        />
      </Modal>
    </BoxHorizontal>
  )
}

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  outline: none;
  height: 40px;
  padding: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  margin-right: 10px;
`
const ButtonWrapper = styled.div`
  width: 50px; 
    margin-top: 10px;
`
