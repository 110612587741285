import {Button, Grid, Paper, Stack} from "@mui/material"
import React, {useEffect, useState} from "react";
import ShippingTable from "./ShippingTable";
import {PageType} from "types/PageType";
import {DateTime} from "luxon";
import {styled} from "@mui/material/styles";
import {closeShipping, getShippings} from "../../apis/shippingAPI";
import {ShippingType} from "types/ShippingType";
import {downloadExcel} from "../../utils/shippingToExcelUtil";
import DateRangePicker from "../../components/DateRangePicker";


const Input = styled('input')({
    display: 'none',
});

export interface ShippingParams {
    page: number,
    size: number,
    startDate: string,
    endDate: string
}

const initParams: ShippingParams = {
    page: 0,
    size: 100,
    startDate: DateTime.now().toISODate(),
    endDate: DateTime.now().toISODate()
}



const ShippingManager = () => {
    const [shppings, setShippings] = useState<PageType<ShippingType> | null>(null)
    const [searchParams, setSearchParams] = useState<ShippingParams>(initParams)

    function fetchShippings() {
        getShippings({
            startDate: searchParams.startDate,
            endDate: searchParams.endDate
        }).then(result => setShippings(result))
    }


    const handleDownloadExcel = () => {
        const shippingIds = shppings?.content.map(it => it.id)
        getShippings({"id": shippingIds, "unmasking": true})
            .then((resp) => downloadExcel(resp.content))
    }

    const handleCloseShipping = () => {
        closeShipping()
    }


    useEffect(() => {
        fetchShippings()
    }, [searchParams])

    return (
        <Grid container spacing={2}>
            {
                <Grid item xs={12}>
                    {/*<Counters params={state.buyOrderCount} selectedLabel={new URLSearchParams(location.search).get('status')}/>*/}
                </Grid>
            }
            <Grid item xs={12} >
                <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                    <Grid item xs={12}>
                        <Stack spacing={1} direction={'column'}>
                            <Stack direction={'column'} alignItems={'flex-end'} spacing={1}>
                                <Stack spacing={1} direction={'row'} sx={{lineHeight: '45px'}}>
                                    <DateRangePicker
                                        startDate={searchParams.startDate}
                                        endDate={searchParams.endDate}
                                        onChange={(change) => {
                                            setSearchParams({...searchParams, startDate: change.startDate, endDate: change.endDate})
                                        }}
                                    />
                                    {/*    <DesktopDatePicker*/}
                                    {/*        label="배송 요청일"*/}
                                    {/*        inputFormat="yyyy-MM-dd"*/}
                                    {/*        value={DateTime.fromISO(searchParams.startDate)}*/}
                                    {/*        onChange={(params) => {*/}
                                    {/*            setSearchParams({...searchParams, startDate: params?.toISODate() ?? searchParams.startDate })*/}
                                    {/*        }}*/}
                                    {/*        renderInput={(params) => <TextField {...params} sx={{width: '160px'}} size={'small'}/>}*/}
                                    {/*    />*/}
                                </Stack>
                                <Stack direction={'row'} spacing={1}>
                                    {/*<Button variant={'contained'} color={'primary'} size={'small'} onClick={() => handleCloseShipping()} >배송 마감</Button>*/}
                                    <Button variant={'contained'} color={'primary'} size={'small'} onClick={() => handleDownloadExcel()}>엑셀 다운로드</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <ShippingTable
                            rows={shppings?.content ?? []}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>)
}

export default ShippingManager