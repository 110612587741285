import styled from "@emotion/styled"
import Button from "components/Button"
import { ModelManagerTab } from "pages/ModelManager/ModelManagerTab"
import { useState } from "react"
import ModelRegistrationRequestFormPopup from "./ModelRegistrationRequestFormPopup"

import { ModelRegistrationRequestListTable } from "./ModelRegistrationRequestListTable"
import ModelRegistratiionRequestSearchBar from "./ModelRegistrationSearchBar"
import { useModelRequestListData } from "./useModelRequestListData"

const ModelRegistrationRequstManager = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null)

  const {
    modelRequestList,
    paginationParams,
    selectedStatusFilter,
    changePageNumber,
    onSearch,
    onRefresh,
    changeSearchKeyword,
    changeSearchFilterValue,
    changeStatusFilter,
  } = useModelRequestListData()

  return (
    <>
      <ModelManagerTab />
      <TableWrapper>
        <InlineWrapper>
          <ModelRegistratiionRequestSearchBar
            selectedStatusFilter={selectedStatusFilter}
            changeStatusFilter={changeStatusFilter}
            changeSearchFilterValue={changeSearchFilterValue}
            changeSearchKeyword={changeSearchKeyword}
            onSearch={onSearch}
          />
          <ButtonWrapper>
            <Button
              onClick={() => {
                setSelectedRequestId(null)
                setIsOpenPopup(true)
              }}
            >
              모델 등록 요청
            </Button>
          </ButtonWrapper>
        </InlineWrapper>

        <div style={{ height: 24 }} />
        <ModelRegistrationRequestFormPopup
          requestId={selectedRequestId}
          isOpen={isOpenPopup}
          onRefreshList={onRefresh}
          closeModal={() => {
            setIsOpenPopup(false)
            setSelectedRequestId(null)
          }}
        />

        <ModelRegistrationRequestListTable
          list={{
            content: modelRequestList,
            currentPage: paginationParams.number,
            totalPages: paginationParams.totalPages,
          }}
          onChangePage={changePageNumber}
          onClickRow={(id: number) => {
            setSelectedRequestId(id)
            setIsOpenPopup(true)
          }}
        />
      </TableWrapper>
    </>
  )
}

export default ModelRegistrationRequstManager

const TableWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  width: 130px;
  margin-left: 35px;
`
