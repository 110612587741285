import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { PointType } from "types/PointType"
import styled from "@emotion/styled"
import { pointTableHeaderItemList } from "pages/User/UserPointListPage/PointListTable/TableHeader"
import Button from "components/Button"
import { toDateStr, toTimeStr } from "utils/datetimeUtil"
import { numberWithCommas } from "utils/NumberUtils"

type Props = {
  point: PointType
  cancelPoint: (poindId: number) => void
} & HTMLAttributes<HTMLDivElement>

const PointListTableRow = ({ point, cancelPoint, ...props }: PropsWithChildren<Props>) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  const confirmToCancelPoint = (pointId: number) => {
    if (window.confirm("포인트 지급을 취소하시겠습니까?")) {
      cancelPoint(pointId)
      return
    }

    return
  }

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[0].width }}>{point.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[1].width }}>
        {point.createdAt ? toDateStr(point.createdAt, false) : "-"}
        <br />
        {point.createdAt ? toTimeStr(point.createdAt, true) : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[2].width }}>
        <PointText style={{ fontWeight: 800 }} actionType={point.actionType} status={point.status}>
          {point.actionType}
        </PointText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[3].width }}>{point.status}</TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[4].width }}>
        {converEmtpyValueToDash(point.reasonType)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[5].width }}>{point.reasonMessage}</TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[6].width }}>
        <PointText actionType={point.actionType} status={point.status}>
          {point.actionType === "적립" ? "+" : "-"}
          {numberWithCommas(point.points)}
        </PointText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[7].width }}>
        {point.availableDate ? toDateStr(point.availableDate, true) : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointTableHeaderItemList[8].width }}>
        {point.actionType === "적립" && point.status === "활성" && (
          <ButtonWrapper>
            <Button
              buttonSize="S"
              buttonStyle="red-fill"
              onClick={() => confirmToCancelPoint(point.id)}
              style={{ fontWeight: 700 }}
            >
              지급 취소
            </Button>
          </ButtonWrapper>
        )}
      </TableRowItem>
    </TableRow>
  )
}

export default PointListTableRow

const PointText = styled.div<{ actionType: "적립" | "차감"; status?: PointType["status"] }>`
  color: ${({ actionType, status }) =>
    status === "활성" ? (actionType === "적립" ? "#d32f2f" : "#1976d2") : "#AAAAAA"};
  text-decoration: ${({ status }) => {
    if (!status) {
      return
    }

    return status === "활성" ? "unset" : "line-through"
  }};
`

const ButtonWrapper = styled.div`
  width: 80px;
`
