import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import UserListTableRow from "pages/User/UserListPage/UserListTable/TableBody/TableRow"
import {Pagination} from "@mui/material"
import {PaginationParamsType} from "types/PageType"
import UserCollectionListTableHeader from "./TableHeader";
import {UserCollectionType} from "../../../../types/UserCollectionType";
import UserCollectionListTableRow from "./TableBody/TableRow";

type Props = {
  userCollectionList: UserCollectionType[]
  selectUserCollectionId: (userCollectionId: number) => void
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
}

const UserCollectionListTable = ({ selectUserCollectionId, userCollectionList, paginationParams, changePage }: Props) => {
  return (
    <ListTable>
      <UserCollectionListTableHeader />
      <TableBody>
        {userCollectionList.map((userCollection) => (
          <UserCollectionListTableRow key={userCollection.id} userCollection={userCollection} onClick={() => selectUserCollectionId(userCollection?.id || 0)} />
        ))}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default UserCollectionListTable
