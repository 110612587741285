import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"
import React, { useEffect, useState } from "react"
import { TextField } from "@mui/material"
import Callout from "../../../../../../components/Callout"
import { updateCustomsCode, validateCustomsCode } from "../../../../../../apis/userAPI"
import { toast } from "react-toastify"
import { UserDetailType } from "../../../../../../types/UserType"

type Props = {
  user: UserDetailType
  isOpen: boolean
  setUserDetail: React.Dispatch<React.SetStateAction<UserDetailType>>
  closeModal: () => void
  customsCode: string
}

const CustomsCodeChangeModal = ({ user, isOpen, setUserDetail, closeModal, customsCode }: Props) => {
  const [inputValue, setInputValue] = useState("")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isAbleToSave, setIsAbleToSave] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    if (isOpen) {
      setInputValue(customsCode)
      setErrorMessage("")
      setIsAbleToSave(false)
      setIsValid(false)
    }
  }, [isOpen])

  useEffect(() => {
    setIsValid(false)
    setIsAbleToSave(false)

    if (inputValue.length > 13) {
      setIsValid(false)
      setErrorMessage("통관고유부호는 13자리를 넘을 수 없습니다.")
    } else if (inputValue.length == 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
      setErrorMessage("")
    }
  }, [inputValue])

  const checkCustomsCode = (customsCode: string) => {
    validateCustomsCode(user.id, customsCode)
      .then(() => {
        setIsAbleToSave(true)
        setErrorMessage("")
      })
      .catch((error) => {
        setIsAbleToSave(false)
        setErrorMessage(error.message || error)
      })
  }

  const changeCustomsCode = (customsCode: string) => {
    updateCustomsCode(user.id, customsCode)
      .then(() => {
        setUserDetail((prev) => ({
          ...prev,
          customsCode: customsCode,
        }))
        setIsValid(false)
        setIsAbleToSave(false)
        setErrorMessage("")
        alert(`저장되었습니다.`)
        closeModal()
      })
      .catch((error) => {
        setIsAbleToSave(false)
        setErrorMessage(error.message || error)
      })
  }

  const clickValidate = () => {
    checkCustomsCode(inputValue)
  }

  const clickChange = () => {
    changeCustomsCode(inputValue)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>통관고유부호</h3>
        </ModalContentHeader>
        <ModalContentBody>
          <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
            <TextField
              placeholder={""}
              style={{ width: "80%" }}
              size={"small"}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
            />
            <Button
              buttonStyle={"blue-fill"}
              buttonSize={"M"}
              disabled={!isValid}
              onClick={clickValidate}
              style={{ width: "20%" }}
            >
              확인
            </Button>
          </div>
          {errorMessage && <div style={{ fontSize: "12px", color: "red", margin: "4px" }}>{errorMessage}</div>}
          {isAbleToSave && <div style={{ fontSize: "12px", color: "blue", margin: "4px" }}>확인되었습니다.</div>}
          <Callout type={"none"} title={""}>
            <div style={{ fontSize: "12px" }}>
              * 개인 회원의 경우, 통관고유부호에 등록된 이름, 휴대폰번호와 회원정보의 이름 및 휴대폰번호가 일치해야
              저장할 수 있습니다.
            </div>
            <p></p>
            <div style={{ fontSize: "12px" }}>
              * 사업자 회원의 경우, 통관고유부호에 등록된 사업자등록번호와 회원 정보에 등록된 사업자등록번호가 일치해야
              저장할 수 있습니다.
            </div>
          </Callout>
          <Button buttonStyle={"blue-fill"} disabled={!isAbleToSave} buttonSize={"L"} onClick={clickChange}>
            저장
          </Button>
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default CustomsCodeChangeModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;

  :first-child {
    margin-right: 5px;
  }
`
