import { getOrderHistoryBySerialNumber, getSerialNumberDetail, putSerialNumber } from "apis/serialAPI"
import { useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { OrderHistoryBySerialNumberType, SerialNumberDetailType } from "types/SerialNumberType"

export const useSerialNumberDetail = (serialNumber: string) => {
  const [serialNumberDetail, setSerialNumberDetail] = useState<SerialNumberDetailType>()
  const [orderHistory, setOrderHistory] = useState<OrderHistoryBySerialNumberType[]>()

  const toggleBlackListFlag = async () => {
    if (!serialNumberDetail) return

    try {
      await putSerialNumber({
        serialNo: serialNumberDetail.serialNo,
        detailModel: { id: serialNumberDetail.detailModel.id },
        blacklist: !serialNumberDetail.blacklist,
      })

      await fetchSerialNumberDetail()
      await fetchOrderHistoryBySerialNumber()

      toast.success(!serialNumberDetail.blacklist ? "블랙리스트로 등록했습니다." : "블랙리스트에서 해제했습니다")
    } catch {
      toast.error("블랙리스트 상태 변경중 에러가 발생했습니다.")
    }
  }

  const fetchSerialNumberDetail = () => {
    try {
      getSerialNumberDetail(serialNumber).then((response) => setSerialNumberDetail(response))
    } catch {
      toast.error("상세 내역을 불러올 수 없습니다.")
      setSerialNumberDetail(undefined)
    }
  }

  const fetchOrderHistoryBySerialNumber = () => {
    try {
      getOrderHistoryBySerialNumber(serialNumber).then((response) => setOrderHistory(response))
    } catch {
      toast.error("거래 내역을 불러올 수 없습니다.")
      setOrderHistory(undefined)
    }
  }

  useMount(() => {
    fetchSerialNumberDetail()
    fetchOrderHistoryBySerialNumber()
  })

  return {
    toggleBlackListFlag,
    serialNumberDetail,
    orderHistory,
  }
}
