import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Button,
  DialogActions,
  Typography,
  Autocomplete,
} from "@mui/material"
import { getDetailModels } from "apis/detailModelAPI"
import { updateSellOrderProduct } from "apis/sellOrderAPI"
import { FunctionComponent, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { DetailModelType } from "types/DetailModelType"
import { SellOrderType } from "types/SellOrderType"

export const ConfirmBeforeDiagnosisSellOrderUpdateProductPopup: FunctionComponent<{
  open: boolean
  sellOrderId: SellOrderType["id"]
  productTitle: string
  onClickClose: () => void
  onClickSubmit: () => void
}> = ({ open, sellOrderId, productTitle, onClickClose, onClickSubmit }) => {
  const [productTitleInput, setProductTitleInput] = useState<string>("")
  const [detailModel, setDetailModel] = useState<DetailModelType | null>(null)
  const [detailModels, setDetailModels] = useState<DetailModelType[]>([])

  const fetchDetailModels = (fullRefNo: String) => {
    getDetailModels({ fullRefNo: fullRefNo, onDisplay: true, size: 500 }).then((res) => setDetailModels(res.content))
  }

  const updateProductTitle = async () => {
    if (productTitle === productTitleInput) {
      // 상품명 필드 변경되지 않은 경우 요청하지 않음
      return
    }

    try {
      await updateSellOrderProduct(sellOrderId, { productTitle: productTitleInput })
      toast.success(`상품명을 변경하였습니다`)
    } catch (error: any) {
      toast.error(`상품명 변경 실패 (${error.message || error})`)
    }
  }

  const updateDetailModel = async () => {
    if (!detailModel) {
      return toast.error(`연결할 모델을 선택해주세요`)
    }

    try {
      await updateSellOrderProduct(sellOrderId, { detailModelId: detailModel.id })
      toast.success(`상세모델이 연결되었습니다`)
    } catch (error: any) {
      toast.error(`상세모델 연결 실패 (${error.message || error})`)
    }
  }

  useEffect(() => {
    if (open) {
      setDetailModel(null)
      setProductTitleInput(productTitle)
    }
  }, [productTitle, open])

  return (
    <Dialog open={open} maxWidth={"lg"} onClose={onClickClose}>
      <DialogTitle>진단전판매신청 기타상품 수정</DialogTitle>
      <DialogContent style={{ width: "656px" }}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"column"} spacing={1}>
            <Typography>상품명 변경</Typography>
            <TextField
              placeholder={"ex) Submariner Date 126610LV"}
              value={productTitleInput}
              onChange={(event) => setProductTitleInput(event.target.value)}
              fullWidth
            />
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography>상세모델 연결</Typography>
            <Stack direction={"row"} spacing={1}>
              <Autocomplete
                // disablePortal
                options={detailModels}
                fullWidth
                value={detailModel}
                renderInput={(params) => <TextField {...params} label="레퍼런스 번호로 검색" />}
                getOptionLabel={(detailModel) => {
                  return `${detailModel.title} ${detailModel.titleSecondary} [${detailModel.fullRefNo}]`
                }}
                onInputChange={(event, newValue) => fetchDetailModels(newValue)}
                onChange={async (event: any, detailModel: any) => setDetailModel(detailModel)}
              />
              <Button variant="outlined" color="primary" onClick={() => updateDetailModel()}>
                연결
              </Button>
            </Stack>
            {detailModel && (
              <img
                src={detailModel.thumbnail ?? undefined}
                style={{ height: "218px", width: "218px", objectFit: "contain" }}
              />
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          size={"small"}
          color={"primary"}
          onClick={async () => {
            await updateProductTitle()
            onClickSubmit()
          }}
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  )
}
