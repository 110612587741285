import styled from "@emotion/styled"
import PopupPage from "components/Popup/PopupPage"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import Button from "components/Button"
import React, { useEffect, useMemo, useState } from "react"
import { EventDetailTemplateBodyItemProductTabType, EventType } from "types/EventType"
import { createEvent, deleteEvent, getEventApplies, getEventApplyList, getEventDetail, updateEventDetail } from "apis/eventAPI"
import { FormControlLabel, Radio, RadioGroup, Stack, Switch, TextField } from "@mui/material"
import { HtmlEditor } from "./HtmlEditor"
import { DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { upload } from "apis/resourceAPI"
import { toast } from "react-toastify"
import { EventApplyType } from "types/EventApplyType"
import { EventApplyListTable } from "./EventApplyListTable"
import { PaginationParamsType } from "types/PageType"
import { isNil, isNotNil } from "utils/validationUtils"
import { TemplateEditor } from "./TemplateEditor"
import {
  EventFormStateTemplateBodyItemHeaderImageType,
  EventFormStateTemplateBodyItemImageType,
  EventFormStateTemplateBodyItemNoticeImageType,
  EventFormStateTemplateBodyItemProductTabType,
  EventFormStateType,
} from "types/EventFormStateType"
import { toDateTimeStr } from "utils/datetimeUtil"
import { EventRequestBodyType } from "types/EventRequestBodyType"
import { isEqual } from "lodash"
import { downloadExcel } from "../../../utils/excelUtil";
import { ThemeModeTypeCode, ThemeModeTypeCodeList } from "../../../types/ShopHeroBannerType"
import ImageUploadButton from "../../../components/ImageUploadButton"
import { Typography } from "@viver-inc/viver-ui"

export const initialEventForm: EventFormStateType = {
  title: "",
  title1: "",
  title2: "",
  themeModeType: ThemeModeTypeCode.DARK,
  bannerImageUrl: null,
  shareImageUrl: null,
  onDisplay: true,
  notifiedAt: "",
  startedAt: undefined,
  endedAt: undefined,
  bodyType: "URL",
  url: "",
  body: "",
  templateBodyImage: {
    priority: 0,
    type: "IMAGE",
    images: [{ priority: 0, onDisplay: true, imageUrl: null, imageUrlEnglish: null, imageUrlJapanese:null, apply: false, linkUrl: undefined }],
  },
  templateBodyNoticeImage: {
    priority: 1,
    type: "NOTICE_IMAGE",
    onDisplay: true,
    notice: { openedImageUrl: null,
              openedImageUrlEnglish: null,
              openedImageUrlJapanese: null,
              closedImageUrl: null,
              closedImageUrlEnglish: null,
              closedImageUrlJapanese: null,
              mainImageUrl: null,
              mainImageUrlEnglish: null,
              mainImageUrlJapanese: null
    },
  },
  templateBodyHeaderImage: {
    priority: 2,
    type: "HEADER_IMAGE",
    onDisplay: true,
    header: { imageUrl: null, imageUrlEnglish: null, imageUrlJapanese:null },
  },
  templateBodyProductTab: {
    priority: 3,
    type: "PRODUCT_TAB",
    onDisplay: true,
    products: [{ title: "", curationType: "수동", products: [] }],
  },
}

const EVENT_APPLY_LIST_SIZE = 50
const initialEventApplyList: EventApplyType[] = []
const initialEventApplyListTablePaginationParams = {
  number: 0,
  totalElements: 0,
  totalPages: 1,
  first: true,
  size: EVENT_APPLY_LIST_SIZE,
}

const formTypeOptionList: { label: string; value: EventType["bodyType"] }[] = [
  { label: "URL", value: "URL" },
  // { label: "HTML", value: "HTML" },
  { label: "TEMPLATE", value: "TEMPLATE" },
]

type Props = {
  isOpen: boolean
  selectedEventId: number | undefined
  closePopup: () => void
}

const MAX_TITLE_LENGTH = 100

export const EventEditorPopup = ({ isOpen, selectedEventId, closePopup }: Props) => {
  const [prevEventFormState, setPrevEventFormState] = useState<EventFormStateType | null>(null)
  const [eventFormState, setEventFormState] = useState<EventFormStateType>(initialEventForm)
  const [eventApplyList, setEventApplyList] = useState<EventApplyType[]>(initialEventApplyList)
  const [eventApplyListTablePaginationParams, setEventApplyListTablePaginationParams] = useState<PaginationParamsType>(
    initialEventApplyListTablePaginationParams
  )

  useEffect(() => {
    if (!selectedEventId) {
      setPrevEventFormState(initialEventForm)
      setEventFormState(initialEventForm)
      setEventApplyList(initialEventApplyList)
      setEventApplyListTablePaginationParams(initialEventApplyListTablePaginationParams)
      return
    }

    getEventDetail(selectedEventId).then((event) => {
      if (event.bodyType === "URL") {
        const nextEventFormState = {
          ...initialEventForm,
          title: event.title,
          title1: event.title1,
          title2: event.title2,
          themeModeType: event.themeModeType,
          bannerImageUrl: event.bannerImageUrl,
          shareImageUrl: event.shareImageUrl,
          onDisplay: event.onDisplay,
          notifiedAt: event.notifiedAt,
          startedAt: event.startedAt,
          endedAt: event.endedAt,
          bodyType: event.bodyType,
          url: event.url,
        }
        setPrevEventFormState(nextEventFormState)
        setEventFormState(nextEventFormState)
      }
      if (event.bodyType === "HTML") {
        const nextEventFormState = {
          ...initialEventForm,
          title: event.title,
          title1: event.title1,
          title2: event.title2,
          themeModeType: event.themeModeType,
          bannerImageUrl: event.bannerImageUrl,
          shareImageUrl: event.shareImageUrl,
          onDisplay: event.onDisplay,
          notifiedAt: event.notifiedAt,
          startedAt: event.startedAt,
          endedAt: event.endedAt,
          bodyType: event.bodyType,
          body: event.body,
        }
        setPrevEventFormState(nextEventFormState)
        setEventFormState(nextEventFormState)
      }
      if (event.bodyType === "TEMPLATE") {
        const templateBodyImage =
          (event.templateBody.find((item) => item.type === "IMAGE") as EventFormStateTemplateBodyItemImageType) ??
          initialEventForm.templateBodyImage
        const templateBodyNoticeImage =
          (event.templateBody.find(
            (item) => item.type === "NOTICE_IMAGE"
          ) as EventFormStateTemplateBodyItemNoticeImageType) ?? initialEventForm.templateBodyNoticeImage
        const templateBodyHeaderImage =
          (event.templateBody.find(
            (item) => item.type === "HEADER_IMAGE"
          ) as EventFormStateTemplateBodyItemHeaderImageType) ?? initialEventForm.templateBodyHeaderImage

        const originalTemplateBodyProductTab = event.templateBody.find(
          (item) => item.type === "PRODUCT_TAB"
        ) as EventDetailTemplateBodyItemProductTabType
        const templateBodyProductTab: EventFormStateTemplateBodyItemProductTabType = originalTemplateBodyProductTab
          ? {
            ...originalTemplateBodyProductTab,
            products: (originalTemplateBodyProductTab.products ?? []).map((productTab) => ({
              ...productTab,
              products: productTab.products.map(({ product }) => ({ productId: product.id })),
            })),
          }
          : initialEventForm.templateBodyProductTab

        const nextEventFormState = {
          ...initialEventForm,
          title: event.title,
          title1: event.title1,
          title2: event.title2,
          themeModeType: event.themeModeType,
          bannerImageUrl: event.bannerImageUrl,
          shareImageUrl: event.shareImageUrl,
          onDisplay: event.onDisplay,
          notifiedAt: event.notifiedAt,
          startedAt: event.startedAt,
          endedAt: event.endedAt,
          bodyType: event.bodyType,
          templateBodyImage,
          templateBodyNoticeImage,
          templateBodyHeaderImage,
          templateBodyProductTab,
        }
        setPrevEventFormState(nextEventFormState)
        setEventFormState(nextEventFormState)
      }
    })

    getEventApplies(selectedEventId, {
      page: initialEventApplyListTablePaginationParams.number,
      size: initialEventApplyListTablePaginationParams.size,
    }).then((eventApplyList) => {
      setEventApplyList(eventApplyList.content)
      setEventApplyListTablePaginationParams((paginationParams) => ({
        ...paginationParams,
        number: eventApplyList.number ?? 0,
        totalElements: eventApplyList.totalElements ?? 0,
        totalPages: eventApplyList.totalPages ?? 1,
        first: eventApplyList.first,
        size: eventApplyList.size ?? paginationParams.size,
      }))
    })
  }, [selectedEventId])

  const formByBodyType = useMemo<EventRequestBodyType | null>(() => {
    if (eventFormState.bodyType === "URL") {
      return {
        title: eventFormState.title,
        title1: eventFormState.title1,
        title2: eventFormState.title2,
        themeModeType: eventFormState.themeModeType,
        bannerImageUrl: eventFormState.bannerImageUrl,
        shareImageUrl: eventFormState.shareImageUrl,
        onDisplay: eventFormState.onDisplay,
        notifiedAt: eventFormState.notifiedAt,
        startedAt: eventFormState.startedAt,
        endedAt: eventFormState.endedAt,
        bodyType: "URL",
        url: eventFormState.url,
      }
    }
    if (eventFormState.bodyType === "HTML") {
      return {
        title: eventFormState.title,
        title1: eventFormState.title1,
        title2: eventFormState.title2,
        themeModeType: eventFormState.themeModeType,
        bannerImageUrl: eventFormState.bannerImageUrl,
        shareImageUrl: eventFormState.shareImageUrl,
        onDisplay: eventFormState.onDisplay,
        notifiedAt: eventFormState.notifiedAt,
        startedAt: eventFormState.startedAt,
        endedAt: eventFormState.endedAt,
        bodyType: "HTML",
        body: eventFormState.body,
      }
    }
    if (eventFormState.bodyType === "TEMPLATE") {
      return {
        title: eventFormState.title,
        title1: eventFormState.title1,
        title2: eventFormState.title2,
        themeModeType: eventFormState.themeModeType,
        bannerImageUrl: eventFormState.bannerImageUrl,
        shareImageUrl: eventFormState.shareImageUrl,
        onDisplay: eventFormState.onDisplay,
        notifiedAt: eventFormState.notifiedAt,
        startedAt: eventFormState.startedAt,
        endedAt: eventFormState.endedAt,
        bodyType: "TEMPLATE",
        templateBody: [
          eventFormState.templateBodyImage,
          eventFormState.templateBodyNoticeImage,
          eventFormState.templateBodyHeaderImage,
          eventFormState.templateBodyProductTab,
        ],
      }
    }

    return null
  }, [eventFormState])

  const isSavalble = useMemo(() => {
    if (isEqual(prevEventFormState, eventFormState)) {
      return false
    }

    if (
      !eventFormState.title ||
      !eventFormState.bodyType ||
      !eventFormState.bannerImageUrl ||
      !eventFormState.notifiedAt
    ) {
      return false
    }

    if (eventFormState.bodyType === "URL" && !eventFormState.url) {
      return false
    }
    if (eventFormState.bodyType === "HTML" && !eventFormState.body) {
      return false
    }

    return true
  }, [eventFormState, prevEventFormState])

  const handleChangeEventTitle = (title: string) => {
    if (title.length > MAX_TITLE_LENGTH) {
      return
    }

    setEventFormState((prev) => ({ ...prev, title }))
  }

  const handleUploadBannerImage = (fileList: FileList | null) => {
    if (!fileList) {
      return
    }

    upload(fileList)
      .then(({ url }) => {
        setEventFormState((prev) => ({ ...prev, bannerImageUrl: url }))
      })
      .catch(() => toast.error("이미지 업로드에 실패했습니다."))
  }

  const handleUploadShareImage = (fileList: FileList | null) => {
    if (!fileList) {
      return
    }

    upload(fileList)
      .then(({ url }) => {
        setEventFormState((prev) => ({ ...prev, shareImageUrl: url }))
      })
      .catch(() => toast.error("이미지 업로드에 실패했습니다."))
  }

  const handleSaveForm = () => {
    if (isNil(formByBodyType)) {
      toast.error("정의되지 않은 이벤트 타입입니다.")
      return
    }

    if (selectedEventId) {
      updateEventDetail(formByBodyType, selectedEventId)
        .then(() => {
          setPrevEventFormState(eventFormState)
          toast.success("이벤트 수정이 완료되었습니다.")
        })
        .catch((error) => toast.error(`이벤트 수정에 실패했습니다 (${error.message || error})`))
    } else {
      createEvent(formByBodyType)
        .then((res) => {
          toast.success("이벤트가 생성되었습니다.")
          closePopup()
        })
        .catch((error) => toast.error(`이벤트 생성에 실패했습니다 (${error.message || error})`))
    }
  }

  const handleDeleteEvent = (selectedEventId: number) => {
    if (window.confirm("해당 이벤트를 삭제하시겠습니까?"))
      deleteEvent(selectedEventId)
        .then(() => {
          closePopup()
        })
        .catch(() => toast.error("이벤트 삭제에 실패했습니다."))
  }

  const handleChangePageEventApplyListTable = (nextPage: number) => {
    if (isNil(selectedEventId)) {
      return
    }

    setEventApplyListTablePaginationParams((paginationParams) => ({ ...paginationParams, number: nextPage }))
    getEventApplies(selectedEventId, { page: nextPage - 1, size: EVENT_APPLY_LIST_SIZE }).then((eventApplyList) => {
      setEventApplyList(eventApplyList.content)
      setEventApplyListTablePaginationParams((paginationParams) => ({
        ...paginationParams,
        number: eventApplyList.number ?? 0,
        totalElements: eventApplyList.totalElements ?? 0,
        totalPages: eventApplyList.totalPages ?? 1,
        first: eventApplyList.first,
        size: eventApplyList.size ?? paginationParams.size,
      }))
    })
  }


  const handleExcelDownloadClick = () => {
    if(selectedEventId) {
      getEventApplyList(selectedEventId)
      .then(eventApplyList => {
        let data = eventApplyList.map(it => {
          return {
            "회원ID": it.user.id,
            "Email": it.user.email,
            "이름": it.user.name,
            "연락처": it.user.phone,
            "이벤트 응모 추가 데이터": JSON.stringify(it.extras),
            "이벤트 응모시간": toDateTimeStr(it.createdAt),
          }
        })
        downloadExcel(data, "이벤트참여자")
      })

    }
  }

  return (
    <PopupPage isOpen={isOpen}>
      <ContentWrapper>
        <PopupPageTitle>이벤트 수정</PopupPageTitle>
        <PopupPageScrollContainer>
          <FormWrapper>
            <FormItemInlineWrapper>
            <FormLabel>
              제목 <b>*</b>
            </FormLabel>
            <FormContent>
              <InlineWrapper>
                <Input value={eventFormState.title} onChange={(e) => handleChangeEventTitle(e.target.value)}  placeholder={'이벤트 제목을 작성해 주세요 (서비스에 노출되지 않습니다.)'} />
                <InputValueCount>
                  {eventFormState.title.length}/{MAX_TITLE_LENGTH}
                </InputValueCount>
              </InlineWrapper>
            </FormContent>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>
              배너 이미지 <b>*</b>
            </FormLabel>
            <FormContent>
              <ColumnWrapper>
                <ImageUploadButton height={'150px'} width={'150px'} buttonHeight={'20px'} handleImage={handleUploadBannerImage} imageUrl={eventFormState.bannerImageUrl}  handleDeleteImage={() => setEventFormState((prev) => ({ ...prev, bannerImageUrl: null }))}/>
              </ColumnWrapper>
            </FormContent>
            <FormLabel>
              공유 이미지
            </FormLabel>
            <FormContent>
              <ColumnWrapper>
                <Stack direction={"row"} spacing={1} style={{alignItems : "flex-end"}}>
                  <ImageUploadButton height={'150px'} width={'150px'} buttonHeight={'20px'} handleImage={handleUploadShareImage} imageUrl={eventFormState.shareImageUrl}  handleDeleteImage={() => setEventFormState((prev) => ({ ...prev, shareImageUrl: null }))}/>
                  <Typography variant={'desc14'} weight={'m'}>권장 이미지 크기 : 1200 x 630 px<br/>이미지가 없을 경우, VIVER 기본 이미지로 공유됩니다.</Typography>
                </Stack>
              </ColumnWrapper>
            </FormContent>
            <FormLabel>
            </FormLabel>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>
              타입 <b>*</b>
            </FormLabel>
            <FormContent>
              <ColumnWrapper>
                <Stack direction={"column"} spacing={1}>
                  <RadioGroup row sx={{ justifyContent: "center" }}
                              onChange={(event, value) => {
                                // @ts-ignore
                                setEventFormState((prev) => ({...prev, themeModeType: value}))
                              }}
                  >
                    {ThemeModeTypeCodeList.map((item) => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        label={item.title}
                        checked={eventFormState.themeModeType === item.value}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </Stack>
              </ColumnWrapper>
            </FormContent>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>
              텍스트
            </FormLabel>
            <FormContent>
              <RowWrapper>
                <TextField size={'small'} fullWidth  value={eventFormState.title1} inputProps={{maxLength:50}} onChange={(e) => setEventFormState((prev) => ({...prev, title1: e.target.value}))} placeholder={'첫째줄 소문구'}/>
                <TextField size={'small'} fullWidth  value={eventFormState.title2} inputProps={{maxLength:50}} onChange={(e) => setEventFormState((prev) => ({...prev, title2: e.target.value}))} placeholder={'둘째줄 대문구'}/>
              </RowWrapper>
            </FormContent>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>
              노출 여부 <b>*</b>
            </FormLabel>
            <FormContent>
              <Switch
                checked={eventFormState.onDisplay}
                onChange={(e, check) => {
                  setEventFormState((prev) => ({ ...prev, onDisplay: check }))
                }}
              />
            </FormContent>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>
              고객 노출 일시 <b>*</b>
            </FormLabel>
            <FormContent>
              <DateTimePicker
                PopperProps={{
                  style: { zIndex: 10000 },
                }}
                label={"시작"}
                onChange={(e) => {
                  setEventFormState((prev) => ({
                    ...prev,
                    notifiedAt: e?.toISO() || "",
                  }))
                }}
                value={(eventFormState.notifiedAt && DateTime.fromISO(eventFormState.notifiedAt)) || null}
                inputFormat={"yyyy-MM-dd HH:mm"}
                renderInput={(props) => <TextField size={"small"} sx={{ width: "210px" }} {...props} />}
              />
            </FormContent>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>진행 기간</FormLabel>
            <FormContent>
              <InlineWrapper>
                <DateTimePicker
                  PopperProps={{
                    style: { zIndex: 10000 },
                  }}
                  label={"시작"}
                  onChange={(e) => {
                    setEventFormState((prev) => ({
                      ...prev,
                      startedAt: e?.toISO(),
                    }))
                  }}
                  value={(eventFormState.startedAt && DateTime.fromISO(eventFormState.startedAt)) || null}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                  renderInput={(props) => (
                    <TextField size={"small"} sx={{ width: "210px" }} {...props} required={false} />
                  )}
                />
                <div style={{ margin: "0 15px" }}> ~ </div>
                <DateTimePicker
                  PopperProps={{
                    style: { zIndex: 10000 },
                  }}
                  label={"종료"}
                  onChange={(e) => {
                    setEventFormState((prev) => ({
                      ...prev,
                      endedAt: e?.toISO(),
                    }))
                  }}
                  value={(eventFormState.endedAt && DateTime.fromISO(eventFormState.endedAt)) || null}
                  inputFormat={"yyyy-MM-dd HH:mm"}
                  renderInput={(props) => (
                    <TextField size={"small"} sx={{ width: "210px" }} required={false} {...props} />
                  )}
                />
              </InlineWrapper>
            </FormContent>
          </FormItemInlineWrapper>
          <FormItemInlineWrapper>
            <FormLabel>
              이벤트 참여 회원
            </FormLabel>
            <FormContent>
              <ButtonWrapper>
                <Button buttonSize="M" buttonStyle="blue-fill" onClick={handleExcelDownloadClick}>엑셀 다운로드</Button>
              </ButtonWrapper>
              <EventApplyListTable
                eventApplyList={eventApplyList}
                paginationParams={eventApplyListTablePaginationParams}
                changePage={handleChangePageEventApplyListTable}
              />
            </FormContent>
          </FormItemInlineWrapper>

          <FormItemInlineWrapper>
            <FormLabel>
              본문 형식 <b>*</b>
            </FormLabel>
            <FormContent>
              <InlineWrapper style={{ width: "fit-content" }}>
                {formTypeOptionList.map(({ label, value }) => {
                  return (
                    <InlineWrapper
                      key={label}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        if (isNil(selectedEventId)) {
                          setEventFormState((prev) => ({ ...prev, bodyType: value }))
                        }
                      }}
                    >
                      <label htmlFor={value}>{label}</label>
                      <Radio
                        name="formType"
                        value={value}
                        checked={eventFormState.bodyType === value}
                        disabled={isNotNil(selectedEventId)}
                      />
                    </InlineWrapper>
                  )
                })}
              </InlineWrapper>
            </FormContent>
          </FormItemInlineWrapper>
          {eventFormState.bodyType === "URL" && (
            <FormItemInlineWrapper>
              <FormLabel>
                본문 URL <b>*</b>
              </FormLabel>
              <FormContent>
                <Input
                  value={eventFormState.url}
                  onChange={(e) => setEventFormState((prev) => ({ ...prev, url: e.target.value }))}
                />
              </FormContent>
            </FormItemInlineWrapper>
          )}
          {eventFormState.bodyType === "HTML" && (
            <FormItemInlineWrapper>
              <FormLabel>
                본문 HTML <b>*</b>
              </FormLabel>
              <FormContent>
                <HtmlEditor
                  body={eventFormState.body || ""}
                  changeHtmlBody={(body) => setEventFormState((prev) => ({ ...prev, body }))}
                />
              </FormContent>
            </FormItemInlineWrapper>
          )}
          {eventFormState.bodyType === "TEMPLATE" && (
            <FormItemInlineTopWrapper>
              <FormLabel>
                본문 <b>*</b>
              </FormLabel>
              <FormContent>
                <TemplateEditor eventFormState={eventFormState} setEventFormState={setEventFormState} />
              </FormContent>
            </FormItemInlineTopWrapper>
          )}`
          </FormWrapper>
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <AlignWrapper>
            <ButtonWrapper style={{ marginRight: "10px" }}>
              <Button disabled={!isSavalble} onClick={handleSaveForm}>
                저장
              </Button>
            </ButtonWrapper>
            {selectedEventId && (
              <ButtonWrapper style={{ marginRight: "10px" }}>
                <Button buttonStyle={"red-fill"} onClick={() => handleDeleteEvent(selectedEventId)}>
                  삭제
                </Button>
              </ButtonWrapper>
            )}
            <ButtonWrapper>
              <Button buttonStyle="blue-border" onClick={closePopup}>
                닫기
              </Button>
            </ButtonWrapper>
          </AlignWrapper>
        </PopupPageFooter>
      </ContentWrapper>
    </PopupPage>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`

const AlignWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const ButtonWrapper = styled.div`
  width: 150px;
`

const FormWrapper = styled.div`
  margin-bottom: 15px;
`

const FormItemInlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;

  :not(:last-child) {
    margin-bottom: 25px;
  }
`

const FormItemInlineTopWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  padding: 0 15px;

  :not(:last-child) {
    margin-bottom: 25px;
  }
`

const FormLabel = styled.div`
  min-width: 150px;
  font-size: 18px;

  b {
    color: red;
  }
`

const FormContent = styled.div`
  flex: 1;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex; 
  justify-content: flex-start;
`
const RowWrapper = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: column;  
    gap: 16px;  
`

const InputValueCount = styled.div`
  width: 10%;
`

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  outline: none;
  height: 40px;
  padding: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  margin-right: 10px;
`

const BannerThumnailImage = styled.img`
  width: 300px;
  margin-top: 10px;
`
