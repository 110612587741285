import styled from "@emotion/styled"
import React, { useCallback, useState } from "react"
import { OfficialModelPriceType } from "types/OfficialModelPriceType"
import { applyOfficialModelPriceList, deleteOfficialModelPriceList, downloadOfficialModelPriceList, getOfficialModelPriceList, uploadOfficialModelPriceList } from "apis/officialModelPriceAPI"
import { OfficialPriceSearchBar } from "./SearchBar"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { toast } from "react-toastify"
import { Button, Link } from "@mui/material"
import OfficialPriceTable from "./OfficialPriceTable"

const initSearchParams = {
  brandSid: "ALL",
  searchFilter: "fullRefNo",
  searchWord: "",
  detailModelExists: "ALL",
  metricsMonth: "ALL",
  applied: "ALL",
  selectedIds: [],
}

const OfficialPriceTab = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: 25,
  })

  const [uploading, setUploading] = useState<boolean>(false)

  const [downloading, setDownloading] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useState<any>(initSearchParams)

  const [officialPriceList, setOfficialPriceList] = useState<OfficialModelPriceType[]>([])

  let selectedIds: number[] = []

  const changeBrandSid = useCallback((brandSid: any) => {
    setSearchParams((prev: any) => ({ ...prev, brandSid: brandSid }))
  }, [])

  const changeSearchFilter = useCallback((searchFilter: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchFilter: searchFilter }))
  }, [])

  const changeSearchWord = useCallback((searchWord: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchWord: searchWord }))
  }, [])

  const changeDetailModelExists = useCallback((detailModelExists: any) => {
    setSearchParams((prev: any) => ({ ...prev, detailModelExists: detailModelExists }))
  }, [])

  const changeApplied = useCallback((applied: any) => {
    setSearchParams((prev: any) => ({ ...prev, applied: applied }))
  }, [])

  const changeMetricsMonth = useCallback((metricsMonth: any) => {
    setSearchParams((prev: any) => ({ ...prev, metricsMonth: metricsMonth }))
  }, [])


  const parseSearchParams = useCallback((searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      "brandSid": searchParams.brandSid === "ALL" ? null : searchParams.brandSid,
      "detailModelExists": searchParams.detailModelExists === "ALL" ? null : searchParams.detailModelExists,
      "metricsMonth": searchParams.metricsMonth === "ALL" ? null : searchParams.metricsMonth,
      "applied": searchParams.applied === "ALL" ? null : searchParams.applied,
      [searchParams.searchFilter]: searchParams.searchWord,
    }
  }, [])


  function fetchList({ pageNumber, pageSize, searchParams }: { pageNumber?: number; pageSize?: number; searchParams?: any; }) {
    getOfficialModelPriceList({
      size: pageSize ?? paginationParams.size,
      page: pageNumber,
      ...parseSearchParams(searchParams),
    })
      .then(({ content, totalElements = 0, totalPages = 0, first, size = pageSize ?? 25, number = 0 }) => {
        setOfficialPriceList(content.map((listItem) => ({ ...listItem, isChecked: false })))
        setPaginationParams((prev) => ({
          ...prev,
          number,
          totalElements,
          totalPages,
          first,
          size,
        }))
        setSearchParams((prev: any) => ({ ...prev, selectedIds: [] }))
      })
      .catch(() => {
        toast.error("공식 출시가 수집 내역을 불러오는데 실패했습니다.")
      })
  }

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchList({
        pageNumber: pageNumber,
        searchParams: searchParams,
      })
    },
    [paginationParams],
  )

  const onChangePageSize = useCallback((pageSize: number) => {
      setPaginationParams((prev) => ({ ...prev, size: pageSize }))
      fetchList({
        pageNumber: 0,
        pageSize: pageSize,
        searchParams: searchParams,
      })
    },
    [paginationParams],
  )


  const onSearch = useCallback(() => {
      fetchList({
        pageNumber: 0,
        searchParams: searchParams,
      })
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchList])

  const onSelectionModelChange = (ids: any[]) => {
    setSearchParams((prev: any) => ({ ...prev, selectedIds: ids }))
  }

  const applyOfficialPrice = () => {
    applyOfficialModelPriceList({ idList: searchParams.selectedIds }).then(({ successCount, failedCount }) => {
      toast.info(`공식출시가 ${successCount}건 반영 했습니다.`)
      setSearchParams(initSearchParams)
      fetchList({
        pageNumber: 0,
        searchParams: searchParams,
      })
    }).catch(() => {
      toast.error("공식출시가 반영 실패 했습니다.")
    })
  }

  const deleteOfficialPrice = () => {
    deleteOfficialModelPriceList({ idList: searchParams.selectedIds }).then(() => {
      setSearchParams(initSearchParams)
      fetchList({
        pageNumber: 0,
        searchParams: searchParams,
      })
    })
  }

  const onUploadExcel = async (file: FileList) => {
    if (uploading) {
      toast.info("공식 출시가 수집 내역을 업로드 중입니다.")
      return
    }
    setUploading(true)
    uploadOfficialModelPriceList(file).then(({ successCount, failedCount }) => {
      toast.info(`엑셀 업로드 ${successCount}건 성공했습니다.`)
      setSearchParams(initSearchParams)
      fetchList({
        pageNumber: 0,
        searchParams: searchParams,
      })
      setUploading(false)
    }).catch(() => {
      toast.error("공식 출시가 수집 내역을 업로드 하지 못했습니다.")
      setUploading(false)
    })
  }

  const onDownloadExcel = async () => {
    if (downloading) {
      toast.info("공식 출시가 수집 내역을 다운로드 중입니다.")
      return
    }

    setDownloading(true)
    try {
      const blob = await downloadOfficialModelPriceList({ ...parseSearchParams(searchParams) })
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `official_price_list.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      toast.error("공식 출시가 수집 내역을 다운로드 하지 못했습니다.")
    }
    setDownloading(false)
  }

  useMount(() => {
      fetchList({})
  })

  return (
    <PageBodyWrapper>
      <SearchBarWrapper>
        <OfficialPriceSearchBar
          changeBrandSid={changeBrandSid}
          changeSearchFilter={changeSearchFilter}
          changeSearchWord={changeSearchWord}
          changeDetailModelExists={changeDetailModelExists}
          changeMetricsMonth={changeMetricsMonth}
          changeApplied={changeApplied}
          onSearch={onSearch}
          searchParams={searchParams}
        />
      </SearchBarWrapper>
      <SearchBarWrapper>
        <Link href={"https://d38hzphyq6kkhx.cloudfront.net/resources/admin_files/offical_price_sample_240827.xlsx"} style={{ marginRight: "10px" }}>
          샘플파일
        </Link>

        <label htmlFor={`contained-button-file`} style={{ marginRight: "10px" }}>
          <input
            id={`contained-button-file`}
            style={{ display: "none" }}
            type="file"
            onChange={(params) => {
              if (params?.target?.files != null) {
                onUploadExcel(params?.target?.files)
              }
            }}
          />
          <Button
            fullWidth
            component="span"
            variant={uploading ? "contained" : "outlined"}
            color={"error"}>
            {uploading ? "엑셀 업로드 중" : "엑셀 업로드"}
          </Button>
        </label>
        <Button
          color={downloading ? "warning" : "primary"}
          variant={downloading ? "contained" : "outlined"}
          style={{ marginRight: "10px" }}
          onClick={onDownloadExcel}>
          {downloading ? "다운로드 중" : "엑셀 다운로드"}
        </Button>
        <Button
          color={"error"}
          variant={"outlined"}
          style={{ marginRight: "10px" }}
          onClick={deleteOfficialPrice}>
          선택 삭제
        </Button>
        <Button
          variant={"outlined"}
          style={{ marginRight: "10px" }}
          onClick={applyOfficialPrice}>
          공식출시가 반영
        </Button>
      </SearchBarWrapper>
      <OfficialPriceTable
        rows={officialPriceList ?? []}
        onPageChange={onPageChange}
        onPageSizeChange={onChangePageSize}
        onSelectionModelChange={onSelectionModelChange}
        page={paginationParams.number}
        size={paginationParams.size}
        totalCount={paginationParams.totalElements ?? 0}
        selectedIds={searchParams.selectedIds}
      />
    </PageBodyWrapper>
  )
}

const PageBodyWrapper = styled.div`
    width: 100%;
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
`

const SearchBarWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 28px;
`
export default OfficialPriceTab