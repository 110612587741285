import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import { FunctionComponent } from "react"

export const NoticeListTableHeader: FunctionComponent<{ columnWidths: (string | number | undefined)[] }> = ({
  columnWidths,
}) => {
  return (
    <ListTableHeader>
      <ListTableHeaderItem style={{ maxWidth: columnWidths[0] }}>공지ID</ListTableHeaderItem>
      <ListTableHeaderItem style={{ maxWidth: columnWidths[1] }}>제목</ListTableHeaderItem>
      <ListTableHeaderItem style={{ maxWidth: columnWidths[2] }}>게시 날짜</ListTableHeaderItem>
      <ListTableHeaderItem style={{ maxWidth: columnWidths[3] }}>게시 시작일시</ListTableHeaderItem>
      <ListTableHeaderItem style={{ maxWidth: columnWidths[4] }}>게시 종료일시</ListTableHeaderItem>
      <ListTableHeaderItem style={{ maxWidth: columnWidths[5] }}>게시상태</ListTableHeaderItem>
    </ListTableHeader>
  )
}
