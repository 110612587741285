import {
  AdminAuthorityType,
  AdminGroupType,
  AdminUserListSearchParams,
  AdminUserType,
  UpdateAdminGroupType,
  UpdateAdminUserType,
} from "../types/AdminUserType"
import { httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { PageType } from "../types/PageType"
import { AppVersion, PlatformTypeCode } from "../types/AppVersionType"
import { AdminUserDetailState } from "../pages/AdminUser/AdminUserList/AdminUserDetailPopup"

export const findAdminGroupList: () => Promise<PageType<AdminGroupType>> = () => httpGet("/admin/group")
export const findAdminGroup: (id: number) => Promise<AdminGroupType> = (id: number) => httpGet(`/admin/group/${id}`)

export const findAdminMenuCodes: () => Promise<string> = () => httpGet("/admin/group/menus")
export const findAdminGroupCodes: () => Promise<AdminGroupType[]> = () => httpGet("/admin/group/codes")
export const findAdminAuthorities: () => Promise<AdminAuthorityType[]> = () => httpGet("/admin/group/authorities")

export const updateAdminGroup: (id: number, dto: UpdateAdminGroupType) => Promise<void> = (
  id: number,
  dto: UpdateAdminGroupType
) => httpPost(`/admin/group/${id}`, dto)
