import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ButtonHTMLAttributes, PropsWithChildren } from "react"

type ButtonStyleType = "red-fill" | "blue-fill" | "red-border" | "blue-border" | "black-border"
type ButtonSizeType = "S" | "M" | "L"

type Props = {
  buttonStyle?: ButtonStyleType
  buttonSize?: ButtonSizeType
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({ buttonSize = "L", buttonStyle = "blue-fill", children, ...props }: PropsWithChildren<Props>) => {
  return (
    <CustomButton buttonStyle={buttonStyle} buttonSize={buttonSize} {...props}>
      {children}
    </CustomButton>
  )
}

export default Button

const CustomButton = styled.button<{ buttonStyle: ButtonStyleType; buttonSize: ButtonSizeType }>`
  width: 100%;
  flex: 1;
  border-radius: 4px;
  outline: none;
  border: none;
  background: white;
  cursor: pointer;

  ${({ buttonStyle }) => {
    switch (buttonStyle) {
      case "red-fill":
        return css`
          background-color: #d32f2f;
          border: 1px solid #d32f2f;
          color: #fff;
        `
      case "blue-fill":
        return css`
          background-color: #1976d2;
          border: 1px solid #1976d2;

          color: #fff;
        `
      case "red-border":
        return css`
          border: 1px solid #d32f2f;
          color: #d32f2f;
        `
      case "blue-border":
        return css`
          border: 1px solid #1976d2;
          color: #1976d2;
        `
      case "black-border":
        return css`
          border: 1px solid #000;
          color: #000;
        `
    }
  }}

  ${({ buttonSize }) => {
    switch (buttonSize) {
      case "S":
        return css`
          padding: 4px;
          font-size: 12px;
        `

      case "M":
        return css`
          padding: 5px;
          font-size: 14px;
        `

      case "L":
        return css`
          padding: 10px;
          font-size: 16px;
          font-weight: 600;
        `
    }
  }}

  :disabled {
    background: rgba(224, 224, 224, 1);
    border: 1px solid rgba(224, 224, 224, 1);
    color: rgba(0, 0, 0, 0.26);
  }
`
