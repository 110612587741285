import { toast } from "react-toastify"
import styled from "@emotion/styled"
import { findUserByCsv } from "apis/userAPI"

type Props = {
  changeState: (key: string, value: any) => void
}

const CsvFileUploader = ({ changeState }: Props) => {
  const handleSubmitCsv = (fileList: FileList) => {
    if (!fileList) {
      return
    }

    findUserByCsv(fileList)
      .then((res) => {
        changeState(
          "userIds",
          res.map(({ id }) => id)
        )
      })
      .catch((e) => toast.error(e.message))
  }

  return (
    <OptionWrapper>
      <h3>CSV 파일 선택</h3>
      <InlineWrapper>
        <FileUploder
          type={"file"}
          accept=".csv"
          onChange={(e) => {
            if (e.target.files) {
              handleSubmitCsv(e.target.files)
            }
          }}
        />
      </InlineWrapper>
    </OptionWrapper>
  )
}

export default CsvFileUploader

const FileUploder = styled.input`
  flex: 1;
  height: 40px;
  padding: 5px 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h3 {
    margin: 5px 0;
    font-size: 16px;
  }
`
