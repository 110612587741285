import styled from "@emotion/styled"
import { ProductTrackingLogSearchBar } from "./ProductTrackingLogListSearchBar"
import { ProductTrackingLogListTable } from "./ProductTrackingLogListTable"
import { useProductTrackingLogList } from "./useProductTrackingLogList"

export const ProductTrackingLogListScreen = () => {
  const {
    paginationParams,
    searchParams,
    productTrackingLogList,
    changePageNumber,
    onSearch,
    changeSearchFilterValue,
    changeSearchKeyword,
  } = useProductTrackingLogList()

  return (
    <>
      <PageHeaderWrapper>
        <ProductTrackingLogSearchBar
          searchParams={searchParams}
          changeSearchKeyword={changeSearchKeyword}
          changeSearchFilterValue={changeSearchFilterValue}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <ProductTrackingLogListTable
          logList={productTrackingLogList}
          paginationParams={paginationParams}
          changePage={changePageNumber}
        />
      </PageBodyWrapper>
    </>
  )
}

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const PageBodyWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`
