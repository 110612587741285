import React, {useEffect, useState} from "react";
import {Box, Stack, Switch} from "@mui/material";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import {PageType} from "../../../types/PageType";
import {SellOrderReviewRecommendType, SellOrderReviewType} from "../../../types/SellOrderReviewType";
import {DateTime} from "luxon";
import SellOrderReviewAPI from "../../../apis/sellOrderReviewAPI";
import SellOrderReviewTableFilter from "./SellOrderReviewTableFilter";
import {
    initSellOrderReviewTableFilter,
    SellOrderReviewTableFilterState
} from "../OrderReviewTableFilter";
import RenderCellExpand from "../RenderCellExpand";

// @ts-ignore
const SellOrderReviewTable = ({handleSelection, handleUpdateSellOrderReview, refreshCounter}) => {
    const [tableFilter, setTableFilter] = useState<SellOrderReviewTableFilterState>(initSellOrderReviewTableFilter)
    const [sellOrderReviews, setSellOrderReviews] = useState<PageType<SellOrderReviewType>>({content: []})
    const [rows, setRows] = useState<SellOrderReviewType[]>([])
    const [recommendReview, setRecommendReview] = useState<SellOrderReviewRecommendType>()

    const getColumns = (handleDisplaySwitchToggle: Function) => {
        return [
            {field: 'brandSid', headerName: "브랜드", width: 100, valueGetter: (params: any) => `${params.row.product?.brandSid}`},
            {field: 'fullRefNo', headerName: "Full Ref No.", width: 100, valueGetter: (params: any) => `${params.row.product?.fullRefNo}`, renderCell: (params: GridRenderCellParams) => (<RenderCellExpand {...params} />)},
            {field: 'productId', headerName: "상품번호", width: 70, valueGetter: (params: any) => `${params.row.product?.id}`},
            {field: 'id', headerName: "주문번호", width: 100, renderCell: (params: GridRenderCellParams) => (<RenderCellExpand {...params} />)},
            {field: 'buyerId', headerName: "구매자ID", width: 70, valueGetter: (params: any) => `${params.row.buyer?.id}`},
            {field: 'buyerName', headerName: "구매자명", width: 100, valueGetter: (params: any) => `${params.row.buyer?.name}`},
            {field: 'seller', headerName: "판매자ID", width: 70, valueGetter: (params: any) => `${params.row.seller?.id}`},
            {field: 'seller.name', headerName: "판매자명", width: 100, valueGetter: (params: any) => `${params.row.seller?.name}`},
            {field: 'body', headerName: "리뷰내용", flex: 7, renderCell: (params: GridRenderCellParams) => (<RenderCellExpand {...params} />)},
            {field: 'createdAt', headerName: "작성일", width: 100, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`, renderCell: (params: GridRenderCellParams) => (<RenderCellExpand {...params} />)},
            {field: 'updatedAt', headerName: "수정일", width: 100, valueGetter: (params: any) => `${DateTime.fromISO(params.row.updatedAt).toFormat("yyyy-MM-dd HH:mm:ss")}`, renderCell: (params: GridRenderCellParams) => (<RenderCellExpand {...params} />)},
            {field: 'recommend', headerName: "노출여부", width: 70, renderCell: (params: any) => {
                return (
                    <Switch
                        checked={params.row.recommend}
                        onChange={(_event, checked) => {
                            handleDisplaySwitchToggle(params.row, checked)
                        }}
                    />
                )
            }}
        ]
    }

    const fetch = (tableFilter: any = {}) => {
        SellOrderReviewAPI.getPage(tableFilter).then((result) => {
            setSellOrderReviews(result)
            setRows(result?.content)
        })
    }

    const updateRecommend = (reviewIds: String[], recommend: boolean) => {
        setRecommendReview({reviewIds: reviewIds,recommend: recommend})
        var params = {ids: reviewIds, recommend: recommend}
        SellOrderReviewAPI.updateRecommend(params).then((result) => {
            fetch(tableFilter)
        })
    }

    useEffect(() => {
        fetch(tableFilter)
    }, [tableFilter, refreshCounter])

    const handleDisplaySwitchToggle = (sellOrderReview: SellOrderReviewType, checked: boolean) => {
        (checked ? updateRecommend(sellOrderReview.id!!.split(',') as String[], true) : updateRecommend(sellOrderReview.id!!.split(',') as String[], false))
    }

    return (
        <Stack spacing={2}>
            <SellOrderReviewTableFilter
                filter ={tableFilter}
                handleFilterChange={(changedFilter: SellOrderReviewTableFilterState) => { setTableFilter(changedFilter) }}
            />
            <Box sx={{height: 720, width: '100%'}}>
                <DataGrid
                    columns={getColumns(handleDisplaySwitchToggle)}
                    getRowHeight={() => 70}
                    onSelectionModelChange={handleSelection}
                    checkboxSelection

                    paginationMode="server"
                    onPageChange={(page) => {
                        setTableFilter({...tableFilter, page: page})
                    }}
                    onPageSizeChange={(size) => {
                        setTableFilter({...tableFilter, size: size})
                    }}

                    page={sellOrderReviews.pageable?.pageNumber}
                    pageSize={sellOrderReviews.pageable?.pageSize}
                    rowCount={sellOrderReviews?.totalElements}
                    rows={rows}
                    onCellEditCommit={(params) => {
                        const updatedRows = rows.map(row => {
                            if (row.id === params.id) {
                                return { ...row, priority: params.value };
                            }
                            return row;
                        });
                        setRows(updatedRows);
                    }}
                />
            </Box>
        </Stack>
    )
}

export default SellOrderReviewTable