import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { DateTime } from "luxon"
import { PreProductState } from "./index"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { numberWithCommas, truncate } from "../../utils/NumberUtils"
import { Button } from "@mui/material"
import { toDateStr, toTimeStr } from "../../utils/datetimeUtil"

function createColumns(handleEditButtonClick: Function): any[] {
  const openProductPopup = (row: SellOrderType) => {
    handleEditButtonClick(row)
  }
  const getColorByStatus = (status: SellOrderStatusCode) => {
    switch (status) {
      case SellOrderStatusCode.진단_완료:
        return "error"
      case SellOrderStatusCode.판매_대기:
      default:
        return "info"
    }
  }

  return [
    {
      field: "id",
      headerName: "판매주문번호",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 130,
      maxWidth: 160,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          상품 번호 <br /> (판매 주문번호)
        </p>
      ),
      valueGetter: (params: any) => `${params.row.id}`,
      renderCell: (params: any) => (
        <div style={{ textAlign: "left" }}>
          <p>
            <span style={{ fontWeight: "bold" }}>{params.row.product?.id}</span>
            <br />
            <span style={{ color: "#aaa" }}>{params.row.id}</span>
          </p>
        </div>
      ),
    },
    {
      field: "productId",
      headerName: "상품번호",
      hide: true,
      valueGetter: (params: any) => `${params.row.product?.id}`,
    },
    {
      field: "status",
      headerName: "진행상태",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.sellOrderStatus}`
      },
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openProductPopup(params.row)
            }}
            color={getColorByStatus(params.row.sellOrderStatus)}
          >
            {params.row.sellOrderStatus}
          </Button>
        </div>
      ),
    },
    {
      field: "setup",
      headerName: "상품화",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 60,
      maxWidth: 80,
      align: "center",
      valueGetter: (params: any) => {
        return !!params.row.product.completeSetupAt
      },
      renderCell: (params: any) => {
        return !!params.row.product.completeSetupAt ? (
          <Button variant={"text"} size={"small"} color={"success"}>
            완료
          </Button>
        ) : (
          <Button variant={"text"} size={"small"} color={"error"}>
            미완료
          </Button>
        )
      },
    },
    {
      field: "productCondition",
      headerName: "제품상태",
      editable: false,
      sortable: false,
      align: "left",
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      valueGetter: ({ row: { product: { exposedProductInfo: { productCondition } } } }: any) => {
        return productCondition
      },
    },
    {
      field: "saleType",
      headerName: "판매방식",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 100,
      maxWidth: 140,
      valueGetter: (params: any) => `${params.row.product?.saleType}`,
    },
    // {
    //   field: "repurchaseGuarantee",
    //   headerName: "매입보증",
    //   editable: false,
    //   sortable: false,
    //   align: "center",
    //   flex: 1,
    //   minWidth: 60,
    //   maxWidth: 80,
    //   valueGetter: (params: any) => `${params.row.product?.repurchaseGuarantee ? "O" : "-"}`,
    // },
    {
      field: "exhibitionSale",
      headerName: "전시판매",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 70,
      maxWidth: 70,
      valueGetter: (params: any) => {
        return `${params.row?.exhibitionSale}`
      },
      renderCell: (params: any) => (
          <div>
            {
              params.row?.exhibitionSale
                  ? <Button size={"small"} style={{fontWeight: params.row?.exhibitionSale ? 700 : 400}}>동의</Button>
                  : <Button size={"small"} style={{color: "#999999"}}>미동의</Button>
            }
          </div>
      )
    },
    {
      field: "globalSale",
      headerName: "해외판매",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.product?.globalSale ? "동의" : "미동의"}`,
      renderCell: (params: any) => (
        <div>
          <Button size={"small"} variant={"text"} color={params.row.product?.globalSale ? "success" : "error"}>
            {params.row.product?.globalSale ? "동의" : "미동의"}
          </Button>
        </div>
      ),
    },
    {
      field: "brandName",
      headerName: "브랜드",
      editable: false,
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
      valueGetter: (params: any) => `${params.row.product?.brand?.name}`,
    },
    {
      field: "title",
      headerName: "상품명",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 220,
      valueGetter: (params: any) => `${params.row.product?.title}`,
      renderCell: (params: any) => (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>{params.row.product?.title}</span>
            <br />
            <span style={{ color: "#aaa" }}>{params.row.product?.titleSecondary}</span>
          </p>
        </div>
      ),
    },
    {
      field: "fullRefNo",
      headerName: "Ref No.",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 160,
      maxWidth: 220,
      valueGetter: (params: any) => `${params.row.product?.detailModel?.fullRefNo}`,
    },
    {
      field: "estimatedPrice",
      headerName: "최적판매가",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.productDiagnosis?.viverPrices?.finalPrice
        return price ? `${numberWithCommas(truncate(price, 4))} 만원` : "-"
      },
    },
    {
      field: "userDesiredPrice",
      headerName: "희망판매가",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.productDiagnosis?.userDesiredPrice
        return price ? `${numberWithCommas(truncate(price, 4))} 만원` : "-"
      },
    },
    // {
    //   field: "sellerName",
    //   headerName: "판매자 정보",
    //   editable: false,
    //   sortable: false,
    //   flex: 1,
    //   minWidth: 120,
    //   maxWidth: 160,
    //   valueGetter: (params: any) => `${params.row.seller?.name}`,
    //   renderCell: (params: any) => (
    //     <div>
    //       <p>
    //         {params.row.seller?.name}
    //         <br />
    //         {params.row.seller?.phone}
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   field: "phone",
    //   headerName: "전화번호",
    //   hide: true,
    //   valueGetter: (params: any) => `${params.row.seller?.phone}`,
    // },
    // {
    //   field: "buyerName",
    //   headerName: "구매자 정보",
    //   editable: false,
    //   sortable: false,
    //   flex: 1,
    //   minWidth: 120,
    //   valueGetter: (params: any) => `${params.row.buyOrder?.buyer?.name}`,
    //   renderCell: (params: any) => (
    //     <div>
    //       <p>
    //         {params.row.buyOrder?.buyer?.name}
    //         <br />
    //         {params.row.buyOrder?.buyer?.phone}
    //       </p>
    //     </div>
    //   ),
    // },
    {
      field: "phone",
      headerName: "전화번호",
      hide: true,
      valueGetter: (params: any) => `${params.row.buyOrder?.buyer?.phone}`,
    },
    {
      field: "acceptedAt",
      headerName: "판매신청일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          신청일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "-",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.acceptedAt, false)}
            <br />
            {toTimeStr(params.row.acceptedAt)}
          </p>
        </div>
      ),
    },
    /*
                {
                    field: 'waitingProductAt',
                    headerName: "입고확인일",
                    editable: false,
                    sortable: false,
                    flex: 1,
                    minWidth: 80,
                    maxWidth: 100,
                    renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>입고<br/>확인일</p>),
                    valueGetter: (params: any) => params.value ? DateTime.fromISO(params.value)?.toFormat('yyyy-MM-dd HH:mm:ss') : '',
                    renderCell: (params: any) => (
                        <div style={{textAlign: "center"}}>
                            <p>{toDateStr(params.row.waitingDiagnosisAt, false)}<br/>
                                {toTimeStr(params.row.waitingDiagnosisAt)}</p>
                        </div>
                    )
                },
        */
    {
      field: "notifiedAt",
      headerName: "안내완료일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          안내
          <br />
          완료일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.productDiagnosis?.notifiedAt, false)}
            <br />
            {toTimeStr(params.row.productDiagnosis?.notifiedAt)}
          </p>
        </div>
      ),
    },
    {
      field: "waitingSaleAt",
      headerName: "고객승인완료일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          고객승인
          <br />
          완료일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.waitingSaleAt, false)}
            <br />
            {toTimeStr(params.row.waitingSaleAt)}
          </p>
        </div>
      ),
    },
    {
      field: "onSaleAt",
      headerName: "상품등록일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          상품
          <br />
          등록일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.onSaleAt, false)}
            <br />
            {toTimeStr(params.row.onSaleAt)}
          </p>
        </div>
      ),
    },
  ]
}

type PreProductProps = {
  state: PreProductState
  dispatch: any
  fetch: Function
  components: any[]
}

const PreProductTable: React.FC<PreProductProps> = ({ state, dispatch, fetch, components }) => {
  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }

  const handleSelection = (ids: any[]) => {
    dispatch({ type: "SELECTION_PRODUCT_IDS", payload: ids as string[] })
  }
  const handleEditButtonClick = (row: SellOrderType) => {
    dispatch({ type: "SHOW_EDIT_PRODUCT_POPUP", payload: row })
  }

  return (
    <>
      <div style={{ height: 1200, width: "100%" }}>
        <DataGrid
          components={{ Toolbar: CustomToolbar }}
          getRowId={(row) => row.id!}
          columns={createColumns(handleEditButtonClick)}
          pagination
          {...dispatch.detailModels?.content}
          paginationMode="server"
          onPageChange={(page) => {
            fetch({ page, size: state.sellOrders.pageable?.pageSize || 25 })
          }}
          onPageSizeChange={(size) => {
            fetch({ page: 0, size })
          }}
          page={state.sellOrders.pageable?.pageNumber || 0}
          pageSize={state.sellOrders.pageable?.pageSize || 25}
          rowCount={state.sellOrders?.totalElements || 0}
          rows={state.sellOrders?.content || []}
          selectionModel={state.selectedIds}
          checkboxSelection
          disableSelectionOnClick={true}
          onSelectionModelChange={handleSelection}
          loading={state.loading}
        />
      </div>
    </>
  )
}

// @ts-ignore
export default PreProductTable
