import {Paper, Tab} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import ResellItemTab from "./ResellItemTab";
import {useLocation, useSearchParams} from "react-router-dom";
import AskingPriceTab from "./AskingPriceTab";

interface ResellItemState {
    tab: string
}

const initState = {
    tab: 'resellItem'
}

const PriceManager = () => {
    const [state, setState] = useState(initState)
    const [searchParams, setSearchParams] = useSearchParams()
    let {search} = useLocation()

    function handleChangeTab(newTab: string) {
        if (state.tab != newTab) {
            setState({...initState, tab: newTab})
        }
        setSearchParams([["tab", newTab]])
    }

    useEffect(() => {
        handleChangeTab(new URLSearchParams(search).get("tab") ?? initState.tab)
    }, [])

    return (
        <Paper sx={{p: 2}}>
            <TabContext value={state.tab}>
                <TabList onChange={(_e, value) => handleChangeTab(value) }>
                    <Tab value={"resellItem"} label={`거래 게시물 관리`}></Tab>
                    <Tab value={"askingPrice"} label={`시세 관리`}></Tab>
                </TabList>
                <TabPanel value={"resellItem"}> <ResellItemTab /> </TabPanel>
                <TabPanel value={"askingPrice"}> <AskingPriceTab /> </TabPanel>
            </TabContext>
        </Paper>
    )
}

export default PriceManager