import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material"
import RowBlock from "../../../components/RowBlock"
import { DateTimePicker } from "@mui/lab"
import ImageUploadButton from "../../../components/ImageUploadButton"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { upload, uploadForNoResize } from "../../../apis/resourceAPI"
import ShopContentsAPI from "../../../apis/shopHeroBannerAPI"
import {
    BannerTypeCode,
    BannerTypeCodeList,
    ShopHeroBannerType,
    ThemeModeTypeCode,
    ThemeModeTypeCodeList,
} from "types/ShopHeroBannerType"
import CheckBox from "../../../components/Checkbox"
import styled from "@emotion/styled"
import { isNil } from "../../../utils/validationUtils"
import { toast } from "react-toastify"

const initState: ShopHeroBannerType = {
    description: null,
    imageUrl: null,
    linkUrl: null,
    onDisplay: true,
    priority: null,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO(),
    createdAt: null,
    bannerType:  BannerTypeCode.IMAGE,
    bannerBody: null,
    isMotionOff: false,
    thumbnailUrl: null,
    videoUrl: null,
    themeModeType: ThemeModeTypeCode.DARK,
    isMagazine: false,
    title1: null,
    title2: null,
    title3: null,
    showDomesticUser: true,
    showForeignUser: false
}

// @ts-ignore
const BannerCreatePopup = ({open, handleClose}) => {
    const [state, setState] = useState(initState)

    useEffect(() => {
        setState(initState)
    }, [open])

    // @ts-ignore
    const handleBannerImage = (image) => {
        if ( state.bannerType === BannerTypeCode.IMAGE) {
            upload(image).then((result) => {
                setState({
                    ...state,
                    imageUrl: result.url,
                })
            })
        } else if ( state.bannerType === BannerTypeCode.VIDEO) {
            setState({...state,videoUrl: null})
            uploadForNoResize(image).then((result) => {
                setState({
                    ...state,
                    videoUrl: result.url,
                })
            })
        }
    }

    const handleBannerThumbnail = (image : any) => {
         if ( state.bannerType === BannerTypeCode.VIDEO) {
             upload(image).then((result) => {
                setState({
                    ...state,
                    thumbnailUrl: result.url,
                })
            })
        }
    }

    const handleSubmit = () => {
        if (isNil(state.description)) {
            toast.error("설명을 입력하세요.")
            return
        }
        if (state.bannerType == BannerTypeCode.IMAGE && isNil(state.imageUrl)) {
            toast.error("배너 이미지를 선택하세요.")
            return
        }
        // if (state.bannerType == BannerTypeCode.HTML && isNil(state.bannerBody)) {
        //     toast.error("배너 HTML를 입력하세요.")
        //     return
        // }
        if (state.bannerType == BannerTypeCode.VIDEO && isNil(state.videoUrl)) {
            toast.error("배너 동영상을 선택하세요.")
            return
        }
        if (state.bannerType == BannerTypeCode.VIDEO && isNil(state.thumbnailUrl)) {
            toast.error("배너 썸네일을 선택하세요.")
            return
        }
        if (isNil(state.linkUrl)) {
            toast.error("링크 URL를 입력하세요.")
            return
        }
        if (isNil(state.startedAt)) {
            toast.error("노출 시작일자를 입력하세요.")
            return
        }
        if (isNil(state.endedAt)) {
            toast.error("노출 종료일자를 입력하세요.")
            return
        }
        if (state.startedAt > state.endedAt) {
            toast.error("노출 기간을 확인하세요.")
            return
        }

        ShopContentsAPI.create(state).then(() => {handleClose()})
    }

    return (
        <Dialog open={open} maxWidth={"lg"}  fullWidth    >
            <DialogTitle>배너 추가</DialogTitle>
            <DialogContent  >
                <Stack direction={'row'}   >
                    <Stack spacing={3}  style={{width:'100%', height:'900px'}} >
                        <RowBlock title={"설명"}><TextField size={'small'}   fullWidth value={state.description} onChange={(e) => setState({...state, description: e.target.value})} placeholder={'배너의 설명을 입력 해주세요. (서비스에 노출되진 않습니다)'}/></RowBlock>
                        <RowBlock title={"이미지"} fullWidth>
                            <Stack direction={'column'} alignItems={'flex-start'} spacing={2} alignContent={'space-between'} >
                                    <RadioGroup row sx={{ justifyContent: "center" }}
                                                onChange={(event, value) => {
                                                    // @ts-ignore
                                                    setState({...state, bannerType: value})
                                                }}
                                    >
                                        {BannerTypeCodeList.map((item,) => (
                                          <FormControlLabel
                                            key={item.value}
                                            value={item.value}
                                            label={item.title}
                                            checked={state.bannerType === item.value}
                                            control={<Radio />}
                                          />
                                        ))}
                                    </RadioGroup>
                                { (state.bannerType === BannerTypeCode.IMAGE ) && (
                                  <Stack  width={'100%'} justifyContent={'space-between'} spacing={1}>
                                      {/*<Box  width={'380px'} height={'380px'}>*/}
                                      {/*    <Box style={{*/}
                                      {/*        width: '380px',*/}
                                      {/*        height: '380px',*/}
                                      {/*        border: 'solid',*/}
                                      {/*        background: '#33333333'*/}
                                      {/*    }}>*/}
                                      {/*        { state.imageUrl ? <img width={'100%'} height={'100%'} src={state.imageUrl}  /> : <></>}*/}
                                      {/*    </Box>*/}
                                      {/*</Box>*/}
                                      <Stack direction={"column"}   spacing={2}  >
                                          <ImageUploadButton height={'330px'} width={'330px'} handleImage={handleBannerImage} imageUrl={state.imageUrl}  handleDeleteImage={() => {setState({...state, imageUrl: null})}}/>
                                          { (state.bannerType === BannerTypeCode.IMAGE  ) && (
                                            <Stack direction={'row'} alignItems={'center'}   >
                                                <CheckBox checked={state?.isMotionOff}   id="isMotionOff"  onChange={() => setState((pre) => ({...state, isMotionOff: !state.isMotionOff})) } />
                                                <CheckBoxLabel htmlFor="isMotionOff" >
                                                    모션 off
                                                </CheckBoxLabel>
                                            </Stack>
                                          )}
                                      </Stack>
                                  </Stack>
                                )}
                                { ( state.bannerType === BannerTypeCode.VIDEO ) && (
                                  <Stack width={"100%"} justifyContent={"space-between"} spacing={1}>
                                      {/*<Box width={"380px"} height={"380px"}>*/}
                                      {/*    <Box style={{*/}
                                      {/*        width: "380px",*/}
                                      {/*        height: "380px",*/}
                                      {/*        border: "solid",*/}
                                      {/*        // background: "#33333333",*/}
                                      {/*    }}>*/}
                                      {/*        { ( state.videoUrl !== null) && (*/}
                                      {/*            <video style={{width: '100%', maxWidth: '100%', height: '100%', objectFit: 'contain'}} autoPlay loop   playsInline>*/}
                                      {/*                <source src={state.videoUrl}/>*/}
                                      {/*            </video>*/}
                                      {/*        )}*/}

                                      {/*    </Box>*/}
                                      {/*</Box>*/}
                                      <Stack direction={"row"} spacing={2} alignContent={"center"}>
                                          <Stack direction={"column"}>
                                              <Typography>썸네일</Typography>
                                              <ImageUploadButton height={"330px"} width={"330px"}
                                                                 handleImage={handleBannerThumbnail}
                                                                 imageUrl={state.thumbnailUrl} handleDeleteImage={() => {
                                                  setState({ ...state, thumbnailUrl: null })
                                              }} />
                                          </Stack>
                                          <Stack direction={"column"}>
                                              <Typography>동영상</Typography>
                                              <ImageUploadButton height={"330px"} width={"330px"}
                                                                 handleImage={handleBannerImage} videoUrl={state.videoUrl}
                                                                 handleDeleteImage={() => {
                                                                     setState({ ...state, videoUrl: null })
                                                                 }} />
                                          </Stack>
                                      </Stack>
                                  </Stack>
                                )}
                                {/*{(state.bannerType === BannerTypeCode.HTML) && (*/}
                                {/*  <HtmlEditor*/}
                                {/*    body={state.bannerBody || ""}*/}
                                {/*    changeHtmlBody={(bannerBody) => setState((prev) => ({ ...prev, bannerBody   }))}*/}
                                {/*  />*/}
                                {/*)}*/}
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"링크 URL"}><TextField size={'small'} fullWidth onChange={(e) => setState({...state, linkUrl: e.target.value})} placeholder={'https://'}/></RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={'row'} justifyContent={'flex-start'}>
                                <Switch checked={state.onDisplay ?? false} onChange={(e) => setState({...state, onDisplay: e.target.checked})}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'flex-start'}>
                                <DateTimePicker onChange={(e) => {
                                    setState({
                                        ...state,
                                        startedAt: e?.toISO() ?? DateTime.now().toISO()
                                    })
                                }} value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 시작'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                                <Typography>~</Typography>
                                <DateTimePicker onChange={(e) => {
                                    setState({
                                        ...state,
                                        endedAt: e?.toISO() ?? DateTime.now().toISO()
                                    })
                                }} value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())} renderInput={(props) => (<TextField {...props} />)} label={'게시 종료'} inputFormat={"yyyy-MM-dd HH:mm"}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"타입"}>
                            <Stack direction={'row'}  justifyContent={'flex-start'}>
                                <RadioGroup row sx={{ justifyContent: "center" }}
                                            onChange={(event, value) => {
                                                // @ts-ignore
                                                setState({...state, themeModeType: value})
                                            }}
                                >
                                    {ThemeModeTypeCodeList.map((item) => (
                                      <FormControlLabel
                                        key={item.value}
                                        value={item.value}
                                        label={item.title}
                                        checked={state.themeModeType === item.value}
                                        control={<Radio />}
                                      />
                                    ))}
                                </RadioGroup>
                                <Stack direction={'row'} alignItems={'center'} spacing={5} justifyContent={"space-between"}>
                                    <Stack direction={'row'} alignItems={'center'}   >
                                        <CheckBox checked={state?.isMagazine}   id="isMagazine"  onChange={() => setState((pre) => ({...state, isMagazine: !state.isMagazine})) } />
                                        <CheckBoxLabel htmlFor="isMagazine" >
                                            매거진
                                        </CheckBoxLabel>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"텍스트"}   >
                            <Stack direction={"column"} spacing={1}>
                            <TextField size={'small'} fullWidth inputProps={{maxLength:50}} onChange={(e) => setState({...state, title1: e.target.value})} placeholder={'첫째줄 카피 최대 50자리까지(띄어쓰기 포함)'}/>
                            <TextField size={'small'} fullWidth inputProps={{maxLength:50}} onChange={(e) => setState({...state, title2: e.target.value})} placeholder={'둘째줄 카피 최대 50자리까지(띄어쓰기 포함)'}/>
                            <TextField size={'small'} fullWidth inputProps={{maxLength:50}} onChange={(e) => setState({...state, title3: e.target.value})} placeholder={'소문구 카피 최대 50자리까지(띄어쓰기 포함)'}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출"}   >
                            <Stack direction={'row'} alignItems={'center'}   >
                                <CheckBox checked={state?.showDomesticUser}   id="showDomesticUser"  onChange={() => setState((pre) => ({...state, showDomesticUser: !state.showDomesticUser})) } />
                                <CheckBoxLabel htmlFor="showDomesticUser" >
                                    내국인 회원 (국내거주 외국인 포함)
                                </CheckBoxLabel>
                                <CheckBox checked={state?.showForeignUser}   id="showForeignUser"  onChange={() => setState((pre) => ({...state, showForeignUser: !state.showForeignUser})) } />
                                <CheckBoxLabel htmlFor="showForeignUser" >
                                    외국인 회원
                                </CheckBoxLabel>
                            </Stack>
                        </RowBlock>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>추가</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`

export default BannerCreatePopup