import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const couponCodeTableHeaderItemList = [
  {
    title: "코드",
    width: "12%",
  },
  {
    title: "상태",
    width: "5%",
  },
  {
    title: "이름",
    width: "20%",
  },
  {
    title: "타입",
    width: "7%",
  },
  {
    title: "수량",
    width: "8%",
  },
  {
    title: "쿠폰 포인트 사용",
    width: "7%",
  },
  {
    title: "지급 포인트",
    width: "8%",
  },
  {
    title: "유효기간",
    width: "10%",
  },
  {
    title: "발행 시작일시",
    width: "7%",
  },
  {
    title: "발행 종료일시",
    width: "7%",
  },
  {
    title: "생성일시",
    width: "7%",
  },
  {
    title: "수정일시",
    width: "7%",
  },
]

const CouponCodeListTableHeader = () => {
  return (
    <ListTableHeader>
      {couponCodeTableHeaderItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default CouponCodeListTableHeader
