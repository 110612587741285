import { FunctionComponent } from "react"
import { StyledBox, StyledInputText, StyledTxt, HAS_DESIGN_PRINT } from "./styled"
import { Colors } from "@viver-inc/viver-ui"
export type AppraisalCertificateReportType = {
  label: string
  labelKo:string
  key: string
  value: string
}
export const DiagnosisPrintReport:FunctionComponent<{
  report:AppraisalCertificateReportType[]
  handleChange:(label:string, value:string)=>void
  editable: boolean
}> = (props) => {
  return (
    props.report.length > 0 ? (
      <StyledBox $flexDirection="column" $paddingTop={26} $paddingLeft={5} $paddingBottom={29}>
        {
          props.report.map((item, index) => (
            <StyledBox $alignItems={"center"} $width={"100%"} key={index}>
              <StyledBox $width={78} $flexShrink={0}>
                <StyledTxt
                  as="strong"
                  $fontSize={7}
                  $lineHeight={10}
                  $color={Colors.GRAY_100}
                  className="is-design"
                >
                  {item.label}
                </StyledTxt>
              </StyledBox>
              <StyledBox
                $width="100%"
                $height={24}
                $alignItems="center"
                $paddingLeft={11}
                $paddingRight={8}
                $isBg={index % 2 === 0}
              >
                <StyledInputText
                  $fontSize={8}
                  $lineHeight={10}
                  $color={Colors.BLACK}
                  value={item.value}
                  onChange={(event) =>{
                    props.handleChange(item.label, event.target.value)
                  }}
                  readOnly={!props.editable}
                />
              </StyledBox>
            </StyledBox>
          ))
        }
      </StyledBox>
    ) : null
  )
}