import React from "react"
import styled from "@emotion/styled"
import TranslationColumnManagerSearchBar from "./TranslationColumnManagerSearchBar"
import TranslationColumnManagerTable from "./TranslationColumnManagerTable"
import TranslationColumnManagerAction from "./TranslationColumnManagerAction"
import useTranslationColumnManager from "./useTranslationColumnManager"


const TranslationManager = () => {
  const {
    state,
    setState,
    setTableColumnTargetSave,
    setTableColumnTargetDelete,
  } = useTranslationColumnManager()

  return (
    <>
      <PageHeaderWrapper>
        <TranslationColumnManagerSearchBar
          state={state}
          dispatch={setState}
        />
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <TranslationColumnManagerAction
          onSave={setTableColumnTargetSave}
          onDelete={setTableColumnTargetDelete}
        />
        <TranslationColumnManagerTable
          state={state}
          dispatch={setState}
        />
      </PageBodyWrapper>
    </>
  )
}

export default TranslationManager

const PageHeaderWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    background: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
`

const PageBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px
`