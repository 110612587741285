import styled from "@emotion/styled"
import { UpgradeServiceOrderStatus } from "types/UpgradeServiceOrderType"

type Props = {
  filterStatus?: string
  countList: { list: { label: string; count: number }[]; totalCount: number }
  onSelectStatus: (filterStatus?: string) => void
}

const countOptionList = [
  {
    title: "수리 등록 완료",
    key: "등록완료",
  },
  {
    title: "결제 대기",
    key: UpgradeServiceOrderStatus.결제대기,
  },
  {
    title: "결제 완료",
    key: UpgradeServiceOrderStatus.결제완료,
  },
  {
    title: "수리 중",
    key: UpgradeServiceOrderStatus.수리중,
  },
  {
    title: "수리 완료",
    key: UpgradeServiceOrderStatus.수리완료,
  },
  {
    title: "결제 만료",
    key: UpgradeServiceOrderStatus.결제만료,
  },
]

export const UpgradeServiceCountBar = ({ filterStatus, countList, onSelectStatus }: Props) => {
  return (
    <CountTabListWrapper>
      <CountTab isSelected={!filterStatus} onClick={() => onSelectStatus(undefined)}>
        <div className="label">전체</div>
        <div className="value">{countList.totalCount}건</div>
      </CountTab>
      {countOptionList.map((item) => {
        return (
          <CountTab key={item.key} isSelected={filterStatus === item.key} onClick={() => onSelectStatus(item.key)}>
            <div className="label">{item.title}</div>
            <div className="value">
              {countList.list.find((countItem) => countItem.label === item.key)?.count || 0}건
            </div>
          </CountTab>
        )
      })}
    </CountTabListWrapper>
  )
}

const CountTabListWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 22px 30px;
`

const CountTab = styled.div<{ isSelected?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-right: 48px;

  :hover {
    cursor: pointer;
  }

  .label {
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    font-size: 15px;
    margin-right: 6px;
  }

  .value {
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    font-size: 15px;
    color: #0000f8;
  }
`
