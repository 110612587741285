import { ThemeProvider } from "@emotion/react"
import { LocalizationProvider } from "@mui/lab"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { ErrorBoundary } from "@sentry/react"
import { createTheme, Box, CssBaseline, Toolbar, Container } from "@mui/material"
import { FunctionComponent } from "react"
import { Outlet, useLocation } from "react-router-dom"

import LayoutFooter from "./LayoutFooter"
import LayoutNavigation from "./LayoutNavigation"
import ErrorBoundaryFallback from "../ErrorBoundaryFallback"
const mdTheme = createTheme()
const Layout: FunctionComponent = (props) => {
  const location = useLocation()

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon as any}>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <CssBaseline />
          <LayoutNavigation asideWidth={240} />
          <Box
            component="main"
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container
              style={{ display: "flex", flexDirection: "column", flex: 1 }}
              maxWidth={false}
              sx={{ mt: 4, mb: 4 }}
            >
              <ErrorBoundary key={location.pathname} fallback={ErrorBoundaryFallback}>
                <Outlet />
              </ErrorBoundary>
              <LayoutFooter />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </LocalizationProvider>
  )
}
export default Layout
