import styled from "@emotion/styled"
import { uploadThumbnail } from "apis/modelAPI"
import { isEmpty, isNil } from "lodash"
import { useCallback, useRef } from "react"

type Props = { src: null | string; label: string; onUploadFile: (url: string) => void; height?: number }

const defaultImage = require("./DetailModelImageInputPlaceholder.png")
const plusIcon = require("../../../images/16_plus_circle.png")

export const ModelImageInput = ({ src, label, height, onUploadFile }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const uploadFile = useCallback(async (fileList: FileList) => {
    if (isEmpty(fileList)) return

    const { url } = await uploadThumbnail(fileList)
    return url
  }, [])

  return (
    <ImageUploaderWrapper>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        accept="image/*"
        type="file"
        onChange={async (event) => {
          const fileList = event.target.files

          if (isEmpty(fileList) || isNil(fileList)) return

          const url = await uploadFile(fileList)

          if (url) onUploadFile(url)

          event.target.value = ""
        }}
      />

      <Label>{label}</Label>
      <ImageUploadPreview src={src || defaultImage} height={height || 260} />
      <ImageUploadButton
        onClick={async () => {
          if (isNil(inputRef.current)) return

          inputRef.current.click()
        }}
      >
        <Icon src={plusIcon} />
        {label}
      </ImageUploadButton>
    </ImageUploaderWrapper>
  )
}

const ImageUploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-right: 24px;
  }
`

const ImageUploadPreview = styled.img<{ height: number }>`
  width: 260px;
  border-radius: 8px;
  height: ${({ height }) => height}px;
  margin-bottom: 6px;
  object-fit: contain;
  background: #f7f8fa;
`

const ImageUploadButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: #fff;
  outline: none;
  border: none;
  padding: 11px 20px;

  font-size: 16px;
  font-weight: 600;
  cusror: pointer;
`

const Icon = styled.img`
  margin-right: 6px;
  width: 20px;
  height: 20px;
`

const Label = styled.div`
  min-width: 64px;
  height: 38px;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: #3b464f;
`
