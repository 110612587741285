import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { useState } from "react"

type Props = {
  isOpen: boolean
  closeModal: () => void
  changePhoneNumber: (phoneNumber: string) => void
  phoneNumber: string
}

const UserPhonenNumberChangeModal = ({ isOpen, closeModal, changePhoneNumber, phoneNumber }: Props) => {
  const [inputValue, setInputValue] = useState(phoneNumber)

  const clickChangePhoneNumberButton = () => {
    changePhoneNumber(inputValue)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>전화번호 변경 관리</h3>
          <div>회원의 전화번호를 변경할 수 있습니다.</div>
        </ModalContentHeader>
        <ModalContentBody>
          <SearchInput value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button
              buttonStyle="blue-fill"
              buttonSize="M"
              disabled={!inputValue || inputValue === phoneNumber}
              onClick={clickChangePhoneNumberButton}
            >
              변경
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default UserPhonenNumberChangeModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`
