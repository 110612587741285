import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import {HomeHeroBannerType} from "types/HomeHeroBannerType";


export default {
    create: (banner: HomeHeroBannerType): Promise<HomeHeroBannerType> => httpPost("/home/hero-banners", banner),
    update: (id: number, banner: HomeHeroBannerType): Promise<HomeHeroBannerType> => httpPut(`/home/hero-banners/${id}`, banner),
    get: (id: number): Promise<HomeHeroBannerType> => httpGet(`/home/hero-banners/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/home/hero-banners/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<HomeHeroBannerType>> => httpGet("/home/hero-banners", params),
    getList: (params?:  { [key: string]: any }): Promise<HomeHeroBannerType[]> => httpGet("/home/hero-banners/list", params),
    reordering: (list: HomeHeroBannerType[]): Promise<HomeHeroBannerType[]> => httpPut(`/home/hero-banners/priority`, list),
}