import { httpDelete, httpGet, httpPost, httpPut } from "../utils/httpAPI"
import {
  PaymentAreaComponentType, PaymentBannerComponentType, PaymentCardBenefitComponentType,
  PaymentDiscountNoticeComponentType,
  PaymentMessageComponentType,
} from "types/PaymentAreaComponentType"
import { SaleTypeCode } from "../types/ProductType"

export const getComponents = (): Promise<PaymentAreaComponentType[]> =>
  httpGet("/payment-area")

export const updatePaymentMethodComponent = (
  id: PaymentAreaComponentType["id"],
  component: Partial<Omit<PaymentAreaComponentType, "id">>,
) => httpPut(`/payment-area/payment-methods/${id}`, component)


export const createMessageComponent = (
  component: Partial<Omit<PaymentMessageComponentType, "id">>,
): Promise<PaymentMessageComponentType> => httpPost(`/payment-area/messages`, component)

export const updateMessageComponent = (
  id: PaymentMessageComponentType["id"],
  component: Partial<Omit<PaymentMessageComponentType, "id">>,
) => httpPut(`/payment-area/messages/${id}`, component)

export const deleteMessageComponent = (id: PaymentMessageComponentType["id"]) => httpDelete(`/payment-area/messages/${id}`)


export const createDiscountNoticeComponent = (
  component: Partial<Omit<PaymentDiscountNoticeComponentType, "id">>,
): Promise<PaymentDiscountNoticeComponentType> => httpPost(`/payment-area/discount-notice`, component)

export const updateDiscountNoticeComponent = (
  id: PaymentDiscountNoticeComponentType["id"],
  component: Partial<Omit<PaymentDiscountNoticeComponentType, "id">>,
) => httpPut(`/payment-area/discount-notice/${id}`, component)

export const deleteDiscountNoticeComponent = (id: PaymentDiscountNoticeComponentType["id"]) => httpDelete(`/payment-area/discount-notice/${id}`)


export const createCardBenefitComponent = (
  component: Partial<Omit<PaymentCardBenefitComponentType, "id">>,
): Promise<PaymentCardBenefitComponentType> => httpPost(`/payment-area/card-benefit`, component)

export const updateCardBenefitComponent = (
  id: PaymentCardBenefitComponentType["id"],
  component: Partial<Omit<PaymentCardBenefitComponentType, "id">>,
) => httpPut(`/payment-area/card-benefit/${id}`, component)

export const deleteCardBenefitComponent = (id: PaymentCardBenefitComponentType["id"]) => httpDelete(`/payment-area/card-benefit/${id}`)


export const createBannerComponent = (
  component: Partial<Omit<PaymentBannerComponentType, "id">>,
): Promise<PaymentCardBenefitComponentType> => httpPost(`/payment-area/banners/`, component)

export const updateBannerComponent = (
  id: PaymentAreaComponentType["id"],
  component: Partial<Omit<PaymentAreaComponentType, "id">>,
) => httpPut(`/payment-area/banners/${id}`, component)

export const deleteBannerComponent = (id: PaymentAreaComponentType["id"]) => httpDelete(`/payment-area/banners/${id}`)

export const reorderComponents = (ids: PaymentAreaComponentType[]) =>
  httpPost<PaymentAreaComponentType>(`/payment-area/reordering`, ids)
