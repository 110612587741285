import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import { ShopFrontPopupBannerType } from "../types/ShopFrontPopupBannerType"

export default {
    create: (banner: ShopFrontPopupBannerType): Promise<ShopFrontPopupBannerType> => httpPost("/shop/front-pop-banners", banner),
    update: (id: number, banner: ShopFrontPopupBannerType): Promise<ShopFrontPopupBannerType> => httpPut(`/shop/front-pop-banners/${id}`, banner),
    get: (id: number): Promise<ShopFrontPopupBannerType> => httpGet(`/shop/front-pop-banners/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/shop/front-pop-banners/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<ShopFrontPopupBannerType>> => httpGet("/shop/front-pop-banners",params),
}