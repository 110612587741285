import styled from "@emotion/styled"
import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { OrderHistoryBySerialNumberType, SerialNumberListItemType } from "types/SerialNumberType"
import { isNotNil } from "utils/validationUtils"
import { SerialNumberDetailModal } from "../SerialNumberDetailModal"
import { useSerialNumberFinder } from "./useSerialNumberFinder"
import { getOrderHistoryBySerialNumber } from "../../../apis/serialAPI"

const warningIconSrc = require("images/warning_icon.png")
const paperIconSrc = require("images/paper_icon.png")

type Props = {
  value: string
  productId?: number
  onChangeValue: (value: string) => void
  onSelect: (serialNumber: SerialNumberListItemType | null) => void
  onUpdateLatestOrder?: (latestOrder: OrderHistoryBySerialNumberType|null) => void
}

export const SerialNumberFinder = ({ value, productId, onSelect, onChangeValue, onUpdateLatestOrder }: Props) => {
  const [isOpenSerialDetailModal, setIsOpenSerialDetailModal] = useState<boolean>(false)
  const { selectedSerial, setSelectedSerial, serialList, fetchSerialList } = useSerialNumberFinder(value)
  const [latestOrder, setLatestOrder] = useState<OrderHistoryBySerialNumberType|null>(null);

  const updateLatestOrder = (order: OrderHistoryBySerialNumberType | null) => {
    setLatestOrder(order)
    if (onUpdateLatestOrder) onUpdateLatestOrder(order) // 상위 컴포넌트로 전달
  }

  useEffect(() => {
    if (!value) {
      updateLatestOrder(null);
      return;
    }

    getOrderHistoryBySerialNumber(value).then((response: OrderHistoryBySerialNumberType[]) => {
      if (response.length > 0) {
        const sortedResponse = response.sort((a, b) => b.transactionAt!!.localeCompare(a.transactionAt!!));
        if (!(sortedResponse.length === 1 && sortedResponse[0].productId === productId)) {
          updateLatestOrder(sortedResponse[0]);
        } else {
          updateLatestOrder(null);
        }
      } else {
        updateLatestOrder(null);
      }
    });
  }, [value]);

  return (
    <>
      {isOpenSerialDetailModal && selectedSerial && (
        <SerialNumberDetailModal
          serialNumber={selectedSerial.serialNo}
          closeModal={() => setIsOpenSerialDetailModal(false)}
        />
      )}
      <Wrapper>
        <Autocomplete<SerialNumberListItemType>
          disablePortal
          options={serialList}
          getOptionLabel={(option) => option.serialNo}
          fullWidth
          onFocus={fetchSerialList}
          clearOnBlur={false}
          clearIcon={null}
          popupIcon={null}
          renderInput={(params) => (
            <>
            <InlineWrapper>
              <TextField {...params} variant="standard" type={"text"} required />
              {isNotNil(selectedSerial) && (
                  <IconWrapper>
                    {selectedSerial.blacklist && (
                      <Icon src={warningIconSrc} style={{ width: "14px", height: "14px", marginRight: "8px" }} />
                    )}
                    <Icon
                      src={paperIconSrc}
                      style={{
                        width: "20px",
                        height: "20px",
                        filter: value && latestOrder ? "invert(14%) sepia(93%) saturate(7486%) hue-rotate(359deg) brightness(94%) contrast(130%)" : "none",
                      }}
                      onClick={() => setIsOpenSerialDetailModal(true)}
                    />
                  </IconWrapper>
              )}
            </InlineWrapper>
            {latestOrder && (
              <InlineWrapper>
                <div style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "4px",
                  textAlign: "left",
                  bottom: "0",
                }}>
                  판매 이력이 있는 상품입니다. <br />
                  시리얼 넘버 정보를 확인해주세요.
                </div>
              </InlineWrapper>
              )}
            </>
          )}
          inputValue={value}
          onInputChange={(e, value) => {
            if (e) onChangeValue(value)
          }}
          onChange={(e, option) => {
            setSelectedSerial(option)
            onSelect(option)
          }}
          renderOption={(props, option) => (
            <MenuItem {...props}>
              <Label>{option.serialNo}</Label>
              <IconWrapper>
                {option.blacklist && (
                  <Icon src={warningIconSrc} style={{ width: "14px", height: "14px", marginRight: "8px" }} />
                )}
                <Icon src={paperIconSrc} style={{ width: "20px", height: "20px" }} />
              </IconWrapper>
            </MenuItem>
          )}
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  flex: 1;
`

const MenuItem = styled.li`
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;

  :hover {
    background: #eeeeee;
  }
`

const Label = styled.div`
  width: 85%;
  text-align: left;
`

const Icon = styled.img``

const IconWrapper = styled.div`
  width: 15%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`
