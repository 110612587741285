import { Button, TextField } from "@mui/material"
import { isNaN } from "lodash"
import { useCallback, useState } from "react"

type Props = {
  onSavePrice: (price: number) => Promise<void>
  onCancleEdit: () => void
  negotiationPrice: number
}

export const NegotiationPriceEditor = ({ negotiationPrice, onSavePrice, onCancleEdit }: Props) => {
  const [inputValue, setInputValue] = useState<number>(negotiationPrice)

  const handleSavePrice = useCallback(async () => {
    await onSavePrice(inputValue)
  }, [onSavePrice, inputValue])

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        marginRight: "10px",
      }}
    >
      <TextField
        variant="outlined"
        fullWidth
        type={"string"}
        value={inputValue}
        onChange={(event) => {
          if (isNaN(Number(event.target.value))) return

          setInputValue(Number(event.target.value))
        }}
        style={{
          padding: "5px",
        }}
        inputProps={{
          style: {
            padding: 10,
          },
        }}
      />
      <Button
        size="small"
        color="primary"
        variant="contained"
        style={{
          margin: "0 5px",
        }}
        onClick={handleSavePrice}
      >
        저장
      </Button>
      <Button size="small" variant="outlined" color="error" onClick={onCancleEdit}>
        취소
      </Button>
    </div>
  )
}
