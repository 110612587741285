import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { ProductTrackingLogListItemType } from "types/ProductTrackingLogType"
import { toDateTimeStr } from "utils/datetimeUtil"
import { headerItemList } from "../../TableHeader"

type Props = {
  logListItem: ProductTrackingLogListItemType
} & HTMLAttributes<HTMLDivElement>

const ProductTrackingLogListTableRow = ({ logListItem, ...props }: PropsWithChildren<Props>) => {
  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: headerItemList[0].width }}>
        {logListItem.product.id || ""}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>
        {logListItem.product.detailModel?.fullRefNo || ""}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>
        {logListItem.product.serialNo || ""}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[3].width, justifyContent: "center" }}>
        {logListItem.tracker.name}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[4].width, justifyContent: "center" }}>
        {logListItem.tracker.site}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[5].width, justifyContent: "center" }}>
        {logListItem.reason || ""}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[6].width, justifyContent: "center" }}>
        {toDateTimeStr(logListItem.createdAt)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[7].width, justifyContent: "center" }}>
        {logListItem.memo}
      </TableRowItem>
    </TableRow>
  )
}

export default ProductTrackingLogListTableRow
