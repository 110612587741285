import { AdminGroupListState } from "./index"
import { Stack, Tooltip } from "@mui/material"
import { DataGrid, GridRenderCellParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import Chip from "@mui/material/Chip"

const maxVisibleChips = 8 // 표시할 최대 Chip 수

function renderMenuViews(params: GridRenderCellParams) {
  const items = params.value
  const hiddenCount = items.length - maxVisibleChips

  return (
    <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
      {items.slice(0, maxVisibleChips).map((item: string) => (
        <Chip key={item} label={item} variant="outlined" size="small" />
      ))}
      {hiddenCount > 0 && (
        <Tooltip title={items.slice(maxVisibleChips).join(", ")} arrow>
          <Chip label={`외 ${hiddenCount}개`} variant="filled" size="small" />
        </Tooltip>
      )}
    </Stack>
  )
}

function createColumns(): any[] {
  return [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 90,
    },
    {
      field: "groupName",
      headerName: "그룹명",
      editable: false,
      sortable: false,
      minWidth: 150,
    },
    {
      field: "menuViews",
      headerName: "메뉴 권한",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: renderMenuViews,
    },
  ]
}

type AdminGroupProps = {
  state: AdminGroupListState
  dispatch: any
  components: any[]
}

const AdminGroupTable: React.FC<AdminGroupProps> = ({ state, dispatch, components }) => {
  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }

  const handleSelection = (params: any) => {
    dispatch({ type: "TOGGLE_ADMIN_GROUP_DETAIL_POPUP", payload: params.id })
  }

  return (
    <>
      <Stack spacing={1}>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            getRowId={(row) => row.id!}
            columns={createColumns()}
            pagination
            {...dispatch.detailModels?.content}
            paginationMode="server"
            onPageChange={(page) => {
              dispatch({ type: "CHANGE_PAGE_NUMBER", payload: page })
            }}
            onPageSizeChange={(size) => {
              dispatch({ type: "CHANGE_PAGE_SIZE", payload: size })
            }}
            page={state.pageNumber || 0}
            pageSize={state.pageSize || 25}
            rowCount={state.adminGroups?.totalElements || 0}
            rows={state.adminGroups?.content || []}
            onRowClick={handleSelection}
            loading={state.loading}
          />
        </div>
      </Stack>
    </>
  )
}

export default AdminGroupTable
