import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material"
import { ShowroomAction, ShowroomState } from "../index"
import React, { useEffect, useState } from "react"
import { ShowroomType } from "types/ShowroomType"
import { ShowroomPageActionCode } from "../showroomPageActionCode"
import { getShowrooms, updateShowroom } from "../../../apis/showroomAPI"

type Props = {
  state: ShowroomState
  dispatch: React.Dispatch<ShowroomAction>
}

const EditShowroomPopup: React.FC<Props> = ({ state, dispatch }) => {
  const [showroom, setShowroom] = useState<ShowroomType>()

  const createHandleOnChangeTextField = (key: keyof ShowroomType) => {
    return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShowroom((showroom) => {
        if (!showroom) return showroom
        return { ...showroom, [key]: event.target.value }
      })
    }
  }

  const handleOnClickSubmitButton = async () => {
    if (!showroom) return
    await updateShowroom(showroom.id, showroom)
    dispatch({ type: ShowroomPageActionCode.FETCH_SHOWROOMS, payload: await getShowrooms() })
    dispatch({ type: ShowroomPageActionCode.CLOSE_EDIT_SHOWROOM_POPUP })
  }

  useEffect(() => {
    if (state.openEditShowroomPopup && state.selectedShowroom) {
      setShowroom(state.selectedShowroom)
    }
  }, [state.openEditShowroomPopup, state.selectedShowroom])

  return (
    <Dialog open={state.openEditShowroomPopup} maxWidth={"xs"} fullWidth>
      <DialogTitle>쇼룸 수정</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DialogContentText>수정할 쇼룸 정보를 입력해주세요.</DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"name"}
              label={"쇼룸 이름"}
              placeholder={"ex) 압구정 로데오역점"}
              InputLabelProps={{ shrink: true }}
              value={showroom?.name}
              onChange={createHandleOnChangeTextField("name")}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"address"}
              label={"쇼룸 주소"}
              placeholder={"ex) 서울시 강남구 선릉로 157길 24, 1층"}
              InputLabelProps={{ shrink: true }}
              value={showroom?.address}
              onChange={createHandleOnChangeTextField("address")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"phone"}
              label={"쇼룸 전화번호"}
              placeholder={"ex) 02-518-0351"}
              InputLabelProps={{ shrink: true }}
              value={showroom?.phone}
              onChange={createHandleOnChangeTextField("phone")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"operatingTime"}
              label={"운영 시간"}
              placeholder={"ex) 월요일 ~ 일요일 : 11시~20시 (연중 무휴)"}
              InputLabelProps={{ shrink: true }}
              value={showroom?.operatingTime}
              onChange={createHandleOnChangeTextField("operatingTime")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showroom?.onDisplay}
                    onChange={(_event, checked) => {
                      setShowroom((showroom) => {
                        if (!showroom) return showroom
                        return { ...showroom, onDisplay: checked }
                      })
                    }}
                  />
                }
                label="노출"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            color={"error"}
            onClick={() => {
              dispatch({ type: ShowroomPageActionCode.CLOSE_EDIT_SHOWROOM_POPUP })
            }}
          >
            취소
          </Button>
          <Button color={"primary"} onClick={handleOnClickSubmitButton}>
            수정
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default EditShowroomPopup
