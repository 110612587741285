import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";

import {CategoryType} from "types/CategoryType";
import {updateCategory} from "../../apis/categoryAPI";

const onlyUpperCase = (str: string): string => str.replaceAll(/[^a-zA-Z]/g, "").toUpperCase()

type AddCategoryPopupProps = {
    open: boolean;
    handleClose: () => void;
    editCategory: CategoryType;
}
const initState = {onDisplay: false}

const EditCategoryPopup: React.FC<AddCategoryPopupProps> = ({open, handleClose, editCategory}) => {
    const [state, setState] = useState<CategoryType>(initState)

    useEffect(() => {
        setState({...initState, sid: editCategory?.sid, name: editCategory?.name, onDisplay: editCategory?.onDisplay})
    }, [open])

    const handleCancel = () => handleClose()
    const handleUpdate = () => {
        updateCategory(state.sid as string, state).then(() => {handleClose()})
    }

    return (
        <Dialog open={open} maxWidth={"xs"} fullWidth>
            <DialogTitle>카테고리 수정</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DialogContentText>
                            추가할 카테고리의 정보를 입력 해주세요. <b>SID는 카테고리를 구분할 ID 입니다</b>. 알파벳 대문자로만 입력 해주세요.
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id={"sid"}
                            label={"SID"}
                            placeholder={"ex) WATCH"}
                            InputLabelProps={{shrink: true,}}
                            helperText={"알파벳 대문자로만 작성 해주세요."}
                            onChange={(params) => {
                                setState({...state, sid: onlyUpperCase(params.target.value)})
                            }}
                            value={state?.sid}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id={"name"}
                            label={"카테고리명"}
                            placeholder={"ex) 시계"}
                            helperText={"사용자에게 노출될 카테고리명입니다."}
                            InputLabelProps={{shrink: true,}}
                            onChange={(params) => setState({...state, name: params.target.value})}
                            autoFocus
                            value={state?.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    checked={state?.onDisplay}
                                    onChange={(params) => setState({...state, onDisplay: params.target.checked})}
                                />}
                                label="노출"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={handleCancel}>취소</Button>
                <Button color={"primary"} onClick={handleUpdate}>수정</Button>
            </DialogActions>
        </Dialog>
    )
}


// @ts-ignore
export default EditCategoryPopup