import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import ConfirmReceivingTable from "./ConfirmReceivingTable"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { confirmReceiving, getSellOrder } from "apis/sellOrderAPI"
import { useReactToPrint } from "react-to-print"
import { isEmpty } from "lodash"
import ECUQRTableToPrint from "../../../../components/Popup/ProductQrPopup/ECUQRTableToPrint"
import ECUQRTableListToPrint from "../../../../components/Popup/ProductQrPopup/ECUQRTableListToPrint"
import PolyBagQRTableToPrint from "../../../../components/Popup/ProductQrPopup/PloyBagQRTableToPrint"
import PolyBagQRTableListToPrint from "../../../../components/Popup/ProductQrPopup/PloyBagQRTableListToPrint"

type ConfirmReceivingPopupProps = {
  open: boolean,
  initOrders: SellOrderType[],
  handleClose: Function,
}

const ConfirmReceivingPopup: React.FC<ConfirmReceivingPopupProps> = ({ open, initOrders, handleClose }) => {

  const [orders, setOrders] = useState<SellOrderType[]>(initOrders)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectedOrder, setSelectedOrder] = useState<SellOrderType>()
  const [openECUPopup, setOpenECUPopup] = useState<boolean>(false)
  const [openPolyBagPopup, setOpenPolyBagPopup] = useState<boolean>(false)
  const [canPrintList, setCanPrintList] = useState<boolean>(false)

  const handleSelection = (ids: (string | number)[]) => {
    if (isEmpty(ids)) {
      return
    }

    setSelectedIds(ids as string[])
    getSellOrder(ids[0].toString()).then((result) => {
      setSelectedOrder(result)
    })
  }

  const handleConfirmClick = () => {
    confirmReceiving([selectedOrder?.id!!]).then(res => {
      setOrders(
        orders.map(it => {
          if (it.id === selectedOrder?.id) {
            // @ts-ignore
            it.sellOrderStatus = res?.[0]?.sellOrderStatus
            // @ts-ignore
            it.waitingDiagnosisAt = res?.[0]?.waitingDiagnosisAt
            setSelectedOrder({ ...selectedOrder, sellOrderStatus: it.sellOrderStatus, waitingDiagnosisAt: it.waitingDiagnosisAt })
          }
          return it
        }),
      )
    })
  }
  const handlePrintECUQRClick = useReactToPrint({
    // @ts-ignore
    content: () => ecuQRRef.current,
    copyStyles: true,
  })
  const handlePrintPolyBagQRClick = useReactToPrint({
    // @ts-ignore
    content: () => polyBagRef.current,
    copyStyles: true,
  })
  const handleECUOpen = () => {
    setOpenECUPopup(true)
  }
  const handlePolyBagOpen = () => {
    setOpenPolyBagPopup(true)
  }

  const ecuQRRef = useRef()
  const polyBagRef = useRef()

  useEffect(() => {
    setOrders(initOrders)
    setSelectedIds([])
    setSelectedOrder(undefined)
    setOpenECUPopup(false)
    setOpenPolyBagPopup(false)
    setCanPrintList(initOrders.filter((e) => (e.sellOrderStatus === SellOrderStatusCode.픽업_준비 || e.sellOrderStatus === SellOrderStatusCode.입고중)).length === 0)
  }, [initOrders])

  return <Dialog open={open} maxWidth={"xl"} fullWidth>
    <DialogTitle>입고상품 확인 및 QR 생성</DialogTitle>
    <DialogContent>
      {openECUPopup && <ECUQRTableListToPrint open={openECUPopup} orderIds={initOrders.map((i) => i.id!!)} handleClose={() => {
          setOpenECUPopup(false)
        }} />}
      {openPolyBagPopup && <PolyBagQRTableListToPrint open={openPolyBagPopup} orderIds={initOrders.map((i) => i.id!!)} handleClose={() => {
          setOpenPolyBagPopup(false)
        }} />}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid textAlign={"end"} sx={{ marginBottom: 2 }}>
            <Button disabled={!canPrintList} variant={"contained"} color={"primary"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={handleECUOpen}>ECU QR 출력</Button>
            <Button disabled={!canPrintList} variant={"contained"} color={"primary"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={handlePolyBagOpen}>폴리백 QR 출력</Button>
          </Grid>
          <ConfirmReceivingTable
            orders={orders}
            selectedIds={selectedIds}
            handleSelection={handleSelection}
          />
        </Grid>
        <Grid item xs={6} sx={{ alignSelf: "center" }}>
          {selectedOrder ?
            <>
              <Grid textAlign={"start"} sx={{ marginBottom: 2 }}>
                <Button disabled={selectedOrder?.sellOrderStatus !== SellOrderStatusCode.입고중} variant={"contained"} color={"error"} size={"medium"} sx={{ margin: "10px 0 0 10px" }} onClick={handleConfirmClick}>입고확인</Button>
              </Grid>
              {/*@ts-ignore*/}
              <div ref={ecuQRRef}>
                <ECUQRTableToPrint sellOrder={selectedOrder} />
              </div>
              <Grid textAlign={"end"} sx={{ marginBottom: 2 }}>
                <Button disabled={selectedOrder?.sellOrderStatus === SellOrderStatusCode.픽업_준비 || selectedOrder?.sellOrderStatus === SellOrderStatusCode.입고중} variant={"contained"} color={"error"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={handlePrintECUQRClick}>ECU QR 출력</Button>
              </Grid>
              {/*@ts-ignore*/}
              <div ref={polyBagRef}>
                <PolyBagQRTableToPrint sellOrder={selectedOrder} />
              </div>
              <Grid textAlign={"end"}>
                <Button disabled={selectedOrder?.sellOrderStatus === SellOrderStatusCode.픽업_준비 || selectedOrder?.sellOrderStatus === SellOrderStatusCode.입고중} variant={"contained"} color={"error"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={handlePrintPolyBagQRClick}>폴리백 QR 출력</Button>
              </Grid>
            </>
            : <Typography textAlign={"center"}>좌측 목록에서 주문을 선택해주세요</Typography>
          }
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color={"error"} onClick={() => handleClose()}>닫기</Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmReceivingPopup
