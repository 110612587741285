import {Button, Grid} from "@mui/material"
import React, {useEffect, useReducer, useState} from "react";
import {PageType} from "types/PageType";
import {useLocation} from "react-router-dom";
import ArticleTable from "./ArticleTable";
import EditArticlePopup from "./EditArticlePopup";
import {Article} from "types/MagazineType";
import {getArticles} from "../../../apis/magazineAPI";

export interface ArticleState {
    selectedIds: string[],
    searchParams: SearchParams,
    articles: PageType<Article>,
    isShowEditPopup: boolean,
    editArticle?: Article,
    refresh: boolean,
    loading: boolean
}

const initState: ArticleState = {
    selectedIds: [],
    searchParams: {page: 0, size: 50},
    articles: {content:[], pageable:{pageSize: 50, pageNumber: 0}, totalPages:0, totalElements:0, numberOfElements:0},
    isShowEditPopup: false,
    editArticle: undefined,
    refresh: false,
    loading: false
}

interface ArticleAction {
    type: string,
    payload?: any
}

interface SearchParams {
    page?: number,
    size?: number,
}

function reducer(state: ArticleState, action: ArticleAction): ArticleState {
    switch (action.type) {
        case 'SELECTION_ARTICLE_IDS': return {...state, selectedIds: action.payload}
        case 'FETCH_ARTICLE': return {...state, articles: action.payload}
        case 'SHOW_VIEW_ARTICLE_POPUP': return {...state,  isShowEditPopup: true, editArticle: action.payload}
        case 'HIDE_VIEW_ARTICLE_POPUP': return {...state,  isShowEditPopup: false}
        case 'REFRESH': return {...state, refresh: !state.refresh}
        case 'START_LOADING': return {...state, loading: true}
        case 'END_LOADING': return {...state, loading: false}
        case 'SET_SEARCH_PARAMS': return {...state, searchParams: action.payload}
    }

    return state;
}

const MagazineArticleListPage = () => {
    const [state, dispatch] = useReducer(reducer, initState)

    const fetchArticles = (searchParams: any = {}) => {
        dispatch({
            type: 'SET_SEARCH_PARAMS',
            payload: {page: searchParams?.page || initState.searchParams.page, size: searchParams?.size || initState.searchParams.size}
        })
        getArticles(searchParams).then(res => dispatch({type: 'FETCH_ARTICLE', payload: res}))
    }

    const handleNewContentClick = (event: any) => {
        dispatch({type: 'SHOW_VIEW_ARTICLE_POPUP', payload: undefined})
    }

    useEffect(() => {
        fetchArticles(state.searchParams)
    }, [state.refresh])

    return (
        <Grid container spacing={2}>
            <EditArticlePopup open={state.isShowEditPopup} selectedArticle={state.editArticle} handleClose={
                () => {
                    dispatch({type: 'HIDE_VIEW_ARTICLE_POPUP'})
                    dispatch({type: 'REFRESH'})
                }
            } />
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <ArticleTable
                        state = {state}
                        dispatch = {dispatch}
                        fetch= { fetchArticles }
                        components={[
                            <Button variant={"contained"} color={"error"} size={"small"} style={{margin: '0 2px'}} onClick={handleNewContentClick}>아티클 등록</Button>,
                        ]}
                    />
                </Grid>
            </Grid>
        </Grid>)
}

export default MagazineArticleListPage