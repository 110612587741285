import { PageType } from "types/PageType"
import {
  SerialNumberDetailType,
  SerialNumberListSearchParams,
  SerialNumberListItemType,
  SerialNumberRegisterBody, OrderHistoryBySerialNumberType,
} from "types/SerialNumberType"
import { httpGet, httpPost, httpPut } from "utils/httpAPI"

export const getSerialNumberList: (
  params: SerialNumberListSearchParams
) => Promise<PageType<SerialNumberListItemType>> = (params) => httpGet(`/serial-numbers`, params)

export const getSerialNumberDetail: (serialNo: string) => Promise<SerialNumberDetailType> = (serialNo) =>
  httpGet(`/serial-numbers/${serialNo}`)

export const postSerialNumber: (body: SerialNumberRegisterBody) => Promise<SerialNumberDetailType> = (body) =>
  httpPost(`/serial-numbers`, body)

export const putSerialNumber: (body: SerialNumberRegisterBody) => Promise<SerialNumberDetailType> = (body) =>
  httpPut(`/serial-numbers/${body.serialNo}`, {
    blacklist: body.blacklist,
    detailModel: body.detailModel,
  })

export const getSerialNumberMemoCount: (serialNo: string) => Promise<{ label: string; count: number }[]> = (serialNo) =>
  httpGet(`/user-memos/counts`, { serialNo })

export const getOrderHistoryBySerialNumber: (serialNo: string) => Promise<OrderHistoryBySerialNumberType[]> = (serialNo) =>
  httpGet(`/serial-numbers/order-history/${serialNo}`)