import styled from "@emotion/styled"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { numberWithCommas } from "utils/NumberUtils"

type Props = {
  initialPrice: number
  description: string
  penaltyFeeRate?: number
  penaltyType?: string
  penaltyAmount?: number
}

const PenaltyTypeInformation = ({ penaltyType, initialPrice, description, penaltyAmount, penaltyFeeRate }: Props) => {
  const formatRateToPercentage = (rate: number | undefined) => {
    return rate ? `${(rate * 100).toFixed(2)}%` : "-"
  }
  return (
    <TableWrapper>
      <InlineWrapper>
        <DetailTableColumCell title={"유형"}>{penaltyType || "-"}</DetailTableColumCell>
        <DetailTableColumCell title={"상품 상태"}>{description}</DetailTableColumCell>
        <DetailTableColumCell title={"패널티 기준가"}>{numberWithCommas(initialPrice) || "0"}원</DetailTableColumCell>
        <DetailTableColumCell title={"비율"}>
          {penaltyFeeRate ? formatRateToPercentage(penaltyFeeRate) : "-"}
        </DetailTableColumCell>
        <DetailTableColumCell title={"패널티 금액"}>{numberWithCommas(penaltyAmount) || "0"}원</DetailTableColumCell>
      </InlineWrapper>
    </TableWrapper>
  )
}

export default PenaltyTypeInformation

const TableWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  div.table-column-cell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`
