import { Dialog, DialogTitle, TextField } from "@mui/material"
import { FunctionComponent } from "react"

import { ModelListItemType } from "types/ModelListItemType"
import Button from "components/Button"
import styled from "@emotion/styled"
import { useModelUpdateData } from "./useModelUpdateData"
import { ModelImageInput } from "./ModelImageInput"
import { toast } from "react-toastify"
import { isNil } from "utils/validationUtils"

const imageUploaderList: {
  label: string
  key: "thumbnail" | "thumbnailBackground" | "thumbnailBackgroundSquare"
  height?: number
}[] = [
  { label: "대표 모델 이미지", key: "thumbnail" },
  { label: "모델 상세 BG", key: "thumbnailBackground", height: 432 },
  { label: "모델 리스트 BG", key: "thumbnailBackgroundSquare" },
]

export const ModelUpdatePopup: FunctionComponent<{
  modelListItem: ModelListItemType | null
  brand: string
  onClickCancel: () => void
  onClickConfirm: () => void
}> = ({ modelListItem: model, brand, onClickCancel, onClickConfirm }) => {
  const { modelState, changeState, handleSaveModel } = useModelUpdateData(model, brand)

  return (
    <Dialog open={true} maxWidth={"lg"}>
      <DialogTitle
        style={{
          fontWeight: 700,
          fontSize: "24px",
          padding: "48px 48px 24px",
        }}
      >
        모델 상세
      </DialogTitle>
      <Wrapper>
        <SubTitle>모델 기본 정보</SubTitle>
        <InlineWrapper>
          <Label>브랜드</Label>
          <Text>{brand}</Text>
        </InlineWrapper>
        <InlineWrapper>
          <Label>모델명</Label>
          <TextField
            required
            id={"name"}
            placeholder={"ex) SUBMARINER"}
            InputLabelProps={{ shrink: true }}
            value={modelState.name}
            variant="standard"
            onChange={(event) => changeState("name", event.target.value)}
          />
        </InlineWrapper>
        <div style={{ height: 16 }} />
        <SubTitle>사진 업로드</SubTitle>
        <InlineWrapper style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
          {imageUploaderList.map(({ label, key, height }) => {
            return (
              <ModelImageInput
                key={key}
                label={label}
                src={modelState[key]}
                height={height}
                onUploadFile={(url) => {
                  changeState(key, url)
                }}
              />
            )
          })}
        </InlineWrapper>
      </Wrapper>
      <ButtonListWrapper>
        <Button buttonStyle="black-border" onClick={onClickCancel}>
          닫기
        </Button>
        <Button
          disabled={!modelState.name || !modelState.thumbnail}
          buttonStyle="blue-fill"
          onClick={async () => {
            try {
              await handleSaveModel()
              onClickConfirm()
            } catch {
              toast.error(`모델 ${isNil(model) ? "등록" : "수정"}에 실패했습니다. `)
            }
          }}
        >
          저장
        </Button>
      </ButtonListWrapper>
    </Dialog>
  )
}

const Label = styled.div`
  min-width: 64px;
  height: 38px;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: #3b464f;
`

const Wrapper = styled.div`
  padding: 0 48px 24px;
  display: flex;
  flex-direction: column;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
`

const SubTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin: 16px 0;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
`

const ButtonListWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px 16px;

  button {
    max-width: 120px;
    margin-left: 8px;
  }
`
