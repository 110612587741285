import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import "../../App.css"
import RowBlock from "components/RowBlock"
import { DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { toast } from "react-toastify"
import CsvFileUploader from "./CsvFileUploader"
import UserIdSelector from "./UserIdSelector"
import styled from "@emotion/styled"
import { isNil } from "utils/validationUtils"
import { MessageStatusCode, NotificationTypeCode, UserGroupTypeCode, UserNotificationScheduleType } from "../../types/UserNotificationScheduleType"
import { cancelSchedule, createSchedule, getSchedule, updateSchedule } from "../../apis/notificationAPI"
import SelectAppLinkPopup from "../../components/Popup/SelectAppLinkPopup"

type EditableScheduleType = Omit<UserNotificationScheduleType, "id" | "canceledBy" | "createdAt" | "createdBy" | "updatedAt" | "updatedBy">

const defaultScheduleState: EditableScheduleType = {
  status: MessageStatusCode.발송대기,
  notificationType: NotificationTypeCode.마케팅_기타,
  userGroupType: UserGroupTypeCode.개발팀,
  userIds: [],
  inAppMessage: {
    title: "",
    titleSecondary: "",
    linkUrl: "",
    usage: true,
  },
  pushMessage: {
    title: "",
    body: "",
    linkUrl: "",
    ad: true,
    usage: true,
  },
  scheduledAt: DateTime.now().startOf("day").plus({ day: 3, hour: 11 }).toISO(),
}

const userSourceOption: { value: string; title: string }[] = [
  {
    value: "ID",
    title: "유저 휴대폰번호 입력",
  },
  { value: "CSV", title: "CSV 업로드" },
]

type EditSchedulePopupProps = {
  open: boolean
  selectedScheduleId: number | undefined
  handleClose: () => void
}

const EditUserNotificationSchedulePopup: React.FC<EditSchedulePopupProps> = ({ open, selectedScheduleId, handleClose }) => {
  const [selectedUserSourceOption, setSelectedUserSourceOption] = useState<string>("ID")
  const [schedule, setSchedule] = useState<EditableScheduleType>(defaultScheduleState)
  const [editable, setEditable] = useState<boolean>(false)
  const [useSameMessage, setUseSameMessage] = useState<boolean>(true)
  const [selectInAppMessageLinkPopup, setSelectInAppMessageLinkPopup] = useState<boolean>(false)
  const [selectPushMessageLinkPopup, setSelectPushMessageLinkPopup] = useState<boolean>(false)

  const cancel = () => {
    if (isNil(selectedScheduleId)) return

    cancelSchedule(selectedScheduleId)
      .then(() => {
        toast.success("취소 완료")
        handleClose()
      })
      .catch((error) => {
        toast.error(`취소 실패 (${error.message || error})`)
      })
  }

  const save = () => {
    if (!getIsValidForm()) {
      return
    }


    const pushMessage = { ...schedule.pushMessage, body: schedule.pushMessage?.ad ? ("(광고) " + schedule.pushMessage.body) : schedule.pushMessage?.body }
    const inAppMessage = { ...schedule.inAppMessage, titleSecondary: schedule.pushMessage?.ad ? ("(광고) " + schedule.inAppMessage?.titleSecondary) : schedule.inAppMessage?.titleSecondary }

    if (!isNil(selectedScheduleId)) {
      updateSchedule(selectedScheduleId, { ...schedule, id: selectedScheduleId, pushMessage: pushMessage, inAppMessage: inAppMessage })
        .then(() => {
          toast.success("수정 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`수정 실패 (${error.message || error})`)
        })
    } else {
      createSchedule({ ...schedule, pushMessage: pushMessage, inAppMessage: inAppMessage })
        .then(() => {
          toast.success("등록 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`등록 실패 (${error.message || error})`)
        })
    }
  }

  const getIsValidForm = (): boolean => {
    if (schedule?.userGroupType === UserGroupTypeCode.특정회원) {
      if (!schedule.userIds?.length) {
        toast.error("푸시를 발송할 대상 회원을 선택해주세요.")
        return false
      }
    }

    console.log(schedule.pushMessage?.usage)
    console.log(schedule.inAppMessage?.usage)
    if (!(schedule.pushMessage?.usage || schedule.inAppMessage?.usage)) {
      toast.error("인앱/푸시 알림 발송 여부를 선택해주세요")
      return false
    }

    return true
  }

  useEffect(() => {
    if (open && !isNil(selectedScheduleId)) {
      getSchedule(selectedScheduleId).then((res) => {
        const pushMessage = { ...res.pushMessage, body: res.pushMessage?.body?.replace(/(\(광고\))/g, "").trim() }
        const inAppMessage = { ...res.inAppMessage, titleSecondary: res.inAppMessage?.titleSecondary?.replace(/(\(광고\))/g, "").trim() }
        setSchedule({...res, pushMessage: pushMessage, inAppMessage: inAppMessage})
        setEditable(res.status === MessageStatusCode.발송대기)
      })
    } else {
      setSchedule(defaultScheduleState)
      setEditable(true)
    }
  }, [open, selectedScheduleId])

  const changeState = (key: string, value: any) => {
    setSchedule({ ...schedule, [key]: value })
  }

  return (
    <Dialog open={open} maxWidth={"lg"} fullWidth>
      <SelectAppLinkPopup open={selectInAppMessageLinkPopup} handleClose={(url: string) => {
        setSelectInAppMessageLinkPopup(false)
        if (useSameMessage) {
          const newInAppMessage = { ...schedule.inAppMessage, linkUrl: url }
          const newPushMessage = { ...schedule.pushMessage, linkUrl: url }
          setSchedule({ ...schedule, inAppMessage: newInAppMessage, pushMessage: newPushMessage })
        } else {
          const newInAppMessage = { ...schedule.inAppMessage, linkUrl: url }
          setSchedule({ ...schedule, inAppMessage: newInAppMessage })
        }
      }} />
      <SelectAppLinkPopup open={selectPushMessageLinkPopup} handleClose={(url: string) => {
        setSelectPushMessageLinkPopup(false)
        const newMessage = { ...schedule.pushMessage, linkUrl: url }
        setSchedule({ ...schedule, pushMessage: newMessage })
      }} />
      <DialogTitle>
        <Typography fontWeight={"bold"} fontSize={"30px"}>
          {isNil(selectedScheduleId) ? "푸시메시지 등록" : "푸시메시지 수정"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} direction={"column"}>
          <RowBlock title={"상태"}>
            <Typography>{schedule?.status || "신규"}</Typography>
          </RowBlock>
          <RowBlock title={"발송 예정 시각"}>
            <DateTimePicker
              disabled={!editable}
              onChange={(e) => {
                let dt = e?.startOf("minute")
                if (dt && dt?.minute % 5) dt = dt.plus({ minute: 5 - (dt?.minute % 5) })
                setSchedule({ ...schedule, scheduledAt: dt?.toISO() ?? defaultScheduleState.scheduledAt! })
              }}
              value={DateTime.fromISO(schedule.scheduledAt ?? defaultScheduleState.scheduledAt!)}
              inputFormat={"yyyy-MM-dd HH:mm:ss"}
              renderInput={(props) => <TextField
                {...props}
                helperText={"5분 단위로만 예약이 가능합니다."}
              />}
            />
          </RowBlock>
          <RowBlock title={"발송 대상"}>
            <Select
              disabled={!editable}
              value={schedule?.userGroupType || "개발팀"}
              onChange={(e) =>
                setSchedule((prev) => ({
                  ...prev,
                  userGroupType: e.target.value as UserGroupTypeCode,
                  userIds: [],
                }))
              }
            >
              <MenuItem value={"개발팀"}>개발팀</MenuItem>
              <MenuItem value={"특정회원"}>특정회원</MenuItem>
              <MenuItem value={"전체회원"}>전체회원</MenuItem>
              <MenuItem value={"내국인회원"}>내국인회원</MenuItem>
              <MenuItem value={"외국인회원"}>외국인회원</MenuItem>
            </Select>
          </RowBlock>
          {schedule?.userGroupType === "특정회원" && (
            <RowBlock title={"대상 선택 방식"}>
              <Select
                fullWidth
                disabled={!editable}
                value={selectedUserSourceOption || "ID"}
                onChange={(e) => setSelectedUserSourceOption(e.target.value)}
              >
                {userSourceOption.map(({ value, title }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {title}
                    </MenuItem>
                  )
                })}
              </Select>
              {selectedUserSourceOption === "CSV" && (
                <div style={{ margin: "10px 0" }}>
                  <CsvFileUploader changeState={changeState} />
                </div>
              )}
              {selectedUserSourceOption === "ID" && (
                <div style={{ margin: "10px 0" }}>
                  <UserIdSelector userIdList={schedule.userIds || []} changeState={changeState} />
                </div>
              )}
              {!!schedule.userIds?.length && (
                <div>
                  <h4>지급할 유저 ID ({schedule.userIds.length}명)</h4>
                  <SelectedIdListWrapper>
                    {schedule.userIds.map((userId: number) => (
                      <SelectedIdItem key={userId}>
                        {userId}
                        <button
                          className="deleteButton"
                          onClick={() =>
                            setSchedule((prev) => ({
                              ...prev,
                              userIds: (prev.userIds || []).filter((prevId: number) => prevId !== userId),
                            }))
                          }
                        >
                          X
                        </button>
                      </SelectedIdItem>
                    ))}
                  </SelectedIdListWrapper>
                </div>
              )}
            </RowBlock>
          )}

          <RowBlock title={"알림 타입"}>
            <Select
              disabled={!editable}
              defaultValue={NotificationTypeCode.마케팅_기타}
              value={schedule?.notificationType}
              onChange={(event) =>
                setSchedule((prev) => ({
                  ...prev,
                  notificationType: event.target.value as NotificationTypeCode,
                }))
              }
            >
              <MenuItem value={"매거진_신규"}>매거진_신규</MenuItem>
              <MenuItem value={"마케팅_기타"}>마케팅_기타</MenuItem>
              <MenuItem value={"공지_이벤트"}>공지_이벤트</MenuItem>
              <MenuItem value={"공지_기타"}>공지_기타</MenuItem>
            </Select>
          </RowBlock>

          <RowBlock title={"동일 메시지 사용"}>
            <Switch
              checked={useSameMessage}
              disabled={!editable}
              onChange={(e, check) => {
                setUseSameMessage(check)
                if (check) {
                  const newMessage = {
                    ...schedule.pushMessage,
                    title: schedule.inAppMessage?.title,
                    body: schedule.inAppMessage?.titleSecondary,
                    linkUrl: schedule.inAppMessage?.linkUrl,
                  }
                  setSchedule({ ...schedule, pushMessage: newMessage })
                }
              }}
            />
          </RowBlock>
          <Stack spacing={1} direction={"row"}>
            <Stack spacing={1} width={"50%"} direction={"column"}>
              <RowBlock title={"인앱 알림"}>
                <Switch
                  checked={schedule.inAppMessage?.usage}
                  disabled={!editable}
                  onChange={(e, check) => {
                    const newMessage = { ...schedule.inAppMessage, usage: check }
                    setSchedule({ ...schedule, inAppMessage: newMessage })
                  }}
                />
              </RowBlock>
              <RowBlock title={"타이틀"}>
                <TextField
                  size={"small"}
                  fullWidth
                  multiline={true}
                  disabled={!editable}
                  value={schedule.inAppMessage?.title}
                  onChange={(e) => {
                    if (useSameMessage) {
                      const newInAppMessage = { ...schedule.inAppMessage, title: e.target.value }
                      const newPushMessage = { ...schedule.pushMessage, title: e.target.value }
                      setSchedule({ ...schedule, inAppMessage: newInAppMessage, pushMessage: newPushMessage })
                    } else {
                      const newInAppMessage = { ...schedule.inAppMessage, title: e.target.value }
                      setSchedule({ ...schedule, inAppMessage: newInAppMessage })
                    }
                  }}
                />
              </RowBlock>
              <RowBlock title={"본문"}>
                <TextField
                  size={"small"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{schedule.pushMessage?.ad ? "(광고)" : ""}</InputAdornment>
                    ),
                  }}
                  fullWidth
                  multiline={true}
                  disabled={!editable}
                  value={schedule.inAppMessage?.titleSecondary}
                  onChange={(e) => {
                    if (useSameMessage) {
                      const newInAppMessage = { ...schedule.inAppMessage, titleSecondary: e.target.value }
                      const newPushMessage = { ...schedule.pushMessage, body: e.target.value }
                      setSchedule({ ...schedule, inAppMessage: newInAppMessage, pushMessage: newPushMessage })
                    } else {
                      const newInAppMessage = { ...schedule.inAppMessage, titleSecondary: e.target.value }
                      setSchedule({ ...schedule, inAppMessage: newInAppMessage })
                    }
                  }}
                />
              </RowBlock>
              <RowBlock title={`링크 (${(schedule.inAppMessage?.linkUrl && schedule.inAppMessage?.linkUrl?.split("\n").length) || 0}건)`}>
                <TextField
                  size={"small"}
                  fullWidth
                  multiline={true}
                  minRows={1}
                  maxRows={10}
                  disabled={!editable}
                  value={schedule.inAppMessage?.linkUrl}
                  onChange={(e) => {
                    if (useSameMessage) {
                      const newInAppMessage = { ...schedule.inAppMessage, linkUrl: e.target.value }
                      const newPushMessage = { ...schedule.pushMessage, linkUrl: e.target.value }
                      setSchedule({ ...schedule, inAppMessage: newInAppMessage, pushMessage: newPushMessage })
                    } else {
                      const newInAppMessage = { ...schedule.inAppMessage, linkUrl: e.target.value }
                      setSchedule({ ...schedule, inAppMessage: newInAppMessage })
                    }
                  }}
                  placeholder={"viver://"}
                />
                <Button
                  variant={"outlined"}
                  size={"small"}
                  disabled={!editable}
                  onClick={() => setSelectInAppMessageLinkPopup(true)}
                >
                  링크 선택
                </Button>
              </RowBlock>
            </Stack>
            <Stack spacing={1} width={"50%"} direction={"column"}>
              <RowBlock title={"푸시 알림"}>
                <Switch
                  checked={schedule.pushMessage?.usage}
                  disabled={!editable}
                  onChange={(e, check) => {
                    const newMessage = { ...schedule.pushMessage, usage: check }
                    setSchedule({ ...schedule, pushMessage: newMessage })
                  }}
                />
              </RowBlock>
              <RowBlock title={"타이틀"}>
                <TextField
                  size={"small"}
                  fullWidth
                  multiline={true}
                  disabled={!editable || useSameMessage}
                  value={schedule.pushMessage?.title}
                  onChange={(e) => {
                    const newMessage = { ...schedule.pushMessage, title: e.target.value }
                    setSchedule({ ...schedule, pushMessage: newMessage })
                  }}
                />
              </RowBlock>
              <RowBlock title={"본문"}>
                <TextField
                  size={"small"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{schedule.pushMessage?.ad ? "(광고)" : ""}</InputAdornment>
                    ),
                  }}
                  fullWidth
                  multiline={true}
                  disabled={!editable || useSameMessage}
                  value={schedule.pushMessage?.body}
                  onChange={(e) => {
                    const newMessage = { ...schedule.pushMessage, body: e.target.value }
                    setSchedule({ ...schedule, pushMessage: newMessage })
                  }}
                />
              </RowBlock>
              <RowBlock title={`링크 (${(schedule.pushMessage?.linkUrl && schedule.pushMessage?.linkUrl?.split("\n").length) || 0}건)`}>
                <TextField
                  size={"small"}
                  fullWidth
                  multiline={true}
                  minRows={1}
                  maxRows={10}
                  disabled={!editable || useSameMessage}
                  value={schedule.pushMessage?.linkUrl}
                  onChange={(e) => {
                    const newMessage = { ...schedule.pushMessage, linkUrl: e.target.value }
                    setSchedule({ ...schedule, pushMessage: newMessage })
                  }}
                  placeholder={"viver://"}
                />
                <Button
                  variant={"outlined"}
                  size={"small"}
                  disabled={!editable || useSameMessage}
                  onClick={() => setSelectPushMessageLinkPopup(true)}
                >
                  링크 선택
                </Button>
              </RowBlock>
              <RowBlock title={"광고성"}>
                <Switch
                  checked={schedule.pushMessage?.ad}
                  disabled={!editable}
                  onChange={(e, check) => {
                    const newMessage = { ...schedule.pushMessage, ad: check }
                    setSchedule({ ...schedule, pushMessage: newMessage })
                  }}
                />
              </RowBlock>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} disabled={!editable} variant={"contained"} color={"info"} onClick={save}>
          저장하기
        </Button>
        {!isNil(selectedScheduleId) && (
          <Button size={"medium"} disabled={!editable} variant={"contained"} color={"error"} onClick={cancel}>
            발송취소
          </Button>
        )}
        <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditUserNotificationSchedulePopup

const SelectedIdListWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    max-width: calc(500px - 32px);
    flex-wrap: wrap;
`

const SelectedIdItem = styled.div`
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px 10px;
    background: rgba(224, 224, 224, 1);
    font-size: 12px;
    margin: 0 5px 5px 0;

    button.deleteButton {
        margin-left: 5px;
        font-size: 12px;
    }
`
