import { UploadResultType } from "types/UploadResultType"
import { httpPostMultipartFile } from "../utils/httpAPI"

export const upload: (file: FileList, options?: [{ key: string; value: any }]) => Promise<UploadResultType> = (
  file,
  options
) => httpPostMultipartFile("/resources/admin", file, options)

export const uploadForNoResize: (file: FileList, options?: [{ key: string; value: any }]) => Promise<UploadResultType> = (
  file,
  options
) => httpPostMultipartFile("/resources/admin/noresize", file, options)


export const uploadFile: (file: File, options?: [{ key: string; value: any }]) => Promise<UploadResultType> = (
  file,
  options
) => httpPostMultipartFile("/resources/admin", [file], options)
