import React, { useState } from "react"
import { Box, Button, Modal } from "@mui/material"
import { DaumPostcodeEmbed } from "react-daum-postcode"

type Props = {
  handleClose: Function
}

const SearchAddressPopup = ({ handleClose }: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "400px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    margin: "auto",
  }

  return (
    <>
      <Button
        variant={"outlined"}
        color={"success"}
        size={"medium"}
        onClick={() => {
          setOpen(true)
        }}>
        주소 검색
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
          handleClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <DaumPostcodeEmbed
            onComplete={(data) => {
              setOpen(false)
              handleClose(data)
            }}
            style={{ height: "100%" }} />
        </Box>
      </Modal>
    </>
  )
}

export default SearchAddressPopup
