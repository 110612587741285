import _ from 'lodash'
import {PageType} from "types/PageType";

export function replaceItem(arr: any[], comparator: any, value: any) {
    return arr.map((item) =>  {
        if (comparator(item)) return value
        return item
    })
}

export function replacePageItem(page: PageType<any>, comparator: any, value: any) {
    return {
        ...page,
        content: page.content.map((item) => {
            if (comparator(item)) return value
            return item
        })
    }
}

export function changeItem(arr: any[], indexA: number, indexB: number) {
    const arrCopy = [...arr]
    const temp = arrCopy[indexA]
    arrCopy[indexA] = arrCopy[indexB]
    arrCopy[indexB] = temp
    return arrCopy
}

export function changeAt(arr: any[], index: number, obj: any) {
    return arr.map((item, idx) => {
        return idx === index ? obj : item
    })
}

export function insertAt(arr: any[], index: number, obj: any) {
    return [
        ...arr.slice(0, index < 0 ? 0 : index),
        obj,
        ...arr.slice(index > arr.length ? arr.length : index)
    ]
}

export function removeAt(arr: any[], index: number) {
    const copy = [...arr]
    copy.splice(index, 1)
    return copy
}