import { httpPost } from "../utils/httpAPI"
import {
  CancelSellOrderPenaltyFeeRequest,
  SellOrderPenaltyFeeResponse,
  DisqualifiedOrderPenaltyFeeRequest,
  ResellPriceRequest,
  ResellPriceResponse,
} from "types/EstimationType"

export const getResellPrice: (dto: ResellPriceRequest) => Promise<ResellPriceResponse> =
  // @ts-ignore
  (dto) => httpPost("/estimates/resell-price", dto)

export const getCancelSellOrderPenaltyFee: (
  dto: CancelSellOrderPenaltyFeeRequest
) => Promise<SellOrderPenaltyFeeResponse> = (dto) => httpPost("/estimates/cancel-sell-order-penalty-fee", dto)

export const getDisqualifiedSellOrderPenaltyFee: (
  payload: DisqualifiedOrderPenaltyFeeRequest
) => Promise<SellOrderPenaltyFeeResponse> = (payload) =>
  httpPost("/estimates/disqualify-sell-order-penalty-fee", payload)
