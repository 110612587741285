import styled from "@emotion/styled"
import { Switch } from "@mui/material"
import { BoxHorizontal, BoxVertical, Typography } from "@viver-inc/viver-ui"
import { upload, uploadFile } from "apis/resourceAPI"
import React, { FunctionComponent } from "react"
import { EventFormStateTemplateBodyItemNoticeImageType } from "types/EventFormStateType"
import ImageUploadButton from "../../../../components/ImageUploadButton"

export const TemplateEditorNoticeImage: FunctionComponent<{
  templateBodyNoticeImage: EventFormStateTemplateBodyItemNoticeImageType
  setTemplateBodyNoticeImage: (templateBodyNoticeImage: EventFormStateTemplateBodyItemNoticeImageType) => void
}> = ({ templateBodyNoticeImage, setTemplateBodyNoticeImage }) => {

  return (
    <BoxVertical borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10}  bottom={10}>
      <Typography variant="sub18" weight="sb">
        유의사항
      </Typography>
      <BoxVertical >
          <BoxHorizontal alignItems="center">
            <Switch
              checked={templateBodyNoticeImage.onDisplay}
              onChange={(e, check) => {
                setTemplateBodyNoticeImage({ ...templateBodyNoticeImage, onDisplay: check })
              }}
            />
            <Typography variant="desc14" weight="r">
              노출
            </Typography>
          </BoxHorizontal>
          <BoxVertical flex={1} paddingHorizontal={10} paddingVertical={10}>
            <TemplateEditorNoticeImageItem
              label="열린상태 이미지"
              imageUrl={templateBodyNoticeImage.notice.openedImageUrl}
              imageUrlEnglish={templateBodyNoticeImage.notice.openedImageUrlEnglish}
              imageUrlJapanese={templateBodyNoticeImage.notice.openedImageUrlJapanese}
              setImageUrl={(openedImageUrl) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, openedImageUrl },
                })
              }
              setImageUrlEnglish={(openedImageUrlEnglish) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, openedImageUrlEnglish },
                })
              }
              setImageUrlJapanese={(openedImageUrlJapanese) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, openedImageUrlJapanese },
                })
              }
            />
            <TemplateEditorNoticeImageItem
              label="닫힌상태 이미지"
              imageUrl={templateBodyNoticeImage.notice.closedImageUrl}
              imageUrlEnglish={templateBodyNoticeImage.notice.closedImageUrlEnglish}
              imageUrlJapanese={templateBodyNoticeImage.notice.closedImageUrlJapanese}
              setImageUrl={(closedImageUrl) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, closedImageUrl },
                })
              }
              setImageUrlEnglish={(closedImageUrlEnglish) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, closedImageUrlEnglish },
                })
              }
              setImageUrlJapanese={(closedImageUrlJapanese) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, closedImageUrlJapanese },
                })
              }
            />
            <TemplateEditorNoticeImageItem
              label="본문이미지"
              imageUrl={templateBodyNoticeImage.notice.mainImageUrl}
              imageUrlEnglish={templateBodyNoticeImage.notice.mainImageUrlEnglish}
              imageUrlJapanese={templateBodyNoticeImage.notice.mainImageUrlJapanese}
              setImageUrl={(mainImageUrl) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, mainImageUrl },
                })
              }
              setImageUrlEnglish={(mainImageUrlEnglish) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, mainImageUrlEnglish },
                })
              }
              setImageUrlJapanese={(mainImageUrlJapanese) =>
                setTemplateBodyNoticeImage({
                  ...templateBodyNoticeImage,
                  notice: { ...templateBodyNoticeImage.notice, mainImageUrlJapanese },
                })
              }
            />
          </BoxVertical>
      </BoxVertical>
    </BoxVertical>
  )
}

const TemplateEditorNoticeImageItem: FunctionComponent<{
  label: string
  imageUrl: string | null
  imageUrlEnglish: string | null
  imageUrlJapanese: string | null
  setImageUrl: (imageUrl: string | null) => void
  setImageUrlEnglish: (imageUrl: string | null) => void
  setImageUrlJapanese: (imageUrl: string | null) => void
}> = ({ label, imageUrl, imageUrlEnglish, imageUrlJapanese, setImageUrl, setImageUrlEnglish, setImageUrlJapanese }) => {

  const handleOpenedImage = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageUrl(result.url)
    })
  }

  const handleOpenedImageEnglish = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageUrlEnglish(result.url)
    })
  }

  const handleOpenedImageJapanese = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageUrlJapanese(result.url)
    })
  }

  return (
    <>
      <BoxVertical  paddingHorizontal={10} paddingTop={5} >
        <Typography variant="desc16" weight="m" >{label}</Typography>
      </BoxVertical>
      <BoxVertical flex={1} paddingHorizontal={10} paddingTop={5} >
          <ImageItemWrapper >
            <BoxHorizontal paddingVertical={10} paddingHorizontal={10}>
              {/*<BoxHorizontal paddingLeft={10} paddingTop={10} width={120}>*/}
              {/*  <Typography variant="desc16" weight="m" marginBottom={10}>{label}</Typography>*/}
              {/*</BoxHorizontal>*/}
              <BoxHorizontal>
                <ImageWrapper>
                  <BoxVertical alignItems={"center"}>
                    <Typography variant="desc16" weight="m" marginBottom={10}>
                      한국어
                    </Typography>
                    <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageUrl} handleImage={handleOpenedImage}  handleDeleteImage={()=>{setImageUrl(null)}} />
                  </BoxVertical>
                </ImageWrapper>
                <ImageWrapper>
                  <BoxVertical alignItems={"center"}>
                    <Typography variant="desc16" weight="m" marginBottom={10}>
                      영어
                    </Typography>
                    <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageUrlEnglish} handleImage={handleOpenedImageEnglish}  handleDeleteImage={()=>{setImageUrlEnglish(null)}} />
                  </BoxVertical>
                </ImageWrapper>
                <ImageWrapper>
                  <BoxVertical alignItems={"center"}>
                    <Typography variant="desc16" weight="m" marginBottom={10}>
                      일본어
                    </Typography>
                    <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageUrlJapanese} handleImage={handleOpenedImageJapanese}  handleDeleteImage={()=>{setImageUrlJapanese(null)}} />
                  </BoxVertical>
                </ImageWrapper>
              </BoxHorizontal>
            </BoxHorizontal>
          </ImageItemWrapper>
        </BoxVertical>
    </>
  )
}

const ImageItemWrapper = styled.div`
    margin-bottom: 20px; 
    background-color: #eeeeee;
`
const ImageWrapper = styled.div`
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px; 
`