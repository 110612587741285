import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { toDateTimeStr } from "utils/datetimeUtil"
import { CouponPointUsageHistoryType } from "types/CouponPointUsageHistoryType"
import { CouponPointUsageHistoryTableHeaderItemList } from "../../TableHeader";
import { numberWithCommas } from "../../../../../../utils/NumberUtils";
import { Link } from "@mui/material";

type Props = {
    couponPointUsageHistory: CouponPointUsageHistoryType
} & HTMLAttributes<HTMLDivElement>

const CouponPointUsageHistoryListTableRow = ({ couponPointUsageHistory, ...props }: PropsWithChildren<Props>) => {

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: CouponPointUsageHistoryTableHeaderItemList[0].width }}>{couponPointUsageHistory.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: CouponPointUsageHistoryTableHeaderItemList[1].width }}>
        <Link href={`/coupon-codes?tab=list&filter=code&keyword=${couponPointUsageHistory.couponCode}`} target="_self">
          {couponPointUsageHistory.couponCode}
        </Link>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: CouponPointUsageHistoryTableHeaderItemList[2].width }}>
        {`${couponPointUsageHistory.user.name}(${couponPointUsageHistory.user.id})`}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: CouponPointUsageHistoryTableHeaderItemList[3].width, justifyContent: "end" }}>
          {`${numberWithCommas(couponPointUsageHistory.points)} P`}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: CouponPointUsageHistoryTableHeaderItemList[3].width, justifyContent: "end" }}>
          {`-${numberWithCommas(couponPointUsageHistory.usedPoints)} P`}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: CouponPointUsageHistoryTableHeaderItemList[4].width, justifyContent: "end" }}>
          {couponPointUsageHistory.updatedAt ? toDateTimeStr(couponPointUsageHistory.updatedAt) : "-"}
      </TableRowItem>
    </TableRow>
  )
}

export default CouponPointUsageHistoryListTableRow
