import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import "../../../App.css"
import { Author, Question, ContentBlock, ContentBlockTypeCode } from "types/MagazineType"
import RowBlock from "../../../components/RowBlock"
import { DatePicker, DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { toast } from "react-toastify"
import { createQuestion, getQuestion, getAuthors, getQuestions, updateQuestion } from "../../../apis/magazineAPI"
import { DetailModelType } from "types/DetailModelType"
import { getDetailModels } from "../../../apis/detailModelAPI"
import { ProductType } from "types/ProductType"
import { getProducts } from "../../../apis/productAPI"
import QuestionHtmlPreview from "./QuestionHtmlPreview";
import QuestionJsonEditor from "./QuestionJsonEditor";
import SearchDetailModelPopup from "../../../components/Popup/SearchDetailModelPopup";
import ImageUploadButton from "../../../components/ImageUploadButton";
import {upload} from "../../../apis/resourceAPI";
import styled from "@emotion/styled";
import SearchArticlesPopup from "../../../components/Popup/SearchMagazineArticlePopup";
import SearchMagazineQuestionPopup from "../../../components/Popup/SearchMagazineQuestionPopup";
import { QuestionContentBlockEditor } from "./QuestionContentBlockEditor"

const viverBaseUrl = "https://www.viver.co.kr"


type EditQuestionPopupProps = {
  open: boolean
  selectedQuestion?: Question
  handleClose: Function
}

const EditQuestionPopup: React.FC<EditQuestionPopupProps> = ({ open, selectedQuestion, handleClose }) => {
  // @ts-ignore
  const [question, setQuestion] = useState<Question>(selectedQuestion || {})
  const [editable, setEditable] = useState<boolean>(false)
  const [authors, setAuthors] = useState<Author[]>(selectedQuestion?.authors || [])
  const [body, setBody] = useState<string>("[]")
  const [html, setHtml] = useState<string>()
  const [selectedText, setSelectedText] = useState<string>()

  const [editorMode, setEditorMode] = useState<"json" | "contentBlock">("contentBlock")
  const [openSearchDetailModelPopup, setOpenSearchDetailModelPopup] = useState<boolean>(false)
  const [openSearchArticlesPopup, setOpenSearchArticlesPopup] = useState<boolean>(false)
  const [openSearchQuestionsPopup, setOpenSearchQuestionsPopup] = useState<boolean>(false)
  const [embedDetailModels, setEmbedDetailModels] = useState<DetailModelType[]>([])
  const [embedProducts, setEmbedProducts] = useState<ProductType[]>([])
  const [embedQuestions, setEmbedQuestions] = useState<Question[]>([])

  const handleIconUpload = (image: FileList) => {
    upload(image, [{key: "path", value: "resources/content"}]).then((result) => {
        setQuestion({ ...question, iconUrl: result.url })
    })
  }

  const addArticleLink = () => {
    if (!selectedText)
      toast.error("먼저 링크를 삽입할 텍스트를 선택하세요.")
    else {
      setOpenSearchArticlesPopup(true)
    }
  }

  const addQuestionLink = () => {
    if (!selectedText)
      toast.error("먼저 링크를 삽입할 텍스트를 선택하세요.")
    else {
      setOpenSearchQuestionsPopup(true)
    }
  }

  const addBlocks = (...added: ContentBlock[]) => {
    try {
      let blocks: ContentBlock[] = JSON.parse(body).concat(added)
      question.answer = blocks
      setBody(JSON.stringify(blocks))
    } catch (err) {
      toast.error("본문 형식이 올바르지 않습니다.")
    }
  }

  const addDetailModelBlock = () => {
    setOpenSearchDetailModelPopup(true)
  }
  /*
    const addProductBlock = () => {
        addBlock({type: QuestionBlockTypeCode.PRODUCT, productIds: [
                embedProducts.pop()?.id!
            ]})
    }
    const addQuestionBlock = () => {
        addBlock({type: ContentBlockTypeCode.CONTENT, contentIds: [
                embedQuestions.pop()?.id!
        ]})
    }
*/
  const save = (e: any) => {
    let newQuestion: Question = { ...question, answer: JSON.parse(body), html: html }

    // @ts-ignore
    if (question?.id) {
      newQuestion = { ...newQuestion, priority: 1000 - question?.id }
      updateQuestion(question?.id!, newQuestion)
        .then((res) => {
          toast.success("수정 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`수정 실패 (${error.message || error})`)
        })
    } else {
      createQuestion(newQuestion)
        .then((res) => {
          toast.success("작성 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`등록 실패 (${error.message || error})`)
        })
    }
  }

  useEffect(() => {
    if (open) {
      // @ts-ignore
      getAuthors().then((res) => setAuthors(res))
      if (selectedQuestion) {
        // @ts-ignore
        getQuestion(selectedQuestion.id!).then((res) => {
          // @ts-ignore
          const it: Question = res
          setQuestion(it)
          question.answer = it.answer
          setBody(JSON.stringify(it.answer))
            // @ts-ignore
          const detailModelIds: number[] =
            it.answer
              ?.filter((it) => it.type === ContentBlockTypeCode.DETAIL_MODEL)
              ?.flatMap((it) => it.detailModelIds) || []
          // @ts-ignore
          const productIds: string[] =
            it.answer?.filter((it) => it.type === ContentBlockTypeCode.PRODUCT)?.flatMap((it) => it.productIds) || []
          // @ts-ignore
          const articleIds: number[] =
            it.answer?.filter((it) => it.type === ContentBlockTypeCode.CONTENT)?.flatMap((it) => it.contentIds) || []

          if (detailModelIds.length)
            getDetailModels({ id: detailModelIds }).then((res) => setEmbedDetailModels(res.content))
          if (productIds.length) getProducts({ id: productIds }).then((res) => setEmbedProducts(res.content))
          if (articleIds.length) getQuestions({ id: articleIds }).then((res) => setEmbedQuestions(res.content))
        })
        setEditable(true)
      }
    } else {
      // @ts-ignore
      setQuestion({})
      setBody("[]")
      setAuthors([])
      setEditable(true)
    }
  }, [open])

  // @ts-ignore
  return (
    <Dialog open={open} maxWidth={"lg"} fullWidth>
      <SearchDetailModelPopup
        open={openSearchDetailModelPopup}
        handleClose={(selected: DetailModelType) => {
          if (selected) {
            setEmbedDetailModels([...embedDetailModels, selected])
            addBlocks({ type: ContentBlockTypeCode.DETAIL_MODEL, detailModelIds: [selected.id!] })
          }
          setOpenSearchDetailModelPopup(false)
        }}
      />
      <SearchArticlesPopup
        open={openSearchArticlesPopup}
        handleClose={(selectedArticle: Question) => {
          if (selectedArticle) {
            if (selectedText != null) {
              const newBody = body.replace(selectedText, `<a href=\\\"${viverBaseUrl}/contents/${selectedArticle.id}\\\">${selectedText}</a>`)
              setQuestion({...question, answer: JSON.parse(newBody) as ContentBlock[]})
            }
          }
          setOpenSearchArticlesPopup(false)
        }}
      />
      <SearchMagazineQuestionPopup
        open={openSearchQuestionsPopup}
        handleClose={(selectedQuestion: Question) => {
          if (selectedQuestion) {
            if (selectedText != null) {
              const newBody = body.replace(selectedText, `<a href=\\\"${viverBaseUrl}/contents/qna/${selectedQuestion.id}\\\">${selectedText}</a>`)
              setQuestion({...question, answer: JSON.parse(newBody) as ContentBlock[]})
            }
          }
          setOpenSearchQuestionsPopup(false)
        }}
      />
      <DialogTitle>
        <Typography fontWeight={"bold"} fontSize={"30px"}>
          {selectedQuestion ? "Q & A 수정" : "Q & A 등록"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction={"row"}>
          <Grid item xs={6}>
            <Stack spacing={3} direction={"column"}>
              {/*<RowBlock title={"상태"}><Typography>{content?.status || '신규'}</Typography></RowBlock>*/}
                <RowBlock title={"리소스"}>
                  <Grid container direction={"row"}>
                    <ImageUploadButton
                      width={"100px"}
                      height={"100px"}
                      objectFit={"cover"}
                      text={"아이콘"}
                      handleImage={handleIconUpload}
                      imageUrl={question.iconUrl}
                      handleDeleteImage={() => {
                        setQuestion({ ...question, iconUrl: undefined })
                      }}
                    />
                  </Grid>
                </RowBlock>
              <RowBlock title={"질문"}>
                <TextField
                  size={"small"}
                  fullWidth
                  disabled={!editable}
                  value={question.question}
                  onChange={(e) => {
                    setQuestion({ ...question, question: e.target.value })
                  }}
                />
              </RowBlock>
              <RowBlock title={"발행 일자"}>
                  <DatePicker
                    disabled={!editable}
                    onChange={(e) => {
                      setQuestion({ ...question, publishDate: e?.toISODate() ?? DateTime.now().toISODate() })
                    }}
                    value={DateTime.fromISO(question.publishDate ?? DateTime.now().toISODate())}
                    inputFormat={"yyyy-MM-dd"}
                    renderInput={(props) => <TextField size={"small"} {...props} />}
                  />
              </RowBlock>
              <RowBlock title={"에디터 모드"}>
                JSON 편집기
                <Switch
                  checked={editorMode === "contentBlock"}
                  onChange={(e, check) => {
                    setEditorMode(check ? "contentBlock" : "json")
                  }}
                />
                블록 편집기
              </RowBlock>
              <RowBlock title={"본문"}>
                <Button
                  size={"small"}
                  style={{fontWeight: 700}}
                  color={"warning"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.MARGIN, value: "80" }, { type: ContentBlockTypeCode.H1, value: "A" })}
                >
                  답변
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.TEXT, value: "<ul><li>내용</li></ul>" })}
                >
                  리스트
                </Button>
                <br />
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.H1, value: "타이틀" })}
                >
                  타이틀
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.H2, value: "서브타이틀" })}
                >
                  서브타이틀
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.TEXT, value: "텍스트" })}
                >
                  텍스트
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.DASH })}
                >
                  단락 구분선
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.MARGIN, value: "80" }, { type: ContentBlockTypeCode.HR })}
                >
                  얇은 구분선
                </Button>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => addBlocks({ type: ContentBlockTypeCode.MARGIN, value: "24" })}
                >
                  여백
                </Button>
                <label htmlFor={`contained-button-file-image`}>
                  <FileInput accept="image/*" id={`contained-button-file-image`} multiple type="file" onChange={(params) => {
                    if (params?.target?.files != null) {
                      upload(params.target.files, [{key: "path", value: "resources/content"}])
                          .then((result) => {
                            addBlocks(
                                { type: ContentBlockTypeCode.MARGIN, value: "24" },
                                { type: ContentBlockTypeCode.IMAGE, resources: [result.url], caption: "", margin: true },
                                { type: ContentBlockTypeCode.MARGIN, value: "24" },
                            )
                          })
                    }
                    params.target.value = ""
                  }}/>
                  <Button component="span" size={"small"} variant={"outlined"}>
                    이미지
                  </Button>
                </label>

                <label htmlFor={`contained-button-file-video`}>
                  <FileInput accept="video/*" id={`contained-button-file-video`} multiple type="file" onChange={(params) => {
                    if (params?.target?.files != null) {
                      upload(params.target.files, [{key: "path", value: "resources/content"}])
                          .then((result) => {
                            addBlocks(
                                { type: ContentBlockTypeCode.MARGIN, value: "24" },
                                { type: ContentBlockTypeCode.VIDEO, resources: [result.url], caption: "", margin: false },
                                { type: ContentBlockTypeCode.MARGIN, value: "24" },
                            )
                          })
                    }
                    params.target.value = ""
                  }}/>
                  <Button component="span" size={"small"} variant={"outlined"}>
                    동영상
                  </Button>
                </label>
                <Button
                    size={"small"}
                    variant={"outlined"}
                    onClick={() =>
                    {
                      const id = window.prompt("본문에 삽입할 YouTube 링크를 입력하세요", "https://www.youtube.com/watch?v=IM_kwpFP-8g")
                          ?.split(/[=\/]/).pop()

                      if (!!id)
                        addBlocks(
                            { type: ContentBlockTypeCode.MARGIN, value: "24" },
                            { type: ContentBlockTypeCode.YOUTUBE, resources: [`${id}`], caption: "", margin: false },
                            { type: ContentBlockTypeCode.MARGIN, value: "24" },
                        )
                    }}
                >
                  유튜브
                </Button>
                <br/>
                <Button size={"small"} variant={"outlined"} onClick={() => addDetailModelBlock()}>
                  상세모델
                </Button>
                {/*<Button size={"small"} variant={"outlined"} onClick={() => addProductBlock()}>상품</Button>*/}
                {/*<Button size={"small"} variant={"outlined"} onClick={() => addQuestionBlock()}>컨텐츠</Button>*/}
                {/*<TextField*/}
                {/*  size={"small"}*/}
                {/*  fullWidth*/}
                {/*  minRows={25}*/}
                {/*  maxRows={25}*/}
                {/*  multiline={true}*/}
                {/*  disabled={!editable}*/}
                {/*  inputProps={{ style: { fontSize: "10pt" } }}*/}
                {/*  value={body}*/}
                {/*  onChange={(e) => {*/}
                {/*    setBody(e.target.value)*/}
                {/*  }}*/}
                {/*/>*/}
                {editorMode === "json" && (
                  <>
                    <div style={{ height: 10 }} />
                    <Button size={"small"} variant={"outlined"} onClick={() => addArticleLink()}>
                      매거진 링크
                    </Button>
                    <Button size={"small"} variant={"outlined"} onClick={() => addQuestionLink()}>
                      QnA 링크
                    </Button>
                    <div style={{ height: 10 }} />
                    <QuestionJsonEditor
                      initialContentBlock={question.answer || []}
                      onChangeJson={(json) => {
                        setBody(json)
                      }}
                      onChangeCursorSelection={(text) => {
                        setSelectedText(text)
                      }}
                    />
                  </>
                )}
                {editorMode === "contentBlock" && (
                  <>
                    <div style={{ height: 10 }} />
                    <QuestionContentBlockEditor
                      contentBlocks={question.answer || []}
                      embedDetailModels={embedDetailModels}
                      onChangeContentBlocks={(contentBlocks) => {
                        setQuestion((question) => ({ ...question, answer: contentBlocks }))
                        setBody(JSON.stringify(contentBlocks))
                      }}
                    />
                  </>
                )}
              </RowBlock>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} direction={"column"}>
              <RowBlock title={"홈 화면 노출"}>
                <Switch
                  checked={question.onDisplay}
                  disabled={!editable}
                  onChange={(e, check) => {
                    setQuestion({ ...question, onDisplay: check })
                  }}
                />
              </RowBlock>
              {/*<RowBlock title={"연관 항목 표시"}>*/}
              {/*  <FormControl component="fieldset" variant="standard">*/}
              {/*    <FormGroup>*/}
              {/*      <FormControlLabel*/}
              {/*        control={*/}
              {/*          <Switch*/}
              {/*            checked={question.exposeRelatedProducts}*/}
              {/*            disabled={!editable}*/}
              {/*            onChange={(e, check) => {*/}
              {/*              setQuestion({ ...question, exposeRelatedProducts: check })*/}
              {/*            }}*/}
              {/*          />*/}
              {/*        }*/}
              {/*        label="연관 상품"*/}
              {/*      />*/}
              {/*      <FormControlLabel*/}
              {/*        control={*/}
              {/*          <Switch*/}
              {/*            checked={question.exposeRelatedContents}*/}
              {/*            disabled={!editable}*/}
              {/*            onChange={(e, check) => {*/}
              {/*              setQuestion({ ...question, exposeRelatedContents: check })*/}
              {/*            }}*/}
              {/*          />*/}
              {/*        }*/}
              {/*        label="연관 Q & A"*/}
              {/*      />*/}
              {/*    </FormGroup>*/}
              {/*  </FormControl>*/}
              {/*</RowBlock>*/}
              <RowBlock title={"작성자"}>
                <Autocomplete
                    fullWidth
                    multiple
                    isOptionEqualToValue={(author: Author, value: Author) => author.name === value.name}
                    value={authors.filter(author => question.authors?.find(it => it.id === author.id))}
                    getOptionLabel={(author: Author) => author.name || ''}
                    options={authors}
                    onChange={(e, value: Author[]) => {
                      setQuestion({ ...question, authors: value })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                              ),
                            }}
                        />
                    )}
                />
              </RowBlock>
              <RowBlock title={"게시 시각"}>
                <>
                    <DateTimePicker
                      label={"시작"}
                      disabled={!editable}
                      onChange={(e) => setQuestion({ ...question, startedAt: e?.toISO() })}
                      value={question.startedAt ? DateTime.fromISO(question.startedAt) : null}
                      inputFormat={"yyyy-MM-dd HH:mm"}
                      renderInput={(props) => <TextField size={"small"} sx={{ width: "50%" }} {...props} />}
                    />
                    <DateTimePicker
                      label={"종료"}
                      disabled={!editable}
                      onChange={(e) => setQuestion({ ...question, endedAt: e?.toISO() })}
                      value={question.endedAt ? DateTime.fromISO(question.endedAt) : null}
                      inputFormat={"yyyy-MM-dd HH:mm"}
                      renderInput={(props) => <TextField size={"small"} sx={{ width: "50%" }} {...props} />}
                    />
                </>
              </RowBlock>
              <RowBlock title={"미리보기"}>
                  <QuestionHtmlPreview
                    question={question}
                    answer={body}
                    embedDetailModels={embedDetailModels}
                    embedProducts={embedProducts}
                    embedQuestions={embedQuestions}
                    onRender={(renderedHtml) => {setHtml(renderedHtml)}}
                  />
              </RowBlock>
              {question.id && (
                <RowBlock title={"URL"}>
                  <TextField
                    size={"small"}
                    fullWidth
                    value={`viver://contents/${question.id}`}
                    onFocus={(e) => e.target.select()}
                    onClick={async (e) => {
                      const text = `viver://contents/${question.id}`
                      if ("clipboard" in navigator) await navigator.clipboard.writeText(text)
                      else document.execCommand("copy", true, text)
                      toast.success("복사되었습니다.")
                    }}
                  />
                </RowBlock>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} disabled={!editable} variant={"contained"} color={"info"} onClick={save}>
          저장하기
        </Button>
        {/*{selectedContent && <Button size={"medium"} disabled={!editable} variant={"contained"} color={"error"} onClick={remove}>삭제</Button>}*/}
        <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const FileInput = styled('input')({
  display: 'none',
});

export default EditQuestionPopup
