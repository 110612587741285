import {httpDelete, httpGet, httpPost, httpPostMultipartFile, httpPut} from "../utils/httpAPI";
import {ShowcaseType} from "types/ShowcaseType";
import {UploadResultType} from "types/UploadResultType";

export const createShowcase = (showcase: ShowcaseType): Promise<ShowcaseType> => httpPost("/showcases", showcase)
export const getShowcases: (params?: any) => Promise<ShowcaseType[]> = (params) => httpGet("/showcases", params)
export const getShowcase: (id: number) => Promise<ShowcaseType> = (id) => httpGet(`/showcases/${id}`)
export const deleteShowcase: (id: number) => Promise<string> = (id) => httpDelete(`/showcases/${id}`)
export const showShowcase: (id: number) => Promise<string> = (id) => httpPost(`/showcases/${id}/show`)
export const hideShowcase: (id: number) => Promise<string> = (id) => httpPost(`/showcases/${id}/hide`)
export const updateShowcase: (id?: number, showcase?: ShowcaseType) => Promise<ShowcaseType> = (id, showcase) => httpPut(`/showcases/${id}`, showcase)
export const uploadThumbnail: (file: FileList) => Promise<UploadResultType> = (file) => httpPostMultipartFile('/showcases/thumbnail', file)