import { Grid, Card, CardHeader, CardContent, Typography, CardActions, Button } from "@mui/material"
import { FallbackRender } from "@sentry/react"

const ErrorBoundaryFallback: FallbackRender = ({ error, resetError }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={"오류가 발생했습니다"} />
          <CardContent>
            <Typography textAlign={"center"}>{String(error)}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={resetError}>새로고침</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
export default ErrorBoundaryFallback
