import { PageType } from "../../../types/PageType"
import {
  AdminAuthorityType,
  AdminGroupType,
  AdminUserListSearchParams,
  AdminUserType,
} from "../../../types/AdminUserType"
import React, { useEffect, useReducer } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import { findAdminUserList } from "../../../apis/adminUserAPI"
import AdminUserTable from "./AdminUserTable"
import { BuyOrderListSearchParams } from "../../../types/BuyOrderType"
import AdminUserDetailPopup from "./AdminUserDetailPopup"
import { findAdminAuthorities, findAdminGroupCodes } from "../../../apis/adminGroupAPI"

export interface AdminUserListState {
  selectedId?: number
  groupCodes: AdminGroupType[]
  authorities: AdminAuthorityType[]
  adminUsers: PageType<AdminUserType>
  searchFilterKey?: keyof AdminUserListSearchParams
  searchFilterValue?: string | null
  isShowAdminUserDetailPopup: boolean
  refresh: boolean
  loading: boolean
  pageSize: number
  pageNumber: number
}

const initState: AdminUserListState = {
  selectedId: undefined,
  groupCodes: [],
  authorities: [],
  adminUsers: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  searchFilterKey: "name",
  searchFilterValue: undefined,
  isShowAdminUserDetailPopup: false,
  refresh: false,
  loading: false,
  pageSize: 25,
  pageNumber: 0,
}

interface AdminUserAction {
  type: string
  payload?: any
}

function reducer(state: AdminUserListState, action: AdminUserAction) {
  switch (action.type) {
    case "FETCH_ADMIN_USERS":
      return { ...state, adminUsers: action.payload }
    case "FETCH_ADMIN_GROUP_CODES":
      return { ...state, groupCodes: action.payload }
    case "FETCH_ADMIN_AUTHORITIES":
      return { ...state, authorities: action.payload }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: null }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
    case "TOGGLE_ADMIN_USER_DETAIL_POPUP":
      return {
        ...state,
        isShowAdminUserDetailPopup: !state.isShowAdminUserDetailPopup,
        selectedId: action.payload,
      }
    case "CHANGE_PAGE_NUMBER":
      return {
        ...state,
        pageNumber: action.payload,
        refresh: !state.refresh,
      }
    case "CHANGE_PAGE_SIZE":
      return { ...state, pageNumber: 0, pageSize: action.payload, refresh: !state.refresh }
  }

  return state
}

const AdminUserListPage = () => {
  const [state, dispatch] = useReducer(reducer, initState)

  const fetchAllAdminUsers = (searchParams: AdminUserListSearchParams = {}) => {
    let newParams: BuyOrderListSearchParams = { ...searchParams }
    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    findAdminUserList(newParams)
      .then((res) => dispatch({ type: "FETCH_ADMIN_USERS", payload: res }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchAdminGroupCodes = () => {
    findAdminGroupCodes().then((groups) => dispatch({ type: "FETCH_ADMIN_GROUP_CODES", payload: groups }))
  }

  const fetchAdminAuthorities = () => {
    findAdminAuthorities().then((auth) => dispatch({ type: "FETCH_ADMIN_AUTHORITIES", payload: auth }))
  }

  useEffect(() => {
    fetchAdminGroupCodes()
    fetchAdminAuthorities()
  }, [])

  useEffect(() => {
    fetchAllAdminUsers({
      page: state.pageNumber || 0,
      size: state.pageSize || 25,
    })
  }, [state.refresh])

  return (
    <Grid container spacing={2}>
      <AdminUserDetailPopup
        open={state.isShowAdminUserDetailPopup}
        adminUserId={state.selectedId}
        handleClose={() => {
          dispatch({ type: "TOGGLE_ADMIN_USER_DETAIL_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
        groupCodes={state.groupCodes}
        authorities={state.authorities}
      />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Grid item xs={12} container spacing={1}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
              <FormControl sx={{ width: "160px" }} size={"small"}>
                <InputLabel id="search_key">검색항목</InputLabel>
                <Select
                  value={state.searchFilterKey || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                  }}
                >
                  <MenuItem value={"name"}>이름</MenuItem>
                  <MenuItem value={"email"}>이메일</MenuItem>
                  <MenuItem value={"groupName"}>그룹명</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ width: "220px" }}
                size={"small"}
                value={state.searchFilterValue || ""}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: params.target.value })
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") dispatch({ type: "REFRESH" })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AdminUserTable state={state} dispatch={dispatch} components={[]} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AdminUserListPage
