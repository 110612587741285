import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const couponCodeUsageHistoryTableHeaderItemList = [
  {
    title: "ID",
    width: "10%",
  },
  {
    title: "쿠폰 코드",
    width: "35%",
  },
  {
    title: "사용자 (ID)",
    width: "35%",
  },
  {
    title: "사용 일시",
    width: "30%",
  },
]

const CouponCodeUsageHistoryListTableHeader = () => {
  return (
    <ListTableHeader>
      {couponCodeUsageHistoryTableHeaderItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default CouponCodeUsageHistoryListTableHeader
