import {
  BuyingFeePolicyType,
  FeePolicyDataType,
  FeePolicyDeleteParams,
  FeePolicyRequestParams,
  FeePolicySearchParams,
} from "../types/FeePolicyType"
import { httpDelete, httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { PageType } from "../types/PageType"

export const getFeePolicies: (params: FeePolicySearchParams) => Promise<PageType<FeePolicyDataType>> = (params) => {
  return httpGet(`/fee-policies/list`, { ...params })
}

export const deleteFeePolicies: (params: FeePolicyDeleteParams) => Promise<void> = (params) => {
  return httpDelete(`/fee-policies`, { ...params })
}

export const updateFeePolicy: (params: FeePolicyRequestParams) => Promise<void> = (params) => {
  params.rate = params.rate === null ? null : params.rate / 100
  params.discountRate = params.discountRate === null ? null : params.discountRate / 100
  params.endDate = params.endDate === "-" ? null : params.endDate

  return httpPut(`/fee-policies/${params.id}`, { ...params })
}

export const insertFeePolicy: (params: FeePolicyRequestParams) => Promise<void> = (params) => {
  params.rate = params.rate === null ? null : params.rate / 100
  params.discountRate = params.discountRate === null ? null : params.discountRate / 100
  params.endDate = params.endDate === "-" ? null : params.endDate

  return httpPost(`/fee-policies`, { ...params })
}

export const getBuyingFees: (productId: number, buyerId?: number) => Promise<BuyingFeePolicyType[]> = (
  productId,
  buyerId
) => {
  return httpPost(
    `/estimates/buy-order-buying-fees`,
    buyerId
      ? {
          buyerId: buyerId,
          product: { id: productId },
        }
      : { product: { id: productId } }
  )
}
