import styled from "@emotion/styled"
import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { SerialNumberListItemType } from "types/SerialNumberType"
import { headerItemList } from "../../TableHeader"

type Props = {
  serialNumberListItem: SerialNumberListItemType
} & HTMLAttributes<HTMLDivElement>

const SerialNumberSearchTableRow = ({ serialNumberListItem, ...props }: PropsWithChildren<Props>) => {
  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: headerItemList[0].width }}>{serialNumberListItem.serialNo}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>
        {serialNumberListItem.detailModel.brand.name}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>
        <ColumnWrapper>
          <div>{serialNumberListItem.detailModel.title}</div>
          <div style={{ color: "#969696" }}>{serialNumberListItem.detailModel.titleSecondary}</div>
        </ColumnWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[3].width }}>
        {serialNumberListItem.detailModel.fullRefNo}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[4].width }}>
        {serialNumberListItem.blacklist ? "블랙리스트" : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[5].width }}>
        {serialNumberListItem.memoCount > 0 ? "O" : "-"}
      </TableRowItem>
    </TableRow>
  )
}

export default SerialNumberSearchTableRow

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
