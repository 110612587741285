import styled from "@emotion/styled"
import { InputHTMLAttributes } from "react"

type Props = InputHTMLAttributes<HTMLInputElement>

const SearchInput = ({ ...props }: Props) => {
  return <Input {...props}></Input>
}

export default SearchInput

const Input = styled.input`
  flex: 1;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  outline: none;
  padding: 10px;
  display: flex;
  algin-items: center;
`
