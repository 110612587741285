import {Button, Stack} from "@mui/material";
import React from "react";

// @ts-ignore
const BuyOrderReviewToolbar = ({handleShowButtonClick, handleHideButtonClick}) => {
    return (
        <Stack direction={"row"} justifyContent={'flex-end'} spacing={1}>
           <Button variant={"contained"} size={"small"} onClick={handleShowButtonClick}>노출</Button>
           <Button variant={"contained"} size={"small"} onClick={handleHideButtonClick}>숨김</Button>
        </Stack>
    )
}

export default BuyOrderReviewToolbar