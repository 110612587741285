import { Card, Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"
import { Link as NavLink } from "react-router-dom"
import { CountType } from "types/CountType"

interface CountersProps {
  params: CountType[]
  selectedLabel?: string | null
}

interface CounterProps {
  label?: string
  count?: number
  link?: string
  selected?: boolean
}

const Counter: React.FC<CounterProps> = ({ label, link, count, selected = false }) => {
  const weight = selected ? 800 : 400

  return (
    <>
      <Typography color="text" sx={{ pl: 4 }} style={{ fontWeight: weight }}>
        {
            selected
                ? <NavLink style={{ fontWeight: 800, textDecoration: "none", color: "black" }} to={link ?? "./"}>
                    {label} <span style={{ color: "blue" }}>{`${count}건` || "-"}</span>
                </NavLink>
                : <NavLink style={{ textDecoration: "none", color: "black" }} to={link ?? "./"}>
                    {label} <span style={{ color: "blue" }}>{`${count}건` || "-"}</span>
                </NavLink>
        }
      </Typography>
    </>
  )
}

const Counters: React.FC<CountersProps> = ({ params, selectedLabel }) => {
  return (
    <Card>
      <Stack spacing={2} direction={"row"} justifyContent="flex-start" sx={{ pt: 3, pb: 3 }}>
        {params.map((value) => (
          <Counter
            key={value.label}
            label={value.label}
            count={value.count}
            link={value.link}
            selected={value.label === (selectedLabel || "전체")}
          />
        ))}
      </Stack>
    </Card>
  )
}

export default Counters
