import {DataGrid} from '@mui/x-data-grid';
import React from "react";
import {toDateStr, toTimeStr} from "../../utils/datetimeUtil";
import {Grid} from "@mui/material";
import {DateTime} from "luxon";

const renderMultiLineDateTime = (datetimeStr: string) => (
    <div style={{textAlign: "center"}}>
        <p>{toDateStr(datetimeStr, false)}<br/>
            {toTimeStr(datetimeStr)}</p>
    </div>
)

function createColumns(): any[] {
    return [
        {
            field: 'id',
            headerName: "배송 ID",
            minWidth: 80,
            maxWidth: 120,
        },
        {
            field: 'orderShippingType',
            headerName: "베송 유형",
            minWidth: 80,
            maxWidth: 120,
        },
        {
            field: 'shippingStatus',
            headerName: "상태",
            minWidth: 80,
            maxWidth: 120,
        },
        {
            field: 'startedAt',
            headerName: "요청일",
            minWidth: 80,
            maxWidth: 100,
            valueFormatter: (params: any) => `${params.value ? DateTime.fromISO(params.value).toISODate() : ''}`
        },
        {
            field: 'trackingNo',
            headerName: "운송장번호",
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'name',
            headerName: "이름",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            field: 'address',
            headerName: "주소 1",
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'addressDetail',
            headerName: "주소 2",
            flex: 1,
            minWidth: 200,
        }
    ]
}

type BuyOrderProps = {
    rows: any[]
}

const ShippingTable: React.FC<BuyOrderProps> = ({rows}) => {
    return (
        <Grid container spacing={1} sx={{pt: 1}}>
            <Grid item xs={12}>
            <div style={{height: 1200, width: '100%'}}>
                <DataGrid
                    columns={createColumns()}
                    rows={rows}

                    pagination
                    paginationMode="server"

                    // onPageChange={(page) => {
                    //     fetch({page, size: state.buyOrders.pageable?.pageSize || 50})
                    // }}
                    // onPageSizeChange={(size) => {
                    //     fetch({page: 0, size})
                    // }}
                    // page={state.buyOrders.pageable?.pageNumber || 0}
                    // pageSize={state.buyOrders.pageable?.pageSize || 50}
                    // rowCount={state.buyOrders?.totalElements || 0}
                    // rows={state.buyOrders?.content || []}

                    checkboxSelection
                    // selectionModel={state.selectedIds}
                    // onSelectionModelChange={handleSelection}
                    disableSelectionOnClick={true}
                />
            </div>
            </Grid>
        </Grid>)
}

// @ts-ignore
export default ShippingTable