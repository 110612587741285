import {httpGet, httpPut} from "../utils/httpAPI";
import {AppVersion, PlatformTypeCode} from "types/AppVersionType";
import { PageType } from "types/PageType";
import {DateTime} from "luxon";
import {DetailModelType} from "../types/DetailModelType";
import {ProductStatusCode, SaleTypeCode} from "../types/ProductType";


export declare interface BuyPriceOfferType {

}

export declare interface BuyPriceOfferGroupByProductType {
    thumbnail?: string,
    brandSid?: string,
    title?: string,
    titleSecondary?: string,
    detailModel?: DetailModelType,
    status?: ProductStatusCode,
    saleType?: SaleTypeCode,
    completeSaleAt?: string,
    transactionAt?: string,
    isOfferedUserBought?: boolean,
    offeredUserBoughtAt?: string,
    price?: number,
    priceUpdatedAt?:string,
    refusedAt?: string,
    receiveBuyPriceOffer?: boolean,
    proposedPrice?: number,
    proposedAt?: string

}

export const getBuyPriceOfferPage = (params?: any | null): Promise<PageType<BuyPriceOfferType>> => httpGet("/buy-price-offers", {page: 0,
    size: 50,
    sort: 'offeredAt,desc',
    startDate: DateTime.now().minus({day: 365}).toISODate(),
    endDate: DateTime.now().toISODate(), ...params})
export const getBuyPriceOfferGroupByProductPage = (params?: any | null): Promise<PageType<BuyPriceOfferGroupByProductType>> => httpGet("/buy-price-offers/group-by-product", params)