import { Box, Button, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import ShopCurationAPI from "apis/shopProductCurationAPI"
import { ShopCurationProductType, ShopCurationType } from "types/ShopCurationType"
import EditIcon from "@mui/icons-material/Edit"
import _ from "lodash"
import {toDateStr, toDateTimeStr, toTimeStr} from "utils/datetimeUtil"
import {PageType} from "../../../../types/PageType";
import CurationTableFilter from "./CurationTableFilter";

function renderStatusCell(params: any) {
  if (params?.row?.postingStatus === "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus === "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

interface CurationTableFilterState {
  postingStatus: string | null,
  page: number | null,
  size: number | null
}

const initCurationTableFilter: CurationTableFilterState = {
  postingStatus: null,
  page: 0,
  size: 50
}

const CurationTable: React.FunctionComponent<{
  handleSelection: (ids: number[]) => void
  handleUpdateCurationId: (curationId: number) => void
  refreshCounter: number
}> = ({ handleSelection, handleUpdateCurationId, refreshCounter }) => {
  const [tableFilter, setTableFilter] = useState<CurationTableFilterState>(initCurationTableFilter)
  const [curations, setCurations] = useState<PageType<ShopCurationType>>({content: []})

  const getColumns = () => {
    return [
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={"수정"}
            onClick={() => {
              handleUpdateCurationId(params.row.id)
            }}
            showInMenu
          />,
        ],
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "priority", headerName: "노출순서", maxWidth: 75 },
      { field: "title", headerName: "제목", flex: 2 },
      { field: "titleSecondary", headerName: "부제목", flex: 3 },
      { field: "curationType", headerName: "타입", width: 100 },
      { field: "minQuantity", headerName: "최소 갯수", maxWidth: 70 },
      { field: "maxQuantity", headerName: "최대 갯수", maxWidth: 70 },
      {
        field: "maxQuantity2",
        headerName: "노출 상품수",
        maxWidth: 85,
        valueGetter: (params: any) =>
          `${
            _.countBy(
              params.row.products.map((r: { product: ShopCurationProductType }) => r.product),
              "status"
            )["판매중"] ?? 0
          }`,
      },
      {
        field: "createdAt",
        headerName: "등록일시",
        maxWidth: 80,
        valueGetter: (params: any) => toDateTimeStr(params.row.createdAt),
        renderCell: (params: any) => (
            <div style={{textAlign: "center"}}>
              <p>{toDateStr(params.row.createdAt, false)}<br/>
                {toTimeStr(params.row.createdAt)}</p>
            </div>
        )
      },
      {
        field: "startedAt",
        headerName: "노출 시작",
        maxWidth: 80,
        renderCell: (params: any) => (
            <div style={{textAlign: "center"}}>
              <p>{toDateStr(params.row.startedAt, false)}<br/>
                {toTimeStr(params.row.startedAt)}</p>
            </div>
        )
      },
      {
        field: "endedAt",
        headerName: "노출 종료",
        maxWidth: 80,
        renderCell: (params: any) => (
            <div style={{textAlign: "center"}}>
              <p>{toDateStr(params.row.endedAt, false)}<br/>
                {toTimeStr(params.row.endedAt)}</p>
            </div>
        )
      },
      { field: "postingStatus", headerName: "진행 상태", width: 100, renderCell: renderStatusCell },
    ]
  }


  const fetch = (tableFilter: any = {}) => {
    ShopCurationAPI.getPage(tableFilter).then(setCurations)
  }

  useEffect(() => {
    fetch(tableFilter)
  }, [tableFilter, refreshCounter])

  return (
    <Stack spacing={2}>
      <CurationTableFilter
        filter={tableFilter}
        handleFilterChange={(changedFilter: CurationTableFilterState) => {
          setTableFilter(changedFilter)
        }}
      />
      <Box sx={{ height: 720, width: "100%" }}>
        <DataGrid
          columns={getColumns()}
          getRowHeight={() => 70}
          onSelectionModelChange={(ids: any) => {
            handleSelection(ids as number[])
          }}
          checkboxSelection

          paginationMode="server"
          onPageChange={(page) => {
            setTableFilter({...tableFilter, page: page})
          }}
          onPageSizeChange={(size) => {
            setTableFilter({...tableFilter, size: size})
          }}

          page={curations.pageable?.pageNumber}
          pageSize={curations.pageable?.pageSize}
          rowCount={curations?.totalElements}
          rows={curations?.content}
        />
      </Box>
    </Stack>
  )
}

export default CurationTable
