import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { ScheduleState } from "./index"
import { toDateStr, toTimeStr } from "../../utils/datetimeUtil"
import { Button, Grid } from "@mui/material"
import {MessageStatusCode, UserNotificationScheduleType} from "../../types/UserNotificationScheduleType";

function createColumns(handleEditButtonClick: Function): any[] {
  const openPushMessagePopup = (row: UserNotificationScheduleType) => {
    handleEditButtonClick(row)
  }
  const getColorByStatus = (status: MessageStatusCode) => {
    switch (status) {
      case MessageStatusCode.발송대기:
        return "info"
      case MessageStatusCode.발송취소:
        return "error"
      case MessageStatusCode.발송완료:
        return "success"
    }
  }

  return [
    {
      field: "id",
      headerName: "ID",
      align: "center",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "상태",
      editable: false,
      sortable: false,
      align: "center",
      minWidth: 80,
      maxWidth: 80,
      valueGetter: (params: any) => {
        return `${params.row.status}`
      },
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openPushMessagePopup(params.row)
            }}
            color={getColorByStatus(params.row.status)}
          >
            {params.row.status}
          </Button>
        </div>
      ),
    },
    {
      field: "scheduledAt",
      headerName: "발송시간",
      editable: false,
      sortable: false,
      align: "center",
      minWidth: 80,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.sendAt}`,
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.scheduledAt, false)}
            <br />
            {toTimeStr(params.row.scheduledAt, true)}
          </p>
        </div>
      ),
    },
    {
      field: "userGroupType",
      headerName: "발송대상",
      editable: false,
      sortable: false,
      align: "center",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "notificationType",
      headerName: "알림타입",
      editable: false,
      sortable: false,
      align: "center",
      minWidth: 120,
      maxWidth: 120,
    },
    {
      field: "inAppMessage",
      headerName: "인앱 알림",
      editable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (params: any) => {
        let urls: string[] = params.row.inAppMessage?.linkUrl?.split(",") || []
        return (
          <div style={{whiteSpace: "pre-line", wordBreak: "break-word", lineHeight: 1}}>
            <p style={{fontWeight: 700}}>{params.row.inAppMessage?.title}</p>
            <p>{params.row.inAppMessage?.titleSecondary?.substring(0, 100)}</p>
            <p style={{fontStyle: "italic"}}>{
                urls.length > 0 && urls?.[0] + (urls.length > 1 ? `외 ${urls.length || 0}건` : '')
            }</p>
          </div>
        )
      },
    },
    {
      field: "pushMessage",
      headerName: "푸시 알림",
      editable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (params: any) => {
        let urls: string[] = params.row.pushMessage?.linkUrl?.split(",") || []
        return (
          <div style={{whiteSpace: "pre-line", wordBreak: "break-word", lineHeight: 1}}>
            <p style={{fontWeight: 700}}>{params.row.pushMessage?.title}</p>
            <p>{params.row.pushMessage?.body?.substring(0, 100)}</p>
            <p style={{fontStyle: "italic"}}>{
              urls.length > 0 && urls?.[0] + (urls.length > 1 ? `외 ${urls.length || 0}건` : '')
            }</p>
          </div>)
      },
    },
  ]
}

type Props = {
  state: ScheduleState
  dispatch: any
  fetch: Function
  components: any[]
}

const UserNotificationScheduleTable: React.FC<Props> = ({ state, dispatch, fetch, components }) => {
  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }

  const handleEditButtonClick = (row: UserNotificationScheduleType) => {
    dispatch({ type: "SHOW_VIEW_SCHEDULE_POPUP", payload: row })
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            getRowId={(row) => row.id!}
            columns={createColumns(handleEditButtonClick)}
            pagination
            {...dispatch.detailModels?.content}
            paginationMode="server"
            onPageChange={(page) => {
              fetch({ page, size: state.schedules.pageable?.pageSize || 25 })
            }}
            onPageSizeChange={(size) => {
              fetch({ page: 0, size })
            }}
            page={state.schedules.pageable?.pageNumber || 0}
            pageSize={state.schedules.pageable?.pageSize || 25}
            rowCount={state.schedules?.totalElements || 0}
            rows={state.schedules?.content || []}
            rowHeight={180}
            disableSelectionOnClick={true}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default UserNotificationScheduleTable
