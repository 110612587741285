export const Spacer:React.FC<{space:number; horizontal?:boolean}> = (props)=>{

  return (
    <div style={{
      width:props.horizontal ? `${props.space}px` : 0,
      height:!props.horizontal ? `${props.space}px` : 0,
    }}>
    </div>
  )
}
