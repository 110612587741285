import React, { useEffect, useState } from "react"
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import {
  FeePolicyDeleteParams,
  FeePolicySearchParams,
  FeePolicyDataType,
  FeePolicyTargetType,
} from "../../../types/FeePolicyType"
import { PageType } from "../../../types/PageType"
import { deleteFeePolicies, getFeePolicies } from "../../../apis/feePolicyAPI"
import { Spacer } from "../../../components/Spacer"
import { FeePolicyTable } from "./FeePolicyTable"
import { FeePolicyModal } from "./FeePolicyModal"

export type FeePolicyState = {
  searchName?: string
  searchType?: string
  searchSaleType?: string
  searchTargetType?: string
  searchValue?: string
  selectedIds: number[]
  refresh: boolean
  loading: boolean
  feePolicyList: PageType<FeePolicyDataType>
  selectedFeePolicy?: FeePolicyDataType
  isOpenModal: boolean
  page: number
  pageSize: number
}

const initialState: FeePolicyState = {
  searchName: "주문 관리 수수료",
  searchType: "판매 결제 수수료",
  searchSaleType: "전체",
  searchTargetType: "전체",
  searchValue: undefined,
  selectedIds: [],
  feePolicyList: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  refresh: false,
  loading: false,
  isOpenModal: false,
  page: 0,
  pageSize: 25,
}

export const FeePolicyManager: React.FC = () => {
  const [state, setState] = useState<FeePolicyState>(initialState)

  const fetchFeePolicyList = (params: FeePolicySearchParams) => {
    if (params.type === "전체") params.type = undefined
    if (params.saleType === "전체") params.saleType = undefined
    if (params.targetType === "전체") params.targetType = undefined
    if (params.name === "전체") params.name = undefined

    getFeePolicies(params).then((result) => {
      const processedContent = result.content.map((value) => {
        const rate = value.rate != null ? parseFloat((value.rate * 100).toFixed(1)) : value.rate
        const discountRate =
          value.discountRate != null ? parseFloat((value.discountRate * 100).toFixed(1)) : value.discountRate

        return {
          ...value,
          rate,
          discountRate,
        }
      })

      setState((prevState) => ({
        ...prevState,
        feePolicyList: {
          ...result,
          content: processedContent,
        },
      }))
    })
  }

  const removeFeePolicy = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteFeePolicies({ ids: state.selectedIds }).then((result) => {
        setState((prevState) => ({
          ...prevState,
          refresh: !prevState.refresh,
        }))
      })
    }
  }

  const addFeePolicy = () => {
    setState((prevState) => ({
      ...prevState,
      selectedFeePolicy: undefined,
      isOpenModal: true,
    }))
  }

  useEffect(() => {
    fetchFeePolicyList({
      page: state.page,
      size: state.pageSize,
      name: state.searchName,
      saleType: state.searchSaleType,
      type: state.searchType,
      targetType: state.searchTargetType,
      search: state.searchValue,
    })
  }, [state.refresh, state.page, state.pageSize])

  return (
    <Grid container spacing={2}>
      <FeePolicyModal
        open={state.isOpenModal}
        handleClose={() => {
          setState((prevState) => ({
            ...prevState,
            isOpenModal: false,
            refresh: !state.refresh,
          }))
        }}
        feePolicy={state.selectedFeePolicy}
      />

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"end"}>
                <Button
                  variant={"contained"}
                  color={"info"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={addFeePolicy}
                >
                  추가
                </Button>
                <Button
                  variant={"contained"}
                  color={"error"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={removeFeePolicy}
                >
                  수수료 적용 OFF
                </Button>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="searchName">수수료명</InputLabel>
                    <Select
                      value={state.searchName}
                      onChange={(params) => {
                        const searchName = params.target.value
                        let searchType: string | undefined

                        switch (searchName) {
                          case "전체":
                            searchType = "전체"
                            break
                          case "주문 관리 수수료":
                            searchType = "판매 결제 수수료"
                            break
                          case "배송비":
                            searchType = "판매 배송 수수료"
                            break
                          default:
                            searchType = undefined
                        }

                        setState((prevState) => ({
                          ...prevState,
                          searchName: searchName,
                          searchType: searchType,
                          refresh: !prevState.refresh,
                        }))
                      }}
                    >
                      {/*<MenuItem value={"전체"} selected={true}>*/}
                      {/*  전체*/}
                      {/*</MenuItem>*/}
                      <MenuItem value={"주문 관리 수수료"}>주문 관리 수수료</MenuItem>
                      {/*<MenuItem value={"거래 수수료"}>거래 수수료</MenuItem>*/}
                      {/*<MenuItem value={"판매 진단 수수료"}>판매 진단 수수료</MenuItem>*/}
                      {/*<MenuItem value={"배송비"}>배송비</MenuItem>*/}
                      {/*<MenuItem value={"서비스 수수료"}>서비스 수수료</MenuItem>*/}
                      {/*<MenuItem value={"페널티 금액"}>페널티 금액</MenuItem>*/}
                      {/*<MenuItem value={"일반 감정진단"}>일반 감정진단</MenuItem>*/}
                      {/*<MenuItem value={"반품 배송비"}>반품 배송비</MenuItem>*/}
                      {/*<MenuItem value={"정밀 감정진단"}>정밀 감정진단</MenuItem>*/}
                    </Select>
                  </FormControl>
                  <Spacer horizontal={true} space={8}></Spacer>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="searchType">수수료 타입</InputLabel>
                    <Select
                      value={state.searchType}
                      onChange={(params) => {
                        setState((prevState) => ({
                          ...prevState,
                          searchType: params.target.value,
                          refresh: !prevState.refresh,
                        }))
                      }}
                    >
                      {state.searchName === "전체" && <MenuItem value={"전체"}>전체</MenuItem>}
                      {/*<MenuItem value={"구매 수수료"}>구매 수수료</MenuItem>*/}
                      {/*<MenuItem value={"구매 진단 수수료"}>구매 진단 수수료</MenuItem>*/}
                      {/*<MenuItem value={"구매 정밀 진단 수수료"}>구매 정밀 진단 수수료</MenuItem>*/}
                      {/*<MenuItem value={"구매 배송 수수료"}>구매 배송 수수료</MenuItem>*/}
                      {/*<MenuItem value={"반품 수수료"}>반품 수수료</MenuItem>*/}
                      {/*<MenuItem value={"구매 업그레이드 수수료"}>구매 업그레이드 수수료</MenuItem>*/}
                      {/*<MenuItem value={"판매 수수료"}>판매 수수료</MenuItem>*/}
                      {/*<MenuItem value={"판매 진단 수수료"}>판매 진단 수수료</MenuItem>*/}
                      {/*<MenuItem value={"판매 업그레이드 수수료"}>판매 업그레이드 수수료</MenuItem>*/}
                      {state.searchName === "주문 관리 수수료" && (
                        <MenuItem value={"판매 결제 수수료"}>{"판매 결제 수수료"}</MenuItem>
                      )}
                      {state.searchName === "배송비" && (
                        <MenuItem value={"판매 배송 수수료"}>{"판매 배송 수수료"}</MenuItem>
                      )}
                      {/*<MenuItem value={"판매 취소 페널티"}>판매 취소 페널티</MenuItem>*/}
                      {/*<MenuItem value={"보관판매 취소 페널티"}>보관판매 취소 페널티</MenuItem>*/}
                      {/*<MenuItem value={"판매 요청 취소 페널티"}>판매 요청 취소 페널티</MenuItem>*/}
                      {/*<MenuItem value={"판매 가품 페널티"}>판매 가품 페널티</MenuItem>*/}
                      {/*<MenuItem value={"판매 커스텀 페널티"}>판매 커스텀 페널티</MenuItem>*/}
                      {/*<MenuItem value={"판매 상태 불일치 페널티"}>판매 상태 불일치 페널티</MenuItem>*/}
                      {/*<MenuItem value={"판매 다른 상품 전달 페널티"}>판매 다른 상품 전달 페널티</MenuItem>*/}
                      {/*<MenuItem value={"외국인 구매 수수료"}>외국인 구매 수수료</MenuItem>*/}
                      {/*<MenuItem value={"직접 입력"}>직접 입력</MenuItem>*/}
                      {/*<MenuItem value={"구매 환불 수수료"}>구매 환불 수수료</MenuItem>*/}
                    </Select>
                  </FormControl>
                  <Spacer horizontal={true} space={8}></Spacer>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="searchSaleType">판매 타입</InputLabel>
                    <Select
                      value={state.searchSaleType}
                      onChange={(params) => {
                        setState((prevState) => ({
                          ...prevState,
                          searchSaleType: params.target.value,
                          refresh: !prevState.refresh,
                        }))
                      }}
                    >
                      <MenuItem value={"전체"} selected={true}>
                        전체
                      </MenuItem>
                      {/*<MenuItem value={"전시용"}>전시용</MenuItem>*/}
                      <MenuItem value={"직접판매"}>직접판매</MenuItem>
                      <MenuItem value={"위탁판매"}>위탁판매</MenuItem>
                      <MenuItem value={"진단전판매"}>진단전판매</MenuItem>
                      {/*<MenuItem value={"매입보증판매"}>매입보증판매</MenuItem>*/}
                    </Select>
                  </FormControl>
                  <Spacer horizontal={true} space={8}></Spacer>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="searchTargetType">적용 구분</InputLabel>
                    <Select
                      value={state.searchTargetType}
                      onChange={(params) => {
                        setState((prevState) => ({
                          ...prevState,
                          searchTargetType: params.target.value,
                          refresh: !prevState.refresh,
                        }))
                      }}
                    >
                      <MenuItem value={"전체"} selected={true}>
                        전체
                      </MenuItem>
                      <MenuItem value={FeePolicyTargetType.상품}>상품</MenuItem>
                      <MenuItem value={FeePolicyTargetType.유저}>유저</MenuItem>
                      <MenuItem value={FeePolicyTargetType.상세모델}>상세모델</MenuItem>
                      {/*<MenuItem value={"모델"}>모델</MenuItem>*/}
                      <MenuItem value={FeePolicyTargetType.브랜드}>브랜드</MenuItem>
                      {/*<MenuItem value={"카테고리"}>카테고리</MenuItem>*/}
                    </Select>
                  </FormControl>
                  <Spacer horizontal={true} space={8}></Spacer>
                  <TextField
                    label={"적용 값"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: "300px" }}
                    size={"small"}
                    value={state.searchValue}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setState((prevState) => ({
                          ...prevState,
                          refresh: !prevState.refresh,
                        }))
                      }
                    }}
                    onChange={(params) => {
                      setState((prevState) => ({
                        ...prevState,
                        searchValue: params.target.value,
                      }))
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <FeePolicyTable state={state} dispatch={setState} fetch={fetchFeePolicyList} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
