import { FunctionComponent } from "react"
import { Switch } from "@mui/material"

export const DetailModelViverCommentInput: FunctionComponent<{
  display: boolean
  commentTitle: string
  commentDetail: string
  onChangeDisplay: (display: boolean) => void
  onChangeCommentTitle: (commentTitle: string) => void
  onChangeCommentDetail: (commentDetail: string) => void
}> = ({ display, commentTitle, commentDetail, onChangeDisplay, onChangeCommentTitle, onChangeCommentDetail }) => {
  return (
    <div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
        <div style={{ width: 120 }}>바이버 코멘트</div>
        <div style={{ width: 8 }} />
        <div>
          노출
          <Switch checked={display} onChange={(_, checked) => onChangeDisplay(checked)} />
        </div>
      </div>
      {display && (
        <>
          <div style={{ height: 16 }} />
          <DetailModelViverCommentTitleTextField
            label="코멘트 타이틀"
            inputValue={commentTitle}
            inputPlaceholder="타이틀을 입력해주세요"
            onChangeInputValue={onChangeCommentTitle}
          />
          <div style={{ height: 16 }} />
          <DetailModelViverCommentContentTextField
            label="코멘트 상세"
            inputValue={commentDetail}
            inputPlaceholder="코멘트를 입력해주세요"
            onChangeInputValue={onChangeCommentDetail}
          />
        </>
      )}
    </div>
  )
}

const DetailModelViverCommentTitleTextField: FunctionComponent<{
  label: string
  inputValue: string
  inputPlaceholder?: string
  onChangeInputValue: (inputValue: string) => void
}> = ({ label, inputValue, inputPlaceholder, onChangeInputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <input
        style={{
          flex: 1,
          height: 38,
          border: "none",
          borderBottom: "1px solid #1C2229",
          outline: "none",
          fontSize: 14,
          fontFamily: "Pretendard",
          lineHeight: "18px",
        }}
        type="text"
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={(event) => onChangeInputValue(event.target.value)}
      />
    </div>
  )
}

const DetailModelViverCommentContentTextField: FunctionComponent<{
  label: string
  inputValue: string
  inputPlaceholder?: string
  onChangeInputValue: (inputValue: string) => void
}> = ({ label, inputValue, inputPlaceholder, onChangeInputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "start" }}>
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <textarea
        style={{
          flex: 1,
          height: 160,
          padding: 16,
          border: "1px solid #CED2D6",
          borderRadius: 4,
          outline: "none",
          lineHeight: "24px",
          fontSize: 16,
          fontFamily: "Pretendard",
          resize: "vertical",
        }}
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={(event) => onChangeInputValue(event.target.value)}
      />
    </div>
  )
}
