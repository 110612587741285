import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"
import { useState } from "react"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { PostPointBodyType } from "types/PointType"
import { DateTime } from "luxon"
import { isNaN } from "lodash"
import { toDateStr } from "utils/datetimeUtil"

type Props = {
  isOpen: boolean
  closeModal: () => void
  addPoint: (body: PostPointBodyType) => void
}

const AddPointModal = ({ isOpen, closeModal, addPoint }: Props) => {
  const [message, setMessage] = useState("")
  const [point, setPoint] = useState<string>("")
  const [availableDate, setAvailableDate] = useState<string>("")

  const clickPostButton = () => {
    addPoint({
      reasonType: "수동 적립",
      reasonMessage: message,
      points: Number(point),
      availableDate: availableDate,
    })

    setMessage("")
    setPoint("")
    closeModal()
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>포인트 관리</h3>
          <div>회원 포인트를 관리할 수 있습니다.</div>
        </ModalContentHeader>
        <InlineWrapper>
          <LabelWrapper>지급 포인트</LabelWrapper>
          <SearchInput
            value={point}
            onChange={(e) => {
              const inputValue = Number(e.target.value)

              if (isNaN(inputValue)) {
                return
              }

              setPoint(inputValue.toString())
            }}
          />
        </InlineWrapper>
        <InlineWrapper>
          <LabelWrapper>만료일</LabelWrapper>
          <DatePicker
            type="date"
            min={toDateStr(DateTime.now().toISO(), true)}
            onChange={(e) => {
              setAvailableDate(toDateStr(e.target.value, true) || "")
            }}
          />
        </InlineWrapper>
        <div>메세지</div>
        <ModalContentBody>
          <TextArea value={message} onChange={(e) => setMessage(e.target.value)} />
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button
              buttonStyle="blue-fill"
              buttonSize="M"
              disabled={!point || Number(point) <= 0 || !availableDate || !message}
              onClick={clickPostButton}
            >
              지급
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default AddPointModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
`

const LabelWrapper = styled.div`
  margin-right: 10px;
  width: 80px;
`

const TextArea = styled.textarea`
  flex: 1;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  outline: none;
  padding: 10px;
  display: flex;
  algin-items: center;
`

const DatePicker = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid #eee;
  outline: none;
  border-radius: 4px;
  padding: 10px;
`
