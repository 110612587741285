import { Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";

const RenderCellExpand = (props: GridRenderCellParams) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const { value } = props;
    const textElementRef = useRef<HTMLDivElement | null>(null);
    const checkOverflow = () => {
        const clientWidth = textElementRef.current!.getBoundingClientRect().width;
        textElementRef.current!.style.overflow = "visible";
        const contentWidth = textElementRef.current!.getBoundingClientRect().width;
        textElementRef.current!.style.overflow = "hidden";
        setIsOverflow(contentWidth > clientWidth);
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener("resize", checkOverflow);
        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, []);

    return (
        <Tooltip title={value} disableHoverListener={!isOverflowed}>
      <span
          ref={textElementRef}
          style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
          }}
      >
        {value}
      </span>
        </Tooltip>
    );
};

export default RenderCellExpand;