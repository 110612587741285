import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ShopSwipeBannerType } from "types/ShopCurationType"
import { Box, Button, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { DateTime } from "luxon"
import _ from "lodash"
import EditIcon from "@mui/icons-material/Edit"

type Props = {
  swipeBanners: ShopSwipeBannerType[]
  setSwipeBanners: (swipeBanner: ShopSwipeBannerType[]) => void
  setUpSwipeBanners: (id : number) => void
  setDownSwipeBanners: (id : number) => void
  openShowSwipoeBannerDetailCreatePopup: () => void
  openShowSwipoeBannerDetailUpdatePopup: (swipeBanner:ShopSwipeBannerType) => void
}

function renderPreviewCell(params: any) {
  return (<img src={params?.row?.bannerImageUrl} style={{width: '100%'}} />)
}

function renderPostingPeriodCell(params: any) {
  const startedAt = DateTime.fromISO(params?.row?.startedAt)
  const endedAt = DateTime.fromISO(params?.row?.endedAt)

  return (
    <Stack direction={'column'} alignItems={"self-end"}>
      <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
    </Stack>
  )
}
function renderStatusCell(params: any) {
  if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
  if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}
const SwipeBannerDetailTable = ({ swipeBanners, setSwipeBanners, setUpSwipeBanners, setDownSwipeBanners, openShowSwipoeBannerDetailCreatePopup,  openShowSwipoeBannerDetailUpdatePopup}: Props) => {
  const [selectionProductIds, setSelectionCurationProductIds] = useState<number[]>([])
  const [rows, setRows] = useState<ShopSwipeBannerType[]>([])

  useEffect(() => {
      setRows(swipeBanners)
  }, [swipeBanners])

  const getColumns = () => {
      return [
        // {field: 'id', headerName: "ID", width: 70, },
        {field: 'title', headerName: "제목", width: 100},
        {field: 'bannerLinkUrl', headerName: "링크 URL", flex: 3},
        {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
        { field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
        { field: "postingStatus", headerName: "진행 상태", width: 100, renderCell: renderStatusCell },
        {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
        {field: 'actions', headerName: "순서(위치이동)", type: 'actions',  width: 110, getActions: (params: any) => ([
            <GridActionsCellItem
              icon={<ArrowUpwardIcon/>}
              label={"위로"}
              onClick={() => {
                const index = _.findIndex(rows, {id: params.row.id})
                if (index > 0) {
                  setUpSwipeBanners(index)
                }
              }}
            />,
            <GridActionsCellItem
              icon={<ArrowDownwardIcon/>}
              label={"아래로"}
              onClick={() => {
                const index = _.findIndex(rows, {id: params.row.id})
                if (index < rows.length-1) {
                  setDownSwipeBanners(index)
                }
              }}
            />,
            <GridActionsCellItem
              icon = {<EditIcon />}
              label = {"수정"}
              onClick={() => {
                openShowSwipoeBannerDetailUpdatePopup(params.row)
              }}
              // showInMenu
            />
          ])},
      ]
  }

  return (
    <Box>
        <Stack height={"100%"}>
          <ButtonWrapper>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={openShowSwipoeBannerDetailCreatePopup}
              style={{ marginRight: 10 }}
            >
              추가
            </Button>
            <Button
              variant={"contained"}
              color={"error"}
              size={"small"}
              disabled={selectionProductIds.length < 1}
              onClick={() => {
                if (!swipeBanners.length) return

                const swipeBannersApply = swipeBanners.filter(
                  (data) => data && !selectionProductIds.includes(data.id!!)
                )
                setSwipeBanners(swipeBannersApply)
              }
            }
            >
              삭제
            </Button>
          </ButtonWrapper>
        </Stack>
      <DataGrid
      //  getRowId={(row: any) => row.id ?? 0 }
        style={{
          height: "500px",
        }}
        columns={getColumns()}
        onSelectionModelChange={(ids) => {
          setSelectionCurationProductIds(ids as number[])
        }}
        checkboxSelection
        getRowHeight={() => 80}
        rows={swipeBanners ?? []}
        hideFooter
        onCellEditCommit={(params) => {
          const updatedRows = rows.map(row => {
            if (row.id === params.id) {
              return { ...row, priority: params.value };
            }
            return row;
          });
          setRows(updatedRows);
          setSwipeBanners(updatedRows)
        }}
      />
      {/*{swipeBanners}*/}
    </Box>
  )
}

export default SwipeBannerDetailTable

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
