import styled from "@emotion/styled"
import { isEmpty, isNil } from "lodash"
import { FunctionComponent } from "react"

export const NoticeDetailFormNotifyDate: FunctionComponent<{
  notifyDate?: string
  onChangeNotifyDate: (notifyDate: string) => void
}> = ({ notifyDate, onChangeNotifyDate }) => {
  return (
    <div>
      <InputDateTime
        type="date" // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
        value={notifyDate || ""} // YYYY-MM-DD
        required
        onChange={(event) => {
          if (!isNil(event.target.value) && !isEmpty(event.target.value)) {
            onChangeNotifyDate(event.target.value)
          }
        }}
      />
    </div>
  )
}

const InputDateTime = styled.input`
  display: inline-block;
  height: 40px;
  width: 200px;
  border: 1px solid #eee;
  outline: none;
  border-radius: 4px;
  padding: 10px;
`
