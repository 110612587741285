import {DataGrid, GridColumnHeaderParams, GridToolbarContainer} from '@mui/x-data-grid';
import React from "react";
import {BuyOrderState} from "./index";
import {BuyOrderStatusCode, BuyOrderType} from "types/BuyOrderType";
import {numberWithCommas, truncate} from "../../utils/NumberUtils";
import {toDateStr, toDateTimeStr, toTimeStr} from "../../utils/datetimeUtil";
import {Button, Grid, Stack} from "@mui/material";
import {ShippingStatusCode} from "types/ShippingType";
import testAPI from "../../apis/testAPI";

const renderMultiLineDateTime = (datetimeStr: string) => (
    <div style={{textAlign: "center"}}>
        <p>{toDateStr(datetimeStr, false)}<br/>
            {toTimeStr(datetimeStr)}</p>
    </div>
)

function createColumns(handleEditButtonClick: Function): any[] {
    const openBuyOrderPopup = (row: BuyOrderType) => {
        handleEditButtonClick(row)
    }
    const getColorByStatus = (status: BuyOrderStatusCode|ShippingStatusCode) => {
        switch (status) {
            case '결제 대기':
            case '배송 준비':
                return 'info'
            case '배송중':
            case '상품 발송':
                return 'error'
            case '구매 취소':
                return 'warning'
            case '구매 완료':
            case '배송 완료':
                return 'success'
        }
    }

    return [
        {
            field: 'id',
            headerName: "구매주문번호",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 130,
            maxWidth: 160,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>상품번호 <br/> (구매주문번호)</p>),
            valueGetter: (params: any) => `${params.row.id}`,
            renderCell: (params: any) => (
                <div style={{textAlign: "left"}}>
                    <p><span style={{fontWeight: "bold"}}>{params.row.product?.id}</span>
                        <br/><span style={{color: '#aaa'}}>{params.row.id}</span></p>
                </div>
            )
        },
        {
            field: 'productId',
            headerName: "상품번호",
            hide: true,
            valueGetter: (params: any) => `${params.row?.product?.id}`,
        },
        {field: 'buyOrderStatus', headerName: "진행상태", editable: false, sortable: false,
            flex: 1,
            minWidth: 90,
            maxWidth: 120,
            valueGetter: (params: any) => {
                return `${params.row.buyOrderStatus}`
            },
            renderCell: (params: any) => (
                <div>
                    <Button size={"small"} variant={"outlined"} style={{fontWeight: "bold"}}
                            onClick={() => {openBuyOrderPopup(params.row)}}
                            color={getColorByStatus(params.row.buyOrderStatus)}>{params.row.buyOrderStatus}</Button>
                </div>
            )
        },
        {field: 'saleType', headerName: "판매방식", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => `${params.row.product?.saleType}`,
        },
        {field: 'penaltyPaymentStatus', headerName: "페널티 결제상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => {
                return `${params.row.refund?.latestPenaltyPayment?.status ?? '-'}`
            },
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>페널티<br/>결제상태</p>),
        },
        {field: 'pickupShippingStatus', headerName: "수거 배송상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => {
                return `${params.row.refund?.pickUpShipping?.shippingStatus ?? '-'}`
            },
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>수거<br/>배송상태</p>),
        },
        {field: 'returnShippingStatus', headerName: "반송 배송상태", editable: false, sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => {
                return `${params.row.refund?.returnShipping?.shippingStatus ?? '-'}`
            },
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>반송<br/>배송상태</p>),
        },
        {field: 'title', headerName: "상품명", editable: false, sortable: false,
            flex: 1,
            minWidth: 220,
            valueGetter: (params: any) => `${params.row.product?.title}`,
        },
        {field: 'paymentAmount', headerName: "결제 금액", editable: false, sortable: false,
            align: 'right',
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            valueGetter: (params: any) => {
                const price = params.row.paymentAmount
                return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
            }
        },
        {field: 'feeAmount', headerName: "반품 수수료", editable: false, sortable: false,
            align: 'right',
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            valueGetter: (params: any) => {
                const price = params.row.refund?.feeAmount
                return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
            }
        },
        {
            field: 'buyerName',
            headerName: "구매자 정보",
            editable: false,
            sortable: false,
            minWidth: 120,
            maxWidth: 120,
            valueGetter: (params: any) => `${params.row.buyer?.name}`,
            renderCell: (params: any) => (
                <div>
                    <p>{params.row.buyer?.name}<br/>
                        {params.row.buyer?.phone}</p>
                </div>
            )
        },
        {
            field: 'orderedAt',
            headerName: "구매일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => renderMultiLineDateTime(params.row.orderedAt)
        },
        {
            field: 'completedAt',
            headerName: "구매완료일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>구매<br/>완료일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => renderMultiLineDateTime(params.row.completedAt)
        },
        {
            field: 'canceledAt',
            headerName: "구매취소일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>구매<br/>취소일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => renderMultiLineDateTime(params.row.canceledAt)
        },
        {
            field: 'requestedAt',
            headerName: "반품요청일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>반품<br/>요청일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => renderMultiLineDateTime(params.row.refund?.requestedAt)
        },
        {
            field: 'rejectedAt',
            headerName: "반품거절일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>반품<br/>거절일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => renderMultiLineDateTime(params.row.refund?.rejectedAt)
        },
        {
            field: 'approvedAt',
            headerName: "반품완료일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>반품<br/>완료일</p>),
            valueGetter: (params: any) => toDateTimeStr(params.value),
            renderCell: (params: any) => renderMultiLineDateTime(params.row.refund?.approvedAt)
        },
    ]
}

type BuyOrderProps = {
    state: BuyOrderState,
    dispatch: any,
    fetch: Function,
    components: any[],
}

const BuyOrderTable: React.FC<BuyOrderProps> = ({state, dispatch, fetch, components}) => {

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{justifyContent: "flex-end"}}>
                {components}
            </GridToolbarContainer>
        );
    }

    const handleSelection = (ids: any[]) => {dispatch({type: 'SELECTION_BuyOrder_IDS', payload: ids as string[]})}
    const handleEditButtonClick = (row: BuyOrderType) => {
        dispatch({type: 'SHOW_VIEW_BUYORDER_POPUP', payload: row})
    }

    return (
        <Grid container spacing={1} sx={{pt: 1}}>
            <Grid item xs={12}>
            <div style={{height: 1200, width: '100%'}}>
                <DataGrid
                    components={{Toolbar: CustomToolbar}}
                    getRowId={row => row.id!}
                    columns={createColumns(handleEditButtonClick)}

                    pagination
                    {...dispatch.detailModels?.content}
                    paginationMode="server"
                    onPageChange={(page) => {
                        fetch({page, size: state.buyOrders.pageable?.pageSize || 25})
                    }}
                    onPageSizeChange={(size) => {
                        fetch({page: 0, size})
                    }}

                    page={state.buyOrders.pageable?.pageNumber || 0}
                    pageSize={state.buyOrders.pageable?.pageSize || 25}
                    rowCount={state.buyOrders?.totalElements || 0}
                    rows={state.buyOrders?.content || []}

                    selectionModel={state.selectedIds}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    onSelectionModelChange={handleSelection}
                    loading={state.loading}
                />
            </div>
            </Grid>
        </Grid>)
}

// @ts-ignore
export default BuyOrderTable