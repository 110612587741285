import {httpGet, httpPost, httpPut} from "../utils/httpAPI"
import {PageType} from "types/PageType"
import {ShopContentType} from "../types/ShopContentType";
import {ShopCurationCreateBodyType, ShopCurationType} from "../types/ShopCurationType";
import { ShopHeroBannerType } from "../types/ShopHeroBannerType"

export default {
  getDetail: (curationId: number) => httpGet<ShopContentType>(`/shop/contents/${curationId}`),
  getPage: (params?: { [key: string]: any }): Promise<PageType<ShopContentType>> => httpGet("/shop/contents", params),
  getList: (params?: { [key: string]: any }): Promise<ShopContentType[]> => httpGet("/shop/contents/list", params),
  reordering: (list: ShopContentType[]): Promise<ShopContentType[]> => httpPut(`/shop/contents/reordering`, list),
  reorderingByDirect: (list: ShopContentType[]): Promise<ShopContentType[]> => httpPut(`/shop/contents/reordering/direct`, list),
  update: (curation: ShopCurationType) => httpPut<ShopCurationType>(`/shop/contents/${curation.id}`, curation),
  create: (curation: ShopCurationCreateBodyType) => httpPost<ShopCurationType>("/shop/contents", curation),
}
