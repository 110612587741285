import React, { useEffect, useState } from "react"
import { Box, Button, Input, Modal, Stack } from "@mui/material"
import { ShippingType } from "types/ShippingType"
import SearchAddressPopup from "./SearchAddressPopup"
import { toast } from "react-toastify"
import { getShipping, updateShippingAddress } from "../../apis/shippingAPI"

type Props = {
  shippingId: number | undefined
  onUpdateAddress: Function
  disabled: boolean
}

const ChangeShippingAddress = ({ shippingId, onUpdateAddress, disabled }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [shipping, setShipping] = useState<ShippingType>()
  const [newShipping, setNewShipping] = useState<ShippingType>()

  useEffect(() => {
    if (open && shippingId) {
      getShipping(shippingId).then((result) => {
        setShipping(result)
        setNewShipping(result)
      })
    }
  }, [open])

  const handleSetAddress = (addr: any) => {
    setNewShipping({ ...newShipping, zipCode: addr.zonecode, address: addr.roadAddress, addressDetail: "" })
  }

  const updateAddress = () => {
    updateShippingAddress(shippingId!!, newShipping!!).then((result) => {
      toast.success("배송 주소를 업데이트 했습니다.")
      onUpdateAddress(result.address + " " + result.addressDetail)
      setOpen(false)
    }).catch(() => {
      toast.error("배송 주소를 업데이트 하지 못했습니다.")
    })
  }

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    height: "600px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    margin: "auto",
  }

  return (
    <>
      <Button
        variant={"outlined"}
        size={"small"}
        color={"info"}
        disabled={disabled}
        onClick={() => {
          setOpen(true)
        }}>
        주소 변경
      </Button>

      {open && (<Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td width={"10%"} className={"header"}></td>
              <td width={"10%"} className={"header"}>우편번호</td>
              <td width={"40%"} className={"header"}>주소</td>
              <td width={"40%"} className={"header"}>상세 주소</td>
            </tr>
            <tr>
              <td>
                {"현재"}
              </td>
              <td align={"left"}>
                {shipping?.zipCode}
              </td>
              <td align={"left"}>
                {shipping?.address}
              </td>
              <td align={"left"}>
                {shipping?.addressDetail}
              </td>
            </tr>
            <tr>
              <td>
                {"변경"}
              </td>
              <td align={"left"}>
                {newShipping?.zipCode}
              </td>
              <td align={"left"}>
                {newShipping?.address}
              </td>
              <td align={"left"}>
                <Input
                  placeholder={"주소 상세"}
                  size={"small"}
                  sx={{ width: "100%", textAlign: "center" }}
                  name={"addressDetail"}
                  value={newShipping?.addressDetail}
                  onChange={(event) => {
                    setNewShipping({ ...newShipping, [event.target.name]: event.target.value })
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                  <SearchAddressPopup handleClose={handleSetAddress} />
                  <Button
                    variant={"outlined"}
                    size={"medium"}
                    color={"info"}
                    onClick={() => {
                      updateAddress()
                    }}>
                    저장하기
                  </Button>
                  <Button
                    variant={"outlined"}
                    size={"medium"}
                    color={"error"}
                    onClick={() => {
                      setOpen(false)
                    }}>
                    닫기
                  </Button>
                </Stack>
              </td>
            </tr>
            </tbody>
          </table>
        </Box>
      </Modal>)}


    </>
  )
}

export default ChangeShippingAddress
