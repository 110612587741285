import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextareaAutosize, Typography } from "@mui/material"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { rejectSellOrder } from "../../../apis/sellOrderAPI"

type BeforeDiagnosisSellOrderRejectReasonPopupProps = {
  open: boolean
  sellOrderId: string
  handleClose: Function
}

type ReasonType = {
  type: string
  details: string[]
}

type RejectReasonParam = {
  reasonTypeIndex: number
  reasonType: ReasonType
  reasonDetailIndex: number
  reasonDetail: string
}

const BeforeDiagnosisSellOrderRejectReasonPopup: React.FC<BeforeDiagnosisSellOrderRejectReasonPopupProps> = ({
                                                                                                               open,
                                                                                                               sellOrderId,
                                                                                                               handleClose,
                                                                                                             }) => {


  const reasonTypeList: ReasonType[] = [
    { type: "모델 정보 및 레퍼런스", details: ["미취급 브랜드 또는 모델"] },
    { type: "상품상태", details: ["미착용/중고 상태 재확인(보호필름이 부착된 시계는 중고 상품으로 등록)", "스탬핑 연도 재확인", "보증서 유무 재확인", "정품박스 유무 재확인", "부속품 유무 재확인"] },
    { type: "자가진단 체크리스트", details: ["찍힘/눌림 재확인", "글라스 흠집 재확인", "링크 개수 정보 누락 및 불일치", "보호 필름 부착 여부 재확인"] },
    { type: "이미지", details: ["상품 사진 교체, 추가 및 삭제 필요(저화질 또는 부적절한 이미지)", "실제 상품과 무관한 사진 첨부", "첨부 이미지로 레퍼런스 및 상태 확인 불가", "상품 실물 사진 누락"] },
    { type: "상세 설명글", details: ["상품상태 체크리스트와 설명글 불일치", "자가진단 체크리스트와 설명글 불일치", "러버밴드 (오이스터플렉스) 사이즈 기입 필요", "판매 상품과 무관한 설명글", "부적절한 타사 언급", "욕설이나 지나친 비속어", "광고성 글"] },
    { type: "직접 입력", details: [] },
  ]

  const initParam: RejectReasonParam = {
    reasonTypeIndex: 0,
    reasonType: reasonTypeList[0],
    reasonDetailIndex: 0,
    reasonDetail: reasonTypeList[0].details[0],
  }

  const [rejectReasonParam, setRejectReasonParam] = useState<RejectReasonParam>(initParam)

  const onChangeReasonType = (index: number) => {
    setRejectReasonParam({ ...rejectReasonParam, reasonTypeIndex: index, reasonType: reasonTypeList[index], reasonDetailIndex: 0, reasonDetail: reasonTypeList[index].details[0] })
  }

  const onChangeReasonDetailIndex = (index: number) => {
    setRejectReasonParam({ ...rejectReasonParam, reasonDetailIndex: index, reasonDetail: rejectReasonParam.reasonType.details[index] })
  }

  const onChangeReasonDetail = (text: string) => {
    setRejectReasonParam({ ...rejectReasonParam, reasonDetail: text })
  }

  const reject = () => {
    if (!window.confirm("선택한 사유로 판매를 거절 하시겠습니까?\n고객에게 해당 판매 건에 대한 반려 알림이 발송됩니다.")) {
      return
    }
    rejectSellOrder(sellOrderId, {
      rejectReasonType: reasonTypeList.at(rejectReasonParam.reasonTypeIndex)!!.type,
      rejectReasonDetail: rejectReasonParam.reasonDetail,
    })
      .then((res) => {
        toast.success("판매거절 완료")
        onClose()
      })
      .catch((error) => {
        toast.error(`판매거절 실패 (${error.message || error})`)
      })
  }

  const onClose = () => {
    setRejectReasonParam(initParam)
    handleClose()
  }

  return (
    <>
      <Dialog open={open} maxWidth="md" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <DialogTitle>
          <Typography fontWeight={"bold"} fontSize={"24px"}>
            거절 사유 등록
          </Typography>
        </DialogTitle>
        <DialogContent>
          <table className={"basic"}>
            <thead>
            <tr>
              <td className={"no-border"} width="30%">
                <Typography fontWeight={"bold"} fontSize={"20px"}>
                  항목 구분
                </Typography>
              </td>
              <td className={"no-border"}>
                <Typography fontWeight={"bold"} fontSize={"20px"}>
                  세부 사유
                </Typography>
              </td>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td style={{ textAlign: "start" }}>
                <Select
                  size={"small"}
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  required
                  value={rejectReasonParam.reasonTypeIndex}
                  onChange={(e) => {
                    const value = e.target.value as number
                    onChangeReasonType(value)
                  }}
                >
                  {reasonTypeList.map((row, index) => (
                    <MenuItem value={index}>
                      {row.type}
                    </MenuItem>
                  ))}
                </Select>

              </td>
              <td style={{ textAlign: "start" }}>
                {
                  rejectReasonParam.reasonType.details.length === 0
                    ? <TextareaAutosize
                      style={{
                        width: "100%",
                      }}
                      minRows={5}
                      maxLength={40}
                      value={rejectReasonParam.reasonDetail}
                      onChange={(e) => {
                        const value = e.target.value as string
                        onChangeReasonDetail(value)
                      }}
                    />
                    : <Select
                      size={"small"}
                      style={{
                        width: "100%",
                        height: "36px",
                      }}
                      required
                      value={rejectReasonParam.reasonDetailIndex}
                      onChange={(e) => {
                        const value = e.target.value as number
                        onChangeReasonDetailIndex(value)
                      }}
                    >
                      {rejectReasonParam.reasonType.details.map((row, index) => (
                        <MenuItem value={index}>
                          {row}
                        </MenuItem>
                      ))}
                    </Select>
                }

              </td>
            </tr>
            </tbody>
          </table>

        </DialogContent>
        <DialogActions>
          <Button size={"medium"} disabled={rejectReasonParam.reasonDetail === ""} variant={"contained"} color={"error"} onClick={reject}>
            거절
          </Button>
          &nbsp;&nbsp;
          <Button size={"medium"} variant={"outlined"} color={"error"} onClick={onClose}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BeforeDiagnosisSellOrderRejectReasonPopup
