import styled from "@emotion/styled"
import Button from "components/Button"
import { useState } from "react"
import { UpgradeServiceOrderDetailModal } from "../UpgradeServiceDetail"
import { UpgradeServiceListTable } from "./UpgradeServiceListTable"
import { UpgradServiceSearchBar } from "./UpgradeServiceSearchBar"
import { useUpgradeServiceOrderList } from "./useUpgradeServiceOrderList"
import { UpgradeServiceOrderRegisterModal } from "../UpgradeServiceRegisterModal"
import { UpgradeServiceCountBar } from "./UpgradeServiceCountBar"

export const UpgradeServiceListPage = () => {
  const [isOpenUpgradeServiceRegisterModal, setIsOpenUpgradeServiceRegisterModal] = useState<boolean>(false)
  const [selectedUpgradeServiceOrderId, setSelectedUpgradeServiceOrderId] = useState<string>()
  const {
    changeSearchFilterValue,
    changePageNumber,
    changeSearchKeyword,
    onSearch,
    onRefresh,
    onSelectStatus,
    upgradeServiceOrderList,
    paginationParams,
    searchParams,
    countList,
    filterStatus,
  } = useUpgradeServiceOrderList()

  return (
    <>
      {isOpenUpgradeServiceRegisterModal && (
        <UpgradeServiceOrderRegisterModal
          closeModal={() => {
            setIsOpenUpgradeServiceRegisterModal(false)
            onRefresh()
          }}
        />
      )}
      {!!selectedUpgradeServiceOrderId && (
        <UpgradeServiceOrderDetailModal
          orderId={selectedUpgradeServiceOrderId}
          closeModal={() => {
            setSelectedUpgradeServiceOrderId(undefined)
            onRefresh()
          }}
        />
      )}

      <PageHeaderWrapper>
        <UpgradeServiceCountBar onSelectStatus={onSelectStatus} countList={countList} filterStatus={filterStatus} />
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <SearchBarWrapper>
          <UpgradServiceSearchBar
            changeSearchFilterValue={changeSearchFilterValue}
            changeSearchKeyword={changeSearchKeyword}
            onSearch={onSearch}
            searchParams={searchParams}
          />
        </SearchBarWrapper>
        <InlineWrapper>
          <ButtonWrapper style={{ width: "73px" }}>
            <Button buttonSize="M" onClick={() => setIsOpenUpgradeServiceRegisterModal(true)}>
              수리 등록
            </Button>
          </ButtonWrapper>
        </InlineWrapper>
        <UpgradeServiceListTable
          upgradeServiceOrderList={upgradeServiceOrderList}
          paginationParams={paginationParams}
          changePage={changePageNumber}
          selectRow={(orderId) => setSelectedUpgradeServiceOrderId(orderId)}
        />
      </PageBodyWrapper>
    </>
  )
}

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
`

const PageBodyWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
`

const SearchBarWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 28px;
`

const ButtonWrapper = styled.div``

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
`
