import { createUserMemo, getUserMemos } from "apis/userMemoAPI"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { UserMemoTargetTypeCode, UserMemoType } from "types/UserType"

const FETCH_LIST_COUNT = 5

export const useSerialNumberMemo = (serialNo: string) => {
  const [newMemo, setNewMemo] = useState<string>("")

  const [memoList, setMemoList] = useState<UserMemoType[]>([])

  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })

  const changeNewMemo = (memo: string) => {
    setNewMemo(memo)
  }

  const fetchMemoList = useCallback(
    (pageNumber?: number) => {
      getUserMemos({
        page: pageNumber,
        size: FETCH_LIST_COUNT,
        serialNo,
      }).then(({ content, totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
        setMemoList(content)
        setPaginationParams((prev) => ({
          ...prev,
          number,
          totalElements,
          totalPages,
          first,
          size,
        }))
      })
    },
    [serialNo]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchMemoList(pageNumber)
    },
    [fetchMemoList]
  )

  const onRegisterNewMemo = async () => {
    try {
      createUserMemo({ serialNo, memo: newMemo, targetType: UserMemoTargetTypeCode.SERIAL_NO }).then(() => {
        setNewMemo("")
        fetchMemoList(0)
      })
    } catch {
      toast.error("메모를 생성할 수 없습니다.")
    }
  }

  useMount(() => fetchMemoList(0))

  return {
    newMemo,
    changePageNumber,
    changeNewMemo,
    memoList,
    paginationParams,
    onRegisterNewMemo,
  }
}
