import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useReducer } from "react"
import { useSearchParams } from "react-router-dom"
import BeforeDiagnosisSellOrderTable from "./BeforeDiagnosisSellOrderTable"
import {
  cancelSellOrder,
  countSellOrders,
  deniedSellOrderShipping,
  getSellOrderList,
  getSellOrders,
} from "../../../apis/sellOrderAPI"
import { OrderType, SellOrderListSearchParams, SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { PageType } from "types/PageType"
import { CountType } from "types/CountType"
import Counters from "../Counters"
import { toApplyCounts } from "../../../utils/SellOrderTypeConverter"
import { DesktopDatePicker } from "@mui/lab"
import { DateTime } from "luxon"
import ConfirmBeforeDiagnosisSellOrderPopup from "./ConfirmBeforeDiagnosisSellOrderPopup"
import ShippingRequestPopup from "../../../components/Popup/ShippingRequestPopup"
import { toast } from "react-toastify"
import ConfirmReceivingPopup from "../Consignment/ConfirmReceivingPopup/ConfirmReceivingPopup"
import { getShippings } from "../utils"
import { downloadExcel } from "../../../utils/excelUtil"
import { toDateTimeStr } from "../../../utils/datetimeUtil"
import { revertShipping } from "../../../apis/shippingAPI"
import { ShippingStatusCode } from "../../../types/ShippingType"

export interface SellOrderState {
  selectedIds: string[]
  sellOrders: PageType<SellOrderType>
  sellOrderCounts: CountType[]
  shippingStatusCode: ShippingStatusCode[]
  isConfirmPopup: boolean
  isShippingRequestPopupOpen: boolean
  isConfirmReceivingPopupOpen: boolean
  editSellOrder?: SellOrderType
  startDateFilter: DateTime | null
  endDateFilter: DateTime | null
  searchFilterKey: string | null
  searchFilterValue: string | null
  refresh: boolean
  loading: boolean
}

const initState: (orderId?: string | null) => SellOrderState = (orderId) => {
  let state: SellOrderState = {
    selectedIds: [],
    sellOrders: {
      content: [],
      pageable: {
        pageSize: 25,
        pageNumber: 0,
      },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    sellOrderCounts: [],
    shippingStatusCode: [
      ShippingStatusCode.배송_준비,
      ShippingStatusCode.배송중,
      ShippingStatusCode.배송_완료,
      ShippingStatusCode.상품_발송,
    ],
    isConfirmPopup: false,
    isShippingRequestPopupOpen: false,
    isConfirmReceivingPopupOpen: false,
    editSellOrder: undefined,
    startDateFilter: null,
    endDateFilter: null,
    searchFilterKey: "productId",
    searchFilterValue: null,
    refresh: false,
    loading: false,
  }

  if (orderId) {
    state.searchFilterKey = "id"
    state.searchFilterValue = orderId
  }

  return state
}

interface SellOrderAction {
  type: string
  payload?: any
}

function getSelectedOrders(state: SellOrderState, types?: SellOrderStatusCode[]): SellOrderType[] {
  if (types)
    return state.sellOrders.content.filter(
      (it) => state.selectedIds.includes(it.id!) && types.includes(it.sellOrderStatus!!)
    )
  else return state.sellOrders.content.filter((it) => state.selectedIds.includes(it.id!))
}

function reducer(state: SellOrderState, action: SellOrderAction): SellOrderState {
  switch (action.type) {
    case "SELECTION_SELLORDER_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_SELLORDER":
      return { ...state, sellOrders: action.payload }
    case "FETCH_SELLORDER_COUNT":
      return { ...state, sellOrderCounts: action.payload }
    case "SHOW_DELIVERY_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: true }
    case "HIDE_DELIVERY_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: false }
    case "SHOW_CONFIRM_RECEIVING_POPUP_OPEN":
      return { ...state, isConfirmReceivingPopupOpen: true }
    case "HIDE_CONFIRM_RECEIVING_POPUP_OPEN":
      return { ...state, isConfirmReceivingPopupOpen: false }
    case "SHOW_CONFIRM_POPUP_OPEN":
      return { ...state, isConfirmPopup: true, editSellOrder: action.payload }
    case "HIDE_CONFIRM_POPUP_OPEN":
      return { ...state, isConfirmPopup: false }
    case "CHANGE_SHIPPING_STATUS_CODE":
      return { ...state, shippingStatusCode: action.payload, refresh: !state.refresh }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload, refresh: !state.refresh }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload, refresh: !state.refresh }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const BeforeDiagnosisSellOrder = () => {
  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(reducer, initState(currentSearchParams.get("orderId")))
  const numericFields = ["productId", "sellerId"]

  const fetchSellOrders = (searchParams: SellOrderListSearchParams = {}) => {
    let newParams: SellOrderListSearchParams = {
      ...searchParams,
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) ?? undefined,
      sellerName: currentSearchParams.get("sellerName") ?? undefined,
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      productId: currentSearchParams.get("productId") ?? undefined,
      sellOrderType: [OrderType.진단전], // 진단전 고정
      startDate: state?.startDateFilter?.toISODate(),
      endDate: state?.endDateFilter?.toISODate(),
      shippingStatusCode: state.shippingStatusCode,
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getSellOrders(newParams)
      .then((sellOrders) => dispatch({ type: "FETCH_SELLORDER", payload: sellOrders }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = async (searchParams: SellOrderListSearchParams = {}) => {
    const result = await countSellOrders({
      ...searchParams,
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) ?? undefined,
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      sellOrderType: [OrderType.진단전],
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      shippingStatusCode: state.shippingStatusCode,
    })
    dispatch({
      type: "FETCH_SELLORDER_COUNT",
      payload: toApplyCounts(
        result,
        [
          SellOrderStatusCode.판매_대기,
          SellOrderStatusCode.판매중,
          SellOrderStatusCode.픽업_준비,
          SellOrderStatusCode.입고중,
          SellOrderStatusCode.입고_완료,
          SellOrderStatusCode.판매_포기,
          SellOrderStatusCode.반려,
        ],
        ".",
        [],
        true
      ),
    })
  }

  const handleConfirmClick = () => {
    if (state.selectedIds.length > 0) {
      dispatch({ type: "SHOW_CONFIRM_RECEIVING_POPUP_OPEN" })
    } else {
      toast.error("선택한 내역이 없습니다.")
    }
  }

  const handleCancelClick = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    if (window.confirm("선택한 판매주문을 판매포기 처리하시겠습니까?")) {
      Promise.all(state.selectedIds.map((id) => cancelSellOrder(id)))
        .then(() => {
          dispatch({ type: "REFRESH" })
          alert(`판매포기 처리하였습니다.`)
        })
        .catch((err) => {
          if (err.message == undefined) {
            toast.error("에러가 발생하였습니다.")
          } else {
            toast.error(err.message)
            console.log("Error", err)
          }
        })
    }
  }

  const handleExcelDownloadClick = () => {
    if (state.startDateFilter === null) {
      toast.error("시작일을 선택해주세요")
      return false
    }
    if (state.endDateFilter === null) {
      toast.error("종료일을 선택해주세요")
      return false
    }

    let newParams: SellOrderListSearchParams = {
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) ?? undefined,
      sellerName: currentSearchParams.get("sellerName") ?? undefined,
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      productId: currentSearchParams.get("productId") ?? undefined,
      sellOrderType: [OrderType.진단전], // 진단전 고정
      startDate: state?.startDateFilter?.toISODate(),
      endDate: state?.endDateFilter?.toISODate(),
      shippingStatusCode: state.shippingStatusCode,
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getSellOrderList(newParams).then((sellOrders) => {
      if (sellOrders.length == 0) {
        toast.error("다운로드 할 내역이 없습니다.")
        return false
      }
      let data = sellOrders.map((it) => {
        return {
          판매주문번호: it.id,
          상품번호: it?.product?.id,
          진행상태: it.sellOrderStatus,
          제품상태: it.product?.exposedProductInfo?.productCondition,
          스탬핑:
            it.product?.exposedProductInfo?.stampingDate != null
              ? it.product.exposedProductInfo.stampingDate
              : it.product?.exposedProductInfo?.stampingYear,
          입고방식: it.shipping?.shippingMethod?.replace("안전배송_2", "일양택배")?.replace("안전배송", "발렉스"),
          해외판매: it.product?.globalSale ? "동의" : "미동의",
          판매자ID: it.seller?.id,
          입고상태: it.shipping?.shippingStatus,
          상품명: `${it?.product?.title} ${it?.product?.titleSecondary}`,
          "Ref No.": it?.product?.detailModel?.fullRefNo,
          판매희망가: it?.product?.price,
          판매신청일: toDateTimeStr(it?.acceptedAt),
          판매승인일: toDateTimeStr(it?.onSaleAt),
          판매취소일: toDateTimeStr(it?.cancelSaleAt),
        }
      })
      downloadExcel(data, "판매관리_진단전")
    })
  }

  const handlePickupClick = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    dispatch({ type: "SHOW_DELIVERY_REQUEST_POPUP_OPEN" })
  }
  const handleRevertClick = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    if (getSelectedOrders(state, [SellOrderStatusCode.입고중]).length != state.selectedIds.length) {
      toast.error("입고중인 상태만 픽업 요청 취소 할 수 있습니다.")
      return
    }

    if (
      window.confirm(
        `선택된 ${state.selectedIds.length}건의 입고중 상태를 픽업 준비 단계로 되돌립니다.\n배송업체에 이미 픽업 요청이 전달된 경우 필히 취소처리 바랍니다.`
      )
    )
      revertShipping({
        items: getSelectedOrders(state, [SellOrderStatusCode.입고중]).map((it) => {
          return { id: it.shipping?.id }
        }),
        manually: true,
      })
        .then((res) => {
          toast.success(`${res.succeed?.length}건의 배송 상태가 변경되었습니다.`)
        })
        .catch((err) => {
          toast.error(`픽업 요청 취소가 실패했습니다. (${err})`)
        })
        .finally(() => dispatch({ type: "REFRESH" }))
  }

  const handleDeniedSellOrderShipping = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    if (
      window.confirm(
        "판매자가 입고 거부하여 배송 상태와 상관없이 페널티가 1.8% 가 부여됩니다.\n[판매자 입고 거부] 처리하시겠습니까?"
      )
    ) {
      Promise.all(state.selectedIds.map((id) => deniedSellOrderShipping(id)))
        .then(() => {
          dispatch({ type: "REFRESH" })
          alert(`[판매자 입고 거부] 처리가 완료되었습니다.`)
        })
        .catch((err) => {
          if (err.message == undefined) {
            toast.error("에러가 발생하였습니다.")
          } else {
            toast.error(err.message)
            console.log("Error", err)
          }
        })
    }
  }

  useEffect(() => {
    fetchSellOrders({
      page: state.sellOrders.pageable?.pageNumber || 0,
      size: state.sellOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>
      <ConfirmBeforeDiagnosisSellOrderPopup
        open={state.isConfirmPopup}
        selectedSellOrderId={state.editSellOrder?.id}
        handleClose={() => {
          dispatch({ type: "HIDE_CONFIRM_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ShippingRequestPopup
        type={"RETURN"}
        open={state.isShippingRequestPopupOpen}
        selectedShipping={getShippings(state.sellOrders.content, state.selectedIds)}
        handleClose={() => {
          dispatch({ type: "HIDE_DELIVERY_REQUEST_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ConfirmReceivingPopup
        open={state.isConfirmReceivingPopupOpen}
        initOrders={getSelectedOrders(state)}
        handleClose={() => {
          dispatch({ type: "HIDE_CONFIRM_RECEIVING_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <Grid item xs={12}>
        <Counters params={state.sellOrderCounts} selectedLabel={currentSearchParams.get("status")} />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ pl: 4, py: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography style={{ marginRight: "30px", fontWeight: 700 }}>배송 상태</Typography>
          {[
            ShippingStatusCode.배송_준비,
            ShippingStatusCode.배송중,
            ShippingStatusCode.배송_완료,
            ShippingStatusCode.상품_발송,
          ].map((it) => (
            <FormControlLabel
              label={it}
              control={
                <Checkbox
                  checked={state.shippingStatusCode.indexOf(it) > -1}
                  onChange={(e) => {
                    dispatch({
                      type: "CHANGE_SHIPPING_STATUS_CODE",
                      payload: e.target.checked
                        ? state.shippingStatusCode.concat(it)
                        : state.shippingStatusCode.filter((code) => code != it),
                    })
                  }}
                />
              }
            />
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
            <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>판매 신청일</InputLabel>
            <DesktopDatePicker
              label="시작일"
              inputFormat="yyyy-MM-dd"
              value={state.startDateFilter}
              onChange={(params) => {
                dispatch({ type: "CHANGE_START_DATE", payload: params })
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
            />
            <DesktopDatePicker
              label="종료일"
              inputFormat="yyyy-MM-dd"
              value={state.endDateFilter}
              onChange={(params) => {
                dispatch({ type: "CHANGE_END_DATE", payload: params })
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_key">검색항목</InputLabel>
              <Select
                value={state.searchFilterKey || "productId"}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                }}
              >
                <MenuItem value={"id"}>판매주문번호</MenuItem>
                <MenuItem value={"productId"}>상품번호</MenuItem>
                <MenuItem value={"shippingMethod"}>입고방식</MenuItem>
                <MenuItem value={"productTitle"}>상품명</MenuItem>
                <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                <MenuItem value={"sellerPhone"}>판매자 연락처</MenuItem>
                <MenuItem value={"sellerId"}>판매자ID</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "220px", marginBottom: "10px" }}
              size={"small"}
              value={state.searchFilterValue || ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch({ type: "SEARCH" })
                }
              }}
              onChange={(params) => {
                const value = numericFields.includes(state.searchFilterKey || "")
                  ? params.target.value.replace(/[^0-9]/g, "")
                  : params.target.value

                dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <BeforeDiagnosisSellOrderTable
              state={state}
              dispatch={dispatch}
              fetch={fetchSellOrders}
              components={[
                <Button
                  variant={"outlined"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleExcelDownloadClick}
                >
                  엑셀 다운로드
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handlePickupClick}>
                  픽업 요청
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handleRevertClick}>
                  픽업 요청 취소
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handleConfirmClick}>
                  입고확인 및 QR 생성
                </Button>,
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  color={"error"}
                  onClick={handleCancelClick}
                >
                  판매 포기
                </Button>,
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  color={"error"}
                  onClick={handleDeniedSellOrderShipping}
                >
                  판매자 입고 거부
                </Button>,
              ]}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BeforeDiagnosisSellOrder
