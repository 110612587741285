import React from "react"

type Props = {
  title: string
}

const DialogBlock: React.FC<React.PropsWithChildren<Props>> = ({ children, title }) => {
  return (
    <div className={"css-wb57ya-MuiFormControl-root-MuiTextField-root"}>
      <label className={"css-1sumxir-MuiFormLabel-root-MuiInputLabel-root"}>{title}</label>
      <div className={"css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root"}>
        {children}
        <fieldset
          aria-hidden="true"
          className="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline"
        >
          <legend className="css-1in441m">
            <span>{title}</span>
          </legend>
        </fieldset>
      </div>
    </div>
  )
}

export default DialogBlock
