import { getMyPageBannerDetail, postMyPageBanner, putMyPageBanner } from "apis/myPageBannerAPI"
import { upload } from "apis/resourceAPI"
import { isEmpty } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { MyPageBannerType, BannerExposureAreaCode } from "types/MyPageBannerType"
import { isNil } from "utils/validationUtils"
import { ThemeModeTypeCode } from "types/ShopHeroBannerType"
import { DateTime } from "luxon"

export const useMyPageBannerEditPopup = (editingBannerId?: number) => {
  const [form, setForm] = useState<Omit<MyPageBannerType, "id">>({
    bannerImageUrl: null,
    bannerLinkUrl: "",
    priority: 0,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO(),
    onDisplay: false,
    exposureAreas: BannerExposureAreaCode.MYPAGE_HOME.toString(),
    themeModeType: ThemeModeTypeCode.DARK,
    title1: null,
    title2: null,
    showDomesticUser: true,
    showForeignUser: false
  })

  useEffect(() => {
    if (isNil(editingBannerId)) return

    getMyPageBannerDetail(editingBannerId).then((response) => {
      setForm({
        bannerImageUrl: response.bannerImageUrl,
        bannerLinkUrl: response.bannerLinkUrl,
        startedAt: response.startedAt,
        endedAt: response.endedAt,
        priority: response.priority,
        onDisplay: response.onDisplay,
        exposureAreas: response.exposureAreas,
        themeModeType: response.themeModeType,
        title1: response.title1,
        title2: response.title2,
        showDomesticUser: response.showDomesticUser,
        showForeignUser: response.showForeignUser
      })
    })
  }, [editingBannerId])

  const validateForm = useCallback(() => {
    if (isNil(form.priority)) {
      toast.error("우선순위를 등록해주세요.")
      return false
    }

    if (isEmpty(form.bannerImageUrl)) {
      toast.error("배너 이미지를 등록해주세요")
      return false
    }

    if (isEmpty(form.bannerLinkUrl)) {
      toast.error("링크 URL를 등록해주세요.")
      return false
    }

    if (isNil(form.startedAt)) {
      toast.error("게시 시작일을 등록해주세요.")
      return false
    }

    return true
  }, [form])

  const uploadImage = useCallback(async (image: FileList) => {
    const result = await upload(image)
    setForm((prev) => ({ ...prev, bannerImageUrl: result.url }))
  }, [])

  const createMyPageBanner = useCallback(async () => {
    await postMyPageBanner(form)
  }, [form])

  const editMyPageBanner = useCallback(async () => {
    if (isNil(editingBannerId)) return

    await putMyPageBanner(editingBannerId, form)
  }, [form, editingBannerId])

  return { form, setForm, editMyPageBanner, createMyPageBanner, uploadImage, validateForm }
}
