import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import { Pagination } from "@mui/material"
import PointListTableHeader from "pages/Point/PointListPage/PointListTable/TableHeader"
import PointListTableRow from "pages/Point/PointListPage/PointListTable/TableBody/TableRow"
import { PointListItemType } from "types/PointListItemType"
import { PaginationParamsType } from "types/PageType"

type Props = {
  pointList: PointListItemType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
}

const PointListTable = ({ pointList, paginationParams, changePage }: Props) => {
  return (
    <ListTable>
      <PointListTableHeader />
      <TableBody>
        {pointList.map((point) => (
          <PointListTableRow key={point.id} point={point} />
        ))}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default PointListTable
