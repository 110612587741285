import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import {HTMLAttributes, PropsWithChildren} from "react"
import {headerItemList} from "pages/UserCollecion/UserCollectionListPage/UserCollectionListTable/TableHeader"
import {UserCollectionType} from "../../../../../../types/UserCollectionType";
import {toDateTimeStr} from "../../../../../../utils/datetimeUtil";

type Props = {
  userCollection: UserCollectionType
} & HTMLAttributes<HTMLDivElement>

const UserCollectionListTableRow = ({ userCollection, ...props }: PropsWithChildren<Props>) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: headerItemList[0].width }}>{userCollection.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>{userCollection.userId}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>{userCollection.status}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[3].width }}>{userCollection.exposureLevel}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[4].width }}>{converEmtpyValueToDash(userCollection.repurchaseGuaranteeUntil)}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[5].width }}>{toDateTimeStr(userCollection.createdAt)}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[6].width }}>{toDateTimeStr(userCollection.updatedAt)}</TableRowItem>
    </TableRow>
  )
}

export default UserCollectionListTableRow
