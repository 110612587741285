import CardTitle from "../../components/CardTitle"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React from "react"
import { DateTime } from "luxon"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { deleteBrand, hideBrand, showBrand } from "../../apis/brandAPI"
import { BrandType } from "types/BrandType"
import { Switch } from "@mui/material"
import { isNil } from "utils/validationUtils"

function createColumns(
  handleDeleteButtonClick: any,
  handleEditButtonClick: any,
  handleDisplaySwitchToggle: Function
): any[] {
  return [
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label={"수정"}
          onClick={() => handleEditButtonClick?.(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={"삭제"}
          onClick={() => handleDeleteButtonClick?.(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: "onDisplay",
      headerName: "노출상태",
      editable: false,
      width: 70,
      renderCell: (params: any) => {
        return (
          <Switch
            checked={params.row.onDisplay}
            onChange={(_event, checked) => handleDisplaySwitchToggle(params.row, checked)}
          />
        )
      },
    },
    { field: "sid", headerName: "ID", editable: false, minWidth: 100, flex: 0.3 },
    { field: "name", headerName: "브랜드명", editable: false, minWidth: 100, flex: 1 },
    {
      field: "createdBy",
      headerName: "생성자",
      editable: false,
      width: 100,

      valueGetter: (params: any) => {
        return isNil(params.value) ? "-" : `${params.value.name}`
      },
    },
    {
      field: "createdAt",
      headerName: "생성일",
      editable: false,
      width: 100,
      valueFormatter: (params: any) => {
        return isNil(params.value) ? "-" : `${DateTime.fromISO(params.value).toISODate()}`
      },
    },
    {
      field: "updatedBy",
      headerName: "수정자",
      editable: false,
      width: 100,
      valueGetter: (params: any) => {
        return isNil(params.value) ? "-" : `${params.value.name}`
      },
    },
    {
      field: "updatedAt",
      headerName: "수정일",
      editable: false,
      width: 100,
      valueFormatter: (params: any) => {
        return isNil(params.value) ? "-" : `${DateTime.fromISO(params.value).toISODate()}`
      },
    },
  ]
}

type BrandsProps = {
  rows: BrandType[]
  dispatch: any
}

const BrandTable: React.FC<BrandsProps> = ({ rows, dispatch }) => {
  const handleDeleteButtonClick = (row: BrandType) => {
    deleteBrand(row.sid as string).then(() => dispatch({ type: "REFRESH" }))
  }
  const handleEditButtonClick = (brand: BrandType) => {
    dispatch({ type: "SHOW_EDIT_BRAND_POPUP", payload: brand })
  }

  const handleDisplaySwitchToggle = (brand: BrandType, checked: boolean) => {
    ;(checked ? showBrand(brand.sid as string) : hideBrand(brand.sid as string)).then(() => {
      dispatch({ type: "REFRESH" })
    })
  }

  return (
    <>
      <CardTitle>브랜드 목록</CardTitle>
      <div style={{ height: 800, width: "100%" }}>
        <DataGrid<BrandType>
          getRowId={(row) => row.sid!}
          columns={createColumns(handleDeleteButtonClick, handleEditButtonClick, handleDisplaySwitchToggle)}
          rows={rows}
          pageSize={50}
          checkboxSelection
          disableSelectionOnClick={true}
          onSelectionModelChange={(rows) => dispatch({ type: "SELECT_BRAND_IDS", payload: rows as string[] })}
        />
      </div>
    </>
  )
}

export default BrandTable
