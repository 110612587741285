import { Button, Stack } from "@mui/material"

type Props = {
  handleCreateCuration: () => void
  handleReorderCuration: () => void
  handleDeleteButton: () => void
  disabledDeleteButton: boolean
}

const CurationToolbar = ({
  handleCreateCuration,
  handleReorderCuration,
  handleDeleteButton,
  disabledDeleteButton,
}: Props) => {
  return (
    <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
      {/*<Button variant={"contained"} size={"small"} onClick={handleReorderCuration}>*/}
      {/*  순서 변경*/}
      {/*</Button>*/}
      <Button variant={"contained"} size={"small"} onClick={handleCreateCuration}>
        추가
      </Button>
      <Button
        variant={"contained"}
        size={"small"}
        onClick={handleDeleteButton}
        color={"error"}
        disabled={disabledDeleteButton}
      >
        삭제
      </Button>
    </Stack>
  )
}

export default CurationToolbar
