import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FaqCategoryType } from "types/FaqType"

type Props = {
  categoryList: FaqCategoryType[]
  selectedCategory: FaqCategoryType
  selectCategory: (category: FaqCategoryType) => void
}

export const FaqCategoryBar = ({ categoryList, selectedCategory, selectCategory }: Props) => {
  return (
    <Wrapper>
      {categoryList.map((category, index) => (
        <CategoryItem
          key={`${category}_${index}`}
          isSelected={selectedCategory === category}
          onClick={() => selectCategory(category)}
        >
          {category}
        </CategoryItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const CategoryItem = styled.div<{ isSelected: boolean }>`
  border-radius: 21px;
  padding: 6px 12px;
  margin-right: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background: #1c2229;
        color: #fff;
        border: 1px solid #1c2229;
      `
    } else {
      return css`
        background: #fff;
        color: #666f78;
        border: 1px solid #ced2d6;
      `
    }
  }}
`
