import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { toDateTimeStr } from "utils/datetimeUtil"
import { CouponCodeUsageHistoryType } from "types/CouponCodeUsageHistoryType"
import { couponCodeUsageHistoryTableHeaderItemList } from "../../TableHeader";
import { Link } from "@mui/material";

type Props = {
  couponCodeUsageHistory: CouponCodeUsageHistoryType
} & HTMLAttributes<HTMLDivElement>

const CouponCodeUsageHistoryListTableRow = ({ couponCodeUsageHistory, ...props }: PropsWithChildren<Props>) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: couponCodeUsageHistoryTableHeaderItemList[0].width }}>{couponCodeUsageHistory.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeUsageHistoryTableHeaderItemList[1].width }}>
        <Link href={`/coupon-codes?tab=list&filter=code&keyword=${couponCodeUsageHistory.couponCode}`} target="_self">
          {couponCodeUsageHistory.couponCode}
        </Link>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeUsageHistoryTableHeaderItemList[2].width }}>
          {`${couponCodeUsageHistory.user.name} (${couponCodeUsageHistory.user.id})`}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: couponCodeUsageHistoryTableHeaderItemList[3].width, justifyContent: "end" }}>
        {couponCodeUsageHistory.createdAt ? toDateTimeStr(couponCodeUsageHistory.createdAt) : "-"}
      </TableRowItem>
    </TableRow>
  )
}

export default CouponCodeUsageHistoryListTableRow
