import { getSerialNumberList } from "apis/serialAPI"
import { isEmpty } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { SerialNumberListItemType } from "types/SerialNumberType"

const FETCH_LIST_SIZE = 500

export const useSerialNumberFinder = (value: string) => {
  const [selectedSerial, setSelectedSerial] = useState<SerialNumberListItemType | null>(null)
  const [serialList, setSerialList] = useState<SerialNumberListItemType[]>([])

  const fetchSerialList = useCallback(async () => {
    const response = await getSerialNumberList({ serialNo: value, size: FETCH_LIST_SIZE })
    const foundSerialNumber = response.content.find((item) => item.serialNo === value)
    setSelectedSerial(foundSerialNumber || null)
    setSerialList(response.content)
  }, [value])

  useEffect(() => {
    if (isEmpty(value)) return

    fetchSerialList()
  }, [fetchSerialList, value])

  return { serialList, selectedSerial, setSelectedSerial, fetchSerialList }
}
