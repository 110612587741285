import React, { useEffect, useReducer } from "react"
import { AdminGroupType } from "../../../types/AdminUserType"
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { findAdminGroup, updateAdminGroup } from "../../../apis/adminGroupAPI"
import { toast } from "react-toastify"

type AdminGroupDetailPagePopupProps = {
  open: boolean
  adminGroupId?: number
  handleClose: () => void
  menus: string[]
}

export declare interface AdminGroupDetailState {
  adminGroup: AdminGroupType
  loading?: boolean
}

const initState: AdminGroupDetailState = {
  adminGroup: {
    id: undefined,
    groupName: undefined,
    menuViews: [],
  },
  loading: false,
}

interface AdminUserDetailAction {
  type: string
  payload?: any
}

function reducer(state: AdminGroupDetailState, action: AdminUserDetailAction) {
  switch (action.type) {
    case "FETCH_ADMIN_GROUP_BY_ID":
      return { ...state, adminGroup: action.payload }
    case "CHANGE_ADMIN_GROUP_MENUS":
      return { ...state, adminGroup: { ...state.adminGroup, menuViews: action.payload } }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const AdminGroupDetailPopup: React.FC<AdminGroupDetailPagePopupProps> = ({
  open,
  adminGroupId,
  handleClose,
  menus,
}) => {
  const [state, dispatch] = useReducer(reducer, initState)

  useEffect(() => {
    if (open && adminGroupId) {
      dispatch({ type: "START_LOADING" })
      findAdminGroup(adminGroupId)
        .then((res) => {
          dispatch({ type: "FETCH_ADMIN_GROUP_BY_ID", payload: res })
        })
        .finally(() => dispatch({ type: "END_LOADING" }))
    }
  }, [open, adminGroupId])

  const handleSave = async () => {
    if (adminGroupId) {
      await updateAdminGroup(adminGroupId, {
        id: state.adminGroup.id,
        menuViews: state.adminGroup.menuViews,
      })
      toast.success("그룹 수정이 완료되었습니다.")
      handleClose()
    }
  }

  if (state.loading) return <CircularProgress />

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography fontWeight={"bold"} fontSize={"30px"}>
            어드민 그룹 정보
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>ID</td>
              <td className={"header"} colSpan={2}>
                그룹명
              </td>
            </tr>
            <tr>
              <td>{state.adminGroup?.id}</td>
              <td colSpan={2}>{state.adminGroup?.groupName}</td>
            </tr>
            <tr>
              <td colSpan={3} className={"header"}>
                접근 가능 메뉴
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Autocomplete
                  fullWidth
                  multiple
                  value={menus.filter((menu) => state.adminGroup.menuViews?.find((it: string) => it === menu))}
                  getOptionLabel={(option) => option}
                  options={menus}
                  onChange={(_, value) => {
                    dispatch({ type: "CHANGE_ADMIN_GROUP_MENUS", payload: value.map((menu) => menu) })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                      }}
                    />
                  )}
                ></Autocomplete>
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={handleSave}>
          수정
        </Button>
        <Button color="error" onClick={handleClose}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AdminGroupDetailPopup
