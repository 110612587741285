import { PointReasonType } from "./PointType"

export type PointScheduleListItemType = {
  id: number
  userIds: number[]
  userSourceType: PointScheduleUserSourceType
  userSource?: string
  status: PointScheduleStatusCode
  reasonType: PointReasonType
  reasonMessage: string
  points: number
  availableDate: string
  scheduleAt: string
  createdAt: string
  updatedAt: string
  pushMessage?: string
}

export type PointScheduleUserSourceType = "CSV" | "ID" | "EXTERNAL_REPORT"

export enum PointScheduleStatusCode {
  대기 = "대기",
  취소 = "취소",
  실패 = "실패",
  완료 = "완료",
}

export type PointScheduleListSearchParams = {
  size?: number
  page?: number
  status?: PointScheduleStatusCode
  reasonType?: PointReasonType
  reasonMessage?: string
}

export type PostPointSchdeduleBodyType = {
  userIds?: number[]
  userSource?: string
  userSourceType: PointScheduleUserSourceType
  reasonType: PointReasonType
  reasonMessage: string
  points: number
  availableDate: string
  scheduleAt: string
}

export type PutPointScheduleBodyType = {
  userIds?: number[]
  userSource?: string
  userSourceType?: PointScheduleUserSourceType
  reasonType?: PointReasonType
  reasonMessage?: string
  points?: number
  availableDate?: string
  scheduleAt?: string
}
