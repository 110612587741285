import { DataGrid, GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid"
import React, { useState } from "react"
import { ResellItemType } from "types/ResellItemType"
import { Alert, Box, lighten, Snackbar, Switch } from "@mui/material"
import _ from "lodash"

type ResellItemTableProps = {
  rows?: ResellItemType[]
  handleRowsUpdate: Function
  handleRowSelection: VoidFunction
}

function renderConfirmCell(params: any) {
  const { value } = params
  return <Switch checked={value === "확인"} readOnly={true} />
}

const RenderEditConfirmCell = (params: GridRenderEditCellParams) => {
  const { id, value, field } = params
  const apiRef = useGridApiContext()
  return (
    <Switch
      checked={value === "확인"}
      onChange={(e) => {
        if (e.target.checked) {
          apiRef.current.setEditCellValue({ id, field, value: "확인" })
        } else {
          apiRef.current.setEditCellValue({ id, field, value: "미확인" })
        }
      }}
    />
  )
}

const ResellItemTable: React.FC<ResellItemTableProps> = ({ rows, handleRowsUpdate, handleRowSelection }) => {
  const [snackbar, setSnackbar] = useState<{ children: "수정 완료"; severity: "success" } | null>(null)

  function getRowColor(color: any) {
    return lighten(color, 0.6)
  }

  function getHoverRowColor(color: any) {
    return lighten(color, 0.5)
  }

  const processRowUpdate = (newRow: any, oldRow: any) => {
    if (!_.isEqual(newRow, oldRow)) {
      handleRowsUpdate(newRow)
      setSnackbar({ children: "수정 완료", severity: "success" })
    }
    return newRow
  }

  const handleProcessRowUpdateError = React.useCallback((error: any) => {
    console.log(error)
  }, [])

  function handleCloseSnackbar() {
    setSnackbar(null)
  }

  function createColumns() {
    return [
      {
        field: "confirmStatus",
        headerName: "확인",
        width: 90,
        renderCell: renderConfirmCell,
        renderEditCell: RenderEditConfirmCell,
        editable: true,
      },
      { field: "year", headerName: "스템핑", editable: true, width: 60 },
      {
        field: "itemStatus",
        headerName: "상태",
        editable: true,
        width: 60,
        type: "singleSelect",
        valueOptions: ["NEW", "USED"],
      },
      { field: "officialGuarantee", headerName: "보증서", type: "boolean", editable: true, width: 60 },
      { field: "lastPrice", headerName: "가격", type: "number", editable: true, flex: 1 },
    ]
  }

  return (
    <>
      <Box
        sx={{
          height: "690px",
          "& .resell-item-row-checked": {
            bgcolor: (theme) => getRowColor(theme.palette.success.main),
            "&:hover": {
              bgcolor: (theme) => getHoverRowColor(theme.palette.success.main),
            },
          },
          "& .resell-item-row-unchecked": {
            bgcolor: (theme) => lighten(theme.palette.warning.main, 0.6),
            "&:hover": {
              bgcolor: (theme) => getHoverRowColor(theme.palette.warning.main),
            },
          },
        }}
      >
        <DataGrid
          columns={createColumns()}
          pagination
          paginationMode={"server"}
          sortingMode={"server"}
          onSelectionModelChange={handleRowSelection}
          rowHeight={40}
          rows={rows ? rows : []}
          hideFooter={true}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          getRowClassName={(params) => {
            return params?.row?.confirmStatus === "미확인" ? "resell-item-row-unchecked" : "resell-item-row-checked"
          }}
        />
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  )
}

export default ResellItemTable
