import { httpGet, httpPost, httpPut } from "utils/httpAPI"
import { PageType } from "types/PageType"
import {
  CouponCodeListSearchParams,
  CouponCodeType,
  CouponCodeBodyType
} from "types/CouponCodeType"
import {CouponCodeUsageHistorySearchParams, CouponCodeUsageHistoryType} from "../types/CouponCodeUsageHistoryType";
import {CouponPointUsageHistorySearchParams, CouponPointUsageHistoryType} from "../types/CouponPointUsageHistoryType";

export const getCouponCodeList: (
    listParams: CouponCodeListSearchParams
) => Promise<PageType<CouponCodeType>> = (listParams: CouponCodeListSearchParams) =>
    httpGet(`/coupon-codes`, listParams)

export const postCouponCode: (body: CouponCodeBodyType) => Promise<CouponCodeType> = (
  body: CouponCodeBodyType
) => httpPost("/coupon-codes", body)

export const putCouponCode: (body: CouponCodeBodyType, id: string) => Promise<CouponCodeType> = (
  body: CouponCodeBodyType,
  id: string
) => httpPut(`/coupon-codes/${id}`, body)

export const getCouponCodeUsageHistoryList: (listParams: CouponCodeUsageHistorySearchParams) => Promise<PageType<CouponCodeUsageHistoryType>> = (
  listParams: CouponCodeUsageHistorySearchParams
) => httpGet(`/coupon-codes/usages`, listParams)

export const getCouponPointUsageHistoryList: (listParams: CouponPointUsageHistorySearchParams) => Promise<PageType<CouponPointUsageHistoryType>> = (
    listParams: CouponPointUsageHistorySearchParams
) => httpGet(`/coupon-codes/point-usages`, listParams)