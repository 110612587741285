import {httpGet, httpGetToBlob} from "../utils/httpAPI";
import {DateTime} from "luxon";


export const downloadFinanceReport = (params: ReportRangeSearchParam): Promise<Blob> => httpGetToBlob("/reports/finance", params)

export const downloadPointReport = (params: ReportTargetMonthParam): Promise<Blob> => httpGetToBlob("/reports/point", params)

export interface ReportRangeSearchParam {
    startDate: String,
    endDate: String
}

export interface ReportTargetMonthParam {
    targetMonth: String,
}