import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import { toDateTimeStr } from "../../utils/datetimeUtil"
import { Button } from "@mui/material"

//@ts-ignore
const ProductRequestTable = ({ rows, onPageChange, onPageSizeChange, openProductRequestImagePopup, page, size, totalCount }) => {
  const columns = [
    {
      field: "id",
      headerName: "신청번호",
      editable: false,
      sortable: false,
      width: 130,
      valueGetter: (params: any) => `${params.row.id}`,
    },
    {
      field: "sellerId",
      headerName: "회원ID",
      editable: false,
      sortable: false,
      width: 80,
      valueGetter: (params: any) => `${params.row.userId}`,
    },
    {
      field: "brandSid",
      headerName: "브랜드",
      editable: false,
      sortable: false,
      type: "string",
      width: 110,
      valueGetter: (params: any) => `${params.row.brand.sid}`,
    },
    {
      field: "modelName",
      headerName: "모델명",
      editable: false,
      sortable: false,
      type: "string",
      width: 250,
      renderCell: (params: any) => {
        return <div style={{ whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.detailModel.title}</div>
      },
    },
    {
      field: "fullRefNo",
      headerName: "Full Ref No.",
      editable: false,
      sortable: false,
      type: "string",
      width: 150,
      renderCell: (params: any) => {
        return <div style={{ whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.fullRefNo}</div>
      },
    },
    {
      field: "productCondition",
      headerName: "제품상태",
      editable: false,
      sortable: false,
      align: "right" as const,
      minWidth: 80,
      valueGetter: (params: any) => `${params.row.productCondition}`,
    },
    {
      field: "officialGuarantee",
      headerName: "보증서",
      editable: false,
      sortable: false,
      type: "string",
      width: 80,
      valueGetter: (params: any) => `${params.row.officialGuarantee}`,
    },
    {
      field: "originalBoxCondition",
      headerName: "정품박스",
      editable: false,
      sortable: false,
      type: "string",
      width: 80,
      valueGetter: (params: any) => `${params.row.originalBoxCondition}`,
    },
    {
      field: "accessoriesCondition",
      headerName: "부속품",
      editable: false,
      sortable: false,
      type: "string",
      width: 100,
      valueGetter: (params: any) => `${params.row.accessoriesCondition}`,
    },
    {
      field: "stampingYear",
      headerName: "스탬핑",
      editable: false,
      sortable: false,
      type: "string",
      width: 80,
      valueGetter: (params: any) => `${params.row.stampingYear ?? "-"}`,
    },
    {
      field: "gallery",
      headerName: "상품이미지",
      editable: false,
      sortable: false,
      type: "string",
      width: 110,
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {openProductRequestImagePopup(params.row.id)}}
          >
            보기
          </Button>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "신청일자",
      editable: false,
      sortable: false,
      type: "string",
      width: 180,
      valueGetter: (params: any) => `${toDateTimeStr(params.row.createdAt)}`,
    },
  ]

  return (
    <div style={{ height: 1200, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
        pagination
        page={page}
        pageSize={size}
        rowCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationMode={"server"}
        disableExtendRowFullWidth={true}
        disableColumnMenu={true}
        getRowHeight={(params) => undefined}
      />

    </div>
  )
}

export default ProductRequestTable