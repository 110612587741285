import { toast } from "react-toastify"
import {
  PointScheduleListItemType,
  PointScheduleStatusCode,
  PostPointSchdeduleBodyType,
} from "types/PointScheduleListItemType"
import { EditablePointScheduleListItemType } from "./useEditPointScheduleData"

export const mapPointScheduleListItemTypeToEditablePointScheduleListItemType = (
  pointSchduleItem: PointScheduleListItemType
): EditablePointScheduleListItemType => {
  return {
    id: pointSchduleItem.id,
    userIds: pointSchduleItem.userIds || [],
    userSourceType: pointSchduleItem.userSourceType,
    userSource: pointSchduleItem.userSourceType || "",
    status: pointSchduleItem.status,
    reasonType: pointSchduleItem.reasonType,
    reasonMessage: pointSchduleItem.reasonMessage,
    points: pointSchduleItem.points,
    availableDate: pointSchduleItem.availableDate,
    scheduleAt: pointSchduleItem.scheduleAt,
    pushMessage: pointSchduleItem.pushMessage || "",
  }
}

export const initialPointScheduleState: EditablePointScheduleListItemType = {
  id: 0,
  userIds: [],
  userSourceType: "ID",
  userSource: "",
  status: PointScheduleStatusCode.대기,
  reasonType: "수동 적립",
  reasonMessage: "",
  points: 0,
  availableDate: "",
  scheduleAt: "",
  pushMessage: "",
}

export const parsePointScheduleBody = (form: EditablePointScheduleListItemType): PostPointSchdeduleBodyType => {
  return {
    points: form.points,
    userIds: form.userIds,
    userSourceType: "ID", //모든 지급 방식 ID로 통일
    reasonType: form.reasonType,
    reasonMessage: form.reasonMessage,
    availableDate: form.availableDate,
    scheduleAt: form.scheduleAt,
  }
}

export const validatePintScheduleForm = (form: EditablePointScheduleListItemType) => {
  const omittableKey = ["id", "userSource", "pushMessage", "userIds"]
  let isValid = true

  for (const [key, value] of Object.entries(form)) {
    if (key === "userSourceType") {
      if (value === "ID" && !form.userIds.length) {
        toast.error("포인트를 지급할 유저를 선택해주세요.")
        isValid = false
      }

      if (value !== "ID" && !form.userIds.length) {
        toast.error("파일을 업로드 해주세요.")
        isValid = false
      }
    }

    if (!value && !omittableKey.includes(key)) {
      toast.error(mapRequiredFormKeyToErrorMessage(key))
      isValid = false
    }
  }

  return isValid
}

const mapRequiredFormKeyToErrorMessage = (key: string) => {
  switch (key) {
    case "reasonType":
      return "지급 이유를 선택해주세요."
    case "reasonMessage":
      return "지급 메세지를 입력해주세요."
    case "points":
      return "지급할 포인트를 입력해주세요."
    case "scheduleAt":
      return "포인트 지급 예정일을 선택해주세요."
    case "availableDate":
      return "포인트 만료일을 설정해주세요."
  }
}
