import React, { useCallback, useEffect, useReducer } from "react"
import {
  DiagnosisIssueListSearchParam,
  DiagnosisIssueListType,
  DiagnosisStatusCode,
} from "../../../types/DiagnosisType"
import { PageType } from "../../../types/PageType"
import ViewBuyOrderPopup from "../../BuyOrder/ViewBuyOrderPopup"
import { Grid, Paper } from "@mui/material"
import Counters from "../../SellOrder/Counters"
import { SaleTypeCode } from "../../../types/ProductType"
import BeforeDiagnosisProductPopup from "../../Product/BeforeDiagnosisProductPopup"
import EditPreProductPopup from "../../PreProduct/EditPreProductPopup"
import IssueListTable from "./IssueListTable"
import { getDiagnosisIssueList } from "../../../apis/diagnosisAPI"
import EditRePurchaseGuaranteedDiagnosisPopup from "../RePurchaseGuaranteed/EditRePurchaseGuaranteedDiagnosisPopup"
import EditBeforeDiagnosisDiagnosisPopup from "../BeforeDiagnosis/EditBeforeDiagnosisDiagnosisPopup"
import EditConsignmentDiagnosisPopup from "../Consignment/EditConsignmentDiagnosisPopup"
import { SellOrderType } from "../../../types/SellOrderType"
import { CountType } from "../../../types/CountType"
import { useSearchParams } from "react-router-dom"
import { DiagnosisPrintPopup } from "../DiagnosisPrintPopup"
import styled from "@emotion/styled"

export interface DiagnosisIssueListState {
  diagnoses: PageType<DiagnosisIssueListType>
  selectedId: number[]
  selectedDiagnosis?: DiagnosisIssueListType
  selectedSellOrder?: SellOrderType // 진단 팝업이 SellOrder 자체를 요구해서 하나 추가함.
  diagnosisCount: CountType[]
  isShowProductPopup: boolean
  isShowBuyOrderPopup: boolean
  isShowDiagnosisPopup: boolean
  isShowDiagnosisPrintPopup: boolean
  diagnosisStatus?: DiagnosisStatusCode
  refresh: boolean
  loading: boolean
  pageSize: number
  pageNumber: number
}

const initState: DiagnosisIssueListState = {
  diagnoses: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  selectedId: [],
  selectedDiagnosis: undefined,
  selectedSellOrder: undefined,
  diagnosisCount: [],
  diagnosisStatus: undefined,
  isShowProductPopup: false,
  isShowBuyOrderPopup: false,
  isShowDiagnosisPopup: false,
  isShowDiagnosisPrintPopup: false,
  refresh: false,
  loading: false,
  pageSize: 25,
  pageNumber: 0,
}

interface DiagnosisIssueListAction {
  type: string
  payload?: any
}

function reducer(state: DiagnosisIssueListState, action: DiagnosisIssueListAction): DiagnosisIssueListState {
  switch (action.type) {
    case "FETCH_ISSUE_LIST":
      return { ...state, diagnoses: action.payload }
    case "FETCH_ISSUE_LIST_COUNT":
      return { ...state, diagnosisCount: action.payload }
    case "FETCH_SELL_ORDER":
      return { ...state, selectedSellOrder: action.payload }
    case "TOGGLE_PRODUCT_POPUP":
      return { ...state, isShowProductPopup: !state.isShowProductPopup, selectedDiagnosis: action.payload }
    case "TOGGLE_BUY_ORDER_POPUP":
      return { ...state, isShowBuyOrderPopup: !state.isShowBuyOrderPopup, selectedDiagnosis: action.payload }
    case "TOGGLE_DIAGNOSIS_POPUP":
      return { ...state, isShowDiagnosisPopup: !state.isShowDiagnosisPopup, selectedDiagnosis: action.payload }
    case "TOGGLE_DIAGNOSIS_PRINT_POPUP":
      return {
        ...state,
        isShowDiagnosisPrintPopup: !state.isShowDiagnosisPrintPopup,
        selectedDiagnosis: action.payload,
      }
    case "CHANGE_PAGE_NUMBER":
      return {
        ...state,
        pageNumber: action.payload,
        refresh: !state.refresh,
      }
    case "CHANGE_PAGE_SIZE":
      return { ...state, pageNumber: 0, pageSize: action.payload, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }
  return state
}

const DiagnosesIssueList: React.FC = () => {
  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(reducer, initState)

  const renderEditPopupByOrderType = useCallback(() => {
    if (!state.selectedDiagnosis) {
      return
    }

    if (state.selectedDiagnosis.saleType === SaleTypeCode.매입보증판매) {
      return (
        <EditRePurchaseGuaranteedDiagnosisPopup
          open={state.isShowDiagnosisPopup}
          selectedSellOrder={state.selectedSellOrder!!}
          handleClose={() => {
            dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )
    }

    if (state.selectedDiagnosis.saleType === SaleTypeCode.진단전판매) {
      return (
        <EditBeforeDiagnosisDiagnosisPopup
          open={state.isShowDiagnosisPopup}
          selectedSellOrder={state.selectedSellOrder}
          handleClose={() => {
            dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )
    }

    return (
      <EditConsignmentDiagnosisPopup
        open={state.isShowDiagnosisPopup}
        selectedSellOrder={state.selectedSellOrder}
        handleClose={() => {
          dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
    )
  }, [state.selectedSellOrder, state.isShowDiagnosisPopup])

  const fetchDiagnosisIssueList = (searchParams: DiagnosisIssueListSearchParam = {}) => {
    let newParams: DiagnosisIssueListSearchParam = {
      ...searchParams,
      status: (currentSearchParams.get("status")?.split(",") as DiagnosisStatusCode[]) ?? undefined,
    }

    dispatch({ type: "START_LOADING" })
    getDiagnosisIssueList(newParams)
      .then((results) => dispatch({ type: "FETCH_ISSUE_LIST", payload: results }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  useEffect(() => {
    fetchDiagnosisIssueList({
      page: state.pageNumber || 0,
      size: state.pageSize || 25,
    })
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>
      {renderEditPopupByOrderType()}
      <DiagnosisPrintPopup
        isOpen={state.isShowDiagnosisPrintPopup}
        closePopup={() => {
          dispatch({ type: "TOGGLE_DIAGNOSIS_PRINT_POPUP" })
        }}
        productId={state.selectedDiagnosis?.productId!!}
      />
      <ViewBuyOrderPopup
        open={state.isShowBuyOrderPopup}
        selectedBuyOrderId={state.selectedDiagnosis?.buyOrderId}
        handleClose={() => {
          dispatch({ type: "TOGGLE_BUY_ORDER_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
      {state.selectedDiagnosis?.saleType == SaleTypeCode.진단전판매 ? (
        <BeforeDiagnosisProductPopup
          open={state.isShowProductPopup}
          selectedProductId={state.selectedDiagnosis?.productId}
          handleClose={() => {
            dispatch({ type: "TOGGLE_PRODUCT_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      ) : (
        <EditPreProductPopup
          open={state.isShowProductPopup}
          selectedProductId={state.selectedDiagnosis?.productId}
          handleClose={() => {
            dispatch({ type: "TOGGLE_PRODUCT_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      )}
      <Grid item xs={12}>
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column" }}>
          <TotalCount>
            전체<b style={{ marginLeft: 10, color: "#1976d2" }}>{state.diagnoses.totalElements || "0"}</b>건
          </TotalCount>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <IssueListTable state={state} dispatch={dispatch} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DiagnosesIssueList

const TotalCount = styled.div`
  font-weight: 800;
`
