import React from "react"
import { OrderType, SellOrderProductType, SellOrderType } from "types/SellOrderType"
import ProductQR from "components/ProductQR"
import { toDateTimeStr } from "utils/datetimeUtil"
import styled from "@emotion/styled"

type QRTableToPrintProps = {
  sellOrder: SellOrderType
}

const ECUQRTableToPrint: React.FC<QRTableToPrintProps> = ({ sellOrder }) => {
  return (
    <StyledTableWrapper>
      <StyledTable className={"basic qr"}>
        <colgroup>
          <col width="13%" />
          <col width="*" />
        </colgroup>
        <tbody>
        <tr>
          <td colSpan={6}>{`#${sellOrder.product?.id} _ ${sellOrder.product?.saleType} _ ECU 부착용`}</td>
          <td colSpan={2} rowSpan={4} align={"center"}>
            <ProductQR size={80} id={sellOrder.product?.id} />
          </td>
        </tr>
        <tr>
          <td>상품명</td>
          <td style={{ textAlign: "left", paddingLeft: "10px" }} colSpan={5}>
            {sellOrder.product?.title}
            <br />
            {sellOrder.product?.titleSecondary}
            <b>({sellOrder.product?.exposedProductInfo?.productCondition})</b>{" "}
            <span
              style={{
                color: sellOrder?.buyOrder?.advancedDiagnosis ? "red" : "inherit",
                textDecoration: sellOrder?.buyOrder?.advancedDiagnosis ? "underline" : "none",
                fontWeight: sellOrder?.buyOrder?.advancedDiagnosis ? "bold" : "inherit",
              }}
            >
                {sellOrder?.buyOrder && sellOrder?.sellOrderType === OrderType.진단전 && (sellOrder.buyOrder.advancedDiagnosis ? "정밀" : "일반")}
              </span>
          </td>
        </tr>
        <tr>
          <td>상품번호</td>
          <td colSpan={2}>{sellOrder.product?.id}</td>
          <td>판매주문ID</td>
          <td colSpan={2}>{sellOrder?.id}</td>
        </tr>
        <tr>
          <td>입고확인</td>
          <td colSpan={2}>{toDateTimeStr(sellOrder?.waitingDiagnosisAt)}</td>
          <td>판매자</td>
          <td colSpan={2}>
            {sellOrder?.seller?.name} {sellOrder?.seller?.phone}
          </td>
        </tr>
        </tbody>
      </StyledTable>
    </StyledTableWrapper>
  )
}

export default ECUQRTableToPrint

const StyledTable = styled.table`
  font-size: 14px;
  width: 100%;
  @media print {
    aspect-ratio: 10 / 2.6;
    position: relative;
    left: auto;
    top: auto;
  }
	tr > td{
		border-width: 1px;
	}
	tr{
		@media print {
			height: 20px !important;
		}
	}
`
const StyledTableWrapper = styled.div`
  @media print {
    padding: 2mm 4mm 0;
    height: 28mm;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
  }
`
