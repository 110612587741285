import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const ListTable = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <Wrapper>
      <ChildrenWrapper {...props}>{children}</ChildrenWrapper>
    </Wrapper>
  )
}

export default ListTable

const Wrapper = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;

  padding: 4px 0;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(224, 224, 224, 1);

  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  overflow-x: scroll;
`

const ChildrenWrapper = styled.div`
  position: relative;
  min-width: 1024px;
  flex-shrink: 0;
`
