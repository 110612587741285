import { FunctionComponent } from "react"
import { StyledAbsoluteBox, StyledBox, StyledTxt } from "./styled"
import { SelectOptions } from "./SelectOptions"
import { translateAppraisalResultKo } from "./translate"
import { VisibleAppraisalResultType} from "./common"
import { useDiagnosisPrintMargin } from "./DiagnosisPrintMargin"

export const DiagnosisPrintReportDetail:FunctionComponent<{
  appraisalResult: VisibleAppraisalResultType
  handleChange:(value:string)=>void
  selectItems: VisibleAppraisalResultType[]
  editable:boolean
  resultLeft:number,
  resultKoLeft:number,
  resultFontSize: number,
}> = ({
  appraisalResult,
  selectItems,
  handleChange, editable,
  resultLeft,
  resultKoLeft,
  resultFontSize,
}) => {

  return (
    <StyledBox $flexDirection="column" $gap={22} $paddingLeft={5}>
      <StyledBox $display={"inline-flex"}>
        <StyledTxt
          as="div"
          $fontSize={7}
          $lineHeight={13}
          $weight={600}
          $color="#231815"
          $display="block"
        >
          <span className="is-design">VIVER Verify Authentication service has completed the authentication of this product.<br />
          The authentication report confirms that this product is [</span>
          <StyledBox
            $display={"inline-flex"}
            $width={68}
            $justifyContent="center"
            $marginLeftPrint={resultLeft}
            style={{ position: "relative" }}
          >
            <StyledTxt
              $color="rgba(139, 104, 51, 0.80)"
              $fontSize={resultFontSize}
              $weight={700}
              style={{
                textTransform: "uppercase",
              }}
            >
              {appraisalResult}
            </StyledTxt>
            {
              editable ? (
                <StyledAbsoluteBox
                  $width={"100%"}
                  className="not-printing"
                >
                  <SelectOptions
                    items={selectItems}
                    selectedIndex={
                      selectItems.findIndex(item => item === appraisalResult)
                    }
                    handleChange={(event) => {
                      handleChange(event.target.value)
                    }}
                  />
                </StyledAbsoluteBox>
              ) : null
            }

          </StyledBox>
          <span className="is-design">
          ].<br />
          You may refer to the next page for comprehensive results.</span>
        </StyledTxt>
      </StyledBox>
      <StyledBox $display={"inline-flex"}>
        <StyledTxt as="div" $fontSize={6} $lineHeight={13} $weight={600} $color="#231815" $display="block">
          <span className="is-design">해당 상품은 VIVER Verify 감정진단 서비스를 통해 진단이 완료되었습니다.<br />
          감정결과 [</span>
          <StyledBox
            $display={"inline-flex"}
            $width={52}
            $justifyContent="center"
            $marginLeftPrint={resultKoLeft}
          >
            <StyledTxt $color="rgba(139, 104, 51, 0.80)" $fontSize={resultFontSize} $weight={700}>{
              translateAppraisalResultKo(appraisalResult)
            }</StyledTxt>
          </StyledBox>
          <span className="is-design">]으로 확인되었으며, 그 외 성능 등의 진단 결과는 다음 페이지를<br />
          참고하시기 바랍니다.</span>
        </StyledTxt>
      </StyledBox>
    </StyledBox>
  )
}