import React, { Dispatch, SetStateAction } from "react"
import { Button, Grid } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FeePolicyState } from "./FeePolicyManager"
import { FeePolicyDataType } from "../../../types/FeePolicyType"
import { numberWithCommas } from "../../../utils/NumberUtils"
import { toDateTimeStr } from "../../../utils/datetimeUtil"

type FeePolicyTableProps = {
  state: FeePolicyState
  dispatch: Dispatch<SetStateAction<FeePolicyState>>
  fetch: (params: { page: number; size: number }) => void
}

function createColumns(): GridColDef[] {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "name",
      headerName: "수수료명",
      width: 150,
    },
    {
      field: "type",
      headerName: "수수료타입",
      width: 150,
    },
    {
      field: "saleType",
      headerName: "판매타입",
      width: 120,
    },
    {
      field: "targetType",
      headerName: "적용구분",
      width: 100,
    },
    {
      field: "targetId",
      headerName: "적용값",
      width: 100,
    },
    {
      field: "feePolicyStatus",
      headerName: "상태",
      width: 100,
      align: "center",
      renderCell: (params) => {
        return renderStatusCell(params.value)
      },
    },
    {
      field: "onDisplay",
      headerName: "적용여부",
      width: 100,
      align: "center",
      valueGetter: (params) => {
        return params.value == true ? "예" : "아니오"
      },
    },
    {
      field: "priority",
      headerName: "우선순위",
      width: 100,
    },
    {
      field: "calculationType",
      headerName: "계산타입",
      width: 100,
    },

    {
      field: "rate",
      headerName: "수수료율",
      width: 100,
      valueGetter: (params) => {
        return params.value >= 0 ? `${params.value}%` : ""
      },
    },

    {
      field: "amount",
      headerName: "수수료",
      width: 100,
      valueGetter: (params) => {
        return params.value >= 0 ? `${numberWithCommas(params.value)}원` : ""
      },
    },
    {
      field: "maxAmount",
      headerName: "최대수수료",
      width: 150,
      valueGetter: (params) => {
        return params.value >= 0 ? `${numberWithCommas(params.value)}원` : ""
      },
    },
    {
      field: "minAmount",
      headerName: "최소수수료",
      width: 150,
      valueGetter: (params) => {
        return params.value >= 0 ? `${numberWithCommas(params.value)}원` : ""
      },
    },
    /* {
      field: "isEvent",
      headerName: "이벤트여부",
      width: 100,
      align: "center",
      valueGetter: (params) => {
        return params.value == true ? "예" : "아니오"
      },
    },
    {
      field: "discountReasonType",
      headerName: "할인 사유",
      width: 100,
      flex: 1,
      align: "center",
    },
    {
      field: "discountRate",
      headerName: "할인율",
      width: 100,
      valueGetter: (params) => {
        return params.value >= 0 ? `${params.value}%` : ""
      },
    },
    {
      field: "discountMaxAmount",
      headerName: "최대할인금액",
      width: 100,
      valueGetter: (params) => {
        return params.value >= 0 ? `${numberWithCommas(params.value)}원` : ""
      },
    },
    {
      field: "discountMinAmount",
      headerName: "최소할인금액",
      width: 100,
      valueGetter: (params) => {
        return params.value >= 0 ? `${numberWithCommas(params.value)}원` : ""
      },
    },*/
    {
      field: "onStartCreatedAt",
      headerName: "판매시작일 포함 여부",
      width: 150,
      align: "center",
      valueGetter: (params) => {
        return params.value == true ? "예" : "아니오"
      },
    },
    {
      field: "startDate",
      headerName: "시작일자",
      width: 100,
    },
    {
      field: "endDate",
      headerName: "종료일자",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "생성일",
      width: 180,
      align: "center",
      valueGetter: (params) => {
        return toDateTimeStr(params.value)
      },
    },
    {
      field: "updatedAt",
      headerName: "변경일",
      width: 180,
      align: "center",
      valueGetter: (params) => {
        return toDateTimeStr(params.value)
      },
    },
  ]
}

function renderStatusCell(params: any) {
  if (params == "적용중") return <Button color={"error"}>{params}</Button>
  if (params == "적용 대기") return <Button disabled>{params}</Button>
  if (params == "적용 종료") return <Button disabled>{params}</Button>
  if (params == "적용 종료") return <Button disabled>{params}</Button>
}

export const FeePolicyTable: React.FC<FeePolicyTableProps> = (props) => {
  return (
    <Grid container spacing={1} sx={{ pt: 1 }}>
      <Grid item xs={12}>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid<FeePolicyDataType>
            checkboxSelection={true}
            disableSelectionOnClick={true}
            onSelectionModelChange={(ids) => {
              props.dispatch((prevState) => ({
                ...prevState,
                selectedIds: ids as number[],
              }))
            }}
            getRowId={(row) => row.id!!}
            columns={createColumns()}
            onRowClick={(params, event, details) => {
              props.dispatch((prevState) => ({
                ...prevState,
                selectedFeePolicy: params.row,
                isOpenModal: true,
              }))
            }}
            pagination
            paginationMode="server"
            onPageChange={(page) => {
              props.dispatch((prevState) => ({
                ...prevState,
                page: page,
              }))
            }}
            onPageSizeChange={(size) => {
              props.dispatch((prevState) => ({
                ...prevState,
                pageSize: size,
              }))
            }}
            page={props.state.page}
            pageSize={props.state.pageSize}
            rowCount={props.state.feePolicyList.totalElements ?? 0}
            rows={props.state.feePolicyList.content ?? []}
            loading={props.state.loading}
          />
        </div>
      </Grid>
    </Grid>
  )
}
