import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  Typography
} from "@mui/material"
import { ResellItemType } from "types/ResellItemType"
import React, {useEffect, useState} from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getResellItemList, getResellItemPage, ResellItemSearchParams, updateResellItem} from "../../../apis/resellItemAPI"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {numberWithCommas} from '../../../utils/NumberUtils'

type ResellItemDetailViewProps = {
  resellItem?: ResellItemType | null
}

const ResellItemDetailView: React.FC<ResellItemDetailViewProps> = ({ resellItem }) => {
  const [resellItems, setResellItems] = useState<ResellItemType[]>([])
  const [showSameModelOnly, setShowSameModelOnly] = useState(true)

  useEffect(() => {
    if (resellItem && resellItem?.id != null) {
      getResellItemList({
        writerId: resellItem.writerId,
        itemStatus: showSameModelOnly == true ? resellItem.itemStatus : null,
        refNo: showSameModelOnly == true ? resellItem.refNo : null,
        year: showSameModelOnly == true ? resellItem.year : null,
      }).then(results => setResellItems(results))
    } else {
      setResellItems([])
    }
  }, [resellItem, showSameModelOnly])


  return (
    <Box>
      <Stack spacing={1}>
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <Button
              variant={"contained"}
              size={"small"}
              href={resellItem?.url}
              //  target={"_blank"}
            >
              원본 보기
            </Button>
          </Grid>
        </Grid>
        <Box>
          <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography>동일 작성자 게시물 ({resellItems?.length ?? 0} 개)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
              <Box>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={showSameModelOnly} onChange={(c) => setShowSameModelOnly(c.target.checked)}/>} label="동일 모델만 보기" />
                </FormGroup>
              </Box>
              <Box style={{width: '100%', overflow: 'hidden'}}>
                <TableContainer style={{maxHeight: 400, minHeight: 200}} >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">레퍼런스 번호</TableCell>
                        <TableCell align="right">스탬핑</TableCell>
                        <TableCell align="right">상태</TableCell>
                        <TableCell align="right">가격</TableCell>
                        <TableCell align="right">게시일</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resellItems?.map((row) => (
                          <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">{row.refNo}</TableCell>
                            <TableCell align="right">{row.year}</TableCell>
                            <TableCell align="right">{row.itemStatus}</TableCell>
                            <TableCell align="right">{numberWithCommas(row.lastPrice)}</TableCell>
                            <TableCell align="right">{row.registerDate}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
            <Typography>제목</Typography>
        <Box>
          <Paper style={{ padding: "10px", height: 60, maxHeight: 60, overflow: "auto" }} variant="outlined" square>
            {resellItem?.title}
          </Paper>
        </Box>

        <Typography>본문</Typography>
        <Paper style={{ maxHeight: 190, overflow: "auto", padding: "10px", height: 190 }} variant="outlined" square>
          <span style={{ whiteSpace: "pre-wrap" }}>{resellItem?.extraData}</span>
        </Paper>

        <Typography>이미지</Typography>
        <Paper style={{ maxHeight: 390 }} variant="outlined" square>
          {resellItem?.images instanceof Array ? (
            <ImageList sx={{ height: 365 }} cols={3} rowHeight={280} variant="quilted">
              {resellItem.images.map((url) => (
                <ImageListItem key={url}>
                  <img src={url} />
                </ImageListItem>
              ))}
            </ImageList>
          ) : null}
        </Paper>
      </Stack>
    </Box>
  )
}

export default ResellItemDetailView
