import { UserType } from "./UserType"
import { DetailModelType } from "./DetailModelType"

export declare interface ManualAuthenticationIssuanceDataType {
    user?: UserType | null,
    advancedDiagnosis: boolean,
    detailModel?: DetailModelType | null
}

export const enum AdvancedDiagnosisTypeCode {
    GENERAL = 0,
    DETAIL = 1,
}

export const AdvancedDiagnosisTypeCodeList = [
    { value: AdvancedDiagnosisTypeCode.GENERAL, title: "일반 감정 진단서" },
    { value: AdvancedDiagnosisTypeCode.DETAIL, title: "정밀 감정 진단서" }
];
