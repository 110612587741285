import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"

export const headerItemList = [
  {
    title: "ID",
    width: "10%",
  },
  {
    title: "사용자 ID",
    width: "10%",
  },
  {
    title: "상태",
    width: "10%",
  },
  {
    title: "노출 레벨",
    width: "10%",
  },
  {
    title: "매입보증 기한",
    width: "20%",
  },
  {
    title: "생성일시",
    width: "20%",
  },
  {
    title: "수정일시",
    width: "20%",
  },
]

const UserCollectionListTableHeader = () => {
  return (
    <ListTableHeader>
      {headerItemList.map((headerItem) => (
        <ListTableHeaderItem key={headerItem.title} style={{ maxWidth: headerItem.width }}>
          {headerItem.title}
        </ListTableHeaderItem>
      ))}
    </ListTableHeader>
  )
}

export default UserCollectionListTableHeader
