import styled from "@emotion/styled"
import { Radio, Switch } from "@mui/material"
import { BoxHorizontal, BoxVertical, Typography } from "@viver-inc/viver-ui"
import { upload, uploadFile } from "apis/resourceAPI"
import React, { FunctionComponent, useMemo } from "react"
import { EventFormStateTemplateBodyItemImageType } from "types/EventFormStateType"
import { isNotNil } from "utils/validationUtils"
import { initialEventForm } from ".."
import Button from "../../../../components/Button"
import ImageUploadButton from "../../../../components/ImageUploadButton"
import { Label } from "recharts"

export const TemplateEditorImage: FunctionComponent<{
  templateBodyImage: EventFormStateTemplateBodyItemImageType
  setTemplateBodyImage: (templateBodyImage: EventFormStateTemplateBodyItemImageType) => void
}> = ({ templateBodyImage, setTemplateBodyImage }) => {
  return (
    <BoxVertical borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10}  bottom={10}>
      <Typography variant="sub18" weight="sb">
        이미지 타입 (최대 10개)
      </Typography>
      <BoxVertical flex={1} paddingHorizontal={10} paddingVertical={10} >
        {templateBodyImage.images.map((imageItem, index) => (
          // 이미지 영역
          <TemplateEditorImageItem
            key={index}
            index={index}
            imageItem={imageItem}
            setImageItem={(imageItem) => {
              setTemplateBodyImage({
                ...templateBodyImage,
                images: templateBodyImage.images.map((image, _index) => {
                  if (index === _index) {
                    return imageItem
                  }
                  return image
                }),
              })
            }}
            onClickDeleteImageItem={() => {
              setTemplateBodyImage({
                ...templateBodyImage,
                images: templateBodyImage.images.filter((_, _index) => index !== _index),
              })
            }}
          />
        ))}
        {/*추가 버튼*/}
          <ButtonWrapper>
            <Button
              buttonSize="M"
              disabled={templateBodyImage.images.length >= 10}
              buttonStyle="blue-fill"
              onClick={() => {
                setTemplateBodyImage({
                  ...templateBodyImage,
                  images: [...templateBodyImage.images, initialEventForm.templateBodyImage.images[0]],
                })
              }}>
              추가
            </Button>
          </ButtonWrapper>
      </BoxVertical>
    </BoxVertical>
  )
}

const TemplateEditorImageItem: FunctionComponent<{
  index: number
  imageItem: EventFormStateTemplateBodyItemImageType["images"][0]
  setImageItem: (imageItem: EventFormStateTemplateBodyItemImageType["images"][0]) => void
  onClickDeleteImageItem: () => void
}> = ({ index, imageItem, setImageItem, onClickDeleteImageItem }) => {
  const imageItemType = useMemo(() => {
    if (imageItem.apply) return "APPLY"
    if (isNotNil(imageItem.linkUrl)) return "DEEPLINK"
    return "NONE"
  }, [imageItem.apply, imageItem.linkUrl])

  const handleImage = (image:any) => {
      upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
        setImageItem({ ...imageItem, imageUrl: result.url })
      })
  }

  const handleImageEnglish = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      debugger
      setImageItem({ ...imageItem, imageUrlEnglish: result.url })
    })
  }

  const handleImageJapanese = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageItem({ ...imageItem, imageUrlJapanese: result.url })
    })
  }

  return (
    // 이미지타입
    <ImageItemWrapper >
      <BoxHorizontal justifyContent={"space-between"} borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10} >
        <BoxHorizontal >
          <BoxVertical>
            {/*이미지 영역*/}
            <BoxHorizontal justifyContent={"flex-start"} width={"100%"}>
              {index+1}
              <BoxHorizontal>
                <ImageWrapper>
                  <BoxVertical alignItems={"center"}>
                    <Typography variant="desc16" weight="m" marginBottom={10}>
                      한국어
                    </Typography>
                    <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'} imageUrl={imageItem.imageUrl} handleImage={handleImage} handleDeleteImage={()=>{setImageItem({ ...imageItem, imageUrl: null })}} />
                  </BoxVertical>
                  </ImageWrapper>
                <ImageWrapper>
                  <BoxVertical alignItems={"center"}>
                    <Typography variant="desc16" weight="m" marginBottom={10}>
                      영어
                    </Typography>
                    <ImageUploadButton height={'100px'} width={'150px'}  buttonHeight={'20px'}  imageUrl={imageItem.imageUrlEnglish} handleImage={handleImageEnglish}  handleDeleteImage={()=>{setImageItem({ ...imageItem, imageUrlEnglish: null })}} />
                  </BoxVertical>
                </ImageWrapper>
                <ImageWrapper>
                  <BoxVertical alignItems={"center"}>
                    <Typography variant="desc16" weight="m" marginBottom={10}>
                      일본어
                    </Typography>
                    <ImageUploadButton height={'100px'} width={'150px'} buttonHeight={'20px'}  imageUrl={imageItem.imageUrlJapanese} handleImage={handleImageJapanese}  handleDeleteImage={()=>{setImageItem({ ...imageItem, imageUrlJapanese: null })}} />
                  </BoxVertical>
                </ImageWrapper>
              </BoxHorizontal>
            </BoxHorizontal>
            {/*노출 없음 응모하기 딥링크*/}
            <BoxVertical flex={1} justifyContent="center">
              <BoxHorizontal alignItems="center" paddingLeft={20} paddingTop={10}>
                {/*노출*/}
                <BoxHorizontal alignItems="center">
                  <Switch
                    checked={imageItem.onDisplay}
                    onChange={(e, check) => {
                      setImageItem({ ...imageItem, onDisplay: check })
                    }}
                  />
                  <Typography variant="desc14" weight="r">
                    노출
                  </Typography>
                </BoxHorizontal>
                {/*없음*/}
                <BoxHorizontal alignItems="center">
                  <RadioLabelWrapper>
                    <Radio
                      checked={imageItemType === "NONE"}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setImageItem({ ...imageItem, linkUrl: undefined, apply: false })
                        }
                      }}
                    />
                    <Typography variant="desc14" weight="r">
                      없음
                    </Typography>
                  </RadioLabelWrapper>
                </BoxHorizontal>
                {/*응모하기*/}
                <BoxHorizontal alignItems="center">
                  <RadioLabelWrapper>
                    <Radio
                      checked={imageItemType === "APPLY"}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setImageItem({ ...imageItem, linkUrl: undefined, apply: true })
                        }
                      }}
                    />
                    <Typography variant="desc14" weight="r">
                      응모하기
                    </Typography>
                  </RadioLabelWrapper>
                </BoxHorizontal>
                {/*딥링크*/}
                <BoxHorizontal alignItems="center" flex={1}>
                  <RadioLabelWrapper>
                    <Radio
                      checked={imageItemType === "DEEPLINK"}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setImageItem({ ...imageItem, linkUrl: "", apply: false })
                        }
                      }}
                    />
                    <BoxHorizontal width={50}>
                      <Typography variant="desc14" weight="r">
                        딥링크
                      </Typography>
                    </BoxHorizontal>
                  </RadioLabelWrapper>
                  <Input
                    value={imageItem.linkUrl ?? ""}
                    placeholder={
                      imageItemType === "DEEPLINK"
                        ? "contents/82 | viver://contents/82 | https://www.viver.co.kr/contents/82"
                        : ""
                    }
                    onChange={(event) => {
                      setImageItem({ ...imageItem, linkUrl: event.target.value })
                    }}
                    style={{width:"290px"}}
                  />
                </BoxHorizontal>
              </BoxHorizontal>
            </BoxVertical>
          </BoxVertical>
        </BoxHorizontal>
        {/*삭제*/}
        <BoxHorizontal>
          {
            index !== 0 &&
            <ButtonWrapper>
              <Button
                buttonSize="M"
                buttonStyle="red-fill"
                onClick={onClickDeleteImageItem}>
                삭제
              </Button>
            </ButtonWrapper>
          }
        </BoxHorizontal>
      </BoxHorizontal>
    </ImageItemWrapper>
  )
}

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  outline: none;
  height: 40px;
  padding: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  margin-right: 10px;
`
const ButtonWrapper = styled.div`
  width: 50px; 
`

const ImageItemWrapper = styled.div`
    margin-bottom: 10px; 
    background-color: #eeeeee;
`
const ImageWrapper = styled.div`
    margin-top: 5px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
    
`

const RadioLabelWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;