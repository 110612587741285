import { MenuItem, Select } from "@mui/material"
import { FunctionComponent, useState } from "react"
import SearchInput from "components/Search/SearchBar/SearchInput"
import styled from "@emotion/styled"

export const ModelManagerSearchBar: FunctionComponent<{
  query: string
  onChangeQuery: (query: string) => void
}> = ({ query, onChangeQuery }) => {
  const [modelQuery, setModelQuery] = useState(query)

  return (
    <StyledDivModelManagerSearchBar>
      <InlineSearchBar>
        <Select value={"title"} size="small" sx={{ width: 135 }}>
          <MenuItem value={"title"}>모델명</MenuItem>
        </Select>
        <SearchInput
          style={{ height: 40 }}
          value={modelQuery}
          onChange={(event) => setModelQuery(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onChangeQuery(modelQuery)
            }
          }}
        />
      </InlineSearchBar>
    </StyledDivModelManagerSearchBar>
  )
}

const StyledDivModelManagerSearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1;
  background: #fff;
  padding-bottom: 20px;
`

const InlineSearchBar = styled.div`
  max-width: 70%;
  flex: 1;
  display: inline-flex;
  align-items: center;
`
