import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import SerialNumberListTableHeader from "./TableHeader"
import SerialNumberSearchTableRow from "./TableBody/TableRow"
import styled from "@emotion/styled"
import { SerialNumberListItemType } from "types/SerialNumberType"

type Props = {
  serialNumberList: SerialNumberListItemType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
  selectSerialRow: (serialNumber: string) => void
}

export const SerialNumberListTable = ({ serialNumberList, paginationParams, changePage, selectSerialRow }: Props) => {
  return (
    <ListTable>
      <SerialNumberListTableHeader />
      <TableBody>
        {serialNumberList.length > 0 ? (
          serialNumberList.map((serialNumberListItem) => (
            <SerialNumberSearchTableRow
              key={serialNumberListItem.serialNo}
              serialNumberListItem={serialNumberListItem}
              onClick={() => selectSerialRow(serialNumberListItem.serialNo)}
            />
          ))
        ) : (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        )}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page - 1)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
