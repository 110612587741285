import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from "@mui/material"
import React, { useState } from "react"
import RowBlock from "../RowBlock"
import SearchEventsPopup from "./SearchEventPopup"
import SearchArticlesPopup from "./SearchMagazineArticlePopup"
import SearchQuestionsPopup from "./SearchMagazineQuestionPopup"
import SearchDetailModelPopup from "./SearchDetailModelPopup"
import { EventType } from "../../types/EventType"
import { DetailModelType } from "../../types/DetailModelType"
import { Article, Question } from "../../types/MagazineType"
import SearchNoticesPopup from "./SearchNoticePopup"
import { NoticeType } from "../../types/NoticeType"
import { ProductStatusCode, ProductType } from "../../types/ProductType"
import ProductSelectPopup from "./ProductSelectPopup"

type SelectAppLinkPopupProps = {
  open: boolean
  dialogTitle?: string
  handleClose: Function
}

const options = [
  {
    name: "GNB",
    children: [
      { name: "스토어 홈", value: "viver://home/shop" },
      { name: "스토어 > 검색", value: "viver://shop/search?query=${검색어}" },
      { name: "콜렉션 홈", value: "viver://home/collection" },
      { name: "판매하기 홈", value: "viver://home/sell" },
      { name: "마이페이지 홈", value: "viver://home/mypage" },
    ],
  },
  {
    name: "공지사항",
    children: [
      { name: "공지사항 목록", value: "viver://mypage/notice" },
      { name: "공지사항 상세", value: "viver://mypage/notice/${공지사항ID}", param: "공지사항" },
    ],
  },
  {
    name: "이벤트",
    children: [
      { name: "이벤트 목록", value: "viver://mypage/event" },
      { name: "이벤트 상세", value: "viver://mypage/event/${이벤트ID}", param: "이벤트" },
    ],
  },
  {
    name: "상품",
    children: [
      { name: "상품 상세", value: "viver://product-detail/${상품ID}", param: "상품" },
      { name: "상단 이미지", value: "viver://product-detail/${상품ID}/thumbnail-image", param: "상품" },
      { name: "타이틀 영역", value: "viver://product-detail/${상품ID}/title", param: "상품" },
      { name: "제품 상태", value: "viver://product-detail/${상품ID}/accessory-conditions", param: "상품" },
      { name: "배송정보", value: "viver://product-detail/${상품ID}/infos", param: "상품" },
      { name: "상품 컨디션", value: "viver://product-detail/${상품ID}/condition-detail", param: "상품" },
      { name: "바이버 인덱스", value: "viver://product-detail/${상품ID}/viver-index", param: "상품" },
      { name: "상세 스펙", value: "viver://product-detail/${상품ID}/spec-info", param: "상품" },
      { name: "연관 상품", value: "viver://product-detail/${상품ID}/related-contents", param: "상품" },
      { name: "하단 텍스트 영역", value: "viver://product-detail/${상품ID}/product-detail-alert-text", param: "상품" },
    ],
  },
  {
    name: "상세모델",
    children: [
      { name: "상세모델 상세", value: "viver://detail-models/${상세모델ID}", param: "상세모델" },
      { name: "상단 이미지", value: "viver://detail-models/${상세모델ID}/photo", param: "상세모델" },
      { name: "판매중인 상품 영역", value: "viver://detail-models/${상세모델ID}/sell-products", param: "상세모델" },
      { name: "상품평 영역", value: "viver://detail-models/${상세모델ID}/comment", param: "상세모델" },
      { name: "바이버 인덱스", value: "viver://detail-models/${상세모델ID}/viver-index", param: "상세모델" },
      {
        name: "바이버 인덱스 > 중고",
        value: "viver://detail-models/${상세모델ID}/viver-index-used",
        param: "상세모델",
      },
      { name: "제품 스펙", value: "viver://detail-models/${상세모델ID}/spec", param: "상세모델" },
      { name: "연관 매거진", value: "viver://detail-models/${상세모델ID}/magazine", param: "상세모델" },
    ],
  },
  {
    name: "마이페이지",
    children: [
      { name: "마이페이지", value: "viver://mypage/home" },
      { name: "로그인", value: "viver://login" },
      { name: "설정", value: "viver://mypage/setting" },
      { name: "판매내역 리스트", value: "viver://mypage/history/sell" },
      { name: "가격제안 리스트", value: "viver://mypage/history/sell/${param}/buy-price-offer", param: "판매주문" },
      { name: "구매내역 리스트", value: "viver://mypage/history/purchase" },
      { name: "고객센터", value: "viver://mypage/cs" },
      { name: "고객센터 > 자주 묻는 질문", value: "viver://mypage/cs/faq" },
      { name: "고객센터 > 문의하기", value: "viver://mypage/cs/asking" },
      { name: "업그레이드 안내", value: "viver://mypage/diagnosis/업그레이드" },
      { name: "검수과정 안내", value: "viver://mypage/diagnosis/검수" },
      { name: "포인트", value: "viver://mypage/point" },
    ],
  },
  {
    name: "컨텐츠",
    children: [
      { name: "매거진 상세", value: "viver://contents/${매거진ID}", param: "매거진" },
      { name: "Q&A 목록", value: "viver://contents/qna" },
      { name: "Q&A 상세", value: "viver://contents/qna/${QnA ID}", param: "QnA" },
    ],
  },
  {
    name: "기타",
    children: [
      { name: "콜렉션 > 브랜드 선택", value: "viver://collection/brand" },
      { name: "매입보증 안내", value: "viver://about/repurchase-guarantee" },
    ],
  },
]

const SelectAppLinkPopup: React.FC<SelectAppLinkPopupProps> = ({ open, dialogTitle, handleClose }) => {
  const [noticeSearchPopup, setNoticeSearchPopup] = useState<boolean>(false)
  const [eventSearchPopup, setEventSearchPopup] = useState<boolean>(false)
  const [detailModelSearchPopup, setDetailModelSearchPopup] = useState<boolean>(false)
  const [productSearchPopup, setProductSearchPopup] = useState<boolean>(false)
  const [articleSearchPopup, setArticleSearchPopup] = useState<boolean>(false)
  const [questionSearchPopup, setQuestionSearchPopup] = useState<boolean>(false)
  const [option1st, setOption1st] = useState<string>("")
  const [option2nd, setOption2nd] = useState<string>("")
  const [url, setUrl] = useState<string>("")

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth>
      <SearchNoticesPopup
        open={noticeSearchPopup}
        handleClose={(result?: NoticeType) => {
          setNoticeSearchPopup(false)
          if (result) setUrl(url.replace(/\${.*}/, "" + result?.id))
        }}
      />
      <SearchEventsPopup
        open={eventSearchPopup}
        handleClose={(result?: EventType) => {
          setEventSearchPopup(false)
          if (result) setUrl(url.replace(/\${.*}/, "" + result?.id))
        }}
      />
      <SearchDetailModelPopup
        open={detailModelSearchPopup}
        handleClose={(result?: DetailModelType) => {
          setDetailModelSearchPopup(false)
          if (result) setUrl(url.replace(/\${.*}/, "" + result?.id))
        }}
      />
      <ProductSelectPopup
        open={productSearchPopup}
        filterStatus={[ProductStatusCode.판매중]}
        isMultiSelectable={true}
        isSearchable={true}
        setProducts={(results?: ProductType[]) => {
          if (results) setUrl(results?.map((it) => url.replace(/\${.*}/, "" + it?.id))?.join("\n"))
        }}
        close={() => {
          setProductSearchPopup(false)
        }}
      />
      <SearchArticlesPopup
        open={articleSearchPopup}
        handleClose={(result?: Article) => {
          setArticleSearchPopup(false)
          if (result) setUrl(url.replace(/\${.*}/, "" + result?.id))
        }}
      />
      <SearchQuestionsPopup
        open={questionSearchPopup}
        handleClose={(result?: Question) => {
          setQuestionSearchPopup(false)
          if (result) setUrl(url.replace(/\${.*}/, "" + result?.id))
        }}
      />
      <DialogTitle>{dialogTitle ? dialogTitle : "앱 링크 선택"}</DialogTitle>
      <DialogContent style={{ height: "100%" }}>
        <RowBlock title={"대분류"}>
          <Select
            fullWidth
            size={"small"}
            value={option1st}
            onChange={(event) => {
              setOption1st(event.target.value as string)
              setOption2nd("")
              setUrl("")
            }}
          >
            {options.map((option) => (
              <MenuItem value={option.name} key={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </RowBlock>

        <RowBlock title={"소분류"}>
          <Select
            fullWidth
            size={"small"}
            value={option2nd}
            onChange={(event) => {
              const selected = event.target.value as string
              setOption2nd(selected)
              setUrl(
                options.find((it) => it.name === option1st)?.children.find((it) => it.name === selected)?.value || ""
              )

              switch (options.find((it) => it.name === option1st)?.children.find((it) => it.name === selected)?.param) {
                case "공지사항":
                  setNoticeSearchPopup(true)
                  break
                case "이벤트":
                  setEventSearchPopup(true)
                  break
                case "상품":
                  setProductSearchPopup(true)
                  break
                case "상세모델":
                  setDetailModelSearchPopup(true)
                  break
                case "매거진":
                  setArticleSearchPopup(true)
                  break
                case "QnA":
                  setQuestionSearchPopup(true)
                  break
              }
            }}
          >
            {options
              .find((it) => it.name === option1st)
              ?.children.map((option) => (
                <MenuItem value={option.name} key={option.name}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </RowBlock>
        <RowBlock title={`URL (${(url && url?.split("\n").length) || 0}건)`}>
          <TextField fullWidth size={"small"} multiline={true} minRows={1} maxRows={10} value={url} />
        </RowBlock>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={() => handleClose(url)}>
          확인
        </Button>
        <Button variant={"outlined"} color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectAppLinkPopup
