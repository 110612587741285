import React from 'react';
import { ProvisionalPreSettlementDetailType } from "../../../types/PreSettlementType"
import { numberWithCommas } from "../../../utils/NumberUtils"

export const ProvisionalPreSettlementInfoView:React.FC<{
  item:ProvisionalPreSettlementDetailType
}> = (props)=>{


  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2>선정산 가견적</h2>
      </div>

      <table className={'basic'}>
        <tbody>
        <tr>
          <td className={'header'} colSpan={1}>
            가견적 기준가
          </td>

          <td colSpan={2}>
            {numberWithCommas(props.item.standardPriceForPreSettlement)}원
          </td>


          <td className={'header'} colSpan={1}>
            가견적 적용
          </td>

          <td colSpan={2}>
            {props.item.calculationType === '정가' ? `${numberWithCommas(props.item.amount)}원` : `${props.item.rate*100}%`}
          </td>

          <td className={'header'} colSpan={1}>
            최종 적용 금액
          </td>
          <td colSpan={2}>
            {numberWithCommas(props.item.preSettlementPrice)}원
          </td>


        </tr>
        </tbody>
      </table>
    </>
  )
}
