import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import {ShopBannerType} from "types/ShopBannerType";
import { ShopHomeReviewType } from "../types/ShopHomeReviewType"


export default {
    create: (banner: ShopBannerType): Promise<ShopBannerType> => httpPost("/shop/contents/banners", banner),
    update: (id: number, banner: ShopBannerType): Promise<ShopBannerType> => httpPut(`/shop/contents/banners/${id}`, banner),
    get: (id: number): Promise<ShopBannerType> => httpGet(`/shop/contents/banners/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/shop/contents/banners/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<ShopBannerType>> => httpGet("/shop/contents/banners", params),
    getList: (params?:  { [key: string]: any }): Promise<ShopBannerType[]> => httpGet("/shop/contents/banners/list", params),
    reordering: (list: ShopBannerType[]): Promise<ShopBannerType[]> => httpPut(`/shop/contents/banners/reordering`, list),
    reorderingByDirect: (list: ShopBannerType[]): Promise<ShopBannerType[]> => httpPut(`/shop/contents/banners/reordering/direct`, list),
}