import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

const SearchBar = ({ children }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <SearchBarWrapper>{children}</SearchBarWrapper>
}

export default SearchBar

const SearchBarWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`
