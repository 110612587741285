import { PointListParams, PointType, PostPointBodyType } from "types/PointType"
import { httpDelete, httpGet, httpPost, httpPut } from "utils/httpAPI"
import { PageType } from "types/PageType"
import { PointListItemType, PointListSearchParams } from "types/PointListItemType"
import {
  PointScheduleListItemType,
  PointScheduleListSearchParams,
  PostPointSchdeduleBodyType,
  PutPointScheduleBodyType,
} from "types/PointScheduleListItemType"

export const cancelPointSchedule: (poindScheduleId: number) => Promise<PointScheduleListItemType> = (
  pointdScheduleId: number
) => httpDelete(`/points/schedule/${pointdScheduleId}`)

export const postPointSchedule: (body: PostPointSchdeduleBodyType) => Promise<PointScheduleListItemType> = (
  body: PostPointSchdeduleBodyType
) => httpPost("/points/schedule", body)

export const putPointSchedule: (body: PutPointScheduleBodyType, id: number) => Promise<PointListItemType> = (
  body: PutPointScheduleBodyType,
  id: number
) => httpPut(`/points/schedule/${id}`, body)

export const getPointScheduleList: (
  listParams: PointScheduleListSearchParams
) => Promise<PageType<PointScheduleListItemType>> = (listParams: PointScheduleListSearchParams) =>
  httpGet(`/points/schedule`, listParams)

export const getPointList: (listParams: PointListSearchParams) => Promise<PageType<PointListItemType>> = (
  listParams: PointListSearchParams
) => httpGet(`/points`, listParams)

export const getPointHistoryList: (userId: number, listParams: PointListParams) => Promise<PageType<PointType>> = (
  userId: number,
  listParams: PointListParams
) => httpGet(`/users/${userId}/point-histories`, listParams)

export const getAvailableUserPoint: (userId: string) => Promise<{ availablePoints: number }> = (userId) =>
  httpGet(`/users/${userId}/point`)

export const postUserPoint: (userId: string, postBody: PostPointBodyType) => Promise<PointType> = (
  userId: string,
  postBody: PostPointBodyType
) => httpPost(`/users/${userId}/point-histories`, postBody)

export const deleteUserPoint: (userId: string, pointId: number) => Promise<number> = (
  userId: string,
  pointId: number
) => httpDelete(`/users/${userId}/point-histories/${pointId}`)
