import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField} from "@mui/material";
import {createUserMemo, deleteUserMemo, getUserMemos} from "../../../apis/userMemoAPI";
import {CreateUserMemoType, UserMemoTargetTypeCode, UserMemoType} from "../../../types/UserType";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {createMemo} from "react-use";
import {DateTime} from "luxon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

type props = {
    open: boolean
    target: UserMemoTargetTypeCode,
    targetId?: string | number,
    handleClose: () => void
}

function createColumns(handleDeleteMemo: (id:number) => void) {
    return [
        {
            field: 'memo',
            headerName: "메모",
            editable: false,
            sortable: false,
            flex: 1
        },
        {
            field: 'createdBy',
            headerName: "작성자",
            editable: false,
            sortable: false,
            valueGetter: (params: any) => `${params.row.createdBy.name}`,
        },
        {
            field: 'createdAt',
            headerName: "작성일",
            editable: false,
            sortable: false,
            minWidth: 135,
            valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm")}`,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 30,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon = {<DeleteIcon />}
                    label = {"삭제"}
                    onClick={() => {
                        if (window.confirm("메모를 삭제 하시겠습니까?")) handleDeleteMemo?.(params.row.id)
                    }}
                />
            ]
        }
    ]
}
const QuickMemoPopup: React.FC<React.PropsWithChildren<props>> = ({ children, open, target, targetId, handleClose }) => {
    const [memos, setMemos] = useState<UserMemoType[]>([])
    const [memo, setMemo] = useState<CreateUserMemoType|null>(null)

    const handleChangeMemo = (memo: string) => {
        setMemo({memo: memo})
    }

    const handleCreateMemo = () => {
        if (memo) {
            createUserMemo({
                ...memo,
                targetType: target,
                userId: target == 'USER' ? (targetId as number) : null,
                productId: target == 'PRODUCT' ? (targetId as number) : null,
            }).then(() =>{
                fetchMemos()
                setMemo(null)
            })
        }
    }

    const handleDeleteMemo = (id: number) => {
        deleteUserMemo(id).then(() => fetchMemos())
    }

    const fetchMemos = () => {
        const searchParams = {
            sort: 'createdAt,desc',
            userId: target == 'USER' ? (targetId as number) : null,
            productId: target == 'PRODUCT' ? (targetId as number) : null,
        }

        getUserMemos(searchParams).then(({content}) => {
            setMemos(content)
        })
    }

    useEffect(() => {
        if (open) fetchMemos()
    }, [open])

    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth>
            <DialogTitle>{target} 메모</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>

                    <Stack spacing={1}>
                        <div style={{height: '400px', width: '100%'}}>
                            <DataGrid style={{height: '400px',}} columns={createColumns(handleDeleteMemo)} rows={memos} />
                        </div>
                    </Stack>

                    <Grid container>
                        <Grid item xs={11}><TextField placeholder={'추가할 메모를 입력 해주세요.'} fullWidth value={memo?.memo ?? ''} onChange={(e) => handleChangeMemo(e.target.value)}/></Grid>
                        <Grid item xs={1}><Button fullWidth style={{height: '100%'}} variant={'contained'} size={'small'} onClick={handleCreateMemo}>추가</Button></Grid>
                    </Grid>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleClose}>확인</Button>
            </DialogActions>
        </Dialog>
    )
}

export default QuickMemoPopup