import { OrderType, SellOrderListSearchParams, SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { useCallback, useEffect, useState } from "react"
import { countSellOrders, getSellOrders } from "apis/sellOrderAPI"
import { PageType } from "types/PageType"
import { toApplyCounts } from "utils/SellOrderTypeConverter"
import { CountType } from "types/CountType"
import { useSearchParams } from "react-router-dom"

export const useSellOrderListData = (sellOrderTypeList: OrderType[]) => {
  const [currentSearchParams] = useSearchParams()
  const [searchObject, setSearchObject] = useState<{
    key: keyof SellOrderListSearchParams | undefined
    value: string | number
  }>({
    key: "productId",
    value: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState<SellOrderListSearchParams>({
    page: 0,
    size: 25,
    sellOrderType: sellOrderTypeList,
    ...Object.fromEntries(currentSearchParams.entries()),
  })
  const [sellOrderCountList, setSellOrderCountList] = useState<CountType[]>([])
  const [selectedSellOrderIdList, setSelectedSellOrderIdList] = useState<string[]>([])
  const [sellOrderListPageData, setSellOrderListPageData] = useState<PageType<SellOrderType>>({
    content: [],
    pageable: {
      pageSize: 25,
      pageNumber: 0,
    },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  })

  const fetchSellOrderList = useCallback(
    (searchParams) => {
      setIsLoading(true)
      getSellOrders(searchParams).then((sellOrderListPageData) => {
        setSellOrderListPageData(sellOrderListPageData)
      })
      setIsLoading(false)
    },
    [setSellOrderListPageData]
  )

  const fetchSellOrderListCount = useCallback((searchParams) => {
    countSellOrders(searchParams).then((result) => {
      setSellOrderCountList(
        toApplyCounts(
          result,
          [
            SellOrderStatusCode.픽업_준비,
            SellOrderStatusCode.입고중,
            SellOrderStatusCode.입고_완료,
            SellOrderStatusCode.진단중,
          ],
          ".",
          [],
          true
        )
      )
    })
  }, [])

  useEffect(() => {
    setSearchParams((prev) => ({
      ...prev,
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) || undefined,
      ...Object.fromEntries(currentSearchParams.entries()),
    }))
  }, [currentSearchParams])

  useEffect(() => {
    Promise.all([fetchSellOrderList(searchParams), fetchSellOrderListCount(searchParams)])
  }, [searchParams])

  const onSearch = () => {
    if (!!searchObject.key) {
      const paramsWithSearchObject = { ...searchParams, [searchObject.key]: searchObject.value }
      Promise.all([fetchSellOrderList(paramsWithSearchObject), fetchSellOrderListCount(paramsWithSearchObject)])
    }
  }

  const changeSearchParams = (key: keyof SellOrderListSearchParams, value: number | string) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const toggleSelectedOrderList = (idList: string[]) => {
    setSelectedSellOrderIdList(idList)
  }

  const refreshList = () => {
    Promise.all([
      fetchSellOrderList({ ...searchParams, page: 0 }),
      fetchSellOrderListCount({ ...searchParams, page: 0 }),
    ])
  }

  const changeSearchObject = (obejctKey: "key" | "value", objectValue: string | number) => {
    setSearchObject((prev) => ({ ...prev, [obejctKey]: objectValue }))
  }

  return {
    searchObject,
    searchParams,
    isLoading,
    sellOrderCountList,
    changeSearchParams,
    sellOrderListPageData,
    selectedSellOrderIdList,
    setSelectedSellOrderIdList,
    toggleSelectedOrderList,
    refreshList,
    changeSearchObject,
    onSearch,
  }
}
