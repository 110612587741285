import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI";
import {PageType} from "types/PageType";
import {ShopHomeReviewType} from "types/ShopHomeReviewType";


export default {
    create: (homeReview: ShopHomeReviewType): Promise<ShopHomeReviewType> => httpPost("/shop/home-reivews", homeReview),
    update: (id: number, homeReview: ShopHomeReviewType): Promise<ShopHomeReviewType> => httpPut(`/shop/home-reivews/${id}`, homeReview),
    get: (id: number): Promise<ShopHomeReviewType> => httpGet(`/shop/home-reivews/${id}`),
    delete: (id: number): Promise<string> => httpDelete(`/shop/home-reivews/${id}`),
    getPage: (params?:  { [key: string]: any }): Promise<PageType<ShopHomeReviewType>> => httpGet("/shop/home-reivews", params),
    getList: (params?:  { [key: string]: any }): Promise<ShopHomeReviewType[]> => httpGet("/shop/home-reivews/list", params),
    reordering: (list: ShopHomeReviewType[]): Promise<ShopHomeReviewType[]> => httpPut(`/shop/home-reivews/reordering`, list),
}