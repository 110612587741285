import { MenuItem, Select } from "@mui/material"
import { FunctionComponent } from "react"

export const DetailModelInfoInput: FunctionComponent<{
  brandSid: string
  modelOptions: { modelId: number; modelName: string }[]
  modelId: number
  name: string
  nameKorean: string
  refNo: string
  fullRefNo: string
  onChangeModelId: (modelId: number) => void
  onChangeName: (name: string) => void
  onChangeNameKorean: (nameKorean: string) => void
  onChangeRefNo: (refNo: string) => void
  onChangeFullRefNo: (fullRefNo: string) => void
}> = ({
  brandSid,
  modelOptions,
  modelId,
  name,
  nameKorean,
  refNo,
  fullRefNo,
  onChangeModelId,
  onChangeName,
  onChangeNameKorean,
  onChangeRefNo,
  onChangeFullRefNo,
}) => {
  return (
    <div style={{ display: "inline-flex", flexDirection: "column", marginLeft: 24, width: 560 }}>
      <div>모델 기본 정보</div>
      <div style={{ height: 16 }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DetailModelInfoBrandTextField label="브랜드" inputValue={brandSid} />
        <div style={{ width: 18 }} />
        <DetailModelInfoModelAutocomplete
          label="모델명"
          modelOptions={modelOptions}
          modelId={modelId}
          onChangeModelId={(modelId) => onChangeModelId(modelId)}
        />
      </div>
      <div style={{ height: 24 }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DetailModelInfoTextField
          label="상세모델명(영문)"
          inputValue={name}
          inputPlaceholder="ex) Submariner Date"
          onChangeInputValue={onChangeName}
        />
        <div style={{ width: 18 }} />
        <DetailModelInfoTextField
          label="상세모델명(한글)"
          inputValue={nameKorean}
          inputPlaceholder="ex) 서브마리너 데이트"
          onChangeInputValue={onChangeNameKorean}
        />
      </div>
      <div style={{ height: 24 }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DetailModelInfoTextField
          label="Ref No."
          inputValue={refNo}
          inputPlaceholder="ex) 126610LN"
          onChangeInputValue={onChangeRefNo}
        />
      </div>
      <div style={{ height: 24 }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DetailModelInfoTextField
          label="Full Ref."
          inputValue={fullRefNo}
          inputPlaceholder="ex) 126610LN-0001"
          onChangeInputValue={onChangeFullRefNo}
        />
      </div>
    </div>
  )
}

const DetailModelInfoBrandTextField: FunctionComponent<{
  label: string
  inputValue: string
}> = ({ label, inputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
      <div style={{ width: 70 }}>{label}</div>
      <div style={{ width: 8 }} />
      <div>{inputValue}</div>
    </div>
  )
}

const DetailModelInfoModelAutocomplete: FunctionComponent<{
  label: string
  modelOptions: { modelId: number; modelName: string }[]
  modelId: number
  onChangeModelId: (modelId: number) => void
}> = ({ label, modelOptions, modelId, onChangeModelId }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
      <div style={{ width: 70 }}>{label}</div>
      <div style={{ width: 8 }} />
      <div style={{ flex: 1 }}>
        <Select
          size="small"
          fullWidth
          MenuProps={{ style: { zIndex: 10001 } }}
          value={modelId}
          onChange={(event) => onChangeModelId(Number(event.target.value))}
        >
          {modelOptions.map((modelOption) => (
            <MenuItem key={modelOption.modelId} value={modelOption.modelId}>
              {modelOption.modelName}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}

const DetailModelInfoTextField: FunctionComponent<{
  label: string
  inputValue: string
  inputPlaceholder?: string
  onChangeInputValue: (inputValue: string) => void
}> = ({ label, inputValue, inputPlaceholder, onChangeInputValue }) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
      <div style={{ width: 70 }}>
        <span>{label}</span>
        <span style={{ color: "#CC2929" }}>*</span>
      </div>
      <div style={{ width: 8 }} />
      <input
        style={{
          flex: 1,
          height: 38,
          border: "none",
          borderBottom: "1px solid #1C2229",
          outline: "none",
          fontSize: 14,
          fontFamily: "Pretendard",
          lineHeight: "18px",
        }}
        type="text"
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={(event) => onChangeInputValue(event.target.value)}
      />
    </div>
  )
}
