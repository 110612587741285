import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import React from "react";
import {DateTime} from "luxon";
import _ from 'lodash'
import {Typography} from "@mui/material";
const formatter = new Intl.NumberFormat('ko-KR', {maximumSignificantDigits: 3})
const priceCellRender = (manual: number | null, calculated: number | null) => {
    if (manual != null) return <Typography><b>{formatter.format(manual)}</b></Typography>
    else return <Typography style={{fontWeight: 100}}>{calculated != null? formatter.format(calculated) : ''}</Typography>
}
//@ts-ignore
const AskingPriceDetailTable = ({rows, isLoading, handleRowUpdate, handleRowsUpdate, handleRowSelection}) => {

    const columns = [
        {field: 'actions', type: 'actions', width: 30, getActions: (params: any) => [
                <GridActionsCellItem
                    label = {"이전 데이터 채우기"}
                    onClick={() => {
                        const targetMetrics = params.row
                        let updatedRows: any[] = []
                        for (const row of _.sortBy(rows, ['metricsDate'])) {
                            if (DateTime.fromISO(row.metricsDate) < DateTime.fromISO(targetMetrics.metricsDate)) {
                                if (row.maxAskingPrice == null && row.minAskingPrice == null && row.avgAskingPrice == null) {
                                    updatedRows.push({...row,
                                        maxAskingPrice: row.maxAskingPrice ?? targetMetrics.maxAskingPrice,
                                        minAskingPrice: row.minAskingPrice ?? targetMetrics.minAskingPrice,
                                        avgAskingPrice: row.avgAskingPrice ?? targetMetrics.avgAskingPrice,
                                    })
                                } else {
                                    updatedRows = []
                                }
                            } else {
                                break
                            }
                        }

                        handleRowsUpdate(updatedRows)
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    label = {"이후 데이터 채우기"}
                    onClick={() => {
                        const targetMetrics = params.row
                        let updatedRows: any[] = []
                        for (const row of _.chain(rows).sortBy(['metricsDate']).reverse().value() ) {
                            if (DateTime.fromISO(row.metricsDate) > DateTime.fromISO(targetMetrics.metricsDate)) {
                                if (row.maxAskingPrice == null && row.minAskingPrice == null && row.avgAskingPrice == null) {
                                    updatedRows.push({...row,
                                        maxAskingPrice: row.maxAskingPrice ?? targetMetrics.maxAskingPrice,
                                        minAskingPrice: row.minAskingPrice ?? targetMetrics.minAskingPrice,
                                        avgAskingPrice: row.avgAskingPrice ?? targetMetrics.avgAskingPrice,
                                    })
                                } else {
                                    updatedRows = []
                                }
                            } else {
                                break
                            }
                        }

                        handleRowsUpdate(updatedRows)
                    }}
                    showInMenu
                />
            ]},
        {field: 'id.metricsDate', headerName: "날짜", type: 'date', editable: false, width: 100, valueGetter: (params: any) => `${params.row?.metricsDate}`},
        {field: 'maxAskingPrice', headerName: "최고가", type: 'number', editable: true, flex: 1, renderCell: (params: any) => (priceCellRender(params.row.manualMaxAskingPrice, params.row.maxAskingPrice))},
        {field: 'minAskingPrice', headerName: "최저가", type: 'number', editable: true, flex: 1, renderCell: (params: any) => (priceCellRender(params.row.manualMinAskingPrice, params.row.minAskingPrice))},
        {field: 'avgAskingPrice', headerName: "평균가", type: 'number', editable: true, flex: 1, renderCell: (params: any) => (priceCellRender(params.row.manualAvgAskingPrice, params.row.avgAskingPrice))},
        {field: 'volume', headerName: "호가수", type: 'number', editable: false,flex: 1},
    ]

    return(
        <div style={{height: 735}}>
            <DataGrid
                columns={columns}
                rows={rows}
                getRowId={(row) => `${row.metricsDate}`}
                loading={isLoading}
                hideFooterPagination={true}
                experimentalFeatures={{newEditingApi: true}}
                onSelectionModelChange={([id]) => {
                    handleRowSelection(
                        _.find(rows, {metricsDate: id})
                    )
                }}
                editMode={"row"}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={() => {}}
            />
        </div>
    )
}

export default AskingPriceDetailTable