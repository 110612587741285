import {Stack} from "@mui/material";
import Filter from "./Filter";
import AskingPriceTable from "./AskingPriceTable";
import React, {useEffect, useState} from "react";
import {AskingPriceType} from "types/AskingPriceType";
import {getAskingPrices, updateAskingPrice, aggregateAskingPrice} from "../../../apis/askingPriceAPI";
import {PageType} from "types/PageType";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import _ from "lodash";
import Toolbar from "./Toolbar";
import AskingPriceEditorPopup from "./AskingPriceEditorPopup";
import {DateTime} from "luxon";

interface AskingPriceTabState {
    searchParams: any
    askingPriceMetricsPage : PageType<AskingPriceType> | null
    pageParams: any,
    askingPriceTableIsLoading: boolean
}

const initState = {
    searchParams: {
        metricsDateGoe: DateTime.local().minus({days: 6}).toISODate(),
        metricsDateLoe: DateTime.local().toISODate(),
        detailModelId: null,
        refNo: null
    },
    pageParams: {
        size: 50,
        page: 0,
        sort: 'id.metricsDate,desc'
    },
    askingPriceMetricsPage: null,
    askingPriceTableIsLoading: false,
}

const AskingPriceTab = () => {
    const [state, setState] = useState<AskingPriceTabState>(initState)
    const [editorTarget, setEditorTarget] = useState(null)

    function handleChangeSearchParams(searchParams: any) {
        setState({...state, searchParams: {...state.searchParams, ...searchParams}})
    }

    function handlePageChange(page: number) {
        setState({...state, pageParams: {...state.pageParams, page: page}})
    }

    function handlePageSizeChange(size: number) {
        setState({...state, pageParams: {...state.pageParams, size: size}})
    }

    function handleSortChange(sort: GridSortModel) {
        const field = sort?.[0]?.field
        const direction = sort?.[0]?.sort

        if (field){
            setState({...state, pageParams: {...state.pageParams, sort: `${field},${direction}`}})
        } else {
            setState({...state, pageParams: {...state.pageParams, sort: null}})
        }
    }

    function handleRowUpdate(newRow: any, oldRow: any) {
        if (!_.isEqual(newRow, oldRow)) {
            const changed = [
                newRow.maxAskingPrice != oldRow.maxAskingPrice ? 'maxAskingPrice' : null,
                newRow.minAskingPrice != oldRow.minAskingPrice ? 'minAskingPrice' : null,
                newRow.avgAskingPrice != oldRow.avgAskingPrice ? 'avgAskingPrice' : null,
            ]
            updateAskingPrice({
                ...oldRow,
                maxAskingPrice: newRow.maxAskingPrice != oldRow.maxAskingPrice ? newRow.maxAskingPrice : oldRow.maxAskingPrice,
                minAskingPrice: newRow.minAskingPrice != oldRow.minAskingPrice ? newRow.minAskingPrice : oldRow.minAskingPrice,
                avgAskingPrice: newRow.avgAskingPrice != oldRow.avgAskingPrice ? newRow.avgAskingPrice : oldRow.avgAskingPrice,
                changed: changed.filter((str) => str)
            }).then(() => {
                fetchMetrics()
            })
        }
        return {
            ...newRow,
            manualMaxAskingPrice: newRow.maxAskingPrice !=  oldRow.maxAskingPrice ? newRow.maxAskingPrice : oldRow.manualMaxAskingPrice,
            manualMinAskingPrice: newRow.minAskingPrice !=  oldRow.minAskingPrice ? newRow.minAskingPrice : oldRow.manualMinAskingPrice,
            manualAvgAskingPrice: newRow.avgAskingPrice !=  oldRow.avgAskingPrice ? newRow.avgAskingPrice : oldRow.manualAvgAskingPrice,
        }
    }

    function fetchMetrics() {
        startLoading()
        getAskingPrices({...state.searchParams, ...state.pageParams}).then((page) => {
            setState({...state, askingPriceMetricsPage: page, askingPriceTableIsLoading: false})
        })
    }

    function handleAggregate() {
        startLoading()
        aggregateAskingPrice({
            startDate: state.searchParams.metricsDateGoe,
            endDate: state.searchParams.metricsDateLoe,
            detailModelId: state.searchParams.detailModelId
        }).then(() => {
            console.log('done')
            fetchMetrics()
        })
    }


    function handleOpenEditorPopup(_editorTarget: any) {
        setEditorTarget(_editorTarget)
    }

    function startLoading() { setState({...state, askingPriceTableIsLoading: true}) }
    function endLoading() { setState({...state, askingPriceTableIsLoading: false}) }

    useEffect(() => {
        fetchMetrics()
    }, [state.searchParams, state.pageParams])

    return (
        <Stack spacing={2}>
            <Filter searchParams={state.searchParams} handleChangeSearchParams={handleChangeSearchParams}/>
            <Toolbar handleAggregate={handleAggregate}/>
            <AskingPriceTable
                rows={state.askingPriceMetricsPage?.content ?? []}
                handlePageSizeChange={ handlePageSizeChange }
                handlePageChange={ handlePageChange }
                page={state.pageParams.page}
                size={state.pageParams.size}
                totalCount={state.askingPriceMetricsPage?.totalElements ?? 0}
                isLoading={state.askingPriceTableIsLoading}
                handleSortChange={handleSortChange}
                handleRowUpdate={handleRowUpdate}
                handleOpenEditorPopup={handleOpenEditorPopup}
            />
            <AskingPriceEditorPopup
                editTarget={editorTarget}
                handleClose={() => {
                    setEditorTarget(null)
                    fetchMetrics()
                }}
            />
        </Stack>)
}

export default AskingPriceTab