import {Grid} from "@mui/material";
import TableauViz from "../../components/Tableau/TableauViz";

const DashboardSample = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableauViz
                    src={'https://prod-apnortheast-a.online.tableau.com/t/viver/views/selection-docs/Tracking'}
                    width={'100%'}
                    height={'2000'}
                    toolbar={'top'}
                />
            </Grid>
        </Grid>
    );
};
export default DashboardSample;