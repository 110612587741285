import React, { Dispatch, SetStateAction } from "react"
import { currencyFormat, numberWithCommas, toRange, truncate } from "../../../utils/NumberUtils"
import { Spacer } from "../../../components/Spacer"
import { FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material"

type InputProvisionalSettlementViewProps = {
  expectedPriceRange:{min:number, max:number};
  recommendedSellPrice:number;
  preCalculatedBasePrice:number;
  setPreCalculatedBasePrice:Dispatch<SetStateAction<number>>
  isRatio:boolean;
  setIsRatio:Dispatch<SetStateAction<boolean>>
  preSettlementValue:number;
  setPreSettlementValue:Dispatch<SetStateAction<number>>
}
export const InputProvisionalSettlementView:React.FC<InputProvisionalSettlementViewProps> = (props)=>{

  const getFinalPrice = ():string=>{

    if(props.isRatio){
      //정률, 천원 이하 절삭되도록 수정
      const price = props.preCalculatedBasePrice * (props.preSettlementValue / 100)
      return numberWithCommas((Math.floor ( (price)/1000) ) * 1000)
    }

    return numberWithCommas(props.preSettlementValue);
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2>선정산 가견적</h2>&nbsp;&nbsp;&nbsp;&nbsp;
      </div>

      <table className={'basic'}>
        <tbody>
          <td style={{height:'50px'}} colSpan={1} className={"header"}>예상 가격 범위</td>
          <td colSpan={3} style={{ textAlign:'start', padding: '9px 24px'}}>{`${toRange(
            props.expectedPriceRange.min,
            props.expectedPriceRange.max,
            4
          )} 만원`}</td>
          <td colSpan={1} className={"header"}>최적 판매가</td>
          <td colSpan={3} style={{ textAlign:'start', padding: '9px 24px'}}>{`${numberWithCommas(
            truncate(props.recommendedSellPrice, 4)
          )} 만원`}</td>
        </tbody>
      </table>
      <Spacer space={32}/>

      <table className={'basic'}>
        <tbody>
          <tr>
            <td className={'header'} colSpan={6}  style={{ textAlign:'start', padding: '10px 24px'}}>
              선정산 가견적 입력
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', padding:'15px 25px'}}>
                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'space-between'}}>
                  <Typography variant={'subtitle1'} fontWeight={'700'}>가견적 기준가</Typography>
                  <div style={{height:'40px'}}></div>
                </div>
                <TextField
                  fullWidth
                  placeholder={"0"}
                  size={"small"}
                  value={numberWithCommas(props.preCalculatedBasePrice)}
                  inputProps={{
                    style: {
                      textAlign: 'right'
                    }
                  }}
                  InputProps={{
                    endAdornment:<InputAdornment position="end">원</InputAdornment>
                  }}
                  onChange={(event) => {
                    const input = event.target.value.replace(/\,/g, '');
                    console.log(input)
                    props.setPreCalculatedBasePrice(input === '' ? 0 : parseInt(input))
                  }}
                />
              </div>
            </td>

            <td colSpan={3}>
              <div style={{display:'flex', flexDirection:'column', padding:'15px 25px'}}>
                <div style={{display:'flex', flex:1, height:'46px', alignItems:'center', justifyContent:'space-between'}}>
                  <Typography variant={'subtitle1'} fontWeight={'700'}>가견적 적용</Typography>
                  <div>
                    <RadioGroup
                      row
                      value={props.isRatio ? '1' : '2'}
                      onChange={(event)=> {
                        props.setIsRatio(event.target.value === '1')
                        props.setPreSettlementValue(0)
                      }}>
                      <FormControlLabel control={<Radio size={'small'}/>} label={'정률'} value={'1'}/>
                      <FormControlLabel control={<Radio size={'small'}/>} label={'정액'} value={'2'}/>
                    </RadioGroup>
                  </div>
                </div>
                <TextField
                  fullWidth
                  placeholder={"0"}
                  size={"small"}
                  value={props.isRatio ? props.preSettlementValue : currencyFormat(props.preSettlementValue)}
                  inputProps={{
                    style: {
                      textAlign: 'right'
                    }
                  }}
                  InputProps={{
                    endAdornment:<InputAdornment position="end">{props.isRatio ? '%' : '원'}</InputAdornment>
                  }}
                  onChange={(event) => {
                    const input = event.target.value.replace(/\,/g, '');
                    props.setPreSettlementValue(input === '' ? 0 :parseInt(input))
                  }}
                />
              </div>

            </td>
          </tr>

        <tr>
          <td colSpan={1} className={'header'}>
            최종 적용 금액
          </td>

          <td colSpan={5}  style={{ textAlign:'start', padding: '9px 24px'}}>
            <Typography variant={'subtitle1'} >
              {getFinalPrice()}원
            </Typography>
          </td>
        </tr>
        </tbody>
      </table>

    </>
  )
}
