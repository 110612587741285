import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {DatePicker} from "@mui/lab";
import {DateTime} from "luxon";
import {useState} from "react";
import {DetailModelType} from "types/DetailModelType";
import {SearchOutlined} from "@mui/icons-material";
import SearchDetailModelPopup from "../../../../components/Popup/SearchDetailModelPopup";

// @ts-ignore
const DateRangeFilter = ({searchParams, handleChangeSearchParams}) => {
    const [detailModel, setDetailModel] = useState<DetailModelType|null>(null)
    const [showDetailModelPopup, setShowDetailModelPopup] = useState(false)

    function DateRangePicker() {
        return (
                <Stack direction={"row"} justifyContent={'flex-end'} spacing={1} >
                    <DatePicker
                        label="시작 날짜"
                        value={searchParams.metricsDateGoe ? new Date(searchParams.metricsDateGoe) : new Date()}
                        onChange={(newValue) => {
                            // @ts-ignore
                            const loe = DateTime.fromISO(searchParams.metricsDateLoe) < newValue ? newValue.toISODate() : searchParams.metricsDateLoe
                            // @ts-ignore
                            handleChangeSearchParams({metricsDateGoe: newValue.toISODate(), metricsDateLoe: loe})
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="끝 날짜"
                        value={searchParams.metricsDateLoe ? new Date(searchParams.metricsDateLoe) : new Date()}
                        onChange={(newValue) => {
                            // @ts-ignore
                            const goe = DateTime.fromISO(searchParams.metricsDateGoe) > newValue ? newValue.toISODate() : searchParams.metricsDateGoe
                            // @ts-ignore
                            handleChangeSearchParams({metricsDateGoe: goe, metricsDateLoe: newValue.toISODate()})
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
        )
    }

    function ProductConditionSelector() {
        return (
            <FormControl fullWidth>
                <InputLabel>상태</InputLabel>
                <Select
                    defaultValue={searchParams.productCondition}
                    onChange={(e) => handleChangeSearchParams({productCondition: e.target.value}) }
                >
                    <MenuItem value={'중고'}>중고</MenuItem>
                    <MenuItem value={'미착용'}>미착용</MenuItem>
                </Select>
            </FormControl>)
    }

    function DetailModelSelector() {
        function handleClose(detailModel: DetailModelType) {
            setDetailModel(detailModel)
            handleChangeSearchParams({detailModelId: detailModel?.id})
            setShowDetailModelPopup(false)
        }

        return (
            <>
                <TextField
                    fullWidth
                    id="standard-bare"
                    variant="outlined"
                    label={"상세 모델"}
                    value={`${detailModel?.title ?? searchParams?.title ?? ''}` }
                    onClick={() => setShowDetailModelPopup(true)}
                    InputProps={{endAdornment: (<IconButton onClick={() => {setShowDetailModelPopup(true)}}><SearchOutlined /></IconButton>),}}
                />
                <SearchDetailModelPopup open={showDetailModelPopup} handleClose={handleClose} />
            </>
        )
    }


    return(
        <Grid container spacing={1} justifyContent={'flex-end'} direction={'row'}>
            <Grid item xs={4}><ProductConditionSelector /></Grid>
            <Grid item xs={4}><DetailModelSelector /></Grid>
            <Grid item xs={4}><DateRangePicker /></Grid>
        </Grid>)
}

export default DateRangeFilter