import { httpGet, httpPost } from "../utils/httpAPI"
import { ShippingRequestResultType, ShippingRequestType, ShippingType, UpdateTrackingNoRequest } from "types/ShippingType"
import { PageType } from "types/PageType"
import { ShippingMethod } from "../types/ShippingMethod"

export const updateTrackingNo: (shippingId: number, dto: UpdateTrackingNoRequest) => Promise<UpdateTrackingNoRequest | ShippingType>
  = (id, dto) => httpPost(`/shippings/${id}`, dto)

export const closeShipping: () => Promise<void> =
  () => httpPost(`/shippings/close`)

export const requestShipping: (shippingRequests: ShippingRequestType) => Promise<ShippingRequestResultType> = (
  shippingRequests,
) => httpPost("/shippings/request-shipping", shippingRequests)

export const revertShipping: (shippingRequests: ShippingRequestType) => Promise<ShippingRequestResultType> = (
  shippingRequests,
) => httpPost("/shippings/revert-shipping", shippingRequests)

export const getShippings: (searchParams?: any | null) => Promise<PageType<ShippingType>>
  = (searchParams) => httpGet("/shippings", searchParams)

export const getShipping: (shippingId: number) => Promise<ShippingType>
  = (id) => httpGet(`/shippings/${id}`)

export const updateShippingMethod: (shippingId: number, shippingMethod: ShippingMethod) => Promise<ShippingType>
  = (shippingId, shippingMethod) => httpPost(`/shippings/${shippingId}/change-shipping-method`, { shippingMethod })

export const updateShippingAddress: (shippingId: number, shipping: ShippingType) => Promise<ShippingType>
  = (shippingId, shipping) => httpPost(`/shippings/${shippingId}/address`,  shipping)
