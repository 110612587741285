import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { useState } from "react"

type Props = {
  handleConfirm: (keyword: string) => void
  handleClose: () => void
}

export const SearchKeywordCreatePopup = ({ handleConfirm, handleClose }: Props) => {
  const [newKeyword, setNewKeyword] = useState<string>("")

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>키워드 추가</DialogTitle>
      <DialogContent style={{ width: "400px" }}>
        <SearchInput style={{ width: "100%" }} value={newKeyword} onChange={(e) => setNewKeyword(e.target.value)} />
      </DialogContent>

      <DialogActions style={{ margin: "0 10px 10px 10px" }}>
        <Button
          disabled={!newKeyword}
          style={{ flex: 1 }}
          variant={"contained"}
          onClick={() => handleConfirm(newKeyword)}
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  )
}
