import { Button, Divider, Stack } from "@mui/material"

type Props = {
  filterPostingStatus: string | null
  handleChangePostingStatus: (postingStatus: string | null) => void
  handleCreateCuration: () => void
  handleDeleteButton: () => void
  disabledDeleteButton: boolean
}

const MdPickToolBar = ({ handleCreateCuration, handleDeleteButton, disabledDeleteButton, handleChangePostingStatus , filterPostingStatus}: Props) => {

  const selectedStyle = {
    textDecoration: 'underline',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
  };

  const unselectedStyle = {
    textDecoration: 'none',
    fontWeight: 'normal',
    backgroundColor: 'transparent'
  };

  return (

    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
      <Stack direction={'row'} justifyContent={'flex-start'}  divider={<Divider orientation="vertical" flexItem />} spacing={1}>
        <Button style={filterPostingStatus === null ? selectedStyle : unselectedStyle}    onClick={ () => {handleChangePostingStatus(null)} }>전체</Button>
        <Button style={filterPostingStatus === '노출중' ? selectedStyle : unselectedStyle}   onClick={ () => {handleChangePostingStatus('노출중')} }>노출중</Button>
        {/*<Button style={filterPostingStatus === '노출 예정' ? selectedStyle : unselectedStyle} onClick={ () => {handleChangePostingStatus('노출 예정')} }>노출 예정</Button>*/}
        <Button style={filterPostingStatus === '노출 종료' ? selectedStyle : unselectedStyle} onClick={ () => {handleChangePostingStatus('노출 종료')} }>노출 종료</Button>
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
        <Button variant={"contained"} size={"small"} onClick={handleCreateCuration}>
          추가
        </Button>
        <Button
          variant={"contained"}
          size={"small"}
          onClick={handleDeleteButton}
          color={"error"}
          disabled={disabledDeleteButton}
        >
          삭제
        </Button>
      </Stack>
    </Stack>

  )
}

export default MdPickToolBar
