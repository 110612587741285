import {DateTime} from "luxon";

export type SellOrderReviewTableFilterState = {
    page: number,
    size: number,
    createdAtGoe: string,
    createdAtLoe: string,
    sort: string,
    fullRefNo?: string,
    buyerId?: number,
    buyerName?: string,
    sellerId?: number,
    sellerName?: string,
    onDisplay?: Boolean
}

export const initSellOrderReviewTableFilter: SellOrderReviewTableFilterState = {
    page: 0,
    size: 50,
    sort: 'createdAt,desc',
    createdAtGoe: DateTime.now().minus({year: 1}).toISODate(),
    createdAtLoe: DateTime.now().plus({day: 1}).toISODate()
}

export type BuyOrderReviewTableFilterState = {
    page: number,
    size: number,
    createdAtGoe: string,
    createdAtLoe: string,
    sort: string,
    fullRefNo?: string,
    buyerId?: number,
    buyerName?: string,
    sellerId?: number,
    sellerName?: string,
    onDisplay?: Boolean
}

export const initBuyOrderReviewTableFilter: BuyOrderReviewTableFilterState = {
    page: 0,
    size: 50,
    sort: 'createdAt,desc',
    createdAtGoe: DateTime.now().minus({year: 1}).toISODate(),
    createdAtLoe: DateTime.now().plus({day: 1}).toISODate()
}

export const searchMenuList: { value: string; title: string }[] = [
    { value: "fullRefNo", title: "Full Ref No." },
    { value: "buyerId", title: "구매자 ID" },
    { value: "buyerName", title: "구매자 이름" },
    { value: "sellerId", title: "판매자 ID" },
    { value: "sellerName", title: "판매자 이름" },
    { value: "onDisplay", title: "노출여부" }
]

export type SellOrderReviewListSearchParams = {
    fullRefNo?: string,
    buyerId?: number,
    buyerName?: string,
    sellerId?: number,
    sellerName?: string,
    onDisplay?: Boolean
}

export type BuyOrderReviewListSearchParams = {
    fullRefNo?: string,
    buyerId?: number,
    buyerName?: string,
    sellerId?: number,
    sellerName?: string,
    onDisplay?: Boolean
}