import styled from "@emotion/styled"
import { css } from "@emotion/react"
import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import { UpgradeServiceOrderListItemType, UpgradeServiceOrderStatus } from "types/UpgradeServiceOrderType"
import { numberWithCommas } from "utils/NumberUtils"
import { headerItemList } from "../../TableHeader"

type Props = {
  upgradeServiceOrderItem: UpgradeServiceOrderListItemType
} & HTMLAttributes<HTMLDivElement>

const UpgradeServiceListTableRow = ({ upgradeServiceOrderItem, ...props }: PropsWithChildren<Props>) => {
  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: headerItemList[0].width }}>
        <ColumnWrapper>
          <b>{upgradeServiceOrderItem.product.id}</b>
          <div style={{ color: "#969696" }}>{upgradeServiceOrderItem.id}</div>
        </ColumnWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[1].width }}>
        <StatusBadge status={upgradeServiceOrderItem.status}>{upgradeServiceOrderItem.status}</StatusBadge>
      </TableRowItem>

      <TableRowItem style={{ maxWidth: headerItemList[2].width }}>
        <ColumnWrapper>
          <b>{upgradeServiceOrderItem.product.detailModel.title}</b>
          <div style={{ color: "#969696" }}>{upgradeServiceOrderItem.product.detailModel.titleSecondary}</div>
        </ColumnWrapper>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[3].width }}>{upgradeServiceOrderItem.orderType}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[4].width }}>{upgradeServiceOrderItem.title || "-"}</TableRowItem>
      <TableRowItem style={{ maxWidth: headerItemList[5].width }}>
        {numberWithCommas(upgradeServiceOrderItem.totalAmount)} 원
      </TableRowItem>
    </TableRow>
  )
}

export default UpgradeServiceListTableRow

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StatusBadge = styled.div<{ status: UpgradeServiceOrderStatus }>`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid;
  font-weight: 500;

  ${({ status }) => {
    switch (status) {
      case UpgradeServiceOrderStatus.결제대기:
        return css`
          border-color: #c14037;
          color: #c14037;
        `
      case UpgradeServiceOrderStatus.결제완료:
        return css`
          border-color: #3a74cd;
          color: #3a74cd;
        `

      case UpgradeServiceOrderStatus.결제만료:
        return css`
          border-color: #aaaaaa;
          color: #aaaaaa;
        `

      case UpgradeServiceOrderStatus.수리완료:
        return css`
          border-color: #aaaaaa;
          color: #aaaaaa;
        `
      case UpgradeServiceOrderStatus.수리중:
        return css`
          border-color: #3a74cd;
          color: #3a74cd;
        `

      case UpgradeServiceOrderStatus.등록완료:
        return css`
          border-color: #467b39;
          color: #467b39;
        `
    }
  }}
`
