import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpAPI"
import {PageType} from "types/PageType"
import {Article, Author, Question} from "types/MagazineType"

export const getAuthors: () => Promise<Author[]> = () => httpGet("/magazine/authors")

export const getArticles: (searchParams: any) => Promise<PageType<Article>> = (searchParams) =>
  httpGet("/magazine/articles", searchParams)
export const getArticle: (id: number) => Promise<Article> = (id) => httpGet(`/magazine/articles/${id}`)
export const createArticle: (dto: Article) => Promise<Article> = (dto) => httpPost("/magazine/articles", dto)
export const updateArticle: (id: number, dto: Article) => Promise<Article> = (id, dto) =>
  httpPut(`/magazine/articles/${id}`, dto)
export const deleteArticle: (id: number) => Promise<string> = (id) => httpDelete(`/magazine/articles/${id}`)

export const getQuestions: (searchParams: any) => Promise<PageType<Question>> = (searchParams) =>
  httpGet("/magazine/questions", searchParams)
export const getQuestion: (id: number) => Promise<Question> = (id) => httpGet(`/magazine/questions/${id}`)
export const createQuestion: (dto: Question) => Promise<Question> = (dto) => httpPost("/magazine/questions", dto)
export const updateQuestion: (id: number, dto: Question) => Promise<Question> = (id, dto) =>
  httpPut(`/magazine/questions/${id}`, dto)
