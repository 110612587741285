import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"

import { updateBrand } from "../../apis/brandAPI"
import { BrandType } from "types/BrandType"

const onlyUpperCase = (str: string): string => str.replaceAll(/[^a-zA-Z]/g, "").toUpperCase()

type AddBrandPopupProps = {
  open: boolean
  handleClose: () => void
  editBrand: BrandType
}
const initState = { onDisplay: false }

const EditBrandPopup: React.FC<AddBrandPopupProps> = ({ open, handleClose, editBrand }) => {
  const [state, setState] = useState<BrandType>(initState)

  useEffect(() => {
    setState({
      ...initState,
      koName: editBrand?.koName,
      sid: editBrand?.sid,
      name: editBrand?.name,
      onDisplay: editBrand?.onDisplay,
    })
  }, [open])

  const handleCancel = () => handleClose()
  const handleUpdate = () => {
    updateBrand(state.sid as string, state).then(() => {
      handleClose()
    })
  }

  return (
    <Dialog open={open} maxWidth={"xs"} fullWidth>
      <DialogTitle>브랜드 수정</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DialogContentText>
              추가할 브랜드의 정보를 입력 해주세요. <b>SID는 브랜드를 구분할 ID 입니다</b>. 알파벳 대문자로만 입력
              해주세요.
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"sid"}
              label={"SID"}
              placeholder={"ex) ROLEX"}
              InputLabelProps={{ shrink: true }}
              helperText={"알파벳 대문자로만 작성 해주세요."}
              onChange={(params) => {
                setState({ ...state, sid: onlyUpperCase(params.target.value) })
              }}
              value={state?.sid}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"name"}
              label={"브랜드명"}
              placeholder={"ex) 롤렉스"}
              helperText={"사용자에게 노출될 브랜드명입니다."}
              InputLabelProps={{ shrink: true }}
              onChange={(params) => setState({ ...state, name: params.target.value })}
              autoFocus
              value={state?.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id={"koName"}
              label={"한국어 브랜드명"}
              placeholder={"ex) 롤렉스"}
              helperText={"사용자에게 노출될 브랜드명입니다."}
              InputLabelProps={{ shrink: true }}
              value={state?.koName}
              onChange={(params) => setState({ ...state, koName: params.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state?.onDisplay}
                    onChange={(params) => setState({ ...state, onDisplay: params.target.checked })}
                  />
                }
                label="노출"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={"error"} onClick={handleCancel}>
          취소
        </Button>
        <Button color={"primary"} onClick={handleUpdate}>
          수정
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditBrandPopup
