import {CountType} from "types/CountType";
import {sum, find} from "lodash";
export const toApplyCounts: (counts: CountType[], statusCodes: any[], path: string, params?: string[], other?: boolean) => CountType[]
    = (counts, statusCodes= [], path, params = [], other = false) => {

    const result: CountType[] = statusCodes.map(code => {
        const counter = find(counts, ['label', code])

        return {
            label: counter?.label,
            count: counter?.count ?? 0,
            link: `${path}?status=${counter?.label}` + params.map(it => `&${it}`),
            selected: false
        }
    })
    .filter(counter => counter)

    const others = counts
        .filter(original => statusCodes.findIndex(it => it == original.label) == -1)

    result.unshift({label: '전체', count: sum(counts.map(it => it.count)), link: path, selected: true})
    if (other) result.push({label: '기타', count: sum(others.map(it => it.count)), link: `${path}?status=${others.map(it => it.label).join(",")}`, selected: false})

    return result
}