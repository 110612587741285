import {Stack} from "@mui/material";
import CurationToolbar from "./CurationToolbar";
import CurationTable from "./CurationTable";


const CurationTab = () => {
   return (
       <Stack direction={'column'} spacing={1}>
          <CurationToolbar
            handleCreateCuration={() => {}}
            handleDeleteButton={() => {}}
            handleReorderCuration={() => {}}
            disabledDeleteButton={true}
          />
          <CurationTable
              handleSelection={() => {}}
              handleUpdateCuration={() => {}}
              refreshCounter={1}
          />
       </Stack>
   )
}

export default CurationTab