import { Box, Button, Stack } from "@mui/material"
import BannerTableFilter from "./BannerTableFilter"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import ShopContentsAPI from "../../../apis/shopHeroBannerAPI"
import { BannerTypeCode, ShopHeroBannerType } from "types/ShopHeroBannerType"
import EditIcon from "@mui/icons-material/Edit"
import { PageType } from "../../../types/PageType"

function renderPreviewCell(params: any) {

    let previewContent;

    // if (params?.row?.bannerType === BannerTypeCode.HTML) {
    //     previewContent = <div dangerouslySetInnerHTML={{ __html: params?.row?.bannerBody }} />;
    // } else
    if (params?.row?.bannerType === BannerTypeCode.IMAGE) {
        previewContent = <img src={params?.row?.imageUrl} alt="미리 보기" style={{ width: '100%', position: 'absolute' }} />;
    } else if (params?.row?.bannerType === BannerTypeCode.VIDEO) {
        previewContent = <img src={params?.row?.thumbnailUrl} alt="미리 보기" style={{ width: '100%', position: 'absolute' }} />;
        // return (
        //   <video style={{ width: "100%", maxWidth: 100, height: 50, objectFit: "contain" }} autoPlay loop playsInline>
        //       <source src={params?.row?.videoUrl} />
        //   </video>);
    }

    return (
      <Box style={{ position: 'relative', height: 100, width: 50, paddingTop: 20 }}>
          {previewContent}
      </Box>
    );
}

function renderStatusCell(params: any) {
    if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
    const startedAt = DateTime.fromISO(params?.row?.startedAt)
    const endedAt = DateTime.fromISO(params?.row?.endedAt)

    return (
        <Stack direction={'column'} alignItems={"self-end"}>
            <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
            <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
        </Stack>
    )
}

interface BannerTableFilterState {
    postingStatus: string | null,
    page: number | null,
    size: number | null
}

const initBannerTableFilter: BannerTableFilterState = {
    postingStatus: null,
    page: 0,
    size: 50
}

//@ts-ignore
const BannerTable = ({handleSelection, handleUpdateBanner, refreshCounter}) => {
    const [tableFilter, setTableFilter] = useState<BannerTableFilterState>(initBannerTableFilter)
    const [banners, setBanners] = useState<PageType<ShopHeroBannerType>>({content: []})

    const getColumns = () => {
        return [
            {
                field: 'actions',
                type: 'actions',
                width: 10,
                getActions: (params: any) => ([
                    <GridActionsCellItem
                        icon = {<EditIcon />}
                        label = {"수정"}
                        onClick={() => {
                            handleUpdateBanner(params.row)
                        }}
                        showInMenu
                    />
                ])

            },
            {field: 'id', headerName: "ID", width: 70},
            {field: 'priority', headerName: "노출순서", width: 100},
            {field: 'description', headerName: "설명", flex: 3},
            {field: 'createdAt', headerName: "등록 일시", width: 180, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
            {field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
            {field: 'preview', headerName: "미리보기", width: 100, renderCell: renderPreviewCell},
            {field: 'postingStatus', headerName: "진행 상태", width: 100,  renderCell: renderStatusCell},
            {field: 'showDomesticUser',  width: 80,
              renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
                </div>
              ),
              renderHeader: () => (
                <div style={{  textAlign: "center", lineHeight: "1.2" }}>
                      내국인 회원<br />노출
                </div>
              ),
            },
            { field: "showForeignUser", width: 80,
                renderCell: (params: any) => (
                  <div
                    style={{ textAlign: "center"  }}
                  >
                    <span>{params.row.showForeignUser ? "Y" : "N"}</span>
                  </div>
                ),
              renderHeader: () => (
                <div style={{ textAlign: "center", lineHeight: "1.2" }}>
                  외국인 회원<br />노출
                </div>
              ),
            }


        ]
    }

  const fetch = (tableFilter: any = {}) => {
        ShopContentsAPI.getPage(tableFilter).then(setBanners)
    }


    useEffect(() => {
        fetch(tableFilter)
    }, [tableFilter, refreshCounter])

    return (
        <Stack spacing={2}>
            <BannerTableFilter
                filter ={tableFilter}
                handleFilterChange={(changedFilter: BannerTableFilterState) => { setTableFilter(changedFilter) }}
            />
            <Box sx={{height: 720, width: '100%'}}>
                <DataGrid
                    getRowId={(row) => row.id!!}
                    columns={getColumns()}
                    getRowHeight={() => 100}
                    onSelectionModelChange={handleSelection}
                    checkboxSelection

                    paginationMode="server"
                    onPageChange={(page) => {
                        setTableFilter({...tableFilter, page: page})
                    }}
                    onPageSizeChange={(size) => {
                        setTableFilter({...tableFilter, size: size})
                    }}

                    page={banners.pageable?.pageNumber}
                    pageSize={banners.pageable?.pageSize}
                    rowCount={banners?.totalElements}
                    rows={banners?.content}
                />
            </Box>
        </Stack>
    )
}

export default BannerTable