import {Button, Stack} from "@mui/material";


// @ts-ignore
const BannerToolbar = ({handleCreateBanner, handleReorderBanner, handleDeleteButton, disabledDeleteButton}) => {
    return(
        <Stack direction={"row"} justifyContent={'flex-end'} spacing={2}>
            <Button variant={'contained'} size={'small'} onClick={handleReorderBanner}>히어로 배너 순서 변경</Button>
            <Button variant={'contained'} size={'small'} onClick={handleCreateBanner}>추가</Button>
            <Button variant={'contained'} size={'small'} onClick={handleDeleteButton} color={'error'} disabled={disabledDeleteButton}>삭제</Button>
        </Stack>
    )
}

export default BannerToolbar