import styled from "@emotion/styled"
import { useUpgradeServiceDetail } from "./useUpgradeServiceDetail"
import PopupPage from "components/Popup/PopupPage"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import DetailTableColumCell from "components/Table/Detail/TableBody/TableCell/Column"
import { isNil } from "utils/validationUtils"
import { toDateTimeStr } from "utils/datetimeUtil"
import Button from "components/Button"
import { useMemo, useState } from "react"
import { UpgradeItemModal } from "../UpgradeItem/UpgradeItemModal"
import { UpgradeItemTable } from "../UpgradeItem/UpgradeItemTable"
import { isEmpty } from "lodash"
import { ModalButtonListByStatus } from "./ModalButtonListByStatus"
import { UserFindModal } from "../UpgradeServiceRegisterModal/UserFinderModal"
import ProductSelectPopup from "components/Popup/ProductSelectPopup"
import { UpgradeServiceOrderStatus } from "types/UpgradeServiceOrderType"

type Props = {
  orderId: string
  closeModal: () => void
}

export const UpgradeServiceOrderDetailModal = ({ closeModal, orderId }: Props) => {
  const [isOpenUpgradeItemModal, setIsOpenUpgradeItemModal] = useState(false)
  const [isOpenUserFinderPopup, setIsOpenUserFinderPopup] = useState<boolean>(false)
  const [isOpenProductSelectPopup, setIsOpenProductSelectPopup] = useState<boolean>(false)

  const {
    upgradeServiceDetail,
    upgradeItemList,
    selectedUpgradeItemIndexList,
    isAllChecked,
    addUpgradeItemListstate,
    deleteUpgradeItemListState,
    updateUpgradeServiceItemList,
    toggleUpgradeItem,
    notifyUpgradePaymentToUser,
    requestStartUpgradeService,
    requestFinishUpgradeService,
    requestExpireUpgradeService,
    requestDeleteUpgradeService,
    toggleAllUpgradeItem,
    onEditUpgradeServiceItem,
    setUpgradeServiceDetail,
    onSelectProduct,
  } = useUpgradeServiceDetail(orderId)

  const convertEmptyValueToDash = (value: any) => {
    return isNil(value) ? "-" : value
  }

  const isEditable = useMemo(() => {
    if (!upgradeServiceDetail) return false

    return upgradeServiceDetail.status === UpgradeServiceOrderStatus.등록완료
  }, [upgradeServiceDetail])

  const requestDeleteUpgradeServiceAndCloseModal = async () => {
    await requestDeleteUpgradeService()
    closeModal()
  }

  if (!upgradeServiceDetail) return null

  return (
    <>
      {isOpenUpgradeItemModal && (
        <UpgradeItemModal
          upgradeItemList={upgradeItemList}
          onRegister={(item) => addUpgradeItemListstate(item)}
          closeModal={() => setIsOpenUpgradeItemModal(false)}
        />
      )}
      {isOpenUserFinderPopup && (
        <UserFindModal
          onSubmit={(user) => {
            setUpgradeServiceDetail((prev) => {
              if (!prev) {
                return
              }
              return { ...prev, user }
            })
          }}
          closeModal={() => setIsOpenUserFinderPopup(false)}
        />
      )}
      {isOpenProductSelectPopup && (
        <ProductSelectPopup
          isMultiSelectable={false}
          isSearchable
          open={isOpenProductSelectPopup}
          setProducts={(productList) => {
            onSelectProduct(productList[0])
          }}
          close={()=> {
            setIsOpenProductSelectPopup(false)
          }}
        />
      )}
      <PopupPage isOpen closePage={closeModal} style={{ width: "1016px", height: "90vh" }}>
        <ContentWrapper>
          <RowWrapper
            style={{
              justifyContent: "space-between",
            }}
          >
            <PopupPageTitle>수리 주문 관리</PopupPageTitle>

            <RowWrapper style={{ border: "1px solid #CCCCCC", width: "312px" }}>
              <DetailTableColumCell title="수리 주문번호" style={{ borderRight: "1px solid #CCCCCC" }}>
                <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.id)}</ValueWrapper>
              </DetailTableColumCell>
              <DetailTableColumCell title="상태">
                <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.status)}</ValueWrapper>
              </DetailTableColumCell>
            </RowWrapper>
          </RowWrapper>
          <PopupPageScrollContainer>
            <h3>수리 주문 정보</h3>
            <RowWrapper>
              <RowWrapper style={{ border: "1px solid #CCCCCC" }}>
                <DetailTableColumCell title="수리유형" style={{ borderRight: "1px solid #CCCCCC" }}>
                  <ValueWrapper>{upgradeServiceDetail.id}</ValueWrapper>
                </DetailTableColumCell>
                <DetailTableColumCell title="상품번호">
                  <ValueWrapper
                    onClick={() => {
                      if (isEditable) setIsOpenProductSelectPopup(true)
                    }}
                  >
                    {!upgradeServiceDetail.product ? (
                      <ProductSelectButton>상품 번호 선택</ProductSelectButton>
                    ) : (
                      <ChangeableText>{convertEmptyValueToDash(upgradeServiceDetail.product.id)}</ChangeableText>
                    )}
                  </ValueWrapper>
                </DetailTableColumCell>
              </RowWrapper>
            </RowWrapper>
            <h3>주문 정보</h3>
            <RowWrapper style={{ border: "1px solid #CCCCCC" }}>
              <ThumnailImageWrapper style={{ borderRight: "1px solid #CCCCCC" }}>
                <ThumnailImage
                  style={{
                    justifyContent: "center",
                  }}
                  src={upgradeServiceDetail.product.detailModel.thumbnail}
                  alt="모델이미지"
                />
              </ThumnailImageWrapper>

              <ColumnWrapper>
                <DetailTableColumCell title="상품명">
                  <RowWrapper>
                    <ValueWrapper>
                      {convertEmptyValueToDash(upgradeServiceDetail.product.detailModel.brand.name)}
                    </ValueWrapper>
                    <ValueWrapper>
                      {convertEmptyValueToDash(upgradeServiceDetail.product.detailModel.title)}
                    </ValueWrapper>
                    <ValueWrapper>
                      {convertEmptyValueToDash(upgradeServiceDetail.product.detailModel.titleSecondary)}
                    </ValueWrapper>
                  </RowWrapper>
                </DetailTableColumCell>
                <RowWrapper>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="Ref No.">
                    <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.id)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="시리얼 넘버">
                    <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.product.serialNo)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="회원 ID">
                    <ValueWrapper
                      onClick={() => {
                        if (isEditable) setIsOpenUserFinderPopup(true)
                      }}
                    >
                      {isNil(upgradeServiceDetail.user) ? (
                        <ProductSelectButton>회원 검색하기</ProductSelectButton>
                      ) : (
                        <ChangeableText>{convertEmptyValueToDash(upgradeServiceDetail.user.id)} </ChangeableText>
                      )}
                    </ValueWrapper>
                  </DetailTableColumCell>
                </RowWrapper>

                <RowWrapper>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="회원명">
                    <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.user.name)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="연락처">
                    <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.user.phone)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="바이버 계정">
                    <ValueWrapper>{convertEmptyValueToDash(upgradeServiceDetail.user.email)}</ValueWrapper>
                  </DetailTableColumCell>
                </RowWrapper>

                <RowWrapper>
                  <DetailTableColumCell style={{ borderRight: "1px solid #CCCCCC" }} title="수리 접수일">
                    <ValueWrapper>
                      <ValueWrapper>{toDateTimeStr(upgradeServiceDetail.createdAt)}</ValueWrapper>
                    </ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="결제일" style={{ borderRight: "1px solid #CCCCCC" }}>
                    <ValueWrapper>{toDateTimeStr(upgradeServiceDetail.paymentAt)}</ValueWrapper>
                  </DetailTableColumCell>
                  <DetailTableColumCell title="수리 완료일">
                    <ValueWrapper>{toDateTimeStr(upgradeServiceDetail.completeUpgradeAt)}</ValueWrapper>
                  </DetailTableColumCell>
                </RowWrapper>
              </ColumnWrapper>
            </RowWrapper>

            <RowWrapper style={{ margin: "10px 0", justifyContent: "space-between" }}>
              <h3>수리 항목</h3>
              <ButtonListWrapper>
                <ButtonWrapper>
                  <Button
                    disabled={!isEditable || isEmpty(selectedUpgradeItemIndexList)}
                    onClick={deleteUpgradeItemListState}
                    buttonSize="M"
                    buttonStyle="red-fill"
                  >
                    삭제하기
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button disabled={!isEditable} onClick={() => setIsOpenUpgradeItemModal(true)} buttonSize="M">
                    생성하기
                  </Button>
                </ButtonWrapper>
              </ButtonListWrapper>
            </RowWrapper>

            <UpgradeItemWrapper>
              <UpgradeItemTable
                onEditUpgradeServiceItem={onEditUpgradeServiceItem}
                isAllChecked={isAllChecked}
                toggleAllUpgradeItem={toggleAllUpgradeItem}
                upgradeItemList={upgradeItemList}
                selectedUpgradeItemIndexList={selectedUpgradeItemIndexList}
                selectRow={toggleUpgradeItem}
              />
            </UpgradeItemWrapper>
          </PopupPageScrollContainer>
          <PopupPageFooter>
            <ModalButtonListByStatus
              upgradeServiceStatus={upgradeServiceDetail.status}
              isNotified={!!upgradeServiceDetail.notifiedAt}
              closeModal={closeModal}
              updateUpgradeServiceItemList={updateUpgradeServiceItemList}
              notifyUpgradePaymentToUser={notifyUpgradePaymentToUser}
              requestStartUpgradeService={requestStartUpgradeService}
              requestFinishUpgradeService={requestFinishUpgradeService}
              requestExpireUpgradeService={requestExpireUpgradeService}
              requestDeleteUpgradeService={requestDeleteUpgradeServiceAndCloseModal}
            />
          </PopupPageFooter>
        </ContentWrapper>
      </PopupPage>
    </>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ValueWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 6px;
`

const UpgradeItemWrapper = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  width: 70px;

  :not(:first-child) {
    margin-left: 8px;
  }
`

const ButtonListWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const ThumnailImage = styled.img`
  width: 234px;
  height: 234px;
`

const ThumnailImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChangeableText = styled.div`
  color: #3a74cd;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
`

const ProductSelectButton = styled.div`
  padding: 10px 16px;
  border-radius: 4px;

  border: 1px solid #d9d9d9;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  :hover {
    border-color: #333333;
    cursor: pointer;
  }
`
