import { DiagnosisIssueHistoryAction, DiagnosisIssueHistoryState } from "./index"
import React from "react"
import { Grid } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { DiagnosisIssueHistoryType, DiagnosisIssueListType } from "../../../types/DiagnosisType"
import { toDateTimeStr } from "../../../utils/datetimeUtil"
import { getSellOrder } from "../../../apis/sellOrderAPI"

type IssueHistoryProps = {
  state: DiagnosisIssueHistoryState
  dispatch: React.Dispatch<DiagnosisIssueHistoryAction>
}

function createColumns(dispatch: any): any[] {
  const openBuyOrderPopup = (row: DiagnosisIssueHistoryType) => {
    dispatch({ type: "TOGGLE_BUY_ORDER_POPUP", payload: row })
  }
  const openDiagnosisPopup = async (row: DiagnosisIssueHistoryType) => {
    dispatch({ type: "START_LOADING" })

    await getSellOrder(row.sellOrderId)
      .then((results) => {
        return dispatch({ type: "FETCH_SELL_ORDER", payload: results })
      })
      .finally(() => dispatch({ type: "END_LOADING" }))

    dispatch({ type: "TOGGLE_DIAGNOSIS_POPUP", payload: row })
  }
  const openDiagnosisPrintPopup = (row: DiagnosisIssueHistoryType) => {
    dispatch({ type: "TOGGLE_DIAGNOSIS_PRINT_POPUP", payload: row })
  }

  const openProductPopup = (row: DiagnosisIssueListType) => {
    dispatch({ type: "TOGGLE_PRODUCT_POPUP", payload: row })
  }

  return [
    {
      field: "diagnosisId",
      headerName: "진단서 번호",
      headerAlign: "center",
      align: "center",
      maxWidth: 80,
      renderCell: (params: any) =>
        !params.row.diagnosisId ? (
          <div>
            <span>-</span>
          </div>
        ) : (
          <div
            style={{ textAlign: "left", textDecoration: "underline" }}
            onClick={() => openDiagnosisPopup(params.row)}
          >
            <span style={{ fontWeight: "bold" }}>{params.row.diagnosisId}</span>
          </div>
        ),
    },
    {
      field: "verifyNo",
      headerName: "Verify No.",
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <div
          style={{ textAlign: "left", textDecoration: "underline" }}
          onClick={() => openDiagnosisPrintPopup(params.row)}
        >
          <span style={{ fontWeight: "bold" }}>{params.row.verifyNo}</span>
        </div>
      ),
      minWidth: 140,
    },
    {
      field: "productId",
      headerName: "상품번호",
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) =>
        !params.row.productId ? (
          <div>
            <span>-</span>
          </div>
        ) : (
          <div style={{ textAlign: "left", textDecoration: "underline" }} onClick={() => openProductPopup(params.row)}>
            <span style={{ fontWeight: "bold" }}>{params.row.productId}</span>
          </div>
        ),
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "saleType",
      headerName: "판매유형",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      maxWidth: 140,
      valueGetter: (params: any) => `${!params.row.saleType ? "-" : params.row.saleType}`,
    },
    {
      field: "diagnosisType",
      headerName: "진단유형",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.diagnosisType === true ? "정밀" : "일반"}`,
    },
    {
      field: "productName",
      headerName: "상품명",
      headerAlign: "center",
      align: "center",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "serialNo",
      headerName: "시리얼 No.",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      valueGetter: (params: any) => `${!params.row.serialNo ? "-" : params.row.serialNo}`,
    },
    {
      field: "buyOrderId",
      headerName: "구매주문번호",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 130,
      maxWidth: 160,
      renderCell: (params: any) =>
        !params.row.buyOrderId ? (
          <div>
            <span>-</span>
          </div>
        ) : (
          <div style={{ textAlign: "left", textDecoration: "underline" }} onClick={() => openBuyOrderPopup(params.row)}>
            <span style={{ fontWeight: "bold" }}>{params.row.buyOrderId}</span>
          </div>
        ),
    },
    {
      field: "buyerId",
      headerName: "구매자 ID",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "sellerId",
      headerName: "판매자 ID",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "issuedByNickName",
      headerName: "담당자",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "issuedAt",
      headerName: "발급일시",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 200,
      maxWidth: 200,
      valueGetter: (params: any) => toDateTimeStr(params.value),
    },
  ]
}

const IssueHistoryTable: React.FC<IssueHistoryProps> = ({ state, dispatch }) => {
  return (
    <Grid container spacing={1} sx={{ pt: 1 }}>
      <Grid item xs={12}>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.id!!}
            columns={createColumns(dispatch)}
            pagination
            paginationMode="server"
            onPageChange={(page) => {
              dispatch({ type: "CHANGE_PAGE_NUMBER", payload: page })
            }}
            onPageSizeChange={(size) => {
              dispatch({ type: "CHANGE_PAGE_SIZE", payload: size })
            }}
            page={state.pageNumber || 0}
            pageSize={state.pageSize || 25}
            rowCount={state.diagnosesHistory?.totalElements || 0}
            rows={state.diagnosesHistory?.content || []}
            selectionModel={state.selectedId}
            // checkboxSelection
            disableSelectionOnClick={true}
            loading={state.loading}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default IssueHistoryTable
