import PopupPage from "components/Popup/PopupPage"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import Button from "components/Button"
import styled from "@emotion/styled"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { toDateStr } from "utils/datetimeUtil"
import { DateTime } from "luxon"
import { FormControl, MenuItem, Select } from "@mui/material"
import { useMemo } from "react"
import { CouponAvailableDateTypeCode, CouponCodeStatusCode, CouponCodeType, CouponTypeCode } from "types/CouponCodeType"
import { useEditCouponCodeData } from "./useEditCouponCodeData"

const couponStatusOption: { value: CouponCodeStatusCode; title: string }[] = [
  {
    value: CouponCodeStatusCode.활성,
    title: "활성",
  },
  {
    value: CouponCodeStatusCode.취소,
    title: "취소",
  },
]
const couponTypeOption: { value: CouponTypeCode; title: string }[] = [
  {
    value: CouponTypeCode.포인트지급,
    title: "포인트지급",
  },
]
const couponAvailableDateTypeOption: { value: CouponAvailableDateTypeCode; title: string }[] = [
  {
    value: CouponAvailableDateTypeCode.만료일_직접_지정,
    title: "만료일 직접 지정",
  },
  {
    value: CouponAvailableDateTypeCode.쿠폰_사용일_기준,
    title: "쿠폰 사용일 기준",
  },
]

type Props = {
  selectedCouponCode?: CouponCodeType
  isOpen: boolean
  closePopup: () => void
  fetchCouponCodeList: () => void
}
type CouponCodeEditorType = "CREATE" | "EDIT"

const EditCouponCodePopup = ({ selectedCouponCode, isOpen, closePopup, fetchCouponCodeList }: Props) => {
  const editorType = useMemo((): CouponCodeEditorType => {
    return !selectedCouponCode ? "CREATE" : "EDIT"
  }, [selectedCouponCode])

  const onFinishRequest = () => {
    closePopup()
    fetchCouponCodeList()
  }

  const { couponCodeState, changeCouponCodeState, onSubmit } = useEditCouponCodeData({
    editorType,
    editingCouponCode: selectedCouponCode,
    onFinishRequest,
  })

  const handleInputChange = (e: any) => {
    let value = e.target.value

    // 띄어쓰기 제거
    value = value.replace("_", "")
    value = value.replace(/\s+/g, "")

    // 영문을 대문자로 변환
    value = value.toUpperCase()

    // 특수 문자 제거
    value = value.replace(/[^\wㄱ-힣]/g, "")

    // 최대 글자수 12자로 제한
    if (value.length > 12) {
      value = value.substring(0, 12)
    }

    changeCouponCodeState("code", value)
  }

  return (
    <PopupPage isOpen={isOpen} style={{ width: "500px", height: "850px" }}>
      <ContentWrapper>
        <PopupPageTitle>쿠폰 코드 {editorType === "CREATE" ? "생성" : "수정"}</PopupPageTitle>
        <PopupPageScrollContainer>
          <OptionWrapper>
            <h3>쿠폰 코드</h3>
            <SearchInput
              disabled={editorType === "EDIT"}
              placeholder={"미입력시 임의의 코드가 발급됩니다."}
              value={couponCodeState.code}
              onChange={handleInputChange}
              onPaste={(e) => {
                e.preventDefault()
                const paste = e.clipboardData.getData("text")
                let value = paste
                  .replace(/\s+/g, "")
                  .toUpperCase()
                  .replace(/[^A-Z0-9가-힣]/g, "")
                if (value.length > 12) {
                  value = value.substring(0, 12)
                }
                changeCouponCodeState("code", value)
              }}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>쿠폰 이름</h3>
            <SearchInput
              disabled={editorType === "EDIT"}
              placeholder={"쿠폰의 이름을 입력하세요."}
              value={couponCodeState.name}
              onChange={(e) => changeCouponCodeState("name", e.target.value)}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>쿠폰 상태</h3>
            <FormControl>
              <Select
                size={"small"}
                value={couponCodeState.status}
                required
                onChange={(e) => {
                  changeCouponCodeState("status", e.target.value as CouponCodeStatusCode)
                }}
                MenuProps={{
                  style: { zIndex: 10001 },
                }}
              >
                {couponStatusOption.map((menu) => (
                  <MenuItem value={menu.value} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </OptionWrapper>
          <OptionWrapper>
            <h3>쿠폰 타입</h3>
            <FormControl>
              <Select
                disabled={editorType === "EDIT"}
                size={"small"}
                value={couponCodeState.type}
                required
                onChange={(e) => {
                  changeCouponCodeState("type", e.target.value as CouponTypeCode)
                }}
                MenuProps={{
                  style: { zIndex: 10001 },
                }}
              >
                {couponTypeOption.map((menu) => (
                  <MenuItem value={menu.value} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </OptionWrapper>
          <OptionWrapper>
            <h3>최대 발행 수량</h3>
            <SearchInput
              disabled={editorType === "EDIT"}
              value={couponCodeState.maxUsageCount}
              onChange={(e) => {
                const inputValue = Number(e.target.value)

                if (isNaN(inputValue)) {
                  return
                }

                changeCouponCodeState("maxUsageCount", inputValue)
              }}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>지급 포인트</h3>
            <SearchInput
              disabled={editorType === "EDIT"}
              value={couponCodeState.points}
              onChange={(e) => {
                const inputValue = Number(e.target.value)

                if (isNaN(inputValue)) {
                  return
                }

                changeCouponCodeState("points", inputValue)
              }}
            />
          </OptionWrapper>

          <OptionWrapper>
            <h3>만료일 지정 방식</h3>
            <FormControl>
              <Select
                disabled={editorType === "EDIT"}
                size={"small"}
                value={couponCodeState.availableDateType}
                required
                onChange={(e) => {
                  changeCouponCodeState("availableDateType", e.target.value as CouponAvailableDateTypeCode)
                }}
                MenuProps={{
                  style: { zIndex: 10001 },
                }}
              >
                {couponAvailableDateTypeOption.map((menu) => (
                  <MenuItem value={menu.value} key={menu.value}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </OptionWrapper>
          <OptionWrapper>
            <h3>만료 예정일</h3>
            {couponCodeState.availableDateType == CouponAvailableDateTypeCode.만료일_직접_지정 ? (
              <DatePicker
                disabled={editorType === "EDIT"}
                type="date"
                value={couponCodeState.availableDate}
                min={toDateStr(DateTime.now().toISO(), true)}
                onChange={(e) => {
                  changeCouponCodeState("availableDate", toDateStr(e.target.value, true) || "")
                }}
              />
            ) : (
              <SearchInput
                disabled={editorType === "EDIT"}
                value={couponCodeState.availableDays}
                onChange={(e) => {
                  const inputValue = Number(e.target.value)

                  if (isNaN(inputValue)) {
                    return
                  }

                  changeCouponCodeState("availableDays", inputValue)
                }}
              />
            )}
          </OptionWrapper>
          <OptionWrapper>
            <h3>발행 시작일시</h3>
            <DatePicker
              type="datetime-local"
              value={
                couponCodeState.startedAt
                  ? DateTime.fromISO(couponCodeState.startedAt).toLocal().toISO().split(".")[0]
                  : ""
              }
              min={toDateStr(DateTime.now().toISO(), true)}
              onChange={(e) => {
                changeCouponCodeState("startedAt", DateTime.fromISO(e.target.value).toISO())
              }}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>발행 종료일시</h3>
            <DatePicker
              type="datetime-local"
              value={
                couponCodeState.endedAt ? DateTime.fromISO(couponCodeState.endedAt).toLocal().toISO().split(".")[0] : ""
              }
              min={toDateStr(DateTime.now().toISO(), true)}
              onChange={(e) => {
                changeCouponCodeState("endedAt", DateTime.fromISO(e.target.value).toISO())
              }}
            />
          </OptionWrapper>
          <OptionWrapper>
            <h3>발급 조건 추가</h3>
            <div className="condition-row">
              <span>가입일 기준</span>
              <SearchInput
                type="number"
                value={couponCodeState.signupBasedAvailableDays}
                onChange={(e) => {
                  let valStr = e.target.value.replace(/\D/g, "").slice(0, 5)

                  const value = Number(valStr)

                  if (isNaN(value) || value <= 0) {
                    changeCouponCodeState("signupBasedAvailableDays", undefined)
                  } else {
                    changeCouponCodeState("signupBasedAvailableDays", value)
                  }
                }}
              />
              <span>일 이내</span>
            </div>
          </OptionWrapper>
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <ButtonListWrapper>
            <ButtonWrapper style={{ width: "80px" }}>
              <Button onClick={onSubmit}>{editorType === "CREATE" ? "생성" : "수정"}</Button>
            </ButtonWrapper>
            <ButtonWrapper style={{ width: "80px" }}>
              <Button buttonStyle="blue-border" onClick={closePopup}>
                닫기
              </Button>
            </ButtonWrapper>
          </ButtonListWrapper>
        </PopupPageFooter>
      </ContentWrapper>
    </PopupPage>
  )
}

export default EditCouponCodePopup

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`

const ButtonWrapper = styled.div`
  :not(:last-child) {
    margin-right: 10px;
  }
`

const ButtonListWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h3 {
    margin: 5px 0;
    font-size: 16px;
  }

  .condition-row {
    display: flex;
    align-items: center;

    span {
      margin: 0 5px;
      white-space: nowrap;
    }

    input[type="number"] {
      text-align: right;
      height: 100%;
      border: none;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      -moz-appearance: textfield;

      &:focus {
        outline: none;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const DatePicker = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid #eee;
  outline: none;
  border-radius: 4px;
  padding: 10px;
`
