import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import {
  getTranslationColumns,
  getTranslationKeys,
  getTranslationManagedTables,
  getTranslationTables,
} from "../../apis/translationAPI"
import { TranslationTableType } from "../../types/TranslationTableType"
import { PageType } from "../../types/PageType"
import TranslationManagerSearchBar from "./TranslationManagerSearchBar"
import { TranslationKeyType } from "../../types/TranslationKeyType"
import TranslationManagerTable from "./TranslationManagerTable"
import { TranslationTableColumnType } from "../../types/TranslationTableColumnType"

type SchemaType = "viver"
export type LanguageType =
  | { key: "all"; value: "전체" }
  // | { key: "ko"; value: "한국어" }
  | { key: "en"; value: "영어" }
  | { key: "ja"; value: "일본어" }

/**
 * TranslationColumnManagerState 타입 정의
 * 번역 열 관리자의 상태를 나타냄
 */
export type TranslationColumnManagerState = {
  schema: SchemaType // 데이터베이스 스키마 이름
  tableName: string // 데이터베이스 테이블 이름
  columnName: string
  language: LanguageType // 선택된 언어 (한국어, 영어, 일본어)
  searchKey: string // 검색 키
  searchContent: string // 검색 내용
  searchTranslationContent: string
  tableList: TranslationTableType[] // 번역 테이블 목록
  columnList: TranslationTableColumnType[] // 번역 테이블 목록
  languageList: LanguageType[]
  tableDetailList: PageType<TranslationKeyType> // 번역 테이블 세부 목록
  columnNameList: string[] // 열 이름 목록
  refresh: boolean // 상태 새로고침 플래그
  page: number // 현재 페이지 번호
  size: number // 페이지당 항목 수
}

const initialState: TranslationColumnManagerState = {
  schema: "viver",
  tableName: "",
  columnName: "all",
  language: { key: "en", value: "영어" },
  searchKey: "",
  searchContent: "",
  searchTranslationContent: "",
  tableList: [],
  tableDetailList: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  columnList: [],
  languageList: [
    { key: "all", value: "전체" },
    //{ key: "ko", value: "한국어" },
    { key: "en", value: "영어" },
    { key: "ja", value: "일본어" },
  ],
  columnNameList: [],
  refresh: false,
  page: 0,
  size: 25,
}

const TranslationManager = () => {
  const [state, setState] = useState<TranslationColumnManagerState>(initialState)

  const fetchTableDetails = () => {
    getTranslationKeys({
      table: state.tableName,
      column: state.columnName === "all" ? null : state.columnName,
      key: state.searchKey,
      language: state.language.key === "all" ? null : state.language.key,
      content: state.searchContent,
      translationContent: state.searchTranslationContent,
      page: state.page,
      size: state.size,
    }).then((result) => {
      setState((prevState) => ({
        ...prevState,
        tableDetailList: result,
      }))
    })
  }

  const fetchTables = () => {
    getTranslationManagedTables({
      schema: state.schema,
    }).then((result) => {
      setState((prevState) => ({
        ...prevState,
        tableList: result,
        tableName: result[0].tableName,
      }))
    })
  }

  const fetchColumns = () => {
    getTranslationColumns({
      tableName: state.tableName,
    }).then((result) => {
      setState((prevState) => ({
        ...prevState,
        columnList: result,
      }))
    })
  }

  useEffect(() => {
    fetchTables()
  }, [state.schema])

  useEffect(() => {
    if (state.tableName) {
      fetchTableDetails()
    }
  }, [state.columnName])

  useEffect(() => {
    if (state.tableName) {
      fetchColumns()
      fetchTableDetails()
    }
  }, [state.tableName, state.language, state.page, state.size, state.refresh])

  return (
    <>
      <PageHeaderWrapper>
        <TranslationManagerSearchBar state={state} dispatch={setState} />
      </PageHeaderWrapper>
      <PageBodyWrapper>
        <TranslationManagerTable state={state} dispatch={setState} />
      </PageBodyWrapper>
    </>
  )
}

export default TranslationManager

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
`

const PageBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
`
