import { UserDetailType } from "../../../../types/UserType"
import { UserCollectionType } from "../../../../types/UserCollectionType"
import { DetailModelType } from "../../../../types/DetailModelType"
import React from "react"
import { truncateWithCommas } from "../../../../utils/NumberUtils"
import { toDateTimeStr } from "../../../../utils/datetimeUtil"
import { Checkbox, FormControlLabel, ImageList, ImageListItem, Link } from "@mui/material"

type Props = {
  user?: UserDetailType
  userCollection?: UserCollectionType
  detailModel?: DetailModelType
}

const UserCollectionContent = ({ user, userCollection, detailModel }: Props) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  return (
    <table className={"basic"}>
      <tbody>
        <tr>
          <td className={"header"}>상태</td>
          <td colSpan={2} className={"header"}>
            회원 ID
          </td>
          <td className={"header"}>회원명</td>
          <td className={"header"}>등록일시</td>
          <td className={"header"}>수정일시</td>
        </tr>
        <tr>
          <td>{userCollection?.status}</td>
          <td colSpan={2}>{user?.id}</td>
          <td>{user?.name}</td>
          <td>{toDateTimeStr(userCollection?.createdAt)}</td>
          <td>{toDateTimeStr(userCollection?.updatedAt)}</td>
        </tr>
        <tr>
          <td rowSpan={8} colSpan={1}>
            <img
              style={{ display: "inline", margin: "auto", width: "300px", height: "300px", objectFit: "contain" }}
              src={detailModel?.thumbnail || ""}
              loading={"lazy"}
              alt={detailModel?.title}
            />
          </td>
          <td className={"header"} colSpan={5}>
            상세모델 정보
          </td>
        </tr>
        <tr>
          <td colSpan={1}>{detailModel?.brand?.name}</td>
          <td colSpan={2}>
            {detailModel?.title} {detailModel?.titleSecondary}
          </td>
          <td colSpan={2}>{detailModel?.fullRefNo}</td>
        </tr>
        <tr>
          <td className={"header"}>상품 상태</td>
          <td className={"header"}>보증서 유무</td>
          <td className={"header"}>정품박스</td>
          <td className={"header"}>부속품</td>
          <td className={"header"}>스탬핑 연도</td>
        </tr>
        <tr>
          <td>{userCollection?.productCondition}</td>
          <td>{userCollection?.officialGuarantee}</td>
          <td>{userCollection?.originalBoxCondition}</td>
          <td>{userCollection?.accessoriesCondition}</td>
          <td>{userCollection?.stampingYear}</td>
        </tr>
        <tr>
          <td className={"header"} colSpan={3}>
            부속품 상세
          </td>
          <td className={"header"} colSpan={2}>
            자가진단
          </td>
        </tr>
        <tr>
          <td colSpan={3} rowSpan={3}>
            {detailModel?.brand?.accessories?.map(({ key, value }) => (
              <FormControlLabel
                label={value}
                style={{ marginRight: "30px" }}
                disabled={true}
                checked={!!userCollection?.accessories?.[key]}
                control={<Checkbox />}
              />
            ))}
          </td>
          <td>찍힘/눌림</td>
          <td>{converEmtpyValueToDash(userCollection?.selfCheck?.stab)}</td>
        </tr>
        <tr>
          <td>글라스 흠집</td>
          <td>{converEmtpyValueToDash(userCollection?.selfCheck?.glassScratch)}</td>
        </tr>
        <tr>
          <td>링크</td>
          <td>{converEmtpyValueToDash(userCollection?.selfCheck?.braceletLinks)}</td>
        </tr>
        <tr>
          <td className={"header"}>구매가격</td>
          <td className={"header"}>매입보증 여부</td>
          <td className={"header"}>연관 구매주문ID</td>
          <td className={"header"}>연관 판매주문ID</td>
          <td className={"header"} colSpan={2}>
            연관 상품ID
          </td>
        </tr>
        <tr>
          <td>{truncateWithCommas(userCollection?.purchasePrice, 4) + " 만원"}</td>
          <td>{userCollection?.repurchaseGuaranteeUntil || "해당없음"}</td>
          <td>{converEmtpyValueToDash(userCollection?.buyOrder?.id)}</td>
          <td>{converEmtpyValueToDash(userCollection?.sellOrder?.id)}</td>
          <td colSpan={2}>
            {userCollection?.productId ? (
              <Link
                onClick={() => {
                  window.location.assign(`/products?productId=${userCollection?.productId}`)
                }}
              >
                {userCollection?.productId}
              </Link>
            ) : (
              "-"
            )}
          </td>
        </tr>
        <tr>
          <td className={"header"} colSpan={6}>
            사용자 등록 이미지 ({userCollection?.gallery?.length || 0} 개)
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <ImageList variant={"standard"} sx={{ width: "100%", height: 480 }} cols={5} rowHeight={300}>
              {(userCollection?.gallery || []).map((item) => (
                <ImageListItem key={item.url}>
                  <img
                    src={`${item.url}`}
                    srcSet={`${item.url}`}
                    alt={item.url}
                    loading="lazy"
                    style={{ border: "1px solid #DDD" }}
                    onClick={() => {
                      window.open(`${item.url}`, "_blank")
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default UserCollectionContent
