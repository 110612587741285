import React, {useContext} from "react";
import {Button, Card, Grid, Paper} from "@mui/material";
import {AuthContext} from "../../AuthContext";
import {useNavigate} from "react-router-dom";
import {Google} from "@mui/icons-material";
import {basePath, baseUrl} from "../../utils/httpAPI";

type  LoginProps = {}

const Login: React.FC<LoginProps> = () => {
    const {checkAuth} = useContext(AuthContext)
    const navigate = useNavigate()

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '100vh'}}
        >

            <Grid xs={12}>
                <Button variant={"contained"} href={`${baseUrl}${basePath}/oauth2/authorization/google`} startIcon={<Google/>}>구글 로그인</Button>
            </Grid>
        </Grid>
    )
}

export default Login