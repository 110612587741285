import { getProductTrackingLogList } from "apis/productTrackingLogAPI"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { ProductTrackingLogListItemType } from "types/ProductTrackingLogType"

const PRODUCT_TRACKING_LOG_LIST_SIZE = 20

export const useProductTrackingLogList = () => {
  const [productTrackingLogList, setProductTrackingLogList] = useState<ProductTrackingLogListItemType[]>([])
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: PRODUCT_TRACKING_LOG_LIST_SIZE,
  })

  const [searchParams, setSearchParams] = useState<{ filter: "productId" | "site" | "name"; keyword: string }>({
    filter: "productId",
    keyword: "",
  })

  const parseSearchParams = useCallback((searchParams?: { filter: "productId" | "site" | "name"; keyword: string }) => {
    if (!searchParams) {
      return {}
    }

    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchProductTarckingLogList = useCallback(
    (pageNumber?: number, searchParams?: { filter: "productId" | "site" | "name"; keyword: string }) => {
      getProductTrackingLogList({
        size: PRODUCT_TRACKING_LOG_LIST_SIZE,
        page: pageNumber,
        ...parseSearchParams(searchParams),
      })
        .then(
          ({
            content,
            totalElements = 0,
            totalPages = 0,
            first,
            size = PRODUCT_TRACKING_LOG_LIST_SIZE,
            number = 0,
          }) => {
            setProductTrackingLogList(content)
            setPaginationParams((prev) => ({
              ...prev,
              number,
              totalElements,
              totalPages,
              first,
              size,
            }))
          }
        )
        .catch(() => {
          toast.error("입고/출고 Log를 불러오는데 실패했습니다.")
        })
    },
    [parseSearchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      fetchProductTarckingLogList(pageNumber, searchParams)
    },
    [searchParams, fetchProductTarckingLogList]
  )

  const changeSearchFilterValue = useCallback((searchFilterValue: "productId" | "site" | "name") => {
    setSearchParams((prev) => ({
      ...prev,
      filter: searchFilterValue,
    }))
  }, [])

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(() => {
    fetchProductTarckingLogList(0, searchParams)
  }, [fetchProductTarckingLogList, searchParams])

  useMount(() => {
    fetchProductTarckingLogList()
  })

  return {
    paginationParams,
    searchParams,
    productTrackingLogList,
    changePageNumber,
    onSearch,
    changeSearchFilterValue,
    changeSearchKeyword,
  }
}
