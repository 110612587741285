import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import UpgradeServiceListTableHeader from "./TableHeader"
import UpgradeServiceListTableRow from "./TableBody/TableRow"
import styled from "@emotion/styled"
import { UpgradeServiceOrderListItemType } from "types/UpgradeServiceOrderType"

type Props = {
  upgradeServiceOrderList: UpgradeServiceOrderListItemType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
  selectRow: (orderId: string) => void
}

export const UpgradeServiceListTable = ({
  upgradeServiceOrderList,
  paginationParams,
  changePage,
  selectRow,
}: Props) => {
  return (
    <ListTable>
      <UpgradeServiceListTableHeader />
      <TableBody>
        {upgradeServiceOrderList.length > 0 ? (
          upgradeServiceOrderList.map((upgradeServiceOrderItem) => {
            return (
              <UpgradeServiceListTableRow
                key={upgradeServiceOrderItem.id}
                upgradeServiceOrderItem={upgradeServiceOrderItem}
                onClick={() => selectRow(upgradeServiceOrderItem.id)}
              />
            )
          })
        ) : (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        )}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page - 1)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
