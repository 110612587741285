import styled from "@emotion/styled"
import { Switch } from "@mui/material"
import React, { FunctionComponent } from "react"

import Button from "components/Button"
import PopupPage from "components/Popup/PopupPage"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"

import { DetailModelInfoInput } from "./DetailModelInfoInput"
import { DetailModelImageInput } from "./DetailModelImageInput"
import { DetailModelTitleInput } from "./DetailModelTitleInput"
import { DetailModelViverCommentInput } from "./DetailModelViverCommentInput"
import { DetailModelSearchTagsInput } from "./DetailModelSearchTagsInput"
import { DetailModelSpecInput } from "./DetailModelSpecInput"
import { useDetailModelCreatePopupStore } from "./useDetailModelCreatePopupStore"
import { DateTime } from "luxon"
import * as XLSX from "xlsx"

export const DetailModelRegeditPopup: FunctionComponent<{
  brandSid: string
  modelId: number
  detailModelId: number | null
  onClickCancel: () => void
  onClickConfirm: () => void
  onRefreshList: () => void
}> = ({ brandSid, modelId, detailModelId, onClickCancel, onClickConfirm, onRefreshList }) => {
  const {
    detailModelAllowDisplay,
    detailModelInfoForm,
    detailModelImageForm,
    detailModelTitleForm,
    detailModelCommentForm,
    detailModelSearchTagsForm,
    detailModelSpecsForm,
    modelOptions,
    registrationStatus,
    fetchRegeditDetailModel,
    changeThumbnailFile,
    setDetailModelAllowDisplay,
    setDetailModelInfoForm,
    setDetailModelTitleForm,
    setDetailModelCommentForm,
    setDetailModelSearchTagsForm,
    setDetailModelSpecsForm,
    saveDetailModelForm,
    changeDetailModelFormStatus,
  } = useDetailModelCreatePopupStore({ brandSid, modelId, detailModelId }, onRefreshList)

  const downloadExcel = () => {
    const data = [
      {
        브랜드: brandSid,
        모델명: modelOptions.filter((model) => model.modelId === modelId)[0].modelName,
        상세모델명_영문: detailModelInfoForm.name,
        상세모델명_한글: detailModelInfoForm.nameKorean,
        "Ref No.": detailModelInfoForm.refNo,
        "Full Ref.": detailModelInfoForm.fullRefNo,
        서브타이틀: detailModelTitleForm.titleSecondary,
        "코멘트 타이틀": detailModelCommentForm.commentTitle,
        "코멘트 상세": detailModelCommentForm.commentDetail,
        검색어: detailModelSearchTagsForm.searchTags,
        "다이얼 색상": detailModelSpecsForm["다이얼 색상"].value,
        "다이얼 특성": detailModelSpecsForm["다이얼 특성"].value,
        "케이스 소재": detailModelSpecsForm["케이스 소재"].value,
        "브레이슬릿 소재": detailModelSpecsForm["브레이슬릿 소재"].value,
        "브레이슬릿 종류": detailModelSpecsForm["브레이슬릿 종류"].value,
        글라스: detailModelSpecsForm.글라스.value,
        "케이스 직경": detailModelSpecsForm["케이스 직경"].value,
        "케이스 크기": detailModelSpecsForm["케이스 크기"].value,
        "케이스 두께": detailModelSpecsForm["케이스 두께"].value,
        무게: detailModelSpecsForm.무게.value,
        방수: detailModelSpecsForm.방수.value,
        "베젤 소재": detailModelSpecsForm["베젤 소재"].value,
        "베젤 종류": detailModelSpecsForm["베젤 종류"].value,
        무브먼트: detailModelSpecsForm.무브먼트.value,
        칼리버: detailModelSpecsForm.칼리버.value,
        진동수: detailModelSpecsForm.진동수.value,
        파워리저브: detailModelSpecsForm.파워리저브.value,
        제조국: detailModelSpecsForm["MANUFACTURED IN"].value,
        "크라운 소재": detailModelSpecsForm["크라운 소재"].value,
        클라스프: detailModelSpecsForm.클라스프.value,
        다이얼: detailModelSpecsForm.다이얼.value,
        핸즈: detailModelSpecsForm.핸즈.value,
      },
    ]

    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, `${detailModelInfoForm.name.replace(/(s*)/g, "")}${detailModelInfoForm.refNo}.xlsx`)
  }

  return (
    <PopupPage isOpen style={{ width: "940px", height: "calc(100vh - 50px)" }} closePage={onClickCancel}>
      <ContentWrapper>
        <PopupPageScrollContainer>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", flexDirection: "row" }}>
            <PopupPageTitle>상세 모델 추가</PopupPageTitle>
            <div style={{ width: 150 }}>
              <Button
                style={{
                  background: "#2E7D32",
                  borderColor: "#2E7D32",
                }}
                onClick={() => {
                  downloadExcel()
                }}
              >
                모델 정보 다운로드
              </Button>
            </div>
          </div>

          <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
            <DetailModelImageInput
              thumbnailUrl={detailModelImageForm.thumbnailUrl}
              onChangeThumbanilFile={(fileList) => changeThumbnailFile(fileList)}
            />
            <DetailModelInfoInput
              brandSid={brandSid}
              modelOptions={modelOptions}
              modelId={detailModelInfoForm.modelId}
              name={detailModelInfoForm.name}
              nameKorean={detailModelInfoForm.nameKorean}
              refNo={detailModelInfoForm.refNo}
              fullRefNo={detailModelInfoForm.fullRefNo}
              onChangeModelId={(modelId) => setDetailModelInfoForm((state) => ({ ...state, modelId }))}
              onChangeName={(name) => setDetailModelInfoForm((state) => ({ ...state, name }))}
              onChangeNameKorean={(nameKorean) => setDetailModelInfoForm((state) => ({ ...state, nameKorean }))}
              onChangeRefNo={(refNo) => setDetailModelInfoForm((state) => ({ ...state, refNo }))}
              onChangeFullRefNo={(fullRefNo) => setDetailModelInfoForm((state) => ({ ...state, fullRefNo }))}
            />
          </div>
          <div style={{ height: 44 }} />
          <DetailModelTitleInput
            title={`${detailModelInfoForm.name} ${detailModelInfoForm.refNo}`}
            titleSecondary={detailModelTitleForm.titleSecondary}
            onChangeTitleSecondary={(titleSecondary) => {
              setDetailModelTitleForm((state) => ({ ...state, titleSecondary }))
            }}
          />
          <div style={{ margin: "40px 0", borderBottom: "1px solid #F0F2F5" }} />
          <DetailModelViverCommentInput
            display={detailModelCommentForm.display}
            commentTitle={detailModelCommentForm.commentTitle}
            commentDetail={detailModelCommentForm.commentDetail}
            onChangeDisplay={(display) => setDetailModelCommentForm((state) => ({ ...state, display }))}
            onChangeCommentTitle={(commentTitle) =>
              setDetailModelCommentForm((state) => ({
                ...state,
                commentTitle,
              }))
            }
            onChangeCommentDetail={(commentDetail) => {
              setDetailModelCommentForm((state) => ({ ...state, commentDetail }))
            }}
          />
          <div style={{ margin: "40px 0", borderBottom: "1px solid #F0F2F5" }} />
          <DetailModelSearchTagsInput
            searchTags={detailModelSearchTagsForm.searchTags}
            onChangeSearchTag={(searchTags) => setDetailModelSearchTagsForm((state) => ({ ...state, searchTags }))}
          />
          <div style={{ margin: "40px 0", borderBottom: "1px solid #F0F2F5" }} />
          <DetailModelSpecInput
            specs={detailModelSpecsForm}
            onChangeSpecs={(specs) => setDetailModelSpecsForm((state) => ({ ...state, ...specs }))}
          />
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <div style={{ display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
            <div style={{ width: 112 }}>
              노출
              <Switch
                checked={detailModelAllowDisplay}
                onChange={(_, checked) => setDetailModelAllowDisplay(checked)}
              />
            </div>
            <div style={{ width: 120 }}>
              <Button buttonStyle="black-border" onClick={onClickCancel}>
                취소
              </Button>
            </div>
            <div style={{ width: 120, marginLeft: 8 }}>
              <Button onClick={saveDetailModelForm} disabled={!registrationStatus}>
                저장
              </Button>
            </div>

            {!registrationStatus && (
              <div style={{ width: 120, marginLeft: 8 }}>
                <Button
                  style={{
                    background: "#2E7D32",
                    borderColor: "#2E7D32",
                  }}
                  onClick={async () => {
                    await fetchRegeditDetailModel()
                    onClickConfirm()
                  }}
                >
                  정보 작성 완료
                </Button>
              </div>
            )}

            {registrationStatus === "등록대기" && (
              <div style={{ width: 120, marginLeft: 8 }}>
                <Button
                  style={{
                    background: "#2E7D32",
                    borderColor: "#2E7D32",
                  }}
                  onClick={changeDetailModelFormStatus}
                >
                  정보 작성 완료
                </Button>
              </div>
            )}
            {registrationStatus === "이미지제작" && (
              <div style={{ width: 120, marginLeft: 8 }}>
                <Button
                  style={{
                    background: "#2E7D32",
                    borderColor: "#2E7D32",
                  }}
                  onClick={changeDetailModelFormStatus}
                >
                  이미지 완료
                </Button>
              </div>
            )}
            {registrationStatus === "검수대기" && (
              <div style={{ width: 120, marginLeft: 8 }}>
                <Button buttonStyle="red-fill" onClick={changeDetailModelFormStatus}>
                  검수 완료
                </Button>
              </div>
            )}
          </div>
        </PopupPageFooter>
      </ContentWrapper>
    </PopupPage>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 940px;
  height: 100%;
  padding: 48px;
`
