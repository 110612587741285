import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import styled from "@emotion/styled"

import ProductTrackingLogListTableHeader from "./TableHeader"
import ProductTrackingLogListTableRow from "./TableBody/TableRow"

type Props = {
  logList: any[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
}

export const ProductTrackingLogListTable = ({ logList, paginationParams, changePage }: Props) => {
  return (
    <ListTable>
      <ProductTrackingLogListTableHeader />
      <TableBody>
        {logList.length > 0 ? (
          logList.map((logListItem, index) => (
            <ProductTrackingLogListTableRow key={`log_list_${index}`} logListItem={logListItem} />
          ))
        ) : (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        )}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page - 1)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
