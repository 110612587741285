import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import {CouponCodeUsageHistoryType} from "../../../../types/CouponCodeUsageHistoryType";
import CouponCodeUsageHistoryListTableHeader from "./TableHeader";
import CouponCodeUsageHistoryListTableRow from "./TableBody/TableRow";

type Props = {
  histories: CouponCodeUsageHistoryType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
}

const CouponCodeUsageHistoryListTable = ({ histories, paginationParams, changePage }: Props) => {
  return (
    <ListTable>
      <CouponCodeUsageHistoryListTableHeader />
      <TableBody>
        {histories.map((history) => (
          <CouponCodeUsageHistoryListTableRow key={history.id} couponCodeUsageHistory={history} />
        ))}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default CouponCodeUsageHistoryListTable
