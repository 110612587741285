import {Button, Divider, Stack} from "@mui/material";


// @ts-ignore
const CurationTableFilter = ({filter, handleFilterChange}) => {

  const selectedStyle = {
    textDecoration: 'underline',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
  };

  const unselectedStyle = {
    textDecoration: 'none',
    fontWeight: 'normal',
    backgroundColor: 'transparent'
  };

  return (
        <Stack direction={'row'} justifyContent={'flex-start'}  divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            <Button style={filter.postingStatus === null ? selectedStyle : unselectedStyle}    onClick={ () => {handleFilterChange({...filter, postingStatus: null})} }>전체</Button>
            <Button style={filter.postingStatus === '노출중' ? selectedStyle : unselectedStyle}   onClick={ () => {handleFilterChange({...filter, postingStatus: '노출중'})} }>노출중</Button>
            <Button style={filter.postingStatus === '노출 예정' ? selectedStyle : unselectedStyle} onClick={ () => {handleFilterChange({...filter, postingStatus: '노출 예정'})} }>노출 예정</Button>
            <Button style={filter.postingStatus === '노출 종료' ? selectedStyle : unselectedStyle} onClick={ () => {handleFilterChange({...filter, postingStatus: '노출 종료'})} }>노출 종료</Button>
        </Stack>
    )
}

export default CurationTableFilter