import { PenaltyDetail } from "types/PenaltyType"
import { useState } from "react"
import { useMount } from "react-use"
import { getPenaltyDetail } from "apis/penaltyAPI"

export const usePenaltyDetail = (penaltyId: string) => {
  const [penaltyDetail, setPenaltyDetail] = useState<PenaltyDetail>()

  const refetch = () => {
    getPenaltyDetail(penaltyId).then((response) => {
      setPenaltyDetail(response)
    })
  }

  useMount(() => {
    getPenaltyDetail(penaltyId).then((response) => {
      setPenaltyDetail(response)
    })
  })

  return { penaltyDetail, refetch }
}
