import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { HTMLAttributes, PropsWithChildren } from "react"
import styled from "@emotion/styled"
import { toDateStr, toTimeStr } from "utils/datetimeUtil"
import { numberWithCommas } from "utils/NumberUtils"
import { pointScheduleHeader } from "../../TableHeader"
import { PointScheduleListItemType, PointScheduleStatusCode } from "types/PointScheduleListItemType"

type Props = {
  point: PointScheduleListItemType
} & HTMLAttributes<HTMLDivElement>

const PointScheduleListTableRow = ({ point, ...props }: PropsWithChildren<Props>) => {
  const converEmtpyValueToDash = (value?: string | number) => {
    return !!value ? value : "-"
  }

  return (
    <TableRow {...props}>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[0].width }}>{point.id}</TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[1].width }}>
        <PointText status={point.status}>{point.status}</PointText>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[2].width }}>
        {point.scheduleAt ? toDateStr(point.scheduleAt, false) : "-"}
        <br />
        {point.scheduleAt ? toTimeStr(point.scheduleAt, true) : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[3].width }}>{point.userSourceType}</TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[4].width }}>{point.userIds?.length}명</TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[5].width }}>
        {converEmtpyValueToDash(point.reasonType)}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[6].width }}>
        <ReasonMessage>{point.reasonMessage}</ReasonMessage>
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[7].width }}>{numberWithCommas(point.points)}</TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[8].width }}>
        {point.availableDate ? toDateStr(point.availableDate, false) : "-"}
      </TableRowItem>
      <TableRowItem style={{ maxWidth: pointScheduleHeader[9].width }}>{point.pushMessage ? "O" : "X"}</TableRowItem>
    </TableRow>
  )
}

export default PointScheduleListTableRow

const PointText = styled.div<{ status: PointScheduleStatusCode }>`
  font-weight: 700;
  color: ${({ status }) => {
    if (status === PointScheduleStatusCode.실패) {
      return "#d32f2f"
    }
    if (status === PointScheduleStatusCode.완료) {
      return "#1976d2"
    }
    if (status === PointScheduleStatusCode.대기) {
      return "#AAAAAA"
    }
  }};

  text-decoration: ${({ status }) => (status === PointScheduleStatusCode.취소 ? "line-through" : "unset")}};
`

const ReasonMessage = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
