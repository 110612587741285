import {Button, Stack} from "@mui/material";

//@ts-ignore
const Toolbar = ({handleAggregate}) => {
    return(
        <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button variant={'contained'} size={'small'} onClick={handleAggregate}>재집계</Button>
        </Stack>
    )
}

export default Toolbar