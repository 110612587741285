export const precisionRound: (n?: number, precision?: number) => number
    = (n, precision = 0) => {
    const factor = Math.pow(10, precision);
    return Math.round((n||0) * factor) / factor;
}
export const precisionCeil: (n?: number, precision?: number) => number
    = (n, precision = 0) => {
    const factor = Math.pow(10, precision);
    return Math.ceil((n||0) * factor) / factor;
}
export const precisionFloor: (n?: number, precision?: number) => number
    = (n, precision = 0) => {
    const factor = Math.pow(10, precision);
    return Math.floor((n||0) * factor) / factor;
}

export const truncate: (n?: number, d?: number) => number
    = (n, d) => {
    return Math.trunc((n||0) / Math.pow(10, d||1))
}

export const truncateWithCommas: (n?: number, d?: number) => string
    = (n, d, w = false) => {
    const t = Math.trunc((n||0) / Math.pow(10, d||1))
    return numberWithCommas(t)
}


export const avg: (n1?: number, n2?: number) => number|undefined
    = (n1, n2) => {
    if (n1 && n2) return (n1 + n2) /2
    else          return undefined
}

export const floor: (n?: number, d?: number) => number
    = (n, d) => {
    return Math.floor((n||0) / Math.pow(10, d||1)) * Math.pow(10, d||1)
}

export const numberWithCommas: (x?: number | null) => string
    = (x) => {
    const replaced = x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if (replaced && replaced !== '0') return replaced
        else if(replaced == '0') return '0'
        else return ''
}

export const toRange: (x1?: number, x2?: number, d?: number, w?: boolean) => string
    = (x1, x2, d = 0, withCommas = true) => {
    const s1 = numberWithCommas(truncate(x1, d))
    const s2 = numberWithCommas(truncate(x2, d))
    if (s1 && s2)
        return `${s1||'?'} ~ ${s2||'?'}`
    else
        return '-'
}

const formatter = new Intl.NumberFormat('ko-KR', {maximumSignificantDigits: 3})
export const currencyFormat = (number: number) => { return formatter.format(number) }