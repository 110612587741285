import { Button, FormControl, Grid, MenuItem, Paper, Select, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { excelDownloadQueries, excelDownloadQuery } from "../../apis/excelDownloadAPI"
import { ExcelDownLoadQueryType, Param } from "../../types/ExcelDownLoadQueryType"
import { DesktopDatePicker } from "@mui/lab"
import { toDateStr } from "../../utils/datetimeUtil"
import { toast } from "react-toastify"
import styled from "@emotion/styled"
import { DateTime } from "luxon"

const ExcelDownloads = () => {
  const [resultList, setResultList] = useState<ExcelDownLoadQueryType[]>([])
  const [typeId, setTypeId] = useState<number>(0)
  const [downloading, setDownloading] = useState<boolean>(false)

  useEffect(() => {
    excelDownloadQueries().then((res) => setResultList(res))
  }, [])

  // 선택한 엑셀다운로드 타입 조회
  function getSelectType() {
    return resultList.find(item => item.id === typeId)
  }

  // 선택한 엑셀다운로드 타입의 파라메터 정보 조회
  function getTypeParams() {
    return resultList.find(item => item.id  ===typeId)?.params
  }

  // 선택한 엑셀다운로드 타입의 파라메터 정보 저장
  function setTypeParams(newParams: Param[]) {
    const updatedList = resultList.map(item =>
      item.id === typeId
        ? { ...item, params: newParams }
        : item
    );
    setResultList(updatedList);
  }

  // 선택한 엑셀다운로드 타입의 파라메터 값 조회
 function getParamValue(column: string) {
   const excelParams =getTypeParams()
   return excelParams?.find(item => item.column  ===column)?.value
 }

  // 선택한 엑셀다운로드 타입의 파라메터 값 저장
  function setParamValue(column: string, newValue: string) {
    const excelParams = getTypeParams();

    const updatedParams = excelParams?.map(item =>
      item.column === column
        ? { ...item, value: newValue }
        : item
    );

    setTypeParams(updatedParams!!);
  }

  const handleExcelDownloadClick = ( ) => {
    const fileName = `${getSelectType()?.queryType}_${DateTime.now().toFormat("yyyyMMdd_HHmmss")}.xlsx`
    const parameter = { id: typeId, params: getTypeParams(), fileName : fileName}

    if ( parameter.params != undefined && parameter?.params?.length > 0) {
      for (const param of parameter.params) {
        if (!param.value || param.value.trim() === "") {
          toast.error(`[${param.column}] 값을 입력하세요.`)
          return false;
        }
      }
    }

    if (downloading) {
      toast.info("엑셀 다운로드 중입니다.")
      return
    }

    setDownloading(true)

    excelDownloadQuery(parameter)
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch( (err : any) => {
      toast.error(`${err.message || err}`)
      console.log("Error", err );
    })

    setDownloading(false)
  }

  return (
    <Grid container spacing={2} direction={"row"}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <h3>엑셀 다운로드 메뉴</h3>
          <FormControl>
            <Select
              size={"small"}
              value={typeId}
              required
              onChange={(e) => {
                setTypeId(Number(e.target.value))
              }}
              MenuProps={{
                style: { zIndex: 10001 },
              }}
            >
              <MenuItem value={"0"} key={"0"}>메뉴를 선택하세요.</MenuItem>
              {resultList.map((menu) => (
                <MenuItem value={menu.id} key={menu.id}  >
                  {menu.queryType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormDescriptionWrapper>
            <pre>
              {getSelectType()?.description}
            </pre>
          </FormDescriptionWrapper>
          <FormItemInlineWrapper>
            {getTypeParams()?.map((param, index) => (
              <div key={index} style={{ marginBottom: '1rem' }}>
                <label>
                  {param.column?.includes("일자") ? (
                    <>
                      <FormLabel>
                        {param.column}
                      </FormLabel>
                      <DesktopDatePicker
                        inputFormat="yyyy-MM-dd"
                        value={getParamValue(param.column) }
                        onChange={(params) => {
                          setParamValue(param.column!!, toDateStr(params  || "") ?? "")
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                      />
                    </>
                  ) : (
                    <>
                      <FormLabel>
                        {param.column}
                      </FormLabel>
                      <FormValue>
                        <TextField
                          size={'small'}
                          value={getParamValue(param.column!!)}
                          onChange={(e) => setParamValue(param.column!!, e.target.value ?? "")}
                          placeholder={param.column}
                        />
                      </FormValue>
                    </>
                  )}
                </label>
              </div>
            ))}
          </FormItemInlineWrapper>

          <Button
            variant={"contained"}
            size={"small"}
            style={{ margin: "0 2px" }}
            onClick={handleExcelDownloadClick}
            disabled={typeId===0}
          >
            엑셀 다운로드
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

const FormDescriptionWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center; 
`


const FormItemInlineWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  padding: 20px 15px;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`

const FormLabel = styled.span`
    width: 500px;
    font-size: 18px;
    padding: 0px 15px ; 
`

const FormValue = styled.span`
  min-width: 150px;
    width: 200px;
  font-size: 18px;
  padding: 20px 30px;     
`

export default ExcelDownloads
