import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack} from "@mui/material";
import {DateTime} from "luxon";
import * as React from "react";
import {useEffect, useState} from "react";
import shopContentAPI from "../../../apis/shopContentAPI";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import Checkbox from '@mui/material/Checkbox';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import _ from 'lodash'
import {ShopContentType} from "../../../types/ShopContentType";

function renderPreviewCell(params: any) {
    return (<img src={params?.row?.bannerImageUrl} style={{width: '100%'}} />)
}

function renderStatusCell(params: any) {
    if (params?.row?.postingStatus == "노출중") return <Button color={"error"}>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 예정") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 종료") return <Button disabled>{params?.row?.postingStatus}</Button>
    if (params?.row?.postingStatus == "노출 대기") return <Button disabled>{params?.row?.postingStatus}</Button>
}

function renderPostingPeriodCell(params: any) {
    const startedAt = DateTime.fromISO(params?.row?.startedAt)
    const endedAt = DateTime.fromISO(params?.row?.endedAt)

    return (
      <Stack direction={'column'} alignItems={"self-end"}>
          <Box>{startedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
          <Box>~ {endedAt.toFormat('yyyy-MM-dd HH:mm:ss')}</Box>
      </Stack>
    )
}

function renderTitleCell(params: any) {
    return (
      <Stack direction={'column'} alignItems={"self-start"}>
          <Box>{params?.row?.title}</Box>
          {params?.row?.titleSecondary ? <Box>({params?.row?.titleSecondary})</Box> : null}
      </Stack>
    )
}


// @ts-ignore
const ShopContentReorderPopup = ({open, handleClose}) => {
    const [rows, setRows] = useState<ShopContentType[]>([])
    const [postingStatusChecked, setPostingStatusChecked] = useState({
        '노출중': true,
        '노출 대기': true,
        '노출 예정': true,
        '노출 종료': false
    })

    useEffect(() => {
        if (open) {
            fetchContents()
        }
    }, [open, postingStatusChecked])

    const fetchContents = () => {
        const now = DateTime.now().toISO()
        shopContentAPI.getList({
            postingStatus: _.chain(postingStatusChecked).pickBy((v => v)).keys().value(),
            onDisplay: true,
            endedAtGoe: now, sort: "priority,asc"
        }).then(result => setRows(result))
    }

    const handleSubmit = () => {
        shopContentAPI.reorderingByDirect(rows).then((result) => {
              // fetchContents()
            handleClose()
          }
        )
    }

    const handleFilterChanged = (params: any) => {
        setPostingStatusChecked({...postingStatusChecked,  ...params})
    }

    const getColumns = () => {
        return [
            { field: "id", headerName: "ID", width: 80 },
            {field: 'type', headerName: "타입", width: 180, valueGetter: (params: any) => {
                if (params.row.type == 'BANNER') return '배너'
                else if (params.row.type == 'CURATION') return '상품 큐레이션'
                else if (params.row.type == 'DETAIL_MODEL_CURATION') return '상세 모델 큐레이션'
                else if (params.row.type == 'LIKED_DETAIL_MODEL_PRODUCT_CURATION') return '관심 상세 모델 상품 큐레이션'
                else if (params.row.type == 'BRAND_MODEL_CURATION') return '브랜드 인기 모델'
                else if (params.row.type == 'SWIPE_BANNER') return '스와이프 배너'
                }},
            {field: 'preview', headerName: "미리보기", width: 130, renderCell: renderPreviewCell},
            {field: 'title', headerName: "제목(부제목)", flex: 3, width: 150, renderCell: renderTitleCell},
            {field: 'createdAt', headerName: "등록 일시", width: 160, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}`,},
            {field: 'postingPeriod', headerName: "노출 기간", width: 180, renderCell: renderPostingPeriodCell},
            {field: 'postingStatus', headerName: "진행 상태", width: 100, renderCell: renderStatusCell},
            {field: 'showDomesticUser',  width: 80,
                renderCell: (params: any) => (
                  <div style={{textAlign: "center"}}>
                      <p>{params.row.showDomesticUser ? "Y" : "N"}</p>
                  </div>
                ),
                renderHeader: () => (
                  <div style={{  textAlign: "center", lineHeight: "1.2" }}>
                      내국인 회원<br />노출
                  </div>
                ),
            },
            { field: "showForeignUser", width: 80,
                renderCell: (params: any) => (
                  <div
                    style={{ textAlign: "center"  }}
                  >
                      <span>{params.row.showForeignUser ? "Y" : "N"}</span>
                  </div>
                ),
                renderHeader: () => (
                  <div style={{ textAlign: "center", lineHeight: "1.2" }}>
                      외국인 회원<br />노출
                  </div>
                ),
            },
            {field: 'priority', headerName: "순서(수동입력)", width: 100, editable:true, type:'number'},
            {field: 'actions', type: 'actions', headerName: "순서(위치이동)", width: 120, getActions: (params: any) => ([
                    <GridActionsCellItem
                        icon={<ArrowUpwardIcon/>}
                        label={"위로"}
                        onClick={() => {
                            const index = _.findIndex(rows, {id: params.row.id})
                            if (index > 0) {
                                let updatedRows = [
                                    ...(_.slice(rows, 0, index-1)),
                                    rows[index],
                                    rows[index-1],
                                    ...(_.slice(rows, index+1, rows.length)),
                                ]

                                updatedRows = updatedRows.map(data => ({
                                    ...data,
                                    priority: null
                                })) ;

                                setRows(updatedRows);
                            }
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<ArrowDownwardIcon/>}
                        label={"아래로"}
                        onClick={() => {
                            const index = _.findIndex(rows, {id: params.row.id})
                            if (index < rows.length-1) {
                                let updatedRows = [
                                    ...(_.slice(rows, 0, index)),
                                    rows[index+1],
                                    rows[index],
                                    ...(_.slice(rows, index+2, rows.length)),
                                ]

                                updatedRows = updatedRows.map(data => ({
                                    ...data,
                                    priority: null
                                })) ;

                                setRows(updatedRows);
                            }
                        }}
                    />
                ])},
        ]
    }

    return (
        <Dialog open={open} maxWidth={"xl"} fullWidth>
            <DialogTitle>샵 컨텐츠 순서 변경</DialogTitle>
            <DialogContent >
                <Box>
                    <FormControlLabel control={<Checkbox checked={postingStatusChecked['노출중']} onChange={e => handleFilterChanged({'노출중': e.target.checked})} />} label="노출중" />
                    <FormControlLabel control={<Checkbox checked={postingStatusChecked['노출 대기']} onChange={e => handleFilterChanged({'노출 대기': e.target.checked})} />} label="노출 대기" />
                    <FormControlLabel control={<Checkbox checked={postingStatusChecked['노출 예정']} onChange={e => handleFilterChanged({'노출 예정': e.target.checked})} />} label="노출 예정" />
                    <FormControlLabel control={<Checkbox checked={postingStatusChecked['노출 종료']} onChange={e => handleFilterChanged({'노출 종료': e.target.checked})} />} label="노출 종료" />
                </Box>
                <Box height={700}>
                    <DataGrid
                        columns={getColumns()}
                        rows={rows}
                        getRowHeight={() => 100}
                        hideFooter={true}
                        onCellEditCommit={(params ) => {
                            const updatedRows = rows.map(row => {
                                if (row.id === params.id) {
                                    return { ...row, priority: params.value };
                                }
                                return row;
                            });
                            setRows(updatedRows);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>수정</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShopContentReorderPopup