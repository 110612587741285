import {PaginationParamsType} from "types/PageType"
import {useCallback, useState} from "react"
import styled from "@emotion/styled"
import {useMount} from "react-use"
import {CouponPointUsageHistorySearchParams, CouponPointUsageHistoryType} from "types/CouponPointUsageHistoryType";
import {getCouponPointUsageHistoryList} from "../../../apis/couponCodeAPI";
import CouponPointUsageHistoryListTableSearchBar from "./SearchBar";
import CouponPointUsageHistoryListTable from "./PointListTable";
import {useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";

export type CouponPointUsageHistorySearchType = {
  used?: boolean
  filter: keyof CouponPointUsageHistorySearchParams
  keyword: string
}

const FETCH_SIZE = 20

const CouponPointUsageHistoryPage = () => {
  const [pointList, setCouponPointUsageHistory] = useState<CouponPointUsageHistoryType[]>([])
  const [searchParams, setSearchParams] = useState<CouponPointUsageHistorySearchType>({used: true, filter: "code", keyword: ""})

  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_SIZE,
  })

  const parseSearchParams = useCallback((searchParams?: CouponPointUsageHistorySearchType) => {
    if (!searchParams) {
      return { used: true }
    }
    return {
      [searchParams.filter]: searchParams.keyword,
      used: true,
    }
  }, [])

  const fetchCouponPointUsageHistory = useCallback(
    (pageNumber?: number, searchParams?: CouponPointUsageHistorySearchType) => {
        getCouponPointUsageHistoryList({ size: FETCH_SIZE, page: pageNumber, ...parseSearchParams(searchParams) }).then(
        ({ content, totalElements = 0, totalPages = 0, first, size = FETCH_SIZE, number = 0 }) => {
          setCouponPointUsageHistory(content || [])
          setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
          if (!totalElements) toast.info("조회 결과가 없습니다.")
        }
      )
    },
    [parseSearchParams]
  )

  const changeSearchFilterValue = useCallback(
    (searchFilterValue: keyof CouponPointUsageHistorySearchParams) => {
      setSearchParams({ filter: searchFilterValue, keyword: "" })
      fetchCouponPointUsageHistory()
    },
    [fetchCouponPointUsageHistory]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(
    (keyword: CouponPointUsageHistorySearchType["keyword"]) => {
      fetchCouponPointUsageHistory(0, { ...searchParams, keyword })
      setPaginationParams((prev) => ({ ...prev, number: 0 }))
    },
    [fetchCouponPointUsageHistory, searchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber - 1 }))
      fetchCouponPointUsageHistory(pageNumber - 1, searchParams)
    },
    [searchParams, fetchCouponPointUsageHistory]
  )

  const [queryParam] = useSearchParams()
  useMount(() => {
    const keyword = queryParam.get("keyword") as string
    if(keyword) {
        setSearchParams({ ...searchParams, keyword })
        onSearch(keyword)
    } else
      fetchCouponPointUsageHistory()
  })

  return (
    <>
      <PageHeaderWrapper>
        <CouponPointUsageHistoryListTableSearchBar
          searchParams={searchParams}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>
      <CouponPointUsageHistoryListTable histories={pointList} paginationParams={paginationParams} changePage={changePageNumber} />
    </>
  )
}

export default CouponPointUsageHistoryPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 16px;
  margin-bottom: 20px;
`
