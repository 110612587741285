import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextareaAutosize, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { numberWithCommas, toRange, truncate } from "../../../utils/NumberUtils"
import { toast } from "react-toastify"
import { approveSellOrder, getSellOrder, updateSellOrder } from "../../../apis/sellOrderAPI"
import { ResellPriceResponse } from "types/EstimationType"
import { getResellPrice } from "../../../apis/estimationAPI"
import { ProductStatusCode } from "types/ProductType"
import IncommingShipping from "../../BuyOrder/ViewPopup/IncommingShipping"
import { ProductConditionCode } from "types/DiagnosisType"
import SellOrderInformation from "../EditPopup/SellOrderInformation"
import { BrandType } from "types/BrandType"
import { getBrand } from "../../../apis/brandAPI"
import { reorderProductGallery, updateReceiveBuyPriceOffer, updateUserDescription } from "../../../apis/productAPI"
import { ResourceType } from "types/ResourceType"
import BeforeDiagnosisProductGallery from "./BeforeDiagnosisProductGallery"
import { ConfirmBeforeDiagnosisSellOrderUpdateProductPopup } from "./ConfirmBeforeDiagnosisSellOrderUpdateProductPopup"
import SearchDetailModelPopup from "../../../components/Popup/SearchDetailModelPopup"
import BeforeDiagnosisSellOrderRejectReasonPopup from "./BeforeDiagnosisSellOrderRejectReasonPopup"
import { toDateTimeStr } from "../../../utils/datetimeUtil"

type ConfirmBeforeDiagnosisSellOrderPopupProps = {
  open: boolean
  selectedSellOrderId?: string
  handleClose: Function
}

const ConfirmBeforeDiagnosisSellOrderPopup: React.FC<ConfirmBeforeDiagnosisSellOrderPopupProps> = ({
                                                                                                     open,
                                                                                                     selectedSellOrderId,
                                                                                                     handleClose,
                                                                                                   }) => {
  // @ts-ignore
  const [brand, setBrand] = useState<BrandType | undefined>()
  const [sellOrder, setSellOrder] = useState<SellOrderType | undefined>()
  const [estimatedPrice, setEstimatedPrice] = useState<ResellPriceResponse>({})
  const [editable, setEditable] = useState<Boolean>(true)
  const [openUpdateSellOrderDetailModelPopup, setOpenUpdateSellOrderDetailModelPopup] = useState<boolean>(false)
  const [userDescription, setUserDescription] = useState<string | undefined>("")
  const [selectPopupOpen, setSelectPopupOpen] = useState<boolean>(false)
  const [openRejectPopup, setOpenRejectPopup] = useState<boolean>(false)
  const [receiveBuyPriceOffer, setReceiveBuyPriceOffer] = useState<boolean>(false)


  const updateDetailModelId = (detailModelId: number) => {
    // @ts-ignore
    updateSellOrder(sellOrder?.id, { detailModelId: detailModelId }).then((res) => {
      // @ts-ignore
      setSellOrder(res)
      // @ts-ignore
      updateEstimatedPrice(res)
    })
  }
  const fetchSellOrder = async () => {
    if (!selectedSellOrderId) return

    setEditable(false)
    try {
      const sellOrder = await getSellOrder(selectedSellOrderId)
      setSellOrder(sellOrder)
      updateEstimatedPrice(sellOrder)

      const brand = await getBrand(sellOrder?.product?.brandSid || "ETC")
      setBrand(brand)

      setUserDescription(sellOrder?.product?.userDescription || "")
      setReceiveBuyPriceOffer(sellOrder?.product?.receiveBuyPriceOffer!!)

    } catch (error: any) {
      toast.error(`주문조회 실패 (${error.message || error})`)
    }
    setEditable(true)
  }
  const updateEstimatedPrice = (sellOrder?: SellOrderType) => {
    getResellPrice({
      // @ts-ignore
      detailModel: { id: sellOrder?.product?.detailModel?.id },
      mainProductCondition: sellOrder?.product?.sellerProvidedProductInfo?.productCondition,
      accessoriesCondition: sellOrder?.product?.sellerProvidedProductInfo?.accessoriesCondition,
      officialGuarantee: sellOrder?.product?.sellerProvidedProductInfo?.officialGuarantee,
      stampingYear: sellOrder?.product?.sellerProvidedProductInfo?.stampingYear,
      originalBoxCondition: sellOrder?.product?.sellerProvidedProductInfo?.originalBoxCondition,
    }).then((res) => setEstimatedPrice(res))
  }

  const updateRepresentativeImageByGalleryIndex = async (nextRepresentativeImageIndex: number) => {
    const gallery: ResourceType[] = sellOrder?.product?.gallery || []
    const reorderedGallery = [
      gallery[nextRepresentativeImageIndex],
      ...gallery.filter((_, index) => index !== nextRepresentativeImageIndex),
    ]

    setEditable(false)
    let product = await reorderProductGallery(sellOrder?.product?.id!, reorderedGallery)
    let updated = { ...sellOrder, product: product }
    setSellOrder(updated)
    setEditable(true)
  }

  const updateUserDescriptionFromProduct = () => {
    setEditable(false)
    updateUserDescription(sellOrder?.product?.id!, userDescription || "")
      .then((product) => {
        if (product.id == sellOrder?.product?.id) {
          let updated = { ...sellOrder, product: product }
          setSellOrder(updated)

          toast.success("성공적으로 저장하였습니다.")
        }
      })
      .catch((error) => {
        toast.error(`상품 설명 저장 실패 (${error.message || error})`)
      })
      .finally(() => {
        setEditable(true)
      })
  }
  const onUpdateReceiveBuyPriceOffer = async (receiveBuyPriceOffer: boolean) => {
    await updateReceiveBuyPriceOffer(sellOrder?.product!!.id!!, receiveBuyPriceOffer).then(async () => {
      toast.success(receiveBuyPriceOffer ? "구매 입찰 제안 받기 기능이 설정되었습니다." : "구매 입찰 제안 받기 기능이 해제되었습니다.")
      setReceiveBuyPriceOffer(receiveBuyPriceOffer)
    }).catch((error) => {
      toast.error(`${error.message || error}`)
    })
  }

  const approve = () => {
    setEditable(false)
    approveSellOrder(sellOrder?.id!)
      .then((res) => {
        toast.success("판매승인 완료")
        handleClose()
      })
      .catch((error) => {
        toast.error(`판매승인 실패 (${error.message || error})`)
      })
      .finally(() => {
        setEditable(true)
      })
  }
  const reject = () => {
    setEditable(false)
    setOpenRejectPopup(true)
  }

  useEffect(() => {
    if (open) {
      setSelectPopupOpen(false)
      setEditable(false)
      fetchSellOrder()
    } else {
      setSelectPopupOpen(false)
      setSellOrder({})
      setBrand(undefined)
      setReceiveBuyPriceOffer(false)
    }
  }, [open])

  const isEditingDisabled = useMemo(() => {
    return (
      sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매_대기 &&
      sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매중
    )
  }, [sellOrder])

  return (
    <>
      <SearchDetailModelPopup
        open={selectPopupOpen}
        // @ts-ignore
        handleClose={(detailModel) => {
          setSelectPopupOpen(false)
          updateDetailModelId(detailModel?.id)
        }}
      />
      <BeforeDiagnosisSellOrderRejectReasonPopup
        open={openRejectPopup}
        handleClose={() => {
          setOpenRejectPopup(false)
          setEditable(true)
          fetchSellOrder()
        }}
        sellOrderId={selectedSellOrderId!!} />
      <Dialog open={open} maxWidth={"xl"} fullWidth>
        <DialogTitle>
          <Typography fontWeight={"bold"} fontSize={"30px"}>
            진단전판매 주문 정보
          </Typography>
        </DialogTitle>
        <DialogContent>
          <SellOrderInformation sellOrder={sellOrder} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <h2>상품 정보</h2>&nbsp;&nbsp;&nbsp;&nbsp;

            <Button variant={"outlined"} size={"small"} color={"error"} onClick={() => setSelectPopupOpen(true)} disabled={isEditingDisabled}>
              상세모델 변경
            </Button>
          </div>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"ta-center"} rowSpan={7}>
                <img
                  style={{ display: "block", margin: "auto", width: "300px", height: "300px", objectFit: "contain" }}
                  src={
                    sellOrder?.product?.detailModel.notClassified
                      ? require("./thumbnail_not_classified_model.png")
                      : sellOrder?.product?.thumbnail
                  }
                  alt=""
                />
              </td>
              <td className={"header"} colSpan={3}>
                상품명
              </td>
              <td className={"header"} colSpan={1}>
                Ref No.
              </td>
            </tr>
            <tr>
              <td colSpan={1}> {sellOrder?.product?.brandSid}</td>
              <td colSpan={1}>
                {sellOrder?.product?.title}{" "}
                {sellOrder?.product?.detailModel.notClassified && (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => {
                      setOpenUpdateSellOrderDetailModelPopup(true)
                    }}
                  >
                    상품 수정
                  </Button>
                )}
              </td>
              <td colSpan={1}>{sellOrder?.product?.titleSecondary}</td>
              <td colSpan={1}>{sellOrder?.product?.detailModel?.fullRefNo}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={4}>
                가격 정보
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 시장 가격범위 (감가 미적용)
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.marketPrice?.min,
                estimatedPrice?.marketPrice?.max,
                4,
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                최근 30일간 시세 볼륨
              </td>
              <td className={"ta-center"} colSpan={1}>
                {estimatedPrice.marketVolume}
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                판매신청 당시 예상 가격범위
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.min,
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.max,
                4,
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                페널티 기준가
              </td>
              <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                truncate(sellOrder?.product?.initialPrice, 4),
              )} 만원`}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 예상 가격범위
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.correctedEstimatedPrice?.min,
                estimatedPrice?.correctedEstimatedPrice?.max,
                4,
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                고객 판매 희망가
              </td>
              <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                truncate(sellOrder?.product?.price, 4),
              )} 만원`}</td>
            </tr>
            <tr>
              <td className={"header"}>구매입찰 제안 받기</td>
              <td>
                <Switch
                  checked={receiveBuyPriceOffer}
                  onChange={(_, checked) => onUpdateReceiveBuyPriceOffer(checked)}
                />
              </td>
            </tr>
            </tbody>
          </table>

          <h2>상품상태</h2>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"header"}>제품상태</td>
              <td className={"header"}>보증서</td>
              <td className={"header"}>정품박스</td>
              <td className={"header"}>부속품</td>
              <td className={"header"}>스탬핑 연도</td>
            </tr>
            <tr>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.productCondition}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.officialGuarantee}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.originalBoxCondition}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.accessoriesCondition}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.stampingYear}</td>
            </tr>
            {sellOrder?.product?.sellerProvidedProductInfo?.productCondition !== ProductConditionCode.미착용 && (
              <>
                <tr>
                  <td className={"header"} colSpan={3}>
                    상세 부속품
                  </td>
                  <td className={"header"} colSpan={2}>
                    자가진단
                  </td>
                </tr>
                <tr>
                  <td rowSpan={4} colSpan={3}>
                    {brand?.accessories?.map(({ key, value }) => (
                      <FormControlLabel
                        label={value}
                        style={{ marginRight: "30px" }}
                        disabled={true}
                        checked={!!sellOrder?.product?.sellerProvidedProductInfo?.accessories?.[key]}
                        control={<Checkbox />}
                      />
                    ))}
                  </td>
                  <td>찍힘/눌림</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.stab || "-"}</td>
                </tr>
                <tr>
                  <td>글라스 흠집</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.glassScratch || "-"}</td>
                </tr>
                <tr>
                  <td>링크</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.braceletLinks || "-"}</td>
                </tr>
                <tr>
                  <td>보호 필름</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.protectingFilm || "-"}</td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={5} className={"header"}>
                사진 ({sellOrder?.product?.gallery?.length || 0} 개)
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <BeforeDiagnosisProductGallery
                  sellOrder={sellOrder}
                  editable={editable}
                  setEditable={setEditable}
                  updateRepresentativeImageByGalleryIndex={updateRepresentativeImageByGalleryIndex}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={5} className={"header"}>
                설명 ({sellOrder?.product?.userDescription?.length || 0} 자)
              </td>
            </tr>
            <tr>
              <td colSpan={5} style={{ textAlign: "right" }}>
                <TextareaAutosize
                  minRows={5}
                  style={{ width: "100%" }}
                  value={userDescription || ""}
                  onChange={(e) => setUserDescription(e.target.value)}
                />
                <Button
                  disabled={!editable || (sellOrder?.product?.status != ProductStatusCode.판매_대기 && sellOrder?.product?.status != ProductStatusCode.판매중)}
                  size={"medium"}
                  variant={"contained"}
                  color={"error"}
                  onClick={updateUserDescriptionFromProduct}
                >
                  수정
                </Button>
              </td>
            </tr>
            </tbody>
          </table>

          <h2>정산 정보</h2>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"header"}>판매가</td>
              <td className={"header"}>거래 수수료</td>
              <td className={"header"}>주문관리 수수료</td>
              <td className={"header"}>배송비</td>
              <td className={"header"}>선정산 금액</td>
            </tr>
            <tr>
              <td>{numberWithCommas(sellOrder?.product?.price)}원</td>
              <td>{numberWithCommas(sellOrder?.sellingFee ?? 0)}원</td>
              <td>{numberWithCommas(sellOrder?.sellingManagementFee ?? 0)}원</td>
              <td>{numberWithCommas(sellOrder?.shippingFee ?? 0)}원</td>
              <td>
                {sellOrder?.confirmedPreSettlement?.preSettlementPrice
                  ? numberWithCommas(sellOrder.confirmedPreSettlement.preSettlementPrice)
                  : 0}
                원
              </td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"} />
              <td colSpan={1} className={"header"}>
                네고 금액
              </td>
              <td colSpan={1} className={"header"}>
                총 정산 금액
              </td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"} />
              <td>{numberWithCommas((sellOrder?.product?.negotiatedAmount || 0) * -1)}원</td>
              <td>{numberWithCommas(sellOrder?.settlementAmount ?? 0)}원</td>
            </tr>
            </tbody>
          </table>

          <IncommingShipping sellOrder={sellOrder} setSellOrder={setSellOrder} handleClose={handleClose} />

          <h2>거절 이력</h2>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"header"} width={"15%"}>일시</td>
              <td className={"header"} width={"20%"}>항목 구분</td>
              <td className={"header"}>사유</td>
              <td className={"header"} width={"15%"}>담당자</td>
            </tr>
            {sellOrder?.rejectHistory?.map((row) => (
              <tr>
                <td>{toDateTimeStr(row.rejectedAt)}</td>
                <td>{row.rejectReasonType}</td>
                <td>{row.rejectReasonDetail}</td>
                <td>{row.rejectedBy?.name}</td>
              </tr>
            ))}
            </tbody>
          </table>

        </DialogContent>
        <DialogActions>
          <Button
            disabled={!editable || sellOrder?.product?.status != ProductStatusCode.판매_대기}
            size={"medium"}
            variant={"contained"}
            color={"success"}
            onClick={approve}
          >
            승인
          </Button>
          <Button
            disabled={!editable || sellOrder?.product?.status != ProductStatusCode.판매_대기}
            size={"medium"}
            variant={"contained"}
            color={"error"}
            onClick={reject}
          >
            거절
          </Button>
          &nbsp;&nbsp;
          <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
            닫기
          </Button>
        </DialogActions>
        <ConfirmBeforeDiagnosisSellOrderUpdateProductPopup
          open={openUpdateSellOrderDetailModelPopup}
          sellOrderId={sellOrder?.id}
          productTitle={sellOrder?.product?.title || ""}
          onClickClose={() => {
            fetchSellOrder()
            setOpenUpdateSellOrderDetailModelPopup(false)
          }}
          onClickSubmit={() => {
            fetchSellOrder()
            setOpenUpdateSellOrderDetailModelPopup(false)
          }}
        />
      </Dialog>
    </>
  )
}

export default ConfirmBeforeDiagnosisSellOrderPopup
