import { httpDelete, httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { BrandType } from "types/BrandType"
import { PageType } from "../types/PageType"

export const createBrand = (brand: BrandType): Promise<BrandType> => httpPost("/brands", brand)
export const getBrands: (params?: any | null) => Promise<BrandType[]> = (params) => httpGet("/brands", params)

export const getBrandList: (params?: any | null) => Promise<PageType<BrandType>> = (params) =>
  httpGet("/brands/list", params)

export const getBrand: (sid: string) => Promise<BrandType> = (sid) => httpGet(`/brands/${sid}`)
export const deleteBrand: (sid: string) => Promise<string> = (sid) => httpDelete(`/brands/${sid}`)
export const showBrand: (sid: string) => Promise<string> = (sid) => httpPost(`/brands/${sid}/show`)
export const hideBrand: (sid: string) => Promise<string> = (sid) => httpPost(`/brands/${sid}/hide`)
export const updateBrand: (sid: string, brand: BrandType) => Promise<BrandType> = (sid, brand) =>
  httpPut(`/brands/${sid}`, brand)
export const getBrandAccessories: (sid: string) => Promise<string[]> = (sid) => httpGet(`/brands/${sid}/accessories`)
