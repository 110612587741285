import { Box, Button, FormControlLabel, Grid, Paper, Radio, RadioGroup } from "@mui/material"
import React, { useState } from "react"
import UserSelectPopup from "../../../components/Popup/UserSelectPopup"
import { UserType } from "../../../types/UserType"
import { Spacer } from "../../../components/Spacer"
import {
  AdvancedDiagnosisTypeCode,
  AdvancedDiagnosisTypeCodeList,
  ManualAuthenticationIssuanceDataType,
} from "../../../types/DiagnosisAuthenticationIssuanceType"
import DetailModelSelectPopup from "../../../components/Popup/DetailModelSelectPopup"
import AuthenticationIssuanceButton from "../../../components/AuthenticationIssuanceButton"
import { SellOrderStatusCode } from "../../../types/SellOrderType"


type ModalStateType = {
  user: boolean
  detailModel: boolean
}

const initState: ManualAuthenticationIssuanceDataType = {
  user: null,
  advancedDiagnosis: true,
  detailModel: null
}

const ManualAuthenticationIssuance = () => {
  const [state, setState] = useState<ManualAuthenticationIssuanceDataType>(initState)
  const [modalState, setModalState] = useState<ModalStateType>({
    user: false,
    detailModel: false
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
          <UserSelectPopup
            open={modalState.user}
            filterStatus={["활성"]}
            isMultiSelectable={false}
            setUsers={(results?: UserType[]) => {
              if (results) {
                setState((prevState) => ({
                  ...prevState,
                  user: results[0],
                }))
              }
            }}
            close={() => {
              setModalState((prevState) => ({
                ...prevState,
                user: false,
              }))
            }}
          />
          <DetailModelSelectPopup
            open={modalState.detailModel}
            isMultiSelectable={false}
            handleClose={(results) => {
              if (results) {
                setState((prevState) => ({
                  ...prevState,
                  detailModel: results[0],
                }))
                setModalState((prevState) => ({
                  ...prevState,
                  detailModel: false,
                }))
              }
            }}
          />
          <Grid item container xs={12} justifyContent="flex-start" spacing={1}>
            <Grid item xs={2}>
              <h2>회원 계정</h2>
            </Grid>
            <Grid item xs={12}>
              <table className={"basic"}>
                <tbody>
                <tr>
                  <td className={"header"}>회원조회</td>
                  <td className={"header"}>회원ID</td>
                  <td className={"header"}>회원명</td>
                  <td className={"header"}>연락처</td>
                  <td className={"header"}>바이버 계정</td>
                </tr>
                <tr>
                  <td>
                      <Button
                        variant={"contained"}
                        size={"small"}
                        onClick={()=>{
                          setModalState((prevState) => ({
                            ...prevState,
                            user: true,
                          }))
                        }}
                      >회원조회</Button>
                  </td>
                  <td>{state.user?.id}</td>
                  <td>{state.user?.name}</td>
                  <td>{state.user?.phone}</td>
                  <td>{state.user?.email}</td>
                </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <Spacer space={20} />
          <Grid item container xs={12} justifyContent="flex-start" spacing={1}>
            <Grid item xs={12}>
              <h2>진단서 유형 선택</h2>
            </Grid>
            <Grid item xs={4}>
              <RadioGroup row sx={{ justifyContent: "center" }}
                          onChange={(event, value) => {
                            // @ts-ignore
                            setState((prevState) => ({
                              ...prevState,
                              advancedDiagnosis: value === AdvancedDiagnosisTypeCode.DETAIL.toString(),
                            }))
                          }}
              >
                {AdvancedDiagnosisTypeCodeList.map((item,) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    label={item.title}
                    checked={(state.advancedDiagnosis ? AdvancedDiagnosisTypeCode.DETAIL : AdvancedDiagnosisTypeCode.GENERAL) ===   item.value   }
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
          <Spacer space={20} />
          <Grid item container xs={12} justifyContent="flex-start" spacing={1}>
            <Grid item xs={2}>
              <h2>상세 모델 선택</h2>
            </Grid>
            <Grid item xs={12}>
              <table className={"basic"}>
                <tbody>
                <tr>
                  <td className={"header"}>상세 모델 조회</td>
                  <td className={"header"}>모델ID</td>
                  <td className={"header"}>썸네일</td>
                  <td className={"header"}>상세 모델명</td>
                  <td className={"header"}>레퍼런스 No.</td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      onClick={()=>{
                        setModalState((prevState) => ({
                          ...prevState,
                          detailModel: true,
                        }))
                      }}
                    >상세 모델 조회</Button>
                  </td>
                  <td>{state.detailModel?.id}</td>
                  <td>
                    { state.detailModel?.thumbnail && (
                    <Box>
                      <img style={{width: "100%"}} src={state.detailModel?.thumbnail} alt={""}/>
                    </Box>
                      )}
                  </td>
                  <td>
                    {state.detailModel?.title}
                    <br/>
                    {state.detailModel?.titleSecondary}
                  </td>
                  <td>{state.detailModel?.fullRefNo}</td>
                </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <Spacer space={100} />
          <Grid item container xs={12} justifyContent="center" spacing={1}>
            <AuthenticationIssuanceButton
              disabled={state.user == null || state.detailModel == null   }
              userId={state.user?.id}
              advancedDiagnosis={state.advancedDiagnosis}
              detailModelId={state.detailModel?.id}
            />
          </Grid>
          <Spacer space={100} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ManualAuthenticationIssuance