import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import styled from "@emotion/styled"
import SerialNumberMemoTableRow from "./TableBody/TableRow"
import SerialNumberMemoListTableHeader from "./TableHeader"

type Props = {
  memoList: any[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
}

export const SerialNumberMemoListTable = ({ memoList, paginationParams, changePage }: Props) => {
  return (
    <ListTable style={{ minWidth: "none" }}>
      <SerialNumberMemoListTableHeader />
      <TableBody
        style={{
          height: "180px",
        }}
      >
        {memoList.length > 0 ? (
          memoList.map((memoListItem) => <SerialNumberMemoTableRow key={memoListItem.id} memoListItem={memoListItem} />)
        ) : (
          <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
        )}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page - 1)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
