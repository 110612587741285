import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import React from "react";
import {Typography} from "@mui/material";
const formatter = new Intl.NumberFormat('ko-KR', {})
const priceCellRender = (manual: number | null, calculated: number | null) => {
    if (manual != null) return <Typography><b>{formatter.format(manual)}</b></Typography>
    else return <Typography style={{fontWeight: 100}}>{calculated != null? formatter.format(calculated) : ''}</Typography>
}

//@ts-ignore
const AskingPriceTable = ({rows, handlePageSizeChange, handlePageChange, page, size, totalCount, isLoading, handleSortChange, handleRowUpdate, handleOpenEditorPopup}) => {
    const columns = [
        {field: 'actions', type: 'actions', width: 30, getActions: (params: any) => [
                <GridActionsCellItem
                    label = {"해당 모델 시세 관리"}
                    onClick={() => {
                        handleOpenEditorPopup({
                            // @ts-ignore
                            detailModelId: params?.row?.detailModel?.id,
                            title: params?.row?.detailModel?.title,
                            productCondition: params?.row?.productCondition
                        })
                    }}
                    showInMenu
                />
            ]},
        {field: 'id.metricsDate', headerName: "날짜", type: 'date', editable: false, width: 100, valueGetter: (params: any) => `${params.row?.metricsDate}`},
        {field: 'id.productCondition', headerName: "상태", type: 'string', editable: false, width: 70, valueGetter: (params: any) => `${params.row?.productCondition}`},
        {field: 'detailModelTitle', headerName: "모델명", editable: false, valueGetter: (params: any) => `${params.row?.detailModel?.title} ${params.row?.detailModel?.titleSecondary ?? ''}`, sortable: false, flex: 2},
        {field: 'fullRefNo', headerName: "레퍼런스번호", editable: false, valueGetter: (params: any) => `${params.row?.detailModel?.fullRefNo}`, flex: 1},
        {field: 'maxAskingPrice', headerName: "최고가", type: 'number', editable: true, width: 140, renderCell: (params: any) => (priceCellRender(params.row.manualMaxAskingPrice, params.row.maxAskingPrice))},
        {field: 'minAskingPrice', headerName: "최저가", type: 'number', editable: true, width: 140, renderCell: (params: any) => (priceCellRender(params.row.manualMinAskingPrice, params.row.minAskingPrice))},
        {field: 'avgAskingPrice', headerName: "평균가", type: 'number', editable: true, width: 140, renderCell: (params: any) => (priceCellRender(params.row.manualAvgAskingPrice, params.row.avgAskingPrice))},
        {field: 'volume', headerName: "호가수", type: 'number', editable: false, width: 140},
    ]

    return(
        <div style={{height: 735, width: '100%'}}>
            <DataGrid
                columns={columns}
                rows={rows}
                getRowId={(row) => `${row.metricsDate}-${row.detailModel.id}-${row.productCondition}`}
                pagination
                page={page}
                pageSize={size}
                rowCount={totalCount}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                paginationMode={"server"}
                loading={isLoading}
                onSortModelChange={handleSortChange}

                experimentalFeatures={{newEditingApi: true}}
                editMode={"row"}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={() => {}}
            />

        </div>
    )
}

export default AskingPriceTable