import styled from "@emotion/styled"
import Modal from "components/Modal"
import { FormControl, MenuItem, Select } from "@mui/material"
import Button from "components/Button"
import { useState } from "react"

type Props = {
  isOpen: boolean
  closeModal: () => void
}

const userStatusMenuList = [
  { value: "1", title: "정상" },
  { value: "2", title: "이용정지" },
  { value: "3", title: "휴면" },
  { value: "4", title: "탈퇴" },
]

const suspensionPeriod = [
  { value: "15", title: "15일" },
  { value: "30", title: "30일" },
  { value: "60", title: "60일" },
  { value: "0", title: "영구정지" },
]

const UserStatusChangeModal = ({ isOpen, closeModal }: Props) => {
  const [selectedStatusOptionValue, setSelectedStatusOptionValue] = useState("1")

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>상태 관리</h3>
          <div>회원의 서비스 이용 상태를 변경할 수 있습니다.</div>
        </ModalContentHeader>
        <ModalContentBody>
          <InlineWrapper>
            <FormControl style={{ width: "100%", minWidth: "100px" }}>
              <Select
                size={"small"}
                defaultValue={selectedStatusOptionValue}
                onChange={(e) => setSelectedStatusOptionValue(e.target.value)}
                MenuProps={{
                  style: { zIndex: 10003 },
                }}
              >
                {userStatusMenuList.map((menu) => (
                  <MenuItem value={menu.value} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedStatusOptionValue === "2" && (
              <FormControl style={{ width: "100px", minWidth: "180px" }}>
                <Select
                  size={"small"}
                  defaultValue={"15"}
                  MenuProps={{
                    style: { zIndex: 10003 },
                  }}
                >
                  {suspensionPeriod.map((menu) => (
                    <MenuItem value={menu.value} key={menu.title}>
                      {menu.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </InlineWrapper>
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button buttonStyle="blue-fill" buttonSize="M">
              수정
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default UserStatusChangeModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`

const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`
