import styled from "@emotion/styled"
import { Button, IconButton, Pagination, Switch } from "@mui/material"
import React, { FunctionComponent, useRef, useState } from "react"

import ListTable from "components/Table/List"
import ListTableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"
import ListTableHeader from "components/Table/List/TableHeader"
import ListTableHeaderItem from "components/Table/List/TableHeader/TableHeaderItem"
import TableRow from "components/Table/List/TableBody/TableRow"
import TableRowItem from "components/Table/List/TableBody/TableRow/TableRowItem"
import { DetailModelListItemType } from "types/DetailModelListItemType"
import DeleteIcon from "@mui/icons-material/Delete"
import { downloadExcel } from "../../../utils/excelUtil"
import { uploadDetailModelBatchByExcel } from "../../../apis/detailModelAPI"
import { DetailModelOriginCellValue } from "../../../types/DetailModelType"

const detailModelListTableHeaderItemList: { title: string; width?: string }[] = [
  { title: "ID", width: "80px" },
  { title: "상태", width: "120px" },
  { title: "대표사진", width: "120px" },
  { title: "상세모델명" },
  { title: "Full Ref No." },
  { title: "모델명" },
  { title: "노출", width: "80px" },
  { title: "삭제", width: "80px" },
]

export const DetailModelManagerModelListTable: FunctionComponent<{
  fetchDetailModelList: (params: {
    modelId: number
    page: number
    title?: string | undefined
    registrationStatus: string
    id?: string | undefined
    fullRefNo?: string | undefined
  }) => Promise<void>
  detailModelListParams: {
    modelId: number
    page: number
    title?: string | undefined
    registrationStatus: string
    id?: string | undefined
    fullRefNo?: string | undefined
  }
  detailModelList: {
    content: DetailModelListItemType[]
    totalPages: number
  }
  removeDetailModel: (id: number) => void
  onChangePage: (page: number) => void
  onChangeDetailModelDisplay: (detailModelId: number, allowDisplay: boolean) => void
  onClickUpdateDetailModel: (detailModelListItem: DetailModelListItemType) => void
}> = ({
  fetchDetailModelList,
  detailModelListParams,
  detailModelList,
  onChangePage,
  onChangeDetailModelDisplay,
  onClickUpdateDetailModel,
  removeDetailModel,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<FileList | undefined>(undefined)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleSubmitExcel = () => {
    if (!selectedFile) {
      alert("파일을 선택해주세요.")
      return
    }

    setIsLoading(true)

    uploadDetailModelBatchByExcel(selectedFile)
      .then((res) => {
        handleDownloadExcel(res.dataList)
        if (res.failureCount === 0) {
          alert(`총 ${res.succeedCount}건 등록 되었습니다.`)
          setSelectedFile(undefined)
          if (fileInputRef.current) {
            fileInputRef.current.value = ""
          }
          fetchDetailModelList(detailModelListParams)
        } else {
          alert(
            `총 ${res.totalCount}건 오류 ${res.failureCount}건이 발생했습니다.\n오류 내용을 결과 파일로 확인 후 수정해주세요.`
          )
        }
      })
      .catch((e) => alert(`파일 업로드에 실패했습니다`))
      .finally(() => setIsLoading(false))
  }

  const handleDownloadExcel = (dataList: DetailModelOriginCellValue[]) => {
    const excelData = dataList.map((data) => {
      return {
        "처리 결과": data.status,
        "오류 내용": data.error,
        브랜드: data.brand,
        모델명: data.modelName,
        상세모델명_영문: data.detailModelNameEng,
        상세모델명_한글: data.detailModelNameKor,
        "Ref No.": data.refNo,
        "Full Ref.": data.fullRef,
        서브타이틀: data.subTitle,
        "코멘트 타이틀": data.commentTitle,
        "코멘트 상세": data.commentDetail,
        검색어: data.searchValue,
        "다이얼 색상": data.dialColor,
        "다이얼 특성": data.dialCharacter,
        "케이스 소재": data.caseMaterial,
        "브레이슬릿 소재": data.braceletMaterial,
        "브레이슬릿 종류": data.braceletType,
        글라스: data.glass,
        "케이스 직경": data.caseDiameter,
        "케이스 크기": data.caseSize,
        "케이스 두께": data.caseWidth,
        무게: data.weight,
        방수: data.waterproof,
        "베젤 소재": data.bezelMaterial,
        "베젤 종류": data.bezelType,
        무브먼트: data.movement,
        칼리버: data.caliber,
        진동수: data.vibrationNumber,
        파워리저브: data.powerReserve,
        제조국: data.manufacturedIn,
        "크라운 소재": data.crownMaterial,
        클라스프: data.clasp,
        다이얼: data.dial,
        핸즈: data.hands,
      }
    })
    downloadExcel(excelData, "상세모델_추가_결과")
  }

  return (
    <StyledDivDetailModelListTable>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginBottom: 16, gap: 6 }}>
        <a href={`${process.env.PUBLIC_URL}/excel/detailmodel.xlsx`} download target="_blank">
          <Button variant={"contained"} color={"success"} size={"small"}>
            엑셀 양식 다운로드
          </Button>
        </a>
        <FileUploader
          type={"file"}
          accept=".xlsx"
          ref={fileInputRef}
          onChange={(e) => {
            if (e.target.files) {
              setSelectedFile(e.target.files)
            }
          }}
        />
        <Button variant={"contained"} color={"info"} size={"small"} disabled={isLoading} onClick={handleSubmitExcel}>
          파일 등록
        </Button>
      </div>
      <ListTable>
        <ListTableHeader>
          {detailModelListTableHeaderItemList.map(({ title, width }, index) => (
            <ListTableHeaderItem key={index} style={{ maxWidth: width }}>
              {title}
            </ListTableHeaderItem>
          ))}
        </ListTableHeader>
        <ListTableBody>
          {detailModelList.content.length < 1 ? (
            <EmptyWrapper>결과가 없습니다.</EmptyWrapper>
          ) : (
            detailModelList.content.map((detailModel) => (
              <TableRow key={detailModel.id} style={{ height: 48 }}>
                <TableRowItem style={{ maxWidth: detailModelListTableHeaderItemList[0].width }}>
                  {detailModel.id}
                </TableRowItem>
                <TableRowItem
                  onClick={() => onClickUpdateDetailModel(detailModel)}
                  style={{ maxWidth: detailModelListTableHeaderItemList[1].width, height: 48, overflow: "hidden" }}
                >
                  <StatusBadge status={detailModel?.registrationStatus}>{detailModel.registrationStatus}</StatusBadge>
                </TableRowItem>
                <TableRowItem
                  style={{ maxWidth: detailModelListTableHeaderItemList[2].width, height: 48, overflow: "hidden" }}
                >
                  {detailModel.thumbnail && (
                    <img style={{ width: 104 }} src={detailModel.thumbnail} alt={detailModel.title} />
                  )}
                </TableRowItem>
                <TableRowItem style={{ maxWidth: detailModelListTableHeaderItemList[3].width }}>
                  <div style={{ flexDirection: "column" }}>
                    <div>{detailModel.title}</div>
                    <div style={{ color: "#969696" }}>{detailModel.titleSecondary}</div>
                  </div>
                </TableRowItem>
                <TableRowItem style={{ maxWidth: detailModelListTableHeaderItemList[4].width }}>
                  {detailModel.fullRefNo}
                </TableRowItem>
                <TableRowItem style={{ maxWidth: detailModelListTableHeaderItemList[5].width }}>
                  {detailModel.name}
                </TableRowItem>
                <TableRowItem style={{ maxWidth: detailModelListTableHeaderItemList[6].width }}>
                  <Switch
                    checked={detailModel.onDisplay}
                    onChange={(_, checked) => onChangeDetailModelDisplay(detailModel.id, checked)}
                  />
                </TableRowItem>
                <TableRowItem style={{ maxWidth: detailModelListTableHeaderItemList[7].width }}>
                  <IconButton aria-label="delete" size="large" onClick={() => removeDetailModel(detailModel.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableRowItem>
              </TableRow>
            ))
          )}
        </ListTableBody>
        <ListTableFooter>
          <Pagination
            count={detailModelList.totalPages}
            page={detailModelListParams.page}
            onChange={(_, page) => onChangePage(page)}
          />
        </ListTableFooter>
      </ListTable>
    </StyledDivDetailModelListTable>
  )
}

const EmptyWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDivDetailModelListTable = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const StatusBadge = styled.div<{ status: DetailModelListItemType["registrationStatus"] }>`
  height: 27px;
  padding: 5px 9px;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;

  ${({ status }) => {
    switch (status) {
      case "검수완료":
        return `
          color: #298A20;;
          border-color: #298A20;;
        `
      case "등록대기":
        return `
        color: #1F78E0;
        border-color: #1F78E0;
      `
      case "검수대기":
        return `
        color: #FF3333;
        border-color: #FF3333;
      `
      case "이미지제작":
        return `
        color: #1F78E0;
        border-color:#1F78E0;
      `
    }
  }}
`

const FileUploader = styled.input`
  //flex: 1;
  height: 33px;
  padding: 5px 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
`
