import React, { useState } from "react"
import { ProvisionalPreSettlementDetailType } from "../../../types/PreSettlementType"

import { toDateStr, toDateTimeStr } from "utils/datetimeUtil"

import { Button } from "@mui/material"
import { SellOrderType } from "../../../types/SellOrderType"
import ViewConsignmentSellOrderPopup from "../../SellOrder/Consignment/ViewConsignmentSellOrderPopup"
import { isNotNil } from "../../../utils/validationUtils"
import ConfirmBeforeDiagnosisSellOrderPopup from "../../SellOrder/BeforeDiagnosis/ConfirmBeforeDiagnosisSellOrderPopup"

export const ProductInfoView: React.FC<{ item: ProvisionalPreSettlementDetailType; sellOrder?: SellOrderType }> = (
  props
) => {
  const [isOpenSellOrder, setIsOpenSellOrder] = useState<boolean>(false)
  return (
    <>
      {isNotNil(props.sellOrder) ? (
        <>
          <ViewConsignmentSellOrderPopup
            open={isOpenSellOrder && props.item.status === "위탁전환완료"}
            selectedSellOrderId={props.sellOrder!.id}
            handleClose={() => setIsOpenSellOrder(false)}
          />
          <ConfirmBeforeDiagnosisSellOrderPopup
            open={isOpenSellOrder && props.item.status !== "위탁전환완료"}
            selectedSellOrderId={props.sellOrder!.id}
            handleClose={() => setIsOpenSellOrder(false)}
          />
        </>
      ) : null}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2>상품 정보</h2>&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"ta-center"} rowSpan={4}>
              <img
                style={{ display: "block", margin: "auto", width: "100px", height: "200px", objectFit: "cover" }}
                src={props.item.product.thumbnail}
                alt=""
              />
            </td>
            <td className={"header"} colSpan={3}>
              상품명
            </td>
            <td className={"header"} colSpan={2}>
              Ref No.
            </td>
          </tr>
          <tr>
            <td style={{ height: "80px" }} colSpan={1}>
              {props.item.product.brandSid}
            </td>
            <td colSpan={1}>{props.item.product.title}</td>
            <td colSpan={1}>{props.item.product.titleSecondary}</td>
            <td colSpan={2}>{props.item.product.detailModel.fullRefNo}</td>
          </tr>
          <tr>
            <td className={"header"} colSpan={1}>
              판매 주문번호
            </td>
            <td className={"header"} colSpan={1}>
              상품번호
            </td>
            <td className={"header"} colSpan={1}>
              견적 요청 일시
            </td>

            <td className={"header"} colSpan={1}>
              견적서 생성 일시
            </td>

            <td className={"header"} colSpan={1}>
              견적 안내 일시
            </td>
          </tr>
          <tr>
            <td style={{ height: "80px" }} colSpan={1}>
              {props.item.orderId}
            </td>
            <td colSpan={1}>
              <Button onClick={() => setIsOpenSellOrder(true)}>{props.item.product.id}</Button>
            </td>
            <td colSpan={1}>{toDateTimeStr(props.item.requestedAt)}</td>
            <td colSpan={1}>
              {props.item.status !== "작성대기" && props.item.status !== "지급반려"
                ? toDateStr(props.item.estimatedAt)
                : "-"}
            </td>
            <td colSpan={1}>{props.item.status === "안내완료" ? toDateStr(props.item.notifiedAt) : "-"}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
